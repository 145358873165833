import {
  CarOutlined,
  ClockCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { gql, useApolloClient } from "@apollo/client";
import {
  Badge as ABadge,
  Badge,
  Button,
  Input,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import HotKeys from "react-hot-keys";
import { Link } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { style } from "typestyle";
import {
  FulfillmentMethod,
  Ordering,
  OrderStatus,
  Restaurant,
  Zeus,
} from "../graphql/generated";
import { useTypedQuery } from "../graphql/hooks";
import { ORDER, ORDER_LIST } from "../graphql/queries";
import { useDebounce } from "../hooks/useDebounce";
import usePagination, { Order } from "../hooks/usePagination";
import { onError } from "../utils/on-error";
import { price } from "../utils/price";
import { formatDistanceDuration } from "../utils/sphere";
import EstimatedArrivalDisplay from "./EstimatedArrivalDisplay";
import { HelpButton } from "./HelpButton";
import OrderActions from "./order/OrderActions";
import OrderCustomerInfo from "./order/OrderCustomerInfo";
import { locale, OrderBadge } from "./OrderBadge";
import PickupAtDisplay from "./PickupAtDisplay";
import RiderName from "./rider/RiderName";

const STATUSES = Object.keys(OrderStatus) as OrderStatus[];
const OPTIONS = STATUSES.map((k) => ({
  text: locale[k],
  value: k,
}));

const FULFILLMENT_METHODS = Object.keys(
  FulfillmentMethod
) as FulfillmentMethod[];
const FULFILLMENT_METHOD_OPTIONS = FULFILLMENT_METHODS.map((k) => ({
  text: k,
  value: k,
}));

const ORDER_GQL = gql(Zeus.query(ORDER));

const expandable = {
  rowExpandable: (r) => r.status !== "COMPLETE" && r.status !== "CANCELLED",
  defaultExpandAllRows: false,
  expandedRowRender: (r) => (
    <div>
      <OrderActions order={r} />
      <OrderCustomerInfo
        bordered
        column={3}
        {...r}
        customerId={r.customer.id}
        name={r.customer.name}
        email={r.customer.email}
        orderId={r.id}
      />
    </div>
  ),
};

type SortableOrdersTableCol =
  | "placedAt"
  | "initialPickupAt"
  | "pickupAt"
  | "estimatedArrivalTime";

const convertOrderingToAnt = (ordering: Order): "ascend" | "descend" =>
  ordering === "ASC" ? "ascend" : "descend";

export type OrdersTableConfig = {
  page: number;
  take: number;
  sortBy: SortableOrdersTableCol;
  order: Ordering;
  statuses: OrderStatus[];
  fulfillmentMethods: FulfillmentMethod[];
  query: string;
};

const DEFAULT_SORT_BY: SortableOrdersTableCol = "placedAt";
const DEFAULT_ORDERING: Order = "DESC";
const DEFAULT_STATUSES = STATUSES;
const DEFAULT_FULFILLMENT_METHODS = FULFILLMENT_METHODS;

export type OnChangeOrdersTableConfig = (newConfig: OrdersTableConfig) => void;

export const OrdersTable = ({
  restaurant,
  customerId,
  zoneId,
  riderId,
  visible = true,
  persistContextId: propsPersistContextId,
}: {
  restaurant?: Restaurant;
  customerId?: string;
  zoneId?: string;
  riderId?: string;
  visible?: boolean;
  // if provided table pagination will be persisted
  persistContextId?: string;
}) => {
  const persistContextId = propsPersistContextId
    ? `orders-table-${propsPersistContextId}`
    : undefined;

  const [statuses, setStatuses] = useLocalStorage(
    `${persistContextId}-statuses`,
    DEFAULT_STATUSES
  );

  const [fulfillmentMethods, setFulfillmentMethods] = useLocalStorage(
    `${persistContextId}-fulfillment-methods`,
    DEFAULT_FULFILLMENT_METHODS
  );

  const additionalOnChangeActions = useCallback(
    (
      p: TablePaginationConfig,
      f: Record<string, FilterValue | null>,
      s: SorterResult<any> | SorterResult<any>[]
    ) => {
      const statuses = f["status"] as OrderStatus[] | undefined;
      const fulfillmentMethods = f["fulfillmentMethod"] as
        | FulfillmentMethod[]
        | undefined;
      if (statuses) setStatuses(statuses);
      if (fulfillmentMethods) setFulfillmentMethods(fulfillmentMethods);
    },
    [setFulfillmentMethods, setStatuses]
  );

  const {
    pagination,
    args: { order, page, query, sortBy, take },
    setQuery,
    onChange,
  } = usePagination({
    defaultOrder: DEFAULT_ORDERING,
    defaultSortBy: DEFAULT_SORT_BY,
    additionalTableConfigs: [
      {
        propertyName: "statuses",
        value: statuses,
        setFunc: setStatuses,
      },
      {
        propertyName: "fulfillmentMethods",
        value: fulfillmentMethods,
        setFunc: setFulfillmentMethods,
      },
    ],
    additionalOnChangeActions,
    persistContextId,
  });

  const { data, loading } = useTypedQuery(ORDER_LIST, {
    variables: {
      ...(restaurant ? { restaurantId: restaurant?.id } : {}),
      ...(customerId ? { customerId } : {}),
      skip: (page - 1) * take!,
      take,
      sortBy,
      order,
      zoneId,
      riderId,
      query,
      status: statuses,
      fulfillmentMethods,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "network-only",
    pollInterval: 2500,
    onError: onError,
  });

  const client = useApolloClient();

  const preloadRef = useRef(-1);
  const enablePreload = true;
  const doPreload = useCallback(
    (id) => {
      if (!enablePreload) return;
      clearTimeout(preloadRef.current);

      preloadRef.current = +setTimeout(() => {
        client.query({
          query: ORDER_GQL,
          variables: {
            id,
          },
          fetchPolicy: "network-only",
        });
      }, 300);

      return () => clearTimeout(preloadRef.current);
    },
    [enablePreload, client]
  );

  const setLiveMode = useCallback(() => {
    const fulfillmentMethods = [FulfillmentMethod.DELIVERY];
    const statuses = [
      OrderStatus.AWAITING_RESTAURANT,
      OrderStatus.PREP,
      OrderStatus.AWAITING_RIDER,
      OrderStatus.EN_ROUTE,
      OrderStatus.AWAITING_CUSTOMER,
    ];
    setFulfillmentMethods(fulfillmentMethods);
    setStatuses(statuses);
  }, [setFulfillmentMethods, setStatuses]);

  const setArchiveMode = useCallback(() => {
    const fulfillmentMethods = Object.keys(
      FulfillmentMethod
    ) as FulfillmentMethod[];
    const statuses = Object.keys(OrderStatus) as OrderStatus[];
    setFulfillmentMethods(fulfillmentMethods);
    setStatuses(statuses);
  }, [setFulfillmentMethods, setStatuses]);

  const COLUMNS = useMemo<
    ColumnsType<NonNullable<typeof data>["OrderList"]["items"][0]>
  >(
    () => [
      ...(restaurant
        ? []
        : [
            {
              key: "restaurant",
              title: "Merchant",

              render: (res) => (
                <Tooltip title={res.restaurant.configuration}>
                  {!res.b2bDeliveryJob ? (
                    <div
                      style={{
                        width: 100,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Link to={`/restaurant/${res.restaurant.id}`}>
                        {res.restaurant.name}
                      </Link>
                    </div>
                  ) : (
                    <Tag color="cyan">B2B</Tag>
                  )}
                </Tooltip>
              ),
            },
          ]),
      ...(zoneId
        ? []
        : [
            {
              key: "zone",
              title: "Zone",
              render: (_, r) => (
                <Tooltip title={r.restaurant.zone.name}>
                  <Link to={`/zone/${r.restaurant.zone.id}`}>
                    {r.restaurant.zone.slug
                      .toUpperCase()
                      .replace(/\W+/g, "")
                      .slice(0, 3)}
                  </Link>
                </Tooltip>
              ),
            },
          ]),
      {
        dataIndex: "number",
        key: "number",
        title: "Number",
        render: (n, r) => (
          <span>
            <Badge count={r.unreadMessageCount} size="small" offset={[5, -7]}>
              <Link to={`/order/${r.id}`}>{n}</Link>
            </Badge>{" "}
            {r.aggregateOrder.orders.length > 1 && (
              <Tooltip
                title={`Aggregated with ${
                  r.aggregateOrder.orders.length - 1
                } others`}
              >
                <Tag>{r.aggregateOrder.id.slice(0, 3)}</Tag>
              </Tooltip>
            )}
            {r.basket.alcohol ? (
              <Tooltip title="This order contains alcohol">
                <Badge status="warning" />
              </Tooltip>
            ) : null}{" "}
            {r.reusables ? (
              <Tooltip title="This order contains reusables">
                <Badge color="green" />
              </Tooltip>
            ) : null}
          </span>
        ),
      },
      {
        dataIndex: "basket",
        key: "totalPrice",
        title: "Total Price",
        render: (r) => price(r.totalPrice),
      },
      {
        dataIndex: "riderEarnings",
        key: "riderEarnings",
        title: "Rider Fee",
        render: (r, o) => (
          <span>
            <Tooltip title={formatDistanceDuration(o.basket.distanceDuration)}>
              {price(r)}
            </Tooltip>
          </span>
        ),
      },
      {
        key: "fulfillmentMethod",
        title: "Method",
        filters: FULFILLMENT_METHOD_OPTIONS,
        filteredValue: fulfillmentMethods,
        dataIndex: "fulfillmentMethod",
        render: (r, o) => (
          <>
            <Tooltip title={r}>{r.slice(0, 1)}</Tooltip>
            {o.basket.distanceDuration?.distance?.value >
            o.restaurant.zone.standardDeliveryDistanceMetres ? (
              <Tooltip title="This delivery may only be completed by car.">
                <CarOutlined
                  style={{ marginLeft: 4, verticalAlign: "middle" }}
                />
              </Tooltip>
            ) : null}
          </>
        ),
      },
      {
        key: "status",
        title: "Status",
        filters: OPTIONS,
        filteredValue: statuses,
        render: (res) => (
          <OrderBadge
            status={res.status}
            blocked={res.pickupBlocked}
            isRiderAtRestaurant={res.isRiderAtRestaurant}
          />
        ),
      },
      {
        dataIndex: "placedAt",
        key: "placedAt",
        title: "Placed At",
        sorter: true,
        defaultSortOrder:
          sortBy === "placedAt" ? convertOrderingToAnt(order) : undefined,
        render: (res) => new Date(res).toLocaleString("en-GB"),
      },
      {
        key: "initialPickupAt",
        title: "Prepare",
        sorter: true,
        defaultSortOrder:
          sortBy === "initialPickupAt"
            ? convertOrderingToAnt(order)
            : undefined,
        render: (res) => (
          <PickupAtDisplay
            status={res.status}
            initialPickupAt={res.initialPickupAt}
            riderPickedUpTime={res.riderPickedUpTime}
          />
        ),
      },
      {
        key: "pickupAt",
        title: "Pickup",
        sorter: true,
        defaultSortOrder:
          sortBy === "pickupAt" ? convertOrderingToAnt(order) : undefined,
        render: (_, res) => (
          <PickupAtDisplay
            status={res.status}
            pickupAt={res.pickupAt}
            scheduledFor={res.scheduledFor}
            riderPickedUpTime={res.riderPickedUpTime}
            pickupDelayedByPartner={res.pickupDelayedByPartner}
          />
        ),
      },
      {
        key: "estimatedArrivalTime",
        title: "ETA",
        sorter: true,
        defaultSortOrder:
          sortBy === "estimatedArrivalTime"
            ? convertOrderingToAnt(order)
            : undefined,
        render: (res) => <EstimatedArrivalDisplay order={res} />,
      },
      {
        key: "rider.name",
        title: "Rider",
        render: (res, o) =>
          res.rider ? (
            <Tooltip
              title={
                res.isBeingOfferedToRider
                  ? "Rider is being offered the order"
                  : res.isRiderAtRestaurant && res.riderArrivedAtRestaurantTime
                  ? `Rider arrived at ${res.restaurant.name} ${moment(
                      res.riderArrivedAtRestaurantTime
                    ).fromNow()}`
                  : res.status === "AWAITING_CUSTOMER" &&
                    res.riderArrivedAtCustomerTime
                  ? `Rider arrived at customer ${moment(
                      res.riderArrivedAtCustomerTime
                    ).fromNow()}`
                  : "Rider is assigned to the order"
              }
            >
              <ABadge
                status={res.isBeingOfferedToRider ? "processing" : "success"}
                text={
                  o.rider ? (
                    <RiderName
                      id={o.rider.id}
                      name={o.rider.name}
                      vehicle={o.rider.vehicle}
                    />
                  ) : null
                }
              />
            </Tooltip>
          ) : null,
      },
    ],
    [restaurant, zoneId, fulfillmentMethods, statuses, sortBy, order]
  );

  const searchRef = useRef<Input | null>(null);

  const [searchVal, setSearchVal] = useState(query);

  // used to force a refresh of table
  const [_, setDummyCount] = useState(0);

  // forces a refresh of the table
  const forceRefresh = useCallback(() => {
    setDummyCount((dummyCount) => dummyCount + 1);
  }, [setDummyCount]);

  // refresh the table whenever data is fetched
  useEffect(() => {
    forceRefresh();
    console.log("refreshing");
  }, [data, forceRefresh]);

  return (
    <div
      style={{
        overflow: "auto",
        display: !visible ? "none" : undefined,
      }}
    >
      <Table
        title={() => (
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            <Title level={4} style={{ flex: 1 }}>
              {data?.OrderList.total} Orders
            </Title>

            <Space>
              <HotKeys
                keyName="s+s"
                onKeyUp={() => searchRef.current?.focus()}
              />
              <Tooltip title="Quick Order Search (s)">
                <Input
                  ref={searchRef}
                  placeholder="Search Orders"
                  value={searchVal}
                  onChange={(e) => {
                    const query = e.target.value;
                    setSearchVal(query);
                    setQuery(query);
                  }}
                  style={{ marginBottom: 8 }}
                  allowClear
                />
              </Tooltip>

              <ButtonGroup>
                <HotKeys keyName="l" onKeyDown={setLiveMode} />
                <HotKeys keyName="a" onKeyDown={setArchiveMode} />
                <Tooltip title="Show only live orders (l)">
                  <Button onClick={setLiveMode} icon={<PlayCircleOutlined />}>
                    Live Mode
                  </Button>
                </Tooltip>
                <Tooltip title="Show all orders (a)">
                  <Button
                    onClick={setArchiveMode}
                    icon={<ClockCircleOutlined />}
                  >
                    Archive Mode
                  </Button>
                </Tooltip>
              </ButtonGroup>

              <HelpButton />
            </Space>
          </div>
        )}
        dataSource={data?.OrderList.items}
        rowKey="id"
        expandable={expandable}
        pagination={{
          ...pagination,
          total: data?.OrderList.total,
        }}
        locale={{
          emptyText: (
            <>
              <Title level={4}>No Orders</Title>
              {statuses?.length || fulfillmentMethods?.length || query ? (
                <div>
                  <div>
                    <Text type="warning">
                      There may be more matching orders without your filters.
                    </Text>
                  </div>
                  <Button
                    onClick={() => {
                      const statuses = STATUSES;
                      const fulfillmentMethods = FULFILLMENT_METHODS;
                      const query = "";
                      setStatuses(statuses);
                      setFulfillmentMethods(fulfillmentMethods);
                      setQuery(query);
                    }}
                  >
                    Clear Filters
                  </Button>
                </div>
              ) : null}
            </>
          ),
        }}
        onChange={onChange}
        loading={loading}
        onRow={(r) => ({
          onMouseOver: () => doPreload(r.id),
          style: {
            borderBottom:
              r.aggregateOrder.orders.length > 1
                ? "1px solid green"
                : undefined,
          },
        })}
        className={styles.row}
        columns={COLUMNS}
      />
    </div>
  );
};

const styles = {
  row: style({
    $nest: {
      td: {
        padding: "8px 12px !important",
      },
    },
  }),
};
