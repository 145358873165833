import { Switch } from "antd";
import React, { useEffect } from "react";
import { useStore } from "../../../../state/zustand";

export const DarkModeToggle = () => {
  const dark = useStore((s) => s.darkMode);
  const toggleDark = useStore((s) => s.toggleDark);

  useEffect(() => {
    if (dark) {
      document.querySelector("html")!.className = "dark-mode";
    } else {
      document.querySelector("html")!.className = "";
    }
  }, [dark]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Switch
        checkedChildren={<span>🌙</span>}
        unCheckedChildren={<span>☀</span>}
        checked={dark}
        onChange={toggleDark}
      />
    </div>
  );
};
