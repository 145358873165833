import { Modal } from "antd";
import React from "react";
import { MenuImport } from "../forms/MenuImport";

interface Props {
  visible: boolean;
  onCancel: () => void;
  menuId?: string;
}

function ImportMenuModal(props: Props) {
  const { visible, onCancel, menuId } = props;

  return (
    <Modal
      title="Import Menu"
      visible={visible && menuId !== undefined}
      onCancel={onCancel}
      footer={null}
    >
      {menuId ? <MenuImport menuId={menuId as string} /> : null}
    </Modal>
  );
}

export default ImportMenuModal;
