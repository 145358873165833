import { Drawer, Progress, Table, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTypedQuery } from "../../graphql/hooks";
import { PARTNER_TABLET_LIST } from "../../graphql/queries";
import { useDebounce } from "../../hooks/useDebounce";
import { bytesToSize } from "../../utils/price";
import { PartnerTablet } from "./PartnerTablet";

export default function PartnerTabletTable({
  partnerId,
}: {
  partnerId?: string;
}) {
  const [page, setSkip] = useState(1);
  const [take, setTake] = useState(25);
  const [sortBy, setSortBy] = useState("lastConnection");
  const [order, setOrder] = useState("DESC");
  const [_query, setQuery] = useState("");
  const query = useDebounce(_query, 500);

  const { data, loading } = useTypedQuery(PARTNER_TABLET_LIST, {
    pollInterval: 5000,
    variables: {
      partnerId,
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
    },
  });

  const [selected, setSelected] = useState<string>("");

  return (
    <>
      <Table
        title={() => (
          <Title level={5}>{data?.PartnerTabletList.total} Tablets</Title>
        )}
        dataSource={data?.PartnerTabletList.items}
        onChange={(p, f, s) => {
          if (s instanceof Array) {
          } else {
            setSortBy(
              s.order
                ? (s.field as string) ||
                    (s.columnKey as string) ||
                    "lastConnection"
                : "lastConnection"
            );
            setOrder(!s.order ? "DESC" : s.order === "ascend" ? "ASC" : "DESC");
          }
        }}
        pagination={{
          total: data?.PartnerTabletList.total,
          current: page,
          pageSize: take,
          onChange: (p, ps) => {
            setTake(ps!);
            setSkip(p);
          },
          showSizeChanger: true,
        }}
        columns={[
          {
            title: "Partner",
            dataIndex: "partner",
            render: (p) => <Link to={`/restaurant/${p.id}`}>{p.name}</Link>,
          },
          {
            title: "Device",

            sorter: true,
            render: (_, r) => `${r.manufacturer} ${r.model}`,
          },
          {
            title: "Serial",
            dataIndex: "serial",
            sorter: true,
            render: (s, r) => (
              <Link to="#" onClick={() => setSelected(r.id)}>
                {s}
              </Link>
            ),
          },
          {
            title: "Release",
            dataIndex: "release",
            render: (r) => <Text style={{ fontFamily: "monospace" }}>{r}</Text>,
            sorter: true,
          },
          {
            title: "Connected",
            dataIndex: "lastConnection",
            render: (r) => moment(r).fromNow(),
            sorter: true,
          },
          {
            title: "WiFi",
            dataIndex: "routerMake",
            render: (_, r) => (
              <Tooltip
                title={`BSSID: ${r.bssid}
              SSID: ${r.wifiNetwork}`}
              >
                <Text>
                  {r.routerMake && r.routerMake !== "Not Found"
                    ? r.routerMake
                    : r.wifiNetwork}{" "}
                  <Progress
                    type="circle"
                    percent={100 * ((r.wifiStrength || 0) / 4)}
                    size="small"
                    width={24}
                  />
                </Text>
              </Tooltip>
            ),
          },
          {
            title: "Memory",
            key: "usedRam",
            sorter: true,
            render: (_, r) => (
              <Text type={!r.ram ? "secondary" : undefined}>
                {r.ram && r.usedRam
                  ? `${bytesToSize(r.usedRam)}/${bytesToSize(r.ram)}`
                  : "No Data"}
              </Text>
            ),
          },
          {
            title: "Battery",
            key: "battery",
            sorter: true,
            render: (_, r) =>
              typeof r.battery === "number" ? (
                <Text
                  style={{
                    color:
                      r.battery > 0.8
                        ? "green"
                        : r.battery > 0.3
                        ? "yellow"
                        : "red",
                  }}
                >
                  {(r.battery * 100).toFixed(0)}% ({r.batteryState})
                </Text>
              ) : (
                ""
              ),
          },
        ]}
      />
      <Drawer
        width={768}
        visible={!!selected}
        onClose={() => setSelected("")}
        bodyStyle={{
          padding: 0,
        }}
      >
        {selected ? (
          <>
            <PartnerTablet id={selected} />
          </>
        ) : null}
      </Drawer>
    </>
  );
}
