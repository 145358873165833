import { CheckCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { Input, Modal, Table, Tag, Select } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocalStorage } from "react-use";

interface ModifierOption {
  id: string;
  name: string;
  description?: string;
  unitPrice: number;
  published: boolean;
  available: boolean;
  sortOrder: number;
  categoryId: string;
  categoryName: string;
}

interface Props {
  show: boolean;
  onHide: () => void;
  onSave: (selected: ModifierOption[]) => void;
  initialModifierItemIds: string[];
  modifierItems: ModifierOption[];
  categories: {
    id: string;
    name: string;
  }[];
  partnerId: string;
}

export const SearchModifierItems = (props: Props) => {
  const {
    show,
    onHide,
    onSave,
    initialModifierItemIds,
    modifierItems,
    categories,
    partnerId,
  } = props;
  // contains the ids of the selected modifier groups
  const [selected, setSelected] = useState<string[]>(initialModifierItemIds);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (!show) {
      setSelected(initialModifierItemIds);
    }
  }, [initialModifierItemIds, show]);

  const onSelect = useCallback((id) => {
    setSelected((selected) => {
      const idx = selected.findIndex((eid) => id === eid);
      if (idx !== -1)
        return selected
          .slice(0, idx)
          .concat(selected.slice(idx + 1, selected.length));

      return [...selected, id];
    });
  }, []);

  const modifiers = useMemo(
    () =>
      modifierItems?.map((mi) => ({
        ...mi,
        unitPriceStr: `£${(mi.unitPrice / 100).toFixed(2)}`,
        selected: selected.includes(mi.id),
      })) || [],
    [modifierItems, selected]
  );

  // determines which categories to limit the search to
  // if empty array will search in all categories
  const [searchCategoryIds, setSearchCategoryIds] = useLocalStorage<string[]>(
    `SearchModifierCategories-${partnerId}`,
    []
  );

  const filteredModifiers = useMemo(
    () =>
      modifiers?.filter(
        (mi) =>
          mi.name?.toLowerCase().includes(query.toLowerCase()) &&
          (searchCategoryIds == null ||
            searchCategoryIds.length === 0 ||
            searchCategoryIds.includes(mi.categoryId))
      ),
    [modifiers, query, searchCategoryIds]
  );

  const onOk = useCallback(
    () => onSave(modifiers.filter((modifier) => modifier.selected)),
    [onSave, modifiers]
  );

  return (
    <Modal
      visible={show}
      onCancel={onHide}
      title={`Modifiers`}
      okText="Confirm Modifiers"
      onOk={onOk}
      width="60vw"
      okButtonProps={{ className: "confirm-modifiers-btn" }}
    >
      <div style={{ marginBottom: 5 }}>
        <Text>Categories to Search in</Text>
      </div>

      <Select
        mode="multiple"
        style={{ width: "50%", marginBottom: 15 }}
        allowClear
        placeholder="Categories"
        value={searchCategoryIds}
        onChange={setSearchCategoryIds}
      >
        {categories.map((category) => (
          <Select.Option key={category.id} value={category.id}>
            {category.name}
          </Select.Option>
        ))}
      </Select>
      <div style={{ marginBottom: 5 }}>
        <Text>Item Name</Text>
      </div>
      <Input
        className="search-modifier-input"
        name="search"
        onChange={(e) => setQuery(e.currentTarget.value)}
        placeholder="Search Modifiers"
        style={{ marginBottom: 8 }}
      />

      <Table
        className="search-modifiers-results-table"
        dataSource={filteredModifiers}
        onRow={(mg) => ({
          style: {
            cursor: "pointer",
          },
          onClick: () => onSelect(mg.id),
        })}
        pagination={{
          pageSize: 5,
        }}
        columns={[
          {
            title: "Category",
            dataIndex: "categoryName",
            defaultSortOrder: "descend",
            sorter: {
              compare: (a, b) =>
                String(a.categoryName).localeCompare(b.categoryName!),
            },
          },
          {
            title: "Name",
            dataIndex: "name",
            sorter: {
              compare: (a, b) => String(a.name).localeCompare(b.name!),
            },
          },
          {
            title: "Description",
            dataIndex: "description",
            sorter: {
              compare: (a, b) =>
                String(a.description).localeCompare(b.description!),
            },
          },
          {
            title: "Unit Price",
            dataIndex: "unitPriceStr",
            sorter: {
              compare: (a, b) => a.unitPrice - b.unitPrice!,
            },
          },
          {
            width: 50,
            render: (r) => (
              <Tag
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexShrink: 1,
                  width: 15,
                  height: 15,
                  borderRadius: "20%",
                  padding: 10,
                }}
                color={r.selected ? "green" : "default"}
              >
                {r.selected ? (
                  <CheckCircleFilled size={18} />
                ) : (
                  <PlusCircleFilled size={18} />
                )}
              </Tag>
            ),
          },
        ]}
      />
    </Modal>
  );
};
