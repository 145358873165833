import { PageHeader } from "antd";
import React from "react";
import { Container } from "shards-react";
import { RidersTable } from "../../components/RidersTable";

const Riders = () => {
  return (
    <Container fluid className="main-content-container px-4">
      <PageHeader title="Riders" />

      <RidersTable />
    </Container>
  );
};

export default Riders;
