import React from "react";
import { OrdersTable } from "./OrdersTable";

export const RestaurantOverview = ({ restaurant }) => {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <OrdersTable restaurant={restaurant} />
    </div>
  );
};
