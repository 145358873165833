import { CloseOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  message,
  Space,
  TimePicker,
} from "antd";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { $, DayOfWeek } from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";
import { RestaurantContext } from "../../views/Restaurant";

const options = Object.keys(DayOfWeek);

export default function OpeningTimes() {
  const { data } = useContext(RestaurantContext);

  const restaurant = data?.Restaurant!;

  const [openingTimes, setOpeningTimes] = useState<
    typeof restaurant["openingTimes"]
  >([]);
  // indicates whether the user has modified the opening times since the last save.
  const [dirtyOpeningTimes, setDirtyOpeningTimes] = useState(false);

  useEffect(() => {
    if (data && !dirtyOpeningTimes) setOpeningTimes(restaurant.openingTimes);
  }, [data]);

  const [changeOpeningTimes, { loading }] = useTypedMutation(
    {
      updateRestaurant: [
        {
          id: $`id`,
          restaurant: $`restaurant`,
        },
        {
          id: true,
        },
      ],
    },
    {
      onCompleted: () => {
        message.success("Updated opening times!");
        setDirtyOpeningTimes(false);
      },
      variables: {
        id: restaurant?.id,
        restaurant: {
          openingTimes: openingTimes.map((o) => ({
            close: o.close,
            open: o.open,
            daysOfWeek: o.daysOfWeek,
          })),
        },
      },
    }
  );

  const addNewTime = useCallback(() => {
    if (
      openingTimes.length &&
      (!openingTimes[openingTimes.length - 1]?.daysOfWeek.length ||
        !openingTimes[openingTimes.length - 1]?.open ||
        !openingTimes[openingTimes.length - 1]?.close)
    ) {
      message.error("Fill out the previous opening time!");
      return;
    }
    setDirtyOpeningTimes(true);
    setOpeningTimes([
      ...openingTimes,
      {
        close: "22:00",
        open: "10:00",
        daysOfWeek: [],
        id: Math.random().toString(),
      },
    ]);
  }, [openingTimes]);

  return (
    <Form
      onFinish={changeOpeningTimes.bind(null)}
      onValuesChange={() => setDirtyOpeningTimes(true)}
    >
      {openingTimes.map((o, i) => (
        <div key={`opening_time_${i}_resto${restaurant?.id}`}>
          <Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <TimePicker.RangePicker
                style={{
                  flex: 1,
                  marginRight: 8,
                }}
                showSecond={false}
                format="HH:mm"
                value={[moment(o.open, "HH:mm"), moment(o.close, "HH:mm")]}
                onChange={([open, close]: any) => {
                  setDirtyOpeningTimes(true);
                  setOpeningTimes(
                    openingTimes.map((ot, ii) =>
                      ii === i
                        ? {
                            ...ot,
                            open: open.format("HH:mm"),
                            close: close.format("HH:mm"),
                          }
                        : ot
                    )
                  );
                }}
              />
              <Button
                danger
                icon={<CloseOutlined />}
                onClick={() => {
                  setDirtyOpeningTimes(true);
                  setOpeningTimes(openingTimes.filter((_, ii) => i !== ii));
                }}
              >
                Remove
              </Button>
            </div>
            <Checkbox.Group
              style={{
                marginTop: 8,
              }}
              onChange={(v) => {
                setDirtyOpeningTimes(true);
                setOpeningTimes(
                  openingTimes.map((ot, ii) =>
                    ii === i ? { ...ot, daysOfWeek: v as any } : ot
                  )
                );
              }}
              options={options}
              value={o.daysOfWeek}
            />
          </Form.Item>
          <Divider />
        </div>
      ))}

      <Space style={{ marginBottom: 10 }}>
        <Button icon={<PlusOutlined />} onClick={addNewTime.bind(null)}>
          Add New Time
        </Button>
        <Button
          loading={loading}
          htmlType="submit"
          type="primary"
          icon={<SaveOutlined />}
        >
          Save Opening Hours
        </Button>
      </Space>
    </Form>
  );
}
