import { Badge, Card, message, Select, Table } from "antd";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { $, ItemUpdateRequestStatus, Ordering } from "../../graphql/generated";
import { useTypedMutation, useTypedQuery } from "../../graphql/hooks";
import { getImageUrl } from "../../utils/image-url";

interface Props { }

const STATUSES = [
  ItemUpdateRequestStatus.PENDING,
  ItemUpdateRequestStatus.APPROVED,
  ItemUpdateRequestStatus.REJECTED,
];

const ItemCreationRequest = (props: Props) => {
  const { } = props;

  const [approvedFilter, setApprovedFilter] = useState<boolean | undefined>(
    false
  );

  const {
    data: unapprovedItemsData,
    loading,
    updateQuery: updateQueryItemList,
  } = useTypedQuery(
    {
      ItemList: [
        {
          sortBy: "name",
          order: Ordering.DESC,
          approved: $`approvedFilter`,
        },
        {
          items: {
            id: true,
            headerImageKey: true,
            name: true,
            description: true,
            approved: true,
            menu: {
              id: true,
              restaurant: {
                id: true,
                name: true,
              },
            },
          },
        },
      ],
    },
    {
      variables: {
        approvedFilter,
      },
      pollInterval: 10000,
    }
  );

  const [updateItemApproved, { loading: loadingItemUpdate }] = useTypedMutation(
    {
      updateItem: [
        {
          id: $`itemId`,
          item: {
            approved: $`approved`,
          },
        },
        {
          id: true,
          approved: true,
        },
      ],
    },
    {
      onCompleted: (d) => {
        message.success(
          `Changed Item to be ${d.updateItem.approved ? "Approved" : "Unapproved"
          }`
        );
        updateQueryItemList((prevQuery) => {
          const updatedItems = prevQuery.ItemList.items.map((item) => {
            return item.id === d.updateItem.id
              ? {
                ...item,
                approved: d.updateItem.approved,
              }
              : item;
          });
          return {
            ...prevQuery,
            ItemList: {
              ...prevQuery.ItemList,
              items: updatedItems,
            }
          };
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onChangeApproved = useCallback(
    (id: string, approved: boolean) => {
      updateItemApproved({
        variables: {
          itemId: id,
          approved,
        },
      });
    },
    [updateItemApproved]
  );

  return (
    <>
      <Card title="Item Creation Requests">
        <Table
          loading={loading}
          dataSource={unapprovedItemsData?.ItemList.items}
          onChange={(p, f) => {
            if (f["approved"]) {
              if (f["approved"].length === 2) {
                // both approved and unapproved
                setApprovedFilter(undefined);
              } else {
                setApprovedFilter(f["approved"][0] === "true");
              }
            }
          }}
          columns={[
            {
              title: "Partner",
              width: 150,
              render: (row) =>
                row.menu?.restaurant ? (
                  <Link to={`/restaurant/${row.menu.restaurant.id}`}>
                    {" "}
                    {row.menu?.restaurant.name}
                  </Link>
                ) : null,
            },
            {
              title: "Name",
              render: (r) => (r.name ? <span>{r.name}</span> : null),
            },
            {
              title: "Description",
              render: (r) =>
                r.description ? <span>{r.description}</span> : null,
            },
            {
              title: "Image",
              dataIndex: "headerImageKey",
              render: (headerImageKey) => {
                return (
                  headerImageKey && (
                    <a href={getImageUrl(headerImageKey)} target="_blank">
                      <img
                        style={{
                          width: 128,
                          height: 128,
                        }}
                        src={getImageUrl(headerImageKey, 256, 256)}
                      />
                    </a>
                  )
                );
              },
            },
            {
              title: "Status",
              key: "approved",
              filters: [
                {
                  text: "Approved",
                  value: "true",
                },
                {
                  text: "Unapproved",
                  value: "false",
                },
              ],
              defaultFilteredValue: ["false"],
              render: (r) =>
                r ? (
                  <Select
                    loading={loadingItemUpdate}
                    onChange={(v) => onChangeApproved(r.id, v === "true")}
                    value={r.approved.toString()}
                  >
                    <Select.Option key="unapproved" value="true">
                      &nbsp;
                      <Badge text={"Approved"} status="success" />
                    </Select.Option>
                    <Select.Option key="approved" value="false">
                      &nbsp;
                      <Badge text={"Unapproved"} status="warning" />
                    </Select.Option>
                  </Select>
                ) : null,
            },
          ]}
        />
      </Card>
    </>
  );
};

export default ItemCreationRequest;
