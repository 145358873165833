import { MailFilled } from "@ant-design/icons";
import { Badge, Button, Drawer, Form, Input, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ChatFeed, Message } from "react-chat-ui";
import { UserType } from "../../../graphql/generated";
import { useTypedMutation, useTypedQuery } from "../../../graphql/hooks";
import { ME } from "../../../graphql/queries";
import { ORDER_MESSAGES_LIST, SEND_ORDER_MESSAGE } from "./queries";

/**
 * Messages relating to a specific order
 */
export default function OrderMessages({ orderId }) {
  const { data: me } = useTypedQuery(ME);
  const [form] = useForm();

  const [show, setShow] = useState(false);

  const { data, refetch } = useTypedQuery(ORDER_MESSAGES_LIST, {
    variables: {
      orderId,
      isReading: show,
    },
    pollInterval: 3000,
  });

  const [sendMessage, { loading }] = useTypedMutation(SEND_ORDER_MESSAGE, {
    onCompleted: (d) => {
      refetch();
      form.setFieldsValue({ message: "", viewableBy: null });
    },
  });

  const divContainerRef = useRef<HTMLDivElement | null>(null);

  const messages = useMemo<Message[]>(
    () =>
      data?.ChatMessageList.items
        .map((m) => ({
          id: m.sender.id === me?.me.id ? 0 : m.sender.id,
          message: (
            <>
              <div className="order-message">
                <span className="order-message-main">{m.text}</span>
              </div>
              <span className="order-message-meta">
                {new Date(m.sent).toLocaleTimeString()}
                {m.viewableBy ? ` | Private: ${m.viewableBy}` : ""}
              </span>
            </>
          ) as any,
          senderName: m.sender.name,
        }))
        .reverse() || [],
    [data, me?.me.id]
  );

  useEffect(() => {
    if (show) {
      divContainerRef.current
        ?.querySelector("#chat-panel")
        ?.scrollIntoView(false);
    } else {
      document.querySelector(".main-sidebar")?.scrollIntoView(false);
    }
  }, [messages, divContainerRef.current, show]);

  const onFinish = useCallback(
    ({ message, viewableBy }) => {
      if (!message || loading) return;
      sendMessage({
        variables: {
          orderId,
          text: message,
          viewableBy,
        },
      });
    },
    [orderId, loading]
  );

  return (
    <>
      <div style={{ position: "sticky", bottom: 32, right: 32 }}>
        <Badge count={data?.ChatMessageList.unreadCount}>
          <Button
            shape="circle"
            size="large"
            onClick={() => setShow(true)}
            icon={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MailFilled color="white" />
              </div>
            }
            type="primary"
          ></Button>
        </Badge>
      </div>

      <Drawer visible={show} width={400} onClose={() => setShow(false)}>
        <div
          ref={divContainerRef}
          style={{
            height: "75%",
            paddingRight: 16,
            overflowY: "scroll",
          }}
        >
          <ChatFeed
            messages={messages} // Array: list of message objects
            isTyping={false} // Boolean: is the recipient typing
            hasInputField={false}
            showSenderName
            bubblesCentered={false}
            bubbleStyles={{
              chatbubble: {
                maxWidth: "100%",
              },
              userBubble: {
                color: "red",
              },
            }}
          />
        </div>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item name="viewableBy" label="Private To">
            <Select
              allowClear
              options={Object.keys(UserType).map((k) => ({
                label: k,
                value: k,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="message"
            label="Reply"
            rules={[
              {
                required: true,
                max: 240,
              },
            ]}
          >
            <Input disabled={loading} />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Send
          </Button>
        </Form>
      </Drawer>
    </>
  );
}
