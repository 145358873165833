import gql from "graphql-tag";
import { $, Selectors } from "./generated/graphql-zeus";
import { query } from "./hooks";

export const ALL_RESTAURANTS = gql`
  query getAllRestaurants($lat: Float!, $lng: Float!) {
    allRestaurants(lat: $lat, lng: $lng) {
      id
      name
      description
      branch
      busyStatus
      commission
      lastOnline
      online
      onboardingStatus
      address {
        id
        postcode
        coordinates {
          coordinates
        }
      }
    }
  }
`;

export const RESTAURANT = query({
  Restaurant: [
    { id: $`id` },
    {
      id: true,
      name: true,
      description: true,
      branch: true,
      online: true,
      slug: true,
      headerImage: true,
      isReusablesEnabled: true,
      headerImageKey: true,
      configuration: true,
      lastOnline: true,
      exclusive: true,
      busyStatus: true,
      fulfillmentMethods: true,
      acceptsScheduledOrders: true,
      onboardingStatus: true,
      basePrepTime: true,
      phoneNumber: true,
      baseDeliveryTime: true,
      commission: true,
      commissionCollection: true,
      incrementPrepTime: true,
      allergyWarnings: true,
      maxDeliveryDistanceMetres: true,
      ratingStars: true,
      ratingCount: true,
      url: true,
      headerVideo: {
        id: true,
        key: true,
      },
      brand: {
        id: true,
        description: true,
        name: true,
        slug: true,
        logo: {
          id: true,
          key: true,
        },
      },
      external: {
        id: true,
        name: true,
        raw: true,
      },
      hygiene: {
        id: true,
        rating: true,
        authorityRatingImage: true,
        authorityRatingId: true,
        authorityRatingLink: true,
      },
      openingTimes: {
        id: true,
        open: true,
        close: true,
        daysOfWeek: true,
      },
      tags: {
        id: true,
        name: true,
        image: true,
        type: true,
      },
      address: {
        id: true,
        address_line_1: true,
        town: true,
        country: true,
        instructions: true,
        flat_number: true,
        postcode: true,
        coordinates: {
          coordinates: true,
        },
      },
      zone: {
        id: true,
        name: true,
        slug: true,
        city: {
          id: true,
          name: true,
        },
      },
      owner: {
        id: true,
        name: true,
        email: true,
      },
      menuId: true,
    },
  ],
});

export const _RESTAURANT = gql`
  query Restaurant($id: String!) {
    Restaurant(id: $id) {
      id
      name
      description
      branch
      online
      slug
      headerImage
      lastOnline
      exclusive
      busyStatus
      fulfillmentMethods
      onboardingStatus
      basePrepTime
      phoneNumber
      baseDeliveryTime
      commission
      commissionCollection
      incrementPrepTime
      allergyWarnings
      ratingStars
      ratingCount
      external {
        id
        name
        raw
      }
      hygiene {
        id
        rating
        authorityRatingImage
        authorityRatingId
        authorityRatingLink
      }
      openingTimes {
        id
        open
        close
        daysOfWeek
      }
      tags {
        id
        name
        image
        type
      }
      address {
        id
        postcode
        coordinates {
          coordinates
        }
      }
      zone {
        id
        name
        slug
      }
      owner {
        id
        name
        email
      }
      menuId
    }
  }
`;

export const SEARCH_QUERY = query({
  ConsumerSearch: [
    {
      location: {
        zoneId: $`zoneId`,
      },
      query: $`query`,
    },
    {
      items: {
        item: {
          id: true,
          name: true,
          categoryId: true,
          headerImageKey: true,
          menu: {
            id: true,
            restaurant: {
              id: true,
              name: true,
              slug: true,
              ratingCount: true,
              ratingStars: true,
              currentDeliveryTime: true,
            },
          },
        },
        relevance: true,
      },
      partners: {
        item: {
          id: true,
          slug: true,
          headerImageKey: true,
          name: true,
          ratingCount: true,
          ratingStars: true,
          tags: {
            name: true,
          },
        },
        relevance: true,
      },
    },
  ],
});

const ModifierGroupFragments = query({
  Menu: [
    { id: "" },
    {
      modifierGroups: {
        id: true,
        name: true,
        instruction: true,
        maxOptions: true,
        maxPerOption: true,
        minOptions: true,
        cascades: true,
        sortOrder: true,
        priceStrategy: true,

        options: {
          id: true,
          name: true,
          available: true,
          published: true,
          description: true,
          unitPrice: true,
          categoryId: true,
          headerImage: true,
          sortOrder: true,
        },
      },
    },
  ],
}).Menu[1].modifierGroups;

export const ME = query({
  me: {
    "...on Customer": {
      id: true,
    },
    "...on RestaurantAdmin": {
      id: true,
      email: true,
      roles: true,
      name: true,
    },
    "...on Rider": {
      id: true,
    },
  },
});

export const MENU = query({
  Menu: [
    {
      id: $`id`,
    },
    {
      id: true,
      name: true,
      categories: {
        id: true,
        name: true,
        description: true,
        topLevel: true,
        sortOrder: true,
        items: {
          id: true,
          available: true,
          headerImageKey: true,
          sortOrder: true,
          name: true,
          approved: true,
          emissions: true,
          vegetarian: true,
          deletedAt: true,
          heat: true,
          maxPerOrder: true,
          tax: true,
          weight: true,
          popular: true,
          slow: true,
          unavailableFor: true,
          containerType: {
            id: true,
            depth: true,
            width: true,
            length: true,
            name: true,
          },
          description: true,
          ingredients: true,
          published: true,
          unitPrice: true,
          vegan: true,
          alcohol: true,
          categoryId: true,
          headerImage: true,
          modifiers: {
            ...ModifierGroupFragments,
          },
        },
        times: {
          id: true,
          open: true,
          close: true,
          daysOfWeek: true,
        },
      },
      modifierGroups: {
        ...ModifierGroupFragments,
      },
    },
  ],
});

export const ALL_ANALYTICS = gql`
  query allAnalytics($start: Date!, $end: Date!) {
    allAnalytics(start: $start, end: $end) {
      totalOrders
      customers
      totalRestaurants
    }
  }
`;

export const ONLINE_RIDERS = query({
  RiderList: [
    {
      sortBy: $`sortBy`,
      order: $`order`,
      skip: $`skip`,
      take: $`take`,
      zoneId: $`zoneId`,
      activationStatus: $`activationStatus`,
      online: true,
      query: $`query`,
    },
    {
      items: {
        id: true,
        online: true,
        lastOnline: true,
        throttledUntil: true,
      },
      total: true,
      hasMore: true,
    },
  ],
});

export const RIDER_LIVE_UI = query({
  RiderLiveUI: [
    {
      riderId: $`riderId`,
    },
    {
      layoutType: true,
      action: {
        id: true,
        text: true,
        color: true,
        allowMaxDistanceOverride: true,
        maxDistanceInMetres: true,
        disabled: true,
        areYouSure: true,
        explanation: true,
        orderId: true,
        title: true,
        validationPrompt: {
          id: true,
          title: true,
          validateAge: {
            customerDOBShouldBeLessThanOrEqualTo: true,
          },
          canBeTemporarilyDismissed: true,
          buttons: {
            text: true,
            color: true,
            type: true,
          },
        },
      },
      banners: {
        id: true,
        color: true,
        isDismissible: true,
        message: true,

        title: true,
      },
      job: {
        feeFormatted: true,
        feeModifiedExplanation: true,
        orders: {
          id: true,
          number: true,
          numberOfItems: true,
          tipFormatted: true,
          estimatedReadyAt: true,
          isReadyToCollect: true,
          destination: {
            title: true,
            coordinates: true,
            formatted: true,
            phoneNumber: true,
          },
          origin: {
            title: true,
            coordinates: true,
            formatted: true,
            phoneNumber: true,
          },
          items: {
            id: true,
            name: true,
            quantity: true,
          },
          support: {
            actions: {
              id: true,
              title: true,
              explanation: true,
              areYouSure: true,
              allowMaxDistanceOverride: true,
              disabled: true,
              maxDistanceInMetres: true,
              color: true,
              text: true,
              orderId: true,
            },
          },
        },
        directions: {
          distance: true,
          duration: true,
          geometry: true,
          steps: {
            id: true,
            geometry: true,
          },
        },
        currentDestination: {
          title: true,
          coordinates: true,
          formatted: true,
          phoneNumber: true,
        },
      },
      offer: {
        id: true,
        offeredAt: true,
        offeredUntil: true,
        destinations: {
          id: true,
          coordinates: true,
          formatted: true,
          phoneNumber: true,
          title: true,
        },
        origins: {
          id: true,
          coordinates: true,
          formatted: true,
          phoneNumber: true,
          title: true,
        },
      },
      support: {
        supportPhoneNumber: true,
        supportURL: true,
      },
      settings: {
        id: true,
        initialValue: true,
        schema: true,
      },
    },
  ],
});

export const RIDER_FLIGHT_PLAN = query({
  Rider: [
    {
      id: $`id`,
    },
    {
      id: true,
      name: true,
      vehicle: true,
      position: {
        type: true,
        coordinates: true,
      },
      activeFlightPlan: {
        id: true,
        createdAt: true,
        activeOffer: {
          id: true,
          offered: true,
          outcome: true,
        },
        legs: {
          id: true,
          legOrder: true,
          address: {
            id: true,
            address_line_1: true,
            flat_number: true,
            postcode: true,
            coordinates: {
              type: true,
              coordinates: true,
            },
          },
          order: {
            id: true,
            number: true,
            status: true,
            riderPickedUpTime: true,
            pickupAt: true,
            initialPickupAt: true,
            lastOfferedToRider: true,
            isBeingOfferedToRider: true,
            address: {
              id: true,
              address_line_1: true,
              postcode: true,
              coordinates: {
                coordinates: true,
                type: true,
              },
            },
            restaurant: {
              id: true,
              name: true,
              address: {
                id: true,
                coordinates: {
                  coordinates: true,
                  type: true,
                },
              },
            },
          },
        },
      },
    },
  ],
});

export const OFFER_LIST = query({
  FlightPlanOfferList: [
    {
      sortBy: $`sortBy`,
      order: $`order`,
      skip: $`skip`,
      take: $`take`,
      query: $`query`,
      includeCompleted: $`includeCompleted`,
      orderId: $`orderId`,
      riderId: $`riderId`,
      zoneId: $`zoneId`,
    },
    {
      total: true,
      items: {
        id: true,
        changed: true,
        offered: true,
        sneaky: true,
        fee: true,
        context: true,
        outcome: true,
        rider: {
          id: true,
          name: true,
        },
        orders: {
          id: true,
          number: true,
          restaurant: {
            id: true,
            name: true,
          },
        },
      },
    },
  ],
});

export const ALL_RIDERS = query({
  RiderList: [
    {
      sortBy: $`sortBy`,
      order: $`order`,
      skip: $`skip`,
      take: $`take`,
      zoneId: $`zoneId`,
      activationStatus: $`activationStatus`,
      online: $`online`,
      query: $`query`,
    },
    {
      items: {
        id: true,
        name: true,
        email: true,
        phoneNumber: true,
        profileImageKey: true,
        roles: true,
        note: true,
        created: true,
        position: {
          type: true,
          coordinates: true,
        },
        application: {
          id: true,
        },
        online: true,
        lastOnline: true,
        throttledUntil: true,
        activationStatus: true,
        vehicle: true,
        zone: {
          id: true,
          name: true,
        },
      },
      total: true,
      hasMore: true,
    },
  ],
});

export const _ALL_RIDERS = gql`
  query allRiders(
    $sortBy: String!
    $order: Ordering!
    $skip: Int
    $take: Int
    $zoneId: String!
    $activationStatus: RiderActivationStatus!
    $online: Boolean
    $query: String
  ) {
    allRiders(
      sortBy: $sortBy
      order: $order
      skip: $skip
      take: $take
      zoneId: $zoneId
      activationStatus: $activationStatus
      online: $online
      query: $query
    ) {
      id
      name
      email
      phoneNumber
      roles
      note
      position {
        type
        coordinates
      }
      online
      lastOnline
      throttledUntil
      activationStatus
      vehicle
    }
  }
`;

export const BASKET = query({
  Basket: [
    {
      id: $`id`,
    },
    {
      id: true,
      donationTotal: true,
      reusablesFee: true,
      reusablesDeposit: true,
      items: {
        id: true,
        name: true,
        quantity: true,
        totalPrice: true,
        modifierItems: {
          id: true,
          name: true,
          quantity: true,
        },
      },
    },
  ],
});

export const ORDERS_FRAGMENT = gql`
  fragment OrderFragment on Order {
    id
    number
    placedAt
    pickupAt
    status
    riderEarnings
    isBeingOfferedToRider
    baseRiderEarnings
    pickupBlocked
    amountRefunded
    fulfillmentMethod
    deliveryEstimate
    deliveryEstimateMax
    basket {
      id
      location {
        coordinates
      }
      scheduledDelivery
      targetDeliveryFromNow
      restaurantId
      riderTip
      subtotal
      deliveryFee
      sessionId
      totalPrice
      totalPriceBeforeDiscount
    }
    rider {
      name
    }
    b2bDeliveryJobId
    b2bDeliveryJob {
      id
    }
  }
`;

export const ADMINS_LIST = query({
  RestaurantAdminList: [
    {
      sortBy: $`sortBy`,
      order: $`order`,
      skip: $`skip`,
      take: $`take`,
      query: $`query`,
    },
    {
      total: true,
      items: {
        id: true,
        name: true,
        email: true,
        roles: true,
      },
    },
  ],
});

export const CUSTOMER_LIST = query({
  CustomerList: [
    {
      sortBy: $`sortBy`,
      order: $`order`,
      skip: $`skip`,
      take: $`take`,
      query: $`query`,
    },
    {
      total: true,
      items: {
        id: true,
        name: true,
        email: true,
        marketing: true,
        emailConfirmed: true,
      },
    },
  ],
});

export const MARKETING_CAMPAIGN_LIST = query({
  MarketingCampaignList: [
    {
      sortBy: $`sortBy`,
      order: $`order`,
      skip: $`skip`,
      take: $`take`,
      query: $`query`,
      startDate: $`startDate`,
    },
    {
      total: true,
      items: {
        id: true,
        name: true,
        status: true,
        scheduledFor: true,
        sends: true,
      },
    },
  ],
});

export const ADMIN = query({
  RestaurantAdmin: [
    {
      id: $`id`,
    },
    {
      id: true,
      name: true,
      email: true,
      administeredRestaurant: {
        id: true,
        name: true,
      },
      phoneNumber: true,
      roles: true,
      linkedCustomerAccount: {
        id: true,
        name: true,
        email: true,
      },
      b2bJobs: {
        id: true,
        placed: true,
      },
      restaurants: {
        id: true,
        name: true,
        zone: {
          id: true,
          name: true,
        },
      },
    },
  ],
});

export const ORDER_LIST = query({
  OrderList: [
    {
      sortBy: $`sortBy`,
      order: $`order`,
      skip: $`skip`,
      take: $`take`,
      customerId: $`customerId`,
      status: $`status`,
      zoneId: $`zoneId`,
      riderId: $`riderId`,
      query: $`query`,
      restaurantId: $`restaurantId`,
      fulfillmentMethods: $`fulfillmentMethods`,
    },
    {
      total: true,
      hasMore: true,
      items: {
        id: true,
        number: true,
        placedAt: true,
        pickupAt: true,
        pickupDelayedByPartner: true,
        initialPickupAt: true,
        orderMarkedReadyAt: true,
        status: true,
        scheduledFor: true,
        cancellationReason: true,
        riderEarnings: true,
        isBeingOfferedToRider: true,
        isRiderAtRestaurant: true,
        baseRiderEarnings: true,
        riderPickedUpTime: true,
        pickupBlocked: true,
        amountRefunded: true,
        partnerAcknowlegedAt: true,
        fulfillmentMethod: true,
        deliveryEstimate: true,
        estimatedArrivalTime: true,
        estimatedArrivalTimeDelta: true,
        riderArrivedAtRestaurantTime: true,
        riderArrivedAtCustomerTime: true,
        riderDroppedOffTime: true,
        deliveryEstimateMax: true,
        unreadMessageCount: true,
        reusables: {
          id: true,
          predictionCount: true,
          partnerCount: true,
        },
        customer: {
          id: true,
          name: true,
          email: true,
        },
        address: {
          id: true,
          address_line_1: true,
          flat_number: true,
          postcode: true,
          coordinates: {
            type: true,
            coordinates: true,
          },
          instructions: true,
        },
        restaurant: {
          id: true,
          name: true,
          configuration: true,
          zone: {
            id: true,
            slug: true,
            name: true,
            standardDeliveryDistanceMetres: true,
          },
        },
        basket: {
          id: true,
          location: {
            coordinates: true,
          },
          alcohol: true,
          instructions: true,
          distanceDuration: true,
          scheduledDelivery: true,
          targetDeliveryFromNow: true,
          restaurantId: true,
          riderTip: true,
          subtotal: true,
          deliveryFee: true,
          sessionId: true,
          totalPrice: true,
          totalPriceBeforeDiscount: true,
        },
        rider: {
          id: true,
          name: true,
          vehicle: true,
        },
        b2bDeliveryJobId: true,
        b2bDeliveryJob: {
          id: true,
        },
        aggregateOrder: {
          id: true,
          orders: {
            id: true,
            number: true,
          },
        },
      },
    },
  ],
});
export const _ORDER_LIST = gql`
  query OrderList(
    $sortBy: String!
    $order: Ordering!
    $skip: Int
    $take: Int
    $customerId: String
    $status: [OrderStatus!]
    $zoneId: String
    $query: String
  ) {
    OrderList(
      sortBy: $sortBy
      order: $order
      skip: $skip
      take: $take
      customerId: $customerId
      status: $status
      zoneId: $zoneId
      query: $query
    ) {
      ...OrderFragment
      restaurant {
        name
        id
      }
    }
  }
  ${ORDERS_FRAGMENT}
`;

export const ALL_CITIES = gql`
  query allCities($skip: Int, $take: Int) {
    allCities(skip: $skip, take: $take) {
      id
      name
      zones {
        id
        name
        polygon {
          type
          coordinates
        }
      }
    }
  }
`;

export const CITY = gql`
  query city($id: String!) {
    city(id: $id) {
      id
      name
      zones {
        id
        name
        onboardingStatus
        busyStatus
        polygon {
          type
          coordinates
        }
        baseDeliveryFee
        feePerKM
        baseRiderEarnings
        riderEarningsPerKM
      }
    }
  }
`;

export const S3_URL = gql`
  query fileUploadUrl {
    generateFileUploadUrl
  }
`;

export const RESTAURANT_ORDERS = gql`
  query allOrdersByRestaurant(
    $sortBy: String!
    $order: Ordering!
    $skip: Int
    $take: Int
    $id: String!
  ) {
    allOrdersByRestaurant(
      sortBy: $sortBy
      order: $order
      skip: $skip
      take: $take
      id: $id
    ) {
      ...OrderFragment
    }
  }
  ${ORDERS_FRAGMENT}
`;

export const ORDER = query({
  order: [
    {
      id: $`id`,
    },
    {
      id: true,
      status: true,
      cancellationReason: true,
      number: true,
      pickupBlocked: true,
      partnerAcknowlegedAt: true,
      fulfillmentMethod: true,
      isBeingOfferedToRider: true,
      isRiderAtRestaurant: true,
      amountRefunded: true,
      stripeFee: true,
      scheduledFor: true,
      estimatedArrivalTime: true,
      canTipRider: true,
      estimatedArrivalTimeDelta: true,
      initialPickupAt: true,
      lastOfferedToRider: true,
      riderArrivedAtCustomerTime: true,
      riderArrivedAtRestaurantTime: true,
      riderDroppedOffTime: true,
      paymentIntentId: true,
      riderEarnings: true,
      baseRiderEarnings: true,
      placedAt: true,
      allergyNotes: true,
      pickupAt: true,
      riderPickedUpTime: true,
      internalNote: true,
      partnerRating: true,
      riderRating: true,

      ourAdjustment: true,
      partnerAdjustment: true,
      riderEarningsAdjustment: true,
      riderTipAdjustment: true,

      ourAdjustmentJustification: true,
      partnerAdjustmentJustification: true,
      riderEarningsAdjustmentJustification: true,
      riderTipAdjustmentJustification: true,

      customerLastRemindedToPickUp: true,
      longWaitReportedAt: true,
      sizePoints: true,
      parentOrderId: true,
      refundedReason: true,

      riderReport: {
        note: true,
        positive: true,
        ratings: true,
      },
      reusables: {
        id: true,
        customerCount: true,
        partnerCount: true,
        predictionCount: true,
        riderCount: true,
      },
      reusablesPickupAggregate: {
        id: true,
        washingLocation: {
          id: true,
          name: true,
          status: true,
        },
      },
      aggregateOrder: {
        id: true,
        status: true,
        orders: {
          id: true,
          number: true,
          status: true,
          restaurant: {
            id: true,
            name: true,
          },
        },
      },
      restaurant: {
        id: true,
        name: true,
        address: {
          address_line_1: true,
          flat_number: true,
          postcode: true,
          coordinates: {
            coordinates: true,
          },
        },
      },
      rider: {
        id: true,
        name: true,
        vehicle: true,
        position: {
          coordinates: true,
        },
        phoneNumber: true,
      },
      customer: {
        id: true,
        name: true,
        email: true,
      },
      basket: {
        id: true,
        paymentIntentId: true,
        tableNumber: true,
        instructions: true,
        alcohol: true,
        distanceDuration: true,
        numberOfItems: true,
        weight: true,
        subtotal: true,
        smallOrderFee: true,
        deliveryFee: true,
        riderTip: true,
        totalPriceBeforeDiscount: true,
        totalPrice: true,
      },
      address: {
        id: true,
        address_line_1: true,
        flat_number: true,
        postcode: true,
        phone_number: true,
        coordinates: {
          coordinates: true,
        },
      },
      b2bDeliveryJob: {
        id: true,
        created: true,
        destination: {
          id: true,
          address_line_1: true,
          postcode: true,
          coordinates: {
            coordinates: true,
          },
          phone_number: true,
        },
        origin: {
          id: true,
          address_line_1: true,
          postcode: true,
          coordinates: {
            coordinates: true,
          },
          phone_number: true,
        },
        placedBy: {
          id: true,
          name: true,
          email: true,
        },
        scheduledFor: true,
        size: true,
        price: true,
      },
    },
  ],
});
export const PROMOTION_LIST = query({
  PromotionList: [
    {
      order: $`order`,
      sortBy: $`sortBy`,
      skip: $`skip`,
      take: $`take`,
      start: $`start`,
      zoneId: $`zoneId`,
      query: $`query`,
    },
    {
      total: true,
      items: {
        id: true,
        name: true,
        created: true,
        imageKey: true,
        color: true,
        body: true,
        callToActionText: true,
        end: true,
        modified: true,
        start: true,
        target: true,
        targetType: true,
        title: true,
        enabled: true,
        zones: {
          id: true,
          name: true,
        },
      },
    },
  ],
});

export const CHARITY_CAMPAIGN_LIST = query({
  CharityCampaignList: [
    {
      order: $`order`,
      sortBy: $`sortBy`,
      skip: $`skip`,
      take: $`take`,
      query: $`query`,
      charityId: $`charityId`,
    },
    {
      total: true,
      items: {
        id: true,
        title: true,
        description: true,
        startDate: true,
        endDate: true,
        progress: true,
        goal: true,
        goalPrefix: true,
        goalSuffix: true,
        campaignLogo: {
          id: true,
          url: [
            {
              width: 256,
              height: 256,
            },
            true,
          ],
        },
        charity: {
          id: true,
          name: true,
          logo: {
            id: true,
            url: [
              {
                width: 256,
                height: 256,
              },
              true,
            ],
          },
        },
      },
    },
  ],
});

export const CHARITY_LIST = query({
  CharityList: [
    {
      order: $`order`,
      sortBy: $`sortBy`,
      skip: $`skip`,
      take: $`take`,
      query: $`query`,
    },
    {
      total: true,
      items: {
        id: true,
        name: true,
        logo: {
          id: true,
          url: [
            {
              width: 256,
              height: 256,
            },
            true,
          ],
        },
      },
    },
  ],
});

export const CHARITY_FINANCIAL_OVERVIEW = query({
  FinancialOverview: [
    {
      endDate: $`endDate`,
      startDate: $`startDate`,
      zoneIds: $`zoneIds`,
    },
    {
      meta: {
        endDate: true,
        startDate: true,
        zoneIds: true,
      },
      charities: {
        id: true,
        donationTotal: true,
        charity: {
          id: true,
          name: true,
        },
        campaigns: {
          id: true,
          donationTotal: true,
          campaign: {
            id: true,
            title: true,
          },
        },
      },
    },
  ],
});

export const RIDERS_FINANCIAL_OVERVIEW = query({
  FinancialOverview: [
    {
      endDate: $`endDate`,
      startDate: $`startDate`,
      zoneIds: $`zoneIds`,
    },
    {
      meta: {
        endDate: true,
        startDate: true,
        zoneIds: true,
      },
      riders: {
        id: true,
        numberOfOrders: true,
        extraFromSlots: true,
        riderEarningsSum: true,
        extraFromReferrals: true,
        totalPayout: true,
        riderTips: true,
        adjustments: true,
        rider: {
          id: true,
          name: true,
        },
      },
    },
  ],
});

export const CHARITY = query({
  Charity: [
    { id: $`id` },
    {
      id: true,
      name: true,
      logo: {
        id: true,
        url: [
          {
            width: 1920,
          },
          true,
        ],
      },
    },
  ],
});

export const CHARITY_CAMPAIGN = query({
  CharityCampaign: [
    { id: $`id` },
    {
      id: true,
      title: true,
      charity: {
        id: true,
        name: true,
      },
      costPerGoalUnit: true,
      endDate: true,
      startDate: true,
      goal: true,
      goalPrefix: true,
      goalSuffix: true,
      description: true,
      campaignLogo: {
        id: true,
        url: [
          {
            width: 1920,
          },
          true,
        ],
      },
    },
  ],
});

export const IMAGE_ASSET_LIST = query({
  ImageAssetList: [
    {
      order: $`order`,
      sortBy: $`sortBy`,
      skip: $`skip`,
      take: $`take`,
      query: $`query`,
    },
    {
      total: true,
      items: {
        id: true,
        title: true,
        altText: true,
        height: true,
        fileSize: true,
        created: true,
        blurhash: true,
        width: true,
        key: true,
        mimeType: true,
        uploadedBy: {
          id: true,
          email: true,
          name: true,
        },
        url: [
          {
            width: $`width`,
            height: $`height`,
            gravity: $`gravity`,
          },
          true,
        ],
      },
    },
  ],
});

export const FILE_LIST = query({
  FileList: [
    {
      order: $`order`,
      sortBy: $`sortBy`,
      skip: $`skip`,
      take: $`take`,
      query: $`query`,
    },
    {
      total: true,
      items: {
        id: true,
        title: true,
        size: true,
        createdAt: true,
        type: true,

        url: true,
      },
    },
  ],
});

export const VIDEO_ASSET_LIST = query({
  VideoAssetList: [
    {
      order: $`order`,
      sortBy: $`sortBy`,
      skip: $`skip`,
      take: $`take`,
      query: $`query`,
    },
    {
      total: true,
      items: {
        id: true,
        title: true,
        altText: true,
        height: true,
        fileSize: true,
        created: true,
        width: true,
        key: true,
        mimeType: true,
        uploadedBy: {
          id: true,
          email: true,
          name: true,
        },
      },
    },
  ],
});

export const BASKET_LIST = query({
  BasketList: [
    {
      order: $`order`,
      sortBy: $`sortBy`,
      skip: $`skip`,
      take: $`take`,
      query: $`query`,
      modifiedInLast: $`modifiedInLast`,
      complete: $`complete`,
      zoneIds: $`zoneIds`,
    },
    {
      total: true,
      items: {
        id: true,
        numberOfItems: true,
        totalPrice: true,
        fulfillmentMethod: true,
        distanceDuration: true,
        paymentIntentId: true,
        checkedOutAt: true,
        restaurant: {
          id: true,
          name: true,
        },
        customer: {
          id: true,
          name: true,
        },
      },
    },
  ],
});

export const ZONE = query({
  zone: [
    {
      id: $`id`,
    },
    {
      id: true,
      name: true,
      baseDeliveryFee: true,
      baseRiderEarnings: true,
      busyStatus: true,
      busyWeight: true,
      feePerKM: true,
      activePolygonId: true,
      currentDeliveryDistanceMetres: true,
      minDeliveryDistanceMetres: true,
      standardDeliveryDistanceMetres: true,
      hardLimitDeliveryDistanceMetres: true,
      liveView: {
        activeRiders: true,
        unassignedRiders: true,
      },
      weather: {
        updated: true,
        result: true,
      },

      autoBookingSlotConfigId: true,
      openingTimes: {
        id: true,
        close: true,
        open: true,
        daysOfWeek: true,
      },
      onboardingStatus: true,
      polygon: {
        coordinates: true,
        type: true,
      },
      riderEarningsPerKM: true,
      timeZone: true,
      slug: true,
      city: {
        id: true,
        name: true,
      },
      boosts: {
        id: true,
        start: true,
        end: true,
        type: true,
        multiplier: true,
        notified: true,
      },
      currentBoost: {
        id: true,
        start: true,
        end: true,
        multiplier: true,
        notified: true,
      },
    },
  ],
});

export const RIDER = query({
  Rider: [
    {
      id: $`id`,
    },
    {
      id: true,
      name: true,
      email: true,
      phoneNumber: true,
      profileImageKey: true,
      isPhoneNumberVerified: true,
      roles: true,
      lastOnline: true,
      throttledUntil: true,
      note: true,
      online: true,
      accountNo: true,
      sortCode: true,
      carRegistration: true,

      vehicleDetails: {
        id: true,
        details: true,
        updated: true,
      },
      zone: {
        id: true,
        name: true,
        polygon: {
          type: true,
          coordinates: true,
        },
      },
      position: {
        type: true,
        coordinates: true,
      },
      address: {
        id: true,
        address_line_1: true,
        postcode: true,
      },
      activationStatus: true,
      vehicle: true,
      invoices: {
        start: true,
        end: true,
        value: true,
        paid: true,
        id: true,
        orders: true,
        revolutTransactionId: true,
        revolutTransactionLink: true,
        url: true,
      },

      statistics: {
        riderId: true,
        averageRating: true,
        acceptedOrders: true,
        rejectedOrders: true,
        timedoutOrders: true,
      },
    },
  ],
});

export const ZONE_LIST = query({
  ZoneList: [
    {
      order: $`order`,
      sortBy: $`sortBy`,
      take: $`take`,
      skip: $`skip`,
      query: $`query`,
    },
    {
      hasMore: true,
      total: true,
      items: {
        id: true,
        name: true,
        city: {
          id: true,
          name: true,
        },
      },
    },
  ],
});

export const RIDER_ONLINE_SESSIONS = query({
  RiderOnlineSessionList: [
    {
      order: $`order`,
      sortBy: $`sortBy`,
      take: $`take`,
      skip: $`skip`,
      riderId: $`riderId`,
    },
    {
      hasMore: true,
      total: true,
      items: {
        id: true,
        path: {
          coordinates: true,
          type: true,
        },
        start: true,
        end: true,
        riderId: true,
        updated: true,
        rider: {
          id: true,
          name: true,
        },
      },
    },
  ],
});

export const RESTAURANT_FINANCIALS = query({
  financialsForRestaurant: [
    {
      restaurantId: $`restaurantId`,
      startDate: $`startDate`,
      endDate: $`endDate`,
    },
    {
      restaurantId: true,
      restaurant: {
        name: true,
      },
      restaurantSalesTotal: true,
      deliveryFeeTotal: true,
      overallTotal: true,
      purchasesFromUsTotal: true,
      numberOfOrders: true,
      riderEarnings: true,
      riderTips: true,
      adjustmentsTotal: true,
      amountRefunded: true,
      accountCreditTotal: true,
      donationToCharityTotal: true,
      taxPercent: true,
      commissionBeforeAddedTax: true,
      taxFromCommission: true,
      commissionTotal: true,
      toPayRestaurant: true,
      discountsTotal: true,
      smallOrderFeeTotal: true,
      stripeFeeTotal: true,
      ourProfit: true,
    },
  ],
});

export const RESTAURANTS_FINANCIALS = query({
  financialsForRestaurants: [
    {
      startDate: $`startDate`,
      endDate: $`endDate`,
    },
    {
      ...RESTAURANT_FINANCIALS.financialsForRestaurant[1],
    },
  ],
});

export const RIDER_FINANCIALS = gql`
  query financialsForRider(
    $startDate: Date!
    $endDate: Date!
    $riderId: String
  ) {
    financialsForRider(
      startDate: $startDate
      endDate: $endDate
      riderId: $riderId
    ) {
      riderEarningsSum
      riderTips
      totalPayout
      numberOfOrders
    }
  }
`;

export const RIDERS_FINANCIALS = gql`
  query financialsForRiders($startDate: Date!, $endDate: Date!) {
    financialsForRider(startDate: $startDate, endDate: $endDate) {
      riderid
      riderEarnings
      numberOfOrders
    }
  }
`;

export const ALL_DISCOUNTS = query({
  DiscountList: [
    {
      validFrom: $`validFrom`,
      validUntil: $`validUntil`,
      skip: $`skip`,
      take: $`take`,
      sortBy: $`sortBy`,
      order: $`order`,
    },
    {
      hasMore: true,
      total: true,
      items: {
        id: true,
        code: true,
        enabled: true,
        description: true,
        minimumTotalPrice: true,
        maxUsesGlobally: true,
        maxUsesPerCustomer: true,
        validFrom: true,
        type: true,
        validUntil: true,
        value: true,
      },
    },
  ],
});

export const CUSTOMER = query({
  Customer: [
    {
      id: $`id`,
    },
    {
      id: true,
      name: true,
      email: true,
      marketing: true,
      created: true,
      referralLink: true,
      accountCredit: true,
      orderStatistics: {
        emissionsSaved: true,
        lastUpdated: true,
        metres: true,
      },
      isPhoneNumberVerified: true,
      phoneNumber: true,
      stripeCustomerId: true,
      paymentMethods: {
        id: true,
        brand: true,
        exp_month: true,
        exp_year: true,
        last4: true,
        stripeId: true,
      },
      addresses: {
        id: true,
        address_line_1: true,
        postcode: true,
        coordinates: {
          type: true,
          coordinates: true,
        },
        country: true,
        flat_number: true,
        instructions: true,
        nickname: true,
        town: true,
        phone_number: true,
      },
    },
  ],
});

export const RESTAURANT_LIST = query({
  RestaurantList: [
    {
      zoneId: $`zoneId`,
      skip: $`skip`,
      take: $`take`,
      order: $`order`,
      sortBy: $`sortBy`,
    },
    {
      items: {
        id: true,
        name: true,
        configuration: true,
        description: true,
        busyStatus: true,
        onboardingStatus: true,
        lastOnline: true,
        online: true,
        currentPrepTime: true,
        headerImageKey: true,
        nextOpeningTime: true,
        address: {
          id: true,
          address_line_1: true,
          flat_number: true,
          postcode: true,
          instructions: true,
          coordinates: {
            type: true,
            coordinates: true,
          },
        },
        zone: {
          id: true,
          name: true,
        },
        brand: {
          id: true,
          name: true,
          logo: {
            id: true,
            key: true,
          },
        },
        openingTimes: {
          open: true,
          close: true,
          daysOfWeek: true,
        },
      },
      total: true,
      hasMore: true,
    },
  ],
});

export const PROMOTION = query({
  Promotion: [
    {
      id: $`id`,
    },
    {
      ...PROMOTION_LIST.PromotionList[1].items,
    },
  ],
});

export const PARTNER_ANALYTICS = query({
  PartnerAnalytics: [
    {
      id: $`id`,
      startDate: $`startDate`,
      endDate: $`endDate`,
      timeRange: $`timeRange`,
      dayOfWeek: $`dayOfWeek`,
    },
    {
      prepTimes: {
        percentile: true,
        value: true,
      },
      id: true,
      orderCount: true,
      waitTimes: {
        percentile: true,
        value: true,
      },
      layoutGroups: {
        id: true,
        title: true,
        description: true,
        charts: {
          "...on StatChart": {
            id: true,
            label: true,
            type: true,
            value: true,
          },
          "...on BarChart": {
            id: true,
            type: true,
            series: {
              label: true,
              x_values: true,
              y_values: true,
            },
          },
          "...on LineChart": {
            id: true,
            type: true,
            series: {
              label: true,
              x_values: true,
              y_values: true,
            },
          },
          "...on PieChart": {
            id: true,
            type: true,
            labels: true,
            values: true,
          },
          "...on TableChart": {
            id: true,
            type: true,
            title: true,
            __typename: true,
            columns: {
              id: true,
              title: true,
              __typename: true,
            },
            data: {
              id: true,
              __typename: true,
              records: {
                dataIndex: true,
                formatted: true,
                value: true,
                __typename: true,
              },
            },
          },
        },
      },
    },
  ],
});

export const LAYOUT_GROUPS_FRAGMENT =
  PARTNER_ANALYTICS["PartnerAnalytics"]["1"]["layoutGroups"];

export const BRAND_LIST = query({
  BrandList: [
    {
      skip: $`skip`,
      take: $`take`,
      order: $`order`,
      sortBy: $`sortBy`,
    },
    {
      hasMore: true,
      total: true,
      items: {
        id: true,
        name: true,
        slug: true,
        description: true,
        logo: {
          id: true,
          key: true,
        },
      },
    },
  ],
});

export const CONFIG = query({
  Configuration: {
    env: true,
    cdnURL: true,
    imgProxyURL: true,
  },
});

export const CHARTS_FRAGMENT =
  PARTNER_ANALYTICS.PartnerAnalytics[1].layoutGroups;

export const PARTNER_TABLET_LIST = query({
  PartnerTabletList: [
    {
      order: $`order`,
      sortBy: $`sortBy`,
      partnerId: $`partnerId`,
      skip: $`skip`,
      take: $`take`,
    },
    {
      total: true,
      items: {
        id: true,
        created: true,
        lastConnection: true,
        manufacturer: true,
        model: true,
        serial: true,
        battery: true,
        batteryState: true,
        ram: true,
        usedRam: true,
        release: true,
        wifiStrength: true,
        wifiNetwork: true,
        routerMake: true,
        bssid: true,
        volume: true,
        width: true,
        height: true,
        location: {
          type: true,
          coordinates: true,
        },
        partner: {
          id: true,
          name: true,
        },
      },
    },
  ],
});

export const PARTNER_TABLET = query({
  PartnerTablet: [
    {
      id: $`id`,
    },
    {
      ...PARTNER_TABLET_LIST["PartnerTabletList"][1]["items"],
      screenshots: {
        id: true,
        key: true,
        created: true,
      },
      ip: true,
      isp: true,
      routerMake: true,
      bssid: true,
      hostname: true,
      partnerId: true,
      webRTCOfferDescription: true,
      webRTCOfferReceiveDescription: true,
      webRTCOfferCandidates: true,
      webRTCOfferReceiveCandidates: true,
    },
  ],
});

export const ADDRESS_FRAGMENT = Selectors.query({
  Address: [
    {
      id: $`id`,
    },
    {
      id: true,
      address_line_1: true,
      coordinates: {
        type: true,
        coordinates: true,
      },
      country: true,
      flat_number: true,
      imageKey: true,
      instructions: true,
      nickname: true,
      phone_number: true,
      postcode: true,
      town: true,
    },
  ],
})["Address"]["1"];
