import gql from "graphql-tag";
import { $, Item } from "./generated";
import { mutation } from "./hooks";
import {
  CHARITY,
  CHARITY_CAMPAIGN,
  MENU,
  PROMOTION_LIST,
  RESTAURANT,
  ZONE,
} from "./queries";

export const LOGIN = mutation({
  login: [
    {
      login: {
        email: $`email`,
        password: $`password`,
        twoFactorCode: $`twoFactorCode`,
        userType: "restaurant",
      },
    },
    {
      user: {
        id: true,
        name: true,
        roles: true,
      },
    },
  ],
});

export const LOGOUT = mutation({
  logout: true,
});

export const CREATE_INVOICE = gql`
  mutation riderInvoice($id: String!, $start: Date!, $end: Date!) {
    riderInvoice(id: $id, start: $start, end: $end) {
      id
    }
  }
`;

export const CREATE_CITY = gql`
  mutation createCity($city: CityCreateInput!) {
    createCity(city: $city) {
      id
      name
      zones {
        id
        name
        polygon {
          type
          coordinates
        }
      }
    }
  }
`;

export const CREATE_RESTAURANT = gql`
  mutation createRestaurant($restaurant: RestaurantCreateInput!) {
    createRestaurant(restaurant: $restaurant) {
      id
      name
      branch
      description
      phoneNumber
      address {
        address_line_1
        postcode
      }
    }
  }
`;

export const CREATE_RIDER = gql`
  mutation createRider($rider: RiderCreateInput!) {
    createRider(rider: $rider) {
      id
    }
  }
`;

const getAddItemReturn = () => {
  const itemAttributes = { ...MENU.Menu[1].categories.items };
  // select everything but options
  const { options, ...prunedModifiers } = itemAttributes.modifiers;
  return {
    ...itemAttributes,
    modifiers: prunedModifiers,
  };
};

export const ADD_ITEM = mutation({
  addItem: [
    {
      id: $`menuId`,
      item: $`item`,
    },
    {
      ...getAddItemReturn(),
    },
  ],
});

export const ADD_CATEGORY = gql`
  mutation addCategory($id: String!, $category: MenuCategoryCreateInput!) {
    addCategory(id: $id, category: $category) {
      id
    }
  }
`;

export const UPDATE_RESTAURANT_HEADER_IMAGE = gql`
  mutation updateRestaurantHeaderImage($image: Upload!, $id: String!) {
    updateRestaurantHeaderImage(image: $image, id: $id) {
      headerImage
    }
  }
`;

export const UPDATE_ITEM_HEADER_IMAGE = gql`
  mutation updateItemHeaderImage($image: Upload!, $id: String!) {
    updateItemHeaderImage(image: $image, id: $id) {
      headerImage
    }
  }
`;

export const ADD_MODIFIER_GROUP = gql`
  mutation addModifierGroup(
    $id: String!
    $modifierGroup: MenuModifierGroupCreateInput!
  ) {
    addModifierGroup(id: $id, modifierGroup: $modifierGroup) {
      id
      name
    }
  }
`;

export const CREATE_SUPER_ADMIN = gql`
  mutation createSuperAdmin(
    $name: String
    $password: String!
    $email: String!
  ) {
    createSuperAdmin(name: $name, password: $password, email: $email) {
      id
      name
      email
    }
  }
`;

export const UPDATE_RESTAURANT = gql`
  mutation updateRestaurant($restaurant: RestaurantUpdateInput!, $id: String!) {
    updateRestaurant(restaurant: $restaurant, id: $id) {
      id
    }
  }
`;

export const UPDATE_ZONE_T = mutation({
  updateZone: [
    {
      id: $`id`,
      zone: $`zone`,
    },
    {
      ...ZONE.zone[1],
    },
  ],
});

export const UPDATE_PARTNER = mutation({
  updateRestaurant: [
    {
      id: $`id`,
      restaurant: $`restaurant`,
    },
    {
      ...RESTAURANT.Restaurant[1],
    },
  ],
});

export const UPDATE_ITEM = mutation({
  updateItem: [
    {
      id: $`id`,
      item: $`item`,
    },
    {
      ...MENU.Menu[1].categories.items,
    },
  ],
});

export const UPDATE_RIDER = gql`
  mutation updateRider($rider: RiderUpdateInput!, $id: String!) {
    updateRider(rider: $rider, id: $id) {
      id
    }
  }
`;

export const SET_PASSWORD = gql`
  mutation setPassword($password: String!, $restaurantId: String!) {
    setPassword(password: $password, restaurantId: $restaurantId)
  }
`;

export const SET_OWNER = gql`
  mutation setOwner($id: String!, $email: String!, $name: String!) {
    setOwner(id: $id, email: $email, name: $name)
  }
`;

export const UPDATE_ZONE = gql`
  mutation updateZone($zone: ZoneUpdateInput!, $id: String!) {
    updateZone(zone: $zone, id: $id)
  }
`;

export const CREATE_DISCOUNT = mutation({
  createDiscount: [
    {
      discount: $`discount`,
    },
    {
      id: true,
      code: true,
    },
  ],
});
export const RESET_PASSWORD = gql`
  mutation resetPasswordRequest($type: UserType!, $email: String!) {
    resetPasswordRequest(type: $type, email: $email)
  }
`;

export const REJECT_ORDER = gql`
  mutation rejectOrderAsRestaurant($rejectOrder: OrderRejectionInput!) {
    rejectOrderAsRestaurant(rejectOrder: $rejectOrder)
  }
`;

export const REMOVE_ITEM_HEADER = mutation({
  removeItemHeaderImage: [
    {
      id: $`id`,
    },
    {
      id: true,
      headerImageKey: true,
    },
  ],
});

export const REORDER_MODIFIER_GROUPS = mutation({
  reorderModifierGroups: [
    {
      partnerId: $`partnerId`,
      modifierGroupIds: $`modifierGroupIds`,
    },
    {
      id: true,
    },
  ],
});

export const UPDATE_PROMOTION = mutation({
  updatePromotion: [
    {
      id: $`id`,
      promotion: $`promotion`,
    },
    {
      ...PROMOTION_LIST.PromotionList[1].items,
    },
  ],
});

export const DELETE_ITEM = mutation({
  deleteItem: [
    {
      itemId: $`itemId`,
      partnerId: $`partnerId`,
    },
    {
      ...MENU.Menu[1].categories.items,
    },
  ],
});

export const UPDATE_CHARITY_CAMPAIGN = mutation({
  updateCharityCampaign: [
    {
      id: $`id`,
      args: $`args`,
    },
    {
      ...CHARITY_CAMPAIGN.CharityCampaign[1],
    },
  ],
});

export const UPDATE_CHARITY = mutation({
  updateCharity: [
    {
      id: $`id`,
      args: $`args`,
    },
    {
      ...CHARITY.Charity[1],
    },
  ],
});
