import { ReloadOutlined, RobotFilled, SaveOutlined } from "@ant-design/icons";
import { Widget } from "@rjsf/core";
import {
  Alert,
  Button,
  Card,
  Modal,
  PageHeader,
  Result,
  Skeleton,
  Space,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useInterval } from "react-use";
import {
  DynamicFormResult,
  DynamicMeta,
} from "../../graphql/generated/graphql-zeus";
import { useTypedQuery } from "../../graphql/hooks";
import SchemaForm from "../forms/SchemaForm";
import SelectImageAsset, { DisplayImageAsset } from "../SelectImageAsset";
import AddAddressButton, { AddressDisplay } from "./AddAddressForm";
import FieldTemplate from "./antd-schema/FieldTemplate";
import ObjectFieldTemplate from "./antd-schema/ObjectFieldTemplate";
import DynamicTable, { DynamicButton } from "./dynamic-form/DynamicTable";
import { DYNAMIC_FORM } from "./dynamic-form/queries";
import useSubmitDynamicForm from "./dynamic-form/useSubmitDynamicForm";

const widgets: { [name: string]: Widget } = {
  address: (props) => {
    return (
      <>
        {props.value && <AddressDisplay id={props.value} />}
        <AddAddressButton
          onComplete={(id) => props.onChange(id)}
          initialAddrId={props.value}
        />
      </>
    );
  },
  imageAsset: (props) => {
    return (
      <>
        {props.value && <DisplayImageAsset id={props.value} />}
        <SelectImageAsset
          onChange={(a) => props.onChange(a[0]?.id || undefined)}
        />
      </>
    );
  },
};

export type DynamicFormProps = {
  id: string;
  entityId?: string;
  onComplete?: (d: DynamicFormResult) => void;
  noCard?: boolean;
  submitText?: React.ReactNode;
  submitIcon?: React.ReactNode;
  initialValues?: Record<string, any>;
};

/**
 * A completely dynamic server rendered form.
 *
 * Great for hidden fields that shouldn't be exposed in a schema.
 */
export default React.memo(
  function DynamicForm(props: DynamicFormProps) {
    const {
      id,
      entityId,
      onComplete,
      noCard,
      submitText = "Submit",
      submitIcon,
    } = props;

    const [meta, setMeta] = useState<DynamicMeta | null>(null);

    const { data, error, refetch } = useTypedQuery(DYNAMIC_FORM, {
      variables: { id, entityId, meta },
      fetchPolicy: "network-only",
    });

    const pollInterval = data?.DynamicForm.pollInterval;

    useInterval(() => pollInterval && refetch(), pollInterval);

    // indicates whether the user has changed the values of the form
    const [dirtyState, setDirtyState] = useState(false);
    const [submit, { loading }] = useSubmitDynamicForm(
      setDirtyState,
      onComplete
    );

    const initialValues = useMemo(
      () => ({
        ...data?.DynamicForm.initialValues,
        ...props.initialValues,
      }),
      [JSON.stringify(data?.DynamicForm.initialValues), props.initialValues]
    );

    const [formData, setFormData] = useState(initialValues);

    useEffect(() => {
      if (initialValues && !dirtyState) {
        setFormData(initialValues);
      }
    }, [dirtyState, initialValues]);

    const onSubmit = useCallback(
      (v) => submit({ variables: { id, data: v.formData, entityId } }),
      [id, entityId]
    );

    const uiSchema = useMemo(() => {
      const base = {
        addressId: { "ui:widget": "address" },
      };

      // TODO traverse schema tree to find imageAssetId and addressId then patch uiSchema
      if (data?.DynamicForm.schema?.properties) {
        const props = Object.entries(
          data?.DynamicForm.schema?.properties as object
        );

        for (const [key, prop] of props) {
          if (prop && prop.format === "image-asset") {
            base[key] = { "ui:widget": "imageAsset" };
          }
        }
      }

      return base;
    }, [data?.DynamicForm.schema]);

    const [disableTable, setDisableTable] = useState(false);

    const [nextForm, setNextForm] = useState("");
    const [nextFormEntity, setNextFormEntity] = useState("");

    useEffect(() => {
      if (data?.DynamicForm.meta)
        setMeta({
          ...(meta || {}),
          ...data?.DynamicForm.meta,
        });
    }, [data?.DynamicForm?.meta]);

    if (error)
      return (
        <Result
          title="Cannot load this form"
          status="403"
          extra={[
            <Space>
              <Button
                icon={<RobotFilled />}
                href="https://chat.google.com/room/AAAAtJTsFMA"
                target="_blank"
              >
                Contact Engineering
              </Button>{" "}
              <Button
                icon={<ReloadOutlined />}
                onClick={() => refetch()}
                loading={!!pollInterval}
              ></Button>{" "}
            </Space>,
            error && (
              <Alert
                style={{ marginTop: 16, marginBottom: 16 }}
                message={error.message}
                type="error"
              />
            ),
          ]}
        />
      );

    if (!data)
      return (
        <>
          {" "}
          <PageHeader
            title={
              <Skeleton
                active
                title={false}
                paragraph={{ rows: 1, width: "100px" }}
                loading={true}
              />
            }
            subTitle={
              <Skeleton
                active
                title={false}
                paragraph={{ rows: 1, width: "200px" }}
                loading={true}
              />
            }
            extra={[
              <Button
                icon={<ReloadOutlined />}
                shape="round"
                loading={true}
              ></Button>,
            ]}
          />
          <Card bordered={!noCard}>
            {Array(6)
              .fill(0)
              .map((i) => (
                <Skeleton
                  active
                  title={false}
                  key={i}
                  paragraph={{ rows: 2, width: "200px" }}
                  loading={true}
                />
              ))}
          </Card>
        </>
      );

    const form = data.DynamicForm;

    const schemaForm = (
      <SchemaForm
        key={form.id}
        schema={form.schema}
        onChange={(updatedData) => {
          setFormData(updatedData.formData);
          setDirtyState(true);
        }}
        onSubmit={onSubmit}
        onError={console.log}
        formData={formData}
        FieldTemplate={FieldTemplate}
        ObjectFieldTemplate={ObjectFieldTemplate as any}
        showErrorList={false}
        widgets={widgets}
        uiSchema={uiSchema}
      >
        <Button
          icon={<SaveOutlined />}
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={!form.canSubmit}
        >
          {form.submitText}
        </Button>
      </SchemaForm>
    );

    console.log("Initial Values", JSON.stringify(form.initialValues));

    return (
      <>
        <PageHeader
          title={form.title}
          subTitle={form.description}
          extra={[
            ...(form.metaSchema?.["properties"]?.[
              "headerButtons"
            ].items.map((i) => (
              <DynamicButton
                schema={i}
                setNextForm={setNextForm}
                setNextFormEntity={setNextFormEntity}
                submit={submit}
                loading={loading}
                entityId={entityId}
              />
            )) || []),

            <Button
              style={{
                borderRadius: "50%",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<ReloadOutlined />}
              onClick={() => refetch()}
              loading={!!pollInterval}
            ></Button>,
          ]}
        ></PageHeader>
        {form.type === "TABLE" &&
        !disableTable &&
        Array.isArray(form.initialValues) ? (
          <DynamicTable
            setMeta={setMeta}
            formId={form.id}
            metaSchema={form.metaSchema}
            initialValues={form.initialValues}
            meta={form.meta}
            schema={form.schema}
            setNextForm={setNextForm}
            setNextFormEntity={setNextFormEntity}
            onEditEntity={() => refetch()}
          />
        ) : (
          <Card bordered={!noCard}>{schemaForm}</Card>
        )}

        <Modal
          visible={!!nextForm}
          onCancel={() => {
            setNextFormEntity("");
            setNextForm("");
          }}
          footer={null}
        >
          {!!nextForm && (
            <DynamicForm
              noCard
              onComplete={() => {
                setNextFormEntity("");
                setNextForm("");
                refetch();
              }}
              key={nextForm + nextFormEntity}
              id={nextForm}
              entityId={nextFormEntity}
            />
          )}
        </Modal>
      </>
    );
  },
  (p, n) =>
    p.id === n.id &&
    p.noCard === n.noCard &&
    p.entityId === n.entityId &&
    p.onComplete === n.onComplete &&
    JSON.stringify(p.initialValues) === JSON.stringify(n.initialValues)
);
