import { Select } from "antd";
import React from "react";
import { PromotionTarget } from "../../graphql/generated";

const OPTIONS = Object.keys(PromotionTarget).map((v) => ({
  label: v[0] + v.slice(1).toLowerCase(),
  value: v,
}));

export default function PromotionTypeSelect({
  onChange,
}: {
  onChange?: (type: PromotionTarget) => void;
}) {
  return <Select allowClear options={OPTIONS} onChange={onChange} />;
}
