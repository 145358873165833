import { MutationFunction } from "@apollo/client";
import { useCallback } from "react";

export const useUpdateItemKey = <T>(
  updateMutation: MutationFunction,
  path: string,
  id?: string
) => {
  const update = useCallback(
    <Q extends keyof T>(key: Q, value: T[Q], _id?: string) => {
      const variables: { [key: string]: any } = {
        [path]: {
          [key]: value,
        },
      };

      if (id || _id) variables.id = id || _id;
      return updateMutation({
        variables,
      });
    },
    [updateMutation, path, id]
  );

  return update;
};
