import { Select } from "antd";
import React, { useMemo, useState } from "react";
import { Ordering } from "../../../graphql/generated";
import { useTypedQuery } from "../../../graphql/hooks";
import { ZONE_LIST } from "../../../graphql/queries";
import { useDebouncedCallback } from "../../../hooks/useDebounce";

export default function ZoneAutocomplete({
  initialZoneName,
  onSelect,
}: {
  initialZoneName?: string;
  onSelect: (id: string) => void;
}) {
  const [query, setQuery] = useState("");
  const cb = useDebouncedCallback(setQuery, 400);

  const { data, loading } = useTypedQuery(ZONE_LIST, {
    variables: {
      order: Ordering.ASC,
      sortBy: "name",
      query,
      take: 10,
      skip: 0,
    },
    fetchPolicy: "network-only",
  });

  const options = useMemo(
    () =>
      data?.ZoneList.items.map((o) => ({
        label: o.name,
        value: o.id,
      })) || [],
    [data]
  );

  console.log(options);

  return (
    <Select
      loading={loading}
      showSearch
      defaultValue={initialZoneName}
      onSearch={cb}
      onChange={onSelect}
      style={{ width: "100%" }}
    >
      {options.map((o) => (
        <Select.Option value={o.value}>{o.label}</Select.Option>
      ))}
    </Select>
  );
}
