import { ApolloError } from "@apollo/client";
import { message } from "antd";

export const onError = (e?: ApolloError) => {
  message.error(e?.message || "Failed to perform that action.");
};

export const onSuccess = () => {
  message.success("Success!");
};
