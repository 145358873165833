import { PageHeader, Tabs } from "antd";
import React from "react";
import { Container } from "react-bootstrap";
import FileList from "../components/media/FileList";
import MediaList from "../components/media/MediaList";
import VideoList from "../components/media/VideoList";
import useTabSync from "../hooks/useTabSync";

export default function Media() {
  const { key, onSelect } = useTabSync("images");

  return (
    <Container
      fluid
      className="main-content-container px-4"
      style={{ backgroundColor: "white" }}
    >
      <PageHeader title="Media" />

      <Tabs activeKey={key} onChange={onSelect} id="charity-nav">
        <Tabs.TabPane key="images" tab="Images">
          <MediaList />
        </Tabs.TabPane>
        <Tabs.TabPane key="videos" tab="Videos">
          <VideoList />
        </Tabs.TabPane>
        <Tabs.TabPane key="files" tab="Files">
          <FileList />
        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
}
