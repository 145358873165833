import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import HotKeys from "react-hot-keys";
import { useHistory } from "react-router-dom";
import { Cities } from "../../views/Cities";

export default function CitySwitch() {
  const [show, setShow] = useState(false);

  const handleCitySwitch = () => {
    console.log("caught!");
    setShow(true);
  };

  const router = useHistory();
  useEffect(() => {
    const ret = router.listen((l) => setShow(false));

    return ret;
  }, []);

  return (
    <HotKeys keyName="q" onKeyUp={handleCitySwitch}>
      <Modal visible={show} onCancel={() => setShow(false)} footer={null}>
        <Cities />
      </Modal>
    </HotKeys>
  );
}
