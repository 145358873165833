import { Button, PageHeader } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import DynamicForm from "../forms/DynamicForm";
import MarketingCampaignTable from "./MarketingCampaignTable";

export default function MarketingCampaigns() {
  const [show, setShow] = useState(false);

  return (
    <>
      <PageHeader
        title="Campaigns"
        extra={[
          <Button onClick={() => setShow(true)} type="primary">
            Create Campaign
          </Button>,
        ]}
      />
      <MarketingCampaignTable />
      <Modal visible={show} onCancel={() => setShow(false)} footer={null}>
        <DynamicForm
          noCard
          id={"MarketingCampaignCreateForm"}
          onComplete={() => setShow(false)}
          submitText="Schedule Now"
        />
      </Modal>
    </>
  );
}
