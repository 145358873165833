import { PageHeader, Tabs } from "antd";
import React from "react";
import { Container } from "react-bootstrap";
import Charities from "../components/charity/Charities";
import CharityCampaigns from "../components/charity/CharityCampaigns";
import useTabSync from "../hooks/useTabSync";
export default function CharityView() {
  const { key, onSelect } = useTabSync("campaigns");

  return (
    <Container fluid>
      <PageHeader
        title="Charity"
        subTitle="Donations and Charitable contributions"
      />

      <Tabs activeKey={key} onChange={onSelect} id="charity-nav">
        <Tabs.TabPane key="campaigns" tab="Campaigns">
          <CharityCampaigns />
        </Tabs.TabPane>
        <Tabs.TabPane key="charities" tab="Charities">
          <Charities />
        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
}
