export const sidebarNavItem = (title, to, icon) => ({
  title,
  to,
  htmlBefore: icon ? `<i class="material-icons">${icon}</i>` : null,
});

export default function () {
  return [
    sidebarNavItem("Overview", "/dashboard", "assessment"),
    sidebarNavItem(
      "Onboard a Partner",
      "/onboard-restaurant",
      "assignment_turned_in"
    ),
    sidebarNavItem("Cities", "/cities", "list"),
    sidebarNavItem("Discounts", "/discounts", "local_activity"),
    sidebarNavItem("Accounting", "/accounting", "account_balance"),
    sidebarNavItem("Customers", "/customers", "people"),
    sidebarNavItem("Admins", "/admins", "person"),
  ];
}
