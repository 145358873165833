/* eslint-disable */
import * as actions from "./actions";

export const reducer = (state, action) => {
  const hookName = action.type;
  const hook = actions[hookName];

  if (!(hook instanceof Function)) {
    console.error(`Bad call to action hook '${hookName}'`);
    return state;
  }

  delete action.type;

  const newState = hook(state, action);

  localStorage.setItem('state', JSON.stringify(newState));

  return newState;
};
