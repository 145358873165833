export const API_ENDPOINTS = {
  production: "https://api.ecoeats.uk/graphql",

  staging: "https://api.staging.ecoeats.uk/graphql",

  local: "https://local-api.ecoeats.uk/graphql",
};

export const WS_ENDPOINTS = {
  production: "wss://api.ecoeats.uk/graphql",

  staging: "wss://api.staging.ecoeats.uk/graphql",

  local: "wss://local-api.ecoeats.uk/graphql",
};

export const state = {
  login: {
    isAuthenticated: false,
    accessToken: "",
    expires: "",
    user: {},
  },
  restaurants: [],
  api: {
    url: API_ENDPOINTS.production,
    env: "production",
  },
};
