import React, { useState } from 'react';
import { parseNegatableCurrency } from '../../utils/price';
import CurrencyInput from 'react-currency-input-field';

interface Props {
    currencySymbol?: string;
    allowNegativeValues?: boolean;
    // the value in number of pennies
    defaultValue?: number;
    value?: number;
    onChange?: (newValue: number) => void;
}

const CurrencyFormInput = (props: Props) => {
    const { currencySymbol = '£', allowNegativeValues = false, defaultValue = 0, value: valueProp, onChange } = props;

    // indicates whether when the most recent character 
    // was typed whether the last character was a decimal
    const [lastCharDecimal, setLastCharDecimal] = useState(false);

    const currencyInputRef = React.useRef<HTMLInputElement>(null);

    // include a decimal point if on the last update a decimal was
    // the last character.
    // otherwise won't be able to enter decimal values.
    const value = valueProp ? 
      lastCharDecimal ?
        `${(valueProp / 100)}.`
        : valueProp / 100
      : undefined


    const onValueChangeProps = onChange ? {
      onValueChange: (v: string | undefined) => {
        if (!v || v.length == 0) v = '0';
        if (v.charAt(v.length - 1) == '.') {
          setLastCharDecimal(true);
        } else {
          setLastCharDecimal(false);
        }
        onChange(v ? parseNegatableCurrency(parseFloat(v).toFixed(2)) :0);
      },
    } : {};

    return (
        <CurrencyInput
          className="ant-input"
          allowDecimals={true}
          allowNegativeValue={allowNegativeValues}
          defaultValue={defaultValue}
          value={value}
          {...onValueChangeProps}
          decimalsLimit={2}
          prefix={currencySymbol}
          decimalSeparator='.'
          disableAbbreviations={true}
          ref={currencyInputRef}
          step={0.01}
        />
    );
};

export default CurrencyFormInput;