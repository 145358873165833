import React from "react";
import { $ } from "../../graphql/generated";
import { useTypedQuery } from "../../graphql/hooks";
import { LAYOUT_GROUPS_FRAGMENT } from "../../graphql/queries";
import LayoutGroup from "../charts/LayoutGroup";

export default function CustomerAnalytics({ id }: { id: string }) {
  const { data } = useTypedQuery(
    {
      CustomerAnalytics: [
        {
          customerId: $`id`,
        },
        {
          id: true,
          layoutGroups: {
            ...LAYOUT_GROUPS_FRAGMENT,
          },
        },
      ],
    },
    {
      variables: {
        id,
      },
    }
  );

  return (
    <div>
      {data?.CustomerAnalytics.layoutGroups.map((g) => (
        <LayoutGroup group={g} />
      ))}
    </div>
  );
}
