/* eslint-disable */
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col } from "shards-react";
import { Store } from "../../../flux";
import { useStore } from "../../../state/zustand";
import { DarkModeToggle } from "../MainNavbar/NavbarNav/DarkModeToggle";
import { UserActions } from "../MainNavbar/NavbarNav/UserActions";
import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarNavItems from "./SidebarNavItems";
import SidebarSearch from "./SidebarSearch";

function MainSidebar() {
  const [state, setState] = useState({
    menuVisible: false,
    sidebarNavItems: Store.getSidebarItems(),
  });

  useEffect(() => {
    Store.addChangeListener(onChange);
    return () => Store.removeChangeListener(onChange);
  }, []);

  const onChange = useCallback(() => {
    setState((s) => ({
      ...s,
      menuVisible: Store.getMenuState(),
      sidebarNavItems: Store.getSidebarItems(),
    }));
  }, []);

  const classes = useMemo(
    () =>
      classNames("main-sidebar", "px-0", "col-12", state.menuVisible && "open"),
    [state.menuVisible]
  );

  const collapseSider = useStore((s) => s.collapseSider);
  return (
    <Col
      tag="aside"
      className={classes}
      lg={{ size: collapseSider ? 1 : 2 }}
      md={{ size: 3 }}
    >
      <SidebarMainNavbar
        // @ts-ignore
        hideLogoText={false}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <DarkModeToggle />
        <UserActions />
      </div>
      <SidebarSearch />
      <SidebarNavItems />
    </Col>
  );
}

export default MainSidebar;
