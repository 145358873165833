import React, { useContext } from "react";
import { ZoneContext } from "../../views/Zone";
import DynamicForm from "../forms/DynamicForm";

export default function ZoneSimulator() {
  const zone = useContext(ZoneContext);
  const zoneId = zone?.id;

  return (
    <div style={{ maxWidth: "768px", margin: "0 auto" }}>
      <DynamicForm id="SimulatorSettings" entityId={zoneId} />
    </div>
  );
}
