import React, { forwardRef } from "react";
import { Col, Row } from "shards-react";
import { AllTypesProps, MapType, Query, Zone } from "../../graphql/generated";
import { ZONE } from "../../graphql/queries";
import { RidersTable, RidersTableRef } from "../RidersTable";

interface Props {
  zone: MapType<Query, typeof ZONE>["zone"];
  visible?: boolean;
  persistContextId?: string;
}

const ZoneRiders = (
  { zone, visible = true, persistContextId: propsPersistContextId }: Props,
  ref
) => {
  const persistContextId = propsPersistContextId
    ? `${propsPersistContextId}-zone-riders`
    : undefined;

  return (
    <div style={{ display: !visible ? "none" : undefined }}>
      <Row>
        <Col>
          <RidersTable
            ref={ref}
            zone={zone}
            persistContextId={persistContextId}
          />
        </Col>
      </Row>
    </div>
  );
};

export default forwardRef<RidersTableRef, Props>(ZoneRiders);
