import { useStore } from "../state/zustand";
import { PaginationConfig } from "./usePagination";

export default function usePersistedPaginationConfig(
  persistContextId?: string
) {
  const persistedConfig = useStore<PaginationConfig | undefined>((s) => {
    if (persistContextId) {
      return s.paginationConfigs[persistContextId];
    } else {
      return undefined;
    }
  });

  const setStoredConfig = useStore((s) => s.setPaginationConfig);
  return [persistedConfig, setStoredConfig] as [
    PaginationConfig | undefined,
    (contextId: string, newConfig?: PaginationConfig) => void
  ];
}
