import { StarFilled, UserOutlined } from "@ant-design/icons";
import { QueryResult, useMutation } from "@apollo/client";
import {
  Badge,
  Button,
  Col,
  Descriptions,
  message,
  PageHeader,
  Row,
  Select,
  Space,
  Statistic,
  Tabs,
} from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import { endOfWeek, startOfWeek } from "date-fns";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Alert, Card, Modal } from "react-bootstrap";
import { Map, Marker, Polygon, Popup, TileLayer } from "react-leaflet";
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Container } from "shards-react";
import { RiderBadge } from "../../components/badges/RiderBadge";
import RangeDatePicker from "../../components/common/RangeDatePicker";
import PayInvoiceButton from "../../components/financials/PayInvoiceButton";
import { RiderFinancials } from "../../components/financials/RiderFinancials";
import IncidentTable from "../../components/incident/IncidentTable";
import { LoadingButton } from "../../components/LoadingButton";
import { OrdersTable } from "../../components/OrdersTable";
import RiderApplication from "../../components/rider/RiderApplication";
import RiderBookings from "../../components/rider/RiderBookings";
import { RiderDiscounts } from "../../components/rider/rider-discounts/RiderDiscounts";
import RiderFlightPlan from "../../components/rider/RiderFlightPlan";
import RiderLeases from "../../components/rider/RiderLeases";
import FlightOfferTable from "../../components/rider/RiderOffers";
import RiderSessions from "../../components/rider/RiderSessions";
import RiderSendMessage from "../../components/RiderSendMessage";
import ZoneAutocomplete from "../../components/zone/forms/ZoneAutocomplete";
import ZoneAlerts from "../../components/zone/ZoneAlerts";
import {
  $,
  MapType,
  Query,
  RiderActivationStatus,
  RiderUpdateInput,
  RiderVehicle,
} from "../../graphql/generated";
import { useTypedMutation, useTypedQuery } from "../../graphql/hooks";
import { CREATE_INVOICE } from "../../graphql/mutations";
import { RIDER } from "../../graphql/queries";
import { getImageUrl } from "../../utils/image-url";

const keysToSelectOptions = (r) => ({
  label: r,
  value: r,
});

const STATUSES = Object.keys(RiderActivationStatus).map(keysToSelectOptions);

const VEHICLES = Object.keys(RiderVehicle).map(keysToSelectOptions);

export const RiderContext = createContext<
  QueryResult<MapType<Query, typeof RIDER>>
>(null as any);
export const useRider = () => useContext(RiderContext);

export const Rider = () => {
  const { id, tab } = useParams();
  const history = useHistory();
  const match = useRouteMatch();

  const query = useTypedQuery(RIDER, {
    pollInterval: 5000,
    variables: {
      id,
      onlineSessionsSkip: 0,
      onlineSessionsTake: 5,
      onlineSessionsOrder: "DESC",
      onlineSessionsSortBy: "start",
    },
    fetchPolicy: "cache-and-network",
  });

  const { data, refetch, updateQuery } = query;

  const [startDate, setStartDate] = useState(startOfWeek(new Date()));
  const [endDate, setEndDate] = useState(endOfWeek(new Date()));
  const rider = data?.Rider;
  const [key, setKey] = useState("overview");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setKey(tab);
  }, [tab]);

  const onSelect = (_tab) => {
    const path = generatePath(match.path, { id, tab: _tab });
    history.replace(path);
  };

  const [createInvoice, { loading }] = useMutation(CREATE_INVOICE, {
    onCompleted: () => {
      setShowModal(false);
      refetch();
    },
  });

  const generateInvoice = () => {
    createInvoice({
      variables: {
        id,
        start: startDate,
        end: endDate,
      },
    });
  };

  const [updateRider, { loading: updating }] = useTypedMutation(
    {
      updateRider: [
        {
          id: $`id`,
          rider: $`rider`,
        },
        {
          id: true,
          activationStatus: true,
          vehicle: true,
          sortCode: true,
          accountNo: true,
          name: true,
          phoneNumber: true,
          note: true,
        },
      ],
    },
    {
      onCompleted: useCallback(
        (d) => {
          message.success("Updated Rider!");
          updateQuery((p) => ({
            Rider: {
              ...p.Rider,
              ...d.updateRider,
            },
          }));
        },
        [updateQuery]
      ),
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const updateItemKey = useCallback(
    (key: keyof RiderUpdateInput, value: any) =>
      updateRider({
        variables: {
          id,
          rider: {
            [key]: value,
          },
        },
      }),
    [id, updateRider]
  );

  const [showDetails, setShowDetails] = useState(false);
  const [alerts, setAlerts] = useState(0);

  return (
    <RiderContext.Provider value={query}>
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}

        <PageHeader
          avatar={{
            size: 96,
            icon: rider?.profileImageKey ? undefined : (
              <UserOutlined size={64} />
            ),
            src: rider?.profileImageKey
              ? getImageUrl(rider.profileImageKey, 256, 256)
              : undefined,
          }}
          title={
            <Space direction="vertical">
              <Title level={4}>{rider?.name}</Title>
              {rider && <RiderBadge rider={rider} />}
            </Space>
          }
          extra={[<RiderSendMessage name={rider?.name} id={rider?.id} />]}
        />

        <Tabs activeKey={key} onChange={onSelect}>
          <Tabs.TabPane key="overview" tab="Overview">
            <Space>
              <Statistic
                title="Accepts"
                value={rider?.statistics?.acceptedOrders || 0}
              />
              <Statistic
                title="Rejects"
                value={rider?.statistics?.rejectedOrders || 0}
              />
              <Statistic
                title="Timeouts"
                value={rider?.statistics?.timedoutOrders || 0}
              />
              <Statistic
                title="Customer Rating"
                value={
                  rider?.statistics?.averageRating
                    ? rider.statistics.averageRating.toFixed(2)
                    : 0
                }
                suffix={<StarFilled style={{ verticalAlign: "text-top" }} />}
              />
            </Space>
            <Descriptions
              bordered
              style={{
                marginBottom: 32,
              }}
            >
              <Descriptions.Item label="Name">
                <Text
                  editable={{
                    onChange: (v) => updateItemKey("name", v),
                  }}
                >
                  {rider?.name}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {rider?.email}
              </Descriptions.Item>
              <Descriptions.Item label="Number">
                <Badge
                  text={
                    <Text
                      editable={{
                        onChange: (v) => updateItemKey("phoneNumber", v),
                      }}
                    >
                      {rider?.phoneNumber}
                    </Text>
                  }
                  status={rider?.isPhoneNumberVerified ? "success" : "warning"}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Note">
                <Text
                  editable={{
                    onChange: (v) => updateItemKey("note", v),
                  }}
                >
                  {rider?.note}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Zone">
                {rider && (
                  <ZoneAutocomplete
                    onSelect={(id) => updateItemKey("zoneId", id)}
                    initialZoneName={rider?.zone.name}
                  />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Vehicle">
                <Select
                  value={rider?.vehicle}
                  options={VEHICLES}
                  loading={updating}
                  dropdownMatchSelectWidth
                  onChange={(v) => updateItemKey("vehicle", v)}
                />
              </Descriptions.Item>
              {rider?.vehicle === "CAR" ? (
                <Descriptions.Item label="Registration Number">
                  <Text
                    editable={{
                      onChange: (v) => updateItemKey("carRegistration", v),
                    }}
                  >
                    {rider?.carRegistration}
                  </Text>
                  <Button onClick={() => setShowDetails(true)}>
                    Show Details
                  </Button>
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label="Status">
                <Badge
                  status={
                    rider?.activationStatus === RiderActivationStatus.APPROVED
                      ? "success"
                      : rider?.activationStatus ===
                          RiderActivationStatus.REJECTED ||
                        rider?.activationStatus ===
                          RiderActivationStatus.SUSPENDED
                      ? "error"
                      : "warning"
                  }
                />
                <Select
                  style={{
                    width: "80%",
                  }}
                  value={rider?.activationStatus}
                  options={STATUSES}
                  loading={updating}
                  dropdownMatchSelectWidth
                  onChange={(v) => updateItemKey("activationStatus", v)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                {rider?.address.address_line_1}, {rider?.address.postcode}
              </Descriptions.Item>
              <Descriptions.Item label="Payments">
                {rider?.accountNo} | {rider?.sortCode}
              </Descriptions.Item>
            </Descriptions>
            {showDetails && rider ? (
              <pre>{JSON.stringify(rider.vehicleDetails, null, 2)}</pre>
            ) : null}
            <Row gutter={32}>
              <Col sm={24} md={12}>
                <div
                  style={{
                    height: "300px",
                  }}
                >
                  {rider ? (
                    <Map
                      center={rider.position?.coordinates || [0, 0]}
                      zoom={14}
                      style={{
                        height: "100%",
                      }}
                    >
                      <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://maps.ecoeats.uk/tile/{z}/{x}/{y}.png"
                      />
                      <Polygon
                        center={rider.position?.coordinates || [0, 0]}
                        positions={rider.zone.polygon?.coordinates?.[0]}
                        color="green"
                      />
                      {rider.position?.coordinates ? (
                        <Marker position={rider.position.coordinates}>
                          <Popup>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <h5 style={{ flex: 1 }}>Rider</h5>
                            </div>
                            <h6>{rider.name}</h6>
                          </Popup>
                        </Marker>
                      ) : null}
                    </Map>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            key="alerts"
            tab={
              <Badge count={alerts} offset={[4, -5]} size="small">
                Alerts
              </Badge>
            }
            forceRender
          >
            <ZoneAlerts setAlerts={setAlerts} riderId={id} />
          </Tabs.TabPane>
          <Tabs.TabPane key="flight" tab="Flight Plan">
            {key === "flight" && <RiderFlightPlan riderId={id} />}
          </Tabs.TabPane>
          <Tabs.TabPane key="offers" tab="Offers">
            {key === "offers" && <FlightOfferTable riderId={id} />}
          </Tabs.TabPane>
          <Tabs.TabPane key="orders" tab="Orders">
            {key === "orders" && <OrdersTable riderId={id} />}
          </Tabs.TabPane>
          <Tabs.TabPane key="financial" tab="Financial">
            {key === "financial" && rider && (
              <>
                <Row>
                  <Col span={24}>
                    <RiderFinancials riderId={rider.id} />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Card className="mb-4">
                      <Card.Header>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <h5 style={{ flex: 1 }}>Invoices</h5>
                          <Button onClick={() => setShowModal(true)}>
                            Generate Invoice
                          </Button>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <table className="table mb-0">
                          <thead className="bg-light">
                            <tr>
                              <th>Period</th>
                              <th>Orders</th>
                              <th>Total</th>
                              <th>Paid?</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rider.invoices.map((i) => (
                              <tr>
                                <td>
                                  <a
                                    target="_blank"
                                    href={i.url}
                                    rel="noreferrer"
                                  >
                                    {new Date(i.start).toLocaleDateString()} -{" "}
                                    {new Date(i.end).toLocaleDateString()}
                                  </a>
                                </td>
                                <td>{i.orders}</td>
                                <td>£{(i.value / 100).toFixed(2)}</td>
                                <td>
                                  {i.paid ? (
                                    <a
                                      href={i.revolutTransactionLink}
                                      target="_blank"
                                      rel="nofollow noreferrer"
                                    >
                                      <i
                                        className="material-icons mr-2"
                                        style={{ fontSize: 18 }}
                                      >
                                        done
                                      </i>{" "}
                                      View Transaction
                                    </a>
                                  ) : (
                                    <PayInvoiceButton id={i.id} />
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane key="sessions" tab="Location">
            {key === "sessions" && rider ? <RiderSessions /> : null}
          </Tabs.TabPane>

          <Tabs.TabPane key="bookings" tab="Bookings">
            <RiderBookings />
          </Tabs.TabPane>

          <Tabs.TabPane key="application" tab="Application">
            {key === "application" && <RiderApplication />}
          </Tabs.TabPane>
          <Tabs.TabPane key="incidents" tab="Incidents">
            <IncidentTable riderId={id} />
          </Tabs.TabPane>
          <Tabs.TabPane key="leases" tab="Leases">
            <RiderLeases key={id} riderId={id} />
          </Tabs.TabPane>
          <Tabs.TabPane key="discounts" tab="Discounts">
            <RiderDiscounts key={id} riderId={id} />
          </Tabs.TabPane>
        </Tabs>
      </Container>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Generate Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">
            This will forcibly generate an invoice for the given dates
          </Alert>

          <RangeDatePicker
            style={{}}
            initialStartDate={startDate}
            initialEndDate={endDate}
            onChange={({ endDate: ed, startDate: sd }) => {
              setEndDate(ed);
              setStartDate(sd);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <LoadingButton
            onClick={generateInvoice}
            variant="primary"
            loading={loading}
          >
            Generate Invoice
          </LoadingButton>
        </Modal.Footer>
      </Modal>
    </RiderContext.Provider>
  );
};
