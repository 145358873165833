import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popover, Space, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import QR from "react-qr-code";
import { Link } from "react-router-dom";
import { $ } from "../../graphql/generated";
import { query, useTypedQuery } from "../../graphql/hooks";
import usePagination from "../../hooks/usePagination";
import DynamicForm from "../forms/DynamicForm";

const SITE_TABLE = query({
  ReusableContainerList: [
    {
      skip: $`skip`,
      take: $`take`,
      sortBy: $`sortBy`,
      order: $`order`,
      query: $`query`,
    },
    {
      total: true,
      items: {
        id: true,
        createdAt: true,
        type: {
          id: true,
          name: true,
          length: true,
          width: true,
          depth: true,
        },
        uris: true,
      },
    },
  ],
});

export default function ReusableContainerTable() {
  const {
    pagination,
    args: { order, page, query, sortBy, take },
    onChange,
    setQuery,
  } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "typeId",
  });

  const { data, refetch } = useTypedQuery(SITE_TABLE, {
    fetchPolicy: "network-only",
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
    },
  });

  const [show, setShow] = useState(false);
  const [showTypes, setShowTypes] = useState(false);

  return (
    <>
      <Table
        title={() => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Title level={4} style={{ flex: 1 }}>
              {data?.ReusableContainerList.total} Containers
            </Title>

            <Space>
              <Input
                placeholder="Search..."
                suffix={<SearchOutlined />}
                onChange={(t) => setQuery(t.target.value)}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setShow(true)}
              >
                Add Containers
              </Button>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setShowTypes(true)}
              >
                New Container Type
              </Button>
            </Space>
          </div>
        )}
        onChange={(p, f, s) => {
          onChange(p, f, s);
        }}
        dataSource={data?.ReusableContainerList.items}
        pagination={{
          total: data?.ReusableContainerList.total,
          ...pagination,
        }}
        columns={[
          {
            title: "ID",
            sorter: true,
            dataIndex: "id",
            render: (_, r) => (
              <Link
                to={`/reusables/container/${r.id}`}
                style={{
                  fontFamily: "monospace",
                }}
              >
                {r.id}
              </Link>
            ),
          },
          {
            title: "Created",
            key: "created",
            sorter: true,
            render: (_, r) => new Date(r.createdAt).toLocaleString(),
          },
          {
            title: "Type",
            key: "typeId",
            sorter: true,
            render: (_, r) =>
              `${r.type.name} (${r.type.length}x${r.type.width}x${r.type.depth})`,
          },
          {
            title: "QR",
            render: (_, r) => (
              <Popover content={<QR value={r.uris[0]} />} placement="left">
                <div>
                  {" "}
                  <QR value={r.uris[0]} size={32} />
                </div>
              </Popover>
            ),
          },
        ]}
      />
      <Modal
        footer={null}
        visible={show}
        destroyOnClose
        onCancel={() => setShow(false)}
      >
        <DynamicForm
          onComplete={() => {
            refetch();
            setShow(false);
          }}
          noCard
          id="ReusableContainer"
        />
      </Modal>
      <Modal
        footer={null}
        visible={showTypes}
        destroyOnClose
        onCancel={() => setShowTypes(false)}
      >
        <DynamicForm
          onComplete={() => {
            refetch();
            setShowTypes(false);
          }}
          noCard
          id="ReusableContainerType"
        />
      </Modal>
    </>
  );
}
