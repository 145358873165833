import { Col, PageHeader, Row } from "antd";
import React from "react";
import { ChartLayoutGroup } from "../../graphql/generated";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import StatChart from "./StatChart";
import TableChart from "./TableChart";

export default function LayoutGroup({ group }: { group: ChartLayoutGroup }) {
  const charts = (group.charts as unknown) as ChartLayoutGroup["charts"][0]["__union"][];

  return (
    <PageHeader title={group.title} subTitle={group.description}>
      <Row>
        {charts.map((c) => (
          <Col flex={1}>
            {c.__typename === "TableChart" ? (
              <TableChart table={c} />
            ) : c.__typename === "StatChart" ? (
              <StatChart chart={c} />
            ) : c.__typename === "BarChart" ? (
              <BarChart bar={c} />
            ) : c.__typename === "PieChart" ? (
              <PieChart pie={c} />
            ) : null}
          </Col>
        ))}
      </Row>
    </PageHeader>
  );
}
