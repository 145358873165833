import { DatePicker, Result, Select, Space, Statistic, TimePicker } from "antd";
import Title from "antd/lib/typography/Title";
import { Chart, LineAdvance } from "bizcharts";
import moment from "moment";
import React, { useContext, useState } from "react";
import { DayOfWeek } from "../graphql/generated";
import { useTypedQuery } from "../graphql/hooks";
import { PARTNER_ANALYTICS } from "../graphql/queries";
import { RestaurantContext } from "../views/Restaurant";
import LayoutGroup from "./charts/LayoutGroup";

const DAY_OPTIONS = Object.keys(DayOfWeek).map((d, i) => ({
  label: d,
  value: (i + 1) % 7,
}));

export default function PartnerAnalytics() {
  const { data } = useContext(RestaurantContext);

  const [dayOfWeek, setDayOfWeek] = useState<number | undefined>(undefined);
  const [dateRange, setDateRange] = useState([
    moment().startOf("day"),
    moment().endOf("day"),
  ]);

  const [timeRangeMoment, setTimeRangeMoment] = useState([
    moment().startOf("day"),
    moment().endOf("day"),
  ]);
  const [timeRange, setTimeRange] = useState<[string, string]>([
    "00:00",
    "23:59",
  ]);

  const { data: analyticsData } = useTypedQuery(PARTNER_ANALYTICS, {
    skip: !data?.Restaurant.id,
    variables: {
      id: data?.Restaurant.id,
      startDate: dateRange[0],
      endDate: dateRange[1],
      timeRange,
      dayOfWeek,
    },
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <Title level={5} style={{ flex: 1 }}>
          Prep Time Stats
        </Title>
        <Select
          options={DAY_OPTIONS}
          allowClear
          style={{ minWidth: 100, marginRight: 8 }}
          onChange={(v) => setDayOfWeek(v as any)}
        />
        <TimePicker.RangePicker
          value={timeRangeMoment as any}
          onChange={(v, s) => {
            setTimeRangeMoment(v as any);
            setTimeRange(s);
          }}
          showMinute
          showSecond={false}
          format={"HH:mm"}
        />
        <DatePicker.RangePicker
          value={dateRange as any}
          onChange={(v) => setDateRange(v as any)}
        />
      </div>
      <Space>
        {analyticsData?.PartnerAnalytics.prepTimes.map((p) => (
          <Statistic
            title={p.percentile}
            key={p.percentile}
            value={p.value.toFixed(2)}
            suffix="mins"
          />
        ))}
      </Space>
      <Chart
        padding={[10, 20, 50, 40]}
        autoFit
        height={300}
        data={analyticsData?.PartnerAnalytics.prepTimes.map((p) => ({
          ...p,
          count: Math.ceil(
            (analyticsData.PartnerAnalytics.orderCount *
              (100 - +p.percentile)) /
              100
          ),
        }))}
      >
        <LineAdvance
          shape="smooth"
          point
          area
          position="value*count"
          color=""
        />
      </Chart>
      <Title level={5} style={{ flex: 1 }}>
        Wait Times
      </Title>
      <Space>
        {analyticsData?.PartnerAnalytics.waitTimes.map((p) => (
          <Statistic
            title={p.percentile}
            key={p.percentile}
            value={p.value.toFixed(2)}
            suffix="mins"
          />
        ))}
      </Space>
      <Chart
        padding={[10, 20, 50, 40]}
        autoFit
        height={300}
        data={analyticsData?.PartnerAnalytics.waitTimes.map((p) => ({
          ...p,
          count: Math.ceil(
            (analyticsData.PartnerAnalytics.orderCount *
              (100 - +p.percentile)) /
              100
          ),
        }))}
      >
        <LineAdvance
          shape="smooth"
          point
          area
          position="value*count"
          color=""
        />
      </Chart>

      {analyticsData?.PartnerAnalytics.layoutGroups.map((l) => (
        <LayoutGroup group={l} />
      ))}
      {analyticsData?.PartnerAnalytics.prepTimes.length === 0 &&
        analyticsData?.PartnerAnalytics.waitTimes.length === 0 && (
          <Result status="404" title="No Orders to compute for this period" />
        )}
    </div>
  );
}
