import { PlusOutlined, QuestionCircleFilled } from "@ant-design/icons";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import {
  $,
  CoreConfigurations,
  MapType,
  Query,
  RestaurantUpdateInput,
} from "../graphql/generated";
import { useTypedMutation, useTypedQuery } from "../graphql/hooks";
import { UPDATE_RESTAURANT_HEADER_IMAGE } from "../graphql/mutations";
import { RESTAURANT } from "../graphql/queries";
import { getCDNUrl } from "../utils/image-url";
import { useUpdateItemKey } from "../utils/update-item-key";
import CoreAppDisplay from "./CoreAppDisplay";
import VideoList from "./media/VideoList";
import PartnerBrand from "./partner/PartnerBrand";
import TagForm from "./TagForm";

export const RestaurantBranding = ({
  restaurant,
}: {
  restaurant: MapType<Query, typeof RESTAURANT>["Restaurant"];
}) => {
  const [show, setShow] = useState(false);
  const [showMedia, setShowMedia] = useState(false);

  const client = useApolloClient();
  const [
    headerImageRef,
    setHeaderImageRef,
  ] = useState<HTMLInputElement | null>();
  const [headerImageSrc, setHeaderImageSrc] = useState<ArrayBuffer | string>(
    restaurant.headerImage
  );
  const [updateHeaderImage, { loading: updating }] = useMutation(
    UPDATE_RESTAURANT_HEADER_IMAGE,
    {
      onCompleted: () => message.success("Updated header image!"),
    }
  );

  const previewHeaderImage = async () => {
    if (!headerImageRef?.files?.[0]) return;
    const reader = new FileReader();
    reader.onload = (e) => setHeaderImageSrc(e.target?.result as ArrayBuffer);
    reader.readAsDataURL(headerImageRef.files[0]);
  };

  const publishHeaderImage = async () => {
    if (!headerImageSrc) return;

    updateHeaderImage({
      variables: { image: headerImageRef!.files![0], id: restaurant.id },
    });
  };

  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (showSuccess) {
      const n = setTimeout(() => setShowSuccess(false), 3000);
      return () => clearTimeout(n);
    }
  });

  const [
    updateRestaurantMutation,
    { loading: updateRestaurantLoading },
  ] = useTypedMutation(
    {
      updateRestaurant: [
        {
          id: $`id`,
          restaurant: $`restaurant`,
        },
        {
          ...RESTAURANT.Restaurant[1],
        },
      ],
    },
    {
      onCompleted: () => message.success("Saved!"),
      onError: () => message.error("Failed."),
    }
  );

  const updateRestaurant = useUpdateItemKey<RestaurantUpdateInput>(
    updateRestaurantMutation,
    "restaurant",
    restaurant.id
  );

  const { data } = useTypedQuery({
    allPartnerTags: [
      {
        take: 50,
      },
      {
        id: true,
        name: true,
        type: true,
      },
    ],
  });

  const [form] = useForm();

  return (
    <>
      <Row gutter={32}>
        <Col span={12}>
          <Card title="Branding">
            <Row>
              <Col>
                {restaurant && data && (
                  <Form
                    form={form}
                    initialValues={{
                      ...restaurant,
                      tags: restaurant.tags.map((t) => t.id),
                    }}
                    layout="vertical"
                    onFinish={(v) => {
                      console.log(v);
                      updateRestaurantMutation({
                        variables: {
                          id: restaurant.id,
                          restaurant: {
                            ...v,
                          },
                        },
                      });
                    }}
                  >
                    <Form.Item name="configuration" label="App">
                      <Select>
                        {Object.keys(CoreConfigurations).map((k) => (
                          <Select.Option value={k}>
                            <CoreAppDisplay
                              configuration={CoreConfigurations[k]}
                            />{" "}
                            {k}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item name="name" label="Name">
                      <Input />
                    </Form.Item>

                    <Form.Item name="description" label="Description">
                      <Input />
                    </Form.Item>

                    <Form.Item name="exclusive" label="Exclusive to ecoeats?">
                      <Switch />
                    </Form.Item>

                    <Form.Item
                      label="Tags"
                      name="tags"
                      initialValue={restaurant.tags.map((t) => t.id)}
                    >
                      <Select
                        mode="multiple"
                        defaultValue={restaurant.tags.map((t) => t.id)}
                        options={data.allPartnerTags.map((t) => ({
                          value: t.id,
                          label: t.name,
                        }))}
                      />
                    </Form.Item>

                    <Button
                      onClick={() => setShow(true)}
                      icon={<PlusOutlined />}
                    >
                      Create New
                    </Button>

                    <Form.Item name="phoneNumber" label="Phone Number">
                      <Input required />
                    </Form.Item>

                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={updateRestaurantLoading}
                    >
                      Save {restaurant.name}
                    </Button>
                  </Form>
                )}
                <div className="mb-2" style={{ padding: "16px" }}>
                  <strong className="text-muted d-block my-2">
                    Header Image
                  </strong>
                  <input
                    id="headerImage"
                    type="file"
                    ref={setHeaderImageRef}
                    style={{ display: "none" }}
                    onChange={previewHeaderImage}
                  />
                  {/* eslint-disable-next-line */}
                  <div
                    onClick={() => headerImageRef?.click()}
                    style={{
                      width: 750,
                      height: 422,
                      marginBottom: 16,
                      background: "lightgrey",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      alt="restaurant"
                      src={headerImageSrc as any}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </div>
                  <Button loading={updating} onClick={publishHeaderImage}>
                    Upload &amp; Publish
                  </Button>
                </div>

                <div>
                  <Title level={5}>Header Video</Title>
                  {restaurant.headerVideo ? (
                    <video
                      style={{
                        maxWidth: 512,
                      }}
                    >
                      <source
                        src={getCDNUrl(restaurant.headerVideo.key)}
                      ></source>
                    </video>
                  ) : (
                    <Alert
                      message="No Header Image"
                      icon={<QuestionCircleFilled />}
                      type="info"
                    />
                  )}
                  <Button onClick={() => setShowMedia(true)}>Change</Button>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Brand">
            <PartnerBrand brand={restaurant.brand} partnerId={restaurant.id} />
          </Card>
        </Col>
      </Row>
      <Modal
        visible={show}
        onCancel={() => setShow(false)}
        title="Create Tag"
        footer={null}
      >
        <TagForm
          onCompleted={() => {
            client.reFetchObservableQueries(true);
            setShow(false);
          }}
        />
      </Modal>
      <Modal
        visible={showMedia}
        onCancel={() => setShowMedia(false)}
        footer={null}
      >
        <VideoList
          onSelect={(s) => {
            updateRestaurant("headerVideoId", s[0].id);
            setShowMedia(false);
          }}
        />
      </Modal>
    </>
  );
};
