import { SaveOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Card, Form, Input, InputNumber, Switch } from "antd";
import React from "react";
import { MenuCategory } from "../../graphql/generated";
import { ADD_CATEGORY } from "../../graphql/mutations";

export function AddCategoryForm({
  restaurant,
  menu,
  category,
  onSuccess = (): void => void 0,
}: {
  restaurant: {
    id: string;
  };
  menu?: {
    id: string;
  };
  category?: Partial<MenuCategory>;
  onSuccess?: () => void;
}) {
  const [addCategoryMutation, { loading }] = useMutation(ADD_CATEGORY, {
    onCompleted: () => onSuccess(),
  });

  const submitForm = (e) => {
    const input = {
      variables: {
        id: menu?.id,
        category: {
          id: category?.id,
          ...e,
        },
      },
    };

    addCategoryMutation(input);
  };

  return (
    <Card>
      <Form layout="vertical" initialValues={category} onFinish={submitForm}>
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <Input />
        </Form.Item>

        <Form.Item
          name="sortOrder"
          label="Sort Order"
          help="Priority for the placement of this category in the menu. Lower numbers are placed nearer the top, high numbers at the bottom."
        >
          <InputNumber type="number" step="1" />
        </Form.Item>

        <Form.Item valuePropName="checked" name="topLevel" label="Top Level?">
          <Switch />
        </Form.Item>

        <Button loading={loading} icon={<SaveOutlined />} htmlType="submit">
          Save
        </Button>
      </Form>
    </Card>
  );
}
