import {
  ClockCircleOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  message,
  Modal,
  Space,
  Switch,
  TimePicker,
  Typography,
} from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { $, DayOfWeek, OpeningTime } from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";
import { MENU } from "../../graphql/queries";
import CategoryHiddenSelect from "./CategoryHiddenSelect";
import DragSortingMenuTable from "./DragSortingMenuTable";

const options = Object.keys(DayOfWeek);

interface MenuCategoryType {
  id: string;
  name: string;
  items: any;
  times: any;
  description?: string;
  topLevel: boolean;
}

interface Props {
  category: MenuCategoryType;
  restaurantId: string;
  onAddItemPress: (id: string) => void;
  onEditPress: (category: MenuCategoryType) => void;
  selected: boolean;
  onSelected: (selected: boolean) => void;
  selectedItemIds: string[];
  addSelectedItemId: (itemId: string) => void;
  removeSelectedItemId: (itemId: string) => void;
}

const MenuCategory = (props: Props) => {
  const {
    category,
    restaurantId,
    selected,
    onSelected,
    selectedItemIds,
    addSelectedItemId,
    removeSelectedItemId,
  } = props;

  const _onClick = useCallback(() => {
    props.onEditPress(category);
  }, [props.onEditPress]);

  const items = category.items;

  const [openingTimes, setOpeningTimes] = useState<OpeningTime[]>(
    category.times
  );

  const [savedOpeningTimes, setSavedOpeningTimes] = useState<OpeningTime[]>(
    category.times
  );

  useEffect(() => {
    if (category?.times) {
      setOpeningTimes(category?.times);
      setSavedOpeningTimes(category?.times);
    }
  }, [category?.times]);

  const [optionsModalVisible, setOptionsModalVisible] = useState(false);

  const [categoryAlwaysVisible, setCategoryAlwaysVisible] = useState(
    !category.times.length
  );

  const [savedCategoryAlwaysVisible, setSavedCategoryAlwaysVisible] = useState(
    !category.times.length
  );

  useEffect(() => {
    if (category?.times) {
      setCategoryAlwaysVisible(!category.times.length);
      setSavedCategoryAlwaysVisible(!category.times.length);
    }
  }, [category?.times]);

  const [updateCategory, { loading }] = useTypedMutation(
    {
      updateCategoryAsRestaurant: [
        {
          category: {
            times: $`times`,
            topLevel: $`topLevel`,
          },
          restaurantId: $`restaurantId`,
          id: $`id`,
        },
        {
          id: true,
          description: true,
          name: true,
          sortOrder: true,
          times: {
            ...MENU.Menu[1].categories.times,
          },
        },
      ],
    },
    {
      onCompleted: () => {
        message.success("Saved!");
      },
      onError: () => {
        message.error("Failed to update category.");
      },
    }
  );

  const updateCategoryTimes = useCallback(() => {
    if (!categoryAlwaysVisible && openingTimes.length === 0) {
      message.warning("Set some times or choose 'Always Available'");
      return;
    }
    updateCategory({
      variables: {
        restaurantId,
        times: openingTimes.map((o) => ({
          close: o.close,
          open: o.open,
          daysOfWeek: o.daysOfWeek,
        })),
        id: category.id,
      },
    }).then(() => {
      setSavedOpeningTimes(openingTimes);
      setSavedCategoryAlwaysVisible(categoryAlwaysVisible);
    });
  }, [
    setSavedOpeningTimes,
    setSavedCategoryAlwaysVisible,
    categoryAlwaysVisible,
    openingTimes,
    category.id,
    restaurantId,
  ]);

  const addNewTime = useCallback(() => {
    if (
      openingTimes.length &&
      (!openingTimes[openingTimes.length - 1]?.daysOfWeek.length ||
        !openingTimes[openingTimes.length - 1]?.open ||
        !openingTimes[openingTimes.length - 1]?.close)
    ) {
      message.error("Fill out the previous opening time!");
      return;
    }

    setOpeningTimes([
      ...openingTimes,
      {
        close: "22:00",
        open: "10:00",
        daysOfWeek: [],
        id: Math.random().toString(),
      },
    ]);
  }, [openingTimes]);

  const [reorderCategory] = useTypedMutation(
    {
      reorderCategory: [
        {
          partnerId: $`partnerId`,
          categoryId: $`categoryId`,
          itemIds: $`itemIds`,
        },
        {
          id: true,
          items: {
            id: true,
            sortOrder: true,
          },
        },
      ],
    },
    {
      onCompleted: (data) => {
        message.success("Changed category order!");
      },
      onError: (err) => {
        message.error("Failed to re-order items.");
      },
    }
  );

  const reorderItems = useCallback(
    (menuItems) => {
      const variables = {
        partnerId: restaurantId,
        categoryId: category.id,
        itemIds: menuItems.map((item) => item.id),
      };
      reorderCategory({
        variables,
      });
    },
    [restaurantId, category.id]
  );

  const sortedItems = useMemo(
    () => items.slice().sort((a, b) => a.sortOrder - b.sortOrder),
    [items]
  );

  const setHidden = useCallback(
    (topLevel: boolean) => {
      updateCategory({
        variables: {
          topLevel,
          restaurantId,
          id: category.id,
          times: savedOpeningTimes.map((o) => ({
            close: o.close,
            open: o.open,
            daysOfWeek: o.daysOfWeek,
          })),
        },
      });
    },
    [restaurantId, category.id, savedOpeningTimes]
  );

  const onClick = useCallback(() => {
    props.onAddItemPress(category.id);
  }, [category.id]);

  return (
    <div id={category.id} style={{ marginBottom: 16 }}>
      <div style={{ flexDirection: "row", display: "flex", flexWrap: "wrap" }}>
        <span
          style={{
            marginBottom: 8,
            marginRight: 10,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={selected}
            onChange={(e) => onSelected(e.target.checked)}
          />
        </span>

        <Title level={4} style={{ flex: 1 }}>
          {category.name}{" "}
        </Title>

        <CategoryHiddenSelect
          loadin={loading}
          value={category.topLevel}
          onChange={(val) => setHidden(val)}
        />

        <Button
          style={{ marginLeft: 10, marginRight: 10 }}
          icon={<ClockCircleOutlined />}
          onClick={() => setOptionsModalVisible(true)}
        >
          Times
        </Button>

        <Button
          style={{ marginRight: 10 }}
          icon={<EditOutlined />}
          onClick={_onClick}
        >
          Edit
        </Button>

        <Button icon={<PlusOutlined />} onClick={onClick}>
          Add Item
        </Button>
      </div>
      <Modal
        title={`${category.name} Options`}
        visible={optionsModalVisible}
        onOk={() => setOptionsModalVisible(false)}
        onCancel={() => {
          setOptionsModalVisible(false);
          setOpeningTimes(savedOpeningTimes);
          setCategoryAlwaysVisible(savedCategoryAlwaysVisible);
        }}
        width="70vw"
        footer={null}
      >
        <Text>Category Always Visible</Text>
        <Switch
          style={{ marginLeft: 16 }}
          checked={categoryAlwaysVisible}
          onChange={(checked) => {
            setCategoryAlwaysVisible(checked);
            if (checked) {
              // reset the opening times
              // TODO: change if have always available field on server
              setOpeningTimes([]);
            }
          }}
        />
        <Form style={{ marginTop: 20 }} onFinish={updateCategoryTimes}>
          {openingTimes.map((o, i) => (
            <div key={i}>
              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <TimePicker.RangePicker
                    style={{
                      flex: 1,
                      marginRight: 8,
                    }}
                    showSecond={false}
                    format="HH:mm"
                    value={[moment(o.open, "HH:mm"), moment(o.close, "HH:mm")]}
                    onChange={([open, close]) =>
                      setOpeningTimes(
                        openingTimes.map((ot, ii) =>
                          ii === i
                            ? {
                                ...ot,
                                open: open!.format("HH:mm"),
                                close: close!.format("HH:mm"),
                              }
                            : ot
                        )
                      )
                    }
                  />
                  <Button
                    danger
                    icon={<CloseOutlined />}
                    onClick={() =>
                      setOpeningTimes(openingTimes.filter((_, ii) => i !== ii))
                    }
                  >
                    Remove
                  </Button>
                </div>
                <Checkbox.Group
                  style={{
                    marginTop: 8,
                  }}
                  onChange={(v) =>
                    setOpeningTimes(
                      openingTimes.map((ot, ii) =>
                        ii === i ? { ...ot, daysOfWeek: v as any } : ot
                      )
                    )
                  }
                  options={options}
                  value={o.daysOfWeek}
                />
              </Form.Item>
              <Divider />
            </div>
          ))}

          <Space>
            <Button
              disabled={categoryAlwaysVisible}
              icon={<PlusOutlined />}
              onClick={addNewTime.bind(null)}
            >
              Add New Time
            </Button>
            <Button
              loading={loading}
              htmlType="submit"
              type="primary"
              icon={<SaveOutlined />}
            >
              Save Category Times
            </Button>
          </Space>
        </Form>
      </Modal>
      {category.description && (
        <Typography.Text
          type="secondary"
          style={{ fontSize: 14, fontWeight: "normal" }}
        >
          {category.description}
        </Typography.Text>
      )}
      <DragSortingMenuTable
        partnerId={restaurantId}
        menuItems={sortedItems}
        setMenuItems={reorderItems}
        selectedItemIds={selectedItemIds}
        addSelectedItemId={addSelectedItemId}
        removeSelectedItemId={removeSelectedItemId}
      />
    </div>
  );
};

export default MenuCategory;
