import { formatDistance, isToday } from "date-fns";
import React from "react";
export const GenericAuditTableColumns = [
  {
    title: "Time",
    render: (_, o) => {
      const stamp = new Date(o.timestamp);
      if (isToday(stamp)) {
        return formatDistance(stamp, new Date(), {
          addSuffix: true,
        });
      }

      return stamp.toLocaleString();
    },
  },
  {
    title: "From",
    dataIndex: "from",
    render: (f) => <pre>{JSON.stringify(f, null, 2)}</pre>,
  },
  {
    title: "To",
    dataIndex: "to",
    render: (t) => <pre>{JSON.stringify(t, null, 2)}</pre>,
  },
];
