import { Select } from "antd";
import React from "react";
import { MapType, Query } from "../graphql/generated";
import { query, useTypedQuery } from "../graphql/hooks";

const ZONES_QUERY = query({
  zones: [
    {
      skip: 0,
      take: 50,
    },
    {
      id: true,
      name: true,
    },
  ],
});

type ZoneQueryResult = MapType<Query, typeof ZONES_QUERY>;
export default function ZoneAutoComplete({
  onChange,
  initialValue,
}: {
  onChange: (zoneIds: string[]) => void;
  initialValue?: string[];
}) {
  const { data, loading } = useTypedQuery(ZONES_QUERY);

  return (
    <Select
      defaultValue={initialValue}
      onChange={onChange}
      mode="multiple"
      dropdownMatchSelectWidth
      style={{
        minWidth: "100px",
      }}
      loading={loading}
      options={data?.zones.map((z) => ({ value: z.id, label: z.name }))}
    ></Select>
  );
}
