import { useMutation } from "@apollo/client";
import { getOperationName } from "apollo-link";
import React from "react";
import { Alert } from "react-bootstrap";
import {
  Col,
  Form,
  FormGroup,
  FormInput,
  ListGroup,
  ListGroupItem,
  Row,
} from "shards-react";
import { Restaurant } from "../graphql/generated";
import { UPDATE_RESTAURANT } from "../graphql/mutations";
import { _RESTAURANT } from "../graphql/queries";
import { useFormData } from "../hooks/useFormData";
import { LoadingButton } from "./LoadingButton";

export const RestaurantHygiene = ({
  restaurant,
}: {
  restaurant: Restaurant;
}) => {
  const [formData, onChange] = useFormData({
    hygieneRatingId: restaurant.hygiene?.authorityRatingId,
    allergyWarnings: restaurant.allergyWarnings,
  });
  const [updateRestaurant, { loading, error }] = useMutation(
    UPDATE_RESTAURANT,
    {
      refetchQueries: () => [getOperationName(_RESTAURANT)!],
    }
  );

  const submitForm = (e) => {
    e.preventDefault();
    const input = {
      variables: {
        id: restaurant.id,
        restaurant: {
          ...formData,
        },
      },
    };
    updateRestaurant(input);
  };

  return (
    <>
      <ListGroup flush>
        <ListGroupItem className="p-0 px-3 pt-3">
          <Row>
            <Col>
              {error
                ? error.graphQLErrors.map((e) => (
                    <Alert variant="danger">{JSON.stringify(e)}</Alert>
                  ))
                : null}

              {error?.networkError ? (
                <Alert variant="danger">
                  {JSON.stringify(error?.networkError?.message)}
                </Alert>
              ) : null}

              <img
                alt="authority rating"
                src={restaurant.hygiene?.authorityRatingImage}
              />

              <Form>
                <FormGroup>
                  <label>Authority Rating ID</label>
                  <FormInput
                    name="hygieneRatingId"
                    value={formData.hygieneRatingId}
                    type="hygieneRatingId"
                    onChange={onChange}
                  />
                </FormGroup>

                <FormGroup>
                  <label>Allergy Warnings</label>
                  <FormInput
                    multiline
                    name="allergyWarnings"
                    value={formData.allergyWarnings}
                    type="allergyWarnings"
                    onChange={onChange}
                  />
                </FormGroup>

                <FormGroup>
                  <LoadingButton
                    type="submit"
                    onClick={submitForm}
                    loading={loading}
                  >
                    Update Hygiene
                  </LoadingButton>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </>
  );
};
