import { useTypedQuery } from "../graphql/hooks";
import { ME } from "../graphql/queries";

export default function useMe() {
  const query = useTypedQuery(ME);

  const { data } = query;

  return { query, me: data && "name" in data.me ? data.me : null };
}
