import { Badge, DatePicker, Input, Space, Table } from "antd";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTypedQuery } from "../../graphql/hooks";
import { PROMOTION_LIST } from "../../graphql/queries";
import usePagination from "../../hooks/usePagination";
import { rangeInFuture } from "../../utils/is-future";

export default function PromotionTable() {
  const { args, onChange, pagination, setQuery } = usePagination({
    defaultOrder: "ASC",
    defaultSortBy: "start",
  });
  const [start, setStart] = useState(moment().subtract(1, "day"));

  const history = useHistory();

  const { data, loading } = useTypedQuery(PROMOTION_LIST, {
    variables: {
      ...args,
      start,
    },
  });

  const list = data?.PromotionList;

  return (
    <Table
      title={() => (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Title style={{ flex: 1 }} level={5}>
            {list?.total} Promotions
          </Title>{" "}
          <Space>
            <Input onChange={(e) => setQuery(e.target.value)} />
            <DatePicker value={start} onChange={(v) => v && setStart(v)} />
          </Space>
        </div>
      )}
      dataSource={list?.items}
      onChange={onChange}
      loading={loading}
      pagination={{
        total: list?.total,
        ...pagination,
      }}
      columns={[
        {
          title: "Name",
          dataIndex: "name",
          render: (_, r) => <Link to={`/promotion/${r.id}`}>{r.name}</Link>,
          sorter: true,
        },
        {
          title: "Body",
          dataIndex: "body",
          sorter: true,
        },
        {
          title: "Start",
          dataIndex: "start",
          render: (r) => moment(r).fromNow(),
          sorter: true,
        },
        {
          title: "End",
          dataIndex: "end",
          render: (r) => moment(r).fromNow(),
          sorter: true,
        },
        {
          render: (r) => {
            const { isFuture, isNow } = rangeInFuture([r.start, r.end]);
            return (
              <Badge
                text={
                  !r.enabled
                    ? "Disabled"
                    : isFuture
                    ? "Scheduled"
                    : isNow
                    ? "Active"
                    : "Complete"
                }
                status={
                  !r.enabled
                    ? "error"
                    : isFuture
                    ? "default"
                    : isNow
                    ? "processing"
                    : "success"
                }
              />
            );
          },
        },
      ]}
    />
  );
}
