import { Axis, Chart, Coordinate, Interval, Tooltip } from "bizcharts";
import React from "react";
import { PieChart as BC } from "../../graphql/generated";

export default function PieChart({ pie }: { pie: BC }) {
  const total = pie.values.reduce((a, c) => a + c, 0);

  return (
    <Chart
      padding={[10, 20, 50, 40]}
      autoFit
      height={300}
      data={pie.labels.map((l, i) => ({
        item: l,
        count: pie.values[i],
        percent: pie.values[i] / total,
      }))}
      cols={{
        percent: {
          formatter: (val) => {
            val = val * 100 + "%";
            return val;
          },
        },
      }}
    >
      <Coordinate type="theta" radius={0.75} />
      <Tooltip showTitle={false} />
      <Axis visible={false} />
      <Interval
        position="percent"
        adjust="stack"
        color="item"
        style={{
          lineWidth: 1,
          stroke: "#fff",
        }}
        label={[
          "count",
          {
            content: (data) => {
              return `${data.item}: ${(data.percent * 100).toFixed(2)}%`;
            },
          },
        ]}
      />
    </Chart>
  );
}
