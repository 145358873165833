import { Badge, Table } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { $ } from "../../../graphql/generated";
import { query, useTypedQuery } from "../../../graphql/hooks";
import useOnScreen from "../../../hooks/useOnScreen";

const Q = query({
  CommissionPlan: [
    {
      id: $`id`,
    },
    {
      id: true,
      description: true,
      slug: true,
      partnerPlans: {
        id: true,
        created: true,
        ended: true,
        rung: {
          id: true,
          name: true,
          description: true,
          percentage: true,
          fixedBase: true,
          deliveryFeeBaseDiff: true,
          deliveryFeePerKMDiff: true,
        },

        partner: {
          id: true,
          name: true,
        },
      },
    },
  ],
});

export default function PartnersOnPlan({ planId }: { planId: string }) {
  const ref = useRef<HTMLDivElement | null>(null);
  const load = useOnScreen(ref);

  const { data, loading } = useTypedQuery(Q, {
    skip: !load,
    variables: {
      id: planId,
    },
  });

  const plan = data?.CommissionPlan;
  const plans = data?.CommissionPlan.partnerPlans;

  return (
    <div ref={ref}>
      <Table
        title={(d) => <Title level={5}>{d.length} Partners</Title>}
        dataSource={plans}
        loading={loading}
        columns={[
          {
            title: "Partner",
            render: (_, r) => (
              <Link to={`/restaurant/${r.partner.id}`}>{r.partner.name}</Link>
            ),
          },
          {
            title: "Started",
            dataIndex: "created",
            render: (r) => new Date(r).toLocaleString(),
          },
          {
            title: "Rung",
            render: (_, r) => r.rung.name,
          },
          {
            title: "Ended",
            render: (_, r) => (
              <Badge
                status={r.ended ? "success" : "processing"}
                text={r.ended ? new Date(r.ended).toLocaleString() : "Active"}
              />
            ),
          },
        ]}
      />
    </div>
  );
}
