import {
  Badge,
  Card,
  DatePicker,
  Descriptions,
  message,
  PageHeader,
  Switch,
  Table,
} from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  $,
  DiscountType,
  DiscountUpdateInput,
  Ordering,
} from "../graphql/generated/graphql-zeus";
import { query, useTypedMutation, useTypedQuery } from "../graphql/hooks";
import { rangeInFuture } from "../utils/is-future";
import { price } from "../utils/price";

const DISCOUNT = query({
  Discount: [
    {
      id: $`id`,
    },
    {
      id: true,
      code: true,
      description: true,
      maxUsesGlobally: true,
      maxUsesPerCustomer: true,
      minimumTotalPrice: true,
      referralAmount: true,
      type: true,
      validFrom: true,
      enabled: true,
      validUntil: true,
      value: true,
      riderReferral: {
        id: true,
        associationDate: true,
        rider: {
          id: true,
          name: true,
        },
      },
      orders: [
        {
          order: $`order`,
          sortBy: $`sortBy`,
          skip: $`skip`,
          take: $`take`,
        },
        {
          hasMore: true,
          total: true,
          items: {
            id: true,
            placedAt: true,
            number: true,
          },
        },
      ],
    },
  ],
});

function DiscountStatus(r) {
  const { isFuture, isNow } = rangeInFuture([r.validFrom, r.validUntil]);
  return (
    <Badge
      text={
        !r.enabled
          ? "Disabled"
          : isFuture
          ? "Scheduled"
          : isNow
          ? "Active"
          : "Complete"
      }
      status={
        !r.enabled
          ? "error"
          : isFuture
          ? "default"
          : isNow
          ? "processing"
          : "success"
      }
    />
  );
}

export default function Discount() {
  const { id } = useParams();

  const [page, setSkip] = useState(1);
  const [take, setTake] = useState(10);

  const history = useHistory();
  const { data, updateQuery } = useTypedQuery(DISCOUNT, {
    variables: {
      id,
      order: Ordering.DESC,
      sortBy: "placedAt",
      skip: (page - 1) * take,
      take,
    },
  });

  const [updateDiscount, { loading: updating }] = useTypedMutation(
    {
      updateDiscount: [
        {
          discount: $`discount`,
          id: $`id`,
        },
        {
          id: true,
          code: true,
          description: true,
          maxUsesGlobally: true,
          maxUsesPerCustomer: true,
          minimumTotalPrice: true,
          referralAmount: true,
          type: true,
          validFrom: true,
          enabled: true,
          validUntil: true,
          value: true,
        },
      ],
    },
    {
      onCompleted: (d) => {
        message.success("Saved!");
        updateQuery((p) => ({
          Discount: {
            ...p.Discount,
            ...d.updateDiscount,
          },
        }));
      },
    }
  );

  const updateItemKey = useCallback(
    (key: keyof DiscountUpdateInput, value: any) =>
      updateDiscount({
        variables: {
          id,
          discount: {
            [key]: value,
          },
        },
      }),
    [updateDiscount]
  );

  const discount = data?.Discount;

  return (
    <>
      <PageHeader
        title={discount?.code}
        subTitle="Discount"
        onBack={() => history.push("/discounts")}
        extra={[discount && <DiscountStatus {...discount} />]}
      >
        <Descriptions bordered>
          <DescriptionsItem label="Type">{discount?.type}</DescriptionsItem>
          <DescriptionsItem label="Value">
            {discount?.type === DiscountType.PERCENTAGE
              ? `${discount?.value}%`
              : discount?.type === DiscountType.FIXED
              ? price(discount?.value!)
              : "N/A"}
          </DescriptionsItem>
          <DescriptionsItem label="Validity">
            <DatePicker.RangePicker
              value={
                discount
                  ? [moment(discount?.validFrom), moment(discount.validUntil)]
                  : undefined
              }
              showTime
              onChange={(v) =>
                updateDiscount({
                  variables: {
                    id,
                    discount: {
                      validFrom: v?.[0]?.toDate(),
                      validUntil: v?.[1]?.toDate(),
                    },
                  },
                })
              }
            />
          </DescriptionsItem>
          <DescriptionsItem label="Max Global Uses">
            <Text
              editable={{
                onChange: (v) =>
                  updateItemKey("maxUsesGlobally", Number.parseInt(v, 10)),
              }}
            >
              {discount?.maxUsesGlobally}
            </Text>
          </DescriptionsItem>
          <DescriptionsItem label="Max Customer Uses">
            <Text
              editable={{
                onChange: (v) =>
                  updateItemKey("maxUsesPerCustomer", Number.parseInt(v, 10)),
              }}
            >
              {discount?.maxUsesPerCustomer}
            </Text>
          </DescriptionsItem>
          <DescriptionsItem label="Referral Payout to Rider when used">
            <Text
              editable={{
                onChange: (v) =>
                  updateItemKey("referralAmount", Number.parseInt(v, 10)),
              }}
            >
              {discount?.referralAmount}
            </Text>
          </DescriptionsItem>
          <DescriptionsItem label="Enabled?">
            <Switch
              loading={updating}
              checked={discount?.enabled}
              onChange={(e) => updateItemKey("enabled", e)}
            />
          </DescriptionsItem>
          <DescriptionsItem label="Description">
            <Text
              editable={{
                onChange: (v) => updateItemKey("description", v),
              }}
            >
              {discount?.description}
            </Text>
          </DescriptionsItem>
          <DescriptionsItem label="Rider">
            <Badge
              status={discount?.riderReferral ? "success" : "default"}
              text={
                discount?.riderReferral ? (
                  <Link to={`/rider/${discount?.riderReferral.rider.id}`}>
                    {discount?.riderReferral.rider.name}
                  </Link>
                ) : (
                  <Text type="secondary">No Rider</Text>
                )
              }
            />
          </DescriptionsItem>
        </Descriptions>
      </PageHeader>

      <Card
        title={<Title level={4}>{data?.Discount.orders.total} Orders</Title>}
      >
        <Table
          dataSource={data?.Discount.orders.items}
          pagination={{
            total: data?.Discount.orders.total,
            current: page,
            pageSize: take,
            onChange: (p, ps) => {
              if (ps) setTake(ps);
              setSkip(p);
            },
            pageSizeOptions: ["10", "25", "50"],
            showSizeChanger: true,
          }}
          columns={[
            {
              title: "Number",
              dataIndex: "number",
              render: (_, r) => <Link to={`/order/${r.id}`}>{r.number}</Link>,
            },
            {
              title: "Placed",
              dataIndex: "placedAt",
              render: (r) => new Date(r).toLocaleString(),
            },
          ]}
        />
      </Card>
    </>
  );
}
