import { Badge } from "antd";
import React from "react";
import { DayOfWeek, OpeningTime } from "../../graphql/generated";
const todaysDayOfWeek = Object.keys(DayOfWeek)[
  (new Date().getDay() + 6) % 7
] as DayOfWeek;
export default function OpenToday({
  o,
}: {
  o: Pick<OpeningTime, "open" | "close" | "daysOfWeek">[];
}) {
  return (
    <>
      (
      {o
        .filter((o) => o.daysOfWeek.includes(todaysDayOfWeek))
        .map((o) => `${o.open.substring(0, 5)} - ${o.close.substring(0, 5)}`)
        .join(", ") || <Badge status="warning" text="Closed" />}
      )
    </>
  );
}
