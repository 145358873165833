import {
  ApolloError,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationResult,
  OperationVariables,
} from "@apollo/client";
import { message } from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  MapType,
  Mutation,
  ValueTypes,
} from "../graphql/generated/graphql-zeus";
import { useTypedMutation } from "../graphql/hooks";
import { onError } from "../utils/on-error";

export default function useBulkUpdate<Q extends ValueTypes["Mutation"]>(
  // the mutation to call multiple times in each bulk update
  mutation: Q,
  handlers: {
    // called when any of the individual mutations fails
    //  with the error provided to onError() in the mutation which failed
    onError: (error?: ApolloError) => void;
    // called when all of the mutations in a bulk update failed
    // with the array of responses provided to onSuccess.
    onSuccess: (responses: MapType<Mutation, Q>[]) => void;
  } = {
      onError: (e) =>
        e ? onError(e) : message.error("Failed to perform Bulk Update"),
      onSuccess: () => {
        message.success("Successful Bulk Update.");
      },
    },
  // the options that would normally be provided to useTypedMutation
  options?: MutationHookOptions<MapType<Mutation, Q>, Record<string, any>>
) {
  // indicates whether or not a bulk update is in progress
  const [bulkUpdateInProgress, setBulkUpdateInProgress] = useState(false);
  // indicates whether the bulk update failed
  const [bulkUpdateFailed, setBulkUpdateFailed] = useState(false);
  // the error which caused the bulk update to fail
  const [bulkUpdateError, setBulkUpdateError] = useState<
    ApolloError | undefined
  >(undefined);
  // the number of responses that we are waiting for
  const [
    bulkUpdateAwaitingResponses,
    setBulkUpdateAwaitingResponses,
  ] = useState(0);
  // the successful responses from the mutation
  const [bulkUpdateResponses, setBulkUpdateResponses] = useState<
    MapType<Mutation, Q>[]
  >([]);

  const [mutationFunc, mutationOptions] = useTypedMutation(mutation, {
    ...options,
    onError: (e) => {
      // set the error and indicate the bulk update failed
      setBulkUpdateError(e);
      setBulkUpdateFailed(true);
    },
    onCompleted: (d) => {
      // add the response to the list and decrement the count of responses to wait for
      setBulkUpdateResponses((bulkUpdateResponses) => {
        return [...bulkUpdateResponses, d];
      });
      setBulkUpdateAwaitingResponses((awaitingResponses) => {
        return awaitingResponses - 1;
      });
    },
  });

  useEffect(() => {
    if (
      bulkUpdateInProgress &&
      (bulkUpdateAwaitingResponses === 0 || bulkUpdateFailed)
    ) {
      if (bulkUpdateFailed) {
        handlers.onError(bulkUpdateError);
      } else {
        handlers.onSuccess(bulkUpdateResponses);
      }
      setBulkUpdateInProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkUpdateFailed, bulkUpdateAwaitingResponses]);

  type BulkUpdateArgs = (
    | MutationFunctionOptions<MapType<Mutation, Q>, OperationVariables>
    | undefined
  )[];

  type BulkUpdateTask = BulkUpdateArgs;

  const [pendingBulkUpdateTasks, setPendingBulkUpdateTasks] = useState<
    BulkUpdateTask[]
  >([]);

  // if there isn't an update in progress and there are pending tasks then execute the first one queued.
  useEffect(() => {
    if (pendingBulkUpdateTasks.length > 0 && !bulkUpdateInProgress) {
      const pendingBulkUpdateTaskArgs = pendingBulkUpdateTasks[0] as BulkUpdateArgs;
      // initialise state of the bulk update
      setBulkUpdateInProgress(true);
      setBulkUpdateFailed(false);
      setBulkUpdateError(undefined);
      setBulkUpdateAwaitingResponses(pendingBulkUpdateTaskArgs.length);
      setBulkUpdateResponses([]);
      // call the mutation function with each of the arguments in the task
      pendingBulkUpdateTaskArgs.forEach((mutationArg) => {
        mutationFunc(mutationArg);
      });
      // remove the task from the set of pending tasks
      setPendingBulkUpdateTasks((pendingBulkUpdateTasks) =>
        pendingBulkUpdateTasks.filter(
          (task) => task !== pendingBulkUpdateTaskArgs
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingBulkUpdateTasks, bulkUpdateInProgress]);

  // the callback called by the user
  // queues a task
  const bulkUpdate = useCallback((mutationArgs: BulkUpdateArgs) => {
    setPendingBulkUpdateTasks((pendingBulkUpdateTasks) => {
      return [...pendingBulkUpdateTasks, mutationArgs];
    });
  }, []);
  // in the form of the return values of useTypedMutation
  // return [bulkUpdate, mutationOptions];
  return [bulkUpdate, mutationOptions] as [
    (bulkUpdateArgs: BulkUpdateArgs) => void,
    MutationResult<MapType<Mutation, Q>>
  ];
}
