import { Badge, Button, message, Space, Tooltip } from "antd";
import { isBefore } from "date-fns";
import moment from "moment";
import React from "react";
import { useTypedMutation } from "../../graphql/hooks";
export const RiderBadge = ({ rider }) => {
  const throttledUntil = new Date(rider.throttledUntil);
  const isThrottled = isBefore(new Date(), throttledUntil);

  const [unthrottle, { loading: unthrottling }] = useTypedMutation(
    {
      unthrottleRider: [
        {
          id: rider.id,
        },
        {
          id: true,
          throttledUntil: true,
        },
      ],
    },
    {
      onCompleted: () => message.success("Unthrottled!"),
    }
  );

  const diff = moment(rider.throttledUntil).diff(moment(), "minutes");

  return (
    <Space>
      <Badge
        status={
          rider.activationStatus === "APPROVED"
            ? "success"
            : rider.activationStatus === "REJECTED"
            ? "error"
            : "default"
        }
        text={rider.activationStatus.split("_").join(" ")}
        style={{ cursor: "pointer", marginRight: 8, fontSize: 14 }}
      />

      <Tooltip title={`Last Online ${moment(rider.lastOnline).fromNow()}`}>
        <Badge
          status={rider.online ? "success" : "default"}
          text={rider.online ? "Online" : "Offline"}
        />
      </Tooltip>
      <Tooltip title={`Throttled ${moment(rider.throttledUntil).fromNow()}`}>
        <Badge
          status={diff > 0 ? "error" : diff >= -3 ? "warning" : "success"}
          text={
            diff >= 0
              ? "Throttled"
              : diff >= -3
              ? "Recently Throttled"
              : "Not Throttled"
          }
        />
      </Tooltip>
      {diff >= -3 ? (
        <Button onClick={() => unthrottle()} loading={unthrottling}>
          Unthrottle
        </Button>
      ) : null}
    </Space>
  );
};
