import { PageHeader } from "antd";
import React from "react";
import ItemCreationRequest from "../components/menu-requests/ItemCreationRequests";
import ItemUpdateRequests from "../components/menu-requests/ItemUpdateRequests";
import { ItemUpdateRequestStatus } from "../graphql/generated/graphql-zeus";

export default function MenuRequests() {
  return (
    <div>
      <PageHeader
        title="Menu Requests"
        subTitle="Edit requests from Partners"
      />
      <ItemCreationRequest />
      <ItemUpdateRequests />
    </div>
  );
}
