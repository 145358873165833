import { Button, Checkbox, Form, Modal, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import Link from "antd/lib/typography/Link";
import Paragraph from "antd/lib/typography/Paragraph";
import Text from "antd/lib/typography/Text";
import React, { useEffect } from "react";
import {
  allZoneOverviewPanelTabs,
  ZoneOverviewPanelTab,
} from "./ZoneOverviewPanel";

export interface OperationsSettings {
  showRiderStats: boolean;
  hiddenTabs: ZoneOverviewPanelTab[];
  autoFullscreenTabs: ZoneOverviewPanelTab[];
  autoExitFullscreen: boolean;
}

interface Props {
  visible: boolean;
  initialSettings: OperationsSettings;
  onCancel: () => void;
  onChangeSettings: (operationsSettings: OperationsSettings) => void;
  onResetSettings: () => void;
}

const OperationsSettingsModal = (props: Props) => {
  const {
    visible,
    initialSettings,
    onCancel,
    onChangeSettings,
    onResetSettings,
  } = props;

  const [form] = useForm<OperationsSettings>();

  useEffect(() => {
    // when form becomes visible reset the fields to their initialValues, passed as a prop
    if (visible) {
      console.log("setting initial settings", initialSettings);
      form.setFieldsValue(initialSettings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    console.log("initial show rider stats", initialSettings.showRiderStats);
  }, [initialSettings.showRiderStats]);

  return (
    <Modal
      visible={visible}
      title="Operations View Settings"
      onCancel={onCancel}
      onOk={() => onChangeSettings(form.getFieldsValue())}
      zIndex={1071}
    >
      <Form form={form} layout="vertical">
        <Form.Item valuePropName="checked" name="showRiderStats" noStyle>
          <Checkbox>Show Rider Stats?</Checkbox>
        </Form.Item>
        <div style={{ marginBottom: 24 }}>
          <Text type="secondary">
            Whether to show the rider statistics (e.g. Active Riders) in the
            header.
          </Text>
        </div>
        <Form.Item
          name="hiddenTabs"
          label="Hidden Tabs"
          style={{ marginBottom: 0 }}
        >
          <Select allowClear dropdownStyle={{ zIndex: 1072 }} mode="multiple">
            {allZoneOverviewPanelTabs.map((tab) => (
              <Select.Option value={tab.id}>{tab.displayName}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div style={{ marginBottom: 24 }}>
          <Text type="secondary">
            The selected tabs will not be shown as options in the header.
          </Text>
        </div>
        <Form.Item
          name="autoFullscreenTabs"
          label="Auto Fullscreen Tabs"
          style={{ marginBottom: 0 }}
        >
          <Select dropdownStyle={{ zIndex: 1072 }} allowClear mode="multiple">
            {allZoneOverviewPanelTabs.map((tab) => (
              <Select.Option value={tab.id}>{tab.displayName}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div style={{ marginBottom: 24 }}>
          <Text type="secondary">
            Some tabs may be too small to display only on part of your screen.
            When changing to the selected tabs the view will automatically
            change to fullscreen.
          </Text>
        </div>
        <Form.Item valuePropName="checked" name="autoExitFullscreen" noStyle>
          <Checkbox>Auto Exit Fullscreen?</Checkbox>
        </Form.Item>
        <div style={{ marginBottom: 24 }}>
          <Text type="secondary">
            When switching from an automatically fullscreened tab should the
            view exit fullscreen automatically?
          </Text>
        </div>
        <Button
          danger
          onClick={() => {
            Modal.confirm({
              zIndex: 1073,
              title: "Reset Settings and Table Configs?",
              content: (
                <>
                  <Paragraph type="secondary">
                    This will reset all Operations Tab's settings to default as
                    well as resetting the page, filters and sorting
                    configuration of all tables in the admin dash.
                  </Paragraph>
                  <Paragraph type="secondary">
                    This will cause a page refresh.
                  </Paragraph>
                  <Text type="secondary">
                    If this reset is required because of a bug in the Operations
                    View please report it to Engineering
                  </Text>{" "}
                  <Link
                    href="https://app.clickup.com/2494053/v/l/6-29984501-1"
                    target="_blank"
                  >
                    here
                  </Link>
                  <Text type="secondary">.</Text>
                </>
              ),
              okText: "Reset",
              okType: "danger",
              onOk: onResetSettings,
            });
          }}
        >
          Reset Settings
        </Button>
      </Form>
    </Modal>
  );
};

export default OperationsSettingsModal;
