import { PlusOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Space, Table, Tooltip } from "antd";
import Modal from "antd/lib/modal/Modal";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React, { forwardRef, useCallback, useRef, useState } from "react";
import { useImperativeHandle } from "react";
import { useEffect } from "react";
import L, { Marker, Popup } from "react-leaflet";
import { Link, useHistory } from "react-router-dom";
import { Zone } from "../../graphql/generated";
import { useTypedQuery } from "../../graphql/hooks";
import { RESTAURANT_LIST } from "../../graphql/queries";
import usePagination, { Columns } from "../../hooks/usePagination";
import { DeepPartial } from "../../utils/deep-partial";
import { getImageUrl } from "../../utils/image-url";
import Map from "../../utils/Map";
import OpenToday from "../common/OpenToday";
import { formatAddress } from "../forms/AddAddressForm";
import { OnboardRestaurantForm } from "../forms/OnboardRestaurantForm";
import { RestoIcon } from "../RestaurantDelivery";
import { RestaurantStatus } from "../RestaurantStatus";

export type ZonePartnersRef = {
  refreshMap: () => void;
} | null;

interface Props {
  zone?: DeepPartial<Zone>;
  persistContextId?: string;
  visible?: boolean;
}

const ZonePartners = (
  { zone, persistContextId, visible = true }: Props,
  ref
) => {
  const {
    pagination,
    args: { order, skip, sortBy, take },
    onChange,
    applyDefaultSortOrder,
  } = usePagination({
    defaultOrder: "ASC",
    defaultSortBy: "name",
    persistContextId: `zone-partners-table-${persistContextId}`,
  });

  const { data, loading } = useTypedQuery(RESTAURANT_LIST, {
    fetchPolicy: "network-only",
    variables: {
      zoneId: zone?.id,
      skip,
      take,
      order,
      sortBy,
    },
  });

  const history = useHistory();

  const [show, setShow] = useState(false);

  const mapRef = useRef<L.Map>();

  const list = data?.RestaurantList;

  // allows parent components to refresh the map by calling refreshMap()
  useImperativeHandle(
    ref,
    () => ({
      refreshMap() {
        mapRef.current?.leafletElement.invalidateSize();
      },
    }),
    []
  );

  return (
    <div style={{ display: !visible ? "none" : undefined }}>
      <Map
        zoom={12}
        center={list?.items[0]?.address.coordinates?.coordinates || [0, 0]}
        ref={(ref: L.Map) => (mapRef.current = ref)}
      >
        {list?.items.map((i) => (
          <Marker
            key={i.id}
            position={i.address.coordinates!.coordinates}
            icon={RestoIcon(i.headerImageKey, i.configuration)}
          >
            <Popup>
              <Badge
                status={i.online ? "success" : "warning"}
                text={
                  <Title level={5} style={{ display: "inline" }}>
                    {i.name}
                  </Title>
                }
              />
              <br />
              <Text>{formatAddress(i.address)}</Text>
              <br />
              <Text type="secondary">{i.address.instructions}</Text>
            </Popup>
          </Marker>
        ))}
      </Map>

      <Table
        dataSource={list?.items}
        loading={loading}
        onChange={onChange}
        rowKey={(r) => r.id}
        title={() => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Title level={4} style={{ flex: 1 }}>
              {list?.total} Partners
            </Title>

            <Space>
              <Button
                type="primary"
                icon={<PlusOutlined style={{ verticalAlign: "middle" }} />}
                onClick={() => setShow(true)}
              >
                Onboard Partner
              </Button>
            </Space>
          </div>
        )}
        pagination={{
          ...pagination,
          total: data?.RestaurantList.total,
        }}
        columns={applyDefaultSortOrder([
          {
            key: "configuration",
            render: (_, r) => (
              <Avatar.Group>
                <Tooltip
                  title={
                    <Link to={`/configuration/${r.configuration}`}>
                      {r.configuration}
                    </Link>
                  }
                >
                  <Avatar
                    src={getImageUrl(
                      `assets/app-icons/${r.configuration.toLowerCase()}.png`,
                      64,
                      64
                    )}
                  />
                </Tooltip>

                {r.brand && (
                  <Tooltip title={r.brand.name}>
                    <Avatar src={getImageUrl(r.brand.logo.key, 64, 64)} />
                  </Tooltip>
                )}
              </Avatar.Group>
            ),
            sorter: true,
          },
          {
            title: "Name",
            dataIndex: "name",
            sorter: true,
            render: (name, r) => <Link to={`/restaurant/${r.id}`}>{name}</Link>,
          },
          {
            title: "Zone",
            render: (_, r) => (
              <Link to={`/zone/${r.zone.id}`}>{r.zone.name}</Link>
            ),
          },
          {
            sorter: true,
            title: "Description",
            dataIndex: "description",
          },
          {
            title: "Prep Time",
            key: "basePrepTime",
            dataIndex: "currentPrepTime",
            sorter: true,
          },
          {
            title: "Open Today?",
            dataIndex: "openingTimes",
            sorter: true,
            render: (o) => <OpenToday o={o} />,
          },
          {
            title: "Status",
            render: (res) => <RestaurantStatus restaurant={res} />,
          },
        ] as Columns<NonNullable<typeof list>["items"][0]>)}
      />

      <Modal visible={show} onCancel={() => setShow(false)} footer={null}>
        <OnboardRestaurantForm
          onComplete={() => setShow(false)}
          zoneId={zone?.id}
        />
      </Modal>
    </div>
  );
};

export default forwardRef<ZonePartnersRef, Props>(ZonePartners);
