import { Table } from "antd";
import React, { useState } from "react";
import { query, useTypedQuery } from "../../graphql/hooks";
import { OFFER_LIST } from "../../graphql/queries";
import usePagination from "../../hooks/usePagination";
import { OrderOfferTableColumns } from "../order/OrderOfferTable";

export default function ZoneOffers({
  zoneId,
  persistContextId: propsPersistContextId,
  visible = true,
}: {
  zoneId: string;
  persistContextId?: string;
  visible?: boolean;
}) {
  const persistContextId = propsPersistContextId
    ? `${propsPersistContextId}-zone-offers`
    : undefined;

  const {
    pagination,
    args: { order, page, query, sortBy, take },
    onChange,
  } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "offered",
    persistContextId: persistContextId,
  });

  const { data, loading } = useTypedQuery(OFFER_LIST, {
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
      includeCompleted: true,
      zoneId,
    },
    pollInterval: 5000,
    fetchPolicy: "cache-and-network",
  });
  const offers = data?.FlightPlanOfferList.items || [];

  return (
    <div style={{ display: !visible ? "none" : undefined }}>
      <Table
        dataSource={offers}
        loading={loading}
        columns={OrderOfferTableColumns}
        rowKey="id"
        expandable={{
          rowExpandable: (r) => !!r.context,
          expandedRowRender: (r) => (
            <pre>{JSON.stringify(r.context, null, 2)}</pre>
          ),
        }}
        onChange={onChange}
        pagination={{
          ...pagination,
          total: data?.FlightPlanOfferList.total,
        }}
      />
    </div>
  );
}
