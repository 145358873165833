import { Chart, Interval } from "bizcharts";
import React from "react";
import { BarChart as BC } from "../../graphql/generated";

export default function BarChart({ bar }: { bar: BC }) {
  return (
    <Chart
      padding={[10, 20, 50, 40]}
      autoFit
      height={300}
      data={bar.series[0].x_values.map((v, i) => ({
        a: v,
        b: bar.series[0].y_values[i],
      }))}
    >
      <Interval position="a*b" />
    </Chart>
  );
}
