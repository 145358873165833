import create from "zustand";
import { configurePersist } from "zustand-persist";
import { Configuration, MapType } from "../graphql/generated";
import { PaginationConfig } from "../hooks/usePagination";
import { OpsConfig } from "../views/Operations";

const { persist } = configurePersist({
  storage: localStorage,
});

export type Store = {
  darkMode: boolean;
  collapseSider: boolean;
  toggleSider: () => void;
  toggleDark: () => void;
  apiConfig: Configuration;
  setApiConfig: (apiConfig: Configuration) => void;
  selectedOpZoneIds: string[];
  setSelectedOpZoneIds: (zoneIds: string[]) => void;
  opsConfig: OpsConfig;
  updateOpsConfig: (newPreferences: Partial<OpsConfig>) => void;
  // used to persist the pagination configs
  // will contain a different entry for each context in which usePagination is used
  // key will be persistContextId provided as an argument to usePagination
  paginationConfigs: {
    [key: string]: PaginationConfig | undefined;
  };
  setPaginationConfig: (
    contextId: string,
    newConfig?: PaginationConfig
  ) => void;
  resetOpsAndPaginationConfigs: () => void;
};

const defaultOpsConfig: OpsConfig = {
  panelArrangement: "wide-columns",
  showRiderStats: true,
  hiddenTabs: [],
  autoFullscreenTabs: [],
  fullscreenZoneId: undefined,
  fullscreenTrigger: "user",
  activeTabs: [],
  expanded: [],
  autoExitFullscreen: true,
};

export const useStore = create<Store>(
  persist({ key: "settings", denylist: ["apiConfig"] }, (set, get) => ({
    darkMode: true,
    collapseSider: true,
    toggleDark: () => set((state) => ({ ...state, darkMode: !state.darkMode })),
    toggleSider: () =>
      set((state) => ({ ...state, collapseSider: !state.collapseSider })),
    setApiConfig: (apiConfig: Configuration) => set(() => ({ apiConfig })),
    apiConfig: {
      cdnURL: "",
      env: "",
      imgProxyURL: "",
    },
    selectedOpZoneIds: [],
    setSelectedOpZoneIds: (zoneIds: string[]) => {
      set((state) => ({ ...state, selectedOpZoneIds: zoneIds }));
    },
    opsConfig: defaultOpsConfig,
    updateOpsConfig: (updatedConfig: Partial<OpsConfig>) =>
      set((state) => ({
        ...state,
        opsConfig: { ...state.opsConfig, ...updatedConfig },
      })),
    paginationConfigs: {},
    setPaginationConfig: (contextId: string, newConfig?: PaginationConfig) =>
      set((state) => {
        const result = {
          ...state,
        };
        result.paginationConfigs[contextId] = newConfig;
        console.log(
          "updated persisted pagination configs",
          state.paginationConfigs
        );
        return result;
      }),
    resetOpsAndPaginationConfigs: () => {
      set((state) => ({
        ...state,
        opsConfig: defaultOpsConfig,
        paginationConfigs: {},
        selectedOpZoneIds: [],
      }));
    },
  }))
);
