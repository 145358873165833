/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    // eslint-disable-next-line
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

/**
 * Manage form events and map to a local state. Name variables
 * with the prop `name="path.subpath.property"` and they will be
 * mapped to that place in the state.
 *
 * @param {object} state
 * @param {function} setState
 */
export const formOnChange = (state, setState) => (e) => {
  const { name, value } = e.target;
  const path = name.split(".");

  const stateDelta = {
    [path[0]]: path.length === 1 ? value : {},
  };
  let currentObj = stateDelta[path[0]];

  for (let i = 1; i < path.length; i += 1) {
    currentObj[path[i]] = i + 1 >= path.length ? e.target.value : {};
    currentObj = currentObj[path[i]];
  }

  setState(mergeDeep({}, state, stateDelta));
};
