import { PushpinOutlined, SearchOutlined } from "@ant-design/icons";
import { Badge, Button, Input, Popconfirm, Popover, Space, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import { format } from "date-fns";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  $,
  ReusablesPickupStatus,
  ReusablesPickupType,
} from "../../graphql/generated";
import { query, useTypedMutation, useTypedQuery } from "../../graphql/hooks";
import usePagination from "../../hooks/usePagination";
import { getImageUrl } from "../../utils/image-url";
import { onError, onSuccess } from "../../utils/on-error";
import { price } from "../../utils/price";
import { AddressDisplay } from "../forms/AddAddressForm";

const SITE_TABLE = query({
  ReusablesPickupList: [
    {
      skip: $`skip`,
      take: $`take`,
      sortBy: $`sortBy`,
      order: $`order`,
      query: $`query`,
      customerId: $`customerId`,
      aggregateId: $`aggregateId`,
      zoneId: $`zoneId`,
    },
    {
      total: true,
      items: {
        id: true,
        type: true,
        status: true,
        customerCount: true,
        riderCount: true,
        pickupTimeEarliest: true,
        pickupTimeLatest: true,
        refundedDeposit: true,
        customerImageKey: true,
        whereInstructions: true,
        containerLocation: true,
        customer: {
          id: true,
          name: true,
          email: true,
        },
        address: {
          id: true,
          address_line_1: true,
          postcode: true,
          coordinates: {
            type: true,
            coordinates: true,
          },
        },
        aggregate: {
          id: true,
          createdAt: true,
        },
      },
    },
  ],
});

export default function ReusablePickupTable({
  customerId,
  aggregateId,
  zoneId,
}: {
  customerId?: string;
  aggregateId?: string;
  zoneId?: string;
}) {
  const {
    pagination,
    args: { order, page, query, sortBy, take },
    onChange,
    setQuery,
  } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "pickupTimeEarliest",
  });

  const { data, refetch } = useTypedQuery(SITE_TABLE, {
    fetchPolicy: "network-only",
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
      aggregateId,
      zoneId,
      customerId,
    },
    pollInterval: 5000,
  });

  const [cancelPickup, { loading }] = useTypedMutation(
    {
      CancelContainerPickup: [
        {
          id: $`id`,
        },
        {
          id: true,
        },
      ],
    },
    { onError: onError, onCompleted: onSuccess }
  );

  const [show, setShow] = useState(false);
  const [showTypes, setShowTypes] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<string>("");

  return (
    <>
      <Table
        title={() => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Title level={4} style={{ flex: 1 }}>
              {data?.ReusablesPickupList.total} Pickups
            </Title>

            <Space>
              <Input
                placeholder="Search..."
                suffix={<SearchOutlined />}
                onChange={(t) => setQuery(t.target.value)}
              />
            </Space>
          </div>
        )}
        onChange={(p, f, s) => {
          onChange(p, f, s);
        }}
        dataSource={data?.ReusablesPickupList.items}
        pagination={{
          total: data?.ReusablesPickupList.total,
          ...pagination,
        }}
        columns={[
          {
            title: "Customer",
            sorter: true,
            dataIndex: "id",
            render: (_, r) => (
              <Link to={`/customer/${r.customer.id}`}>{r.customer.email}</Link>
            ),
          },
          {
            title: "Type",
            key: "typeId",
            sorter: true,
            render: (_, r) =>
              r.type === ReusablesPickupType.CUSTOMER ? "Pickup" : "DropBox",
          },
          {
            title: "Time",
            dataIndex: "pickupTimeEarliest",
            sorter: true,
            render: (_, r) => (
              <Text>
                {format(new Date(r.pickupTimeEarliest), "HH:mm")} and{" "}
                {format(new Date(r.pickupTimeLatest), "HH:mm")} on{" "}
                {new Date(r.pickupTimeEarliest).toDateString()}
              </Text>
            ),
          },
          {
            title: "Deposit Refund",

            render: (_, r) => price(r.refundedDeposit),
          },
          {
            title: "Status",
            key: "status",
            sorter: true,
            render: (_, r) => (
              <Badge
                status={
                  r.status === ReusablesPickupStatus.SCHEDULED
                    ? "processing"
                    : r.status === ReusablesPickupStatus.RIDER_AT_CUSTOMER
                    ? "warning"
                    : "success"
                }
                text={r.status}
              />
            ),
          },
          {
            title: "Customer Count",
            dataIndex: "customerCount",
          },
          {
            title: "Rider Count",
            dataIndex: "riderCount",
          },
          {
            title: "Location",
            dataIndex: "containerLocation",
          },
          {
            title: "Aggregate",
            render: (_, r) =>
              r.aggregate ? (
                <Link
                  to={`/reusables/pickup-aggregate/${r.aggregate.id}`}
                  style={{
                    fontFamily: "monospace",
                  }}
                >
                  {r.aggregate.id}
                </Link>
              ) : (
                <Text type="secondary">Not Aggregated Yet</Text>
              ),
          },
          {
            render: (_, r) =>
              r.customerImageKey ? (
                <Popover
                  placement="left"
                  content={
                    <img
                      alt="helpful"
                      src={getImageUrl(r.customerImageKey)}
                      style={{ maxWidth: 512 }}
                    />
                  }
                >
                  <img
                    alt="helpful"
                    src={getImageUrl(r.customerImageKey, 256, 256)}
                    style={{
                      width: 96,
                      height: 96,
                    }}
                  />
                </Popover>
              ) : null,
          },
          {
            render: (_, r) => (
              <Space direction="horizontal">
                <Button
                  onClick={() => setSelectedAddress(r.address.id)}
                  type="text"
                  icon={<PushpinOutlined />}
                >
                  {r.address.postcode}
                </Button>
                <Popconfirm
                  title="Cancel this pickup?"
                  onConfirm={() => cancelPickup({ variables: { id: r.id } })}
                >
                  <Button danger loading={loading}>
                    Cancel
                  </Button>
                </Popconfirm>
              </Space>
            ),
          },
        ]}
      />
      <Modal
        footer={null}
        visible={!!selectedAddress}
        destroyOnClose
        onCancel={() => setSelectedAddress("")}
      >
        <AddressDisplay id={selectedAddress} />
      </Modal>
    </>
  );
}
