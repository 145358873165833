import L from "leaflet";
import poly from "polyline-encoded";
import React, { useCallback, useRef, useState } from "react";
import {
  FeatureGroup,
  Map,
  Marker,
  Polyline,
  Popup,
  TileLayer,
} from "react-leaflet";
import { Link } from "react-router-dom";
import { MapType, Query } from "../../graphql/generated";
import { RIDER_FLIGHT_PLAN, RIDER_LIVE_UI } from "../../graphql/queries";
import { LRestoIcon, LRiderIcon } from "../../views/Order";
import { formatAddress } from "../forms/AddAddressForm";
type RiderPlanType = NonNullable<
  MapType<Query, typeof RIDER_FLIGHT_PLAN>["Rider"]["activeFlightPlan"]
>;

type RiderUIType = MapType<Query, typeof RIDER_LIVE_UI>["RiderLiveUI"];

export default function FlightPlanMap({
  plan,
  riderPosition,
  ui,
  vehicle,
  forwardRef,
}: {
  plan: RiderPlanType;
  riderPosition?: [number, number];
  ui: RiderUIType;
  vehicle: "CAR" | "BIKE";
  forwardRef: (m: Map) => void;
}) {
  const mapRef = useRef<Map | null>(null);
  const groupRef = useRef<FeatureGroup | null>(null);

  const [bounds, setBounds] = useState(
    new L.LatLngBounds({ lat: 0, lng: 0 }, { lat: 1, lng: 1 })
  );

  const haveSetBounds = useRef(false);

  const doBoundsUpdate = useCallback(() => {
    if (groupRef.current)
      setBounds(groupRef.current.leafletElement.getBounds().pad(0.5));
  }, [plan]);

  const destinations = plan.legs.map((l) => l.order);
  const origin = plan.legs[0].order.restaurant;

  const route = ui.job?.directions;

  const line = route?.geometry ? poly.decode(route?.geometry) : null;
  console.log(line);
  return (
    <Map
      ref={(ref) => {
        mapRef.current = ref;
        forwardRef(ref);
      }}
      bounds={bounds}
      style={{
        height: "400px",
      }}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://maps.ecoeats.uk/tile/{z}/{x}/{y}.png"
        onload={doBoundsUpdate}
      />
      {line && <Polyline positions={line} />}
      <FeatureGroup ref={(ref) => (groupRef.current = ref)}>
        <Marker
          position={origin.address.coordinates?.coordinates}
          icon={LRestoIcon()}
        >
          <Popup>
            <h3>{origin.name}</h3>
          </Popup>
        </Marker>
        {destinations.map((d) =>
          origin.address.id === d.address?.id ? null : (
            <Marker position={d.address!.coordinates?.coordinates}>
              <Popup>
                <Link to={`/order/${d.id}`}>Order #{d.number}</Link>
              </Popup>
            </Marker>
          )
        )}
        {plan.legs.map((l) =>
          l.address && l.address.id !== origin.address.id ? (
            <Marker position={l.address.coordinates!.coordinates}>
              <Popup>
                Leg {l.legOrder} to {formatAddress(l.address)}
              </Popup>
            </Marker>
          ) : null
        )}
        {riderPosition ? (
          <Marker icon={LRiderIcon(vehicle)} position={riderPosition}></Marker>
        ) : null}
      </FeatureGroup>
    </Map>
  );
}
