import { AlertFilled } from "@ant-design/icons";
import { Alert, Button, Checkbox, Col, Form, message, Row, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { $, OrderRescheduleInput } from "../graphql/generated/graphql-zeus";
import { useTypedMutation, useTypedQuery } from "../graphql/hooks";
import { BASKET } from "../graphql/queries";
import { BasketItem } from "./order/BasketItem";

const initialValues: OrderRescheduleInput = {
  isPartnerResponsible: false,
  skipPrepTime: false,
  split: false,
  includeItems: [],
};

export default function RescheduleOrderForm({
  onCompleted,
  id,
  basketId,
  restaurantName,
}) {
  const history = useHistory();

  const { data } = useTypedQuery(BASKET, {
    variables: {
      id: basketId,
    },
  });

  const [redespatch, { loading }] = useTypedMutation(
    {
      rescheduleOrder: [
        {
          id: $`id`,
          options: $`options`,
        },
        {
          id: true,
        },
      ],
    },
    {
      onCompleted: (data) => {
        message.success("Rescheduled order!");
        onCompleted();
        history.push(`/order/${data.rescheduleOrder.id}`);
      },
    }
  );

  const [form] = useForm();

  useEffect(() => {
    if (data?.Basket.items) {
      form.setFieldsValue({
        includeItems: data.Basket.items.map((i) => i.id),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.Basket.id]);

  const [confirmed, setConfirmed] = useState(false);

  return (
    <Form
      form={form}
      onFinish={(v) => {
        console.log(v);
        redespatch({
          variables: {
            id,
            options: v,
          },
        });
      }}
      layout="horizontal"
      initialValues={initialValues}
    >
      <Form.Item
        name="skipPrepTime"
        label="Skip standard prep time and despatch a rider immedietely"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        name="split"
        label="If this is a large order, split it as if it is being placed for the first time"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        name="isPartnerResponsible"
        label={`Is this as a result of an error by ${restaurantName}?`}
      >
        <Switch />
      </Form.Item>

      <Form.Item name="includeItems">
        <Checkbox.Group>
          {data?.Basket.items.map((i) => (
            <Row key={i.id}>
              <Col span={4}>
                <Checkbox value={i.id} defaultChecked />
              </Col>
              <Col span={20}>
                <BasketItem i={i} key={i.id} />
              </Col>
            </Row>
          ))}
        </Checkbox.Group>
      </Form.Item>

      <Alert
        banner
        type="warning"
        message={`
        This will create a new order and ask ${restaurantName} to accept it.
        Double check that this is what you want to do.`}
        icon={<AlertFilled />}
      ></Alert>

      <Form.Item label="I know what I'm doing">
        <Switch
          checked={confirmed}
          onChange={() => setConfirmed(!confirmed)}
          style={{
            flex: 1,
          }}
        />
      </Form.Item>

      <Button
        danger
        type="primary"
        disabled={!confirmed}
        loading={loading}
        htmlType="submit"
      >
        Redespatch Order
      </Button>
    </Form>
  );
}
