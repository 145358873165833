import { Badge } from "antd";
import React from "react";
import { MerchantSiteStatus } from "../../graphql/generated";

export default function MerchantSiteBadge(r: { status: MerchantSiteStatus }) {
  return (
    <Badge
      text={r.status}
      status={r.status === MerchantSiteStatus.LIVE ? "success" : "default"}
    />
  );
}
