import { SearchOutlined } from "@ant-design/icons";
import { Input, Space, Table } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import { Link } from "react-router-dom";
import { $ } from "../../graphql/generated";
import { query, useTypedQuery } from "../../graphql/hooks";
import usePagination from "../../hooks/usePagination";
import MerchantSiteBadge from "./MerchantSiteBadge";

const SITE_TABLE = query({
  MerchantSiteList: [
    {
      skip: $`skip`,
      take: $`take`,
      sortBy: $`sortBy`,
      order: $`order`,
      query: $`query`,
    },
    {
      total: true,
      items: {
        id: true,
        name: true,
        description: true,
        status: true,
        domains: {
          id: true,
          domain: true,
          createdAt: true,
          isVerified: true,
        },
      },
    },
  ],
});

export default function MerchantSiteTable() {
  const {
    pagination,
    args: { order, page, query, sortBy, take },
    onChange,
    setQuery,
  } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "name",
  });

  const { data, refetch } = useTypedQuery(SITE_TABLE, {
    fetchPolicy: "network-only",
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
    },
    pollInterval: 10000,
  });

  return (
    <>
      <Table
        title={() => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Title level={4} style={{ flex: 1 }}>
              {data?.MerchantSiteList.total} Sites
            </Title>

            <Space>
              <Input
                placeholder="Search..."
                suffix={<SearchOutlined />}
                onChange={(t) => setQuery(t.target.value)}
              />
            </Space>
          </div>
        )}
        onChange={(p, f, s) => {
          onChange(p, f, s);
        }}
        dataSource={data?.MerchantSiteList.items}
        pagination={{
          total: data?.MerchantSiteList.total,
          ...pagination,
        }}
        columns={[
          {
            title: "Name",
            sorter: true,
            render: (_, r) => (
              <Link to={`/merchant-site/${r.id}`}>{r.name}</Link>
            ),
          },

          {
            title: "Status",
            sorter: true,
            dataIndex: "status",
            render: (_, r) => <MerchantSiteBadge status={r.status} />,
          },
          {
            title: "Domains",
            dataIndex: "domains",
            render: (_, r) =>
              r.domains.map((d) => (
                <a
                  target="_blank"
                  href={`https://${d.domain}/`}
                  style={{ fontFamily: "monospace" }}
                  rel="noreferrer"
                >
                  {d.domain}
                </a>
              )),
          },
        ]}
      />
    </>
  );
}
