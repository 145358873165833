import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import { default as App } from "./App";
Sentry.init({
  enabled: process.env.NODE_ENV !== "development",
  dsn: "https://52cc8a35487b4844ae08d92564337596@sentry.ecoeats.uk/2",
  environment: process.env.NODE_ENV,
  release: "admin-dash@" + (process.env.REACT_APP_GIT_SHA || "dev"),
});

ReactDOM.render(<App />, document.getElementById("root"));
