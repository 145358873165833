import { CheckCircleFilled, DeleteFilled } from "@ant-design/icons";
import { Tag } from "antd";
import Table from "antd/lib/table";
import Title from "antd/lib/typography/Title";
import { formatDistance } from "date-fns/esm";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { $, OrderRiderRatingType } from "../../graphql/generated";
import { query, useTypedQuery } from "../../graphql/hooks";
import usePagination from "../../hooks/usePagination";
import { formatEnum } from "../../utils/format-if-past";
const ORDER_AUDITS = query({
  OrderRiderRatingList: [
    {
      order: $`order`,
      sortBy: $`sortBy`,
      skip: $`skip`,
      take: $`take`,
      ratings: $`ratings`,
    },
    {
      hasMore: true,
      total: true,
      items: {
        id: true,
        note: true,
        positive: true,
        ratings: true,
        order: {
          id: true,
          number: true,
          riderDroppedOffTime: true,
        },
        rider: {
          id: true,
          name: true,
        },
      },
    },
  ],
});

const STATUSES = Object.keys(OrderRiderRatingType);
const OPTIONS = STATUSES.map((k) => ({
  text: formatEnum(k),
  value: k,
}));

export default function OrderRiderReportTable() {
  const { args, onChange, pagination } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "timestamp",
  });

  const [statuses, setStatuses] = useState(STATUSES);

  const { data, loading } = useTypedQuery(ORDER_AUDITS, {
    variables: {
      ...args,
      ratings: statuses,
    },
  });

  return (
    <Table
      title={() => (
        <Title level={4}>{data?.OrderRiderRatingList.total} Reports</Title>
      )}
      dataSource={data?.OrderRiderRatingList.items}
      onChange={(p, f, s) => {
        console.log(f);
        setStatuses(f["status"] as any);
        onChange(p, f, s);
      }}
      pagination={{
        ...pagination,
        total: data?.OrderRiderRatingList.total,
      }}
      loading={loading}
      columns={[
        {
          title: "Order",
          render: (_, o) => (
            <Link to={`/order/${o.order.id}`}>{o.order.number}</Link>
          ),
        },
        {
          title: "Rider",
          render: (_, o) => (
            <Link to={`/rider/${o.rider.id}`}>{o.rider.name}</Link>
          ),
        },
        {
          title: "Time",
          render: (_, r) =>
            r.order.riderDroppedOffTime
              ? formatDistance(
                  new Date(r.order.riderDroppedOffTime),
                  new Date(),
                  {
                    includeSeconds: false,
                    addSuffix: true,
                  }
                )
              : "",
        },
        {
          title: "Overall",
          dataIndex: "positive",
          render: (positive) =>
            positive ? (
              <Tag color="green" icon={<CheckCircleFilled />}>
                Positive
              </Tag>
            ) : (
              <Tag color="red" icon={<DeleteFilled />}>
                Negative
              </Tag>
            ),
        },
        {
          key: "status",
          title: "Status",
          filters: OPTIONS,
          filteredValue: statuses,
          defaultFilteredValue: STATUSES,
          render: (_, o) => o.ratings.map((k) => <Tag>{formatEnum(k)}</Tag>),
        },
        {
          title: "Note",
          dataIndex: "note",
          sorter: true,
        },
      ]}
    />
  );
}
