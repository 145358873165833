import { formatDistance } from "date-fns";
import React from "react";
import { Spinner } from "react-bootstrap";
import { style } from "typestyle";

const mixins = {
  timelineBox: {
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    padding: "8px",
    display: "flex",
    transition: "all ease-in-out 0.1s",
    flexDirection: "row",
    marginBottom: "8px",
    $nest: {
      strong: {
        flex: 1,
      },
    },
  },
  complete: {
    backgroundColor: "#abe6ac",
  },
};

const styles = {
  timelineBox: style(mixins.timelineBox as any),
  timelineSep: style({}),
  timelineBoxComplete: style(mixins.timelineBox as any, mixins.complete),
};

const TimelineBox = ({ active, complete, title, time }) => (
  <div className={complete ? styles.timelineBoxComplete : styles.timelineBox}>
    {active ? (
      <Spinner
        style={{ marginRight: "8px" }}
        size="sm"
        variant="success"
        animation="border"
      />
    ) : null}
    <strong>{title}</strong>
    <span>{time ? new Date(time).toLocaleTimeString() : ""}</span>
  </div>
);

export const OrderTimeline = ({ order }) => (
  <div>
    <TimelineBox
      active={order.status === "AWAITING_RESTAURANT"}
      complete
      title="Order Created"
      time={order.placedAt}
    />
    <div className={styles.timelineSep} />
    <TimelineBox
      active={order.status === "PREP"}
      complete={!!order.riderPickedUpTime}
      title="Order is being Prepared"
      time={order.partnerAcknowlegedAt}
    />
    <div className={styles.timelineSep} />
    <TimelineBox
      active={order.isRiderAtRestaurant}
      complete={!!order.riderPickedUpTime}
      title={`${
        order.fulfillmentMethod === "COLLECTION" ? "Customer" : "Rider"
      } Arrived at Partner ${
        order.riderArrivedAtRestaurantTime
          ? `(took ${formatDistance(
              new Date(order.lastOfferedToRider),
              new Date(order.riderArrivedAtRestaurantTime)
            )})`
          : ""
      }`}
      time={order.riderArrivedAtRestaurantTime}
    />
    <div className={styles.timelineSep} />
    <TimelineBox
      active={order.status === "EN_ROUTE"}
      complete={!!order.riderArrivedAtCustomerTime}
      title={`Order Picked Up ${
        order.riderPickedUpTime
          ? `(waited ${formatDistance(
              new Date(order.riderArrivedAtRestaurantTime),
              new Date(order.riderPickedUpTime)
            )})`
          : ""
      }`}
      time={order.riderPickedUpTime}
    />
    {order.fulfillmentMethod === "DELIVERY" ? (
      <>
        {" "}
        <div className={styles.timelineSep} />
        <TimelineBox
          active={order.status === "AWAITING_CUSTOMER"}
          complete={!!order.riderDroppedOffTime}
          title={`Rider Arrived at Customer ${
            order.riderArrivedAtCustomerTime
              ? `(took ${formatDistance(
                  new Date(order.riderPickedUpTime),
                  new Date(order.riderArrivedAtCustomerTime)
                )} at ${(
                  (order.basket.distanceDuration?.distance.value /
                    (new Date(order.riderArrivedAtCustomerTime).getTime() -
                      new Date(order.riderPickedUpTime).getTime())) *
                  1000
                ).toFixed(2)}m/s)`
              : ""
          }`}
          time={order.riderArrivedAtCustomerTime}
        />
      </>
    ) : null}
    <div className={styles.timelineSep} />
    <TimelineBox
      active={false}
      complete={order.status === "COMPLETE"}
      title="Order Complete"
      time={order.riderDroppedOffTime}
    />
  </div>
);
