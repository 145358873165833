import { FileTextOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Table,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import { ColumnsType } from "antd/lib/table";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import { endOfDay, endOfWeek, startOfDay, startOfWeek } from "date-fns";
import moment from "moment";
import React, { useCallback, useState } from "react";
import {
  $,
  PartnerAdjustmentUpdateInput,
} from "../../graphql/generated/graphql-zeus";
import { query, useTypedMutation, useTypedQuery } from "../../graphql/hooks";
import {
  RESTAURANTS_FINANCIALS,
  RESTAURANT_FINANCIALS,
} from "../../graphql/queries";
import { camel2title } from "../../utils";
import {
  convertToCSV,
  download,
  filterAndSortTableData,
} from "../../utils/export-table";
import { price } from "../../utils/price";
import { useUpdateItemKey } from "../../utils/update-item-key";

const RIDER_ADJUSTMENT_LIST = query({
  PartnerAdjustmentList: [
    {
      order: $`order`,
      sortBy: $`sortBy`,
      partnerId: $`partnerId`,
      skip: $`skip`,
      take: $`take`,
      startDate: $`startDate`,
    },
    {
      total: true,
      items: {
        id: true,
        created: true,
        amount: true,
        explanation: true,
        forDate: true,
        partnerId: true,
      },
    },
  ],
});

export const PartnerAdjustmentTable = ({ partnerId, startDate, onChange }) => {
  const [page, setSkip] = useState(1);
  const [take, setTake] = useState(25);
  const [sortBy, setSortBy] = useState("lastConnection");
  const [order, setOrder] = useState("DESC");

  const { data, refetch } = useTypedQuery(RIDER_ADJUSTMENT_LIST, {
    variables: {
      partnerId,
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      startDate,
    },
  });

  const [update] = useTypedMutation(
    {
      updatePartnerAdjustment: [
        {
          id: $`id`,
          input: $`input`,
        },
        {
          ...RIDER_ADJUSTMENT_LIST.PartnerAdjustmentList[1].items,
        },
      ],
    },
    {
      onCompleted: () => {
        message.success("Saved!");
        if (onChange) onChange();
      },
    }
  );

  const [create] = useTypedMutation(
    {
      createPartnerAdjustment: [
        {
          adjustment: $`adjustment`,
        },
        {
          ...RIDER_ADJUSTMENT_LIST.PartnerAdjustmentList[1].items,
        },
      ],
    },
    {
      onCompleted: () => {
        message.success("Created!");
        setShowModal(false);
        refetch();
        if (onChange) onChange();
      },
    }
  );

  const updateAdjustment = useUpdateItemKey<PartnerAdjustmentUpdateInput>(
    update,
    "input"
  );

  const [showModal, setShowModal] = useState(false);

  const onFinish = useCallback(
    (v) => create({ variables: { adjustment: { ...v, partnerId } } }),
    [create, partnerId]
  );

  return (
    <>
      <Table
        dataSource={data?.PartnerAdjustmentList.items}
        pagination={{
          total: data?.PartnerAdjustmentList.total,
          current: page,
          pageSize: take,
          onChange: (p, ps) => {
            setTake(ps!);
            setSkip(p);
          },
          showSizeChanger: true,
        }}
        title={() => (
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            <div style={{ flex: 1 }}>
              <Title level={4} style={{ flex: 1 }}>
                Partner Adjustments
              </Title>
              <Text type="secondary">
                One-off positive or negative adjustments that cannot be
                expressed as an order adjustment.
              </Text>
            </div>
            <Button onClick={() => setShowModal(true)} icon={<PlusOutlined />}>
              Create Adjustment
            </Button>
          </div>
        )}
        columns={[
          {
            title: "Date",
            dataIndex: "forDate",
            render: (d) => new Date(d).toLocaleString(),
          },
          {
            title: "Amount",
            render: (e) => (
              <>
                £
                <Text
                  editable={{
                    maxLength: 30,
                    onChange: (t) =>
                      updateAdjustment(
                        "amount",
                        Number.parseFloat(t) * 100,
                        e.id
                      ),
                  }}
                >
                  {(e.amount / 100 || 0).toFixed(2)}
                </Text>
              </>
            ),
          },
          {
            title: "Explanation",

            render: (e) => (
              <Text
                editable={{
                  onChange: (t) => updateAdjustment("explanation", t, e.id),
                }}
              >
                {e.explanation}
              </Text>
            ),
          },
        ]}
      />
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item
            name="forDate"
            label="Date"
            help="When was the incident that led to this adjustment being required? When an invoice is generated, it will only include adjustments that have a date within their period."
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            name="amount"
            label="Amount"
            help="How much is this adjustment for? Postive values pay the rider more, negative pay them less. Amount is in pence."
            normalize={(n) => Math.round(n)}
            rules={[{ required: true }]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            name="explanation"
            label="Explanation"
            help="Why are you making this adjustment?"
            rules={[
              { required: true },
              { min: 15, message: "Enter a longer, more descriptive reason " },
            ]}
          >
            <Input />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Create Adjustment
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export const Financials = ({ restaurantId }: { restaurantId?: string }) => {
  const [startDate, setStartDate] = useState(startOfWeek(new Date()));
  const [endDate, setEndDate] = useState(endOfWeek(new Date()));
  const { data, loading, refetch } = useTypedQuery(RESTAURANT_FINANCIALS, {
    skip: !restaurantId,
    fetchPolicy: "network-only",
    variables: {
      restaurantId,
      startDate,
      endDate,
    },
    onError: (e) => message.error(e.message),
  });

  const { data: multiData, loading: loadingMulti } = useTypedQuery(
    RESTAURANTS_FINANCIALS,
    {
      skip: !!restaurantId,
      fetchPolicy: "network-only",
      variables: {
        startDate,
        endDate,
      },
      onError: (e) => message.error(e.message),
    }
  );

  const [exportOrders, { loading: exporting }] = useTypedMutation(
    {
      exportOrders: [
        {
          restaurantId: $`restaurantId`,
          startDate: $`startDate`,
          endDate: $`endDate`,
        },
        true,
      ],
    },
    {
      onCompleted: (d) => {
        download(
          `ecoeats-partner-orders-${startDate.toDateString()}-${endDate.toDateString()}.csv`,
          d.exportOrders
        );
      },
    }
  );

  const keys = (data?.financialsForRestaurant
    ? Object.keys(data.financialsForRestaurant)
    : multiData?.financialsForRestaurants?.length
    ? Object.keys(multiData.financialsForRestaurants[0])
    : []
  ).filter(
    (k) =>
      ![
        "restaurantId",
        "restaurant",
        "startDate",
        "endDate",
        "__typename",
      ].includes(k)
  );

  const notToPrice = [
    "restaurantId",
    "numberOfOrders",
    "commissionPercent",
    "taxPercent",
  ];

  const nData = data!;

  const COLUMNS: ColumnsType<typeof nData.financialsForRestaurant> = [
    {
      title: "Partner",
      dataIndex: "restaurant",
      render: (r) => r?.name,
    },
    ...keys.map((k) => ({
      title: camel2title(k),
      dataIndex: k,
      sorter: {
        compare: (a, b) => a[k] - b[k],
        multiple: 1,
      },
      render: (r) => (notToPrice.includes(k) ? r : price(r)),
    })),
  ];

  const dataSource = restaurantId
    ? [data?.financialsForRestaurant!]
    : multiData?.financialsForRestaurants!;

  const asCSV = useCallback(() => {
    const sorted = filterAndSortTableData(dataSource, COLUMNS as any);
    const csv = convertToCSV(sorted);
    download(
      `ecoeats-partner-sales-${startDate.toDateString()}-${endDate.toDateString()}.csv`,
      csv
    );
  }, [dataSource, COLUMNS]);

  return (
    <Card
      style={{ overflowX: "auto", padding: 0 }}
      bodyStyle={{
        padding: 0,
      }}
      title={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Title level={4} style={{ flex: 1 }}>
            Partner Earnings
          </Title>

          <DatePicker.RangePicker
            value={[moment(startDate), moment(endDate)]}
            onChange={(v) => {
              if (v && v[0] && v[1]) {
                setStartDate(startOfDay(v[0]?.toDate()));
                setEndDate(endOfDay(v[1]?.toDate()));
              }
            }}
            style={{
              marginRight: 8,
            }}
          />

          <Button
            loading={exporting}
            onClick={() =>
              exportOrders({
                variables: {
                  endDate,
                  startDate,
                  restaurantId: restaurantId || "",
                },
              })
            }
            style={{
              marginRight: 8,
            }}
            icon={<FileTextOutlined />}
            type="primary"
          >
            Full Export
          </Button>

          <Button onClick={asCSV}>Financials Only</Button>
        </div>
      }
    >
      <Table
        dataSource={dataSource}
        showSorterTooltip
        loading={loading || loadingMulti}
        columns={COLUMNS}
        pagination={{
          pageSize: 25,
        }}
        summary={
          restaurantId || !dataSource
            ? undefined
            : () => {
                const totals: { [key: string]: number } = {};

                dataSource.forEach((d) => {
                  COLUMNS.forEach((c) => {
                    const idx = c["dataIndex"];
                    const number = d[idx];

                    if (typeof number === "number") {
                      if (!totals[idx]) {
                        totals[idx] = 0;
                      }
                      totals[idx] += number;
                    }
                  });
                });

                return (
                  <>
                    <Table.Summary.Row
                      style={{
                        backgroundColor: "#d5d5d5",
                      }}
                    >
                      <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                      {COLUMNS.slice(1).map((c, i) => (
                        <Table.Summary.Cell index={i}>
                          <Text>
                            {c.render?.(totals[c["dataIndex"]], totals, i)}
                          </Text>
                        </Table.Summary.Cell>
                      ))}
                    </Table.Summary.Row>
                  </>
                );
              }
        }
      />

      {restaurantId && (
        <PartnerAdjustmentTable
          onChange={refetch}
          partnerId={restaurantId}
          startDate={startDate}
        />
      )}
    </Card>
  );
};
