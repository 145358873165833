import { Button, Form } from "antd";
import { addMonths } from "date-fns/esm";
import React, { useEffect, useState } from "react";
import {
  AllTypesProps,
  Discount,
  DiscountCreateInput,
} from "../../graphql/generated/graphql-zeus";
import { useTypedMutation } from "../../graphql/hooks";
import { CREATE_DISCOUNT } from "../../graphql/mutations";
import { useFormData } from "../../hooks/useFormData";
import {
  getMapInputFields,
  mapInputToControl,
} from "../../utils/input-control-gql";

function makeid(length) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const { conditions, inputs } = getMapInputFields<
  typeof AllTypesProps.DiscountCreateInput,
  DiscountCreateInput
>(AllTypesProps.DiscountCreateInput);

export const AddDiscountForm = ({
  discount,
  onSuccess = (): void => void 0,
}: {
  discount?: Discount;
  onSuccess?: () => void;
}) => {
  const [formData, onChange] = useFormData<Partial<DiscountCreateInput>>({
    validFrom: new Date().getTime(),
    validUntil: addMonths(new Date(), 1).getTime(),
    maxUsesPerCustomer: 1,
    maxUsesGlobally: 1,
    minimumTotalPrice: 900,
    code: makeid(6),
    ...inputs.reduce(
      (a, c) => (discount?.[c[0]] ? { ...a, [c[0]]: discount?.[c[0]] } : a),
      {}
    ),
  });

  const [errors, setErrors] = useState<string[]>([]);

  console.log(errors);

  const [createDiscountMutation, { loading }] = useTypedMutation(
    CREATE_DISCOUNT,
    {
      onCompleted: () => onSuccess(),
      onError: (e) => {
        if ((e.networkError as any)?.result?.errors)
          setErrors(
            (e.networkError as any).result.errors.map(
              (g) => g.message.match(/\S+(?= of )/g)[0]
            )
          );
      },
    }
  );

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const submitForm = (e) => {
    const input = {
      variables: {
        discount: {
          ...formData,
        },
      },
    };

    createDiscountMutation(input);
  };

  return (
    <Form onFinish={submitForm}>
      {inputs.map(([k, v]) =>
        conditions[k] && !conditions[k]?.(formData[k], formData)
          ? null
          : mapInputToControl({
              ...v,
              error: errors.includes(k),
              name: k,
              onChange,
              formData,
            })
      )}

      <Button htmlType="submit" type="primary" loading={loading}>
        Save Discount
      </Button>
    </Form>
  );
};
