import React from "react";

export default function OrderRatings({ partner, rider }) {
  return (
    <div
      style={{
        margin: "0 8px",
      }}
    >
      {!partner && <span>Not Rated Yet</span>}
      {partner && (
        <span>
          Partner:{" "}
          {Array(5)
            .fill(0)
            .map((p, i) => (
              <i
                className="material-icons"
                style={{
                  color: i <= partner - 1 ? "gold" : "grey",
                }}
              >
                star
              </i>
            ))}
        </span>
      )}
      &nbsp;
      {rider && (
        <span>
          Rider:{" "}
          {Array(5)
            .fill(0)
            .map((p, i) => (
              <i
                className="material-icons"
                style={{
                  color: i <= rider - 1 ? "gold" : "grey",
                }}
              >
                star
              </i>
            ))}
        </span>
      )}
    </div>
  );
}
