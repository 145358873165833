import { Badge, Button, DatePicker, Modal, PageHeader, Table } from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AddDiscountForm } from "../components/forms/AddDiscountForm";
import { DiscountType } from "../graphql/generated";
import { useTypedQuery } from "../graphql/hooks";
import { ALL_DISCOUNTS } from "../graphql/queries";
import usePagination from "../hooks/usePagination";
import { rangeInFuture } from "../utils/is-future";
import { price } from "../utils/price";
export function Discounts() {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [validFrom, setStartDate] = useState(moment().startOf("year"));
  const [validUntil, setEndDate] = useState(moment().endOf("year"));

  const { args, onChange, pagination } = usePagination({
    defaultOrder: "ASC",
    defaultSortBy: "validFrom",
  });

  const { data, refetch } = useTypedQuery(ALL_DISCOUNTS, {
    variables: {
      ...args,
      validFrom,
      validUntil,
    },
  });

  return (
    <>
      <PageHeader
        title={`${data?.DiscountList.total || 0} Discounts`}
        extra={[
          <DatePicker.RangePicker
            value={[validFrom, validUntil]}
            onChange={(v) => {
              if (v) {
                setEndDate(v[0]!);
                setStartDate(v[1]!);
              }
            }}
          />,
          <Button
            onClick={() => {
              setShowModal(true);
            }}
          >
            Add New Discount
          </Button>,
        ]}
      />

      <>
        <Table
          dataSource={data?.DiscountList.items}
          pagination={{
            ...pagination,
            total: data?.DiscountList.total,
          }}
          onChange={onChange}
          columns={[
            {
              title: "Code",
              dataIndex: "code",
              render: (_, r) => (
                <Link
                  to={"/discount/" + r.id}
                  style={{
                    fontFamily: "monospace",
                  }}
                >
                  {r.code}
                </Link>
              ),
            },
            { title: "Type", dataIndex: "type" },
            {
              title: "Value",

              render: (discount) =>
                discount.type === DiscountType.PERCENTAGE
                  ? `${discount.value}%`
                  : discount.type === DiscountType.FIXED
                  ? price(discount.value!)
                  : "N/A",
            },
            { title: "Max Global", dataIndex: "maxUsesGlobally" },
            { title: "Max Per User", dataIndex: "maxUsesPerCustomer" },
            {
              title: "Period",
              sorter: true,
              render: (r) => (
                <Text>
                  {new Date(r.validFrom).toLocaleString()} -{" "}
                  {new Date(r.validUntil).toLocaleString()}
                </Text>
              ),
            },
            {
              title: "Minimum Total Price",
              dataIndex: "minimumTotalPrice",
            },
            {
              render: (r) => {
                const { isFuture, isNow } = rangeInFuture([
                  r.validFrom,
                  r.validUntil,
                ]);
                return (
                  <Badge
                    text={
                      !r.enabled
                        ? "Disabled"
                        : isFuture
                        ? "Scheduled"
                        : isNow
                        ? "Active"
                        : "Complete"
                    }
                    status={
                      !r.enabled
                        ? "error"
                        : isFuture
                        ? "default"
                        : isNow
                        ? "processing"
                        : "success"
                    }
                  />
                );
              },
            },
          ]}
        />
      </>

      <Modal
        title="Create Discount"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        okButtonProps={{
          hidden: true,
        }}
        cancelButtonProps={{
          hidden: true,
        }}
      >
        <AddDiscountForm
          onSuccess={() => {
            setShowModal(false);
            refetch();
          }}
        />
      </Modal>
    </>
  );
}
