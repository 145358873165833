import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, message, Modal } from "antd";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { $, CreatePromotionInput } from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";
import { onError } from "../../utils/on-error";
import ZoneAutoComplete from "../ZoneAutocomplete";
import PromotionColorSelect, { DEFAULT_COLOUR } from "./PromotionColorSelect";
import PromotionTypeSelect from "./PromotionTypeSelect";

export default function CreatePromotionButton() {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const [createPromotion, { loading }] = useTypedMutation(
    {
      createPromotion: [
        {
          promotion: $`promotion`,
        },
        {
          id: true,
        },
      ],
    },
    {
      onCompleted: (d) => {
        history.push(`/promotion/${d.createPromotion.id}`);
      },
      onError,
    }
  );

  const submit = useCallback(
    ({
      body,

      name,
      color,
      targetType,
      title,
      zoneIds,
      callToActionText,
      target,

      range,
    }) => {
      const promotion: CreatePromotionInput = {
        body,
        end: range[1],
        name,
        start: range[0],
        targetType,
        color,
        title,
        zoneIds,
        callToActionText,
        target,
      };
      createPromotion({
        variables: {
          promotion,
        },
      });
    },
    [createPromotion]
  );

  return (
    <>
      <Button icon={<PlusCircleOutlined />} onClick={() => setShowModal(true)}>
        New Promotion
      </Button>

      <Modal
        visible={showModal}
        title="New Promotion"
        onCancel={() => setShowModal(false)}
        footer={[
          <Button htmlType="submit" form="createPromotion">
            Create Promotion
          </Button>,
        ]}
      >
        <Form name="createPromotion" layout="vertical" onFinish={submit}>
          <Form.Item
            label="Name"
            help={"Internal name"}
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Title"
            name="title"
            help={"Displayed as card header in the app"}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Body"
            name="body"
            help={"Body text for promotion"}
            rules={[{ required: true }]}
          >
            <Input multiple />
          </Form.Item>
          <Form.Item
            name="callToActionText"
            label="Call To Action"
            help={"Text for a call to action button"}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="targetType"
            label="Target Type"
            help="What is the type of the target?"
          >
            <PromotionTypeSelect />
          </Form.Item>
          <Form.Item name="target" label="Target" help="What is the target?">
            <Input />
          </Form.Item>
          <Form.Item
            initialValue={DEFAULT_COLOUR}
            name="color"
            label="Colour"
            rules={[{ required: true }]}
          >
            <PromotionColorSelect />
          </Form.Item>
          <Form.Item label="Dates" name="range" rules={[{ required: true }]}>
            <DatePicker.RangePicker showTime />
          </Form.Item>
          <Form.Item
            label="Zones"
            help="Leave empty for all zones"
            name="zoneIds"
          >
            <ZoneAutoComplete onChange={console.log} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
