import { TablePaginationConfig } from "antd";
import {
  ColumnsType,
  ColumnType,
  FilterValue,
  SorterResult,
} from "antd/lib/table/interface";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Ordering } from "../graphql/generated";
import { useStore } from "../state/zustand";
import { useDebounce } from "./useDebounce";
import usePersistedPaginationConfig from "./usePersistedPaginationConfig";

export type Order = "ASC" | "DESC";

const DefaultPageSizes = ["10", "25", "50"];

export type PaginationConfig = {
  page: number;
  take: number;
  sortBy: string;
  order: Order;
  query: string;
};

export type Columns<T> = (ColumnType<T> & { dataIndex?: string })[];

const convertOrderingToAnt = (ordering: Order): "ascend" | "descend" =>
  ordering === "ASC" ? "ascend" : "descend";

export default function usePagination({
  defaultSortBy,
  defaultOrder,
  additionalTableConfigs,
  additionalOnChangeActions,
  persistContextId,
  defaultTake = 10,
}: {
  defaultSortBy: string;
  defaultOrder: Order;
  defaultTake?: number;
  // additional state for the table e.g. filters
  additionalTableConfigs?: {
    propertyName: string;
    value: any;
    setFunc: React.Dispatch<React.SetStateAction<any>>;
  }[];
  // will be added in addition to default onChange effects.
  // useful for setting the values of any additional table configurations
  additionalOnChangeActions?: (
    p: TablePaginationConfig,
    f: Record<string, FilterValue | null>,
    s: SorterResult<any> | SorterResult<any>[]
  ) => void;
  // provided if pagination to be persisted in local storage.
  // must be globally unique id so good idea to set to a name
  // related to the context in which the table is used.
  persistContextId?: string;
}) {
  const [persistedConfig, setStoredConfig] = usePersistedPaginationConfig(
    persistContextId
  );

  const [page, setSkip] = useState(
    persistedConfig != null ? persistedConfig.page : 1
  );
  const [take, setTake] = useState(
    persistedConfig != null ? persistedConfig.take : defaultTake
  );
  const [sortBy, setSortBy] = useState(
    persistedConfig != null ? persistedConfig.sortBy : defaultSortBy
  );
  const [order, setOrder] = useState(
    persistedConfig != null ? persistedConfig.order : defaultOrder
  );
  const [_query, setQuery] = useState(
    persistedConfig != null ? persistedConfig.query : ""
  );

  useEffect(() => {
    if (persistContextId) {
      var storedConfig = {
        page,
        take,
        sortBy,
        order,
        query: _query,
      };
      // add the additional table configs to the store
      additionalTableConfigs?.forEach(
        (config) => (storedConfig[config.propertyName] = config.value)
      );
      setStoredConfig(persistContextId, storedConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    persistContextId,
    page,
    take,
    sortBy,
    order,
    _query,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(additionalTableConfigs),
  ]);

  // update the additional additionalTableConfigs to be the persisted value if appropriate.
  useEffect(() => {
    if (persistedConfig) {
      additionalTableConfigs?.forEach((config) => {
        config.setFunc(persistedConfig[config.propertyName]);
        console.log(
          "persisted config updating",
          config.propertyName,
          "to",
          persistedConfig[config.propertyName]
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const query = useDebounce(_query, 750);

  const onChange = useCallback(
    (
      p: TablePaginationConfig,
      f: Record<string, FilterValue | null>,
      s: SorterResult<any> | SorterResult<any>[]
    ) => {
      if (s instanceof Array) {
      } else if (s.field || s.columnKey) {
        setSortBy((s.field ? s.field : s.columnKey) as string);
        setOrder(s.order === "ascend" ? Ordering.ASC : Ordering.DESC);
      }
      if (additionalOnChangeActions) additionalOnChangeActions(p, f, s);
    },
    [additionalOnChangeActions]
  );

  const applyDefaultSortOrder = useCallback(function <T>(
    columns: (ColumnType<T> & { dataIndex?: string })[]
  ) {
    return columns.map((col) => ({
      ...col,
      defaultSortOrder:
        sortBy === col.key || sortBy === col.dataIndex
          ? convertOrderingToAnt(order)
          : undefined,
    }));
  },
  []);
  const args = useMemo(
    () => ({
      page,
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
    }),
    [page, take, sortBy, order, query]
  );

  return {
    args,
    setQuery,
    onChange,
    applyDefaultSortOrder,
    pagination: {
      current: page,
      pageSize: take,
      onChange: (p, ps) => {
        if (ps) setTake(ps);
        setSkip(p);
      },
      pageSizeOptions: DefaultPageSizes.includes(take.toString())
        ? DefaultPageSizes
        : [take.toString(), ...DefaultPageSizes],
      showSizeChanger: true,
      showQuickJumper: true,
    } as TablePaginationConfig,
  };
}
