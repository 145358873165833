import _Chart from "./chart";

export * from "./formData";

export const Chart = _Chart;

export const camel2title = (camelCase) =>
  camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase());
