import { Button, Form, Input } from "antd";
import { LatLngTuple } from "leaflet";
import React, { useState } from "react";
import { Map, Polygon, TileLayer } from "react-leaflet";
import { useHistory } from "react-router";
import { $ } from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";

export function CreateZoneForm({ cityId }: { cityId: string }) {
  const INITIAL_POSITION = [56.333874, -2.8059686];
  const [positions, setPositions] = useState<any[]>([]);
  const [formData, setFormData] = useState({
    name: "",
  });

  const history = useHistory();

  const [createCityMutation, { loading }] = useTypedMutation(
    {
      createZone: [
        {
          zone: $`zone`,
        },
        {
          id: true,
        },
      ],
    },
    {
      onCompleted: (d) => history.push(`/zone/${d.createZone.id}`),
    }
  );

  const addPosition = (e) => {
    const newPos = [e.latlng.lat, e.latlng.lng];
    setPositions(positions.concat([newPos]));
  };

  const submitForm = (e) => {
    createCityMutation({
      variables: {
        zone: {
          ...e,
          cityId,
          polygon: {
            type: "Polygon",
            coordinates: [positions],
          },
        },
      },
    });
  };

  return (
    <Form onFinish={submitForm}>
      <Form.Item label="Name" name="name">
        <Input name="name" placeholder="Zone" />
      </Form.Item>

      <Button danger onClick={() => setPositions([])}>
        Reset Polygon
      </Button>
      <Map
        center={INITIAL_POSITION as LatLngTuple}
        zoom={10}
        onClick={(e) => addPosition(e)}
        draw
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://maps.ecoeats.uk/tile/{z}/{x}/{y}.png"
        />
        <Polygon positions={positions} color="blue" />
      </Map>

      <Button htmlType="submit">Create New Zone</Button>
    </Form>
  );
}
