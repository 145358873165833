import { Badge, Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import Title from "antd/lib/typography/Title";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Map, Marker, Polyline, TileLayer } from "react-leaflet";
import { useInterval } from "react-use";
import { $, MapType, Query } from "../../graphql/generated";
import { useTypedMutation, useTypedQuery } from "../../graphql/hooks";
import { RIDER_ONLINE_SESSIONS } from "../../graphql/queries";
import { LRiderIcon } from "../../views/Order";
import { RiderContext } from "../../views/rider/Rider";

export default function RiderSessions() {
  const context = useContext(RiderContext);

  const rider = context.data?.Rider;

  const [page, setSkip] = useState(1);
  const [take, setTake] = useState(25);
  const [sortBy, setSortBy] = useState("start");
  const [order, setOrder] = useState("DESC");

  const { data, loading, refetch } = useTypedQuery(RIDER_ONLINE_SESSIONS, {
    skip: !rider,
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      riderId: rider?.id,
    },
  });

  useInterval(refetch, 5000);

  const [selectedSession, setSelectedSession] = useState<
    NonNullable<typeof data>["RiderOnlineSessionList"]["items"][0] | null
  >(null);

  const list = data?.RiderOnlineSessionList;

  useEffect(() => {
    const first = data?.RiderOnlineSessionList.items[0];
    if (first && !selectedSession) {
      setSelectedSession(first);
    }
  }, [data?.RiderOnlineSessionList.total, selectedSession]);

  const [endSession] = useTypedMutation({
    endRiderOnlineSession: [
      {
        riderId: $`riderId`,
      },
      {
        id: true,
        end: true,
      },
    ],
  });

  const COLUMNS: ColumnsType<
    MapType<
      Query,
      typeof RIDER_ONLINE_SESSIONS
    >["RiderOnlineSessionList"]["items"][0]
  > = useMemo(
    () => [
      {
        title: "Rider",
        render: (r) => r.rider.name,
      },
      {
        title: "Start",
        render: (r) => new Date(r.start).toLocaleString(),
      },
      {
        title: "End",
        render: (r) =>
          r.end ? (
            <Badge
              status={r.end ? "success" : "processing"}
              text={r.end ? new Date(r.end).toLocaleString() : "Live"}
            />
          ) : (
            <Button
              type="text"
              danger
              onClick={() => endSession({ variables: { riderId: r.riderId } })}
            >
              End
            </Button>
          ),
      },
    ],
    []
  );

  return (
    <>
      <Map center={selectedSession?.path?.coordinates[0] || [0, 0]} zoom={14}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://maps.ecoeats.uk/tile/{z}/{x}/{y}.png"
        />

        {selectedSession && (
          <Polyline positions={selectedSession.path?.coordinates} />
        )}
        {rider?.position && (
          <Marker
            icon={LRiderIcon(rider.vehicle)}
            position={rider.position.coordinates}
          />
        )}
      </Map>

      <Table
        loading={loading}
        title={() => <Title level={5}>{list?.total} Sessions</Title>}
        columns={COLUMNS}
        dataSource={list?.items}
        onChange={(p, f, s) => {
          if (s instanceof Array) {
          } else {
            setSortBy(
              s.order
                ? (s.field as string) || (s.columnKey as string) || "start"
                : "start"
            );
            setOrder(!s.order ? "DESC" : s.order === "ascend" ? "ASC" : "DESC");
          }
        }}
        onRow={(r) => ({
          onClick: () => setSelectedSession(r),
        })}
        pagination={{
          total: list?.total,
          current: page,
          pageSize: take,
          onChange: (p, ps) => {
            setTake(ps!);
            setSkip(p);
          },
          showSizeChanger: true,
        }}
      />
    </>
  );
}
