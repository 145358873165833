import { DownloadOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, message, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import Title from "antd/lib/typography/Title";
import { endOfDay, endOfWeek, startOfDay, startOfWeek } from "date-fns";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useTypedQuery } from "../../graphql/hooks";
import { RIDERS_FINANCIAL_OVERVIEW } from "../../graphql/queries";
import { camel2title } from "../../utils";
import {
  convertToCSV,
  download,
  filterAndSortTableData,
} from "../../utils/export-table";
import { price } from "../../utils/price";

const notToPrice = [
  "restaurantId",
  "numberOfOrders",
  "commissionPercent",
  "taxPercent",
];

export default function RidersFinancialOverview() {
  const [startDate, setStartDate] = useState(startOfWeek(new Date()));
  const [endDate, setEndDate] = useState(endOfWeek(new Date()));

  const { data, loading } = useTypedQuery(RIDERS_FINANCIAL_OVERVIEW, {
    fetchPolicy: "network-only",
    variables: {
      startDate,
      endDate,
    },
    onError: (e) => message.error(e.message),
  });

  const dataSource = data?.FinancialOverview.riders || [];

  const keys = dataSource[0]
    ? (Object.keys(dataSource[0]) as (keyof NonNullable<
        typeof dataSource
      >[0])[])
    : [];
  const COLUMNS: ColumnsType<NonNullable<typeof dataSource>[0]> = [
    {
      title: "Rider",
      render: (_, r) => <Link to={`/rider/${r.rider.id}`}>{r.rider.name}</Link>,
    },
    ...keys
      .filter((k) => k !== "id" && k !== "rider" && (k as any) !== "__typename")
      .map((k) => ({
        title: camel2title(k),
        dataIndex: k,
        sorter: {
          compare: (a, b) => a[k] - b[k],
          multiple: 1,
        },
        render: k === "numberOfOrders" ? undefined : price,
      })),
  ];

  const asCSV = useCallback(() => {
    const sorted = filterAndSortTableData(dataSource, COLUMNS as any);
    const csv = convertToCSV(sorted);
    download(
      `ecoeats-riders-${startDate.toDateString()}-${endDate.toDateString()}.csv`,
      csv
    );
  }, [dataSource, COLUMNS]);

  return (
    <>
      <Card
        style={{ overflowX: "auto", padding: 0 }}
        bodyStyle={{
          padding: 0,
        }}
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Title level={4} style={{ flex: 1 }}>
              Rider Earnings
            </Title>

            <DatePicker.RangePicker
              value={[moment(startDate), moment(endDate)]}
              onChange={(v) => {
                if (v && v[0] && v[1]) {
                  setStartDate(startOfDay(v[0]?.toDate()));
                  setEndDate(endOfDay(v[1]?.toDate()));
                }
              }}
              style={{
                marginRight: 8,
              }}
            />

            <Button onClick={asCSV} type="primary" icon={<DownloadOutlined />}>
              Financials Only
            </Button>
          </div>
        }
      >
        <Table
          dataSource={dataSource}
          showSorterTooltip
          loading={loading}
          columns={COLUMNS}
          pagination={{
            pageSize: 25,
          }}
          rowKey="id"
        />
      </Card>
    </>
  );
}
