import React, { useEffect } from "react";
import { useTypedQuery } from "../graphql/hooks";
import { CONFIG } from "../graphql/queries";
import { useStore } from "../state/zustand";

/**
 * Use the API config
 */
export default function useAPIConfig() {
  const { data } = useTypedQuery(CONFIG);

  const update = useStore((s) => s.setApiConfig);

  useEffect(() => {
    if (data) update(data.Configuration);
  }, [data?.Configuration]);
}
export const APIConfig = () => {
  useAPIConfig();

  return <></>;
};
