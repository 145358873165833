import { EditOutlined } from "@ant-design/icons";
import { Descriptions, Divider, PageHeader, Skeleton } from "antd";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { $ } from "../../graphql/generated";
import { query, useTypedQuery } from "../../graphql/hooks";
import DynamicFormButton from "../forms/dynamic-form/DynamicFormButton";
import LeaseTable from "../lease/LeaseTable";
import VehicleStatusBagde from "./VehicleStatusBadge";

const QUERY = query({
  LeaseVehicle: [
    {
      id: $`id`,
    },
    {
      id: true,
      created: true,
      note: true,
      dvlaInfo: true,
      registration: true,
      status: true,
    },
  ],
});

export default function VehicleView() {
  const { id } = useParams<{ id: string }>();

  const { data, refetch } = useTypedQuery(QUERY, {
    variables: {
      id,
    },
  });

  const lease = data?.LeaseVehicle;

  const [show, setShow] = useState(false);

  const onComplete = useCallback(() => {
    refetch();

    setShow(false);
  }, []);

  if (!lease) return <Skeleton active />;

  return (
    <>
      <PageHeader
        onBack={() => window.history.back()}
        title={lease.registration}
        subTitle="Vehicle"
        extra={[
          <DynamicFormButton
            button={{
              type: "primary",
              icon: <EditOutlined />,
            }}
            form={{
              id: "LeaseVehicle",
              entityId: lease.id,
              onComplete,
            }}
          >
            Edit
          </DynamicFormButton>,
        ]}
      >
        <Descriptions bordered>
          <Descriptions.Item label="Reg">
            {lease.registration}
          </Descriptions.Item>
          <Descriptions.Item label="Create">
            {new Date(lease.created).toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <VehicleStatusBagde status={lease.status} />
          </Descriptions.Item>
        </Descriptions>{" "}
        <Divider />
        <LeaseTable vehicleId={lease.id} />
        <Divider />
        <SyntaxHighlighter language="json" style={docco}>
          {JSON.stringify(lease.dvlaInfo, null, 2)}
        </SyntaxHighlighter>
      </PageHeader>
    </>
  );
}
