import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { AddMenuModifierGroupForm } from "../components/forms/AddMenuModiferGroupForm";
import { Restaurant } from "../graphql/generated";
import { _RESTAURANT } from "../graphql/queries";

export const AddModifierGroup = () => {
  const { id } = useParams();
  const [restaurant, setRestaurant] = useState<Restaurant>();
  useQuery(_RESTAURANT, {
    onCompleted: ({ Restaurant }) => setRestaurant(Restaurant),
    variables: { id },
  });

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Add Modifier Group"
          subtitle={restaurant?.name}
          className="text-sm-left"
        />
      </Row>

      <Row>
        {/* Editor */}
        <Col lg="12" md="12">
          <AddMenuModifierGroupForm restaurant={restaurant} />
        </Col>

        {/* Display example */}
        <Col lg="3" md="12" />
      </Row>
    </Container>
  );
};
