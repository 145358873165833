import { PageHeader, Skeleton } from "antd";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTypedQuery } from "../../graphql/hooks";
import { CHARITY } from "../../graphql/queries";
import DynamicForm from "../forms/DynamicForm";
import CharityCampaigns from "./CharityCampaigns";

export default function SingleCharity() {
  const { id } = useParams() as any;

  const { data } = useTypedQuery(CHARITY, {
    variables: { id },
  });

  const campaign = data?.Charity;

  if (!campaign) return <Skeleton active></Skeleton>;

  return (
    <>
      <PageHeader
        title={campaign.name}
        avatar={{
          size: 128,
          src: campaign.logo?.url,
        }}
        backIcon
        breadcrumb={{
          itemRender: (r) => <Link to={r.path}>{r.breadcrumbName}</Link>,
          routes: [
            {
              breadcrumbName: "Charities",
              path: "/donations/charities",
            },

            {
              breadcrumbName: campaign.name,
              path: "#",
            },
          ],
        }}
      >
        <DynamicForm id="Charity" entityId={id} />
        <CharityCampaigns charityId={id} />
      </PageHeader>
    </>
  );
}
