import { PageHeader, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import MerchantSiteBadge from "../components/merchant-sites/MerchantSiteBadge";
import SiteDomains from "../components/merchant-sites/SiteDomains";
import SitePages from "../components/merchant-sites/SitePages";
import { $ } from "../graphql/generated";
import { useTypedQuery } from "../graphql/hooks";
export default function MerchantSite() {
  const { id, tab } = useParams() as any;

  const [key, setKey] = useState("pages");

  useEffect(() => {
    setKey(tab);
  }, [tab]);

  const { data } = useTypedQuery(
    {
      MerchantSite: [
        {
          id: $`id`,
        },
        {
          id: true,
          name: true,
          status: true,
        },
      ],
    },
    {
      variables: {
        id,
      },
    }
  );

  const site = data?.MerchantSite;

  return (
    <Container fluid>
      <PageHeader
        title={site?.name}
        subTitle="Merchant Site"
        extra={[site && <MerchantSiteBadge status={site?.status} />]}
      >
        <Tabs activeKey={key} onChange={setKey} id="platform-nav">
          <Tabs.TabPane key="pages" tab="Pages">
            <SitePages id={id} />
          </Tabs.TabPane>

          <Tabs.TabPane key="domains" tab="Domains">
            <SiteDomains id={id} />
          </Tabs.TabPane>
          <Tabs.TabPane key="billing" tab="Billing"></Tabs.TabPane>
        </Tabs>
      </PageHeader>
    </Container>
  );
}
