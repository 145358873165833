import React from "react";
import { createDndContext, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// only reacts to drag sources of the <acceptType>, in this case itself

export const RNDContext = createDndContext(HTML5Backend);

export const DraggableTableRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const acceptType: any = "DraggableMenuRow";

  const ref = React.useRef<HTMLTableRowElement>();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: acceptType,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow((item as any).index, index);
    },
  });
  const [, drag] = useDrag({
    item: {
      type: acceptType,
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};
