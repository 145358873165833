import { Badge } from "antd";
import React from "react";
import { LeaseVehicleStatus } from "../../graphql/generated";

export default function VehicleStatusBagde(r: { status: LeaseVehicleStatus }) {
  return (
    <Badge
      text={r.status}
      status={
        r.status === LeaseVehicleStatus.READY
          ? "success"
          : r.status === LeaseVehicleStatus.MAINTENANCE
          ? "warning"
          : "processing"
      }
    />
  );
}
