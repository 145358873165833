import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Badge, Button, Drawer, Input, Space, Table } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { $, IncidentSeverity } from "../../graphql/generated";
import { useTypedQuery } from "../../graphql/hooks";
import usePagination from "../../hooks/usePagination";
import CreateIncidentForm from "./CreateIncidentForm";

export default function IncidentTable({
  orderId,
  customerId,
  partnerId,
  riderId,
}: {
  orderId?: string;
  partnerId?: string;
  riderId?: string;
  customerId?: string;
}) {
  const {
    pagination,
    args: { order, page, query, sortBy, take },
    onChange,
    setQuery,
  } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "created",
  });

  const { data, refetch } = useTypedQuery(
    {
      IncidentList: [
        {
          skip: $`skip`,
          take: $`take`,
          sortBy: $`sortBy`,
          order: $`order`,
          riderId: $`riderId`,
          partnerId: $`partnerId`,
          customerId: $`customerId`,
          orderId: $`orderId`,
          query: $`query`,
        },
        {
          total: true,
          items: {
            id: true,
            severity: true,
            created: true,
            atFault: true,
            description: true,
            number: true,
            customer: {
              id: true,
              name: true,
              email: true,
            },
            rider: {
              id: true,
              name: true,
              email: true,
            },
            partner: {
              id: true,
              name: true,
            },
            order: {
              id: true,
              number: true,
              restaurant: {
                id: true,
                name: true,
              },
            },
          },
        },
      ],
    },
    {
      fetchPolicy: "network-only",
      variables: {
        skip: (page - 1) * take,
        take,
        sortBy,
        order,
        orderId,
        query,
        partnerId,
        customerId,
        riderId,
      },
      pollInterval: 10000,
    }
  );

  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<string>("");

  return (
    <>
      <Table
        title={() => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Title level={4} style={{ flex: 1 }}>
              {data?.IncidentList.total} Incidents
            </Title>

            <Space>
              <Input
                placeholder="Search..."
                suffix={<SearchOutlined />}
                onChange={(t) => setQuery(t.target.value)}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setShow(true)}
              >
                Create Incident
              </Button>
            </Space>
          </div>
        )}
        onChange={(p, f, s) => {
          onChange(p, f, s);
        }}
        dataSource={data?.IncidentList.items}
        pagination={{
          total: data?.IncidentList.total,
          ...pagination,
        }}
        columns={[
          {
            title: "Number",
            dataIndex: "number",
            sorter: true,
            render: (_, r) => (
              <Link to="#" onClick={() => setSelected(r.id)}>
                I-{r.number}
              </Link>
            ),
          },
          {
            title: "Created",
            dataIndex: "created",
            sorter: true,
            render: (r) => new Date(r).toLocaleString(),
          },
          {
            title: "Order",
            render: (_, r) =>
              r.order ? (
                <Link to={`/order/${r.order.id}`}>#{r.order.number}</Link>
              ) : (
                <Text type="secondary">No Order</Text>
              ),
          },
          {
            title: "Partner",
            render: (_, r) =>
              r.partner ? (
                <Link to={`/restaurant/${r.partner.id}`}>{r.partner.name}</Link>
              ) : (
                <Text type="secondary">No Partner</Text>
              ),
          },
          {
            title: "Rider",
            render: (_, r) =>
              r.rider ? (
                <Link to={`/rider/${r.rider.id}`}>{r.rider.name}</Link>
              ) : (
                <Text type="secondary">No Rider</Text>
              ),
          },
          {
            title: "Customer",
            render: (_, r) =>
              r.customer ? (
                <Link to={`/customer/${r.customer.id}`}>{r.customer.name}</Link>
              ) : (
                <Text type="secondary">No Customer</Text>
              ),
          },
          {
            title: "At Fault",
            dataIndex: "atFault",
            sorter: true,
          },
          {
            title: "Severity",
            dataIndex: "severity",
            sorter: true,
            render: (_, r) => (
              <Badge
                text={r.severity}
                status={
                  r.severity === IncidentSeverity.MAJOR
                    ? "error"
                    : r.severity === IncidentSeverity.MODERATE
                    ? "warning"
                    : "default"
                }
              />
            ),
          },
        ]}
      />

      <Drawer
        width={window.innerWidth <= 768 ? "100%" : "50%"}
        visible={!!(show || selected)}
        onClose={() => {
          setShow(false);
          setSelected("");
        }}
      >
        {!!(show || selected) ? (
          <CreateIncidentForm
            incidentId={selected}
            orderId={orderId}
            customerId={customerId}
            partnerId={partnerId}
            riderId={riderId}
            onCompleted={() => {
              refetch();
              setSelected("");
              setShow(false);
            }}
          />
        ) : null}
      </Drawer>
    </>
  );
}
