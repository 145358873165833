import {
  Alert,
  Button,
  Card,
  Divider,
  Input,
  message,
  Modal,
  PageHeader,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  $,
  IncidentSeverity,
  MapType,
  Mutation,
  UserType,
} from "../../graphql/generated";
import {
  mutation,
  query,
  useTypedMutation,
  useTypedQuery,
} from "../../graphql/hooks";
import { getCDNUrl } from "../../utils/image-url";
import SelectImageAsset from "../SelectImageAsset";
import { GenericAuditTableColumns } from "./GenericAuditTable";

const SEVERITY_OPTIONS = [
  IncidentSeverity.MINOR,
  IncidentSeverity.MODERATE,
  IncidentSeverity.MAJOR,
].map((d, i) => ({
  label: d,
  value: d,
}));

const AT_FAULT_OPTIONS = Object.keys(UserType).map((d, i) => ({
  label: d,
  value: d,
}));

const CREATE_MUTATION = mutation({
  createIncident: [
    {
      incident: $`incident`,
    },
    {
      id: true,
    },
  ],
});

const ORDER = query({
  order: [
    {
      id: $`id`,
    },
    {
      id: true,
      number: true,
      restaurant: {
        id: true,
        name: true,
      },
      rider: {
        id: true,
        name: true,
      },
      customer: {
        id: true,
        name: true,
      },
    },
  ],
});

const CUSTOMER = query({
  Customer: [
    {
      id: $`customerId`,
    },
    {
      id: true,
      name: true,
    },
  ],
});

const RIDER = query({
  Rider: [
    {
      id: $`riderId`,
    },
    {
      id: true,
      name: true,
      email: true,
    },
  ],
});

const INCIDENT = query({
  Incident: [
    {
      id: $`id`,
    },
    {
      id: true,
      severity: true,
      atFault: true,
      description: true,
      number: true,
      created: true,
      customer: {
        id: true,
        name: true,
        email: true,
      },
      rider: {
        id: true,
        name: true,
        email: true,
      },
      partner: {
        id: true,
        name: true,
      },
      order: {
        id: true,
        number: true,
        restaurant: {
          id: true,
          name: true,
        },
      },
      audit: {
        id: true,
        from: true,
        to: true,
        timestamp: true,
      },
      images: {
        id: true,
        key: true,
        created: true,
      },
    },
  ],
});

const UPDATE_MUTATION = mutation({
  updateIncident: [
    {
      id: $`id`,
      incident: $`incident`,
    },
    {
      ...INCIDENT.Incident[1],
    },
  ],
});

function AssociateIdButton({
  id,
  onChange,
  title,
}: {
  id?: string;
  onChange: (id?: string) => void;
  title: string;
}) {
  return (
    <Button
      type="primary"
      danger={!!id}
      onClick={() => {
        if (!id) {
          const { destroy } = Modal.confirm({
            title: `Associate ${title}`,
            closable: true,
            content: (
              <Form
                onFinish={(v) => {
                  onChange(v.id);
                  destroy();
                }}
              >
                <Form.Item
                  name="id"
                  label="ID"
                  help={`Get the ID of the ${title} from the URL of its page.`}
                >
                  <Input />
                </Form.Item>

                <Space>
                  <Button htmlType="submit" type="primary">
                    Associate
                  </Button>
                </Space>
              </Form>
            ),
            okButtonProps: {
              hidden: true,
            },
            cancelButtonProps: {
              hidden: true,
            },
          });
        } else {
          onChange(undefined);
        }
      }}
    >
      {id ? "Remove" : "Find"}
    </Button>
  );
}

function AssociatedEntityHeader({
  id,
  onChange,
  title,
  type,
  url,
}: {
  id?: string;
  onChange: (id?: string) => void;
  title?: string;
  type: string;
  url: string;
}) {
  return (
    <PageHeader
      title={
        title ? (
          <Link to={url}>
            <Title level={5}>{title}</Title>
          </Link>
        ) : (
          <Alert type={"info"} message={`No ${type} Associated`}></Alert>
        )
      }
      subTitle={type}
      extra={[<AssociateIdButton id={id} onChange={onChange} title={type} />]}
    />
  );
}

export default function CreateIncidentForm({
  incidentId,

  onCompleted,
  ...ids
}: {
  incidentId?: string;
  orderId?: string;
  partnerId?: string;
  riderId?: string;
  customerId?: string;
  onCompleted?: (
    v: MapType<Mutation, typeof CREATE_MUTATION>["createIncident"]
  ) => void;
}) {
  const [orderId, setOrderId] = useState(ids.orderId);
  const [partnerId, setPartnerId] = useState(ids.partnerId);
  const [customerId, setCustomerId] = useState(ids.customerId);
  const [riderId, setRiderId] = useState(ids.riderId);
  const [images, setImages] = useState<
    { id: string; key: string; created: any }[]
  >([]);

  const incidentQuery = useTypedQuery(INCIDENT, {
    skip: !incidentId,
    variables: {
      id: incidentId,
    },
  });

  const riderQuery = useTypedQuery(RIDER, {
    skip: !riderId,
    variables: {
      riderId: riderId,
    },
  });
  const customerQuery = useTypedQuery(CUSTOMER, {
    skip: !customerId,
    variables: {
      customerId,
    },
  });

  const restaurantQuery = useTypedQuery(
    {
      Restaurant: [
        {
          id: $`partnerId`,
        },
        {
          id: true,
          name: true,
        },
      ],
    },
    {
      skip: !partnerId,
      variables: {
        partnerId,
      },
    }
  );
  const orderQuery = useTypedQuery(ORDER, {
    variables: {
      id: orderId,
    },
  });

  const [createIncident] = useTypedMutation(CREATE_MUTATION, {
    onCompleted: (d) => {
      message.success("Created!");
      onCompleted?.(d.createIncident);
    },
  });

  const [updateIncident] = useTypedMutation(UPDATE_MUTATION, {
    onCompleted: (d) => {
      message.success("Saved!");
    },
  });

  const [form] = useForm();

  const incident = incidentQuery.data?.Incident;

  useEffect(() => {
    const order = orderQuery.data?.order;
    if (order && !incidentId) {
      if (order.customer.id) setCustomerId(order.customer.id);
      if (order.rider?.id) setRiderId(order.rider.id);
      if (order.restaurant.id) setPartnerId(order.restaurant.id);
    }
  }, [orderQuery.data?.order?.id]);

  useEffect(() => {
    if (incident) {
      if (incident.customer) setCustomerId(incident.customer.id);
      if (incident.rider) setRiderId(incident.rider.id);
      if (incident.partner) setPartnerId(incident.partner.id);
      if (incident.order) setOrderId(incident.order.id);
      if (incident.images) setImages(incident.images);
    }
  }, [incident]);

  return (
    <>
      {!incidentId || (incidentId && incident) ? (
        <Form
          form={form}
          layout="vertical"
          initialValues={incidentQuery.data?.Incident}
          onFinish={(v) =>
            incident
              ? updateIncident({
                  variables: {
                    id: incident.id,
                    incident: {
                      ...v,
                      imageIds: images.map((i) => i.id),
                      partnerId,
                      orderId,
                      customerId,
                      riderId,
                    },
                  },
                })
              : createIncident({
                  variables: {
                    incident: {
                      ...v,
                      partnerId,
                      orderId,
                      customerId,
                      riderId,
                    },
                  },
                })
          }
        >
          <Title level={4}>
            {incident ? `Incident ${incident.number}` : "Create Incident"}
          </Title>

          <AssociatedEntityHeader
            id={orderId}
            onChange={setOrderId}
            type="Order"
            title={
              orderQuery.data?.order
                ? `#${orderQuery.data?.order?.number}`
                : undefined
            }
            url={`/order/${orderId}`}
          />
          <AssociatedEntityHeader
            id={riderId}
            onChange={setRiderId}
            type="Rider"
            title={riderQuery.data?.Rider.name}
            url={`/rider/${riderId}`}
          />
          <AssociatedEntityHeader
            id={partnerId}
            onChange={setPartnerId}
            type="Merchant"
            title={restaurantQuery.data?.Restaurant.name}
            url={`/restaurant/${partnerId}`}
          />
          <AssociatedEntityHeader
            id={customerId}
            onChange={setCustomerId}
            type="Customer"
            title={customerQuery.data?.Customer.name}
            url={`/customer/${customerId}`}
          />

          <Form.Item
            label="Severity"
            name="severity"
            help={"How severe is this incident?"}
          >
            <Select options={SEVERITY_OPTIONS} />
          </Form.Item>

          <Form.Item
            label="At Fault"
            name="atFault"
            help={"Who is at fault for this incident?"}
          >
            <Select options={AT_FAULT_OPTIONS} />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            help="Describe what happened in the incident. Provide enough detail to allow another agent to get up to speed."
          >
            <Input.TextArea rows={8} />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            {incident ? "Save" : "Create"}
          </Button>

          <Divider />

          {incident && (
            <PageHeader
              title={`${incident?.images.length} Images`}
              extra={
                <SelectImageAsset
                  onChange={(i) => {
                    setImages((ids) => [...ids, i[0]]);
                    setTimeout(() => form.submit(), 100);
                  }}
                />
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  overflowY: "scroll",
                  maxHeight: "550px",
                }}
              >
                {images.map((s) => (
                  <Card
                    key={s.id}
                    cover={
                      <img
                        src={getCDNUrl(s.key)}
                        style={{ maxHeight: 450, width: "auto" }}
                      />
                    }
                    style={{ marginRight: 8 }}
                    actions={[
                      <Button
                        onClick={() => {
                          setImages((ids) => ids.filter((i) => i.id !== s.id));
                          setTimeout(() => form.submit(), 100);
                        }}
                      >
                        Remove
                      </Button>,
                    ]}
                  >
                    <Card.Meta title={s.created} />
                  </Card>
                ))}
              </div>
            </PageHeader>
          )}

          <Divider />
          {incident && (
            <Table
              dataSource={incident.audit}
              columns={GenericAuditTableColumns}
            />
          )}
        </Form>
      ) : incidentQuery.loading ? (
        <Spin />
      ) : null}
    </>
  );
}
