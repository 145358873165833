import { ColumnsType } from "antd/lib/table";
import React from "react";
import { Link } from "react-router-dom";
import { MapType, Query } from "../../graphql/generated";
import { CHARITY_CAMPAIGN_LIST } from "../../graphql/queries";
import DynamicForm from "../forms/DynamicForm";
import FutureDateBadge from "../FutureDateBadge";

type QueryType = MapType<
  Query,
  typeof CHARITY_CAMPAIGN_LIST
>["CharityCampaignList"];

const COLUMNS: ColumnsType<QueryType["items"][0]> = [
  {
    title: "Image",
    render: (_, c) => (
      <div style={{ width: 128, height: 128, backgroundColor: "lightgrey" }}>
        <img width="128" src={c.campaignLogo?.url} />
      </div>
    ),
  },
  {
    title: "Campaign",
    render: (_, c) => <Link to={`/campaign/${c.id}`}>{c.title}</Link>,
  },
  {
    title: "Charity",
    render: (_, c) => (
      <Link to={`/charity/${c.charity.id}`}>{c.charity.name}</Link>
    ),
  },
  {
    render: (_, c) => (
      <FutureDateBadge verbose start={c.startDate} end={c.endDate} />
    ),
  },
];

export default function CharityCampaigns({
  charityId,
}: {
  charityId?: string;
}) {
  return (
    <>
      <DynamicForm id="CharityCampaign.List" />
    </>
  );
}
