import { Col, PageHeader, Row } from "antd";
import React from "react";
import AdminsTable from "../components/AdminsTable";

const Admins = () => {
  return (
    <div>
      {/* Page Header */}
      <Row>
        <PageHeader title="Admins" subTitle="Restaurants and Admins" />
      </Row>

      <Row gutter={32}>
        <Col
          flex={1}
          style={{
            padding: "0 32px",
          }}
        >
          <AdminsTable />
        </Col>
      </Row>
    </div>
  );
};

export default Admins;
