import { Descriptions, Divider, PageHeader } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import { useParams } from "react-router";
import { style } from "typestyle";
import { $ } from "../../graphql/generated";
import { query, useTypedQuery } from "../../graphql/hooks";
import { getImageUrl } from "../../utils/image-url";
import ScannedContainerTable from "./ScannedContainerTable";

const SHIPMENT = query({
  ReusableContainerType: [
    {
      id: $`id`,
    },
    {
      id: true,
      depth: true,
      length: true,
      width: true,
      unitPrice: true,
      name: true,
      image: {
        id: true,
        key: true,
      },
      inStock: true,
    },
  ],
});

const styles = {
  icon: style({
    display: "flex",
    borderRadius: 30,
    backgroundColor: "white",
    height: 32,
    width: 32,
    justifyContent: "center",
    alignItems: "center",
  }),
};

export default function ContainerTypeView() {
  const { id } = useParams();

  const { data } = useTypedQuery(SHIPMENT, {
    variables: {
      id,
    },
  });

  const type = data?.ReusableContainerType;

  return (
    <PageHeader
      title={
        <Title
          level={5}
          style={{
            fontFamily: "monospace",
            marginBottom: 0,
          }}
        >
          {type?.name}
        </Title>
      }
      avatar={{
        style: {
          width: 128,
          height: 128,
        },
        src: type?.image?.key
          ? getImageUrl(type.image.key, 256, 256)
          : undefined,
      }}
      subTitle="Reusables Container"
    >
      <Descriptions bordered>
        <Descriptions.Item label="Type">{type?.name}</Descriptions.Item>
        <Descriptions.Item label="Dimensions">
          {type?.length}x{type?.width}x{type?.depth}
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      <ScannedContainerTable />
    </PageHeader>
  );
}
