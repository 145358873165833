import {
  CheckCircleFilled,
  CloseCircleFilled,
  PlusCircleFilled,
  QuestionCircleFilled,
} from "@ant-design/icons";
import { Input, Modal, Table, Tag } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelectedMenu } from "../RestaurantMenu";

interface Props {
  show: boolean;
  onHide: () => void;
  onSave: (selected: string[], unselected?: string[]) => void;
  restaurant?: {
    name: string;
  };
  initialSelectedModifierGroupIds: string[];
  // those which are explicitly not selected for use when there are multiple items
  // undefined if only a single item
  initialUnselectedModifierGroupIds?: string[];
}

type MultiItemState = "unselected" | "mixed" | "selected";
const multiItemStates: MultiItemState[] = ["unselected", "mixed", "selected"];

export const SearchModifierGroups = ({
  show,
  onHide,
  onSave,
  restaurant,
  initialSelectedModifierGroupIds,
  initialUnselectedModifierGroupIds,
}: Props) => {
  const {
    query: { data },
  } = useSelectedMenu();
  const menuMgs = data?.Menu.modifierGroups;

  const [selected, setSelected] = useState<string[]>(
    initialSelectedModifierGroupIds || []
  );

  useEffect(() => {
    setSelected(initialSelectedModifierGroupIds);
  }, [initialSelectedModifierGroupIds]);

  const multiItem = initialUnselectedModifierGroupIds != undefined;

  const [unselected, setUnselected] = useState<string[] | undefined>(
    initialUnselectedModifierGroupIds
  );

  useEffect(() => {
    setUnselected(initialUnselectedModifierGroupIds);
  }, [initialUnselectedModifierGroupIds]);

  const [query, setQuery] = useState("");

  const onSelect = useCallback((id) => {
    setSelected((selected) => {
      const idx = selected.findIndex((eid) => id === eid);
      if (idx !== -1)
        return selected
          .slice(0, idx)
          .concat(selected.slice(idx + 1, selected.length));

      return [...selected, id];
    });
  }, []);

  const onMultiSelect = useCallback(
    (id: string, currentState: MultiItemState) => {
      const nextState =
        multiItemStates[
          (multiItemStates.findIndex((val) => val === currentState) + 1) %
            multiItemStates.length
        ];
      // add to the relevant selected or unselected array, as applicable
      if (nextState === "selected") {
        setSelected((selected) => {
          const idx = selected.findIndex((eid) => id === eid);
          // don't add if already present
          if (idx !== -1) return selected;
          return [...selected, id];
        });
      } else if (nextState === "unselected" && unselected != undefined) {
        setUnselected((unselected) => {
          if (unselected == undefined) return undefined;
          const idx = unselected.findIndex((eid) => id === eid);
          // don't add if already present
          if (idx !== -1) return unselected;
          return [...unselected, id];
        });
      }
      // remove from the previous selected or unselected array, as applicable
      if (currentState === "selected") {
        setSelected((selected) => {
          const idx = selected.findIndex((eid) => id === eid);
          // remove if present
          if (idx !== -1)
            return selected
              .slice(0, idx)
              .concat(selected.slice(idx + 1, selected.length));
          // otherwise don't change
          return selected;
        });
      } else if (currentState === "unselected") {
        setUnselected((unselected) => {
          if (unselected == undefined) return undefined;
          const idx = unselected.findIndex((eid) => id === eid);
          // remove if present
          if (idx !== -1)
            return unselected
              .slice(0, idx)
              .concat(unselected.slice(idx + 1, unselected.length));
          // otherwise don't change
          return unselected;
        });
      }
    },
    [unselected]
  );

  const groups = useMemo(
    () =>
      menuMgs?.map((mg) => ({
        ...mg,
        selected: selected.includes(mg.id),
        unselected: unselected?.includes(mg.id),
      })) || [],
    [menuMgs, selected, unselected]
  );

  const filteredGroups = useMemo(
    () =>
      groups?.filter(
        (mg) =>
          mg.name.toLowerCase().includes(query.toLowerCase()) ||
          mg.instruction?.toLowerCase().includes(query.toLowerCase())
      ),
    [groups, query]
  );

  const onOk = useCallback(() => onSave(selected, unselected), [
    selected,
    unselected,
    onSave,
  ]);

  return (
    <Modal
      visible={show}
      onCancel={() => {
        setSelected(initialSelectedModifierGroupIds);
        setUnselected(initialUnselectedModifierGroupIds);
        onHide();
      }}
      title={`Modifier Groups in ${restaurant?.name}`}
      okText="Add to Item"
      onOk={onOk}
    >
      <Input
        name="search"
        onChange={(e) => setQuery(e.currentTarget.value)}
        placeholder="Search Groups"
        style={{ marginBottom: 8 }}
      />

      <Table
        dataSource={filteredGroups}
        onRow={(mg) => ({
          style: {
            cursor: "pointer",
          },
          onClick: () => {
            if (multiItemStates) {
              onMultiSelect(
                mg.id,
                mg.selected
                  ? "selected"
                  : mg.unselected
                  ? "unselected"
                  : "mixed"
              );
            } else {
              onSelect(mg.id);
            }
          },
        })}
        pagination={{
          pageSize: 5,
        }}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            sorter: {
              compare: (a, b) => String(a.name).localeCompare(b.name!),
            },
          },
          {
            title: "Instruction",
            dataIndex: "instruction",
            sorter: {
              compare: (a, b) =>
                String(a.instruction).localeCompare(b.instruction!),
            },
          },
          {
            render: (r) => {
              const iconSize = 18;
              if (multiItem && r.unselected != null) {
                var color: string;
                var icon: JSX.Element;
                if (r.selected) {
                  color = "green";
                  icon = <CheckCircleFilled size={iconSize} />;
                } else if (r.unselected) {
                  color = "red";
                  icon = <CloseCircleFilled size={iconSize} />;
                } else {
                  color = "default";
                  icon = <QuestionCircleFilled size={iconSize} />;
                }
                return (
                  <Tag
                    color={color}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 5,
                    }}
                  >
                    {icon}
                  </Tag>
                );
              } else {
                return (
                  <Tag
                    color={r.selected ? "green" : "default"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 5,
                    }}
                  >
                    {r.selected ? (
                      <CheckCircleFilled size={iconSize} />
                    ) : (
                      <PlusCircleFilled size={iconSize} />
                    )}
                  </Tag>
                );
              }
            },
          },
        ]}
      />
    </Modal>
  );
};
