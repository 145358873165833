import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Badge, Button, Input, Space, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { $, ReusableShipmentStatus } from "../../graphql/generated";
import { query, useTypedQuery } from "../../graphql/hooks";
import usePagination from "../../hooks/usePagination";
import DynamicForm from "../forms/DynamicForm";

export const SHIPMENT_COLUMNS = [
  {
    title: "ID",
    sorter: true,
    dataIndex: "id",
    render: (_, r) => (
      <Link
        style={{ fontFamily: "monospace" }}
        to={`/reusables/shipment/${r.id}`}
      >
        {r.id.slice(0, 8)}
      </Link>
    ),
  },
  {
    title: "Washer",
    key: "washer",
    sorter: true,
    render: (_, r) =>
      r.washingLocation ? (
        <Link to={`/reusables/washing-location/${r.washingLocation.id}`}>
          {r.washingLocation.name}
        </Link>
      ) : (
        <Text type="secondary">Unassigned</Text>
      ),
  },
  {
    title: "Scheduled For",
    key: "scheduledFor",
    sorter: true,
    render: (_, r) => new Date(r.scheduledFor).toLocaleString(),
  },
  {
    title: "Status",
    key: "status",
    sorter: true,
    render: (_, r) => (
      <Badge
        status={
          r.status === ReusableShipmentStatus.PENDING ? "processing" : "default"
        }
        color={
          r.status === ReusableShipmentStatus.EN_ROUTE
            ? "orange"
            : r.status === ReusableShipmentStatus.COMPLETE
            ? "green"
            : ReusableShipmentStatus.PREPARING
            ? "blue"
            : undefined
        }
        text={r.status}
      />
    ),
  },
  {
    title: "ETA",
    key: "eta",
    sorter: true,
    render: (_, r) =>
      r.estimatedArrivalTime ? (
        new Date(r.estimatedArrivalTime).toLocaleString()
      ) : (
        <Text type="secondary">No ETA</Text>
      ),
  },
  {
    title: "Partner",
    key: "partner",
    render: (_, r) => (
      <Link to={`/restaurant/${r.partner.id}`}>{r.partner.name}</Link>
    ),
  },
];

const SITE_TABLE = query({
  ReusableShipmentList: [
    {
      skip: $`skip`,
      take: $`take`,
      sortBy: $`sortBy`,
      order: $`order`,
      query: $`query`,
      partnerId: $`partnerId`,
    },
    {
      total: true,
      items: {
        id: true,
        createdAt: true,
        scheduledFor: true,
        status: true,
        estimatedArrivalTime: true,
        washingLocation: {
          id: true,
          name: true,
        },
        containers: {
          quantity: true,
          containerType: {
            id: true,
            name: true,
          },
        },
        partner: {
          id: true,
          name: true,
        },
      },
    },
  ],
});

export default function ReusableShipmentTable({
  partnerId,
  washingLocationId,
}: {
  partnerId?: string;
  washingLocationId?: string;
}) {
  const {
    pagination,
    args: { order, page, query, sortBy, take },
    onChange,
    setQuery,
  } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "createdAt",
  });

  const { data, refetch } = useTypedQuery(SITE_TABLE, {
    fetchPolicy: "network-only",
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
      partnerId,
    },
  });

  const [show, setShow] = useState(false);

  return (
    <>
      <Table
        title={() => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Title level={4} style={{ flex: 1 }}>
              {data?.ReusableShipmentList.total} Shipments
            </Title>

            <Space>
              <Input
                placeholder="Search..."
                suffix={<SearchOutlined />}
                onChange={(t) => setQuery(t.target.value)}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setShow(true)}
              >
                New Shipment
              </Button>
            </Space>
          </div>
        )}
        onChange={(p, f, s) => {
          onChange(p, f, s);
        }}
        expandable={{
          rowExpandable: () => true,
          defaultExpandAllRows: false,
          expandedRowRender: (r) => <pre>{JSON.stringify(r, null, 2)}</pre>,
        }}
        dataSource={data?.ReusableShipmentList.items}
        pagination={{
          total: data?.ReusableShipmentList.total,
          ...pagination,
        }}
        rowKey={"id"}
        columns={SHIPMENT_COLUMNS}
      />
      <Modal
        footer={null}
        visible={show}
        destroyOnClose
        onCancel={() => setShow(false)}
      >
        <DynamicForm
          onComplete={() => {
            refetch();
            setShow(false);
          }}
          noCard
          initialValues={{
            partnerId,
          }}
          id="ReusableShipmentCreate"
        />
      </Modal>
    </>
  );
}
