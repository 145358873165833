import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { Badge, Button, Menu, Space } from "antd";
import React, { createRef, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MapType, Query } from "../../graphql/generated";
import { ZONE } from "../../graphql/queries";
import { ZoneContext } from "../../views/Zone";
import { OnChangeOrdersTableConfig, OrdersTableConfig } from "../OrdersTable";
import ZoneAlerts from "../zone/ZoneAlerts";
import ZoneDeliveryParameters, {
  ZoneDeliveryParametersRef,
} from "../zone/ZoneDeliveryParameters";
import ZoneBoosts from "../zone/ZoneBoosts";
import ZonePartners, { ZonePartnersRef } from "../zone/ZonePartners";
import ZoneBookings from "../zone/ZoneBookings";
import ZoneRiders from "../zone/ZoneRiders";
import ZoneOverView from "../zone/ZoneOverview";
import ZoneStatus from "../zone/ZoneStatus";
import { PanelArrangement } from "../common/SplitPanels";
import { RidersTableRef } from "../RidersTable";
import OpsZoneAutoScaler from "../zone/OpsZoneAutoScaler";

export type ZoneOverviewPanelTab =
  | "orders"
  | "alerts"
  | "delivery"
  | "boosts"
  | "auto-scaler"
  | "partners"
  | "bookings"
  | "riders";

export const allZoneOverviewPanelTabs: {
  id: ZoneOverviewPanelTab;
  displayName: string;
}[] = [
  {
    id: "orders",
    displayName: "Orders",
  },
  {
    id: "alerts",
    displayName: "Alerts",
  },
  {
    id: "delivery",
    displayName: "Delivery",
  },
  {
    id: "boosts",
    displayName: "Boosts",
  },
  {
    id: "auto-scaler",
    displayName: "AutoScaler",
  },
  {
    id: "partners",
    displayName: "Partners",
  },
  {
    id: "bookings",
    displayName: "Bookings",
  },
  {
    id: "riders",
    displayName: "Riders",
  },
];

export interface ZoneOverviewPanelConfig {
  expanded?: boolean;
  onExpandedChange?: (updatedExpanded: boolean) => void;
  ordersTableConfig?: OrdersTableConfig;
  onOrdersTableConfigChange?: OnChangeOrdersTableConfig;
  activeTab?: ZoneOverviewPanelTab;
  onChangeActiveTab?: (newActiveTab: ZoneOverviewPanelTab) => void;
  showRiderStats: boolean;
  hiddenTabs: ZoneOverviewPanelTab[];
}

type TabInfo = {
  id: ZoneOverviewPanelTab;
  displayName: string;
  tabContent: JSX.Element;
};

interface Props {
  zone: MapType<Query, typeof ZONE>["zone"];
  refetchZone: () => void;
  fullscreen: boolean;
  onFullscreenClick: () => void;
  config: ZoneOverviewPanelConfig;
  panelArrangement: PanelArrangement;
}

const borderColour = "rgb(220, 220, 220)";

const ZoneOverviewPanel = (props: Props) => {
  const {
    zone,
    refetchZone,
    fullscreen,
    onFullscreenClick,
    config,
    panelArrangement,
  } = props;

  const {
    expanded: expandedProp,
    onExpandedChange,
    activeTab: propsActiveTab,
    onChangeActiveTab,
  } = config;

  const [expanded, setExpanded] = useState(
    expandedProp != null ? expandedProp : true
  );

  useEffect(() => {
    if (expandedProp != null) setExpanded(expandedProp);
  }, [expandedProp]);

  const [activeTab, setActiveTab] = useState<ZoneOverviewPanelTab>(
    propsActiveTab || "orders"
  );

  useEffect(() => {
    if (propsActiveTab) setActiveTab(propsActiveTab);
  }, [propsActiveTab]);

  const [nZoneAlerts, setNZoneAlerts] = useState(0);

  const persistContextId = zone.id
    ? `zone-overview-panel-${zone.id}`
    : undefined;

  const zonePartners = createRef<ZonePartnersRef>();

  const zoneDeliveryParameters = createRef<ZoneDeliveryParametersRef>();

  const zoneRiders = createRef<RidersTableRef>();

  const refreshMaps = useCallback(() => {
    zonePartners.current?.refreshMap();
    zoneDeliveryParameters.current?.refreshMap();
    zoneRiders.current?.refreshMap();
  }, [zoneDeliveryParameters, zonePartners, zoneRiders]);

  // whenever swapping to/from fullscreen or changing the panel arrangement
  //  the position of the maps may be incorrect
  useEffect(() => {
    refreshMaps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullscreen, panelArrangement, activeTab]);

  // on mount position of maps may be incorrect
  useEffect(() => {
    refreshMaps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs: TabInfo[] = [
    {
      id: "orders",
      displayName: "Orders",
      tabContent: (
        <ZoneOverView
          zone={zone}
          visible={activeTab === "orders"}
          persistContextId={persistContextId}
        />
      ),
    },
    {
      id: "alerts",
      displayName: "Alerts",
      tabContent: (
        <ZoneAlerts
          setAlerts={setNZoneAlerts}
          zoneIds={[zone.id]}
          visible={activeTab === "alerts"}
          persistContextId={persistContextId}
        />
      ),
    },
    {
      id: "delivery",
      displayName: "Delivery",
      tabContent: (
        <ZoneDeliveryParameters
          ref={zoneDeliveryParameters}
          visible={activeTab === "delivery"}
        />
      ),
    },
    {
      id: "boosts",
      displayName: "Boosts",
      tabContent: (
        <ZoneBoosts
          zone={zone}
          refetch={refetchZone}
          visible={activeTab === "boosts"}
          persistContextId={persistContextId}
        />
      ),
    },
    {
      id: "auto-scaler",
      displayName: "AutoScaler",
      tabContent: (
        <OpsZoneAutoScaler
          zone={zone}
          refetch={refetchZone}
          visible={activeTab === "auto-scaler"}
        />
      ),
    },
    {
      id: "partners",
      displayName: "Partners",
      tabContent: (
        <ZonePartners
          ref={zonePartners}
          zone={zone}
          persistContextId={persistContextId}
          visible={activeTab === "partners"}
        />
      ),
    },
    {
      id: "bookings",
      displayName: "Bookings",
      tabContent: (
        <ZoneBookings
          persistContextId={persistContextId}
          visible={activeTab === "bookings"}
        />
      ),
    },
    {
      id: "riders",
      displayName: "Riders",
      tabContent: (
        <ZoneRiders
          ref={zoneRiders}
          zone={zone}
          persistContextId={persistContextId}
          visible={activeTab === "riders"}
        />
      ),
    },
  ];

  const visibleTabs = tabs.filter((tab) => !config.hiddenTabs.includes(tab.id));

  return (
    <ZoneContext.Provider value={zone}>
      <div
        style={{
          maxHeight: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          borderTop: `2px solid ${borderColour}`,
        }}
      >
        <div
          style={{
            display: "flex",
            position: "relative",
            justifyContent: "center",
            borderBottom: !expanded ? `1px solid  ${borderColour}` : undefined,
            marginTop: 10,
            minHeight: 35,
          }}
        >
          <Badge count={nZoneAlerts} offset={[4, 0]} size="small">
            <Link style={{ fontSize: "1.7rem" }} to={`/zone/${zone.id}`}>
              {zone.name ? zone.name : "Zone"}
            </Link>
          </Badge>
          <Space
            style={{
              position: "absolute",
              top: -5,
              right: 15,
            }}
          >
            {!expanded ? (
              <ZoneStatus
                zone={
                  zone as any //TODO: ensure autoScalerEnabled is added as an attribute
                }
                showLiveView={false}
                showAutoScalerStatus={false}
              />
            ) : null}
            <Button
              style={{
                borderRadius: "50%",
              }}
              icon={
                <i className="material-icons">
                  {expanded ? "expand_less" : "expand_more"}
                </i>
              }
              onClick={() => {
                const newExpanded = !expanded;
                if (onExpandedChange) {
                  onExpandedChange(newExpanded);
                } else {
                  setExpanded(newExpanded);
                }
              }}
            />
            <Button
              style={{
                borderRadius: "50%",
              }}
              icon={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {fullscreen ? (
                    <FullscreenExitOutlined />
                  ) : (
                    <FullscreenOutlined />
                  )}
                </div>
              }
              onClick={() => onFullscreenClick()}
            />
          </Space>
        </div>
        {expanded ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 15,
              }}
            >
              <ZoneStatus
                zone={
                  zone as any //TODO: ensure autoScalerEnabled is added as an attribute
                }
                showLiveView={config.showRiderStats}
              />
            </div>
            <div
              style={{
                display: "flex",
                position: "relative",
                justifyContent: "center",
                borderBottom: `1px solid  ${borderColour}`,
              }}
            >
              <Menu
                mode="horizontal"
                style={{ lineHeight: "30px" }}
                onClick={(e) => {
                  const tabId = e.key as ZoneOverviewPanelTab;
                  if (onChangeActiveTab) {
                    onChangeActiveTab(tabId);
                  } else {
                    setActiveTab(tabId);
                  }
                }}
                selectedKeys={[activeTab]}
              >
                {visibleTabs.map((tab) => (
                  <Menu.Item key={tab.id}>{tab.displayName}</Menu.Item>
                ))}
              </Menu>
            </div>
          </>
        ) : null}
        <div
          style={{
            height: "100%",
            maxWidth: "100%",
            width: "100%",
            overflowY: "scroll",
            padding: 10,
          }}
        >
          <div style={{ width: "100%" }}>
            {visibleTabs.map((tab) => tab.tabContent)}
          </div>
        </div>
      </div>
    </ZoneContext.Provider>
  );
};

export default ZoneOverviewPanel;
