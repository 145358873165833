import { Divider } from "antd";
import React from "react";
import { $ } from "../../graphql/generated";
import { useTypedQuery } from "../../graphql/hooks";
import ReusablePickupTable from "../reusables/ReusablePickupTable";

export default function CustomerReusables({ id }: { id: string }) {
  const { data } = useTypedQuery({
    CustomerReusables: [
      {
        id: $`id`,
      },
      {
        id: true,
        containersClaimedReturned: true,
        containersNotReturned: true,
        containersScannedButNotScheduled: true,
        containersScheduled: true,
        containersValidatedReturned: true,
        maxContainersSchedulable: true,
        totalDeposits: true,
        activePickup: {
          id: true,
          type: true,
          pickupTimeEarliest: true,
          pickupTimeLatest: true,
        },
      },
    ],
  });

  return (
    <>
      <ReusablePickupTable customerId={id} />
      <Divider />
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </>
  );
}
