import { useMutation } from "@apollo/client";
import { Button } from "antd";
import React from "react";
import { Form, FormGroup, FormInput } from "shards-react";
import { CREATE_SUPER_ADMIN } from "../../graphql/mutations";
import { useFormData } from "../../hooks/useFormData";

export const CreateSuperAdmin = ({
  onSuccess = () => void 0,
}: {
  onSuccess?: () => void;
}) => {
  const [formData, onChange] = useFormData<any>({});
  const [createSuperAdmin, { loading }] = useMutation(CREATE_SUPER_ADMIN, {
    variables: {
      ...formData,
    },
    onCompleted: () => onSuccess(),
  });

  const submitForm = () => createSuperAdmin();

  return (
    <Form>
      <FormGroup>
        <label htmlFor="email">Name</label>
        <FormInput name="name" value={formData.name} onChange={onChange} />
      </FormGroup>

      <FormGroup>
        <label htmlFor="email">Email</label>
        <FormInput name="email" value={formData.email} onChange={onChange} />
      </FormGroup>

      <FormGroup>
        <label htmlFor="password">Password</label>
        <FormInput
          name="password"
          type="password"
          value={formData.password}
          onChange={onChange}
        />
      </FormGroup>

      <FormGroup>
        <Button type="primary" onClick={submitForm} loading={loading}>
          Create Super Admin
        </Button>
      </FormGroup>
    </Form>
  );
};
