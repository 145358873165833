import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";
import { Button, Col, message, Popconfirm, Row, Tooltip } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { $ } from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";
import { ORDER } from "../../graphql/queries";
import DynamicFormButton from "../forms/dynamic-form/DynamicFormButton";
import CancelOrderButton from "../layout/CancelOrderButton";
import RescheduleOrderForm from "../RescheduleOrderForm";

export default function OrderActions({
  order,
}: {
  order: {
    id: string;
    status: string;
    number: string;
    pickupBlocked: boolean;
    basket: {
      id: string;
    };
    rider?: {
      id: string;
    };
    restaurant: {
      id: string;
      name: string;
    };
  };
}) {
  const id = order?.id;

  const isComplete =
    order?.status === "CANCELLED" || order?.status === "COMPLETE";
  const isAssigned = !!order?.rider;

  const [delayPickup, { loading: delaying }] = useTypedMutation(
    {
      delayPickup: [
        {
          id: $`id`,
          restaurantId: $`restaurantId`,
        },
        {
          id: true,
        },
      ],
    },
    {
      variables: {
        id,
        restaurantId: order?.restaurant.id,
      },
      onCompleted: () => message.success("Delayed Order!"),
      onError: (e) => message.error(e.message),
    }
  );

  const [blockPickup, { loading: blocking }] = useTypedMutation(
    {
      blockPickup: [
        {
          id: $`id`,
        },
        {
          id: true,
          pickupBlocked: true,
        },
      ],
    } as any,
    {
      variables: {
        id,
      },
      onCompleted: (d: any) =>
        message.success(
          d.blockPickup.pickupBlocked
            ? "Order will no longer be considered for assignment"
            : "Order unblocked!"
        ),
      onError: (e) => message.error(e.message),
    }
  );

  const [markReady, { loading: markingReady }] = useTypedMutation(
    {
      completeOrderAsRestaurant: [
        {
          id: $`id`,
          restaurantId: $`restaurantId`,
        },
        true,
      ],
    },
    {
      onCompleted: () => message.success("Order marked as ready!"),
      onError: () => message.error("Failed to mark as ready."),
      variables: {
        id,
        restaurantId: order?.restaurant.id,
      },
    }
  );

  const [markComplete, { loading: markingComplete }] = useTypedMutation(
    {
      OrderCompleteAsAdmin: [
        {
          id: $`id`,
        },
        {
          ...ORDER.order[1],
        },
      ],
    },
    {
      onCompleted: () => message.success("Order completed!"),
      onError: () => message.error("Failed to mark as complete."),
      variables: {
        id,
      },
    }
  );

  const [advancePickup, { loading: advancing }] = useTypedMutation(
    {
      OrderBringForward: [
        {
          id: $`id`,
        },
        {
          ...ORDER.order[1],
        },
      ],
    },
    {
      onCompleted: () => message.success("Pickup time advanced."),
      onError: (e) =>
        message.error(e.graphQLErrors[0]?.message || "Failed to advance"),
      variables: {
        id,
      },
    }
  );

  const [removeOrderFromRider, { loading: removing }] = useTypedMutation(
    {
      OrderRemoveRiderAsAdmin: [
        {
          riderId: $`riderId`,
          orderId: $`orderId`,
        },
        {
          ...ORDER.order[1],
        },
      ],
    },
    {
      variables: {
        riderId: order?.rider?.id,
        orderId: order?.id,
      },
      onError: () => message.error("Failed to remove order from rider"),
      onCompleted: () => {
        message.success("Rider has been removed from order");
      },
    }
  );

  const [showReschedule, setShowReschedule] = useState(false);

  return (
    <>
      <Row gutter={16}>
        <Col>
          <CancelOrderButton
            id={order.id}
            number={order.number}
            restaurantId={order.restaurant.id}
            restaurantName={order.restaurant.name}
            status={order.status}
          />
        </Col>

        <Col>
          {" "}
          <Button
            disabled={!order?.rider}
            loading={removing}
            onClick={() => removeOrderFromRider()}
          >
            Remove Rider From Order
          </Button>
        </Col>
        <Col>
          {" "}
          <Button danger onClick={() => setShowReschedule(true)}>
            Redespatch Order
          </Button>
        </Col>

        <Col>
          <Tooltip title="Prevents this order from being assigned to any rider. Does not affect pickup time.">
            <Button
              onClick={() => blockPickup()}
              loading={blocking}
              disabled={isAssigned || isComplete}
              type={order?.pickupBlocked ? "primary" : "ghost"}
              icon={order?.pickupBlocked ? <ExclamationCircleOutlined /> : null}
            >
              {order?.pickupBlocked ? "Unblock Pickup" : "Block Pickup"}
            </Button>
          </Tooltip>
        </Col>

        <Col>
          <Popconfirm
            title="Are you sure you want to delay pickup of this order?"
            okText="Delay pickup"
            disabled={isComplete || isAssigned}
            onConfirm={() => delayPickup()}
          >
            <Button
              type="dashed"
              loading={delaying}
              disabled={isComplete || isAssigned}
              icon={<ClockCircleOutlined />}
            >
              Delay Pickup
            </Button>
          </Popconfirm>
        </Col>

        <Col>
          {" "}
          <Popconfirm
            title="Are you want to bring the pickup time forward?"
            okText="Yes, advance pickup time"
            cancelText="No"
            onConfirm={() => advancePickup()}
            disabled={isComplete}
          >
            <Button
              loading={advancing}
              disabled={isComplete}
              icon={<StepForwardOutlined />}
            >
              Advance Pickup
            </Button>
          </Popconfirm>
        </Col>

        {order?.status === "PREP" ? (
          <Col>
            <Popconfirm
              title="Are you sure this order is ready?"
              okText="Yes, the order is ready"
              cancelText="No"
              onConfirm={() => markReady()}
            >
              <Button loading={markingReady} icon={<CheckCircleOutlined />}>
                Mark Ready
              </Button>
            </Popconfirm>
          </Col>
        ) : null}
        {order?.status !== "COMPLETE" && order?.status !== "CANCELLED" ? (
          <Col>
            <Popconfirm
              title="Are you sure this order is complete?"
              okText="Yes, the order is complete"
              cancelText="No"
              onConfirm={() => markComplete()}
            >
              <Button loading={markingComplete} icon={<CheckCircleOutlined />}>
                Complete Order
              </Button>
            </Popconfirm>
          </Col>
        ) : null}
        <DynamicFormButton
          form={{
            id: "Order.Edit",
            entityId: id,
          }}
        >
          Edit
        </DynamicFormButton>
      </Row>
      <Modal
        title="Reschedule"
        visible={showReschedule}
        onCancel={() => setShowReschedule(false)}
        footer={null}
      >
        {order && (
          <RescheduleOrderForm
            id={order.id}
            basketId={order.basket.id}
            restaurantName={order.restaurant.name}
            onCompleted={() => setShowReschedule(false)}
          />
        )}
      </Modal>
    </>
  );
}
