import { PageHeader, Tabs } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import DynamicForm from "../components/forms/DynamicForm";
import MerchantSiteTable from "../components/merchant-sites/MerchantSiteTable";
import BrandList from "../components/partner/BrandList";
import ZonePartners from "../components/zone/ZonePartners";

export default function Partners() {
  const { tab } = useParams() as any;

  const history = useHistory();
  const match = useRouteMatch();
  const [key, setKey] = useState("partners");

  useEffect(() => {
    setKey(tab);
  }, [tab]);

  const onSelect = useCallback(
    (_tab) => {
      const path = generatePath(match.path, { tab: _tab });
      history.replace(path);
    },
    [history, match.path]
  );

  return (
    <Container fluid>
      <PageHeader title="Merchants" subTitle="All our Restaurants and Shops" />

      <Tabs activeKey={key} onChange={onSelect} id="platform-nav">
        <Tabs.TabPane key="partners" tab="Partners">
          <ZonePartners />
        </Tabs.TabPane>
        <Tabs.TabPane key="partners-list" tab="Partners (All)">
          <DynamicForm id="Merchant.List" />
        </Tabs.TabPane>
        <Tabs.TabPane key="brands" tab="Brands">
          <BrandList />
        </Tabs.TabPane>
        <Tabs.TabPane key="sites" tab="Sites">
          <MerchantSiteTable />
        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
}
