import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, List, message, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import Modal from "antd/lib/modal/Modal";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Marker, TileLayer } from "react-leaflet";
import { Address, $ } from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";
import {
  latLngToPostcode,
  postcodeIcon,
  postcodeToInfo,
  validatePostcode,
} from "../../utils/postcode";
import { RestaurantContext } from "../../views/Restaurant";
import Text from "antd/lib/typography/Text";
import Map from "../../utils/Map";
import { onError } from "../../utils/on-error";

interface Props {
  initialAddress: Address;
  onComplete: (address: Address) => void;
}

const UpdateAddressButton = (props: Props) => {
  const { initialAddress, onComplete } = props;

  const [showModal, setShowModal] = useState(false);

  const [form] = useForm();

  const [marker, setMarker] = useState<[number, number] | null>(
    initialAddress.coordinates?.coordinates
  );

  const [customMarkerPostcodes, setCustomMarkerPostcodes] = useState<
    string[] | undefined
  >(undefined);

  const [postcodeMarker, setPostcodeMarker] = useState<[number, number] | null>(
    null
  );

  // update the location of the postcode marker
  const onPostcodeUpdate = useCallback(
    async (postcode: string) => {
      const postcodeResult = (await postcodeToInfo(postcode))?.result;
      if (postcodeResult) {
        const latitude = postcodeResult.latitude;
        const longitude = postcodeResult.longitude;
        setPostcodeMarker([latitude, longitude]);
      }
    },
    [setPostcodeMarker]
  );

  useEffect(() => {
    if (!marker) {
      setCustomMarkerPostcodes(undefined);
    } else {
      latLngToPostcode(marker).then((val) => {
        setCustomMarkerPostcodes(val.result.map((result) => result.postcode));
      });
    }
  }, [marker]);

  const [updateAddress] = useTypedMutation(
    {
      updateAddress: [
        {
          id: $`id`,
          address: $`address`,
        },
        {
          id: true,
          nickname: true,
          flat_number: true,
          address_line_1: true,
          town: true,
          postcode: true,
          country: true,
          phone_number: true,
          instructions: true,
          coordinates: {
            type: true,
            coordinates: true,
          },
          imageKey: true,
        },
      ],
    },
    {
      onCompleted: (d) => {
        message.success("Updated address!");
        setShowModal(false);
        onComplete(d.updateAddress);
      },
      onError,
    }
  );

  const finish = async (v) => {
    await updateAddress({
      variables: {
        id: initialAddress.id,
        address: {
          ...v,
          coordinates: {
            type: "Point",
            coordinates: marker,
          },
        },
      },
    });
  };

  // update form to reflect initialAddress when initialAddress is updated and the modal is not visible
  useEffect(() => {
    if (initialAddress && !showModal) {
      form.setFieldsValue(initialAddress);
      onPostcodeUpdate(initialAddress.postcode);
    }
  }, [initialAddress, showModal, form, onPostcodeUpdate]);

  const [centreCoordinates, setCentreCoordinates] = useState<[number, number]>(
    initialAddress.coordinates?.coordinates
  );

  // update map to reflect initialAddress when initialAddress's coordinates are updated and the modal is not visible
  useEffect(() => {
    const newInitialAddrCoordinates = initialAddress?.coordinates?.coordinates;
    if (newInitialAddrCoordinates && !showModal) {
      setCentreCoordinates(newInitialAddrCoordinates);
    }
  }, [initialAddress?.coordinates?.coordinates, showModal]);

  useEffect(() => {
    console.log("centreCoordinates", centreCoordinates);
  }, [centreCoordinates]);

  return (
    <>
      <Button style={{ marginLeft: 8 }} onClick={() => setShowModal(true)}>
        Change Address
      </Button>
      <Modal
        id="modal-map"
        visible={showModal}
        title="Update Address"
        onCancel={() => setShowModal(false)}
        footer={
          <Button onClick={() => form.submit()} icon={<CheckCircleOutlined />}>
            Save
          </Button>
        }
      >
        <Form
          form={form}
          onFinish={finish}
          onValuesChange={async (values) => {
            onPostcodeUpdate(values.postcode);
          }}
        >
          <Form.Item label="Flat Number" name="flat_number">
            <Input />
          </Form.Item>
          <Form.Item
            label="Line 1"
            rules={[{ required: true }]}
            name="address_line_1"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Postcode"
            rules={[
              { required: true },
              {
                validator: async (_, value) => {
                  if (await (await validatePostcode(value)).result) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Please enter a valid postcode");
                  }
                },
              },
            ]}
            name="postcode"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            rules={[{ pattern: /^\+[1-9]{1}[0-9]{3,14}$/g }]}
            name="phone_number"
          >
            <Input />
          </Form.Item>

          <Map
            center={centreCoordinates}
            zoom={12}
            style={{
              height: "300px",
            }}
            onclick={(e) => setMarker([e.latlng.lat, e.latlng.lng])}
          >
            {marker ? <Marker position={marker} /> : null}
            {postcodeMarker ? (
              <Marker
                icon={postcodeIcon}
                tooltip={`Location of postcode ${form.getFieldValue(
                  "postcode"
                )}`}
                position={postcodeMarker}
              />
            ) : null}
          </Map>
          <Text>Nearby Postcodes:</Text>
          <List
            dataSource={customMarkerPostcodes}
            renderItem={(postcode) => (
              <Space>
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      postcode,
                    });
                    onPostcodeUpdate(postcode);
                  }}
                >
                  Update
                </Button>
                <List.Item>{postcode}</List.Item>
              </Space>
            )}
          />
        </Form>
      </Modal>
    </>
  );
};

export function PickCoordinatesButton({
  onComplete,
}: {
  onComplete: (coords: [number, number] | null) => void;
}) {
  const { data } = useContext(RestaurantContext);

  const [showModal, setShowModal] = useState(false);

  const [marker, setMarker] = useState<[number, number] | null>(
    data?.Restaurant.address.coordinates?.coordinates
  );

  const finish = useCallback(() => onComplete(marker), [marker]);

  const [form] = useForm();

  return (
    <>
      <Button style={{ marginLeft: 8 }} onClick={() => setShowModal(true)}>
        Change Address
      </Button>
      <Modal
        visible={showModal}
        title="Change Pin"
        onCancel={() => setShowModal(false)}
        footer={
          <Button onClick={() => form.submit()} icon={<CheckCircleOutlined />}>
            Change Pin
          </Button>
        }
      >
        <Form form={form} onFinish={finish}>
          <Map
            id="modal-map"
            center={[56.333874, -2.8059686]}
            zoom={12}
            style={{
              height: "300px",
            }}
            onClick={(e) => setMarker([e.latlng.lat, e.latlng.lng])}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://maps.ecoeats.uk/tile/{z}/{x}/{y}.png"
            />
            {marker ? <Marker position={marker} /> : null}
          </Map>
        </Form>
      </Modal>
    </>
  );
}

export default UpdateAddressButton;
