import Cookies from "js-cookie";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useStateValue } from "../state/StateProvider";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const [{ login }, dispatch] = useStateValue();

  const savedLogin = Cookies.get("login");

  if (!login.isAuthenticated && savedLogin) {
    dispatch({
      type: "login",
      isAuthenticated: true,
      accessToken: savedLogin,
      expiresIn: 3600,
    });
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        login.isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/login?from=${props.location.pathname}`} />
        )
      }
    />
  );
};
