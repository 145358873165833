import { EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Badge, Button, Input, Space, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React, { useRef, useState } from "react";
import L, { Marker, Popup } from "react-leaflet";
import { Link } from "react-router-dom";
import { $ } from "../../graphql/generated";
import { query, useTypedQuery } from "../../graphql/hooks";
import usePagination from "../../hooks/usePagination";
import Map from "../../utils/Map";
import { formatAddress } from "../forms/AddAddressForm";
import DynamicForm from "../forms/DynamicForm";

const SITE_TABLE = query({
  WashingLocationList: [
    {
      skip: $`skip`,
      take: $`take`,
      sortBy: $`sortBy`,
      order: $`order`,
      query: $`query`,
    },
    {
      total: true,
      items: {
        id: true,
        name: true,
        notes: true,
        status: true,
        address: {
          id: true,
          address_line_1: true,
          postcode: true,
          flat_number: true,
          instructions: true,
          coordinates: {
            type: true,
            coordinates: true,
          },
        },
      },
    },
  ],
});

export default function WashingLocationTable({ zoneId }: { zoneId?: string }) {
  const {
    pagination,
    args: { order, page, query, sortBy, take },
    onChange,
    setQuery,
  } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "name",
  });

  const { data, refetch } = useTypedQuery(SITE_TABLE, {
    fetchPolicy: "network-only",
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
    },
  });

  const [show, setShow] = useState(false);

  const [selected, setSelected] = useState<string | undefined>();
  const mapRef = useRef<L.Map>();

  return (
    <>
      <Map
        zoom={12}
        center={
          data?.WashingLocationList.items[0]?.address.coordinates
            ?.coordinates || [0, 0]
        }
        ref={(ref) => (mapRef.current = ref)}
      >
        {data?.WashingLocationList.items.map((i) => (
          <Marker position={i.address.coordinates!.coordinates}>
            <Popup>
              <Badge
                status={i.status === "READY" ? "success" : "error"}
                text={
                  <Title level={5} style={{ display: "inline" }}>
                    {i.name}
                  </Title>
                }
              />
              <Text>{formatAddress(i.address)}</Text>
              <br />
              <Text type="secondary">{i.address.instructions}</Text>
            </Popup>
          </Marker>
        ))}
      </Map>
      <Table
        title={() => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Title level={4} style={{ flex: 1 }}>
              {data?.WashingLocationList.total} Locations
            </Title>

            <Space>
              <Input
                placeholder="Search..."
                suffix={<SearchOutlined />}
                onChange={(t) => setQuery(t.target.value)}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setShow(true)}
              >
                New Location
              </Button>
            </Space>
          </div>
        )}
        onChange={(p, f, s) => {
          onChange(p, f, s);
        }}
        dataSource={data?.WashingLocationList.items}
        pagination={{
          total: data?.WashingLocationList.total,
          ...pagination,
        }}
        columns={[
          {
            title: "Name",
            sorter: true,
            render: (_, r) => (
              <Link to={`/reusables/washing-location/${r.id}`}>{r.name}</Link>
            ),
          },
          {
            title: "Status",
            sorter: true,
            dataIndex: "status",
            render: (r) => (
              <Badge status={r === "READY" ? "success" : "error"} text={r} />
            ),
          },

          {
            render: (_, r) => (
              <Space>
                <Button
                  type="text"
                  icon={<SearchOutlined />}
                  onClick={() =>
                    mapRef.current?.leafletElement.flyTo(
                      {
                        lat: r.address.coordinates?.coordinates[0],
                        lng: r.address.coordinates?.coordinates[1],
                      },
                      15
                    )
                  }
                ></Button>
                <Button
                  type="text"
                  icon={<EditOutlined />}
                  onClick={() => setSelected(r.id)}
                />
              </Space>
            ),
          },
        ]}
      />
      <Modal
        footer={null}
        visible={show || !!selected}
        destroyOnClose
        onCancel={() => {
          setShow(false);
          setSelected(undefined);
        }}
      >
        <DynamicForm
          onComplete={() => {
            setShow(false);
            setSelected(undefined);
            refetch();
          }}
          noCard
          entityId={selected}
          id="WashingLocation"
        />
      </Modal>
    </>
  );
}
