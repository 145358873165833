import { PageHeader, Tabs } from "antd";
import React from "react";
import useTabSync from "../../hooks/useTabSync";
import DynamicForm from "../forms/DynamicForm";
import ReusableAggregatePickupTable from "./ReusableAggregatePickupTable";
import ReusableContainerTable from "./ReusableContainerTable";
import ReusableContainerTypeTable from "./ReusableContainerTypeTable";
import ReusableDropBoxTable from "./ReusableDropBoxTable";
import ReusablePickupTable from "./ReusablePickupTable";
import ReusableShipmentTable from "./ReusableShipmentTable";
import ScannedContainerTable from "./ScannedContainerTable";
import WashingLocationTable from "./WashingLocations";

export default function Reusables({ zoneId }: { zoneId?: string }) {
  const { key, onSelect } = useTabSync("pickups", {}, "subtab");

  return (
    <>
      <Tabs activeKey={key} onChange={onSelect}>
        <Tabs.TabPane tab="Pickups" key="pickups">
          <ReusablePickupTable zoneId={zoneId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Pickup Aggregates" key="pickup-aggregate">
          <ReusableAggregatePickupTable />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Containers" key="containers">
          <ReusableContainerTypeTable />
          <ReusableContainerTable />
          <ScannedContainerTable />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Washing Locations" key="washing-locations">
          <WashingLocationTable zoneId={zoneId} />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Shipments" key="shipments">
          <ReusableShipmentTable />
        </Tabs.TabPane>
        <Tabs.TabPane tab="DropBoxes" key="dropboxes">
          <ReusableDropBoxTable zoneId={zoneId} />
        </Tabs.TabPane>
        {zoneId && (
          <Tabs.TabPane tab="Settings" key="settings">
            <DynamicForm id="ReusablesSettingsInput" entityId={zoneId} />
          </Tabs.TabPane>
        )}
      </Tabs>
    </>
  );
}

export function ReusablesView() {
  return (
    <PageHeader title="🌳 Reusables">
      <Reusables />
    </PageHeader>
  );
}
