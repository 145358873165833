import { SearchOutlined } from "@ant-design/icons";
import { Input, Popover, Space, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import Title from "antd/lib/typography/Title";
import { formatDistance } from "date-fns";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { $ } from "../../graphql/generated";
import { query, useTypedQuery } from "../../graphql/hooks";
import usePagination from "../../hooks/usePagination";
import { getImageUrl } from "../../utils/image-url";
import DynamicForm from "../forms/DynamicForm";

const SITE_TABLE = query({
  ScannedContainerList: [
    {
      skip: $`skip`,
      take: $`take`,
      sortBy: $`sortBy`,
      order: $`order`,
      query: $`query`,
      washingLocationId: $`washingLocationId`,
      containerId: $`containerId`,
      customerId: $`customerId`,
      shipmentId: $`shipmentId`,
      pickupId: $`pickupId`,
    },
    {
      total: true,
      items: {
        id: true,
        scannedAt: true,
        imageKey: true,
        washingLocation: {
          id: true,
          name: true,
        },
        container: {
          id: true,
          type: {
            id: true,
            name: true,
          },
        },
      },
    },
  ],
});

export default function ScannedContainerTable({
  washingLocationId,
  containerId,
  customerId,
  pickupId,
  shipmentId,
}: {
  washingLocationId?: string;
  customerId?: string;
  shipmentId?: string;
  pickupId?: string;
  containerId?: string;
}) {
  const {
    pagination,
    args: { order, page, query, sortBy, take },
    onChange,
    setQuery,
  } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "scannedAt",
  });

  const { data, refetch } = useTypedQuery(SITE_TABLE, {
    fetchPolicy: "network-only",
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
      washingLocationId,
      containerId,
      customerId,
      pickupId,
      shipmentId,
    },
  });

  const [show, setShow] = useState(false);

  return (
    <>
      <Table
        title={() => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Title level={4} style={{ flex: 1 }}>
              {data?.ScannedContainerList.total} Scanned Containers
            </Title>

            <Space>
              <Input
                placeholder="Search..."
                suffix={<SearchOutlined />}
                onChange={(t) => setQuery(t.target.value)}
              />
            </Space>
          </div>
        )}
        onChange={(p, f, s) => {
          onChange(p, f, s);
        }}
        expandable={{
          rowExpandable: () => true,
          defaultExpandAllRows: false,
          expandedRowRender: (r) => <pre>{JSON.stringify(r, null, 2)}</pre>,
        }}
        dataSource={data?.ScannedContainerList.items}
        pagination={{
          total: data?.ScannedContainerList.total,
          ...pagination,
        }}
        rowKey={"id"}
        columns={[
          {
            title: "Scan",
            sorter: true,
            dataIndex: "id",
          },
          {
            title: "Container",
            sorter: true,
            dataIndex: "containerId",
            render: (_, r) => (
              <Link to={`/reusables/container/${r.container.id}`}>
                {r.container.id}
              </Link>
            ),
          },
          {
            title: "Scanned",
            key: "scanned",
            sorter: true,
            render: (_, r) =>
              formatDistance(new Date(r.scannedAt), new Date(), {
                addSuffix: true,
                includeSeconds: true,
              }),
          },

          {
            title: "Washer",
            key: "washer",
            render: (_, r) =>
              r.washingLocation ? (
                <Link
                  to={`/reusables/washing-location/${r.washingLocation?.id}`}
                >
                  {r.washingLocation?.name}
                </Link>
              ) : null,
          },
          {
            render: (_, r) =>
              r.imageKey ? (
                <Popover
                  placement="left"
                  content={
                    <img
                      alt="helpful"
                      src={getImageUrl(r.imageKey)}
                      style={{ maxWidth: 512 }}
                    />
                  }
                >
                  <img
                    alt="helpful"
                    src={getImageUrl(r.imageKey, 256, 256)}
                    style={{
                      width: 96,
                      height: 96,
                    }}
                  />
                </Popover>
              ) : null,
          },
        ]}
      />
      <Modal
        footer={null}
        visible={show}
        destroyOnClose
        onCancel={() => setShow(false)}
      >
        <DynamicForm
          onComplete={() => {
            refetch();
            setShow(false);
          }}
          noCard
          id="ReusableShipmentCreate"
        />
      </Modal>
    </>
  );
}
