import {
  Alert,
  Button,
  Descriptions,
  Divider,
  Drawer,
  message,
  PageHeader,
} from "antd";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import { MapType, Query, RestaurantUpdateInput } from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";
import { UPDATE_PARTNER } from "../../graphql/mutations";
import { RESTAURANT } from "../../graphql/queries";
import { getImageUrl } from "../../utils/image-url";
import { useUpdateItemKey } from "../../utils/update-item-key";
import BrandList from "./BrandList";
type BrandType = MapType<Query, typeof RESTAURANT>["Restaurant"]["brand"];

export default function PartnerBrand({
  partnerId,
  brand: plan,
}: {
  partnerId: string;
  brand: BrandType;
}) {
  const [updateRestaurant, { loading }] = useTypedMutation(UPDATE_PARTNER, {
    onCompleted: () => {
      message.success("Assigned new plan!");
    },
  });
  const updateItemKey = useUpdateItemKey<RestaurantUpdateInput>(
    updateRestaurant,
    "restaurant",
    partnerId
  );

  const [showSwitcher, setShowSwitcher] = useState(false);

  return (
    <>
      <PageHeader
        title={
          plan ? (
            <>
              <Title level={5}>{plan.name} </Title>
            </>
          ) : (
            <Alert type={"info"} message={"No Brand Associated"}></Alert>
          )
        }
        avatar={{
          src: plan ? getImageUrl(plan?.logo.key, 256) : undefined,
          size: 64,
          shape: "square",
        }}
        extra={[
          <Button type="primary" onClick={() => setShowSwitcher(true)}>
            Change Brand
          </Button>,
          plan && (
            <Button
              type="primary"
              danger
              onClick={() => updateItemKey("brandId", null!)}
            >
              Remove Brand
            </Button>
          ),
        ]}
      >
        {plan && (
          <Descriptions bordered style={{ marginBottom: 16 }} column={1}>
            <Descriptions.Item label="Description" span={2}>
              {plan.description}
            </Descriptions.Item>
          </Descriptions>
        )}
      </PageHeader>
      <Drawer
        width={768}
        visible={showSwitcher}
        onClose={() => setShowSwitcher(false)}
      >
        <BrandList
          onSelect={(r) => {
            updateItemKey("brandId", r.id);
            setShowSwitcher(false);
          }}
        />

        <Divider />
      </Drawer>
    </>
  );
}
