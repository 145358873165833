import { CheckCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { Button, Modal, Table, Tag } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useMemo, useState } from "react";
import InfoTag from "../tags/InfoTag";
import WarningTag from "../tags/WarningTag";

interface Props {
  visible: boolean;
  cities?: {
    id: string;
    name: string;
    zones: {
      id: string;
      name: string;
    }[];
  }[];
  initialSelectedZoneIds: string[];
  maxZones?: number;
  minZones?: number;
  onCancel: () => void;
  onSubmit: (selectedZoneIds: string[]) => void;
}

const SelectZonesModal = (props: Props) => {
  const {
    visible,
    cities,
    initialSelectedZoneIds,
    maxZones,
    minZones,
    onCancel,
    onSubmit,
  } = props;

  const initialNZones = initialSelectedZoneIds.length;
  const initialAboveMax = maxZones && initialNZones > maxZones;
  const initialBelowMin = minZones && initialNZones < minZones;
  const initialValid = !(initialBelowMin || initialAboveMax);

  const [selectedZoneIds, setSelectedZoneIds] = useState<string[]>(
    initialSelectedZoneIds
  );

  // cities with selected field added to the zone based on selectedZoneIds
  const augmentedCities = useMemo(() => {
    return cities?.map((city) => ({
      ...city,
      zones: city.zones.map((zone) => ({
        ...zone,
        selected: selectedZoneIds.includes(zone.id),
      })),
    }));
  }, [selectedZoneIds, cities]);

  const removeSelectedZoneId = (zoneId: string) => {
    setSelectedZoneIds((selectedZoneIds) => {
      return selectedZoneIds.filter(
        (selectedZoneId) => selectedZoneId !== zoneId
      );
    });
  };

  const addSelectedZoneId = (zoneId: string) => {
    setSelectedZoneIds((selectedZoneIds) => [...selectedZoneIds, zoneId]);
  };

  const nSelectedZones = selectedZoneIds.length;
  const aboveMax = maxZones && nSelectedZones > maxZones;
  const belowMin = minZones && nSelectedZones < minZones;
  const valid = !(aboveMax || belowMin);

  const MaxMinMessage = () => {
    if (aboveMax) {
      return (
        <WarningTag>
          {`You are above the maximum of ${maxZones} zones.`}
        </WarningTag>
      );
    } else if (belowMin) {
      return (
        <WarningTag>
          {`You are below the minimum of ${minZones} zone${
            minZones !== 1 ? "s" : ""
          }.`}
        </WarningTag>
      );
    } else if (maxZones && minZones) {
      return (
        <InfoTag>{`You can select between ${minZones} and ${maxZones} zones (inclusive).`}</InfoTag>
      );
    } else if (maxZones) {
      return (
        <InfoTag>{`You can select no more than ${maxZones} zones.`}</InfoTag>
      );
    } else if (minZones) {
      return (
        <InfoTag>{`You can select no less than ${minZones} zones.`}</InfoTag>
      );
    } else {
      return null;
    }
  };

  return (
    <Modal
      width="50vw"
      visible={visible}
      closable={initialValid}
      onCancel={() => {
        if (initialValid) onCancel();
      }}
      footer={[
        <Button
          key="cancel"
          disabled={!initialValid}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="ok"
          disabled={!valid}
          onClick={() => {
            onSubmit(selectedZoneIds);
          }}
        >
          Ok
        </Button>,
      ]}
    >
      <Title level={4}>Select Zones to Monitor</Title>
      {MaxMinMessage()}
      <Table
        style={{ marginTop: 10 }}
        dataSource={augmentedCities}
        rowKey="id"
        loading={!cities}
        columns={[
          {
            title: "City Name",
            key: "name",
            dataIndex: "name",
          },
          {
            title: "Zones",
            key: "zones",
            render: (city) => {
              return (
                <Table
                  className="city-zone-table"
                  dataSource={city.zones}
                  rowKey="id"
                  onRow={(zone) => ({
                    style: {
                      cursor: "pointer",
                    },
                    onClick: () => {
                      if (zone.selected) {
                        removeSelectedZoneId(zone.id);
                      } else {
                        addSelectedZoneId(zone.id);
                      }
                    },
                  })}
                  columns={[
                    {
                      title: "Zone Name",
                      key: "name",
                      dataIndex: "name",
                    },
                    {
                      key: "selected",
                      width: 30,
                      render: (zone) => {
                        const iconSize = 18;
                        const zoneSelected = zone.selected;

                        return (
                          <Tag
                            color={zoneSelected ? "green" : "default"}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: 5,
                              maxWidth: iconSize + 5,
                              borderRadius: "20%",
                            }}
                          >
                            {zoneSelected ? (
                              <CheckCircleFilled size={iconSize} />
                            ) : (
                              <PlusCircleFilled size={iconSize} />
                            )}
                          </Tag>
                        );
                      },
                    },
                  ]}
                  pagination={false}
                />
              );
            },
          },
        ]}
      />
    </Modal>
  );
};

export default SelectZonesModal;
