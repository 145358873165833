import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Modal,
  Row,
  Skeleton,
  Space,
} from "antd";
import Title from "antd/lib/typography/Title";
import React, { useContext, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useTypedQuery } from "../../graphql/hooks";
import { BASKET } from "../../graphql/queries";
import { price } from "../../utils/price";
import { OrderContext } from "../../views/Order";
import AdjustOrderForm from "../forms/AdjustOrderForm";
import DynamicFormButton from "../forms/dynamic-form/DynamicFormButton";
import { BasketItem } from "./BasketItem";

function PriceRow({ title, value }: { title: string; value: number }) {
  return (
    <Row>
      <span style={{ flex: 1 }}>{title}</span>
      <span>{price(value)}</span>
    </Row>
  );
}

export default function OrderBasket() {
  const orderData = useContext(OrderContext);
  const order = orderData.data?.order;
  const [showAdjust, setShowAdjust] = useState(false);
  const [showDebug, setShowDebug] = useState(false);

  const { data, loading } = useTypedQuery(BASKET, {
    skip: !order?.basket.id,
    variables: {
      id: order?.basket.id,
    },
  });

  if (!order) return <></>;

  return (
    <>
      <Card
        title={`${order?.basket.numberOfItems} Items | ${(
          order?.basket.weight / 1000
        ).toFixed(2)}kg`}
        style={{ marginBottom: "16px" }}
      >
        {order?.basket.alcohol && (
          <Alert
            type="warning"
            message="This order contains age-restricted items"
          />
        )}
        {order?.allergyNotes ? <p>{order.allergyNotes}</p> : null}
        {data ? (
          data?.Basket.items.map((i) => <BasketItem i={i} key={i.id} />)
        ) : (
          <Skeleton active />
        )}

        <Col flex={1}>
          <Row style={{ marginTop: 16 }}>
            <span style={{ flex: 1 }}>Subtotal</span>
            <span>£{(order?.basket.subtotal / 100).toFixed(2)}</span>
          </Row>
          <Row style={{ marginTop: 16 }}>
            <span style={{ flex: 1 }}>Rider Tip</span>
            <span>£{(order?.basket.riderTip / 100).toFixed(2)}</span>
          </Row>
          {data?.Basket.donationTotal ? (
            <Row>
              <span style={{ flex: 1 }}>Donation Total</span>
              <span>£{(data.Basket.donationTotal / 100).toFixed(2)}</span>
            </Row>
          ) : null}
          {order?.basket.smallOrderFee ? (
            <Row>
              <span style={{ flex: 1 }}>Small Order Fee</span>
              <span>£{(order?.basket.smallOrderFee / 100).toFixed(2)}</span>
            </Row>
          ) : null}
          <Row>
            <span style={{ flex: 1 }}>Delivery Fee</span>
            <span>£{(order?.basket.deliveryFee / 100).toFixed(2)}</span>
          </Row>
          {order?.basket.totalPriceBeforeDiscount > order?.basket.totalPrice ? (
            <Row>
              <span style={{ flex: 1 }}>Discounts</span>
              <span>
                -£
                {(
                  (order?.basket.totalPriceBeforeDiscount -
                    order?.basket.totalPrice) /
                  100
                ).toFixed(2)}
              </span>
            </Row>
          ) : null}

          <Row>
            <span style={{ flex: 1 }}>Customer Paid</span>
            <span>£{(order?.basket.totalPrice / 100).toFixed(2)}</span>
          </Row>

          {data?.Basket.reusablesDeposit ? (
            <PriceRow
              title="Reusables Deposit"
              value={data?.Basket.reusablesDeposit}
            />
          ) : null}
          {data?.Basket.reusablesFee ? (
            <PriceRow title="Reusables Fee" value={data?.Basket.reusablesFee} />
          ) : null}

          {order?.amountRefunded ? (
            <Row style={{ marginTop: 16 }}>
              <span style={{ flex: 1 }}>Refund</span>
              <span
                style={{
                  color: "red",
                }}
              >
                -£{(order?.amountRefunded / 100).toFixed(2)}
              </span>
            </Row>
          ) : null}

          {order?.stripeFee ? (
            <Row style={{ marginTop: 16 }}>
              <span style={{ flex: 1 }}>Stripe Fee</span>
              <span
                style={{
                  color: "red",
                }}
              >
                -£{(order?.stripeFee / 100).toFixed(2)}
              </span>
            </Row>
          ) : null}

          {order?.baseRiderEarnings && (
            <PriceRow
              title="Base Rider Earnings"
              value={order.baseRiderEarnings}
            />
          )}
          {order?.riderEarnings && (
            <PriceRow title="Rider Earnings" value={order.riderEarnings} />
          )}

          <Row>
            <span style={{ flex: 1 }}>Can Tip Rider?</span>
            <span>
              {order?.canTipRider
                ? "Yes (Uncaptured)"
                : order?.status !== "COMPLETE"
                ? "No (Order In Progress)"
                : "No (Captured)"}
            </span>
          </Row>

          <Row>
            <span style={{ flex: 1 }}>Stripe Payment</span>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={`https://dashboard.stripe.com/payments/${order?.paymentIntentId}`}
            >
              {order?.basket.paymentIntentId}
            </a>
          </Row>

          <Divider />

          <Title level={5}>Actions</Title>
          <Space>
            <DynamicFormButton
              form={{
                id: "Order.Refund",
                entityId: order?.id,
              }}
              button={{
                danger: true,
              }}
            >
              Refund
            </DynamicFormButton>
            {order?.reusables && (
              <DynamicFormButton
                form={{
                  id: "Order.Reusables",
                  entityId: order?.id,
                }}
              >
                Refund Reusables
              </DynamicFormButton>
            )}
            <Button onClick={() => setShowAdjust(true)}>
              Adjust Financials
            </Button>
            <Button onClick={() => setShowDebug(true)}>Debug</Button>
          </Space>
        </Col>
      </Card>
      <Modal
        title="Debug"
        visible={showDebug}
        footer={null}
        onCancel={() => setShowDebug(false)}
      >
        <SyntaxHighlighter language="json" style={docco}>
          {JSON.stringify(order, null, 2)}
        </SyntaxHighlighter>
      </Modal>
      <Modal
        title="Adjust Order"
        visible={showAdjust}
        onCancel={() => setShowAdjust(false)}
        footer={null}
      >
        <AdjustOrderForm onCompleted={() => setShowAdjust(false)} />
      </Modal>
    </>
  );
}
