import { Badge, Tooltip } from "antd";
import React from "react";
import {
  FulfillmentMethod,
  OrderCancellationReason,
  OrderStatus,
} from "../graphql/generated";

export const locale: { [K in OrderStatus]: string } = {
  AWAITING_CUSTOMER: "At Customer",
  AWAITING_RESTAURANT: "Pending",
  AWAITING_RIDER: "Ready",
  CANCELLED: "Cancelled",
  COMPLETE: "Complete",
  EN_ROUTE: "En Route",
  PREP: "Prep",
};

export const OrderBadge = ({
  status,
  type,
  tableNumber,
  blocked,
  cancellationReason,
  isRiderAtRestaurant,
}: {
  status: string;
  type?: string;
  tableNumber?: string;
  blocked?: boolean;
  isRiderAtRestaurant?: boolean;
  cancellationReason?: OrderCancellationReason;
}) => (
  <>
    <Tooltip
      title={cancellationReason ? `Reason: ${cancellationReason}` : undefined}
    >
      <Badge
        status={
          status === "COMPLETE"
            ? "success"
            : status === "CANCELLED"
            ? "default"
            : status === "EN_ROUTE"
            ? "warning"
            : status === "AWAITING_RESTAURANT"
            ? "processing"
            : "default"
        }
        color={
          status === "AWAITING_RESTAURANT"
            ? "geekblue"
            : status === "PREP"
            ? "blue"
            : status === "AWAITING_CUSTOMER"
            ? "cyan"
            : status === "AWAITING_RIDER"
            ? isRiderAtRestaurant
              ? "pink"
              : "red"
            : undefined
        }
        text={
          status === "AWAITING_RIDER" && isRiderAtRestaurant
            ? "Pickup"
            : locale[status]
        }
      />
    </Tooltip>
    {type ? (
      <span
        className={`badge badge-${type === "DELIVERY" ? "success" : "light"}`}
        style={{ marginLeft: "8px", cursor: "pointer", fontSize: 16 }}
      >
        {type
          .toLowerCase()
          .split("_")
          .map((c) => c.charAt(0).toUpperCase() + c.slice(1))
          .join(" ")}
      </span>
    ) : null}
    {type === FulfillmentMethod.TABLE_SERVICE && (
      <span
        className={`badge badge-dark`}
        style={{ marginLeft: "8px", cursor: "pointer", fontSize: 16 }}
      >
        Table {tableNumber}
      </span>
    )}
    {blocked ? (
      <span
        className={`badge badge-danger`}
        style={{ marginLeft: "8px", cursor: "pointer", fontSize: 16 }}
      >
        Pickup Blocked
      </span>
    ) : null}
  </>
);
