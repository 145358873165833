import React, { useState } from "react";
import { style } from "typestyle";
import { getImageUrl } from "../../../utils/image-url";
import { searchStyles } from "./styles";

const PartnerResult = ({
  name,
  ratingStars,
  ratingCount,
  tags,
  onClick,
  image,
  index,
}: {
  name: string;
  ratingStars: number;
  ratingCount: number;
  tags: any;
  onClick?: any;
  image?: string;
  index: number;
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className={searchStyles.Result} onClick={onClick} tabIndex={index}>
      <img
        className={styles.SquareImage}
        src={image ? getImageUrl(image, 256, 256) : "/background.png"}
        alt={name}
        onLoad={() => setImageLoaded(true)}
        style={{
          opacity: imageLoaded ? 1 : 0,
          transition: "opacity ease-in-out 0.3s",
        }}
      />

      <div>
        <b>{name}</b>
        <div>
          <p style={{ display: "inline" }}>
            {tags.map((tag) => {
              return <span className={styles.Tag}>• {tag.name}</span>;
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  Tag: style({
    display: "inline",
    marginLeft: "5px",
    color: "#ccc",
  }),
  SquareImage: style({
    height: "70px",
    width: "70px",
    margin: 0,
    padding: 0,
    marginRight: "10px",
    borderRadius: 4,
  }),
};
export default PartnerResult;
