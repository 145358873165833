import { Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { copyTextToClipboard } from "../../utils/copy";

export function CopyText({ text: c }) {
  const [copied, setCopied] = useState(false);
  const inter = useRef(-1);
  useEffect(() => {
    if (copied) {
      inter.current = setTimeout(() => setCopied(false), 2000) as any;
    }

    return () => clearInterval(inter.current);
  }, [copied]);
  return (
    <Tooltip title={copied ? "Copied!" : "Copy ID"}>
      <a
        href="#"
        onClick={() => {
          copyTextToClipboard(c);
          setCopied(true);
        }}
        style={{ fontFamily: "monospace" }}
      >
        {c?.slice(0, 8)}...
      </a>
    </Tooltip>
  );
}
