import { useMutation, useQuery } from "@apollo/client";
import { message } from "antd";
import gql from "graphql-tag";
import React, { useCallback, useState } from "react";
import { Alert, Button, ButtonGroup, Modal } from "react-bootstrap";
import QR from "react-qr-code";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { Form, FormInput } from "shards-react";
import { media, style } from "typestyle";
import food from "../assets/admin.svg";
import logo from "../assets/logo.png";
import { LoadingButton } from "../components/LoadingButton";
import { Zeus } from "../graphql/generated/graphql-zeus";
import { useTypedMutation } from "../graphql/hooks";
import { LOGIN, RESET_PASSWORD } from "../graphql/mutations";
import { useStateValue } from "../state/StateProvider";
const Login = () => {
  const [{ api }, dispatch] = useStateValue();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [twoFactorCode, set2Fa] = useState("");

  const history = useHistory();
  const [forgotPassword, setForgotPassword] = useState(false);
  const params = new URLSearchParams(useLocation().search);

  const { loading: accountLoading } = useQuery(
    gql(
      Zeus.query({
        me: {
          "...on RestaurantAdmin": {
            name: true,
            roles: true,
            email: true,
          },
        } as any,
      })
    ),
    {
      onCompleted(data) {
        if (data) {
          const user = data.me;

          if (
            !user.roles ||
            (!user.roles.includes("CREATE_RESTAURANT") &&
              !user.roles.includes("CREATE_SUPER_ADMINS"))
          ) {
            return;
          }

          dispatch({ type: "login", isAuthenticated: true, user });
          if (params.get("from")) history.push(params.get("from")!);
          else history.push("/");
        }
      },
    }
  );

  const [loginMutation, { loading, error: loginError }] = useTypedMutation(
    LOGIN,
    {
      onCompleted(data) {
        if (data) {
          const { user } = data.login;

          if (!user.roles.includes("UPDATE_OWN_RESTAURANT")) {
            return;
          }

          dispatch({ type: "login", isAuthenticated: true, ...data.login });
          if (params.get("from")) history.push(params.get("from")!);
          else history.push("/");
        }
      },
    }
  );

  const [resetMutation, { loading: resetting }] = useMutation(RESET_PASSWORD, {
    onCompleted() {
      setForgotPassword(false);
      message.success("Sent password reset email!");
    },
  });

  const setApi = (env) => {
    dispatch({
      type: "setApiUrl",
      env,
    });
  };

  const doReset = async (e) => {
    e.preventDefault();
    await resetMutation({
      variables: {
        email,
        type: "restaurant",
      },
    });
  };

  const doLogin = useCallback(
    async (e) => {
      e.preventDefault();
      await loginMutation({
        variables: {
          email,
          password,
          twoFactorCode,
        },
      });
    },
    [loginMutation, email, password, twoFactorCode]
  );

  const [show2fa, setShow2fa] = useState(false);

  const [setup2fa, { error, data }] = useTypedMutation(
    {
      setupTwoFactorAuthentication: [
        {
          login: {
            email,
            password,
            userType: "restaurant",
          },
        },
        {
          uri: true,
        },
      ],
    },
    {
      onCompleted: () => setShow2fa(true),
    }
  );

  const [snack] = useSnackbar();

  const [
    complete2fa,
    { loading: completeLoading, error: completeError },
  ] = useTypedMutation(
    {
      confirmTwoFactorAuthentication: [
        {
          login: {
            email,
            password,
            userType: "restaurant",
          },
          token: twoFactorCode,
        },
        true,
      ],
    },
    {
      onCompleted: () => {
        snack("Thanks for enabling 2fa");
        setShow2fa(false);
      },
    }
  );

  return (
    <div className={styles.background}>
      <div className={styles.food}>
        <img style={{ maxWidth: "100%" }} src={food} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 2,
          justifyContent: "center",
        }}
      >
        <div style={{ marginBottom: 8 }}>
          <img src={logo} style={{ maxHeight: "48px" }} />
        </div>
        <Form>
          {window.location.origin.includes("local-dev") ? (
            <ButtonGroup aria-label="API Server" style={{ marginBottom: 16 }}>
              {["production", "local"].map((e) => (
                <Button
                  onClick={() => setApi(e)}
                  variant={api.env === e ? "primary" : "secondary"}
                >
                  {e.charAt(0).toUpperCase()}
                  {e.slice(1)}
                </Button>
              ))}
            </ButtonGroup>
          ) : null}

          <FormInput
            size="lg"
            className="mb-3"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />

          {!forgotPassword && (
            <>
              <FormInput
                size="lg"
                className="mb-3"
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                className="mb-3"
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormInput
                  size="lg"
                  type="twoFactorCode"
                  placeholder="Two-Factor Code"
                  onChange={(e) => set2Fa(e.target.value)}
                  style={{
                    flex: 1,
                    marginRight: 8,
                  }}
                />
                <Button variant="outline-light" onClick={() => setup2fa()}>
                  Setup
                </Button>
              </div>
            </>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              marginBottom: 8,
            }}
          >
            <LoadingButton
              type="submit"
              onClick={(e) => (forgotPassword ? doReset(e) : doLogin(e))}
              loading={loading || resetting || accountLoading}
              style={{ flex: 1 }}
            >
              <span>
                {forgotPassword
                  ? resetting
                    ? "Requesting reset..."
                    : "Reset Password"
                  : loading
                  ? "Logging in..."
                  : "Login"}
              </span>
            </LoadingButton>

            <Button
              variant="outline-primary"
              style={{ marginLeft: 16, cursor: "pointer", flex: 1 }}
              onClick={() => setForgotPassword(!forgotPassword)}
            >
              {forgotPassword ? "Back to login" : "Forgotten your password?"}
            </Button>
          </div>
          {error && <Alert variant="danger">Couldn't set up 2fa</Alert>}
          {loginError && <Alert variant="danger">{loginError.message}</Alert>}
        </Form>
      </div>
      <Modal show={show2fa} onHide={() => setShow2fa(false)}>
        <Modal.Header closeButton>Your QR Code</Modal.Header>
        <Modal.Body>
          <p>
            Use your phones camera to load this QR code into Authy, Google
            Authenticator or other 2FA app.
          </p>
          {data && <QR value={data.setupTwoFactorAuthentication.uri} />}

          <p>
            <label>Enter a 2fa code from your app</label>
            <FormInput onChange={(e) => set2Fa(e.currentTarget.value)} />
          </p>
          <LoadingButton
            onClick={() => complete2fa()}
            loading={completeLoading}
          >
            Submit Code
          </LoadingButton>
          <p>
            Not working? Contact{" "}
            <a href="https://chat.google.com/room/AAAAmrUyxhE">engineering</a>.
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Login;

const styles = {
  background: style(
    {
      display: "flex",
      height: "100vh",
      flexDirection: "row",
      background:
        "linear-gradient(45deg, #31b668 0%, #31b668 25%, transparent 25%)",
      padding: "0 64px",
    },
    media(
      { maxWidth: "960px" },
      {
        background:
          "linear-gradient(20deg, #31b668 0%, #31b668 25%, transparent 20%)",
      }
    )
  ),
  food: style(
    {
      flex: 3,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    media(
      { maxWidth: "960px" },
      {
        display: "none",
      }
    )
  ),
};
