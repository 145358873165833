import { AlertOutlined } from "@ant-design/icons";
import { Button, Form, message, Modal, Select } from "antd";
import React, { useState } from "react";
import {
  $,
  OrderCancellationReason,
  OrderRejectionInput,
} from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";

const OPTIONS = Object.keys(OrderCancellationReason).map((r) => ({
  label: r,
  value: r,
}));

export default function CancelOrderButton({
  id,
  restaurantId,
  status,
  number,
  restaurantName,
}) {
  const [reason, setReason] = useState(
    OrderCancellationReason.PARTNER_DID_NOT_RESPOND
  );

  const input: OrderRejectionInput = {
    orderId: id,
    reason,
    restaurantId: restaurantId,
  };
  const [show, setShow] = useState(false);

  const [rejectOrder, { loading: rejecting }] = useTypedMutation(
    {
      rejectOrderAsRestaurant: [
        {
          rejectOrder: $`rejectOrder`,
        },
        true,
      ],
    },
    {
      variables: {
        rejectOrder: input,
      },
      onCompleted: () => {
        message.success("This order has been rejected.");
        setShow(false);
      },
    }
  );

  return (
    <>
      <Button
        loading={rejecting}
        disabled={status === "COMPLETE" || status === "CANCELLED"}
        onClick={() => setShow(true)}
        danger
        icon={<AlertOutlined />}
      >
        Cancel Order
      </Button>
      <Modal
        title={`Cancel ${number} from ${restaurantName}`}
        visible={show}
        onCancel={() => setShow(false)}
        okText="Cancel Order"
        okButtonProps={{
          danger: true,
          icon: <AlertOutlined />,
        }}
        onOk={rejectOrder.bind(null, {
          variables: {
            ...input,
          },
        })}
        cancelButtonProps={{
          hidden: true,
        }}
      >
        <Form layout="vertical">
          <Form.Item
            name="reason"
            label="Why are you cancelling this order?"
            initialValue={reason}
          >
            <Select onChange={(v) => setReason(v as any)} options={OPTIONS} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
