import {
  Badge,
  Descriptions,
  List,
  PageHeader,
  Statistic,
  Switch,
  Table,
  Tabs,
} from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import { formatDistanceToNow } from "date-fns";
import React from "react";
import { useParams } from "react-router-dom";
import { Container } from "shards-react";
import AccountStatistics from "../components/customer/AccountStatistics";
import AddressView from "../components/customer/AddressView";
import CustomerAnalytics from "../components/customer/CustomerAnalytics";
import CustomerReusables from "../components/customer/CustomerReusables";
import CustomerWalletTopupTable from "../components/financials/CustomerWalletTopups";
import IncidentTable from "../components/incident/IncidentTable";
import { OrdersTable } from "../components/OrdersTable";
import { $ } from "../graphql/generated";
import { useTypedMutation, useTypedQuery } from "../graphql/hooks";
import { CUSTOMER } from "../graphql/queries";
import useTabSync from "../hooks/useTabSync";
import { price } from "../utils/price";

const Customer = () => {
  const { id, tab = "overview" } = useParams();

  const { data } = useTypedQuery(CUSTOMER, {
    variables: {
      id,
    },
  });

  const customer = data?.Customer;

  const [deleteAddress] = useTypedMutation({
    deleteAddress: [
      {
        id: $`id`,
      },
      true,
    ],
  });

  const { key, onSelect } = useTabSync(tab, { id });

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}

      <PageHeader
        title={customer?.name}
        subTitle="Customer"
        extra={[
          <Statistic
            title="Account Credit"
            value={price(customer?.accountCredit || 0)}
            loading={!customer}
          />,
        ]}
      />

      <Tabs activeKey={key} onChange={onSelect}>
        <Tabs.TabPane key="overview" tab="Overview">
          <Descriptions bordered>
            <Descriptions.Item label="Name">
              <Text>{customer?.name}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              <Text>{customer?.email}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="Joined">
              {new Date(customer?.created).toLocaleString()}
            </Descriptions.Item>
            <Descriptions.Item label="Stripe ID">
              {customer?.stripeCustomerId ? (
                <a
                  href={`https://dashboard.stripe.com/customers/${customer.stripeCustomerId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {customer.stripeCustomerId}
                </a>
              ) : (
                <Text type="secondary">Not Found</Text>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Referral Link">
              <Text>{customer?.referralLink}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="Marketing?">
              <Switch disabled checked={customer?.marketing} />
            </Descriptions.Item>
          </Descriptions>

          <Table
            title={() => <Title level={5}>Payment Methods</Title>}
            dataSource={customer?.paymentMethods}
            columns={[
              {
                title: "Brand",
                dataIndex: "brand",
              },
              {
                title: "Last 4",
                dataIndex: "last4",
              },
              {
                title: "Expiry",
                render: (_, r) => `${r.exp_month}/${r.exp_year}`,
              },
              {
                title: "Stripe ID",
                dataIndex: "stripeId",
              },
            ]}
          ></Table>

          <Title level={5}>Addresses</Title>
          <List
            itemLayout="vertical"
            size="large"
            pagination={{
              pageSize: 3,
            }}
            dataSource={customer?.addresses}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <AddressView
                  deleteAddress={(id) =>
                    deleteAddress({
                      variables: {
                        id,
                      },
                    })
                  }
                  address={item}
                  selectable={false}
                  collapse={false}
                  selected=""
                />
              </List.Item>
            )}
          />
          <div style={{ display: "flex", marginTop: 8 }}>
            <Title style={{ flex: 1 }} level={5}>
              Emissions
            </Title>
            <Badge
              status={customer?.orderStatistics ? "processing" : "default"}
              text={
                customer?.orderStatistics
                  ? `Updated ${formatDistanceToNow(
                      new Date(customer.orderStatistics.lastUpdated),
                      { addSuffix: true, includeSeconds: true }
                    )}`
                  : "No Data"
              }
            />
          </div>

          <AccountStatistics
            emissionsSaved={customer?.orderStatistics?.emissionsSaved}
            metres={customer?.orderStatistics?.metres}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="orders" tab="Orders">
          <OrdersTable customerId={id} />
        </Tabs.TabPane>
        <Tabs.TabPane key="incidents" tab="Incidents">
          <IncidentTable customerId={id} />
        </Tabs.TabPane>
        <Tabs.TabPane key="analytics" tab="Analytics">
          <CustomerAnalytics id={id} />
        </Tabs.TabPane>
        <Tabs.TabPane key="topups" tab="Topups">
          <CustomerWalletTopupTable customerId={id} />
        </Tabs.TabPane>
        <Tabs.TabPane key="reusables" tab="Reusables">
          <CustomerReusables id={id} />
        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
};

export default Customer;
