import { Button, Space } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SplitPanels, {
  PanelArrangement,
} from "../components/common/SplitPanels";
import OperationsSettingsModal, {
  OperationsSettings,
} from "../components/ops/OperationsSettingsModal";
import SelectZonesModal from "../components/ops/SelectZonesModal";
import ZoneOverviewPanel, {
  ZoneOverviewPanelTab,
} from "../components/ops/ZoneOverviewPanel";
import { $, MapType, Query, Zone } from "../graphql/generated";
import { useTypedQuery } from "../graphql/hooks";
import { ZONE } from "../graphql/queries";
import { useStore } from "../state/zustand";

const minNZones = 1;
const maxNZones = 4;

export interface OpsConfig extends OperationsSettings {
  panelArrangement: PanelArrangement;
  fullscreenZoneId: string | undefined;
  fullscreenTrigger: "user" | "automatic";
  activeTabs: {
    activeTab: ZoneOverviewPanelTab;
    zoneId: string;
  }[];
  expanded: { expanded: boolean; zoneId: string }[];
}

const Operations = () => {
  const { data: citiesData, loading, refetch } = useTypedQuery(
    {
      allCities: [
        {
          skip: $`skip`,
          take: $`take`,
        },
        {
          id: true,
          name: true,
          zones: {
            ...ZONE.zone[1],
            // TODO: uncomment on API fix
            // liveView: {
            //   riderAcceptRate: true,
            //   ridersFreeSoon: true,
            // },
          },
        },
      ],
    },
    {
      variables: {
        skip: 0,
        take: 25,
      },
    }
  );

  const cities = citiesData?.allCities;

  const storedSelectedZoneIds = useStore<string[] | undefined>(
    (s) => s.selectedOpZoneIds
  );

  const setStoreSelectedZoneIds = useStore((s) => s.setSelectedOpZoneIds);

  const [selectedZonesIds, setSelectedZonesIds] = useState<string[]>(
    storedSelectedZoneIds ? storedSelectedZoneIds : []
  );

  useEffect(() => {
    setStoreSelectedZoneIds(selectedZonesIds);
  }, [selectedZonesIds, setStoreSelectedZoneIds]);

  const nSelectedZones = selectedZonesIds.length;

  const selectedZones = useMemo(() => {
    return cities?.reduce(
      (prevSelectedZones, city) => [
        ...prevSelectedZones,
        ...city.zones.filter((zone) => selectedZonesIds.includes(zone.id)),
      ],
      [] as MapType<Query, typeof ZONE>["zone"][]
    );
  }, [cities, selectedZonesIds]);

  const [selectZonesModalVisible, setSelectZonesModalVisible] = useState(
    nSelectedZones < minNZones || nSelectedZones > maxNZones
  );

  const storedOpsConfig = useStore((s) => s.opsConfig);
  const updateStoredOpsConfig = useStore((s) => s.updateOpsConfig);

  const [arrangePanels, setArrangePanels] = useState<PanelArrangement>(
    storedOpsConfig?.panelArrangement
      ? storedOpsConfig.panelArrangement
      : "wide-columns"
  );

  useEffect(() => {
    updateStoredOpsConfig({
      panelArrangement: arrangePanels,
    });
  }, [arrangePanels, updateStoredOpsConfig]);

  const [fullscreenZoneId, _setFullscreenZoneId] = useState<string | undefined>(
    storedOpsConfig.fullscreenZoneId
  );

  useEffect(() => {
    updateStoredOpsConfig({
      fullscreenZoneId,
    });
  }, [fullscreenZoneId, updateStoredOpsConfig]);

  // what was the trigger of the last fullscreen zone id being set
  const [fullscreenTrigger, setFullscreenTrigger] = useState<
    "user" | "automatic"
  >(storedOpsConfig.fullscreenTrigger);

  useEffect(() => {
    updateStoredOpsConfig({
      fullscreenTrigger,
    });
  }, [fullscreenTrigger, updateStoredOpsConfig]);

  const setFullscreenZoneId = useCallback(
    (newFullscreenZoneId: string, trigger: "user" | "automatic") => {
      _setFullscreenZoneId(newFullscreenZoneId);
      setFullscreenTrigger(trigger);
    },
    [_setFullscreenZoneId, setFullscreenTrigger]
  );

  const [expanded, setExpanded] = useState<
    { expanded: boolean; zoneId: string }[]
  >(storedOpsConfig.expanded);

  useEffect(() => {
    updateStoredOpsConfig({
      expanded,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(expanded), updateStoredOpsConfig]);

  const onExpandedChange = useCallback(
    (zoneId: string, newExpanded: boolean) => {
      setExpanded((showZoneStats) => [
        ...showZoneStats.filter((zone) => zone.zoneId !== zoneId),
        { expanded: newExpanded, zoneId },
      ]);
    },
    [setExpanded]
  );

  const [autoFullscreenTabs, setAutoFullscreenTabs] = useState(
    storedOpsConfig.autoFullscreenTabs
  );

  useEffect(() => {
    updateStoredOpsConfig({
      autoFullscreenTabs,
    });
  }, [autoFullscreenTabs, updateStoredOpsConfig]);

  const [autoExitFullscreen, setAutoExitFullscreen] = useState(
    storedOpsConfig.autoExitFullscreen
  );

  useEffect(() => {
    updateStoredOpsConfig({
      autoExitFullscreen,
    });
  }, [autoExitFullscreen, updateStoredOpsConfig]);

  const [activeTabs, setActiveTabs] = useState<
    {
      activeTab: ZoneOverviewPanelTab;
      zoneId: string;
    }[]
  >(storedOpsConfig.activeTabs);

  useEffect(() => {
    updateStoredOpsConfig({
      activeTabs,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(activeTabs), updateStoredOpsConfig]);

  const onActiveTabChange = useCallback(
    (zoneId: string, newActiveTab: ZoneOverviewPanelTab) => {
      // auto fullscreen tab
      if (autoFullscreenTabs.includes(newActiveTab)) {
        setFullscreenZoneId(
          zoneId,
          fullscreenZoneId ? fullscreenTrigger : "automatic"
        );
      } else if (fullscreenTrigger === "automatic" && autoExitFullscreen) {
        // if switching back to a non auto fullscreen tab and the last fullscreen was automatic then switch back
        _setFullscreenZoneId(undefined);
      }
      setActiveTabs((activeTabs) => [
        ...activeTabs.filter((zone) => zone.zoneId !== zoneId),
        { activeTab: newActiveTab, zoneId },
      ]);
    },
    [
      autoExitFullscreen,
      autoFullscreenTabs,
      fullscreenTrigger,
      fullscreenZoneId,
      setFullscreenZoneId,
    ]
  );

  const [showRiderStats, setShowRiderStats] = useState(
    storedOpsConfig.showRiderStats
  );

  useEffect(() => {
    updateStoredOpsConfig({
      showRiderStats,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRiderStats]);

  const [hiddenTabs, setHiddenTabs] = useState(storedOpsConfig.hiddenTabs);

  useEffect(() => {
    updateStoredOpsConfig({
      hiddenTabs,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenTabs]);

  const allZoneOverviewPanels = useMemo(
    () =>
      selectedZones?.map((zone) => ({
        id: zone.id,
        element: (
          <ZoneOverviewPanel
            key={`zone-${zone.id}-overview-panel`}
            zone={zone}
            refetchZone={refetch}
            fullscreen={fullscreenZoneId === zone.id}
            onFullscreenClick={() => {
              if (fullscreenZoneId === zone.id) {
                // if already fullscreen set to undefined
                _setFullscreenZoneId(undefined);
              } else {
                setFullscreenZoneId(zone.id, "user");
              }
            }}
            config={{
              expanded: expanded.find(
                (showStats) => showStats.zoneId === zone.id
              )?.expanded,
              onExpandedChange: (updatedShowStats) =>
                onExpandedChange(zone.id, updatedShowStats),
              activeTab: activeTabs.find(
                (activeTab) => activeTab.zoneId === zone.id
              )?.activeTab,
              onChangeActiveTab: (newActiveTab) =>
                onActiveTabChange(zone.id, newActiveTab),
              showRiderStats,
              hiddenTabs,
            }}
            panelArrangement={arrangePanels}
          />
        ),
      })),
    [
      selectedZones,
      refetch,
      fullscreenZoneId,
      expanded,
      activeTabs,
      showRiderStats,
      hiddenTabs,
      arrangePanels,
      setFullscreenZoneId,
      onExpandedChange,
      onActiveTabChange,
    ]
  );

  const visibleZoneOverviewPanels = allZoneOverviewPanels
    ?.filter((panel) =>
      // if there is a fullscreen zone only it is visible, otherwise all selected zones are visible
      fullscreenZoneId ? panel.id === fullscreenZoneId : true
    )
    .map((panel) => panel.element);

  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const resetSettings = useStore((s) => s.resetOpsAndPaginationConfigs);

  return (
    <Container
      fluid
      className="main-content-container px-4"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          margin: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        <span style={{ flexGrow: 1 }} />
        <Space>
          {
            // switch the panel arrangement
            arrangePanels === "wide-columns" ? (
              <Button
                key="set-long-rows"
                onClick={() => {
                  setArrangePanels("long-rows");
                }}
              >
                <i className="material-icons">table_rows</i>
              </Button>
            ) : (
              <Button
                key="set-wide-columns"
                onClick={() => {
                  setArrangePanels("wide-columns");
                }}
              >
                <i className="material-icons">view_column</i>
              </Button>
            )
          }
          <Button key="settings" onClick={() => setShowSettingsModal(true)}>
            <i className="material-icons">settings</i>
          </Button>
          <Button
            key="change-selected-zones"
            onClick={() => setSelectZonesModalVisible(true)}
          >
            Change Selected Zones
          </Button>
        </Space>
      </div>
      <Row style={{ flexGrow: 1 }}>
        <Col>
          {selectedZones && visibleZoneOverviewPanels ? (
            <SplitPanels preferArrange={arrangePanels}>
              {visibleZoneOverviewPanels}
            </SplitPanels>
          ) : null}
          {}
        </Col>
      </Row>
      <SelectZonesModal
        visible={selectZonesModalVisible}
        onCancel={() => setSelectZonesModalVisible(false)}
        onSubmit={(zoneIds) => {
          // if the current fullscreen zone is no longer present then no longer full screen
          if (fullscreenZoneId && !zoneIds.includes(fullscreenZoneId)) {
            _setFullscreenZoneId(undefined);
          }
          setSelectedZonesIds(zoneIds);
          setSelectZonesModalVisible(false);
        }}
        initialSelectedZoneIds={selectedZonesIds}
        minZones={minNZones}
        maxZones={maxNZones}
        cities={cities}
      />
      <OperationsSettingsModal
        visible={showSettingsModal}
        initialSettings={{
          showRiderStats,
          hiddenTabs,
          autoFullscreenTabs,
          autoExitFullscreen,
        }}
        onCancel={() => setShowSettingsModal(false)}
        onChangeSettings={(updatedSettings) => {
          setShowRiderStats(updatedSettings.showRiderStats);
          setHiddenTabs(updatedSettings.hiddenTabs);
          setAutoFullscreenTabs(updatedSettings.autoFullscreenTabs);
          setAutoExitFullscreen(updatedSettings.autoExitFullscreen);
          setShowSettingsModal(false);
        }}
        onResetSettings={() => {
          resetSettings();
          window.location.reload();
        }}
      />
    </Container>
  );
};

export default Operations;
