import { Form, FormInstance, InputNumber, Slider } from "antd";
import Text from "antd/lib/typography/Text";
import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import { parseReactTextToInt } from "../../utils/event-handling";

interface Props {
  itemWeight?: number;
  form: FormInstance<any>;
}

const WEIGHT_MARKERS = {
  0: "0g",
  250: "250g",
  500: "500g",
  750: "750g",
  1000: "1kg",
  1250: "1.25kg",
  1500: "1.5kg",
  1750: "1.75kg",
  2000: "2kg+",
};

const WeightFormItem = (props: Props, ref) => {
  const { itemWeight, form } = props;

  const [weight, setWeight] = useState<number>(0);

  useImperativeHandle(ref, () => ({
    setWeight,
  }));

  useEffect(() => {
    if (itemWeight !== undefined) {
      setWeight(itemWeight);
    }
  }, [itemWeight]);

  useEffect(() => {
    if (typeof weight === "number") {
      form.setFieldsValue({
        weight,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weight]);

  return (
    <Form.Item label="Weight">
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please enter a weight for the item.",
          },
        ]}
        name="weight"
        noStyle
        style={{ width: "80%" }}
      >
        <InputNumber
          min={0}
          onChange={(updatedWeight) => {
            if (updatedWeight !== undefined) {
              const numericWeight = parseReactTextToInt(updatedWeight);
              if (numericWeight !== undefined) {
                setWeight(numericWeight);
              }
            }
          }}
        />
      </Form.Item>
      <Text style={{ marginLeft: 7 }}>g</Text>
      <div style={{ position: "relative", marginLeft: 10 }}>
        <Slider
          min={0}
          max={2000}
          step={250}
          marks={WEIGHT_MARKERS}
          value={weight}
          tooltipVisible={false}
          onChange={(val) => setWeight(val)}
        />
      </div>
    </Form.Item>
  );
};

export default forwardRef(WeightFormItem);
