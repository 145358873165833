import { Collapse, Divider } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import { $ } from "../../../graphql/generated";
import { query, useTypedQuery } from "../../../graphql/hooks";
import DynamicForm from "../../forms/DynamicForm";
import PartnersOnPlan from "./PartnersOnPlan";

const COMMISION_PLAN = query({
  CommissionPlan: [
    {
      id: $`id`,
    },
    {
      id: true,
      slug: true,
      description: true,
      rungs: {
        id: true,
        name: true,
        fixedBase: true,
        percentage: true,
      },
    },
  ],
});

export default function CommissionPlan({ selectedId }) {
  const { data, refetch } = useTypedQuery(COMMISION_PLAN, {
    variables: {
      id: selectedId,
    },
  });

  return (
    <>
      <Title level={5}>Rungs</Title>
      <Collapse defaultActiveKey={["1"]}>
        {data?.CommissionPlan.rungs.map((r) => (
          <Collapse.Panel key={r.id} header={r.name}>
            <DynamicForm id={"CreateCommissionRung"} entityId={r.id} />
          </Collapse.Panel>
        ))}
      </Collapse>

      <DynamicForm
        id={"CreateCommissionRung"}
        entityId={selectedId}
        onComplete={() => refetch()}
      />

      <Divider />
      <PartnersOnPlan planId={selectedId} />
    </>
  );
}
