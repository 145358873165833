import { Badge, Select } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useCallback, useState } from "react";

export default function CategoryHiddenSelect({
  onChange,
  loadin,
  value,
}: {
  value: boolean;
  loadin: boolean;
  onChange: (topLevel: boolean) => void;
}) {
  const [val, setVal] = useState(value);

  const onSelectValueChange = useCallback(
    (v: "TOP_LEVEL" | "HIDDEN") => {
      setVal(v === "TOP_LEVEL");
      onChange(v === "TOP_LEVEL");
    },
    [onChange, setVal]
  );

  return (
    <Select
      loading={loadin}
      value={val ? "TOP_LEVEL" : "HIDDEN"}
      style={{
        minWidth: 200,
      }}
      onChange={onSelectValueChange}
    >
      <Select.Option value="TOP_LEVEL">
        <Badge text="Top Level" status="success" />
        <br />
        <Text type="secondary">For main menu items.</Text>
      </Select.Option>
      <Select.Option value="HIDDEN">
        <Badge text="Hidden" status="error" />
        <br />
        <Text type="secondary">Out of season or for modifiers.</Text>
      </Select.Option>
    </Select>
  );
}
