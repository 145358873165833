import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, FormGroup, Modal } from "react-bootstrap";
import { FormCheckbox, FormInput } from "shards-react";
import { Item } from "../../graphql/generated";
import { price } from "../../utils/price";
/**
 * Locates an item and provides an onSave result
 */
export const SearchItemModal = ({
  show,
  onHide,
  onSave,
  menu,
  preSelected,
}) => {
  const [selected, setSelected] = useState<any[]>([...preSelected]);
  const [query, setQuery] = useState("");
  const onSelect = (e) => {
    const {
      currentTarget: { value: id },
    } = e;
    const idx = selected.findIndex((eid) => id === eid);
    if (idx !== -1)
      setSelected(
        selected.slice(0, idx).concat(selected.slice(idx + 1, selected.length))
      );
    else selected.push(id);
  };

  useEffect(() => {
    setSelected([...preSelected]);
  }, [preSelected]);

  const menuItems = useMemo(() => {
    if (!menu?.categories) {
      return null;
    }
    var menuItems: Item[] = [];
    const categoryItems = menu?.categories.map((category) => category.items);
    menuItems = menuItems.concat.apply(menuItems, categoryItems);
    // removes duplicates
    return [...new Set(menuItems)];
  }, [menu?.categories]);

  const filteredMenuItems = useMemo(() => {
    if (query === "") {
      return [];
    }
    return menuItems?.filter(
      (i) => JSON.stringify(i).toLowerCase().includes(query.toLowerCase()) && i
    );
  }, [query, menuItems]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Find Menu Items</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FormGroup>
            <label htmlFor="search">Search</label>
            <FormInput
              name="search"
              placeholder="Kebab"
              onChange={(e) => setQuery(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            {filteredMenuItems?.map((i) => (
              <FormCheckbox
                style={{ marginRight: "12px" }}
                value={i.id}
                key={i.id}
                onChange={onSelect}
                checked={selected.find((eid) => eid === i.id)}
              >
                <span style={{ marginLeft: 12 }}>
                  {i.name} - {`${price(i.unitPrice)}`}
                </span>
              </FormCheckbox>
            ))}
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={(e) => {
            onSave(selected);
            onHide(e);
          }}
        >
          Add Items
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
