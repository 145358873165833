import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { AddCategoryForm } from "../components/forms/AddCategoryForm";
import { ThreeDots } from "../components/ThreeDots";
import { Restaurant } from "../graphql/generated";
import { _RESTAURANT } from "../graphql/queries";

export const AddCategory = () => {
  const { id } = useParams();
  const [restaurant, setRestaurant] = useState<Restaurant>();
  const { loading } = useQuery(_RESTAURANT, {
    onCompleted: ({ Restaurant }) => setRestaurant(Restaurant),
    variables: { id },
  });

  if (loading || !restaurant) return <ThreeDots />;

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Add Category"
          subtitle={restaurant.id}
          className="text-sm-left"
        />
      </Row>

      <Row>
        {/* Editor */}
        <Col lg="12" md="12">
          <AddCategoryForm restaurant={restaurant} />
        </Col>
      </Row>
    </Container>
  );
};
