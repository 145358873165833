import React from "react";

export const BasketItem = ({ i }) => (
  <div
    style={{
      width: "100%",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <h5
        style={{
          flex: 1,
        }}
      >
        {i.quantity}x {i.name}
      </h5>
      <span>£{(i.totalPrice / 100).toFixed(2)}</span>
    </div>
    <div style={{ marginLeft: 16 }}>
      {i.modifierItems.map((m) => (
        <span style={{ color: "green", display: "block" }}>
          {m.quantity}x {m.name}
        </span>
      ))}
    </div>
  </div>
);
