import {
  Button,
  Card,
  DatePicker,
  Form,
  message,
  Space,
  Switch,
  Table,
} from "antd";
import Title from "antd/lib/typography/Title";
import { endOfWeek, startOfWeek } from "date-fns";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { $ } from "../graphql/generated";
import { useTypedMutation, useTypedQuery } from "../graphql/hooks";
import usePagination from "../hooks/usePagination";
import { price } from "../utils/price";
import PayInvoiceButton from "./financials/PayInvoiceButton";
export default function RiderInvoices() {
  const {
    pagination,
    args: { order, page, sortBy, take },
    onChange,
  } = usePagination({
    defaultOrder: "ASC",
    defaultSortBy: "end",
  });
  const [paid, setPaid] = useState(false);

  const [startDate, setStartDate] = useState(startOfWeek(new Date()));
  const [endDate, setEndDate] = useState(endOfWeek(new Date()));

  const { data, loading } = useTypedQuery(
    {
      allRiderInvoices: [
        {
          order: $`order`,
          sortBy: $`sortBy`,
          paid: $`paid`,
          skip: $`skip`,
          take: $`take`,
          startDate: startDate.toJSON(),
          endDate: endDate.toJSON(),
        },
        {
          items: {
            id: true,
            earnings: true,
            tips: true,
            paid: true,
            rider: {
              id: true,
              name: true,
            },
            end: true,
            start: true,
            value: true,
            orders: true,
            revolutTransactionLink: true,
          },
          hasMore: true,
          total: true,
        },
      ],
    },
    {
      variables: {
        order,
        sortBy,
        take,
        skip: (page - 1) * take,
        paid,
      },
    }
  );

  const [payRiderInvoices, { loading: paying }] = useTypedMutation(
    {
      payRiderInvoices: [
        {
          start: $`start`,
          end: $`end`,
        },
        {
          count: true,
        },
      ],
    },
    {
      onCompleted: (d) => {
        message.success(
          `Started payment job for ${d.payRiderInvoices.count} invoices.`
        );
      },
      onError: (e) =>
        message.error(e.graphQLErrors.map((e) => e.message).join(", ")),
    }
  );

  const doPay = useCallback(() => {
    payRiderInvoices({
      variables: {
        start: startDate,
        end: endDate,
      },
    });
  }, [startDate, endDate, payRiderInvoices]);

  return (
    <Card
      title={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Title level={4} style={{ flex: 1 }}>
            Rider Invoices
          </Title>

          <div>
            <Space>
              <DatePicker.RangePicker
                value={[moment(startDate), moment(endDate)]}
                onChange={(v) => {
                  if (v && v[0] && v[1]) {
                    setStartDate(v[0]?.toDate());
                    setEndDate(v[1]?.toDate());
                  }
                }}
              />

              <Form.Item label="Paid?">
                <Switch checked={paid} onChange={(e) => setPaid(e)} />
              </Form.Item>
              <Button type="primary" loading={paying} onClick={doPay}>
                Pay All
              </Button>
            </Space>
          </div>
        </div>
      }
    >
      <Table
        dataSource={data?.allRiderInvoices.items}
        loading={loading}
        pagination={{
          total: data?.allRiderInvoices.total,
          ...pagination,
        }}
        onChange={onChange}
        columns={[
          {
            title: "Name",
            dataIndex: "rider",
            render: (r) => r.name,
          },
          {
            title: "Period",
            render: (r) =>
              `${new Date(r.start).toLocaleDateString()} - ${new Date(
                r.end
              ).toLocaleDateString()}`,
          },
          {
            title: "orders",
            dataIndex: "orders",
          },
          {
            title: "Total",
            dataIndex: "value",
            render: (r) => price(r),
          },
          {
            title: "Pay",
            render: (i) =>
              i.paid ? (
                <a
                  href={i.revolutTransactionLink}
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  <i className="material-icons mr-2" style={{ fontSize: 18 }}>
                    done
                  </i>{" "}
                  View Transaction
                </a>
              ) : (
                <PayInvoiceButton id={i.id} />
              ),
          },
        ]}
      />
    </Card>
  );
}
