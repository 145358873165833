import { QueryResult } from "@apollo/client";
import { Button, Drawer, message, PageHeader, Table, Switch } from "antd";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import LazyLoad from "react-lazyload";
import {
  Item,
  MapType,
  MenuCategory as MenuCategoryType,
  ModifierGroup,
  Query,
  $,
  MenuModifierGroupCreateInput,
} from "../graphql/generated";
import { useTypedQuery } from "../graphql/hooks";
import { ADD_ITEM, UPDATE_ITEM } from "../graphql/mutations";
import { MENU } from "../graphql/queries";
import useBulkUpdate from "../hooks/useBulkUpdate";
import { DeepPartial } from "../utils/deep-partial";
import { onError } from "../utils/on-error";
import { RestaurantContext } from "../views/Restaurant";
import AddItemForm, { InitialItem } from "./forms/AddItemForm";
import { SearchModifierGroups } from "./forms/SearchModifierGroups";
import AddCategoryModal from "./menu/AddCategoryModal";
import ImportMenuModal from "./menu/ImportMenuModal";
import MenuEditHeader from "./menu/MenuEditHeader";
import UpdateItemWeightsModal from "./menu/UpdateItemWeightsModal";
import MenuCategory from "./partner/MenuCategory";
import AddModifierGroup, {
  InitialAddModifierGroup,
} from "./menu/AddModifierGroup";
import Text from "antd/lib/typography/Text";
import { useTypedMutation } from "../graphql/hooks";
import ModifierGroupsTable from "./menu/ModifierGroupsTable";

type MenuQueryType = QueryResult<MapType<Query, typeof MENU>>;

export interface MenuContextType {
  query: MenuQueryType;
  onClick: (item: Item) => void;
  menu: NonNullable<MenuQueryType["data"]>["Menu"];
}
const MenuContext = createContext<MenuContextType>({} as any);
export const useSelectedMenu = () => useContext(MenuContext);

export default function Menus() {
  const { data } = useContext(RestaurantContext);
  const restaurant = data?.Restaurant;
  const menuQuery = useTypedQuery(MENU, {
    skip: !restaurant,
    variables: {
      id: restaurant?.menuId,
    },
    fetchPolicy: "cache-and-network",
  });
  const menuData = menuQuery.data;
  const refetchMenu = menuQuery.refetch;

  // import modal
  const [showImportModal, setShowImportModal] = useState(false);

  // add category modal
  const [showAddCategoryModel, setShowAddCategoryModal] = useState(false);
  const [initialAddCategory, setInitialAddCategory] = useState<
    Partial<MenuCategoryType> | undefined
  >(undefined);

  // add modifier group modal
  const [showAddModifierGroupModal, setShowAddModifierGroupModal] = useState(
    false
  );
  const [initialAddModifierGroup, setInitialAddModifierGroup] = useState<
    InitialAddModifierGroup | undefined
  >(undefined);

  const [selectedItem, setSelectedItem] = useState<InitialItem | null>(null);

  const menu = menuData?.Menu || {
    categories: [],
    id: "loading",
    items: [],
    modifierGroups: [],
    name: "",
  };
  const categories = useMemo(
    () =>
      menuData
        ? menuData.Menu.categories
            .slice()
            .sort((a, b) => a.sortOrder - b.sortOrder)
        : [],
    [menuData]
  );

  const onClick = useCallback((item: Item) => {
    setSelectedItem(item);
  }, []);

  const onMgClick = useCallback(
    (item: ModifierGroup) => {
      setInitialAddModifierGroup(item);
      setShowAddModifierGroupModal(true);
    },
    [setInitialAddModifierGroup, setShowAddModifierGroupModal]
  );

  const onAddItemClick = useCallback((catId: string) => {
    setSelectedItem({ categoryId: catId });
  }, []);

  const onCategoryClick = useCallback(
    (cat) => {
      setInitialAddCategory(cat);
      setShowAddCategoryModal(true);
    },
    [setInitialAddCategory, setShowAddCategoryModal]
  );

  const [showEditMenu, setShowEditMenu] = useState(false);
  // offset the messages so that they aren't covered by the menu
  useEffect(() => {
    message.config({
      top: 60,
    });
    return () => {
      // reset it back to default
      const defaultTop = 24;
      message.config({
        top: defaultTop,
      });
    };
  }, []);

  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);

  const selectedCategories = useMemo(
    () =>
      menu?.categories.filter((menuCategory) => {
        return selectedCategoryIds.includes(menuCategory.id);
      }),
    [menu?.categories, selectedCategoryIds]
  );

  const itemIdsInSelectedCategories = selectedCategories?.reduce(
    (prevItemIds: string[], category) => {
      return [...prevItemIds, ...category.items.map((i) => i.id)];
    },
    []
  );

  // callback to add a category id to those selected
  const addSelectedCategoryId = useCallback(
    (cId: string) => {
      if (!selectedCategoryIds.includes(cId)) {
        setSelectedCategoryIds([...selectedCategoryIds, cId]);
      }
    },
    [selectedCategoryIds, setSelectedCategoryIds]
  );
  // callback to remove a category id from those selected
  const removeSelectedCategoryId = useCallback(
    (cId: string) => {
      if (selectedCategoryIds.includes(cId)) {
        setSelectedCategoryIds(
          selectedCategoryIds.filter((currentCid) => currentCid !== cId)
        );
      }
    },
    [selectedCategoryIds, setSelectedCategoryIds]
  );

  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);

  // callback to add an item id to those selected
  const addSelectedItemId = useCallback(
    (itemId: string) => {
      if (!selectedItemIds.includes(itemId)) {
        setSelectedItemIds([...selectedItemIds, itemId]);
      }
    },
    [selectedItemIds, setSelectedItemIds]
  );
  // callback to remove an item id from those selected
  const removeSelectedItemId = useCallback(
    (itemId: string) => {
      if (selectedItemIds.includes(itemId)) {
        setSelectedItemIds(
          selectedItemIds.filter((currentItemId) => currentItemId !== itemId)
        );
      }
    },
    [selectedItemIds, setSelectedItemIds]
  );

  // selectedItemModifierGroups contains a list of all the modifier groups which are included on all the selected items
  // unselectedItemModifierGroups contains a list of all the modifier groups which are included on none of the selected items
  const [
    selectedItemModifierGroups,
    unselectedItemModifierGroups,
  ] = useMemo(() => {
    var selectedItemModifierGroups: string[] = [];
    var unselectedItemModifierGroups: string[] = [];
    // slightly more efficient than doing some() and all() separately
    menu?.modifierGroups.forEach((mg) => {
      var containedInOne = false;
      var containedInAll = true;
      menu?.categories.forEach((category) => {
        category.items.forEach((item) => {
          if (selectedItemIds.includes(item.id)) {
            if (
              item.modifiers.findIndex((itemMg) => itemMg.id === mg.id) !== -1
            ) {
              // contains given modifier group
              containedInOne = true;
            } else {
              containedInAll = false;
            }
          }
        });
      });
      if (containedInAll) {
        selectedItemModifierGroups.push(mg.id);
      } else if (!containedInOne) {
        unselectedItemModifierGroups.push(mg.id);
      }
    });
    return [selectedItemModifierGroups, unselectedItemModifierGroups];
  }, [selectedItemIds, menu?.categories, menu?.modifierGroups]);

  // sets edit menu to be visible when there are categories or items selected and not when there are none
  useEffect(() => {
    if (
      !showEditMenu &&
      (selectedCategoryIds.length > 0 || selectedItemIds.length > 0)
    ) {
      setShowEditMenu(true);
    } else if (
      showEditMenu &&
      selectedCategoryIds.length === 0 &&
      selectedItemIds.length === 0
    ) {
      setShowEditMenu(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryIds, selectedItemIds]);

  const [
    showAddMultipleModifiersModal,
    setShowAddMultipleModifiersModal,
  ] = useState(false);

  const [bulkAddItems] = useBulkUpdate(ADD_ITEM, {
    onError,
    onSuccess: (d) => {
      console.log(d);
      // refetch the updates
      refetchMenu();
      setShowAddMultipleModifiersModal(false);
      message.success("Updated Items!");
    },
  });

  const updateModifierGroups = useCallback(
    (selectedMgIds: string[], unselectedMgIds: string[]) => {
      // for each selected item ensure it contains all the selectedMgIds and none of the unselectedMgIds
      var addItemArgs: any[] = [];
      menu?.categories.forEach((category) => {
        category.items.forEach((item) => {
          if (selectedItemIds.includes(item.id)) {
            const itemUpdatedMgIdsSet =
              // removes duplicates
              new Set([
                ...item.modifiers
                  .map((mg) => mg.id)
                  // remove all those which are contained in the unselected mg ids
                  .filter((mgId) => !unselectedMgIds.includes(mgId)),
                // add all those in the selected mg ids
                ...selectedMgIds,
              ]);
            const itemUpdateMgIds = [...itemUpdatedMgIdsSet];
            addItemArgs.push({
              variables: {
                menuId: menu?.id,
                item: {
                  id: item.id,
                  name: item.name,
                  unitPrice: item.unitPrice,
                  published: item.published,
                  categoryId: item.categoryId,
                  available: item.available,
                  modifierIds: itemUpdateMgIds,
                },
              },
            });
          }
        });
        bulkAddItems(addItemArgs);
      });
    },
    [bulkAddItems, menu?.categories, menu?.id, selectedItemIds]
  );

  const [bulkUpdateItems] = useBulkUpdate(UPDATE_ITEM, {
    onError,
    onSuccess: (d) => {
      console.log(d);
      message.success("Updated Items!");
      setShowUpdateWeightsModal(false);
    },
  });

  const approveSelectedItems = useCallback(() => {
    var addItemArgs: any[] = [];
    menu?.categories.forEach((category) => {
      category.items.forEach((item) => {
        if (selectedItemIds.includes(item.id) && !item.approved) {
          addItemArgs.push({
            variables: {
              id: item.id,
              item: {
                approved: true,
              },
            },
          });
        }
      });
    });
    bulkUpdateItems(addItemArgs);
  }, [bulkUpdateItems, menu?.categories, selectedItemIds]);

  const [showUpdateWeightsModal, setShowUpdateWeightsModal] = useState(false);

  const updateWeightsOfSelectedItems = useCallback(
    (weight: number) => {
      var addItemArgs: any[] = [];
      menu?.categories.forEach((category) => {
        category.items.forEach((item) => {
          if (selectedItemIds.includes(item.id)) {
            addItemArgs.push({
              variables: {
                id: item.id,
                item: {
                  weight,
                },
              },
            });
          }
        });
      });
      bulkUpdateItems(addItemArgs);
    },
    [bulkUpdateItems, menu?.categories, selectedItemIds]
  );

  const [
    updateMgMutation,
    { loading: loadingUpdateMgSortOrder },
  ] = useTypedMutation(
    {
      addModifierGroup: [
        {
          id: $`id`,
          modifierGroup: $`modifierGroup`,
        },
        {
          id: true,
          modifierGroups: {
            id: true,
            sortOrder: true,
          },
        },
      ],
    },
    {
      onCompleted: () => {
        message.success("Saved Changes!");
      },
      onError: () => {
        message.error("Couldn't the Modifier Group.");
      },
    }
  );

  const updateMg = useCallback(
    (
      originalMg: {
        id: string;
        name: string;
        maxOptions: number;
        minOptions: number;
        options: {
          id: string;
        }[];
        priceStrategy: "NO_EXTRA_PRICE" | "ITEM_PRICE_SUM";
        cascades: boolean;
      },
      updatedFields: Partial<MenuModifierGroupCreateInput>
    ) => {
      updateMgMutation({
        variables: {
          id: menu?.id,
          modifierGroup: {
            id: originalMg.id,
            name: originalMg.name,
            maxOptions: originalMg.maxOptions,
            minOptions: originalMg.minOptions,
            optionIds: originalMg.options.map((o) => o.id),
            priceStrategy: originalMg.priceStrategy,
            cascades: originalMg.cascades,
            ...updatedFields,
          },
        },
      });
    },
    [menu?.id, updateMgMutation]
  );

  const updateMgSortOrder = useCallback(
    (
      mg: {
        id: string;
        name: string;
        maxOptions: number;
        minOptions: number;
        options: {
          id: string;
        }[];
        priceStrategy: "NO_EXTRA_PRICE" | "ITEM_PRICE_SUM";
        cascades: boolean;
      },
      sortOrder: number
    ) => {
      updateMg(mg, {
        sortOrder,
      });
    },
    [updateMg]
  );

  type ModifierGroupCreation = {
    type: "mg";
    modifierGroup: InitialAddModifierGroup;
  };

  type ItemCreation = {
    type: "item";
    item: InitialItem;
  };

  type Creation = ModifierGroupCreation | ItemCreation;

  // from least to most recent
  const [creationsInProgress, setCreationsInProgress] = useState<Creation[]>(
    []
  );

  return (
    <MenuContext.Provider value={{ query: menuQuery, onClick, menu }}>
      <MenuEditHeader
        visible={showEditMenu}
        onCancel={() => {
          setSelectedCategoryIds([]);
          setSelectedItemIds([]);
        }}
        nSelectedCategories={selectedCategoryIds.length}
        nSelectedItems={selectedItemIds.length}
        addModifierGroupsToSelectedItems={() => {
          setShowAddMultipleModifiersModal(true);
        }}
        selectAllItemsInCategories={() => {
          setSelectedItemIds([
            ...selectedItemIds,
            ...itemIdsInSelectedCategories,
          ]);
        }}
        deselectAllItemsInCategories={() => {
          setSelectedItemIds(
            selectedItemIds.filter(
              (itemId) => !itemIdsInSelectedCategories.includes(itemId)
            )
          );
        }}
        approveSelectedItems={approveSelectedItems}
        updateWeightOfSelectedItems={() => setShowUpdateWeightsModal(true)}
      />
      <PageHeader
        title={<span>Menu Editor</span>}
        subTitle={data?.Restaurant.name}
        extra={[
          <Button
            key="import_menu_btn"
            onClick={() => {
              setShowImportModal(true);
            }}
          >
            Import Menu
          </Button>,
          <Button
            key="add_category_btn"
            onClick={() => {
              setInitialAddCategory(undefined);
              setShowAddCategoryModal(true);
            }}
          >
            Add Category
          </Button>,
          <Button
            key="add_modifier_group_btn"
            onClick={() => {
              setInitialAddModifierGroup(undefined);
              setShowAddModifierGroupModal(true);
            }}
          >
            Add Modifier Group
          </Button>,
        ]}
      ></PageHeader>
      <div
        style={{
          padding: 24,
        }}
      >
        <ModifierGroupsTable
          loading={loadingUpdateMgSortOrder || menuQuery.loading}
          modifierGroups={menu?.modifierGroups}
          updateMgSortOrder={updateMgSortOrder}
          onMgClick={onMgClick}
          refetchMenu={refetchMenu}
          partnerId={restaurant.id}
        />
      </div>

      <div
        style={{
          padding: 24,
        }}
      >
        {categories.map((c) => (
          <LazyLoad
            key={`${c.id}_lazy_load`}
            height={
              c.items.reduce((i, c) => i + (c.headerImageKey ? 129 : 65), 0) +
              38 +
              16 +
              55
            }
            unmountIfInvisible
          >
            <MenuCategory
              key={`menu_category_${c.id}`}
              category={c}
              onEditPress={onCategoryClick}
              restaurantId={restaurant!.id}
              onAddItemPress={onAddItemClick}
              selected={selectedCategoryIds.includes(c.id)}
              onSelected={(selected) => {
                if (selected) {
                  addSelectedCategoryId(c.id);
                } else {
                  removeSelectedCategoryId(c.id);
                }
              }}
              selectedItemIds={selectedItemIds}
              addSelectedItemId={addSelectedItemId}
              removeSelectedItemId={removeSelectedItemId}
            />
          </LazyLoad>
        ))}
      </div>
      <Drawer
        visible={!!selectedItem}
        onClose={() => setSelectedItem(null)}
        width={Math.max(window.innerWidth * 0.7, 768)}
      >
        {restaurant && selectedItem ? (
          <AddItemForm
            restaurant={restaurant}
            item={selectedItem}
            onComplete={(newItem) => {
              menuQuery.refetch().then(() => {
                if (creationsInProgress.length > 0) {
                  // outstanding creations in progress
                  const creations = creationsInProgress;
                  const inProgress = creations.pop() as Creation;
                  setCreationsInProgress(creations);
                  if (inProgress.type === "mg") {
                    const inProgressOptions = inProgress.modifierGroup.options
                      ? inProgress.modifierGroup.options
                      : [];
                    setInitialAddModifierGroup({
                      ...inProgress.modifierGroup,
                      options: newItem
                        ? [...inProgressOptions, newItem]
                        : inProgressOptions,
                    });
                    setShowAddModifierGroupModal(true);
                  } else {
                    message.error(
                      `Unrecognised in progress type ${inProgress.type}`
                    );
                  }
                }
                setSelectedItem(null);
              });
            }}
            onCreateNewModifierGroup={(partialItem) => {
              setCreationsInProgress((creationsInProgress) => {
                return [
                  ...creationsInProgress,
                  {
                    type: "item",
                    item: partialItem,
                  },
                ];
              });
              setInitialAddModifierGroup(undefined);
              setShowAddModifierGroupModal(true);
              setSelectedItem(null);
            }}
          />
        ) : null}
      </Drawer>
      <SearchModifierGroups
        show={showAddMultipleModifiersModal}
        onHide={() => setShowAddMultipleModifiersModal(false)}
        onSave={(selected, unselected) => {
          updateModifierGroups(selected, unselected ? unselected : []);
        }}
        initialSelectedModifierGroupIds={selectedItemModifierGroups}
        initialUnselectedModifierGroupIds={unselectedItemModifierGroups}
        menu={menu}
        restaurant={restaurant}
      />
      <AddCategoryModal
        visible={showAddCategoryModel}
        onCancel={() => {
          setShowAddCategoryModal(false);
          setInitialAddCategory(undefined);
        }}
        onUpdateCategory={() => {
          setShowAddCategoryModal(false);
          menuQuery.refetch();
          setInitialAddCategory(undefined);
        }}
        menu={menu}
        restaurant={restaurant}
        initialCategory={initialAddCategory}
      />
      <ImportMenuModal
        visible={showImportModal}
        onCancel={() => setShowImportModal(false)}
        menuId={menu?.id}
      />
      <Drawer
        visible={showAddModifierGroupModal}
        onClose={() => {
          setShowAddModifierGroupModal(false);
          setInitialAddModifierGroup(undefined);
        }}
        width={Math.max(window.innerWidth * 0.7, 768)}
      >
        {restaurant && menuData?.Menu && showAddModifierGroupModal ? (
          <AddModifierGroup
            partnerId={restaurant.id}
            initialModifierGroup={initialAddModifierGroup}
            menu={menuData.Menu}
            onCompleted={(newModifierGroup) => {
              if (creationsInProgress.length > 0) {
                // outstanding creations in progress
                const creations = creationsInProgress;
                const inProgress = creations.pop() as Creation;
                setCreationsInProgress(creations);
                if (inProgress.type === "item") {
                  const inProgressModifiers = inProgress.item.modifiers
                    ? inProgress.item.modifiers
                    : [];
                  setSelectedItem({
                    ...inProgress.item,
                    modifiers: newModifierGroup
                      ? [...inProgressModifiers, newModifierGroup]
                      : inProgressModifiers,
                  });
                } else {
                  message.error(
                    `Unrecognised in progress type ${inProgress.type}`
                  );
                }
              }
              setShowAddModifierGroupModal(false);
              setInitialAddModifierGroup(undefined);
            }}
            onCreateNewOption={(mgInProgress) => {
              setCreationsInProgress((creationsInProgress) => {
                return [
                  ...creationsInProgress,
                  {
                    type: "mg",
                    modifierGroup: mgInProgress,
                  },
                ];
              });
              setShowAddModifierGroupModal(false);
              setSelectedItem({ categoryId: undefined });
            }}
          />
        ) : null}
      </Drawer>
      <UpdateItemWeightsModal
        visible={showUpdateWeightsModal}
        onCancel={() => setShowUpdateWeightsModal(false)}
        onUpdateItemWeights={updateWeightsOfSelectedItems}
      />
    </MenuContext.Provider>
  );
}
