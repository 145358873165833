import { ThunderboltFilled } from "@ant-design/icons";
import { Badge, Table, Tooltip } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { OrderOffer } from "../../graphql/generated";
import { formatIfPast } from "../../utils/format-if-past";
import { price } from "../../utils/price";

export const OrderOfferTableColumns = [
  {
    title: "Rider",
    dataIndex: "rider",
    render: (r) => <Link to={`/rider/${r.id}/flight`}>{r.name}</Link>,
  },
  {
    title: "Orders",
    dataIndex: "orders",
    render: (orders) =>
      orders.map((o) => (
        <Tooltip
          title={
            <Link to={`/restaurant/${o.restaurant.id}`}>
              {o.restaurant.name}
            </Link>
          }
        >
          <Link to={`/order/${o.id}`}>#{o.number}</Link>{" "}
        </Tooltip>
      )),
  },
  {
    title: "Outcome",

    render: ({ outcome: o, sneaky }) => (
      <Badge
        text={
          <>
            {o}{" "}
            {sneaky && (
              <Tooltip title="This order was added automatically.">
                <ThunderboltFilled />
              </Tooltip>
            )}
          </>
        }
        status={
          o === "WAITING"
            ? "processing"
            : o === "REJECTED"
            ? "warning"
            : o === "TIMED_OUT" || o === "REMOVED"
            ? "error"
            : "success"
        }
      />
    ),
  },
  {
    title: "Fee",
    dataIndex: "fee",
    render: price,
  },
  {
    title: "Offered",
    dataIndex: "offered",
    render: (o) => (
      <Tooltip title={new Date(o).toLocaleString()}>{formatIfPast(o)}</Tooltip>
    ),
  },
  {
    title: "Updated",
    dataIndex: "changed",
    render: (o) => (
      <Tooltip title={new Date(o).toLocaleString()}>{formatIfPast(o)}</Tooltip>
    ),
  },
];

export default function OrderOfferTable({
  offers,
  order,
  rider,
}: {
  offers: OrderOffer[];
  order?: any;
  rider?: any;
}) {
  const dataSource = useMemo(
    () =>
      offers.map((o) =>
        rider ? { ...o, rider } : order ? { ...o, order } : o
      ),
    [offers, rider, order]
  );

  return (
    <Table
      title={() => <Title level={4}>Offers</Title>}
      dataSource={dataSource}
      columns={OrderOfferTableColumns}
    />
  );
}
