import { CarOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";

export default function RiderName({ id, name, vehicle }) {
  return (
    <Link to={`/rider/${id}`}>
      {name}{" "}
      {vehicle === "CAR" && <CarOutlined style={{ verticalAlign: "middle" }} />}
    </Link>
  );
}
