import { PageHeader } from "antd";
import React from "react";
import CreatePromotionButton from "../promotion/CreatePromotionButton";
import PromotionTable from "../promotion/PromotionTable";

export default function MarketingPromotions() {
  return (
    <div>
      <PageHeader
        title="Promotions"
        subTitle="Manage customer app banners"
        extra={[<CreatePromotionButton />]}
      />
      <div
        style={{
          padding: "12px",
        }}
      >
        <PromotionTable />
      </div>
    </div>
  );
}
