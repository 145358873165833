import { Badge, Tooltip } from "antd";
import { formatDistanceToNow, isFuture } from "date-fns";
import React from "react";

export default function FutureDateBadge({
  start,
  end,
  verbose = false,
}: {
  start: string;
  end: string;
  verbose?: boolean;
}) {
  const now = new Date().getTime();
  const _start = new Date(start),
    _end = new Date(end);
  const inFuture = isFuture(_start);
  const isNow = _start.getTime() < now && _end.getTime() > now;

  const titleText = `${
    inFuture ? "Starts " : isNow ? "Ends " : "Ended "
  }${formatDistanceToNow(_end, { addSuffix: true })}`;

  return (
    <Tooltip
      title={
        verbose
          ? `${_start.toLocaleString()} - ${_end.toLocaleString()}`
          : titleText
      }
    >
      <Badge
        status={isNow ? "processing" : inFuture ? "default" : "success"}
        text={
          verbose
            ? titleText
            : isNow
            ? "Active"
            : inFuture
            ? "Scheduled"
            : "Complete"
        }
      />
    </Tooltip>
  );
}
