import { PageHeader, Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { $ } from "../../../graphql/generated";
import { query, useTypedQuery } from "../../../graphql/hooks";
import usePagination from "../../../hooks/usePagination";
import { CreateRiderReferralDiscountButton } from "./CreateRiderReferralDiscountButton";

export const RIDER_REFERRAL_DISCOUNT_LIST = query({
  RiderReferralDiscountList: [
    {
      order: $`order`,
      sortBy: $`sortBy`,
      skip: $`skip`,
      take: $`take`,
      query: $`query`,
      riderId: $`riderId`,
      validFrom: $`validFrom`,
      validUntil: $`validUntil`,
      discountId: $`discountId`,
    },
    {
      hasMore: true,
      total: true,
      items: {
        id: true,
        associationDate: true,
        discountId: true,
        riderId: true,
        discount: {
          id: true,
          code: true,
          validFrom: true,
          validUntil: true,
        },
        rider: {
          id: true,
          name: true,
        },
      },
    },
  ],
});

export const RiderDiscounts = ({ riderId }: { riderId?: string }) => {
  const {
    pagination,
    args: { order, page, query, sortBy, take },
    onChange,
    setQuery,
  } = usePagination({
    defaultOrder: "ASC",
    defaultSortBy: "code",
  });

  const { data, refetch } = useTypedQuery(RIDER_REFERRAL_DISCOUNT_LIST, {
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
      riderId,
    },
    fetchPolicy: "network-only",
    pollInterval: 10000,
  });

  return (
    <>
      <PageHeader
        title={`${data?.RiderReferralDiscountList.total || 0} Referral Codes`}
        extra={[
          riderId && <CreateRiderReferralDiscountButton riderId={riderId} />,
        ]}
      />

      <Table
        onChange={(p, f, s) => {
          onChange(p, f, s);
        }}
        dataSource={data?.RiderReferralDiscountList.items}
        pagination={{
          total: data?.RiderReferralDiscountList.total,
          ...pagination,
        }}
        columns={[
          {
            title: "Discount Code",
            sorter: true,
            render: (_, item) => (
              <Link
                to={`/discount/${item.discountId}`}
                style={{
                  fontFamily: "monospace",
                }}
              >
                {item.discount.code.slice(0, 8)}
              </Link>
            ),
          },
          {
            title: "Rider",

            render: (_, item) => (
              <Link to={`/rider/${item.rider.id}`}>{item.rider.name}</Link>
            ),
          },
          {
            title: "Valid From",
            sorter: true,
            render: (_, item) =>
              new Date(item.discount.validFrom).toLocaleString(),
          },
          {
            title: "Valid Until",
            sorter: true,
            render: (_, item) =>
              new Date(item.discount.validUntil).toLocaleString(),
          },
        ]}
      />
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </>
  );
};
