/* eslint-disable no-restricted-globals */
import { Button, Input, Switch, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import Text from "antd/lib/typography/Text";
import { JSONSchema7 } from "json-schema";
import {} from "mime";
import mime from "mime-types";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import validator from "validator";
import { DynamicMeta } from "../../../graphql/generated";
import usePagination from "../../../hooks/usePagination";
import { getImageUrl } from "../../../utils/image-url";
import useSubmitDynamicForm from "./useSubmitDynamicForm";
export function DynamicButton({
  schema: i,
  loading,
  setNextForm,
  setNextFormEntity,
  submit,
  entityId,
}: {
  schema: JSONSchema7;
  loading?: boolean;
  setNextForm: (id: string) => void;
  setNextFormEntity: (id: string) => void;
  submit: any;
  entityId?: string;
}) {
  if (!i.properties) return <></>;
  return (
    <Button
      loading={loading}
      onClick={() => {
        if (i.properties!["editForm"]) {
          setNextForm(i.properties!["editForm"]["const"]);
          if (entityId) setNextFormEntity(entityId);
        }
        if (i.properties!["submitForm"]) {
          submit({
            variables: {
              id: i.properties!["submitForm"]["const"],
              entityId,
            },
          });
        }
      }}
    >
      {i.const}
    </Button>
  );
}

export default function DynamicTable({
  formId,
  initialValues,
  setMeta,
  schema,
  metaSchema,
  setNextForm,
  setNextFormEntity,
  meta,
  onEditEntity,
}: {
  formId: string;
  schema: JSONSchema7;
  metaSchema?: JSONSchema7;
  meta?: DynamicMeta;
  setMeta?: (meta: DynamicMeta) => void;
  initialValues: any[];
  setNextForm: (id: string) => void;
  setNextFormEntity: (id: string) => void;
  onEditEntity: () => void;
}) {
  const [submit, { loading }] = useSubmitDynamicForm(undefined, () =>
    onEditEntity()
  );

  const { args, onChange, pagination, setQuery } = usePagination({
    defaultOrder: meta?.pagination.order,
    defaultSortBy: meta?.pagination.sortBy,
    defaultTake: meta?.pagination.take,
    // persistContextId: `dynamic-table-${formId}-${JSON.stringify(schema)}`,
  });

  useEffect(() => {
    if (setMeta) {
      setMeta({
        pagination: {
          ...args,
        },
        query: args.query,
      });
    }
  }, [args]);

  const [exceptColumns, setExceptColumns] = useState<string[]>([]);

  const columns = useMemo(() => {
    const cols: ColumnsType<typeof initialValues> = [];

    const props = Object.entries(schema.properties!);
    for (const [key, prop] of props) {
      if (typeof prop === "boolean") continue;
      if (exceptColumns.includes(key)) continue;

      cols.push({
        key: key,
        dataIndex: key,
        title: prop.title || key,
        sorter: true,
        render: (r, o: { id: string }) => {
          if (Array.isArray(r)) {
            return "[" + r.join(", ") + "]";
          }

          if (typeof r === "boolean") {
            const entityId =
              o[metaSchema?.properties?.["uniqueIdPath"]?.["const"]] || r["id"];

            const submitForm = metaSchema?.properties?.["buttons"]?.items?.find(
              (i) => i.properties?.["editForm"]
            )?.properties?.["editForm"]["const"];

            console.log(submitForm, o);

            return entityId && submitForm ? (
              <Switch
                checked={r}
                onChange={() => {
                  submit({
                    variables: {
                      id: submitForm,
                      entityId: o.id,
                      data: {
                        ...o,
                        [key]: !o[key],
                      },
                    },
                  });
                }}
              />
            ) : (
              <Tag color={r ? "green" : "red"}>{r ? "T" : "F"}</Tag>
            );
          }

          if (typeof r === "string") {
            const fileName = /[^/\\&\?]+\.\w{3,4}(?=([\?&].*$|$))/.exec(r)?.[0];
            const mimeRes: string | false = mime.lookup(fileName);
            if (fileName && mimeRes && mimeRes.startsWith("image")) {
              return (
                <a href={getImageUrl(r)} target="_blank" rel="noreferrer">
                  <img
                    src={getImageUrl(r, 256, 256)}
                    alt={fileName}
                    style={{
                      height: 96,
                      width: 96,
                      borderRadius: 2,
                    }}
                  />
                </a>
              );
            }

            if (validator.isURL(r))
              return r.startsWith(location.origin) ? (
                <Link to={r.replace(location.origin, "")}>
                  {r.replace(location.origin, "")}
                </Link>
              ) : (
                <a href={r} target="_blank" rel="noreferrer">
                  {r}
                </a>
              );
          }

          return r;
        },
      });
    }

    cols.push({
      key: "__exceptColumns",
      filters: props.map(([k, v]) => ({
        text: typeof v === "boolean" ? "true" : v.title || k,
        value: k,
      })),
      fixed: "right",
      width: "10",
    });

    if (metaSchema) {
      if (metaSchema.properties) {
        for (const [key, prop] of Object.entries(metaSchema.properties!)) {
          if (typeof prop === "boolean") continue;
          if (key === "buttons" && "items" in prop) {
            cols.push({
              key,
              render: (_, r) => {
                const entityId =
                  r[metaSchema.properties!["uniqueIdPath"]["const"]] || r["id"];

                return prop.items.map((i, idx) => (
                  <DynamicButton
                    schema={i}
                    setNextForm={setNextForm}
                    setNextFormEntity={setNextFormEntity}
                    submit={submit}
                    loading={loading}
                    entityId={entityId}
                    key={idx}
                  />
                ));
              },
            });
          }
        }
      }
    }

    return cols;
  }, [schema, exceptColumns]);

  return (
    <>
      <Table
        title={() => (
          <>
            <Text>{meta.pagination.total} Results</Text>
            <Input autoFocus onChange={(e) => setQuery(e.target.value)} />
          </>
        )}
        columns={columns}
        dataSource={initialValues}
        onChange={(p, f, s, e) => {
          onChange(p, f, s);

          if (f["__exceptColumns"]) {
            console.log(f);
          }
        }}
        pagination={{
          ...pagination,
          total: meta?.pagination.total,
        }}
      />
    </>
  );
}
