import { CloseOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  message,
  Space,
  TimePicker,
} from "antd";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { $, DayOfWeek } from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";
import { ZoneContext } from "../../views/Zone";

const options = Object.keys(DayOfWeek);

export default function ZoneOpeningTimeForm() {
  const zone = useContext(ZoneContext)!;

  const [openingTimes, setOpeningTimes] = useState<typeof zone["openingTimes"]>(
    []
  );

  useEffect(() => {
    if (zone) setOpeningTimes(zone.openingTimes);
  }, [zone]);

  const [changeOpeningTimes, { loading }] = useTypedMutation(
    {
      updateZone: [
        {
          id: $`id`,
          zone: {
            openingTimes: $`openingTimes`,
          },
        },
        {
          id: true,
          openingTimes: {
            id: true,
            open: true,
            close: true,
            daysOfWeek: true,
          },
        },
      ],
    },
    {
      onCompleted: () => {
        message.success("Updated opening times!");
      },
      variables: {
        id: zone.id,

        openingTimes: openingTimes.map((o) => ({
          close: o.close,
          open: o.open,
          daysOfWeek: o.daysOfWeek,
        })),
      },
    }
  );

  const addNewTime = useCallback(() => {
    if (
      openingTimes.length &&
      (!openingTimes[openingTimes.length - 1]?.daysOfWeek.length ||
        !openingTimes[openingTimes.length - 1]?.open ||
        !openingTimes[openingTimes.length - 1]?.close)
    ) {
      message.error("Fill out the previous opening time!");
      return;
    }

    setOpeningTimes([
      ...openingTimes,
      {
        close: "22:00",
        open: "10:00",
        daysOfWeek: [],
        id: Math.random().toString(),
      },
    ]);
  }, [openingTimes]);

  return (
    <Form onFinish={changeOpeningTimes.bind(null)}>
      {openingTimes.map((o, i) => (
        <div key={i}>
          <Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <TimePicker.RangePicker
                style={{
                  flex: 1,
                  marginRight: 8,
                }}
                showSecond={false}
                format="HH:mm"
                value={[moment(o.open, "HH:mm"), moment(o.close, "HH:mm")]}
                onChange={([open, close]: any) =>
                  setOpeningTimes(
                    openingTimes.map((ot, ii) =>
                      ii === i
                        ? {
                            ...ot,
                            open: open.format("HH:mm"),
                            close: close.format("HH:mm"),
                          }
                        : ot
                    )
                  )
                }
              />
              <Button
                danger
                icon={<CloseOutlined />}
                onClick={() =>
                  setOpeningTimes(openingTimes.filter((_, ii) => i !== ii))
                }
              >
                Remove
              </Button>
            </div>
            <Checkbox.Group
              style={{
                marginTop: 8,
              }}
              onChange={(v) =>
                setOpeningTimes(
                  openingTimes.map((ot, ii) =>
                    ii === i ? { ...ot, daysOfWeek: v as any } : ot
                  )
                )
              }
              options={options}
              value={o.daysOfWeek}
            />
          </Form.Item>
          <Divider />
        </div>
      ))}

      <Space>
        <Button icon={<PlusOutlined />} onClick={addNewTime.bind(null)}>
          Add New Time
        </Button>
        <Button
          loading={loading}
          htmlType="submit"
          type="primary"
          icon={<SaveOutlined />}
        >
          Save Opening Hours
        </Button>
      </Space>
    </Form>
  );
}
