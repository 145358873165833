import React from "react";
import { Restaurant } from "../graphql/generated";
import PaymentSettings from "./financials/PartnerPaymentDetails";
import DynamicForm from "./forms/DynamicForm";

export const RestaurantOwner = ({ restaurant }: { restaurant: Restaurant }) => {
  return (
    <>
      <DynamicForm id="Merchant.SetOwner" entityId={restaurant.id} />

      <PaymentSettings id={restaurant.id} />
    </>
  );
};
