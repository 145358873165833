import { Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React from "react";
import { Order } from "../graphql/generated";

export default function EstimatedArrivalDisplay({
  order,
}: {
  order: Pick<
    Order,
    | "status"
    | "estimatedArrivalTime"
    | "estimatedArrivalTimeDelta"
    | "riderDroppedOffTime"
  >;
}) {
  if (!order || !order.estimatedArrivalTime) return null;

  const time = moment(order.estimatedArrivalTime);
  const completed = new Date(order.riderDroppedOffTime);

  return order.status === "COMPLETE" ? (
    <Tooltip title="Delivered">
      <Text type="secondary">{completed.toLocaleString()}</Text>
    </Tooltip>
  ) : order.status === "CANCELLED" ? null : (
    <Tooltip
      title={`${time.toDate().toLocaleString()} ± ${
        order.estimatedArrivalTimeDelta
      } mins`}
    >
      <Text strong>
        {time.format("HH:mm")} -{" "}
        {time.add(order.estimatedArrivalTimeDelta, "minutes").format("HH:mm")}
      </Text>
    </Tooltip>
  );
}
