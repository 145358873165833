import { useMutation } from "@apollo/client";
import { getOperationName } from "apollo-link";
import React from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { Form, FormGroup, FormInput } from "shards-react";
import { UPDATE_RESTAURANT } from "../../graphql/mutations";
import { _RESTAURANT } from "../../graphql/queries";
import { useFormData } from "../../hooks/useFormData";
import { LoadingButton } from "../LoadingButton";

export const RatingsModal = ({ restaurant, show, onHide }) => {
  const [formData, onChange] = useFormData({
    ratingCount: restaurant.ratingCount,
    ratingStars: restaurant.ratingStars,
  });
  const [
    updateRestaurantMutation,
    { loading: updateRestaurantLoading },
  ] = useMutation(UPDATE_RESTAURANT, {
    onCompleted: () => onHide(),
    refetchQueries: () => [getOperationName(_RESTAURANT)!],
    variables: {
      id: restaurant.id,
      restaurant: {
        ...formData,
        ratingStars: Number.parseFloat(formData.ratingStars),
      },
    },
  });

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Rating</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger">
          This rating should be consistent with Google, Yelp, or other source
        </Alert>

        <Form>
          <FormGroup>
            <label htmlFor="ratingStars">Rating Stars</label>
            <FormInput
              name="ratingStars"
              value={formData.ratingStars}
              type="text"
              onChange={onChange}
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="ratingCount">Rating Count</label>
            <FormInput
              name="ratingCount"
              value={formData.ratingCount}
              type="number"
              onChange={onChange}
            />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          Cancel
        </Button>
        <LoadingButton
          variant="primary"
          loading={updateRestaurantLoading}
          onClick={updateRestaurantMutation}
        >
          Push
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  );
};
