import { Avatar, Tooltip } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { $ } from "../graphql/generated";
import { useTypedMutation } from "../graphql/hooks";

/**
 * Track page views
 */
export default function PageView() {
  const [view, { data }] = useTypedMutation({
    AdminPageView: [
      {
        url: $`url`,
      },
      {
        url: true,
        users: {
          id: true,
          name: true,
          email: true,
          avatarUrl: true,
          type: true,
        },
      },
    ],
  });

  const location = useLocation();
  const interval = useRef(-1),
    timeout = useRef(-1);

  const logView = useCallback(() => {
    clearInterval(interval.current);
    clearTimeout(timeout.current);
    const doView = () =>
      view({
        variables: {
          url: window.location.href,
        },
      });

    timeout.current = setTimeout(doView, 500) as any;

    interval.current = setInterval(doView, 7500) as any;

    return () => {
      clearInterval(interval.current);
      clearTimeout(timeout.current);
    };
  }, [location]);

  useEffect(logView, [location]);

  const [users, setUsers] = useState(data?.AdminPageView.users || []);
  useEffect(() => data?.AdminPageView && setUsers(data.AdminPageView.users), [
    data?.AdminPageView,
  ]);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 32,
        right: 32,
      }}
    >
      <div style={{ position: "sticky" }}>
        <Avatar.Group>
          {users.map((u) => (
            <Tooltip key={u.id} title={`${u.name} (${u.email})`}>
              <Avatar>{u.name[0]}</Avatar>
            </Tooltip>
          ))}
        </Avatar.Group>
      </div>
    </div>
  );
}
