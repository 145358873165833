import {
  Badge,
  Descriptions,
  message,
  Skeleton,
  Space,
  Tag,
  Tooltip,
} from "antd";
import Text from "antd/lib/typography/Text";
import React, { useCallback, useContext } from "react";
import { generatePath, Link } from "react-router-dom";
import {
  $,
  FulfillmentMethod,
  UpdateOrderInput,
} from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";
import { OrderContext } from "../../views/Order";
import EstimatedArrivalDisplay from "../EstimatedArrivalDisplay";
import { OrderBadge } from "../OrderBadge";
import OrderRatings from "../OrderRatings";
import PickupAtDisplay from "../PickupAtDisplay";

export default function OrderDescriptions() {
  const { data } = useContext(OrderContext);

  const [updateOrder] = useTypedMutation(
    {
      updateOrder: [
        {
          id: $`id`,
          data: $`data`,
        },
        {
          id: true,
          internalNote: true,
          addressId: true,
        },
      ],
    },
    {
      onCompleted: () => {
        message.success("Saved note!");
      },
    }
  );
  const order = data?.order;
  const updateItemKey = useCallback(
    (key: keyof UpdateOrderInput, value: any) =>
      updateOrder({
        variables: {
          id: order?.id,
          data: {
            [key]: value,
          },
        },
      }),
    [updateOrder, order?.id]
  );

  if (!order)
    return (
      <Skeleton
        paragraph={{
          rows: 2,
        }}
        loading
      />
    );

  return (
    <Descriptions bordered>
      <Descriptions.Item label="Status">
        <OrderBadge
          status={order.status}
          cancellationReason={order.cancellationReason}
          blocked={!!order?.pickupBlocked}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Fulfillment">
        {order.fulfillmentMethod}
      </Descriptions.Item>
      {order.basket.tableNumber !== null ? (
        <Descriptions.Item label="Table Number">
          {order.basket.tableNumber}
        </Descriptions.Item>
      ) : null}

      <Descriptions.Item label="Rating">
        <OrderRatings partner={order.partnerRating} rider={order.riderRating} />
      </Descriptions.Item>
      <Descriptions.Item label="Placed">
        {new Date(order.placedAt).toLocaleString()}
      </Descriptions.Item>
      <Descriptions.Item label="Prepare">
        <PickupAtDisplay
          status={order.status}
          initialPickupAt={order.initialPickupAt}
          riderPickedUpTime={order.riderPickedUpTime}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Pickup">
        <PickupAtDisplay
          status={order.status}
          scheduledFor={order.scheduledFor}
          initialPickupAt={order.pickupAt}
          riderPickedUpTime={order.riderPickedUpTime}
        />
      </Descriptions.Item>

      {order.fulfillmentMethod === FulfillmentMethod.DELIVERY ? (
        <>
          <Descriptions.Item
            label={
              <Tooltip title="This is the estimated displayed to the customer">
                <Text>ETA</Text>
              </Tooltip>
            }
          >
            <EstimatedArrivalDisplay order={order} />
          </Descriptions.Item>
          <Descriptions.Item label="Rider">
            <OrderRiderMiniDisplay
              isBeingOfferedToRider={order.isBeingOfferedToRider}
              rider={order.rider}
            />
          </Descriptions.Item>
        </>
      ) : null}
      <Descriptions.Item label="Note">
        <Text
          editable={{
            onChange: (text) => updateItemKey("internalNote", text),
          }}
        >
          {order.internalNote || ""}
        </Text>
      </Descriptions.Item>
      {order.aggregateOrder.orders.length > 1 && (
        <Descriptions.Item label="Aggregates">
          <Space>
            {order.aggregateOrder.orders
              .filter((o) => o.id !== order.id)
              .map((o) => (
                <Tag color="green">
                  <Link to={`/order/${o.id}`}>
                    {o.number} ({o.restaurant.name})
                  </Link>{" "}
                  <OrderBadge status={o.status} />
                </Tag>
              ))}
          </Space>
        </Descriptions.Item>
      )}

      {order.reusables && (
        <Descriptions.Item label="Reuse">
          Prediction: {order.reusables.predictionCount}, Partner Count:{" "}
          {order.reusables.partnerCount}
        </Descriptions.Item>
      )}
    </Descriptions>
  );
}

export function OrderRiderMiniDisplay(order: {
  rider?: {
    id: string;
    name: string;
    phoneNumber?: string;
  };
  isBeingOfferedToRider: boolean;
}) {
  return (
    <Badge
      status={
        order.rider
          ? order.isBeingOfferedToRider
            ? "processing"
            : "success"
          : "default"
      }
      text={
        order?.rider ? (
          <Link
            style={{ flex: 1 }}
            to={generatePath("/rider/:id/flight", {
              id: order.rider.id,
            })}
          >
            <a>{order.rider.name}</a> ({order.rider.phoneNumber})
          </Link>
        ) : (
          <Text type="secondary">No Rider</Text>
        )
      }
    />
  );
}
