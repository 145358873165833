import { Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { CoreConfigurations } from "../graphql/generated";
import { getImageUrl } from "../utils/image-url";

export default function CoreAppDisplay({
  configuration,
  srcWidth = 64,
  srcHeight = 64,
  imgWidth = 24,
}: {
  configuration: CoreConfigurations;
  srcWidth?: number;
  srcHeight?: number;
  imgWidth?: number;
}) {
  return (
    <Tooltip
      title={
        <Link to={`/configuration/${configuration}`}>{configuration}</Link>
      }
    >
      <img
        alt={configuration.toLowerCase()}
        src={getImageUrl(
          `assets/app-icons/${configuration.toLowerCase()}.png`,
          srcWidth,
          srcHeight
        )}
        width={`${imgWidth}`}
        style={{ borderRadius: imgWidth / 2 }}
      />
    </Tooltip>
  );
}
