/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-access-state-in-setstate */
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button, Tag } from "antd";
import React, { useMemo } from "react";
import { Nav } from "shards-react";
import {
  $,
  ItemUpdateRequestStatus,
  Ordering,
} from "../../../graphql/generated/graphql-zeus";
import { useTypedQuery } from "../../../graphql/hooks";
import { useStore } from "../../../state/zustand";
import SidebarNavItem from "./SidebarNavItem";

export const sidebarNavItem = (title, to, icon, color = null) => ({
  title,
  to,
  htmlBefore: icon
    ? `<i class="material-icons" ${color ? `style="color:${color};"` : ""
    }>${icon}</i>`
    : null,
});

const MENU_REQUESTS_TITLE = "Menu Requests";

export function getSidebarItems(menuRequestColor) {
  return [
    sidebarNavItem("Overview", "/dashboard", "assessment"),
    sidebarNavItem("Partners", "/partners", "store"),
    sidebarNavItem("Reusables", "/reusables-overview", "takeout_dining"),
    sidebarNavItem("Cities", "/cities", "list"),
    sidebarNavItem("Operations", "/ops", "support_agent"),
    sidebarNavItem("Accounting", "/accounting", "account_balance"),
    sidebarNavItem("Customers", "/customers", "people"),
    sidebarNavItem("Riders", "/riders", "directions_bike"),
    sidebarNavItem("Admins", "/admins", "person"),
    sidebarNavItem(
      MENU_REQUESTS_TITLE,
      "/menu-requests",
      "view_list",
      menuRequestColor
    ),
    sidebarNavItem("Tablets", "/tablets", "computer"),
    sidebarNavItem("Marketing", "/marketing", "star"),
    sidebarNavItem("Charity", "/donations", "redeem"),
    sidebarNavItem("Media", "/media", "image"),
    sidebarNavItem("Platform", "/platform", "dns"),
  ];
}

function getItemUpdateTagColor(nPending: number) {
  if (nPending > 50) {
    //red
    return "#f5222d";
  } else if (nPending > 20) {
    // orange
    return "#fa8c16";
  } else {
    // blue
    return "#1890ff";
  }
}

const notificationPollIterval = 120000;

function SidebarNavItems() {
  const toggleSider = useStore((s) => s.toggleSider);
  const collapseSider = useStore((s) => s.collapseSider);

  // lift state into parent to prevent having to fetch for each nav item
  const {
    data: itemUpdateRequestData,
    loading: loadingItemUpdateRequests,
  } = useTypedQuery(
    {
      ItemUpdateRequestList: [
        {
          order: $`order`,
          sortBy: $`sortBy`,
          // skip: $`skip`,
          // take: $`take`,
          statuses: $`statuses`,
        },
        {
          total: true,
        },
      ],
    },
    {
      variables: {
        statuses: [ItemUpdateRequestStatus.PENDING],
        order: "DESC",
        sortBy: "created",
      },
      pollInterval: notificationPollIterval,
    }
  );

  const {
    data: unapprovedItemsData,
    loading: loadingUnapprovedItems,
  } = useTypedQuery(
    {
      ItemList: [
        {
          sortBy: "name",
          order: Ordering.DESC,
          approved: false,
        },
        {
          total: true,
        },
      ],
    },
    {
      pollInterval: notificationPollIterval,
    }
  );
  const nPendingItemCreationRequests = unapprovedItemsData?.ItemList.total;
  const nPendingItemUpdates =
    itemUpdateRequestData?.ItemUpdateRequestList.total;
  var totalNPendingMenuRequests = 0;
  if (nPendingItemCreationRequests) {
    totalNPendingMenuRequests += nPendingItemCreationRequests;
  }
  if (nPendingItemUpdates) {
    totalNPendingMenuRequests += nPendingItemUpdates;
  }
  // nPendingItemUpdates is greater than defined and non-zero
  const pendingItemUpdatesColor = totalNPendingMenuRequests
    ? getItemUpdateTagColor(totalNPendingMenuRequests)
    : null;
  const items = useMemo(() => getSidebarItems(pendingItemUpdatesColor), [
    pendingItemUpdatesColor,
  ]);

  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders flex-column">
        {items.map((item) => {
          return (
            <SidebarNavItem
              key={item.title}
              item={item}
              tag={
                // nPendingItemUpdates is greater than defined and non-zero
                item.title === MENU_REQUESTS_TITLE &&
                  nPendingItemCreationRequests != undefined &&
                  nPendingItemUpdates != undefined ? (
                  <Tag
                    style={{
                      marginLeft: 10,
                      borderRadius: 5,
                      fontSize: "0.7rem",
                    }}
                  >
                    {totalNPendingMenuRequests}
                  </Tag>
                ) : null
              }
            />
          );
        })}
      </Nav>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          justifySelf: "flex-end",
        }}
      >
        <Button
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 10,
          }}
          icon={collapseSider ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
          onClick={toggleSider}
        ></Button>
      </div>
    </div>
  );
}

export default SidebarNavItems;
