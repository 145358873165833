import { SearchOutlined } from "@ant-design/icons";
import { Badge, Button, DatePicker, Drawer, Input, Space, Table } from "antd";
import { startOfDay } from "date-fns";
import moment from "moment";
import React, { useState } from "react";
import { MarketingCampaignStatus } from "../../graphql/generated";
import { useTypedQuery } from "../../graphql/hooks";
import { MARKETING_CAMPAIGN_LIST } from "../../graphql/queries";
import usePagination from "../../hooks/usePagination";
import DynamicForm from "../forms/DynamicForm";

export default function MarketingCampaignTable() {
  const [startDate, setStartDate] = useState(startOfDay(new Date()));

  const { pagination, args, onChange, setQuery } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "scheduledFor",
  });

  const { data, loading } = useTypedQuery(MARKETING_CAMPAIGN_LIST, {
    variables: {
      ...args,
      startDate,
    },
    pollInterval: 5000,
  });

  const [selected, setSelected] = useState<string | null>(null);

  return (
    <>
      <Table
        loading={loading}
        dataSource={data?.MarketingCampaignList.items}
        pagination={{
          ...pagination,
          total: data?.MarketingCampaignList.total,
        }}
        onChange={(p, f, s) => {
          if (f["scheduledFor"]?.[0])
            setStartDate(startOfDay(new Date(f["scheduledFor"]?.[0] as any)));

          setQuery((f["name"]?.[0] as any) || "");

          onChange(p, f, s);
        }}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            sorter: true,
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Input
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys([e.target.value])}
                />
                <Space>
                  <Button
                    type="primary"
                    onClick={confirm}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                  >
                    Search
                  </Button>
                  <Button onClick={clearFilters}>Reset</Button>
                </Space>
              </div>
            ),
          },
          {
            title: "Scheduled For",
            dataIndex: "scheduledFor",
            sorter: true,
            render: (_, r) => new Date(r.scheduledFor).toLocaleString(),
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <DatePicker
                  value={moment(selectedKeys[0])}
                  onChange={(e) => setSelectedKeys(e ? [e.toISOString()] : [])}
                  style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Space>
                  <Button
                    type="primary"
                    onClick={confirm}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                  >
                    Search
                  </Button>
                  <Button onClick={clearFilters}>Reset</Button>
                </Space>
              </div>
            ),
          },
          {
            title: "Status",
            render: (_, r) => (
              <Badge
                status={
                  r.status === MarketingCampaignStatus.COMPLETE
                    ? "success"
                    : r.status === MarketingCampaignStatus.IN_PROGRESS
                    ? "processing"
                    : "default"
                }
                text={r.status}
              />
            ),
          },
          {
            title: "Sends",
            dataIndex: "sends",
          },
          {
            render: (_, r) => (
              <Space>
                <Button type="link" onClick={() => setSelected(r.id)}>
                  Details
                </Button>
              </Space>
            ),
          },
        ]}
      />
      <Drawer
        width="50%"
        onClose={() => setSelected(null)}
        visible={!!selected}
      >
        <DynamicForm
          id={"MarketingCampaignCreateForm"}
          entityId={typeof selected === "string" ? selected : undefined}
          noCard
        />
      </Drawer>
    </>
  );
}
