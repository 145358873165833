import {
  DeleteFilled,
  EditFilled,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { $, MapType, Query } from "../../graphql/generated";
import { query, useTypedQuery } from "../../graphql/hooks";
import usePagination from "../../hooks/usePagination";
import { getImageUrl } from "../../utils/image-url";
import DynamicForm from "../forms/DynamicForm";

export const CONTAINER_TYPE_TABLE = query({
  ReusableContainerTypeList: [
    {
      skip: $`skip`,
      take: $`take`,
      sortBy: $`sortBy`,
      order: $`order`,
      query: $`query`,
    },
    {
      total: true,
      items: {
        id: true,
        name: true,
        depth: true,
        length: true,
        width: true,
        image: {
          id: true,
          key: true,
        },
      },
    },
  ],
});

type ContainerType = MapType<
  Query,
  typeof CONTAINER_TYPE_TABLE
>["ReusableContainerTypeList"]["items"][0];

export default function ReusableContainerTypeTable({
  onSelect,
}: {
  onSelect?: (c: ContainerType) => void;
}) {
  const {
    pagination,
    args: { order, page, query, sortBy, take },
    onChange,
    setQuery,
  } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "name",
  });

  const { data, refetch, loading } = useTypedQuery(CONTAINER_TYPE_TABLE, {
    fetchPolicy: "network-only",
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
    },
    pollInterval: 10000,
  });

  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState("");
  return (
    <>
      <Table
        loading={loading}
        title={() => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Title level={4} style={{ flex: 1 }}>
              {data?.ReusableContainerTypeList.total} Container Types
            </Title>

            <Space>
              <Input
                placeholder="Search..."
                suffix={<SearchOutlined />}
                onChange={(t) => setQuery(t.target.value)}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setShow(true)}
              >
                New Box
              </Button>
            </Space>
          </div>
        )}
        onChange={(p, f, s) => {
          onChange(p, f, s);
        }}
        dataSource={data?.ReusableContainerTypeList.items}
        pagination={{
          total: data?.ReusableContainerTypeList.total,
          ...pagination,
        }}
        onRow={(r) => ({
          onClick: () => onSelect?.(r),
        })}
        columns={[
          {
            render: (_, r) =>
              r.image ? (
                <img
                  src={getImageUrl(r.image.key, 256, 256)}
                  alt="container"
                  style={{
                    width: 64,
                  }}
                />
              ) : null,
          },
          {
            title: "Name",
            sorter: true,
            render: (_, r) => (
              <Link to={`/reusables/container-type/${r.id}`}>{r.name}</Link>
            ),
          },
          {
            title: "Size",
            key: "size",
            sorter: true,
            render: (_, r) => `${r.length}x${r.width}x${r.depth}`,
          },
          {
            render: (_, r) => (
              <Button
                onClick={() => setSelected(r.id)}
                icon={<EditFilled />}
                type="text"
              ></Button>
            ),
          },
        ]}
      />
      <Modal
        footer={null}
        visible={show || !!selected}
        destroyOnClose
        onCancel={() => {
          setShow(false);
          setSelected("");
        }}
      >
        <DynamicForm
          noCard
          id="ReusableContainerType"
          entityId={selected}
          onComplete={() => {
            setSelected("");
            setShow(false);
          }}
        />
      </Modal>
    </>
  );
}

export function SelectContainerTypeButton({
  selected,
  setSelected,
}: {
  selected?: {
    name: string;
    length: number;
    width: number;
    depth: number;
  };
  setSelected: (c?: ContainerType) => void;
}) {
  const [show, setShow] = useState(false);
  return (
    <>
      {selected ? (
        <>
          <Text>
            <Text style={{ color: "#31b668" }} strong>
              ecoeats Branded:{" "}
            </Text>
            {selected.name} {selected.length}mm x {selected.width}mm x{" "}
            {selected.depth}mm
          </Text>
          <Button
            danger
            type="text"
            icon={<DeleteFilled />}
            onClick={() => setSelected(undefined)}
          ></Button>
        </>
      ) : (
        <Text type="secondary">Delivered in single-use container</Text>
      )}
      <Button
        onClick={() => setShow(true)}
        icon={<EditFilled />}
        type="text"
      ></Button>

      <Modal visible={show} onCancel={() => setShow(false)} footer={null}>
        <ReusableContainerTypeTable
          onSelect={(s) => {
            setSelected(s);
            setShow(false);
          }}
        />
      </Modal>
    </>
  );
}
