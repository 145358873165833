import {
  DownOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  UpOutlined,
  WarningTwoTone,
} from "@ant-design/icons";
import { Button, message, Table, Tooltip } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useTypedMutation } from "../../graphql/hooks";
import { $ } from "../../graphql/generated";
import { price } from "../../utils/price";
import AvailabilitySelect from "../partner/AvailabilitySelect";
import { SearchModifierItems } from "./SearchModifierItems";

interface ModifierOption {
  id: string;
  name: string;
  description?: string;
  unitPrice: number;
  published: boolean;
  available: boolean;
  sortOrder: number;
  categoryName: string;
  categoryId: string;
}

interface Props {
  partnerId: string;
  value?: ModifierOption[];
  // array of all items in the menu
  menuItems: ModifierOption[];
  onChange?: (newVal: ModifierOption[]) => void;
  createModifier: () => void;
  categories: {
    id: string;
    name: string;
    items: {
      id: string;
      sortOrder: number;
    }[];
  }[];
}

const ModifierOptionsTable = (props: Props) => {
  const {
    partnerId,
    value,
    onChange,
    menuItems,
    createModifier,
    categories,
  } = props;

  const [showAddModifierModal, setShowAddModifierModal] = useState(false);

  const optionsData = useMemo(() => {
    return value
      ?.map((option) => {
        return {
          ...option,
          key: option.id,
        };
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
  }, [value]);

  // the category id to which all options belong, null if options belong to different categories
  const optionsCategoryId: string | null =
    optionsData &&
    optionsData.length > 0 &&
    optionsData.every(
      (option) => option.categoryId === optionsData[0].categoryId
    )
      ? optionsData[0].categoryId
      : null;

  const [reorderCategory] = useTypedMutation(
    {
      reorderCategory: [
        {
          partnerId: $`partnerId`,
          categoryId: $`categoryId`,
          itemIds: $`itemIds`,
        },
        {
          id: true,
          items: {
            id: true,
            sortOrder: true,
          },
        },
      ],
    },
    {
      onError: () => message.error("Could not reorder options."),
      onCompleted: (data) => {
        message.success("Options Reordered!");
        if (onChange && optionsData)
          onChange(
            optionsData!.map((option) => {
              const newSortOrder = data.reorderCategory.items.find(
                (updatedOption) => updatedOption.id === option.id
              )?.sortOrder;
              return {
                ...option,
                sortOrder: newSortOrder ? newSortOrder : option.sortOrder,
              };
            })
          );
      },
    }
  );

  const swapModifiers = useCallback(
    async (option1, option2) => {
      if (!optionsCategoryId) return;

      const optionsCategory = categories.find(
        (category) => category.id === optionsCategoryId
      );

      if (!optionsCategory) return;

      const prevSortedOptions = [...optionsCategory.items].sort(
        (a, b) => a.sortOrder - b.sortOrder
      );

      const newSortedOptionIds: string[] = prevSortedOptions.map((option) => {
        if (option.id === option1.id) {
          return option2.id;
        } else if (option.id === option2.id) {
          return option1.id;
        } else {
          return option.id;
        }
      });

      const input = {
        variables: {
          partnerId: partnerId,
          categoryId: optionsCategoryId,
          itemIds: newSortedOptionIds,
        },
      };

      reorderCategory(input);
    },
    [optionsCategoryId, categories, partnerId, reorderCategory]
  );

  const moveOptionUp = useCallback(
    async (itemModifier) => {
      const optionIndex = optionsData?.findIndex(
        (mi) => mi.id === itemModifier.id
      );
      if (optionIndex != null && optionIndex > 0) {
        // if first index then can't move up
        await swapModifiers(
          optionsData![optionIndex],
          optionsData![optionIndex - 1]
        );
      }
    },
    [optionsData, swapModifiers]
  );

  const moveOptionDown = useCallback(
    async (itemModifier) => {
      const optionIndex = optionsData?.findIndex(
        (mi) => mi.id === itemModifier.id
      );
      if (
        optionIndex != null &&
        optionIndex !== -1 &&
        optionIndex !== optionsData!.length - 1
      ) {
        // if last index then can't move down
        await swapModifiers(
          optionsData![optionIndex],
          optionsData![optionIndex + 1]
        );
      }
    },
    [optionsData, swapModifiers]
  );

  return (
    <>
      <SearchModifierItems
        show={showAddModifierModal}
        modifierItems={menuItems}
        initialModifierItemIds={value ? value.map((option) => option.id) : []}
        onHide={() => setShowAddModifierModal(false)}
        onSave={(selected) => {
          if (onChange) onChange(selected);
          setShowAddModifierModal(false);
        }}
        categories={categories}
        partnerId={partnerId}
      />
      {menuItems ? (
        <div style={{ position: "relative", float: "right", bottom: 10 }}>
          <Button
            className="add-existing-modifier-btn"
            type="primary"
            onClick={() => setShowAddModifierModal(true)}
          >
            <PlusCircleOutlined />
            Add Existing Modifier
          </Button>
          <Button
            type="primary"
            style={{ marginLeft: 10 }}
            onClick={createModifier}
          >
            <PlusCircleOutlined />
            Create Modifier
          </Button>
        </div>
      ) : null}
      <Table
        loading={menuItems == null}
        dataSource={optionsData}
        pagination={false}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
          },
          {
            title: "Description",
            dataIndex: "description",
          },
          {
            title: "Unit Price",
            dataIndex: "unitPrice",
            render: (unitPrice) => price(unitPrice),
          },
          {
            title: "Availability",
            width: 120,
            render: (_, option) => {
              return (
                <AvailabilitySelect
                  id={option.id}
                  published={option.published}
                  available={option.available}
                  partnerId={partnerId}
                  onChange={({ available, published }) => {
                    if (onChange && value)
                      onChange(
                        value.map((o) =>
                          o.id === option.id
                            ? {
                                ...option,
                                available,
                                published,
                              }
                            : o
                        )
                      );
                  }}
                />
              );
            },
          },
          // {
          //   width: 120,
          //   render: (option) => {
          //     return <table>
          //       <tbody>
          //         <tr>
          //           <td style={{width: 20}}>
          //             <Button icon={<UpOutlined />} onClick={async () => await moveOptionUp(option)}/>
          //           </td>
          //           <td style={{marginLeft: 10, width: 20}}>
          //             <Button icon={<DownOutlined />} onClick={async () => await moveOptionDown(option)}/>
          //           </td>
          //         </tr>
          //       </tbody>
          //     </table>
          //   }
          // },
          {
            width: 120,
            title: !optionsCategoryId ? (
              <Tooltip title="Cannot reorder when options belong to different categories.">
                <WarningTwoTone
                  style={{ float: "right" }}
                  twoToneColor="#f0ad4e"
                />
              </Tooltip>
            ) : undefined,
            render: (modifier) => {
              return (
                <table>
                  <tr>
                    <td style={{ width: 20 }}>
                      <Button
                        style={{ borderRadius: "20%" }}
                        icon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <UpOutlined />
                          </div>
                        }
                        disabled={!optionsCategoryId}
                        onClick={async () => await moveOptionUp(modifier)}
                      />
                    </td>
                    <td style={{ marginLeft: 10, width: 20 }}>
                      <Button
                        style={{ borderRadius: "20%" }}
                        disabled={!optionsCategoryId}
                        icon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <DownOutlined />
                          </div>
                        }
                        onClick={async () => await moveOptionDown(modifier)}
                      />
                    </td>
                  </tr>
                </table>
              );
            },
          },
          {
            width: 60,
            render: (option: ModifierOption) => {
              return (
                <Button
                  danger
                  style={{ borderRadius: "20%" }}
                  icon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MinusCircleOutlined />
                    </div>
                  }
                  onClick={() => {
                    if (onChange && value != null)
                      onChange(value.filter((o) => option.id !== o.id));
                  }}
                />
              );
            },
          },
        ]}
      />
    </>
  );
};

export default ModifierOptionsTable;
