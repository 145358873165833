import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Drawer, Input, Space, Table } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { $ } from "../../graphql/generated";
import { query, useTypedQuery } from "../../graphql/hooks";
import usePagination from "../../hooks/usePagination";
import DynamicForm from "../forms/DynamicForm";
import VehicleStatusBagde from "./VehicleStatusBadge";

const VEHICLE_TABLE = query({
  LeaseVehicleList: [
    {
      skip: $`skip`,
      take: $`take`,
      sortBy: $`sortBy`,
      order: $`order`,
      query: $`query`,
    },
    {
      total: true,
      items: {
        id: true,
        created: true,
        note: true,
        registration: true,
        status: true,
        dvlaInfo: true,
      },
    },
  ],
});

export default function LeaseVehicleTable({ riderId }: { riderId?: string }) {
  const {
    pagination,
    args: { order, page, query, sortBy, take },
    onChange,
    setQuery,
  } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "created",
  });

  const { data, refetch } = useTypedQuery(VEHICLE_TABLE, {
    fetchPolicy: "network-only",
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
    },
    pollInterval: 10000,
  });

  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<
    NonNullable<typeof data>["LeaseVehicleList"]["items"][0] | null
  >(null);

  const onComplete = useCallback(() => {
    refetch();
    setSelected(null);
    setShow(false);
  }, []);

  return (
    <>
      <Table
        title={() => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Title level={4} style={{ flex: 1 }}>
              {data?.LeaseVehicleList.total} Vehicles
            </Title>

            <Space>
              <Input
                placeholder="Search..."
                suffix={<SearchOutlined />}
                onChange={(t) => setQuery(t.target.value)}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setShow(true)}
              >
                Create Vehicle
              </Button>
            </Space>
          </div>
        )}
        onChange={(p, f, s) => {
          onChange(p, f, s);
        }}
        dataSource={data?.LeaseVehicleList.items}
        pagination={{
          total: data?.LeaseVehicleList.total,
          ...pagination,
        }}
        columns={[
          {
            title: "Reg",
            sorter: true,
            render: (_, r) => (
              <Link
                to={`/vehicle/${r.id}`}
                style={{
                  fontFamily: "monospace",
                }}
              >
                {r.registration}
              </Link>
            ),
          },
          {
            title: "Created",
            dataIndex: "created",
            sorter: true,
            render: (r) => new Date(r).toLocaleString(),
          },
          {
            title: "Status",
            sorter: true,
            dataIndex: "status",
            render: (_, r) => <VehicleStatusBagde status={r.status} />,
          },
        ]}
      />

      <Drawer
        width={window.innerWidth <= 768 ? "100%" : "50%"}
        visible={!!(show || selected)}
        onClose={useCallback(() => {
          setShow(false);
          setSelected(null);
        }, [])}
      >
        <DynamicForm
          key={selected?.id}
          id="LeaseVehicle"
          entityId={selected?.id}
          onComplete={onComplete}
          noCard
        />

        <pre>{JSON.stringify(selected, null, 2)}</pre>
      </Drawer>
    </>
  );
}
