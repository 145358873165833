import classNames from "classnames";
import { endOfDay, startOfDay } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  DatePicker,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";
import "../../assets/range-date-picker.css";

const RangeDatePicker = ({
  className,
  onChange,
  initialStartDate,
  initialEndDate,
  style,
  ...props
}: {
  className?: any;
  style: any;
  onChange: any;
  initialStartDate: any;
  initialEndDate: any;
  props?: any[];
}) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange({ startDate, endDate });
    }
  }, [startDate, endDate, onChange]);

  const classes = classNames(className, "d-flex", "my-auto", "date-range");

  return (
    <InputGroup className={classes} style={style} {...props}>
      <DatePicker
        size="sm"
        selected={startDate}
        dateFormat="dd/MM/yyyy"
        onChange={(v) => setStartDate(startOfDay(v))}
        placeholderText="Start Date"
        dropdownMode="select"
        className="text-center"
      />
      <DatePicker
        size="sm"
        selected={endDate}
        dateFormat="dd/MM/yyyy"
        onChange={(v) => setEndDate(endOfDay(v))}
        placeholderText="End Date"
        dropdownMode="select"
        className="text-center"
      />
      <InputGroupAddon type="append">
        <InputGroupText>
          <i className="material-icons">&#xE916;</i>
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default RangeDatePicker;
