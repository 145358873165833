import Cookies from "js-cookie";
import { API_ENDPOINTS } from "./state";

export const login = (state, payload) => {
  /*
    Save the login info to a cookie
  */
  if (payload.accessToken) {
    Cookies.set("login", payload.accessToken, {
      expires: new Date(Date.now() + payload.expiresIn * 1000),
      sameSite: "strict",
    });
  }

  return {
    ...state,
    login: {
      ...payload,
    },
  };
};

export const logout = (state) => {
  return {
    ...state,
    login: {
      ...state.login,
      isAuthenticated: false,
    },
  };
};

export const setApiUrl = (state, { env }) => ({
  ...state,
  api: {
    url: API_ENDPOINTS[env],
    env,
  },
});

export const setDarkMode = (state, { dark }) => ({
  ...state,
  dark,
});
