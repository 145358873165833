import Modal from "antd/lib/modal";
import React, { useEffect } from "react";
import { MenuCategory } from "../../graphql/generated";
import { AddCategoryForm } from "../forms/AddCategoryForm";

interface Props {
  visible: boolean;
  onCancel: () => void;
  onUpdateCategory: () => void;
  menu?: {
    id: string;
  };
  restaurant?: {
    id: string;
  };
  initialCategory?: Partial<MenuCategory>;
}

const AddCategoryModal = (props: Props) => {
  const {
    visible,
    onCancel,
    onUpdateCategory,
    initialCategory,
    restaurant,
    menu,
  } = props;
  useEffect(() => {
    console.log("mounting add category modal");
    return () => console.log("Add Category Model unmounting");
  }, []);
  return (
    <Modal
      title={initialCategory?.name ? initialCategory.name : "Add New Category"}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <AddCategoryForm
        key={
          `add_category_form_${initialCategory?.id}` ??
          `add_category_${Math.random()}`
        }
        restaurant={
          restaurant as {
            id: string;
          }
        }
        menu={menu}
        category={initialCategory}
        onSuccess={onUpdateCategory}
      />
    </Modal>
  );
};

export default AddCategoryModal;
