import { Alert, Button, PageHeader, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Container } from "shards-react";
import CommissionPlans from "../components/financials/commissions/CommissionPlans";
import CustomerWalletTopupTable from "../components/financials/CustomerWalletTopups";
import FinancialOverview from "../components/financials/FinancialOverview";
import { Financials } from "../components/financials/Financials";
import RestaurantInvoiceTable from "../components/financials/RestaurantInvoiceTable";
import RidersFinancialOverview from "../components/financials/RidersFinancialOverview";
import DynamicForm from "../components/forms/DynamicForm";
import LeaseTable from "../components/lease/LeaseTable";
import RiderInvoices from "../components/RiderInvoices";

export const Accounting = () => {
  const { tab } = useParams() as any;
  const history = useHistory();
  const match = useRouteMatch();
  const [key, setKey] = useState("partner-sales");

  const onSelect = (_tab) => {
    const path = generatePath(match.path, { tab: _tab });
    history.replace(path);
  };

  useEffect(() => {
    if (tab) setKey(tab);
  }, [tab]);

  return (
    <>
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <PageHeader title="Accounting" />

        <Tabs activeKey={key} onChange={onSelect}>
          <Tabs.TabPane key="partner-sales" tab="Partner Sales">
            {key === "partner-sales" && <Financials />}
          </Tabs.TabPane>
          <Tabs.TabPane key="partner-invoices" tab="Partner Invoices">
            {key === "partner-invoices" && <RestaurantInvoiceTable />}
          </Tabs.TabPane>
          <Tabs.TabPane key="riders" tab="Rider Earnings">
            {key === "riders" && <RidersFinancialOverview />}
          </Tabs.TabPane>
          <Tabs.TabPane key="rider-invoices" tab="Rider Invoices">
            {key === "rider-invoices" && <RiderInvoices />}
          </Tabs.TabPane>
          <Tabs.TabPane key="charity" tab="Charity">
            {key === "charity" && <FinancialOverview />}
          </Tabs.TabPane>
          <Tabs.TabPane key="plans" tab="Commission Plans">
            {key === "plans" && <CommissionPlans />}
          </Tabs.TabPane>
          <Tabs.TabPane key="leases" tab="Leases">
            {key === "leases" && <LeaseTable />}
          </Tabs.TabPane>
          <Tabs.TabPane key="topups" tab="Topups">
            {key === "topups" && <CustomerWalletTopupTable />}
          </Tabs.TabPane>
          <Tabs.TabPane key="export" tab="Export">
            <>
              <Alert
                message="Completed exports can be found in the Files tab"
                action={
                  <Button href="/media/files" target="_blank">
                    View Files
                  </Button>
                }
              />
              <DynamicForm id="FinancialsExportServiceForm" />
            </>
          </Tabs.TabPane>
        </Tabs>
      </Container>
    </>
  );
};
