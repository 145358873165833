import {
  Checkbox,
  DatePicker as AntDatePicker,
  Form,
  Input,
  Select,
  Space,
  Switch,
} from "antd";
import moment from "moment";
import React from "react";
import {
  DatePicker,
  FormCheckbox,
  FormGroup,
  FormInput,
  FormSelect,
} from "shards-react";
import * as Types from "../graphql/generated/graphql-zeus";

const camel2title = (camelCase): string =>
  !camelCase
    ? ""
    : camelCase
        .replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase());

const GQL_TYPE_MAP = {
  Int: "number",
  Float: "number",
};

export interface MapMetaFields<C, V> {
  exclude;
}

/**
 * V should be the typescript definition of the concrete value.
 *
 * @param concrete A concrete runtime-accessible value containing fields.
 *
 */
export function getMapInputFields<C, V>(
  concrete: C,
  conditions: {
    [P in keyof Partial<C>]: (value: C[P], formData: Partial<V>) => boolean;
  } = {} as any,
  exclude: Array<keyof C> = []
) {
  const inputs = concrete
    ? Object.entries(concrete).filter(([k]) => !exclude.includes(k as any))
    : [];

  return {
    exclude,
    conditions,
    inputs,
  };
}

/**
 * Takes a GraphQL input type and creates a control from it.
 */
export function mapInputToControl<T>(props: {
  type: string;
  required: boolean;
  array?: boolean;
  name: string;
  caption?: string;
  error?: string;
  price?: boolean;
  onChange: any;
  formData: T;
}) {
  const PropType = Types[props.type];

  return (
    <FormGroup>
      <label>
        {camel2title(props.name)}{" "}
        {props.required && <sup style={{ color: "red" }}>*</sup>}{" "}
        {props.error && <span style={{ color: "red" }}>Error!</span>}
        {props.price && (
          <span>£{((props.formData[props.name] || 0) / 100).toFixed(2)}</span>
        )}
      </label>
      <span>{props.caption}</span>
      {props.type === "Date" ? (
        <div>
          <DatePicker
            size="sm"
            style={{
              width: "100%",
            }}
            name={props.name}
            selected={props.formData[props.name] ?? new Date()}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => {
              props.onChange({
                currentTarget: {
                  name: props.name,
                  value: new Date(date).getTime(),
                },
              });
            }}
            placeholderText="Start Date"
            dropdownMode="select"
            className="text-center"
          />
        </div>
      ) : props.type === "Boolean" ? (
        <FormCheckbox
          type="switch"
          className="mb-1"
          value={props.formData[props.name]}
          name={props.name}
          onChange={props.onChange}
        >
          {props.caption ?? props.name + "?"}
        </FormCheckbox>
      ) : PropType ? (
        props.array ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            {Object.entries(PropType).map(([k, v]: [string, string]) => (
              <FormCheckbox
                checked={
                  props.formData[props.name]?.includes &&
                  props.formData[props.name].includes(v)
                }
                onChange={(e) => {
                  let val;
                  if (props.formData[props.name].includes(v))
                    val = props.formData[props.name].filter((x) => x !== v);
                  else val = [...props.formData[props.name], v];

                  props.onChange({
                    currentTarget: { name: props.name, value: val },
                  });
                }}
              >
                {k}
              </FormCheckbox>
            ))}
          </div>
        ) : (
          <FormSelect
            name={props.name}
            value={props.formData[props.name]}
            onChange={props.onChange}
          >
            <option value={undefined}>Select...</option>
            {Object.keys(Types[props.type]).map((ek) => (
              <option key={ek} value={ek}>
                {ek}
              </option>
            ))}
          </FormSelect>
        )
      ) : (
        <FormInput
          name={props.name}
          type={GQL_TYPE_MAP[props.type] ?? "text"}
          value={props.formData[props.name]}
          onChange={props.onChange}
        />
      )}
    </FormGroup>
  );
}

const GQL_NORMALISE_MAP = {
  Int: (v) => Number.parseInt(v, 10),
  Float: (v) => Number.parseFloat(v),
};

/**
 * Takes a GraphQL input type and creates a control from it.
 *
 * Uses AntD
 */
export function ZeusInputElement<T>(props: {
  type: string;
  required: boolean;
  array?: boolean;
  name: string;
  caption?: string;
  error?: string;
  price?: boolean;
  formData: T;
}) {
  const PropType = Types[props.type];

  return (
    <Form.Item
      name={props.name}
      normalize={GQL_NORMALISE_MAP[props.type]}
      rules={[{ required: props.required }]}
      label={
        <span>
          {camel2title(props.name)}{" "}
          {props.error && <span style={{ color: "red" }}>Error!</span>}
          {props.price && (
            <span>£{((props.formData[props.name] || 0) / 100).toFixed(2)}</span>
          )}
        </span>
      }
      help={props.caption}
    >
      {props.type === "Date" ? (
        <AntDatePicker defaultValue={moment(props.formData[props.name])} />
      ) : props.type === "Boolean" ? (
        <Switch
          defaultChecked={props.formData[props.name]}
          title={props.caption ?? props.name + "?"}
        />
      ) : PropType ? (
        props.array ? (
          <Space>
            {Object.entries(PropType).map(([k, v]) => (
              <Checkbox
                key={k}
                defaultChecked={
                  props.formData[props.name]?.includes &&
                  props.formData[props.name].includes(v)
                }
              />
            ))}
          </Space>
        ) : (
          <Select defaultValue={props.formData[props.name]}>
            <Select.Option value={""}>Select...</Select.Option>
            {Object.keys(Types[props.type]).map((ek) => (
              <Select.Option key={ek} value={ek}>
                {ek}
              </Select.Option>
            ))}
          </Select>
        )
      ) : (
        <Input
          name={props.name}
          type={GQL_TYPE_MAP[props.type] ?? "text"}
          defaultValue={props.formData[props.name]}
        />
      )}
    </Form.Item>
  );
}
