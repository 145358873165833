import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Descriptions,
  message,
  Progress,
  Result,
  Row,
  Switch,
} from "antd";
import React, { useState } from "react";
import { $ } from "../../graphql/generated";
import { useTypedMutation, useTypedQuery } from "../../graphql/hooks";
import { getImageUrl } from "../../utils/image-url";
import { useRider } from "../../views/rider/Rider";

type BoundingBoxType = {
  Width: number;
  Height: number;
  Left: number;
  Top: number;
};

function BoundingBoxImage({
  url,
  boundingBox,
}: {
  url: string;
  boundingBox?: BoundingBoxType;
}) {
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <img
        src={url}
        style={{
          maxHeight: 400,
        }}
      />

      {boundingBox ? (
        <div
          style={{
            position: "absolute",
            border: "2px solid rgba(0,0,0,0.8)",
            borderRadius: 8,
            zIndex: 99,
            width: `${boundingBox.Width * 100}%`,
            height: `${boundingBox.Height * 100}%`,
            top: `${boundingBox.Top * 100}%`,
            left: `${boundingBox.Left * 100}%`,
          }}
        />
      ) : null}
    </div>
  );
}

export default function RiderApplication() {
  const rider = useRider();

  const { data } = useTypedQuery(
    {
      Rider: [
        {
          id: $`id`,
        },
        {
          id: true,
          profileImageKey: true,
          application: {
            id: true,
            documentType: true,
            imageUrl: true,
            rekognitionLabels: true,
            compareFaces: true,
          },
        },
      ],
    },
    { variables: { id: rider.data?.Rider.id } }
  );

  const application = data?.Rider.application;

  const [deleteApplication, { loading: deleting }] = useTypedMutation(
    {
      deleteRiderApplication: [
        {
          id: $`id`,
        },
        true,
      ],
    },
    {
      onCompleted: (d) =>
        d.deleteRiderApplication
          ? message.success("Deleted!")
          : message.error("Failed to delete."),
      variables: { id: application?.id },
    }
  );

  const [showBoundingBox, setShowBoundingBox] = useState(true);

  const documentBoundingBox:
    | {
        Width: number;
        Height: number;
        Left: number;
        Top: number;
      }
    | undefined = showBoundingBox
    ? application?.compareFaces?.FaceMatches?.find((f) => f.Face)?.Face
        .BoundingBox
    : undefined;

  const profileBoundingBox:
    | {
        Width: number;
        Height: number;
        Left: number;
        Top: number;
      }
    | undefined = showBoundingBox
    ? application?.compareFaces?.SourceImageFace?.BoundingBox
    : undefined;

  return (
    <div>
      {!application ? (
        <Result status="404" />
      ) : (
        <div>
          <Descriptions bordered>
            <Descriptions.Item label="Type">
              {application.documentType}
            </Descriptions.Item>
            <Descriptions.Item label="Delete">
              <Button
                onClick={() => deleteApplication()}
                danger
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </Descriptions.Item>
          </Descriptions>

          <Row gutter={32} style={{ marginTop: 16, marginBottom: 16 }}>
            <Col span={12}>
              <Card title="Document">
                <BoundingBoxImage
                  url={application.imageUrl}
                  boundingBox={documentBoundingBox}
                />
              </Card>
            </Col>

            <Col span={12}>
              <Card title="Profile Picture">
                {data?.Rider.profileImageKey ? (
                  <BoundingBoxImage
                    url={getImageUrl(data.Rider.profileImageKey)}
                    boundingBox={profileBoundingBox}
                  />
                ) : (
                  <Result status="404" title="No Profile Image" />
                )}
              </Card>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col span={12}>
              <Card title="Image Labels">
                <pre>
                  {JSON.stringify(application.rekognitionLabels, null, 2)}
                </pre>
              </Card>
            </Col>
            <Col span={12}>
              <Card title="Facial Comparison">
                {application.compareFaces ? (
                  <Descriptions bordered column={1}>
                    <Descriptions.Item label="Similarity Percentage">
                      <Progress
                        percent={Math.round(
                          application.compareFaces.FaceMatches?.find(
                            (f) => f.Similarity
                          )?.Similarity || 0
                        )}
                        strokeColor="green"
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Show Bounding Box">
                      <Switch
                        checked={showBoundingBox}
                        onChange={(v) => setShowBoundingBox(v)}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                ) : null}
                <pre>{JSON.stringify(application.compareFaces, null, 2)}</pre>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
