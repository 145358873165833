import { useApolloClient } from "@apollo/client";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink,
} from "shards-react";
import { style } from "typestyle";
import { useTypedMutation, useTypedQuery } from "../../../../graphql/hooks";
import { LOGOUT } from "../../../../graphql/mutations";
import { ME } from "../../../../graphql/queries";

export const UserActions = () => {
  const [visible, setVisible] = useState(false);
  const { data } = useTypedQuery(ME, {
    pollInterval: 15000,
  });
  const client = useApolloClient();
  const [logout, { loading }] = useTypedMutation(LOGOUT);
  const history = useHistory();

  const me = data && "name" in data.me ? data.me : null;

  return (
    <NavItem tag={Dropdown} caret toggle={() => setVisible(!visible)}>
      <DropdownToggle caret tag={NavLink} className={styles.dropdown}>
        <span className="">{me?.name}</span>
      </DropdownToggle>
      <Collapse tag={DropdownMenu} right small open={visible}>
        <DropdownItem
          onClick={() => logout().then(() => history.push("/login"))}
          className="text-danger"
        >
          <i className="material-icons text-danger">&#xE879;</i> Logout
        </DropdownItem>
      </Collapse>
    </NavItem>
  );
};

const styles = {
  dropdown: style({
    height: "100%",
    marginLeft: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
};
