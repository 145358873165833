import { PageHeader } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { $ } from "../graphql/generated";
import { useTypedQuery } from "../graphql/hooks";
import { CONFIG } from "../graphql/queries";
import { useImageURL } from "../utils/image-url";

export default function Configurations() {
  const { id } = useParams<{ id: string }>();

  const { data } = useTypedQuery(
    {
      Configuration: {
        ...CONFIG.Configuration,
        coreSettings: [
          {
            id: $`id`,
          },
          {
            id: true,
            displayName: true,
            enableMultiPartnerOrders: true,
            fulfillmentMethods: true,
            url: true,
            theme: {
              font: true,
              colors: {
                PRIMARY: true,
                SECONDARY: true,
                DARK: true,
                TEXT: true,
              },
            },
          },
        ],
      },
    },
    {
      skip: !id,
      variables: {
        id,
      },
    }
  );

  const avatarUrl = useImageURL(
    `assets/app-icons/${id.toLowerCase()}.png`,
    128,
    128
  );

  return (
    <PageHeader
      avatar={{
        size: 64,
        src: avatarUrl,
      }}
      title={data?.Configuration.coreSettings.displayName}
    >
      <pre>{JSON.stringify(data?.Configuration.coreSettings, null, 2)}</pre>
    </PageHeader>
  );
}
