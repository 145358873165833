import { useStore } from "../state/zustand";

export const IMAGE_RESTAURANT_BACKGROUND =
  "assets/restaurants/default-background.jpg";

export const getImageUrl = (
  key: string,
  width?: number,
  height?: number,
  mode: "fill" | "fit" = "fill",
  url?: string
) =>
  `${url || useStore.getState().apiConfig.imgProxyURL}/i/${
    width && height ? `resize:${mode}:${width}:${height}:0/` : ""
  }gravity:ce/plain/${key}`;

export const getCDNUrl = (key: string) =>
  `${useStore.getState().apiConfig.cdnURL}/${key}`;

export const useImageURL = (
  key: string,
  width?: number,
  height?: number,
  mode: "fill" | "fit" = "fill"
) =>
  getImageUrl(
    key,
    width,
    height,
    mode,
    useStore((s) => s.apiConfig.imgProxyURL)
  );
