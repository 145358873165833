import {
  AlertOutlined,
  CarFilled,
  DeleteOutlined,
  LockFilled,
  ReloadOutlined,
  RobotFilled,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Descriptions,
  message,
  PageHeader,
  Popconfirm,
  Progress,
  Row,
  Select,
  Slider,
  Switch,
  Table,
  Tag,
  Tooltip,
} from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import TitlePlaceholder from "antd/lib/skeleton/Title";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import {
  differenceInMilliseconds,
  formatDistanceToNow,
  isPast,
} from "date-fns";
import { LatLngTuple } from "leaflet";
import moment from "moment";
import React, { useCallback, useRef, useState } from "react";
import { Marker, Polygon, Popup } from "react-leaflet";
import { Link, useHistory, useParams } from "react-router-dom";
import { Container } from "shards-react";
import NiceSpin from "../components/common/NiceSpin";
import FutureDateBadge from "../components/FutureDateBadge";
import RiderName from "../components/rider/RiderName";
import {
  $,
  BookingSlotType,
  BookingStatus,
  MapType,
  Query,
  UpdateBookingSlotArgs,
} from "../graphql/generated";
import { query, useTypedMutation, useTypedQuery } from "../graphql/hooks";
import Map from "../utils/Map";
import { price } from "../utils/price";
import { getCenterPolygon } from "../utils/sphere";
import { LRiderIcon } from "./Order";

const BOOKING_SLOT_VIEW = query({
  BookingSlot: [
    {
      id: $`id`,
    },
    {
      id: true,
      endTime: true,
      startTime: true,
      bookings: [
        {
          includeCancelled: $`includeCancelled`,
        },
        {
          id: true,
          createdAt: true,
          percentageInZone: true,

          rider: {
            id: true,
            name: true,
            email: true,
            vehicle: true,
            online: true,
            lastOnline: true,
            position: {
              type: true,
              coordinates: true,
            },
          },
          stats: {
            id: true,
            ordersAccepted: true,
            onlinePercentage: true,
            guaranteedRiderEarnings: true,
            orderRiderEarnings: true,
            riderOwedExtra: true,
            slotType: true,
            slotStart: true,
            slotEnd: true,
            ordersOffered: true,
            sessionSpans: true,
            attended: true,
          },
          lateCancellation: true,
          status: true,
        },
      ],
      guaranteedHourlyPay: true,
      maxRiders: true,
      percentage: true,
      autoIncreaseRiderPercentage: true,
      carOnly: true,
      zoneId: true,
      capHourlyPay: true,
      slotType: true,
      zone: {
        id: true,
        polygon: {
          type: true,
          coordinates: true,
        },
        name: true,
      },
    },
  ],
});

type BookingView = MapType<
  Query,
  typeof BOOKING_SLOT_VIEW
>["BookingSlot"]["bookings"][0];

const BookingSlotView = () => {
  const { id, tab } = useParams() as any;
  const history = useHistory();
  const [includeCancelled, setIncludeCancelled] = useState(false);
  const { data, refetch, updateQuery, loading } = useTypedQuery(
    BOOKING_SLOT_VIEW,
    {
      variables: {
        id,
        includeCancelled,
      },
      pollInterval: 10000,
      fetchPolicy: "cache-and-network",
    }
  );

  const [cancelBooking, { loading: cancelling }] = useTypedMutation(
    {
      cancelBooking: [
        {
          id: $`id`,
        },
        {
          id: true,
          status: true,
        },
      ],
    },
    {
      onCompleted: () => {
        message.success("Cancelled booking!");
        refetch();
      },
    }
  );

  const [updateSlot, { loading: updating }] = useTypedMutation(
    {
      updateBookingSlot: [
        {
          id: $`id`,
          slot: $`slot`,
        },
        {
          id: true,
          startTime: true,
          endTime: true,
          slotType: true,
          guaranteedHourlyPay: true,
          autoIncreaseRiderPercentage: true,
          percentage: true,
          maxRiders: true,
          carOnly: true,
        },
      ],
    },
    {
      onError: (e) => message.error(e.message),
      onCompleted: (d) => {
        message.success("Saved!");
        updateQuery((p) => ({
          BookingSlot: {
            ...p.BookingSlot,
            ...d.updateBookingSlot,
          },
        }));
      },
    }
  );

  const doUpdateSlot = useCallback(
    function <T extends keyof UpdateBookingSlotArgs>(
      key: T,
      value: UpdateBookingSlotArgs[T]
    ) {
      updateSlot({
        variables: {
          id,
          slot: {
            [key]: value,
          },
        },
      });
    },
    [updateSlot, id]
  );

  const [deleteSlot, { loading: deletingSlot }] = useTypedMutation(
    {
      deleteBookingSlot: [
        {
          id: $`id`,
        },
        true,
      ],
    },
    {
      onCompleted: () => {
        history.push(`/zone/${data?.BookingSlot.zone.id}/bookings`);
        message.success("Deleted slot!");
      },
      onError: (m) => message.error(m.message),
      variables: {
        id,
      },
    }
  );

  const [decideBooking] = useTypedMutation(
    {
      decideBooking: [
        {
          bookingId: $`bookingId`,
        },
        {
          ...BOOKING_SLOT_VIEW["BookingSlot"]["1"]["bookings"]["1"],
        },
      ],
    },
    {
      onCompleted: () => message.success("Decided!"),
      onError: () => message.error("Failed."),
    }
  );

  const timeoutRef = useRef(-1 as any);
  const debounce = useCallback(
    (e) => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => doUpdateSlot("percentage", e), 500);
    },
    [doUpdateSlot]
  );

  const start = new Date(data?.BookingSlot.startTime);

  const riders = data?.BookingSlot.bookings.map((b) => b.rider);

  const mapRef = useRef<any | null>(null);
  const polygonRef = useRef<Polygon | null>(null);

  const fitPolygon = useCallback(() => {
    if (polygonRef.current) {
      mapRef.current?.leafletElement.fitBounds(
        polygonRef.current?.leafletElement.getBounds()
      );
    }
  }, []);

  const startTime = new Date(data?.BookingSlot.startTime),
    endTime = new Date(data?.BookingSlot.endTime);

  return (
    <>
      <NiceSpin spinning={loading && !data}>
        <div style={{ height: "300px" }}>
          {data && (
            <Map
              ref={mapRef}
              center={
                getCenterPolygon(
                  data.BookingSlot.zone.polygon.coordinates[0]
                ) as LatLngTuple
              }
              whenReady={fitPolygon}
              zoom={13}
              style={{ height: "100%" }}
            >
              <Polygon
                ref={polygonRef}
                positions={data.BookingSlot.zone.polygon.coordinates[0]}
              />
              {riders?.map((r) =>
                r.position ? (
                  <Marker
                    icon={LRiderIcon(r.vehicle)}
                    position={r.position?.coordinates}
                  >
                    <Popup>{r.name}</Popup>
                  </Marker>
                ) : null
              )}
            </Map>
          )}
        </div>
      </NiceSpin>
      <Container fluid className="main-content-container px-4">
        <PageHeader
          title={
            data ? (
              `${new Date(
                data?.BookingSlot.startTime
              ).toLocaleDateString()}, ${new Date(
                data?.BookingSlot.startTime
              ).toLocaleTimeString()} - ${new Date(
                data?.BookingSlot.endTime
              ).toLocaleTimeString()}`
            ) : (
              <TitlePlaceholder />
            )
          }
          subTitle={
            <Link to={`/zone/${data?.BookingSlot.zoneId}`}>
              {" "}
              {data?.BookingSlot.zone.name}
            </Link>
          }
          onBack={() => {
            history.push(`/zone/${data?.BookingSlot.zone.id}/bookings`);
          }}
          extra={[
            <div>
              {data && (
                <Progress
                  percent={
                    +(
                      (100 * differenceInMilliseconds(new Date(), startTime)) /
                      differenceInMilliseconds(endTime, startTime)
                    ).toFixed(1)
                  }
                />
              )}
            </div>,
            data && (
              <FutureDateBadge
                start={data.BookingSlot.startTime}
                end={data.BookingSlot.endTime}
                verbose
              />
            ),
            <Popconfirm
              disabled={isPast(start)}
              onConfirm={() => deleteSlot()}
              title="This will cancel the booking slot and un-book all riders"
            >
              <Button
                danger
                icon={<DeleteOutlined />}
                disabled={isPast(start)}
                loading={deletingSlot}
              >
                Delete
              </Button>
            </Popconfirm>,
          ]}
        >
          <Descriptions bordered>
            <DescriptionsItem label="Riders">
              <Badge
                status={
                  data?.BookingSlot.bookings.length ===
                  data?.BookingSlot.maxRiders
                    ? "success"
                    : "warning"
                }
              />
              {data?.BookingSlot.bookings.length} /{" "}
              <Text
                editable={{
                  onChange: (v) =>
                    doUpdateSlot("maxRiders", Number.parseInt(v, 10)),
                }}
              >
                {data?.BookingSlot.maxRiders}
              </Text>
            </DescriptionsItem>
            <DescriptionsItem label="Hourly Pay">
              £
              {data ? (
                <Text
                  editable={{
                    maxLength: 30,
                    onChange: (e) =>
                      doUpdateSlot("guaranteedHourlyPay", Number.parseInt(e)),
                  }}
                >
                  {(data?.BookingSlot.guaranteedHourlyPay / 100 || 0).toFixed(
                    2
                  )}
                </Text>
              ) : null}
            </DescriptionsItem>

            <DescriptionsItem label="Type">
              {" "}
              <Select
                value={data?.BookingSlot.slotType}
                loading={updating}
                onChange={(v) => doUpdateSlot("slotType", v)}
                options={Object.keys(BookingSlotType).map((k) => ({
                  label: k,
                  value: k,
                }))}
              ></Select>
            </DescriptionsItem>
            <DescriptionsItem label="Percentage of Riders" span={12}>
              {data && (
                <Slider
                  min={0}
                  tipFormatter={(n) => `${n}%`}
                  max={100}
                  defaultValue={data?.BookingSlot.percentage}
                  onChange={debounce}
                />
              )}
            </DescriptionsItem>

            <DescriptionsItem label="Car Only">
              {data && (
                <Switch
                  defaultChecked={data?.BookingSlot.carOnly}
                  checkedChildren={<CarFilled />}
                  onChange={(e) => doUpdateSlot("carOnly", e)}
                />
              )}
            </DescriptionsItem>

            <DescriptionsItem label="AutoManage">
              {data && (
                <Switch
                  defaultChecked={data?.BookingSlot.autoIncreaseRiderPercentage}
                  checkedChildren={<RobotFilled />}
                  unCheckedChildren={<LockFilled />}
                  onChange={(e) =>
                    doUpdateSlot("autoIncreaseRiderPercentage", e)
                  }
                />
              )}
            </DescriptionsItem>

            <DescriptionsItem label="Fix Hourly Pay">
              {data && (
                <Tooltip title="Always pay the rider the guaranteed pay, no more and no less.">
                  <Switch
                    defaultChecked={data?.BookingSlot.capHourlyPay}
                    onChange={(e) => doUpdateSlot("capHourlyPay", e)}
                  />
                </Tooltip>
              )}
            </DescriptionsItem>
            <DescriptionsItem label="Time">
              <DatePicker.RangePicker
                value={[
                  moment(data?.BookingSlot.startTime),
                  moment(data?.BookingSlot.endTime),
                ]}
                showHour
                showMinute
                showTime
                showSecond={false}
                allowClear={false}
                allowEmpty={[false, false]}
                onChange={(e) => {
                  updateSlot({
                    variables: {
                      id,
                      slot: {
                        startTime: e?.[0]?.toDate(),
                        endTime: e?.[1]?.toDate(),
                      },
                    },
                  });
                }}
              />
            </DescriptionsItem>
          </Descriptions>
        </PageHeader>

        <Table
          loading={loading && !data}
          dataSource={data?.BookingSlot.bookings}
          title={(p) => (
            <Row>
              <Col flex={1}>
                <Title level={5}>
                  {data?.BookingSlot.bookings.length} Bookings
                </Title>
              </Col>
              <Col>
                <div>
                  <Switch
                    checked={includeCancelled}
                    onChange={(v) => setIncludeCancelled(v)}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      verticalAlign: "middle",
                    }}
                  >
                    Show Cancelled Bookings?
                  </Text>
                </div>
              </Col>
            </Row>
          )}
          rowKey="id"
          expandable={{
            expandedRowRender: (record, i, ii, expanded) =>
              expanded ? <pre>{JSON.stringify(record, null, 2)}</pre> : null,
            defaultExpandAllRows: false,
          }}
          columns={[
            {
              title: "Rider",
              dataIndex: "rider",
              render: (r) => (
                <RiderName id={r.id} name={r.name} vehicle={r.vehicle} />
              ),
            },
            {
              title: "Created",
              dataIndex: "createdAt",
              render: (r) => new Date(r).toLocaleString(),
            },
            {
              title: "Status",
              render: (r: BookingView) => (
                <Badge
                  text={
                    <span>
                      {r.status === "PENDING" ? "BOOKED" : r.status}{" "}
                      {r.lateCancellation ? (
                        <Tag color="red" icon={<AlertOutlined />}>
                          Late
                        </Tag>
                      ) : null}
                      {r.status !== "CANCELLED" ? (
                        <Tooltip title="Ask the algorithm to decide if the booking should be a different status now.">
                          {" "}
                          <Button
                            type="text"
                            icon={<ReloadOutlined />}
                            onClick={() =>
                              decideBooking({ variables: { bookingId: r.id } })
                            }
                          ></Button>
                        </Tooltip>
                      ) : null}
                    </span>
                  }
                  status={
                    r.status === BookingStatus.ATTENDED
                      ? "success"
                      : r.status === BookingStatus.PARTIALLY_ATTENDED
                      ? "warning"
                      : r.status === BookingStatus.NOT_ATTENDED
                      ? "error"
                      : r.status === BookingStatus.PENDING
                      ? "processing"
                      : "default"
                  }
                />
              ),
            },
            {
              title: "Online Time",
              render: (r: BookingView) => (
                <>
                  <Badge
                    status={r.rider.online ? "success" : "warning"}
                    text={
                      r.rider.online
                        ? "Online"
                        : r.rider.lastOnline
                        ? `${formatDistanceToNow(new Date(r.rider.lastOnline), {
                            addSuffix: true,
                          })}`
                        : "Offline"
                    }
                  />
                  <Progress
                    percent={
                      Math.round((r.stats?.onlinePercentage || 0) * 100) / 100
                    }
                    status={r.status === "PENDING" ? "active" : "normal"}
                  />
                </>
              ),
            },
            {
              title: "Time In Zone",
              render: (_, r) => (
                <>
                  <Progress
                    percent={Math.round((r.percentageInZone || 0) * 100) / 100}
                    status={r.status === "PENDING" ? "active" : "normal"}
                  />
                </>
              ),
            },
            {
              title: "Earnings",
              render: (r: BookingView) =>
                r.stats ? (
                  <Tooltip
                    title={`We'll pay the rider ${price(
                      r.stats.riderOwedExtra || 0
                    )} extra.`}
                  >
                    <Text
                      type={
                        r.stats.orderRiderEarnings >=
                        r.stats.guaranteedRiderEarnings
                          ? "success"
                          : "warning"
                      }
                    >
                      {price(r.stats.orderRiderEarnings)}/
                      {price(r.stats.guaranteedRiderEarnings)}
                    </Text>
                  </Tooltip>
                ) : (
                  <Text type="secondary">£0.00</Text>
                ),
            },
            {
              title: "Accepted",
              render: (r: BookingView) =>
                r.stats ? (
                  <>
                    <Text
                      type={
                        r.stats.ordersAccepted === r.stats.ordersOffered
                          ? "success"
                          : "warning"
                      }
                    >
                      {r.stats.ordersAccepted} / {r.stats.ordersOffered}{" "}
                    </Text>
                  </>
                ) : null,
            },
            {
              title: "",
              render: (r) =>
                r.status !== BookingStatus.CANCELLED ? (
                  <Popconfirm
                    title="Are you sure you want to cancel this booking?"
                    cancelText="No"
                    okType="danger"
                    okText="Cancel Booking"
                    onConfirm={cancelBooking.bind(null, {
                      variables: {
                        id: r.id,
                      },
                    })}
                  >
                    <Button type="text" danger>
                      Cancel
                    </Button>
                  </Popconfirm>
                ) : (
                  <Button type="text" disabled>
                    Cancelled
                  </Button>
                ),
            },
          ]}
        />
      </Container>
    </>
  );
};

export default BookingSlotView;
