import { Button, Drawer, Table } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { $ } from "../../graphql/generated";
import { useTypedQuery } from "../../graphql/hooks";

export default function SitePages({ id }: { id: string }) {
  const { data } = useTypedQuery(
    {
      MerchantSite: [
        {
          id: $`id`,
        },
        {
          id: true,
          name: true,
          status: true,
          pages: {
            id: true,
            title: true,
            slug: true,
            layout: true,
          },
        },
      ],
    },
    {
      variables: {
        id,
      },
    }
  );

  const [selected, setSelected] = useState<any>(null);

  return (
    <>
      <Table
        title={() => (
          <Title level={5}>{data?.MerchantSite.pages.length} Pages</Title>
        )}
        dataSource={data?.MerchantSite.pages}
        columns={[
          {
            title: "Title",
            dataIndex: "title",
          },
          {
            title: "Slug",
            dataIndex: "slug",
          },
          {
            render: (_, r) => (
              <Button onClick={() => setSelected(r)}>View</Button>
            ),
          },
        ]}
      />
      <Drawer
        width={768}
        visible={!!selected}
        onClose={() => setSelected(null)}
      >
        {selected && (
          <SyntaxHighlighter language="json" style={docco}>
            {JSON.stringify(selected?.layout, null, 2)}
          </SyntaxHighlighter>
        )}
      </Drawer>
    </>
  );
}
