import { CheckCircleOutlined, PoundCircleFilled } from "@ant-design/icons";
import { Button, message, Popconfirm } from "antd";
import React from "react";
import { $ } from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";

export default function PayPartnerInvoiceButton({ id }) {
  const [payInvoice, { loading: paying }] = useTypedMutation(
    {
      payPartnerInvoice: [
        {
          id: $`id`,
          manual: $`manual`,
        },
        {
          id: true,
          paid: true,
          revolutTransactionLink: true,
        },
      ],
    },
    {
      onCompleted: (d) => {
        message.success("Paid Invoice!");
      },
      onError: (m) => {
        message.error(m.message || "Failed to pay invoice");
      },
    }
  );

  return (
    <Popconfirm
      title="Are you sure you want to pay this invoice?"
      onConfirm={payInvoice.bind(null, {
        variables: {
          id,
        },
      })}
      okButtonProps={{
        icon: <PoundCircleFilled />,
      }}
      okText="Pay via Revolut"
      cancelText="Already paid"
      onCancel={() => payInvoice({ variables: { id, manual: true } })}
    >
      <Button loading={paying} icon={<CheckCircleOutlined />}>
        Pay Now
      </Button>
    </Popconfirm>
  );
}
