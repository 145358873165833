import { InfoCircleFilled } from "@ant-design/icons";
import React from "react";
import TagWithIcon from "./TagWithIcon";

interface Props {
  children: string;
}

const InfoTag = (props: Props) => {
  const { children } = props;
  return (
    <TagWithIcon color="processing" icon={<InfoCircleFilled />}>
      {children}
    </TagWithIcon>
  );
};

export default InfoTag;
