import { DeleteFilled } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Input,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Radio,
  Select,
} from "antd";
import Form from "antd/lib/form";
import { ModalFuncProps } from "antd/lib/modal";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as CurrencyFormat from "react-currency-format";
import { useLocation } from "react-router-dom";
import {
  FulfillmentMethod,
  ItemCreateInput,
  ItemHeatType,
  MapType,
  Mutation,
  Query,
  Item,
} from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";
import {
  ADD_ITEM,
  DELETE_ITEM,
  REMOVE_ITEM_HEADER,
} from "../../graphql/mutations";
import { RESTAURANT } from "../../graphql/queries";
import { CURRENCY_SYMBOLS, parseCurrency } from "../../utils/price";
import ItemModifierGroupsTable from "../menu/ItemModifierGroupsTable";
import WeightFormItem from "../menu/WeightFormItem";
import { MenuContextType, useSelectedMenu } from "../RestaurantMenu";
import {
  CONTAINER_TYPE_TABLE,
  SelectContainerTypeButton,
} from "../reusables/ReusableContainerTypeTable";
import UploadImage from "./UploadImage";

const FULFILLMENT_OPTIONS = Object.keys(FulfillmentMethod).map((k) => ({
  value: k,
  label: k
    .split("_")
    .map((s) => s[0] + s.slice(1).toLowerCase())
    .join(" "),
}));

const HEAT_OPTIONS = [
  {
    value: ItemHeatType.HOT,
    label: "Hot",
  },
  {
    value: ItemHeatType.COLD,
    label: "Cold",
  },
];

const LABEL_COL_SPAN = 4;

const ITEM_CREATED_MSG = "Item created sucessfully!";

const DEFAULT_AVAILABLE = true;

type AddItemReturn = MapType<Mutation, typeof ADD_ITEM>["addItem"][0];

const defaultItemValues = {
  available: true,
  categoryId: "",
  modifierIds: [],
  name: "",
  published: true,
  unitPrice: 0,
};

type FormValues = {
  categoryId?: string;
  name?: string;
  description?: string;
  headerImageUpload?: File | null;
  ingredients?: string;
  heat?: ItemHeatType.HOT | ItemHeatType.COLD;
  price?: string | number;
  alcohol?: boolean;
  vegan?: boolean;
  weight?: number;
  vegitarian?: boolean;
  containerTypeId?: string;
  unavailableFor?: FulfillmentMethod[];
  modifierGroups?: ModifierGroups;
};

type ContainerType = MapType<
  Query,
  typeof CONTAINER_TYPE_TABLE
>["ReusableContainerTypeList"]["items"][0];

type ModifierGroups = MenuContextType["menu"]["modifierGroups"];

export type InitialItem = Partial<{
  id: string;
  categoryId: string;
  vegan: boolean;
  modifiers: ModifierGroups;
  weight: number;
  sortOrder: number;
  containerType: ContainerType;
  containerTypeId: string;
  name: string;
  headerImageKey: string;
  unitPrice: number;
  deletedAt: Date;
}>;

export default function AddItemForm({
  restaurant,
  defaultCategoryId,
  item,
  onComplete,
  onCreateNewModifierGroup,
}: {
  restaurant: MapType<Query, typeof RESTAURANT>["Restaurant"];
  defaultCategoryId?: string;
  item: InitialItem | null;
  onComplete: (
    item: {
      id: string;
      name: string;
      description?: string;
      unitPrice: number;
      published: boolean;
      available: boolean;
      sortOrder: number;
    } | null
  ) => void;
  onCreateNewModifierGroup: (itemInProgress: InitialItem) => void;
}) {
  const fixedCategory = false;

  const [form] = Form.useForm<FormValues>();

  const {
    query: { refetch: refetchMenu },
    menu,
  } = useSelectedMenu();
  const categories = menu?.categories;

  const selectedCategoryId = defaultCategoryId || item?.categoryId;

  useEffect(() => {
    form.setFieldsValue({
      categoryId: selectedCategoryId,
    });
  }, [selectedCategoryId]);

  const categoryOptions = useMemo(() => {
    return categories?.map((category) => {
      return {
        value: category.id,
        label: category.name,
      };
    });
  }, [categories]);

  const [vegan, setVegan] = useState(false);

  useEffect(() => {
    var updatedFields = {
      vegan,
    };
    if (vegan) {
      updatedFields["vegetarian"] = true;
    }
    form.setFieldsValue(updatedFields);
  }, [vegan]);

  useEffect(() => {
    if (item?.vegan != null) {
      setVegan(item?.vegan);
    }
  }, [item?.vegan]);

  const [
    selectedModifierGroups,
    setSelectedModifierGroups,
  ] = useState<ModifierGroups>(item?.modifiers || []);

  useEffect(() => {
    if (selectedModifierGroups.length === 0 && item?.modifiers) {
      setSelectedModifierGroups(item.modifiers);
    }
  }, [item?.modifiers]);

  const [weight, setWeight] = useState<number>(0);

  useEffect(() => {
    if (item?.weight !== undefined) {
      setWeight(item?.weight);
    }
  }, [item?.weight]);

  useEffect(() => {
    if (typeof weight === "number") {
      form.setFieldsValue({
        weight,
      });
    }
  }, [weight]);

  const [selectedContainer, setSelectedContainer] = useState<ContainerType>();

  const [addItemMutation, { loading }] = useTypedMutation(ADD_ITEM, {
    onError: () => message.error("Could not create item."),
    onCompleted: (data) => {
      message.success(isEditItem ? "Saved!" : ITEM_CREATED_MSG);
      onComplete(data.addItem[0]);
    },
  });

  const onSubmit = useCallback(
    (result) => {
      console.log(result, selectedContainer);
      const input = {
        variables: {
          menuId: menu?.id,
          item: result,
        },
      };
      addItemMutation(input);
    },
    [addItemMutation, menu?.id, selectedContainer]
  );

  const isEditItem = item && "id" in item;

  const formDataToItem = useCallback(
    (data: FormValues): InitialItem => {
      var price;
      if (typeof data.price === "string") {
        if (CURRENCY_SYMBOLS.includes(data.price.charAt(0))) {
          price = data.price.substring(1);
        } else {
          price = data.price;
        }
      } else if (typeof data.price === "number") {
        price = data.price.toString();
      }

      var result = {
        ...data,
        id: item?.id,
        // maxPerOrder: !data.limitedPerOrder ? null : data.maxPerOrder,
        unitPrice: parseCurrency(price),
        available: DEFAULT_AVAILABLE,
        price: undefined,
        containerType: selectedContainer,
      };

      return result;
    },
    [item?.id, selectedContainer]
  );

  const onFinish = useCallback(
    (data: FormValues) => {
      const formItem = formDataToItem(data);
      // set sort order to max of sort orders so far.
      const selectedCategorySortOrders = categories
        ?.find((c) => c.id === data.categoryId)
        ?.items.map((i) => i.sortOrder);

      const maxSortOrder = selectedCategorySortOrders
        ? selectedCategorySortOrders.length !== 0
          ? Math.max(...selectedCategorySortOrders)
          : 0
        : undefined;

      const sortOrder = maxSortOrder !== undefined ? maxSortOrder + 1 : 0;

      var itemCreateData = {
        ...formItem,
        name: formItem.name!,
        modifierIds: formItem.modifiers
          ? formItem.modifiers.map((mg) => mg.id)
          : [],
      };

      if (itemCreateData.name && typeof itemCreateData.unitPrice === "number") {
        if (item?.sortOrder != null) {
          itemCreateData["sortOrder"] = item?.sortOrder;
        } else {
          if (sortOrder) {
            itemCreateData["sortOrder"] = sortOrder;
          }
        }
        delete itemCreateData.modifiers;
        delete itemCreateData.containerType;
        const itemCreateInput: ItemCreateInput = itemCreateData as ItemCreateInput;
        onSubmit(itemCreateInput);
      } else {
        message.error("Name and Unit Price are required.");
      }
    },
    [categories, formDataToItem, item?.sortOrder, onSubmit]
  );

  const [headerImg, setHeaderImg] = useState<File | null>(null);

  useEffect(() => {
    form.setFieldsValue({
      headerImageUpload: headerImg,
      containerTypeId: selectedContainer?.id,
    });
  }, [headerImg, selectedContainer?.id]);

  useEffect(() => {
    setSelectedContainer(item?.containerType);
  }, [item?.containerType]);

  const confirmBackConfig: ModalFuncProps = {
    title: "Unsaved changes!",
    content:
      "Are you sure you want to leave the page? You will lose all progress in creating the item!",
    okText: "Leave Page",
    okType: "danger",
    onOk: () => onComplete(null),
  };
  const [removeHeaderImage] = useTypedMutation(REMOVE_ITEM_HEADER, {
    variables: {
      id: item?.id,
    },
  });

  const [deleteItemMutation, { loading: deleting }] = useTypedMutation(
    DELETE_ITEM,
    {
      variables: {
        itemId: item?.id,
        partnerId: restaurant.id,
      },
    }
  );

  const deleteItem = useCallback(() => {
    deleteItemMutation();
  }, [deleteItemMutation]);

  return (
    <>
      <PageHeader
        title={<Text>{isEditItem ? item!.name : "Create new Item"}</Text>}
        onBack={() => {
          Modal.confirm(confirmBackConfig);
        }}
        extra={[
          <Popconfirm
            placement="bottom"
            title="Delete this item?"
            onConfirm={deleteItem}
          >
            <Button
              danger
              disabled={!!item?.deletedAt}
              loading={deleting}
              icon={<DeleteFilled />}
            >
              Delete
            </Button>
          </Popconfirm>,
        ]}
      ></PageHeader>
      <Form
        form={form}
        style={{ marginTop: 20 }}
        labelCol={{ span: LABEL_COL_SPAN }}
        wrapperCol={{ span: 14 }}
        onFinish={onFinish}
        initialValues={{
          ...defaultItemValues,
          categoryId: defaultCategoryId,
          ...item,
          price:
            item?.unitPrice != null
              ? `£${(item.unitPrice / 100).toFixed(2)}`
              : defaultItemValues.unitPrice,
          modifierGroups: selectedModifierGroups,
        }}
        onValuesChange={(changedValues) => {
          if (changedValues.modifiers != null) {
            setSelectedModifierGroups(changedValues.modifiers);
          }
          if (changedValues.headerImageUpload != null) {
            setHeaderImg(changedValues.headerImageUpload);
          }

          if (changedValues.vegan != null) {
            setVegan(changedValues.vegan);
          }
        }}
      >
        <Form.Item
          name="categoryId"
          label="Categories"
          wrapperCol={{ span: 4 }}
          rules={[{ required: true, message: "Please choose a category" }]}
        >
          <Select
            loading={!categoryOptions}
            disabled={fixedCategory}
            placeholder="Select a Category"
            options={categoryOptions ? categoryOptions : []}
            style={{
              minWidth: 200,
            }}
            dropdownMatchSelectWidth
          />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          wrapperCol={{ span: 8 }}
          rules={[
            { required: true, message: "Please choose a name for the item." },
          ]}
        >
          <Input placeholder="The name of the item." />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input placeholder="A description of the item." />
        </Form.Item>
        <Form.Item
          name="headerImageUpload"
          label="Header Image (max 10MB)"
          valuePropName="img"
        >
          <UploadImage
            initialKey={item?.headerImageKey}
            onDelete={removeHeaderImage}
          />
        </Form.Item>
        <Form.Item name="ingredients" label="Ingredients">
          <Input placeholder="The ingredients of the item." />
        </Form.Item>
        <Form.Item name="heat" label="Temperature">
          <Radio.Group options={HEAT_OPTIONS} optionType="button" />
        </Form.Item>
        <WeightFormItem itemWeight={item?.weight} form={form} />
        <Form.Item
          name="price"
          label="Price"
          rules={[
            {
              required: true,
              message: "Please specify the price of the item.",
            },
          ]}
          wrapperCol={{ span: 3 }}
        >
          <CurrencyFormat
            displayType={"input"}
            prefix={"£"}
            customInput={Input}
          />
        </Form.Item>

        {/* <Form.Item
          name="tax"
          label="VAT Rate"
          initialValue={selectedStandardTaxRate}
          rules={[{required: true, message: "Please specify the VAT rate for the item."}]}
          style={{marginBottom: 12}}
        >
          <InputNumber
            min={0}
            formatter={(value) => `${value}%`}
          />
        </Form.Item>
        
        <Col
          offset={LABEL_COL_SPAN}
          style={{marginBottom: 24}}>
          <Radio.Group
            defaultValue={STANDARD_TAX_RATE}
            className="ant-row"
            options={TAX_OPTIONS}
            onChange={(e) => setSelectedStandardTaxRate(e.target.value)}
            optionType="button"
          />
        </Col> */}

        <Form.Item name="alcohol" label="Alcohol" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item name="vegan" label="Vegan" valuePropName="checked">
          <Checkbox />
        </Form.Item>

        <Form.Item name="vegetarian" label="Vegetarian" valuePropName="checked">
          <Checkbox />
        </Form.Item>

        <Form.Item
          name="containerTypeId"
          label="Reusable Container Type"
          help="Choose the type of reusable container this item will come in."
        >
          <SelectContainerTypeButton
            selected={selectedContainer}
            setSelected={setSelectedContainer}
          />
        </Form.Item>

        {/* <Form.Item
          name="vegitarian"
          label="Vegitarian"
          initialValue={false}
          valuePropName="checked"
          >
          <Checkbox 
            disabled={vegan}
          />
        </Form.Item> */}
        <Col
          offset={LABEL_COL_SPAN}
          style={{ marginBottom: 30, marginTop: 30 }}
        >
          <Title level={2}>Order Details</Title>
        </Col>
        <Form.Item
          name="unavailableFor"
          label="Unavailable For"
          wrapperCol={{ span: 5 }}
        >
          <Select
            placeholder="Select all which apply."
            options={FULFILLMENT_OPTIONS}
            style={{
              minWidth: 200,
            }}
            mode="multiple"
            dropdownMatchSelectWidth
          />
        </Form.Item>
        {/* <Form.Item
          label="Limit count per Order?"
          >
          <Form.Item
            name="limitedPerOrder"
            initialValue={limitedPerOrder}
            valuePropName="checked"
            noStyle
          >
            <Switch 
              checked={limitedPerOrder}
              onChange={(value) => setLimitedPerOrder(value)}
            />
          </Form.Item>
          
          <div style={{ display: 'inline-block', position: 'relative', marginLeft: 10}}>
            <QuestionMarkToolTip title="Whether to limit the number of times the item can be added to a customer's order."/>
          </div>
        </Form.Item>
        {
          limitedPerOrder ?
            <Form.Item
              name="maxPerOrder"
              label="Max Per Order"
              initialValue={0}
              rules={[{required: true, message: "Please specifiy the maximum per order."}]}
              >
              <InputNumber
                min={1}  
              />
            </Form.Item>
          : null
        } */}
        {/* <Form.Item
          name="slow"
          label="Long preparation Time?"
          initialValue={false}
          valuePropName="checked"
          >
          <Checkbox />
        </Form.Item> */}

        <Form.Item
          name="modifiers"
          label="Modifier Groups"
          valuePropName="selected"
        >
          {selectedModifierGroups != null && (
            <ItemModifierGroupsTable
              restaurant={restaurant}
              menu={menu}
              // onChange={(value) => setSelectedModifierGroups(value)}
              refreshMenu={refetchMenu}
              // selected={selectedModifierGroups}
              addNewModifierGroup={() => {
                onCreateNewModifierGroup(formDataToItem(form.getFieldsValue()));
              }}
            />
          )}
        </Form.Item>

        <Form.Item
          name="published"
          label="Publish once Approved?"
          valuePropName="checked"
          initialValue={true}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 4 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {isEditItem ? "Save Item" : "Create Item"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
