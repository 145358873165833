import {
  CheckCircleFilled,
  DeleteFilled,
  FullscreenOutlined,
} from "@ant-design/icons";
import {
  Affix,
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  PageHeader,
  Row,
  Tag,
} from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import L from "leaflet";
import {
  createContext,
  default as React,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOMServer from "react-dom/server";
import { FeatureGroup, Map, Marker, Popup, TileLayer } from "react-leaflet";
import { generatePath, Link, useParams } from "react-router-dom";
import { style } from "typestyle";
import IncidentTable from "../components/incident/IncidentTable";
import OrderMessages from "../components/order/messaging/OrderMessages";
import OrderActions from "../components/order/OrderActions";
import OrderB2BInfo from "../components/order/OrderB2BInfo";
import OrderBasket from "../components/order/OrderBasket";
import OrderCustomerInfo from "../components/order/OrderCustomerInfo";
import OrderDescriptions from "../components/order/OrderDescriptions";
import { OrderTimeline } from "../components/OrderTimeline";
import FlightOfferTable from "../components/rider/RiderOffers";
import {
  FulfillmentMethod,
  MapType,
  Query,
  RiderVehicle,
} from "../graphql/generated";
import { useTypedQuery } from "../graphql/hooks";
import { ORDER } from "../graphql/queries";

const styles = {
  icon: style({
    display: "flex",
    borderRadius: 30,
    backgroundColor: "white",
    height: 32,
    width: 32,
    justifyContent: "center",
    alignItems: "center",
  }),
  actionRow: style({
    margin: "16px 0",
    $nest: {
      "> div": {
        marginBottom: 8,
      },
    },
  }),
};

export const RiderIcon = ({ vehicle }) => (
  <div
    className={styles.icon}
    style={{
      border: `4px solid green`,
    }}
  >
    <i className="material-icons" style={{ color: "green", fontSize: 16 }}>
      {vehicle === "CAR" ? "directions_car" : "directions_bike"}
    </i>
  </div>
);

export const RestoIcon = () => (
  <div
    className={styles.icon}
    style={{
      border: `4px solid red`,
    }}
  >
    <i className="material-icons" style={{ color: "red", fontSize: 16 }}>
      restaurant
    </i>
  </div>
);

export const LRiderIcon = (vehicle: keyof typeof RiderVehicle) =>
  L?.divIcon?.({
    className: "custom-icon",
    iconAnchor: [12, 12],
    html: ReactDOMServer.renderToString(<RiderIcon vehicle={vehicle} />),
  });

export const LRestoIcon = () =>
  L?.divIcon?.({
    className: "custom-icon",
    iconAnchor: [16, 32],

    html: ReactDOMServer.renderToString(<RestoIcon />),
  });

export const OrderContext = createContext<{
  data?: MapType<Query, typeof ORDER>;
  refetch: Function;
}>({ refetch: () => void 0 });

export const Order = () => {
  const { id } = useParams();

  const { data, refetch } = useTypedQuery(ORDER, {
    pollInterval: 2500,
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });

  const order = data?.order;

  const mapRef = useRef<Map | null>(null);
  const groupRef = useRef<FeatureGroup | null>(null);

  const [bounds, setBounds] = useState(
    new L.LatLngBounds({ lat: 0, lng: 0 }, { lat: 1, lng: 1 })
  );

  const doBoundsUpdate = useCallback(() => {
    if (groupRef.current)
      setBounds(groupRef.current.leafletElement.getBounds().pad(0.5));
  }, [data]);

  const [fullscreen, setFullscreen] = useState(false);

  useEffect(() => {
    mapRef.current?.leafletElement.invalidateSize();
  }, [fullscreen]);

  return (
    <OrderContext.Provider value={{ data, refetch }}>
      <div
        style={{
          position: "relative",
          height: fullscreen ? "90vh" : "350px",
          width: "100%",
        }}
      >
        <Button
          style={{ position: "absolute", bottom: 8, left: 8, zIndex: 1000 }}
          icon={<FullscreenOutlined />}
          onClick={() => setFullscreen(!fullscreen)}
        >
          {fullscreen ? "Close" : "Expand"}
        </Button>
        {order ? (
          <Map
            ref={(ref) => (mapRef.current = ref)}
            bounds={bounds}
            style={{
              height: "100%",
            }}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://maps.ecoeats.uk/tile/{z}/{x}/{y}.png"
              onload={doBoundsUpdate}
            />
            <FeatureGroup ref={(ref) => (groupRef.current = ref)}>
              <Marker
                position={
                  order?.b2bDeliveryJob
                    ? order.b2bDeliveryJob.origin?.coordinates?.coordinates
                    : order?.restaurant.address!.coordinates!.coordinates
                }
                icon={LRestoIcon()}
              >
                <Popup>
                  <h3>{order?.restaurant.name}</h3>
                </Popup>
              </Marker>
              {order?.address ? (
                <Marker position={order?.address!.coordinates!.coordinates}>
                  <Popup>
                    <h5>Customer</h5>
                  </Popup>
                </Marker>
              ) : null}
              {order?.rider?.position?.coordinates ? (
                <Marker
                  icon={LRiderIcon(order.rider.vehicle)}
                  position={order?.rider.position.coordinates}
                >
                  <Popup>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Link
                        style={{ flex: 1 }}
                        to={
                          order?.rider
                            ? generatePath("/rider/:id", {
                                id: order?.rider?.id,
                              })
                            : "#"
                        }
                      >
                        <h5>Rider</h5>
                      </Link>
                    </div>
                    <h6>{order?.rider.name}</h6>
                  </Popup>
                </Marker>
              ) : null}
            </FeatureGroup>
          </Map>
        ) : null}
      </div>
      <div style={{ backgroundColor: "white", padding: "0 24px" }}>
        {/* Page Header */}

        <Affix offsetTop={0}>
          <div style={{ backgroundColor: "white" }}>
            <PageHeader
              title={
                <div style={{ minWidth: 400 }}>
                  <Link to={`/restaurant/${order?.restaurant?.id}`}>
                    {order?.restaurant?.name || "Loading..."}
                  </Link>
                  <Title level={3}>Order #{order?.number}</Title>
                </div>
              }
              extra={[
                <Row gutter={16} className={styles.actionRow}>
                  {order && <OrderActions order={order} />}
                </Row>,
              ]}
            />
          </div>
        </Affix>

        <OrderDescriptions />

        {order?.pickupBlocked ? (
          <Row className={styles.actionRow}>
            <Col flex={1}>
              <Alert
                type="error"
                message="This order has been blocked from pickup!"
              />
            </Col>
          </Row>
        ) : null}

        {order?.b2bDeliveryJob ? (
          <Row>
            <Col flex={1}>
              <OrderB2BInfo />
            </Col>
          </Row>
        ) : null}
        <Divider />
        <Row gutter={32}>
          <Col md={12}>
            {order && (
              <OrderCustomerInfo
                basket={order?.basket}
                bordered
                column={1}
                address={order.address}
                customerId={order.customer.id}
                email={order.customer.email}
                orderId={order.id}
                name={order.customer.name}
              />
            )}
            {order?.riderReport && (
              <Descriptions bordered column={1} title="Rider Notes">
                <Descriptions.Item label="Overall">
                  {order.riderReport.positive ? (
                    <Tag color="green" icon={<CheckCircleFilled />}>
                      Positive
                    </Tag>
                  ) : (
                    <Tag color="red" icon={<DeleteFilled />}>
                      Negative
                    </Tag>
                  )}
                </Descriptions.Item>
                {order.riderReport.note && (
                  <Descriptions.Item label="Note">
                    {order.riderReport.note}
                  </Descriptions.Item>
                )}
                {order.riderReport.ratings?.length && (
                  <Descriptions.Item label="Ratings">
                    {order.riderReport.ratings.map((r) => (
                      <Text>
                        {r}
                        <br />
                      </Text>
                    ))}
                  </Descriptions.Item>
                )}
              </Descriptions>
            )}
          </Col>
          <Col md={12}>
            {order?.reusablesPickupAggregate && (
              <Card
                title="Reusables Pickup Aggregate"
                actions={[
                  <Button
                    href={`/reusables/pickup-aggregate/${order.reusablesPickupAggregate.id}`}
                  >
                    View Aggregate
                  </Button>,
                ]}
              >
                <Alert
                  type="info"
                  message={
                    <Text>
                      This is a reusables pickup aggregate from customers to{" "}
                      <Link
                        to={`/reusables/washing-location/${order.reusablesPickupAggregate.washingLocation.id}`}
                      >
                        {order.reusablesPickupAggregate.washingLocation.name}
                      </Link>
                    </Text>
                  }
                />
              </Card>
            )}

            <OrderBasket />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col md={12}>
            {order?.fulfillmentMethod === FulfillmentMethod.DELIVERY && (
              <FlightOfferTable orderId={order.id} />
            )}
            {order?.id && <IncidentTable orderId={id} />}
          </Col>
          <Col md={12}>
            <Card style={{ marginBottom: "16px" }} title="Timeline">
              {order ? <OrderTimeline order={order} /> : null}
            </Card>
          </Col>
        </Row>

        <OrderMessages orderId={id} />
      </div>
    </OrderContext.Provider>
  );
};
