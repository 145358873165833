import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  Col,
  Form,
  FormCheckbox,
  FormGroup,
  FormInput,
  FormSelect,
  ListGroup,
  ListGroupItem,
  Row,
} from "shards-react";
import {
  $,
  AllTypesProps,
  MenuModifierGroupCreateInput,
  ModifierGroup,
  PriceStrategy,
} from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";
import { MENU } from "../../graphql/queries";
import { useFormData } from "../../hooks/useFormData";
import { price } from "../../utils/price";
import { LoadingButton } from "../LoadingButton";
import { MenuContextType } from "../RestaurantMenu";
import { SearchItemModal } from "./SearchItem";

const ModifierOptionEntry = ({ oi, name, unitPrice, modifiers, onClick }) => {
  const _onClick = useCallback(() => {
    onClick(oi);
  }, [oi, onClick]);
  return (
    <ListGroupItem>
      <Row style={{ display: "flex" }}>
        <Col style={{ flexGrow: 1 }}>
          {" "}
          {name}
          {" - "}
          {price(unitPrice)}
          {}
          {modifiers?.length
            ? " - " + modifiers.map((m) => m.name).join(", ")
            : null}
        </Col>
        <Col>
          <Button className="btn btn-danger" onClick={_onClick}>
            <i className="material-icons mr-2" style={{ fontSize: 18 }}>
              delete
            </i>
          </Button>
        </Col>
      </Row>
    </ListGroupItem>
  );
};
export const AddMenuModifierGroupForm = ({
  restaurant,
  menu,
  modifierGroup,
  onSuccess = (): void => void 0,
}: {
  restaurant: {
    name: string;
  };
  menu?: MenuContextType["menu"];
  modifierGroup?: ModifierGroup;
  onSuccess?: () => void;
}) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const inputs = AllTypesProps.MenuModifierGroupCreateInput;

  const extractFormDataFromModifierGroup = useCallback(
    (modifierGroup?: ModifierGroup) => {
      return {
        sortOrder: -1,
        ...Object.keys(inputs).reduce(
          (a, c) => ({ ...a, [c]: modifierGroup?.[c] }),
          {}
        ),
        cascades: modifierGroup?.cascades || false,
        optionIds: modifierGroup?.options?.map((o) => o.id) || [],
      };
    },
    []
  );

  const [formData, onChange, setFormData] = useFormData<
    Partial<MenuModifierGroupCreateInput>
  >(extractFormDataFromModifierGroup(modifierGroup));

  useEffect(() => {
    const newFormData = extractFormDataFromModifierGroup(modifierGroup);

    setFormData(newFormData);
  }, [modifierGroup]);

  const [addModifierGroupMutation, { loading }] = useTypedMutation(
    {
      addModifierGroup: [
        {
          id: $`id`,
          modifierGroup: $`modifierGroup`,
        },
        {
          ...MENU.Menu[1],
        },
      ],
    },
    {
      onCompleted: () => onSuccess(),
      onError: (e) => console.log(e.graphQLErrors),
    }
  );
  const parseInputType = (type) =>
    ({
      Float: "number",
      String: "text",
    }[type] ?? "text");

  const submitForm = async (e) => {
    e.preventDefault();
    const input = {
      variables: {
        id: menu?.id,
        modifierGroup: {
          ...formData,
        },
      },
    };

    if (modifierGroup?.id) {
      input.variables.modifierGroup.id = modifierGroup.id;
    }

    addModifierGroupMutation(input);
  };

  const onClick = useCallback(
    (oi) =>
      setFormData((fd) => ({
        ...fd,
        optionIds: fd.optionIds?.filter((s) => s !== oi),
      })),
    []
  );

  return (
    <>
      <ListGroup flush>
        <ListGroupItem className="p-0 px-3 pt-3">
          <Row>
            <Col>
              <Form>
                {Object.keys(inputs).map((k) => {
                  if (inputs[k].array || k === "id") return null;
                  if (k === "priceStrategy") {
                    Object.keys(PriceStrategy);
                    return (
                      <FormGroup key={k}>
                        <label htmlFor={k}>{k}</label>
                        <FormSelect
                          name={k}
                          value={formData[k]}
                          onChange={onChange}
                        >
                          <option value="">Select a Strategy...</option>
                          {Object.keys(PriceStrategy).map((ek) => (
                            <option key={ek} value={ek}>
                              {ek}
                            </option>
                          ))}
                        </FormSelect>
                      </FormGroup>
                    );
                  }
                  return (
                    <Col className="form-group" key={k}>
                      <label htmlFor={k}>{k}</label>
                      {inputs[k].type === "Boolean" ? (
                        <FormCheckbox
                          style={{ marginRight: "12px" }}
                          name={k}
                          value={formData[k]}
                          onChange={onChange}
                        >
                          <span style={{ marginLeft: 16 }}>{k}</span>
                        </FormCheckbox>
                      ) : (
                        <FormInput
                          name={k}
                          value={formData[k]}
                          type={parseInputType(inputs[k].type)}
                          onChange={onChange}
                        />
                      )}
                    </Col>
                  );
                })}

                <FormGroup>
                  <label htmlFor="optionIds">Options</label>
                  <strong className="text-muted d-block mb-2">
                    The items that are options for this modifier
                  </strong>
                  <Button onClick={handleShow}>Add Item</Button>
                  <ListGroup>
                    {formData.optionIds?.map((oi) => {
                      const item = menu?.categories
                        .flatMap((c) => c.items)
                        .find((i) => i.id === oi);

                      return (
                        <ModifierOptionEntry
                          key={oi}
                          oi={oi}
                          name={item?.name}
                          unitPrice={item?.unitPrice}
                          modifiers={item?.modifiers}
                          onClick={onClick}
                        />
                      );
                    })}
                  </ListGroup>
                </FormGroup>

                <FormGroup>
                  <LoadingButton
                    type="submit"
                    onClick={submitForm}
                    loading={loading}
                  >
                    {modifierGroup ? "Save to " : "Add Modifier Group to "}
                    {restaurant?.name}
                  </LoadingButton>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>

      <SearchItemModal
        show={show}
        onHide={handleClose}
        onSave={(selected) =>
          setFormData({
            ...formData,
            optionIds: [
              ...(formData.optionIds ?? []),
              ...selected.filter((s) => !formData.optionIds?.includes(s)),
            ],
          })
        }
        menu={menu}
        preSelected={formData?.optionIds}
      />
    </>
  );
};
