import { $ } from "../../../graphql/generated";
import { query } from "../../../graphql/hooks";

export const COMMISION_PLANS = query({
  CommissionPlanList: [
    {
      order: $`order`,
      sortBy: $`sortBy`,
      skip: $`skip`,
      take: $`take`,
    },
    {
      hasMore: true,
      total: true,
      items: {
        id: true,
        slug: true,
        description: true,
        rungs: {
          id: true,
          name: true,
          fixedBase: true,
          percentage: true,
        },
      },
    },
  ],
});
