import { Badge, Tooltip } from "antd";
import { differenceInMinutes } from "date-fns";
import React from "react";
import {
  RestaurantBusyStatus,
  RestaurantOnboardingStatus,
} from "../graphql/generated";

export const OnboardingStatusBadge = ({
  onboardingStatus,
}: {
  onboardingStatus: RestaurantOnboardingStatus;
}) => (
  <Badge
    status={onboardingStatus === "LIVE" ? "success" : "warning"}
    style={{ cursor: "pointer", marginRight: 8 }}
    text={onboardingStatus}
  />
);

export const PartnerOnlineBadge = ({
  lastOnline,
  online,
}: {
  lastOnline: Date;
  online: boolean;
}) => {
  const onlineState = online
    ? "ONLINE"
    : differenceInMinutes(new Date(lastOnline), new Date()) > -5
    ? "CLOSED"
    : "OFFLINE";

  return (
    <Tooltip
      title={`Last Update: ${new Date(lastOnline).toLocaleString("en-GB")}`}
    >
      <Badge
        status={
          onlineState === "ONLINE"
            ? "success"
            : onlineState === "CLOSED"
            ? "warning"
            : "error"
        }
        text={onlineState}
        style={{ marginRight: 8 }}
      />
    </Tooltip>
  );
};

export const PartnerBusyBadge = ({
  busyStatus,
}: {
  busyStatus: RestaurantBusyStatus;
}) => (
  <Badge
    status={busyStatus === "BUSY" ? "warning" : "success"}
    text={busyStatus}
  />
);

export const RestaurantStatus = ({
  restaurant,
}: {
  restaurant: {
    onboardingStatus: RestaurantOnboardingStatus;
    lastOnline: Date;
    online: boolean;
    busyStatus: RestaurantBusyStatus;
  };
}) => {
  return (
    <>
      {/* eslint-disable-next-line */}
      <OnboardingStatusBadge onboardingStatus={restaurant.onboardingStatus} />
      <PartnerOnlineBadge
        lastOnline={restaurant.lastOnline}
        online={restaurant.online}
      />
      <PartnerBusyBadge busyStatus={restaurant.busyStatus} />
    </>
  );
};
