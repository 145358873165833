import { PlusOutlined } from "@ant-design/icons";
import { Button, Drawer, Space, Table } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { MapType, Query } from "../../graphql/generated";
import { useTypedQuery } from "../../graphql/hooks";
import { BRAND_LIST } from "../../graphql/queries";
import { useStore } from "../../state/zustand";
import { getImageUrl } from "../../utils/image-url";
import DynamicForm from "../forms/DynamicForm";

/**
 * A list of all the ecoeats / ZESH partner brands.
 */
export default function BrandList({
  onSelect,
}: {
  onSelect?: (
    b: MapType<Query, typeof BRAND_LIST>["BrandList"]["items"][0]
  ) => void;
}) {
  const [page, setSkip] = useState(1);
  const [take, setTake] = useState(25);
  const [sortBy, setSortBy] = useState("name");
  const [order, setOrder] = useState("ASC");

  const { data, loading, refetch } = useTypedQuery(BRAND_LIST, {
    fetchPolicy: "network-only",
    variables: {
      skip: (page - 1) * take,
      take,
      order,
      sortBy,
    },
  });

  const history = useHistory();

  const [show, setShow] = useState(false);

  const onChange = useCallback((p, f, s) => {
    if (s instanceof Array) {
    } else {
      setSortBy(
        s.order
          ? (s.field as string) || (s.columnKey as string) || "placedAt"
          : "placedAt"
      );
      setOrder(!s.order ? "DESC" : s.order === "ascend" ? "ASC" : "DESC");
    }
  }, []);

  const [entityId, setEntityId] = useState<string | undefined>();
  const config = useStore((s) => s.apiConfig);
  const list = data?.BrandList;
  return (
    <>
      <Table
        dataSource={list?.items}
        loading={loading}
        onChange={onChange}
        title={() => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Title level={4} style={{ flex: 1 }}>
              {list?.total} Brands
            </Title>

            <Space>
              <Button
                type="primary"
                icon={<PlusOutlined style={{ verticalAlign: "middle" }} />}
                onClick={() => setShow(true)}
              >
                New Brand
              </Button>
            </Space>
          </div>
        )}
        pagination={{
          showTitle: true,
          showQuickJumper: true,
          pageSize: take,
          total: list?.total,
          onChange: (p, ps) => {
            setTake(ps!);
            setSkip(p);
          },
          current: page,
          showSizeChanger: true,
        }}
        columns={[
          {
            key: "logo",
            render: (_, r) => (
              <img src={getImageUrl(r.logo.key, 128)} width={64} height={64} />
            ),
            sorter: true,
          },
          {
            title: "Name",
            dataIndex: "name",
            sorter: true,
          },

          {
            sorter: true,
            title: "Description",
            dataIndex: "description",
          },
          {
            render: (_, r) => (
              <Space>
                <Button
                  target="_blank"
                  href={`https://ecoeats.uk/brand/${r.slug}`}
                  type="link"
                >
                  View
                </Button>
                {onSelect ? (
                  <Button onClick={() => onSelect(r)} type="primary">
                    Select
                  </Button>
                ) : (
                  <Button onClick={() => setEntityId(r.id)} type="link">
                    Edit
                  </Button>
                )}
              </Space>
            ),
          },
        ]}
      />

      <Drawer
        width={768}
        visible={show || !!entityId}
        onClose={() => {
          setShow(false);
          setEntityId(() => undefined);
        }}
        footer={null}
      >
        <DynamicForm
          id="BrandCreateForm"
          entityId={entityId}
          onComplete={() => refetch()}
        />
      </Drawer>
    </>
  );
}
