import PropTypes from "prop-types";
import React from "react";
import styles from "./AccountStatistics.module.css";

const AccountStatistics = ({ emissionsSaved, metres }) => {
  return (
    <div
      className={styles.AccountStats}
      style={{ background: 'url("/nature.svg") no-repeat center' }}
    >
      <h2>Your distance and emissions savings</h2>
      <span className={styles.Stats}>
        {metres !== undefined ? (
          <span>{(metres / 1000).toFixed(2)} km</span>
        ) : (
          0
        )}
        {emissionsSaved !== undefined ? (
          <span>{(emissionsSaved as Number).toFixed(2)}g CO</span>
        ) : (
          0
        )}
      </span>
    </div>
  );
};

AccountStatistics.propTypes = {
  emissionsSaved: PropTypes.number,
  metres: PropTypes.number,
};

export default AccountStatistics;
