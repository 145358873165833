import React from "react";
import { Alert, Form } from "react-bootstrap";
import {
  AllTypesProps,
  PartnerTagInput,
  PartnerTagTypes,
} from "../graphql/generated";
import { useTypedMutation } from "../graphql/hooks";
import { useFormData } from "../hooks/useFormData";
import {
  getMapInputFields,
  mapInputToControl,
} from "../utils/input-control-gql";
import { LoadingButton } from "./LoadingButton";

const { inputs } = getMapInputFields<
  typeof AllTypesProps.PartnerTagInput,
  PartnerTagInput
>(AllTypesProps.PartnerTagInput);

export default function TagForm({ onCompleted }) {
  const [formData, onChange] = useFormData<PartnerTagInput>({
    image: "",
    name: "",
    type: PartnerTagTypes.Food,
  });
  const [createTagMutation, { loading, error }] = useTypedMutation(
    {
      createPartnerTag: [
        {
          tag: {
            ...formData,
          },
        },
        {
          id: true,
        },
      ],
    },
    {
      onCompleted,
    }
  );

  return (
    <Form>
      {inputs.map(([name, v]) =>
        mapInputToControl({
          ...v,
          formData,
          onChange,
          name,
        })
      )}
      <LoadingButton loading={loading} onClick={() => createTagMutation()}>
        Create Tag
      </LoadingButton>
      {error ? (
        <Alert variant="danger" style={{ marginTop: 8 }}>
          {JSON.stringify(
            error.graphQLErrors[0]?.extensions?.exception?.response.message
          )}
        </Alert>
      ) : null}
    </Form>
  );
}
