import { Select } from "antd";
import React from "react";

const OPTIONS = [
  {
    color: "#31b669",
    name: "Primary",
  },
  {
    color: "#ffffff",
    name: "White",
  },
  {
    color: "#85C7F2",
    name: "Blue",
  },
];

type PromotionColor = "#31b669" | "#ffffff" | "#85C7F2";

export const DEFAULT_COLOUR = OPTIONS[0].color;

export default function PromotionColorSelect({
  onChange,
  initialValue,
}: {
  onChange?: (color: string) => void;
  initialValue?: PromotionColor;
}) {
  return (
    <Select
      allowClear
      defaultValue={initialValue || DEFAULT_COLOUR}
      options={OPTIONS.map((o) => ({
        value: o.color,
        label: (
          <div>
            <div
              style={{
                display: "inline-block",
                width: 16,
                height: 16,
                marginRight: 8,
                borderRadius: 4,
                verticalAlign: "middle",
                backgroundColor: o.color,
              }}
            />
            <span
              style={{
                verticalAlign: "middle",
              }}
            >
              {o.name}
            </span>
          </div>
        ),
      }))}
      onChange={onChange}
    />
  );
}
