import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { City as CityT } from "../graphql/generated";
import { UPDATE_ZONE } from "../graphql/mutations";
import { CITY } from "../graphql/queries";
import { useFormData } from "../hooks/useFormData";

export const City = () => {
  const nav = useHistory();
  const { id } = useParams();
  const { data } = useQuery(CITY, {
    variables: {
      id,
    },
  });

  const [updateZone, { loading: updateZoneLoading }] = useMutation(UPDATE_ZONE);

  const city: CityT = data?.city;

  const [formData, onChange, sfd] = useFormData({});

  const [showModal, setShowModal] = useState(false);

  if (!city) return <Spinner animation="border" />;

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title={city?.name}
          subtitle="Cities"
          className="text-sm-left"
        />
      </Row>

      <Row>
        <Col lg="12">
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Zones</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3">
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                  </tr>
                </thead>
                {city?.zones.map((z) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => nav.push(`/zone/${z.id}`)}
                    className="p-0 px-3 pt-3"
                  >
                    <td>{z.name}</td>
                  </tr>
                ))}
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
