import { Tag, TagProps } from "antd";
import React from "react";

interface Props {
  children: string;
  color: TagProps["color"];
  icon: JSX.Element;
}

const TagWithIcon = (props: Props) => {
  const { children, color, icon } = props;
  return (
    <Tag
      color={color}
      icon={
        <span
          style={{
            display: "inline-flex",
            alignItems: "center",
            marginRight: 5,
          }}
        >
          {icon}
        </span>
      }
    >
      {children}
    </Tag>
  );
};

export default TagWithIcon;
