import { useMutation } from "@apollo/client";
import { getOperationName } from "apollo-link";
import { LatLngTuple } from "leaflet";
import React, { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { Marker, Polygon } from "react-leaflet";
import { useHistory } from "react-router-dom";
import {
  Card,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  ListGroup,
  ListGroupItem,
  Row,
} from "shards-react";
import { RestaurantCreateInput, Zone } from "../../graphql/generated";
import { useTypedQuery } from "../../graphql/hooks";
import { CREATE_RESTAURANT } from "../../graphql/mutations";
import { ALL_RESTAURANTS } from "../../graphql/queries";
import { useFormData } from "../../hooks/useFormData";
import Map from "../../utils/Map";
import { validatePostcode } from "../../utils/postcode";
import { getCenterPolygon } from "../../utils/sphere";
import { LoadingButton } from "../LoadingButton";

export const OnboardRestaurantForm = ({
  onComplete,
  zoneId,
}: {
  onComplete?: () => void;
  zoneId?: string;
}) => {
  const history = useHistory();
  const INITIAL_POSITION = [56.333874, -2.8059686];
  const [zoom, setZoom] = useState(12);
  const [position, setPosition] = useState([...INITIAL_POSITION]);
  const [zone, setZone] = useState<Zone>({} as Zone);
  const [formData, onChange] = useFormData<Partial<RestaurantCreateInput>>({});
  const { data } = useTypedQuery(
    {
      allCities: [
        {
          skip: 0,
          take: 50,
        },
        {
          id: true,
          name: true,
          zones: {
            id: true,
            name: true,
            polygon: {
              type: true,
              coordinates: true,
            },
          },
        },
      ],
    },
    {
      onCompleted: (data) => {
        if (!data.allCities) return;
        const firstZone = data.allCities?.[0]?.zones?.[0];
        const defaultZone =
          (zoneId
            ? data.allCities
              .flatMap((c) => c.zones)
              .find((z) => z.id === zoneId)
            : null) ?? firstZone;
        setZone(defaultZone || {});
        onChange({
          currentTarget: {
            value: defaultZone.id,
            name: "zoneId",
          },
        } as FormEvent<HTMLInputElement>);
      },
    }
  );
  const [createRestaurantMutation, { loading, error }] = useMutation(
    CREATE_RESTAURANT,
    {
      onCompleted: (data) => {
        if (onComplete) onComplete();
        history.push(`/restaurant/${data.createRestaurant.id}`);
      },
      refetchQueries: () => [getOperationName(ALL_RESTAURANTS)!],
    }
  );

  const zoneChange = ({ target: { value } }) => {
    const newZone = data?.allCities
      .flatMap((c) => c.zones)
      .find((z) => z.id === value);
    if (newZone) {
      setZone(newZone as any);
      setPosition(getCenterPolygon(newZone.polygon.coordinates[0]));
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    createRestaurantMutation({
      variables: {
        restaurant: {
          ...formData,
          address: {
            ...formData.address,
            phone_number: formData.phoneNumber,
          },
        },
      },
    });
  };

  const mapRef = useRef<any>();
  const polygonRef = useRef<Polygon | null>(null);

  useEffect(() => {
    if (mapRef.current && polygonRef.current) {
      mapRef.current.leafletElement.fitBounds(
        polygonRef.current.leafletElement.getBounds()
      );
    }
  }, [zone]);

  const [formPostcodeValid, setFormPostcodeValid] = useState(false);

  useEffect(() => {
    const postcode = formData?.address?.postcode;
    if (postcode) {
      validatePostcode(postcode).then(v => {
        setFormPostcodeValid(v.result);
      });
    }
  }, [formData?.address?.postcode]);

  return (
    <Card small className="mb-4">
      <ListGroup flush>
        <ListGroupItem className="p-0 px-3 pt-3">
          {error
            ? (error?.networkError as any)?.result?.errors?.map((c) => (
              <Alert variant="danger">{c.message}</Alert>
            ))
            : null}
          <Form>
            <strong className="text-muted d-block my-2">Branding</strong>
            <FormGroup>
              <label htmlFor="name">Name</label>
              <FormInput
                onChange={onChange}
                name="name"
                placeholder="ecoeats Kebabs"
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor="description">Description</label>
              <FormInput
                onChange={onChange}
                name="description"
                placeholder="A wonderful selection of Kebabs from around the globe"
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor="phoneNumber">Phone Number</label>
              <FormInput
                onChange={onChange}
                name="phoneNumber"
                placeholder="07715342345"
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor="ownerName">Owner Name</label>
              <FormInput onChange={onChange} name="ownerName" />
            </FormGroup>

            <FormGroup>
              <label htmlFor="ownerEmail">Owner Email</label>
              <FormInput onChange={onChange} name="ownerEmail" />
            </FormGroup>

            <strong className="text-muted d-block my-2">Location</strong>
            <Map
              ref={mapRef}
              center={position as LatLngTuple}
              zoom={zoom}
              onClick={(e) => {
                // Check if in bounds
                onChange({
                  currentTarget: {
                    name: "address.coordinates.coordinates",
                    value: [e.latlng.lat, e.latlng.lng],
                  },
                } as any);
                setPosition([e.latlng.lat, e.latlng.lng]);
                setZoom(zoom < 15 ? 15 : zoom);
              }}
            >
              {position ? <Marker position={position as LatLngTuple} /> : null}
              {zone?.polygon ? (
                <Polygon
                  ref={polygonRef}
                  positions={zone.polygon.coordinates}
                />
              ) : null}
            </Map>

            <FormGroup>
              <label htmlFor="address.address_line_1">Address</label>
              <FormInput
                onChange={onChange}
                name="address.address_line_1"
                placeholder="1234 Main St"
              />
            </FormGroup>

            <Row form>
              <Col md="6" className="form-group">
                <label htmlFor="zoneId">Zone</label>
                <FormSelect
                  name="zoneId"
                  value={zone.id}
                  onChange={(e) => {
                    zoneChange(e);
                    onChange(e);
                  }}
                >
                  <option value="">Select Zone...</option>
                  {data?.allCities.map((c) => (
                    <optgroup label={c.name} key={c.id}>
                      {c.zones.map((z) => (
                        <option value={z.id} key={c.id}>
                          {z.name}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </FormSelect>
              </Col>
              <Col md="6" className="form-group">
                <label htmlFor="address.postcode">Postcode</label>
                <FormInput
                  {...(formPostcodeValid ? { valid: true } : { invalid: true })}
                  onChange={onChange}
                  name="address.postcode"
                />
              </Col>
            </Row>
            <LoadingButton type="submit" onClick={submitForm} loading={loading}>
              Onboard Restaurant
            </LoadingButton>
          </Form>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};
