import { Card, Input, Table, Tag } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { Permissions } from "../graphql/generated";
import { useTypedQuery } from "../graphql/hooks";
import { ADMINS_LIST } from "../graphql/queries";
import usePagination from "../hooks/usePagination";

export default function AdminsTable() {
  const {
    pagination,
    args: { order, page, query, sortBy, take },
    onChange,
    setQuery,
  } = usePagination({
    defaultOrder: "ASC",
    defaultSortBy: "name",
  });

  const { data } = useTypedQuery(ADMINS_LIST, {
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
    },
    fetchPolicy: "network-only",
  });

  const history = useHistory();

  return (
    <Card
      className="mb-4"
      title={
        <>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Input
              placeholder="Search Admins"
              onChange={(e) => setQuery(e.target.value)}
              style={{ marginBottom: 8 }}
              allowClear
            />
          </div>
        </>
      }
    >
      <Table
        dataSource={data?.RestaurantAdminList.items || []}
        pagination={{
          total: data?.RestaurantAdminList.total,
          ...pagination,
        }}
        onChange={onChange}
        style={{
          cursor: "pointer",
        }}
        onRow={(r) => ({
          onClick: () => history.push(`/admin/${r.id}`),
        })}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            sorter: true,
          },
          {
            title: "Email",
            dataIndex: "email",
            sorter: true,
          },
          {
            title: "Roles",
            dataIndex: "roles",
            render: (roles) =>
              roles.map((r: Permissions) => (
                <Tag
                  color={
                    r === Permissions.CREATE_SUPER_ADMINS
                      ? "red"
                      : r === Permissions.CREATE_RESTAURANT
                      ? "warning"
                      : "blue"
                  }
                >
                  {r
                    .toLowerCase()
                    .split("_")
                    .map((c) => c.charAt(0).toUpperCase() + c.slice(1))
                    .join(" ")}
                </Tag>
              )),
          },
        ]}
      ></Table>
    </Card>
  );
}
