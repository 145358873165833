import { useCallback, useEffect, useRef, useState } from "react";

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export function useDebouncedCallback<T extends (...args: any[]) => any>(
  fn: T,
  ms: number
): T {
  const ref = useRef(-1);

  const wrappedFn = useCallback(
    (...args) => {
      clearTimeout(ref.current);
      ref.current = (setTimeout(() => fn(...args), ms) as unknown) as number;
    },
    [fn]
  );

  useEffect(() => () => clearTimeout(ref.current), []);

  return wrappedFn;
}
