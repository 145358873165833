import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import Text from "antd/lib/typography/Text";
import React from "react";
import {
  TableChart as TableChartT,
  TableRecord,
} from "../../graphql/generated";

const mapRows = (d) => {
  const out = {
    id: d.id,
  };

  for (const r of d.records) {
    out[r.dataIndex] = r;
  }

  return out;
};

const compare = (a, b) => {
  if (typeof a === "number" && typeof b === "number") {
    return ((a as any) - (b as any)) as any;
  } else {
    return String(a).localeCompare(String(b));
  }
};

export default function TableChart({ table }: { table: TableChartT }) {
  const columns: ColumnsType<{
    [key: string]: TableRecord;
  }> = table.columns.map((c, i) => ({
    key: c.id,
    dataIndex: c.id,
    title: c.title,
    defaultSortOrder: i === 0 ? "ascend" : undefined,
    sorter: {
      compare: (_a, _b) => {
        const a = _a[c.id].value,
          b = (_b = _b[c.id].value);

        if (a instanceof Array && b instanceof Array) {
          return compare(a[0], b[0]);
        } else {
          return compare(a, b);
        }
      },
    },
    render: (v: TableRecord, a, i) => {
      return v.formatted
        ? v.formatted.split("\n").map((f) => (
            <>
              <Text>{f}</Text>
              <br />
            </>
          ))
        : v.value;
    },
  }));

  const dataSource = table.data.map(mapRows);

  return <Table columns={columns} dataSource={dataSource}></Table>;
}
