import { ClockCircleFilled, ClockCircleOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import { differenceInMinutes } from "date-fns/esm";
import moment from "moment";
import React from "react";

export default function PickupAtDisplay({
  status,
  initialPickupAt,
  pickupAt,
  riderPickedUpTime,
  pickupDelayedByPartner,
  scheduledFor,
}: {
  status: any;
  riderPickedUpTime?: Date;
  pickupAt?: Date;
  scheduledFor?: Date;
  initialPickupAt?: Date;
  pickupDelayedByPartner?: boolean;
}) {
  if (!pickupAt && !initialPickupAt) return null;

  const time = pickupAt ? moment(pickupAt) : moment(initialPickupAt);
  const now = moment();
  const diff = time.diff(now, "minutes");

  const minutesUntilScheduled = scheduledFor
    ? differenceInMinutes(new Date(scheduledFor), new Date())
    : null;
  const isScheduled = minutesUntilScheduled && minutesUntilScheduled > 30;

  return (
    <>
      <Tooltip
        title={
          <Text
            style={{
              color: "white",
            }}
          >
            {time.toDate().toLocaleString()}{" "}
            {riderPickedUpTime
              ? `- Picked Up at ${new Date(riderPickedUpTime).toLocaleString()}`
              : ""}
          </Text>
        }
      >
        {riderPickedUpTime &&
        ["COMPLETE", "CANCELLED", "EN_ROUTE", "AWAITING_CUSTOMER"].includes(
          status
        ) ? (
          <Text type="secondary">Collected</Text>
        ) : isScheduled ? (
          <Tag icon={<ClockCircleOutlined />}>Scheduled</Tag>
        ) : (
          <Text
            strong
            type={diff < 0 ? "danger" : diff < 7 ? "warning" : "success"}
          >
            {time.fromNow()}

            {pickupDelayedByPartner && (
              <Tooltip title="Pickup was delayed by partner">
                <ClockCircleFilled
                  style={{ verticalAlign: "middle", marginLeft: 6 }}
                />
              </Tooltip>
            )}
          </Text>
        )}
      </Tooltip>
    </>
  );
}
