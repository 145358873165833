import {
  Alert,
  Badge,
  Button,
  Descriptions,
  Divider,
  Drawer,
  message,
  PageHeader,
  Select,
  Table,
  Tag,
} from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import { $, RestaurantUpdateInput } from "../../../graphql/generated";
import { useTypedMutation, useTypedQuery } from "../../../graphql/hooks";
import { UPDATE_PARTNER } from "../../../graphql/mutations";
import { price } from "../../../utils/price";
import { useUpdateItemKey } from "../../../utils/update-item-key";
import CommissionPlans from "./CommissionPlans";

export default function PartnerCommission({ partnerId }) {
  const { data, error, refetch } = useTypedQuery(
    {
      Restaurant: [
        {
          id: $`id`,
        },
        {
          id: true,
          activeCommissionPlan: {
            id: true,
            ended: true,
            created: true,
            plan: {
              id: true,
              description: true,
              slug: true,
              rungs: {
                id: true,
                name: true,
                description: true,
                fixedBase: true,
                percentage: true,
                percentageCollection: true,
                percentageTableService: true,
                deliveryFeeBaseDiff: true,
                deliveryFeePerKMDiff: true,
              },
            },
            rung: {
              id: true,
              name: true,
              description: true,
              fixedBase: true,
              percentage: true,
              percentageCollection: true,
              percentageTableService: true,
              deliveryFeeBaseDiff: true,
              deliveryFeePerKMDiff: true,
            },
          },
          commissionPlans: {
            id: true,
            ended: true,
            created: true,
            plan: {
              id: true,
              description: true,
              slug: true,
            },
            rung: {
              id: true,
              name: true,
            },
          },
        },
      ],
    },
    {
      variables: {
        id: partnerId,
      },
    }
  );

  const [updateRestaurant, { loading }] = useTypedMutation(UPDATE_PARTNER, {
    onCompleted: () => {
      message.success("Assigned new plan!");
      refetch();
    },
  });
  const updateItemKey = useUpdateItemKey<RestaurantUpdateInput>(
    updateRestaurant,
    "restaurant",
    partnerId
  );

  const [showSwitcher, setShowSwitcher] = useState(false);

  const plan = data?.Restaurant.activeCommissionPlan;
  const plans = data?.Restaurant.commissionPlans;

  return (
    <>
      <PageHeader
        title={
          plan ? (
            <>
              <Title level={5}>
                {plan.plan.slug}{" "}
                <Tag color="green">
                  Started {new Date(plan.created).toLocaleString()}
                </Tag>
              </Title>
            </>
          ) : (
            <Alert
              type={error ? "error" : "info"}
              message={
                error
                  ? error.message
                  : "This partner does not have a commission plan"
              }
            ></Alert>
          )
        }
        extra={[
          <Button type="primary" onClick={() => setShowSwitcher(true)}>
            Change Plan
          </Button>,
          plan && (
            <Button
              type="primary"
              danger
              onClick={() => updateItemKey("commissionPlanId", null!)}
            >
              Remove Plan
            </Button>
          ),
        ]}
      >
        {plan && (
          <Descriptions bordered style={{ marginBottom: 16 }} column={1}>
            <Descriptions.Item label="Rung" span={24}>
              <Select
                value={plan.rung.id}
                loading={loading}
                onChange={(id) => updateItemKey("commissionPlanRungId", id)}
              >
                {plan.plan.rungs.map((r) => (
                  <Select.Option key={r.id} value={r.id}>
                    {r.name} -{" "}
                    <Text type="secondary">
                      {r.fixedBase}p | {r.percentage}%
                    </Text>
                    {" - "}
                    <Text type="secondary">
                      {price(r.deliveryFeeBaseDiff)} |{" "}
                      {price(r.deliveryFeePerKMDiff)}/KM
                    </Text>
                  </Select.Option>
                ))}
              </Select>
            </Descriptions.Item>
            <Descriptions.Item label="Description" span={2}>
              {plan.rung.description}
            </Descriptions.Item>
            <Descriptions.Item label="Fixed Base">
              {plan.rung.fixedBase}
            </Descriptions.Item>
            <Descriptions.Item label="Percentage (Delivery)">
              {plan.rung.percentage}
            </Descriptions.Item>
            <Descriptions.Item label="Percentage (Collection)">
              {plan.rung.percentageCollection}
            </Descriptions.Item>
            <Descriptions.Item label="Percentage (Table Service)">
              {plan.rung.percentageTableService}
            </Descriptions.Item>
            <Descriptions.Item label="Delivery Fee Base Diff">
              {plan.rung.deliveryFeeBaseDiff}
            </Descriptions.Item>
            <Descriptions.Item label="Delivery Fee Per KM Diff">
              {plan.rung.deliveryFeePerKMDiff}
            </Descriptions.Item>
          </Descriptions>
        )}
        {plans && (
          <Table
            dataSource={plans}
            columns={[
              {
                title: "Started",
                dataIndex: "created",
                render: (r) => new Date(r).toLocaleString(),
              },
              {
                title: "Slug",
                render: (_, r) => r.plan.slug,
              },
              {
                title: "Rung",
                render: (_, r) => r.rung.name,
              },
              {
                title: "Ended",
                render: (_, r) => (
                  <Badge
                    status={r.ended ? "success" : "processing"}
                    text={
                      r.ended ? new Date(r.ended).toLocaleString() : "Active"
                    }
                  />
                ),
              },
            ]}
          />
        )}
      </PageHeader>
      <Drawer
        width={768}
        visible={showSwitcher}
        onClose={() => setShowSwitcher(false)}
      >
        <CommissionPlans
          onSelect={(r) => {
            updateItemKey("commissionPlanId", r.id);
            setShowSwitcher(false);
          }}
        />

        <Divider />
      </Drawer>
    </>
  );
}
