import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import React, { useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { FormInput } from "shards-react";
import { $, Zeus } from "../../graphql/generated";
import { LoadingButton } from "../LoadingButton";

export const MenuImport = ({ menuId }: { menuId: string }) => {
  const [importMutation, { data, loading, error }] = useMutation(
    gql(
      Zeus.mutation({
        importMenu: [
          {
            id: $`id`,
            url: $`url`,
          },
          true,
        ],
      })
    )
  );

  const [url, setUrl] = useState("");

  return (
    <div>
      <Form>
        <label>URL</label>
        <FormInput
          value={url}
          onChange={(e) => setUrl(e.currentTarget.value)}
          style={{
            marginBottom: 8,
          }}
        />

        <LoadingButton
          loading={loading}
          onClick={() =>
            importMutation({
              variables: {
                id: menuId,
                url,
              },
            })
          }
        >
          Start Import Job
        </LoadingButton>
        {error ? (
          <Alert variant="danger" style={{ marginTop: 8 }}>
            {JSON.stringify(error.graphQLErrors[0].message)}
          </Alert>
        ) : null}
        {data ? (
          <Alert variant="success" style={{ marginTop: 8 }}>
            Import Job Started!
          </Alert>
        ) : null}
      </Form>
    </div>
  );
};
