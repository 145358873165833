import { PlusOutlined } from "@ant-design/icons/lib/icons";
import { Button, Form, Input, message, Modal } from "antd";
import React, { useState } from "react";
import { $ } from "../../../graphql/generated";
import { useTypedMutation } from "../../../graphql/hooks";

export const CreateRiderReferralDiscountButton = ({
  riderId,
}: {
  riderId: string;
}) => {
  const [form] = Form.useForm();

  const [isModalShowing, setIsModalShowing] = useState(false);

  const [createReferral] = useTypedMutation(
    {
      createRiderReferralDiscount: [
        {
          riderId: $`riderId`,
          code: $`code`,
        },
        {
          id: true,
        },
      ],
    },
    {
      onCompleted: () => {
        message.success("Rider referral code created");
      },
      onError: (err: Error) => {
        message.error(err.message);
      },
    }
  );

  const submitForm = () => {
    form.validateFields().then((values) => {
      console.log(values);
      const { riderId, code } = values;

      createReferral({
        variables: {
          riderId,
          code,
        },
      });
    });
  };

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setIsModalShowing(true);
        }}
      >
        Associate Code with Rider
      </Button>
      <Modal
        title={"Add Referral Code"}
        visible={isModalShowing}
        cancelText={"Cancel"}
        okText={"Create"}
        onCancel={() => {
          setIsModalShowing(false);
        }}
        onOk={submitForm}
      >
        <Form form={form}>
          <Form.Item label="Rider ID" name="riderId" initialValue={riderId}>
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Discount Code"
            name="code"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
