import L from "leaflet";
// Global imports
import { } from "mapbox-gl";
import { } from "mapbox-gl-leaflet";
import React from "react";
import {
    GridLayer,
    Map as _Map,
    MapProps,
    TileLayer,
    withLeaflet
} from "react-leaflet";

const MapboxGL = withLeaflet(
  class MapboxGlLayer extends GridLayer {
    private _layer;

    constructor(props) {
      super(props);

      this._addLayer = this._addLayer.bind(this);
      this._removeLayer = this._removeLayer.bind(this);
    }

    createLeafletElement(props) {
      const { map } = props.leaflet || this.context;

      map.on("layeradd", (e) => {
        this._addLayer(e);
      });

      map.on("layerremove", (e) => {
        this._removeLayer();
      });

      return (L as any).mapboxGL(props);
    }

    _addLayer({ layer }) {
      this._layer = layer;
      const { _map } = this._layer;

      if (_map) {
        // Force a resize calculation on the map so that
        // Mapbox GL layer correctly repaints its height after it has been added.
        setTimeout(_map._onResize, 200);
      }
    }

    _removeLayer() {
      this._layer = null;
    }
  }
);

function Map(
  { children, ...props }: MapProps & { mapType?: "gl" | "raster" },
  ref
) {
  return (
    <_Map ref={ref} {...props}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://maps.ecoeats.uk/tile/{z}/{x}/{y}.png"
      />

      {children}
    </_Map>
  );
}

export default React.forwardRef(Map);
