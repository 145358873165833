import { Badge, Card, Tag } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import QueueAnim from "rc-queue-anim";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Basket, Ordering } from "../graphql/generated";
import { useTypedQuery } from "../graphql/hooks";
import { BASKET_LIST } from "../graphql/queries";
import { price } from "../utils/price";

export default function UpcomingBaskets({ zoneIds }: { zoneIds?: string[] }) {
  const [page, setSkip] = useState(1);
  const [take, setTake] = useState(50);
  const [order, setOrder] = useState(Ordering.DESC);
  const [sortBy, setSortBy] = useState<keyof Basket>("modified");

  const { data } = useTypedQuery(BASKET_LIST, {
    variables: {
      skip: (page - 1) * take!,
      take,
      sortBy,
      order,
      zoneIds,
      modifiedInLast: 2,
      complete: false,
    },
    pollInterval: 2500,
  });

  const list = data?.BasketList;

  return (
    <Card>
      <div
        style={{
          height: 100,
        }}
      >
        <Badge count={list?.total} offset={[4, -3]}>
          <Title level={5}>{"Upcoming Orders"}</Title>
        </Badge>
        <QueueAnim
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            overflowX: "auto",
          }}
          type={["right", "left"]}
        >
          {list?.items.map((i) => (
            <Tag color="default" key={i.id}>
              <div>
                <Text>{i.restaurant.name}</Text> |{" "}
                <Text>{price(i.totalPrice)}</Text> |{" "}
                <Text>{i.numberOfItems} items</Text>
              </div>
              <div>
                <Text>
                  {i.fulfillmentMethod === "DELIVERY"
                    ? `${(i.distanceDuration?.distance.value / 1000).toFixed(
                        1
                      )} Km`
                    : i.fulfillmentMethod}{" "}
                  |{" "}
                  {i.customer ? (
                    <Link to={`/customer/${i.customer.id}`}>
                      {i.customer.name.split(" ")[0]}
                    </Link>
                  ) : (
                    "Anonymous"
                  )}
                </Text>
              </div>
              <Badge
                text={i.checkedOutAt ? "Checked Out" : "In Basket"}
                status={i.checkedOutAt ? "success" : "processing"}
              />
            </Tag>
          ))}
        </QueueAnim>
      </div>
    </Card>
  );
}
