import { Button, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  $,
  MapType,
  PartnerTabletActionType,
  PartnerTabletSyncArgs,
  Query,
} from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";
import { PARTNER_TABLET } from "../../graphql/queries";
import { getCDNUrl } from "../../utils/image-url";

const confi = {
  iceServers: [
    {
      urls: ["stun:stun1.l.google.com:19302", "stun:stun2.l.google.com:19302"],
    },
  ],
  iceCandidatePoolSize: 10,
};
let pc = new RTCPeerConnection(confi);

let ourOffer;

export default function WebRTCViewer({
  offer,
  tablet,
}: {
  offer?: any;
  tablet: MapType<Query, typeof PARTNER_TABLET>["PartnerTablet"];
}) {
  const [tabletSync] = useTypedMutation({
    PartnerTabletSync: [
      {
        partnerId: $`partnerId`,
        tablet: $`tablet`,
      },
      {
        id: true,
      },
    ],
  });

  const [action] = useTypedMutation({
    PartnerTabletAction: [
      {
        id: tablet.id,
        action: PartnerTabletActionType.TOUCH_SCREEN,
        payload: $`payload`,
      },
      {
        id: true,
      },
    ],
  });

  const [enableTouches, setEnableTouches] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);

  const mediaStream = useRef(new MediaStream());
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const preRef = useRef<HTMLPreElement | null>(null);

  const [progress, setProgress] = useState<string[]>([]);

  const wipeRTCSession = async () => {
    const args: PartnerTabletSyncArgs = {
      manufacturer: tablet.manufacturer,
      model: tablet.model,
      serial: tablet.serial,
      webRTCOfferReceiveDescription: null,
      webRTCOfferDescription: null,
      webRTCOfferCandidates: null,
      webRTCOfferReceiveCandidates: null,
    };

    await tabletSync({
      variables: {
        tablet: args,
        partnerId: tablet.partnerId,
      },
    });

    pc.close();
    if (videoRef.current) videoRef.current.srcObject = null;
    mediaStream.current = new MediaStream();
  };

  useEffect(() => {
    if (preRef.current) preRef.current.scrollTop = preRef.current.scrollHeight;
  }, [progress]);

  useEffect(() => {
    if (!offer) return;
    (async () => {
      if (pc) {
        for (const c of tablet.webRTCOfferCandidates) {
          await pc.addIceCandidate(c).catch(console.log);
        }
      }
    })();
  }, [tablet.webRTCOfferCandidates]);

  useEffect(() => {
    if (!offer) return;
    (async () => {
      pc.close();
      pc = new RTCPeerConnection(confi);

      if (videoRef.current) videoRef.current.srcObject = null;
      mediaStream.current = new MediaStream();

      setProgress(["setting up objects"]);
      pc.onicecandidate = (c) => {
        if (c.candidate) {
          const args: PartnerTabletSyncArgs = {
            manufacturer: tablet.manufacturer,
            model: tablet.model,
            serial: tablet.serial,
            webRTCOfferReceiveCandidates: [c.candidate],
          };
          tabletSync({
            variables: {
              tablet: args,
              partnerId: tablet.partnerId,
            },
          });
          setProgress((p) => [...p, `ice: ${c.candidate?.candidate}`]);
        }
      };
      pc.ontrack = (e) => {
        console.log(e);
        mediaStream.current.addTrack(e.track);
        setIsStreaming(true);
        setProgress((p) => [...p, `new track loaded`]);
      };

      pc.onconnectionstatechange = () => {
        setProgress((p) => [...p, `state: ${pc.connectionState}`]);
      };
      pc.oniceconnectionstatechange = () => {
        setProgress((p) => [...p, pc.iceConnectionState]);
      };

      if (videoRef.current) videoRef.current.srcObject = mediaStream.current;

      await pc.setRemoteDescription(new RTCSessionDescription(offer));
      setProgress((p) => [...p, "installed remote description"]);
      const answer = await pc.createAnswer({
        offerToReceiveVideo: true,
        offerToReceiveAudio: true,
      });
      await pc.setLocalDescription(answer);
      setProgress((p) => [...p, "installed local description"]);

      console.log(answer);
      const args: PartnerTabletSyncArgs = {
        manufacturer: tablet.manufacturer,
        model: tablet.model,
        serial: tablet.serial,
        webRTCOfferReceiveDescription: { sdp: answer.sdp, type: answer.type },
      };

      await tabletSync({
        variables: {
          tablet: args,
          partnerId: tablet.partnerId,
        },
      });
      setProgress((p) => [...p, "synced answer with coordinator"]);
    })();

    return () => wipeRTCSession();
  }, [JSON.stringify(offer)]);

  return (
    <div
      style={{
        display: "flex",
        marginTop: 8,
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "inline-block",
        }}
      >
        <div
          style={{
            border: "4px solid black",
            borderRadius: 8,
          }}
        >
          <div
            style={{
              height: tablet.height || 500,
              width: tablet.width || 300,
            }}
            onClick={(e) => {
              const deviceScale = 2;
              // e = Mouse click event.
              var rect = e.currentTarget.getBoundingClientRect();
              var x = e.clientX - rect.left; //x position within the element.
              var y = e.clientY - rect.top; //y position within the element.
              console.log("Left? : " + x + " ; Top? : " + y + ".");
              if (enableTouches)
                action({
                  variables: {
                    payload: {
                      x,
                      y,
                    },
                  },
                });
            }}
          >
            <video
              ref={(r) => (videoRef.current = r)}
              playsInline
              autoPlay
              poster={
                tablet.screenshots[0]
                  ? getCDNUrl(tablet.screenshots[0].key)
                  : undefined
              }
              height={tablet.height || "100%"}
              width={tablet.width || "100%"}
            ></video>
          </div>
        </div>
      </div>

      <div
        style={{
          padding: 8,
        }}
      >
        <Space direction="vertical">
          <Button
            onClick={() => setEnableTouches((t) => !t)}
            danger={!enableTouches}
          >
            {enableTouches ? "Disable" : "Enable"} Touches
          </Button>
          {isStreaming && (
            <Button
              onClick={() => {
                wipeRTCSession();
              }}
            >
              End Stream
            </Button>
          )}
          <pre
            ref={(ref) => (preRef.current = ref)}
            style={{
              maxHeight: 200,
              maxWidth: 300,
              overflow: "auto",
            }}
          >
            {progress.join("\n")}
          </pre>
        </Space>
      </div>
    </div>
  );
}
