import { UserOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, PageHeader, Row, Tabs } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container } from "shards-react";
import SmallStats from "../components/common/SmallStats";
import { CreateSuperAdmin } from "../components/forms/CreateSuperAdmin";
import DynamicForm from "../components/forms/DynamicForm";
import IncidentTable from "../components/incident/IncidentTable";
import PermissionsGuard from "../components/layout/PermissionsGuard";
import OrderRiderReportTable from "../components/order/OrderRiderReportTable";
import { OrdersTable } from "../components/OrdersTable";
import LeaseVehicleTable from "../components/vehicle/VehicleTable";
import { $, Permissions } from "../graphql/generated";
import { useTypedQuery } from "../graphql/hooks";
import useMe from "../hooks/useMe";
import useTabSync from "../hooks/useTabSync";

const SS: any = SmallStats;

const mapAnalyticsToBox = ({ label, value, percentage, increase }) => ({
  label,
  value,
  percentage,
  increase,
  chartLabels: [null, null, null, null, null, null, null],
  attrs: { md: "4", sm: "6" },
  datasets: [
    {
      label: "Today",
      fill: "start",
      borderWidth: 1.5,
      backgroundColor: "rgba(0, 184, 216, 0.1)",
      borderColor: "rgb(0, 184, 216)",
      data: [1, 2, 1, 3, 5, 4, 7],
    },
  ],
});

function Overview() {
  const [smallStats, setSmallStats] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);

  const [start, setStart] = useState(moment().startOf("week"));
  const [end, setEnd] = useState(moment().endOf("week"));

  const { me } = useMe();

  const { data } = useTypedQuery(
    {
      allAnalytics: [
        {
          start: $`start`,
          end: $`end`,
          restaurantId: $`restaurantId`,
        },
        {
          customers: true,
          totalOrders: true,
          totalRestaurants: true,
        },
      ],
    },
    {
      skip: !start || !end,
      pollInterval: 3000,
      variables: {
        start,
        end,
      },
    }
  );

  useEffect(() => {
    if (data) {
      setSmallStats(
        [
          {
            label: "Restaurants",
            value: data.allAnalytics.totalRestaurants,
            percentage: 0,
            increase: true,
          },
          {
            label: "Orders",
            value: data.allAnalytics.totalOrders,
            percentage: 0,
            increase: true,
          },
          {
            label: "Customers",
            value: data.allAnalytics.customers,
            percentage: 0,
            increase: true,
          },
        ].map(mapAnalyticsToBox)
      );
    }
  }, [data]);
  const { key, onSelect } = useTabSync("analytics");

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}

      <PageHeader
        title="Overview"
        subTitle="Dashboard"
        extra={[
          me?.roles.includes(Permissions.CREATE_SUPER_ADMINS) ? (
            <Button
              type="primary"
              icon={<UserOutlined />}
              onClick={() => setShowModal(true)}
            >
              New Admin
            </Button>
          ) : undefined,
          <DatePicker.RangePicker
            value={[start, end]}
            showTime
            onChange={(v) => {
              if (v) {
                setStart(v[0]);
                setEnd(v[1]);
              }
            }}
          />,
        ]}
      />

      <Row>
        <Col flex={1}>
          <Tabs activeKey={key} onChange={onSelect} id="charity-nav">
            <Tabs.TabPane key="analytics" tab="Analytics">
              {/* Small Stats Blocks */}
              <Row gutter={32} style={{ marginBottom: 16 }}>
                {smallStats.map((stats, idx) => (
                  <Col key={stats.label} md={8}>
                    <SS
                      id={`small-stats-${idx}`}
                      variation="1"
                      chartData={stats.datasets}
                      chartLabels={stats.chartLabels}
                      label={stats.label}
                      value={stats.value}
                      percentage={stats.percentage}
                      increase={stats.increase}
                      decrease={stats.decrease}
                    />
                  </Col>
                ))}
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane key="incidents" tab="Incidents">
              <PermissionsGuard permissions={["MANAGE_INCIDENTS"]}>
                <IncidentTable />
              </PermissionsGuard>
            </Tabs.TabPane>
            <Tabs.TabPane key="orders" tab="Orders">
              {key === "orders" && <OrdersTable />}
            </Tabs.TabPane>
            <Tabs.TabPane key="order-reports" tab="Rider Reports">
              {key === "order-reports" && <OrderRiderReportTable />}
            </Tabs.TabPane>
            <Tabs.TabPane key="vehicles" tab="Vehicles">
              {key === "vehicles" && <LeaseVehicleTable />}
            </Tabs.TabPane>
            <Tabs.TabPane key="team" tab="Team">
              <DynamicForm id="Team" />
            </Tabs.TabPane>
            <Tabs.TabPane key="rider-fraud" tab="Rider Fraud">
              {key === "rider-fraud" && <DynamicForm id="RiderFraud.Live" />}
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>

      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        title="New Admin"
        footer={null}
      >
        <CreateSuperAdmin onSuccess={() => setShowModal(false)} />
      </Modal>
    </Container>
  );
}

export default Overview;
