export const price = (value: number) => `£${(value / 100)?.toFixed(2)}`;
export const CURRENCY_SYMBOLS = ["£"];

export const parseCurrency = (c: string) => {
  let [pounds, pennies] = c.split(".").map((n) => Number.parseInt(n, 10));

  if (Number.isNaN(pounds)) pounds = 0;
  if (Number.isNaN(pennies) || pennies == undefined) pennies = 0;

  pennies = Math.max(Math.min(pennies, 99), 0);
  pounds = Math.max(pounds, 0);

  return pounds * 100 + pennies;
};

export const parseNegatableCurrency = (c: string) => {
  let [pounds, pennies] = c.split(".").map((n) => Number.parseInt(n, 10));

  if (Number.isNaN(pounds)) pounds = 0;
  if (Number.isNaN(pennies) || pennies == undefined) pennies = 0;

  pennies = Math.min(Math.abs(pennies), 99);

  return pounds < 0 ? 
    pounds * 100 - pennies
    : pounds * 100 + pennies;
};

export function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Bytes";
  var i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
}

