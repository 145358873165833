import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import Table, { ColumnsType } from "antd/lib/table";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import { endOfDay, startOfDay, subDays } from "date-fns";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { $, RiderAdjustmentUpdateInput } from "../../graphql/generated";
import { query, useTypedMutation, useTypedQuery } from "../../graphql/hooks";
import { useDebounce } from "../../hooks/useDebounce";
import { camel2title } from "../../utils";
import {
  convertToCSV,
  download,
  filterAndSortTableData,
} from "../../utils/export-table";
import { price } from "../../utils/price";
import { useUpdateItemKey } from "../../utils/update-item-key";

const RIDER_ADJUSTMENT_LIST = query({
  RiderAdjustmentList: [
    {
      order: $`order`,
      sortBy: $`sortBy`,
      riderId: $`riderId`,
      skip: $`skip`,
      take: $`take`,
      startDate: $`startDate`,
    },
    {
      total: true,
      items: {
        id: true,
        created: true,
        amount: true,
        explanation: true,
        forDate: true,
        riderId: true,
      },
    },
  ],
});

export const RiderAdjustmentTable = ({ riderId, startDate, onChange }) => {
  const [page, setSkip] = useState(1);
  const [take, setTake] = useState(25);
  const [sortBy, setSortBy] = useState("lastConnection");
  const [order, setOrder] = useState("DESC");
  const [_query, setQuery] = useState("");
  const query = useDebounce(_query, 500);

  const { data, refetch } = useTypedQuery(RIDER_ADJUSTMENT_LIST, {
    variables: {
      riderId,
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
      startDate,
    },
  });

  const [update] = useTypedMutation(
    {
      updateRiderAdjustment: [
        {
          id: $`id`,
          input: $`input`,
        },
        {
          ...RIDER_ADJUSTMENT_LIST.RiderAdjustmentList[1].items,
        },
      ],
    },
    {
      onCompleted: () => {
        message.success("Saved!");
        if (onChange) onChange();
      },
    }
  );

  const [create] = useTypedMutation(
    {
      createRiderAdjustment: [
        {
          adjustment: $`adjustment`,
        },
        {
          ...RIDER_ADJUSTMENT_LIST.RiderAdjustmentList[1].items,
        },
      ],
    },
    {
      onCompleted: () => {
        message.success("Created!");
        setShowModal(false);
        refetch();
        if (onChange) onChange();
      },
    }
  );

  const updateAdjustment = useUpdateItemKey<RiderAdjustmentUpdateInput>(
    update,
    "input"
  );

  const [showModal, setShowModal] = useState(false);

  const onFinish = useCallback(
    (v) => create({ variables: { adjustment: { ...v, riderId } } }),
    [create, riderId]
  );

  return (
    <>
      <Table
        dataSource={data?.RiderAdjustmentList.items}
        pagination={{
          total: data?.RiderAdjustmentList.total,
          current: page,
          pageSize: take,
          onChange: (p, ps) => {
            setTake(ps!);
            setSkip(p);
          },
          showSizeChanger: true,
        }}
        title={() => (
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            <div style={{ flex: 1 }}>
              <Title level={4} style={{ flex: 1 }}>
                Rider Adjustments
              </Title>
              <Text type="secondary">
                One-off positive or negative adjustments that cannot be
                expressed as an order adjustment.
              </Text>
            </div>
            <Button onClick={() => setShowModal(true)} icon={<PlusOutlined />}>
              Create Adjustment
            </Button>
          </div>
        )}
        columns={[
          {
            title: "Date",
            dataIndex: "forDate",
            render: (d) => new Date(d).toLocaleString(),
          },
          {
            title: "Amount",
            render: (e) => (
              <>
                £
                <Text
                  editable={{
                    maxLength: 30,
                    onChange: (t) =>
                      updateAdjustment(
                        "amount",
                        Number.parseFloat(t) * 100,
                        e.id
                      ),
                  }}
                >
                  {(e.amount / 100 || 0).toFixed(2)}
                </Text>
              </>
            ),
          },
          {
            title: "Explanation",

            render: (e) => (
              <Text
                editable={{
                  onChange: (t) => updateAdjustment("explanation", t, e.id),
                }}
              >
                {e.explanation}
              </Text>
            ),
          },
        ]}
      />
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item
            name="forDate"
            label="Date"
            help="When was the incident that led to this adjustment being required? When an invoice is generated, it will only include adjustments that have a date within their period."
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            name="amount"
            label="Amount"
            help="How much is this adjustment for? Postive values pay the rider more, negative pay them less. Amount is in pence."
            normalize={(n) => Math.round(n)}
            rules={[{ required: true }]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            name="explanation"
            label="Explanation"
            help="Why are you making this adjustment?"
            rules={[
              { required: true },
              { min: 15, message: "Enter a longer, more descriptive reason " },
            ]}
          >
            <Input />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Create Adjustment
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export const RiderFinancials = ({ riderId }) => {
  const [startDate, setStartDate] = useState(
    startOfDay(subDays(new Date(), 1))
  );
  const [endDate, setEndDate] = useState(endOfDay(new Date()));
  const { data, loading, refetch } = useTypedQuery(
    {
      financialsForRider: [
        {
          endDate: $`endDate`,
          startDate: $`startDate`,
          riderId: $`riderId`,
        },
        {
          adjustments: true,
          numberOfOrders: true,
          riderEarningsSum: true,
          riderTips: true,
          extraFromSlots: true,
          totalPayout: true,
          extraFromReferrals: true,
          bookingStats: {
            id: true,
            riderId: true,
            bookingId: true,
            bookingSlotId: true,
            guaranteedRiderEarnings: true,
            onlinePercentage: true,
            orderRiderEarnings: true,
            ordersAccepted: true,
            ordersOffered: true,
          },
        },
      ],
    },
    {
      variables: {
        ...(riderId ? { riderId } : {}),
        startDate,
        endDate,
      },
    }
  );

  const keys = data?.financialsForRider
    ? Object.keys(data.financialsForRider)
    : [];

  const dataSource = data ? [data?.financialsForRider] : [];

  const notToPrice = ["riderId", "numberOfOrders"];

  const COLUMNS: ColumnsType<NonNullable<typeof data>["financialsForRider"]> = [
    ...keys
      .filter((k) => k !== "bookingStats" && k !== "__typename")
      .map((k) => ({
        title: camel2title(k),
        dataIndex: k,
        sorter: {
          compare: (a, b) => a[k] - b[k],
          multiple: 1,
        },
        render: (r) => (notToPrice.includes(k) ? r : price(r)),
      })),
  ];

  const asCSV = useCallback(() => {
    const sorted = filterAndSortTableData(dataSource, COLUMNS as any);
    const csv = convertToCSV(sorted);
    download(
      `ecoeats-rider-earnings-${startDate.toDateString()}-${endDate.toDateString()}.csv`,
      csv
    );
  }, [dataSource, COLUMNS]);

  return (
    <Card
      style={{ overflowX: "auto", padding: 0 }}
      bodyStyle={{
        padding: 0,
      }}
      title={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Title level={4} style={{ flex: 1 }}>
            Rider Earnings
          </Title>

          <DatePicker.RangePicker
            value={[moment(startDate), moment(endDate)]}
            onChange={(v) => {
              if (v && v[0] && v[1]) {
                setStartDate(startOfDay(v[0]?.toDate()));
                setEndDate(endOfDay(v[1]?.toDate()));
              }
            }}
            style={{
              marginRight: 8,
            }}
          />

          <Button onClick={asCSV}>Download CSV</Button>
        </div>
      }
    >
      <Table
        dataSource={dataSource}
        showSorterTooltip
        loading={loading}
        columns={COLUMNS}
        expandable={{
          expandedRowRender: (record) => (
            <pre>{JSON.stringify(record.bookingStats, null, 2)}</pre>
          ),
          rowExpandable: (record) => !!record.bookingStats.length,
        }}
        pagination={{
          pageSize: 25,
        }}
      />

      <RiderAdjustmentTable
        riderId={riderId}
        startDate={startDate}
        onChange={refetch}
      />
    </Card>
  );
};
