import { CheckCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { Button, Card, Col, message, Modal, PageHeader, Row } from "antd";
import Title from "antd/lib/typography/Title";
import { LatLngTuple } from "leaflet";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Map, Polygon, TileLayer } from "react-leaflet";
import { useTimeoutFn } from "react-use";
import { $, MapType, Ordering, Query } from "../../graphql/generated";
import {
  mutation,
  query,
  useTypedMutation,
  useTypedQuery,
} from "../../graphql/hooks";
import { ZONE } from "../../graphql/queries";
import { getCenterPolygon } from "../../utils/sphere";
import CreatePolygonForm from "./CreatePolygonForm";

const ZONE_POLYGON_LIST = query({
  ZonePolygonList: [
    {
      order: $`order`,
      sortBy: $`sortBy`,
      zoneId: $`zoneId`,
    },
    {
      total: true,
      items: {
        id: true,
        created: true,
        updated: true,
        maxCycleTime: true,
        maxDriveTime: true,
        polygon: {
          type: true,
          coordinates: true,
        },
      },
    },
  ],
});

type Result = MapType<
  Query,
  typeof ZONE_POLYGON_LIST
>["ZonePolygonList"]["items"][0];
function ZonePolygonCard(
  props: Result & { active: boolean; onClick: (id: string) => void }
) {
  const initialPosition = useMemo(
    () => getCenterPolygon(props.polygon.coordinates[0]) as LatLngTuple,
    []
  );

  const onCardClick = useCallback(() => props.onClick(props.id), [
    props.onClick,
    props.id,
  ]);

  const mapRef = useRef<Map | null>(null);
  const polygonRef = useRef<Polygon | null>(null);

  const fitPolygon = useCallback(() => {
    if (polygonRef.current) {
      mapRef.current?.leafletElement.fitBounds(
        polygonRef.current?.leafletElement.getBounds()
      );
    }
  }, []);

  useTimeoutFn(() => fitPolygon(), 100);

  return (
    <Card
      cover={
        <Map ref={mapRef} center={initialPosition} zoom={10}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://maps.ecoeats.uk/tile/{z}/{x}/{y}.png"
          />
          <Polygon
            ref={polygonRef}
            positions={props.polygon.coordinates[0]}
            color="green"
          />
        </Map>
      }
      hoverable
    >
      <Title level={5}>
        {(props.maxCycleTime / 60).toFixed(1)} mins from center to edge
      </Title>
      <Button
        onClick={onCardClick}
        type="primary"
        disabled={props.active}
        icon={<CheckCircleFilled />}
      >
        {props.active ? "Active!" : "Make Active"}
      </Button>
    </Card>
  );
}

const SET_ACTIVE_POLYGON = mutation({
  setActivePolygon: [
    {
      polygonId: $`polygonId`,
      zoneId: $`zoneId`,
    },
    {
      ...ZONE.zone[1],
    },
  ],
});

export default function ZonePolygonLibrary({
  zoneId,
  activePolygonId,
}: {
  zoneId: string;
  activePolygonId: string;
}) {
  const { data, refetch } = useTypedQuery(ZONE_POLYGON_LIST, {
    variables: { zoneId, order: Ordering.ASC, sortBy: "created" },
  });
  const [showAddPolygonModal, setShowAddPolygonModal] = useState(false);

  const onCompleted = useCallback(() => {
    setShowAddPolygonModal(false);
    message.success("Created a new polygon!");
    refetch();
  }, []);

  const [setPolygon, setPolygonStatus] = useTypedMutation(SET_ACTIVE_POLYGON);

  const onClick = useCallback(
    (polygonId) =>
      setPolygon({
        variables: {
          zoneId,
          polygonId,
        },
      }),
    []
  );

  return (
    <div>
      <PageHeader
        title={`${data?.ZonePolygonList.total} Available Polygons`}
        extra={[
          <Button
            onClick={() => setShowAddPolygonModal(true)}
            icon={<PlusCircleFilled />}
          >
            Add Polygon
          </Button>,
        ]}
      ></PageHeader>
      <Row gutter={16}>
        {data?.ZonePolygonList.items.map((p) => (
          <Col key={p.id} md={8}>
            <ZonePolygonCard
              active={p.id === activePolygonId}
              onClick={onClick}
              {...p}
            />
          </Col>
        ))}
      </Row>

      <Modal
        footer={null}
        visible={showAddPolygonModal}
        onCancel={() => setShowAddPolygonModal(false)}
      >
        <CreatePolygonForm onCompleted={onCompleted} zoneId={zoneId} />
      </Modal>
    </div>
  );
}
