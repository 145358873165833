import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Input, message, Result, Row } from "antd";
import Form from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import Modal from "antd/lib/modal/Modal";
import React, { useCallback, useContext, useState } from "react";
import { $ } from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";
import { ZoneContext } from "../../views/Zone";
import BookingSlotTable from "../BookingSlotTable";
import DynamicForm from "../forms/DynamicForm";

const CreateConfigButton = ({ zoneId }: { zoneId: string }) => {
  const [updateZone] = useTypedMutation({
    updateZone: [
      {
        id: $`zoneId`,
        zone: {
          autoBookingSlotConfigId: $`configId`,
        },
      },
      {
        id: true,
        autoBookingSlotConfigId: true,
      },
    ],
  });

  const [createConfig, { loading }] = useTypedMutation(
    {
      createAutoBookingConfig: [
        {
          input: $`input`,
        },
        {
          id: true,
          name: true,
        },
      ],
    },
    {
      onError: (e) => message.error(e.message),
      onCompleted: (d) => {
        updateZone({
          variables: { zoneId, configId: d.createAutoBookingConfig.id },
        });
        message.success("Created config!");
      },
    }
  );

  const onFinish = useCallback(
    (v) => createConfig({ variables: { input: v } }),
    []
  );

  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        loading={loading}
        onClick={() => setShow(true)}
      >
        Create Config
      </Button>
      <Modal visible={show} onCancel={() => setShow(false)} footer={null}>
        <Form onFinish={onFinish}>
          <FormItem label="Name" name="name" required>
            <Input />
          </FormItem>

          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form>
      </Modal>
    </>
  );
};

interface Props {
  persistContextId?: string;
  visible?: boolean;
}

export default function ZoneBookings({
  persistContextId,
  visible = true,
}: Props) {
  const zone = useContext(ZoneContext);

  return (
    <div style={{ display: !visible ? "none" : undefined }}>
      <Row>
        <Col flex={1}>
          {zone && (
            <BookingSlotTable
              zoneId={zone.id}
              persistContextId={
                persistContextId
                  ? `${persistContextId}-zone-bookings`
                  : undefined
              }
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          {zone?.autoBookingSlotConfigId ? (
            <DynamicForm
              id="AutoBookingSlotConfig"
              entityId={zone?.autoBookingSlotConfigId}
            />
          ) : (
            <Result
              title="No Auto Booking Config"
              extra={[
                zone && (
                  <CreateConfigButton
                    key="zone-bookings-create-config-btn"
                    zoneId={zone.id}
                  />
                ),
              ]}
            />
          )}
        </Col>{" "}
      </Row>
    </div>
  );
}
