import React from "react";
import { MapType, Query } from "../../graphql/generated";
import { ZONE } from "../../graphql/queries";
import DynamicForm from "../forms/DynamicForm";

export default function OpsZoneAutoScaler({
  zone,
  refetch,
  visible = true,
}: {
  zone: MapType<Query, typeof ZONE>["zone"];
  refetch: Function;
  visible?: boolean;
}) {
  return (
    <div style={{ display: !visible ? "none" : undefined }}>
      <DynamicForm id="AutoScalerConfig" entityId={zone.id} />
      <DynamicForm id="BusyMonitorSettings" entityId={zone.id} />
    </div>
  );
}
