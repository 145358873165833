import { HeatMapOutlined, InfoCircleFilled } from "@ant-design/icons";
import {
  Descriptions,
  Divider,
  List,
  PageHeader,
  Space,
  Timeline,
  Tooltip,
} from "antd";
import Title from "antd/lib/typography/Title";
import L from "leaflet";
import React from "react";
import ReactDOMServer from "react-dom/server";
import QRCode from "react-qr-code";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { style } from "typestyle";
import { $ } from "../../graphql/generated";
import { query, useTypedQuery } from "../../graphql/hooks";
import { getImageUrl } from "../../utils/image-url";
import ScannedContainerTable from "./ScannedContainerTable";

const SHIPMENT = query({
  ReusableContainer: [
    {
      id: $`id`,
    },
    {
      id: true,
      uris: true,
      createdAt: true,
      type: {
        id: true,
        depth: true,
        length: true,
        width: true,
        unitPrice: true,
        name: true,
        image: {
          id: true,
          key: true,
        },
      },
      aliases: {
        id: true,
        createdAt: true,
      },
    },
  ],
});

const styles = {
  icon: style({
    display: "flex",
    borderRadius: 30,
    backgroundColor: "white",
    height: 32,
    width: 32,
    justifyContent: "center",
    alignItems: "center",
  }),
};
const LWashingIcon = L.divIcon?.({
  className: "custom-icon",
  iconAnchor: [12, 12],
  html: ReactDOMServer.renderToString(
    <div
      className={styles.icon}
      style={{
        border: `4px solid green`,
      }}
    >
      <HeatMapOutlined />
    </div>
  ),
});

export default function ContainerView() {
  const { id } = useParams();

  const { data } = useTypedQuery(SHIPMENT, {
    pollInterval: 10000,
    variables: {
      id,
    },
  });

  const type = data?.ReusableContainer.type;

  return (
    <PageHeader
      title={
        <Title
          level={5}
          style={{
            fontFamily: "monospace",
            marginBottom: 0,
          }}
        >
          {data?.ReusableContainer.id}
        </Title>
      }
      avatar={{
        style: {
          width: 128,
          height: 128,
        },
        src: data?.ReusableContainer.type.image?.key
          ? getImageUrl(data?.ReusableContainer.type.image?.key, 256, 256)
          : undefined,
      }}
      subTitle="Reusables Container"
    >
      <Descriptions bordered>
        <Descriptions.Item label="Created">
          {new Date(data?.ReusableContainer.createdAt).toLocaleString()}
        </Descriptions.Item>

        <Descriptions.Item label="Type">{type?.name}</Descriptions.Item>
        <Descriptions.Item label="Dimensions">
          {type?.length}x{type?.width}x{type?.depth}
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      <List
        header={
          <Title level={4}>
            URIs{" "}
            <Tooltip
              title={"URIs are http-only and uppercase to save QR space"}
            >
              <InfoCircleFilled />
            </Tooltip>
          </Title>
        }
        dataSource={data?.ReusableContainer.uris}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />

      <Divider />

      <Title level={4}>Aliases</Title>
      <Timeline>
        {data?.ReusableContainer.aliases.map((a) => (
          <Timeline.Item>
            <Link to="#" style={{ fontFamily: "monospace" }}>
              {a.id}
            </Link>{" "}
            | {new Date(a.createdAt).toLocaleString()}
          </Timeline.Item>
        ))}
      </Timeline>

      <Divider />

      <ScannedContainerTable containerId={id} />

      <Divider />

      <Title level={4}>Codes</Title>
      <Space>
        {data?.ReusableContainer.uris.map((u) => (
          <QRCode value={u} size={256} level="M" />
        ))}
      </Space>
    </PageHeader>
  );
}
