import { Empty, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import { RefSelectProps } from "antd/lib/select";
import Text from "antd/lib/typography/Text";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactHotkeys from "react-hot-keys";
import { useHistory } from "react-router-dom";
import { useTypedQuery } from "../../../graphql/hooks";
import { SEARCH_QUERY } from "../../../graphql/queries";
import { useDebounce } from "../../../hooks/useDebounce";
import ItemResult from "./ItemResult";
import PartnerResult from "./PartnerResult";

/**
 * A quick search.
 */
export default function Search({ zoneId }: { zoneId: string }) {
  const [showModal, setShowModal] = useState(false);
  const hide = useCallback(() => setShowModal(false), []);

  const [_query, _setQuery] = useState("");

  const onChange = useCallback((v) => _setQuery(v), []);

  const query = useDebounce(_query, 500);

  const { data, loading } = useTypedQuery(SEARCH_QUERY, {
    variables: {
      query,
      zoneId,
    },
  });

  const selectRef = useRef<RefSelectProps | null>(null);

  const handleKey = useCallback(() => {
    setShowModal(true);
  }, []);

  useEffect(() => {
    if (showModal) setTimeout(() => selectRef.current?.focus(), 500);
  }, [showModal]);

  const partners = data?.ConsumerSearch.partners;
  const items = data?.ConsumerSearch.items;

  const history = useHistory();

  return (
    <>
      <ReactHotkeys keyName="s" onKeyUp={handleKey} />

      <Modal visible={showModal} onCancel={hide} footer={null} title="Search">
        <Select
          ref={(ref) => (selectRef.current = ref)}
          showSearch
          loading={loading}
          searchValue={_query}
          onSearch={onChange}
          placeholder="Search..."
          style={{
            width: "100%",
          }}
          dropdownRender={() => (
            <>
              {!partners?.length && !items?.length ? <Empty /> : null}
              {partners?.length ? (
                <>
                  <Text strong>Partners</Text>
                  {partners.map(({ item }, i) => (
                    <PartnerResult
                      image={item.headerImageKey}
                      index={i}
                      name={item.name}
                      ratingCount={item.ratingCount}
                      ratingStars={item.ratingStars}
                      tags={item.tags}
                      onClick={() => history.push(`/restaurant/${item.id}`)}
                    />
                  ))}
                </>
              ) : null}
              {items?.length ? (
                <>
                  <Text strong>Items</Text>
                  {items.map(({ item }, i) => (
                    <ItemResult
                      image={item.headerImageKey}
                      name={item.name}
                      restaurantName={item.menu.restaurant.name}
                      index={i}
                      numRatings={item.menu.restaurant.ratingCount}
                      ratingStars={item.menu.restaurant.ratingStars}
                      onClick={() =>
                        history.push(`/restaurant/${item.menu.restaurant.id}`)
                      }
                    />
                  ))}
                </>
              ) : null}
            </>
          )}
        ></Select>
      </Modal>
    </>
  );
}
