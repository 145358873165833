import { Button, Drawer, Table, Tag } from "antd";
import React, { useState } from "react";
import { MapType, Query } from "../../../graphql/generated";
import { useTypedQuery } from "../../../graphql/hooks";
import DynamicForm from "../../forms/DynamicForm";
import { COMMISION_PLANS } from "./COMMISION_PLANS";
import CommissionPlan from "./CommissionPlan";

type PlanType = MapType<
  Query,
  typeof COMMISION_PLANS
>["CommissionPlanList"]["items"][0];

export default function CommissionPlans({
  partnerId,
  onSelect,
}: {
  partnerId?: string;
  onSelect?: (plan: PlanType) => void;
}) {
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(25);
  const [sortBy, setSortBy] = useState("slug");
  const [order, setOrder] = useState("ASC");

  const { data, refetch } = useTypedQuery(COMMISION_PLANS, {
    variables: {
      skip: (page - 1) * take!,
      take,
      sortBy,
      order,
    },
  });

  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);

  return (
    <>
      <Table
        title={() => (
          <>
            <Button type="primary" onClick={() => setShow(true)}>
              Create New
            </Button>
          </>
        )}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["25", "50", "100"],
          total: data?.CommissionPlanList.total,
          current: page,
          pageSize: take,
          onChange: (p, ps) => {
            if (ps) setTake(ps);
            setPage(p);
          },
        }}
        dataSource={data?.CommissionPlanList.items}
        columns={[
          {
            key: "slug",
            dataIndex: "slug",
            title: "Slug",
          },
          {
            dataIndex: "description",
            title: "Description",
          },
          {
            title: "Rungs",
            render: (_, a) =>
              a.rungs.map((r) => (
                <Tag key={r.id} color="cyan">
                  {r.name}
                </Tag>
              )),
          },
          {
            render: (r) => (
              <Button
                onClick={() => {
                  onSelect ? onSelect(r) : setSelectedId(r.id);
                }}
                type="link"
              >
                {onSelect ? "Select" : "Edit"}
              </Button>
            ),
          },
        ]}
      />
      <Drawer
        width={768}
        visible={show || !!selectedId}
        onClose={() => {
          setShow(false);
          setSelectedId(undefined);
        }}
      >
        {show || !!selectedId ? (
          <>
            <DynamicForm
              id={"CreateCommissionPlan"}
              entityId={selectedId}
              onComplete={() => refetch()}
            />

            {selectedId && <CommissionPlan selectedId={selectedId} />}
          </>
        ) : null}
      </Drawer>
    </>
  );
}
