import { Badge, Card, message, Select, Table, Tooltip } from "antd";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import {
  $,
  ItemUpdateRequestStatus,
} from "../../graphql/generated/graphql-zeus";
import { useTypedMutation, useTypedQuery } from "../../graphql/hooks";
import { getImageUrl } from "../../utils/image-url";
const STATUSES = [
  ItemUpdateRequestStatus.PENDING,
  ItemUpdateRequestStatus.APPROVED,
  ItemUpdateRequestStatus.REJECTED,
];

const ItemUpdateRequests = () => {
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(25);
  const [sortBy, setSortBy] = useState("created");
  const [order, setOrder] = useState("DESC");

  const [statuses, setStatuses] = useState([ItemUpdateRequestStatus.PENDING]);

  const { data: requestData, loading } = useTypedQuery(
    {
      ItemUpdateRequestList: [
        {
          order: $`order`,
          sortBy: $`sortBy`,
          skip: $`skip`,
          take: $`take`,
          statuses: $`statuses`,
        },
        {
          items: {
            id: true,
            created: true,
            description: true,
            name: true,
            imageKey: true,
            status: true,
            partner: {
              id: true,
              name: true,
            },
            item: {
              id: true,
              name: true,
              description: true,
              headerImageKey: true,
            },
          },
          hasMore: true,
          total: true,
        },
      ],
    },
    {
      variables: {
        order,
        sortBy,
        skip: (page - 1) * take,
        take,
        statuses,
      },
      pollInterval: 10000,
    }
  );

  const [updateRequest, { loading: updating }] = useTypedMutation(
    {
      updateItemUpdateRequest: [
        {
          id: $`id`,
          status: $`status`,
        },
        {
          id: true,
          status: true,
        },
      ],
    },
    {
      onCompleted: (d) => {
        message.success(
          "Status changed to " + d.updateItemUpdateRequest.status
        );
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onChange = useCallback(
    (id: string, v: ItemUpdateRequestStatus) => {
      updateRequest({
        variables: {
          id,
          status: v,
        },
      });
    },
    [updateRequest]
  );

  return (
    <Card title="Item Update Requests">
      <Table
        loading={loading}
        dataSource={requestData?.ItemUpdateRequestList.items}
        onChange={(p, f) => {
          if (f["status"]) setStatuses((f["status"] as any) || []);
        }}
        columns={[
          {
            title: "Partner",
            dataIndex: "partner",
            render: (r) => <Link to={`/restaurant/${r.id}`}> {r.name}</Link>,
          },
          {
            title: "Created",
            dataIndex: "created",
            render: (r) => new Date(r).toLocaleString(),
          },
          {
            title: "Name",
            render: (r) =>
              r.name ? (
                <>
                  <span
                    style={{
                      textDecoration: "line-through",
                      color: "lightgrey",
                    }}
                  >
                    {r.item.name}
                  </span>
                  &nbsp;<span>{r.name}</span>
                </>
              ) : (
                <Tooltip title="Unmodified">
                  {" "}
                  <span>{r.item.name}</span>
                </Tooltip>
              ),
          },
          {
            title: "Description",
            render: (r) =>
              r.description ? (
                <>
                  <span
                    style={{
                      textDecoration: "line-through",
                      color: "lightgrey",
                    }}
                  >
                    {r.item.description}
                  </span>
                  &nbsp;<span>{r.description}</span>
                </>
              ) : (
                <Tooltip title="Unmodified">
                  {" "}
                  <span>{r.item.description}</span>
                </Tooltip>
              ),
          },
          {
            title: "Image",
            dataIndex: "imageKey",
            render: (r) =>
              r && (
                <a href={getImageUrl(r)} target="_blank">
                  <img
                    style={{
                      width: 128,
                      height: 128,
                    }}
                    src={getImageUrl(r, 256, 256)}
                  />
                </a>
              ),
          },
          {
            title: "Status",
            key: "status",
            filters: Object.keys(ItemUpdateRequestStatus).map((k) => ({
              text: k,
              value: k,
            })),
            defaultFilteredValue: [ItemUpdateRequestStatus.PENDING],
            render: (s) =>
              s.status === "PENDING" ? (
                <Select
                  loading={updating}
                  onChange={(v) => onChange(s.id, v)}
                  value={s.status}
                >
                  {STATUSES.map((k) => (
                    <Select.Option key={k} value={k}>
                      &nbsp;
                      <Badge
                        text={k}
                        status={
                          k === "PENDING"
                            ? "processing"
                            : k === "REJECTED"
                            ? "error"
                            : "success"
                        }
                      />
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Badge
                  text={s.status}
                  status={s.status === "REJECTED" ? "error" : "success"}
                />
              ),
          },
        ]}
      />
    </Card>
  );
};

export default ItemUpdateRequests;
