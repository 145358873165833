import React from "react";
import LeaseTable from "../lease/LeaseTable";

export default function RiderLeases({ riderId }: { riderId: string }) {
  return (
    <div>
      <LeaseTable key={riderId} riderId={riderId} />
    </div>
  );
}
