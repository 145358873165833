import {
  CarOutlined,
  ClockCircleFilled,
  QuestionCircleFilled,
} from "@ant-design/icons";
import { Button, Descriptions } from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import Modal from "antd/lib/modal/Modal";
import Paragraph from "antd/lib/typography/Paragraph";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import { OrderStatus } from "../graphql/generated";
import { OrderBadge } from "./OrderBadge";

export const HelpButton = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        icon={<QuestionCircleFilled />}
        href="https://app.clickup.com/2494053/v/dc/2c3k5-3466/2c3k5-2206"
        target="_blank"
        type="primary"
      >
        Help
      </Button>
      <Modal
        visible={show}
        cancelText="Got It"
        onCancel={() => setShow(false)}
        okButtonProps={{ hidden: true }}
      >
        <Title level={5}>Live Orders</Title>
        <Paragraph>
          This table presents a view of all the orders in the ecoeats system,
          viewable by zone, rider and restaurant. If you are on an ops shift,
          one of the first things to do is make sure you've clicked the{" "}
          <Button>Live Mode</Button> button to filter out all completed and
          cancelled orders.
        </Paragraph>

        <Title level={5}>Columns</Title>
        <Text>
          Each column helps you quickly figure out the situation that an order
          is in.
        </Text>
        <Descriptions
          bordered
          layout="vertical"
          column={1}
          labelStyle={{
            fontWeight: "bold",
          }}
        >
          <DescriptionsItem label="Restaurant">
            The restaurant that this order was placed at.
          </DescriptionsItem>
          <DescriptionsItem label="Number">
            The semi-unique order number that the customer, restaurant and rider
            see.
          </DescriptionsItem>
          <DescriptionsItem label="Total Price">
            The total price of the order, including all fees. Exceptionally
            large orders (£100+) may require close attention.
          </DescriptionsItem>
          <DescriptionsItem label="Rider Fee">
            The fee that we will pay the rider for this order
          </DescriptionsItem>
          <DescriptionsItem label="Method">
            <Paragraph>
              One of DELIVERY, COLLECTION or TABLE_SERVICE. Delivery orders may
              have a Car Icon <CarOutlined /> next to them. This indicates they
              are only completable by one of our EV drivers due to the distance
              of the customer to the restaurant. You can see the distance by
              hovering over the rider fee.
            </Paragraph>
          </DescriptionsItem>
          <DescriptionsItem label="Status">
            Where this order is in the pipeline. Statuses can be:
            {Object.keys(OrderStatus).map((k) => (
              <div key={k}>
                <OrderBadge status={k} />
                {k === "AWAITING_RIDER" && (
                  <div>
                    <OrderBadge status={k} isRiderAtRestaurant />
                  </div>
                )}
              </div>
            ))}
          </DescriptionsItem>
          <DescriptionsItem label="Placed At">
            When this order was placed by the customer
          </DescriptionsItem>
          <DescriptionsItem label="Prepare">
            The initial estimate we gave the restaurant for pickup. Never
            changes.
          </DescriptionsItem>
          <DescriptionsItem label="Pickup" span={24}>
            <Paragraph>
              The current estimate for when a rider will pick up the order. This
              field is the most complicated to understand, as it can change for
              a number of reasons. The system will initially attempt to align
              this number based on a couple of factors:
            </Paragraph>
            <ul>
              <li>How busy the zone is</li>
              <li>How far away the order is</li>
              <li>How busy the restaurant is</li>
            </ul>
            <Paragraph>
              As we get closer to the initial pickup time, this number may
              change due to:
            </Paragraph>
            <ul>
              <li>
                Rider gets assigned the order, system changes pickup time to
                real-world rider ETA to restaurant
              </li>
              <li>
                No rider is assigned at ~2 mins to estimated pickup, system
                delays pickup time by ~5 mins
              </li>
              <li>
                The restuarant, or a support agent, has pressed{" "}
                <Button icon={<ClockCircleFilled />}>Delay Pickup</Button>. This
                will push back the time we attempt to get a rider sent to the
                restaurant. The orders table will show a <ClockCircleFilled />{" "}
                icon next to orders that have been manually delayed.
              </li>
            </ul>
            <Text strong>How do I know if an order is late?</Text>

            <Paragraph>
              A late order will have a Prepare time in the past.
            </Paragraph>
          </DescriptionsItem>
          <DescriptionsItem label="ETA">
            An up-to-date estimate of when the order will arrive at the
            customer. This is the only time shown to the customer. You can use
            this column to note when the rider attached this order will become
            free.
          </DescriptionsItem>
          <DescriptionsItem label="Rider">
            <Paragraph>
              The current rider for this order. A blue dot indicates we are
              offering the order to the rider whilst a green dot indicates the
              rider has accepted the order.
            </Paragraph>

            <Paragraph>
              Riders may be assigned multiple orders at the same time, always
              from the same restaurant. The one exception to this is if a rider
              is about to complete an order (
              <OrderBadge status="AWAITING_CUSTOMER" />
              ), they may then be pre-offered more orders to complete after
              their current one is done.
            </Paragraph>

            <Paragraph>
              Riders that drive an EV will have a <CarOutlined /> next to their
              name. This means they will be prefered for long distance orders.
            </Paragraph>
          </DescriptionsItem>
        </Descriptions>
      </Modal>
    </>
  );
};
