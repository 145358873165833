import {
  Alert,
  Button,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Space,
  Table,
} from "antd";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { Col, ListGroup, ListGroupItem, Row } from "shards-react";
import {
  $,
  BoostInput,
  BoostType,
  MapType,
  Query,
} from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";
import { ZONE } from "../../graphql/queries";
import usePagination from "../../hooks/usePagination";
import usePersistedPaginationConfig from "../../hooks/usePersistedPaginationConfig";
import FutureDateBadge from "../FutureDateBadge";

const ZoneBoosts = ({
  zone,
  refetch,
  visible = true,
  persistContextId: propsPersistContextId,
}: {
  zone: MapType<Query, typeof ZONE>["zone"];
  refetch: Function;
  visible?: boolean;
  // if provided pagination will be persisted in the given context
  persistContextId?: string;
}) => {
  const [showNewBoostModal, setShowNewBoostModal] = useState(false);
  const handleClose = () => setShowNewBoostModal(false);

  const [createBoost, { loading: creating }] = useTypedMutation(
    {
      boostZone: [
        {
          boost: $`boost`,
        },
        {
          id: true,
          type: true,
          start: true,
          end: true,
          multiplier: true,
          fixedIncrease: true,
          notified: true,
          zoneId: true,
        },
      ],
    },
    {
      onCompleted: (b) => {
        message.success("Scheduled a new boost!");
        handleClose();
        refetch();
      },
    }
  );

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment();
  }

  const handleFinish = ({
    multiplier = 1.1,
    times,
  }: {
    times: [typeof moment, typeof moment];
    multiplier: number;
  }) => {
    const input: BoostInput = {
      multiplier,
      fixedIncrease: 0,
      end: times[1],
      start: times[0],
      zoneId: zone.id!,
      type: BoostType.SCHEDULED,
    };
    createBoost({
      variables: {
        boost: input,
      },
    });
  };

  const [endBoost, { loading: endingBoost }] = useTypedMutation(
    {
      endBoost: [
        {
          id: $`id`,
        },
        {
          id: true,
          end: true,
        },
      ],
    },
    {
      onCompleted: (d) => {
        message.success(`Boost ended.`);
        refetch();
      },
    }
  );

  const persistContextId = propsPersistContextId
    ? `zone-boosts-table-${propsPersistContextId}`
    : undefined;

  const {
    pagination,
    args: { take, skip },
    onChange,
  } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "end",
    persistContextId,
  });

  const boostsTableData = useMemo(() => zone?.boosts.slice(skip, skip + take), [
    zone?.boosts,
    skip,
    take,
  ]);

  return (
    <div style={{ display: !visible ? "none" : undefined }}>
      <ListGroup flush>
        <ListGroupItem className="p-0 px-3 pt-3">
          <Row>
            <Col>
              {zone.currentBoost ? (
                <Alert
                  type="info"
                  message={
                    <>
                      Current boost of{" "}
                      <strong>{zone.currentBoost.multiplier}x</strong> started
                      at {new Date(zone.currentBoost.start).toLocaleString()}{" "}
                      and will end at{" "}
                      {new Date(zone.currentBoost.end).toLocaleString()}.
                    </>
                  }
                />
              ) : null}
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col>
              {" "}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  marginBottom: 8,
                }}
              >
                <span
                  style={{
                    flex: 1,
                  }}
                ></span>

                <Space>
                  <Button onClick={() => setShowNewBoostModal(true)}>
                    New Boost
                  </Button>
                </Space>
              </div>
              <Table
                dataSource={boostsTableData}
                rowKey="id"
                pagination={{
                  ...pagination,
                  total: zone?.boosts.length,
                }}
                onChange={onChange}
                columns={[
                  {
                    title: "Type",
                    dataIndex: "type",
                  },
                  {
                    title: "Multiplier",
                    dataIndex: "multiplier",
                  },
                  {
                    title: "Start",
                    dataIndex: "start",
                    render: (r) => new Date(r).toLocaleString(),
                  },
                  {
                    title: "End",
                    dataIndex: "end",
                    render: (r) => new Date(r).toLocaleString(),
                  },
                  {
                    title: "",
                    key: "data",
                    render: (r) => (
                      <FutureDateBadge end={r.end} start={r.start} />
                    ),
                  },
                  {
                    title: "",
                    key: "data",
                    render: (r) => {
                      const now = new Date().getTime();
                      const inFuture = new Date(r.start).getTime() > now;
                      const isNow =
                        new Date(r.start).getTime() < now &&
                        new Date(r.end).getTime() > now;
                      return isNow || inFuture ? (
                        <Popconfirm
                          title="Are you sure you want to end this boost now?"
                          onConfirm={endBoost.bind(null, {
                            variables: {
                              id: r.id,
                            },
                          })}
                        >
                          <Button
                            danger={isNow}
                            color={inFuture ? "yellow" : "default"}
                            type="text"
                            loading={endingBoost}
                          >
                            {inFuture ? "Cancel" : "End Now"}
                          </Button>
                        </Popconfirm>
                      ) : null;
                    },
                  },
                ]}
              ></Table>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
      <Modal
        title="Create a Boost"
        visible={showNewBoostModal && visible}
        onCancel={handleClose}
        footer={null}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Form.Item
            name="times"
            label="Time"
            required
            rules={[{ required: true, type: "array" }]}
          >
            <DatePicker.RangePicker
              showTime
              showSecond={false}
              showNow
              disabledDate={disabledDate}
            />
          </Form.Item>
          <Form.Item name="multiplier" label="Multiplier" required>
            <InputNumber
              type="number"
              min={1.1}
              max={1.6}
              step={0.1}
              defaultValue={1.1}
            />
          </Form.Item>

          <Button htmlType="submit">Create Boost</Button>
        </Form>
      </Modal>
    </div>
  );
};

export default ZoneBoosts;
