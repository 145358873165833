import {
  FileOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Input, Modal, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import { MapType, Query } from "../../graphql/generated";
import { useTypedQuery } from "../../graphql/hooks";
import { FILE_LIST } from "../../graphql/queries";
import usePagination from "../../hooks/usePagination";
import MediaUpload from "./MediaUpload";
function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Bytes";
  var i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
}
type QueryType = MapType<Query, typeof FILE_LIST>["FileList"];

export type ImageAssetType = QueryType["items"][0];

const COLUMNS: ColumnsType<QueryType["items"][0]> = [
  {
    title: "Title",
    dataIndex: "title",
    sorter: true,
  },
  {
    title: "Size",
    dataIndex: "size",
    sorter: true,
    render: bytesToSize,
  },
  {
    title: "Created",
    dataIndex: "createdAt",
    sorter: true,
    render: (c) => new Date(c).toLocaleString(),
  },

  {
    dataIndex: "uploadedBy",
    render: (_, u) => (
      <Button icon={<FileOutlined />} href={u.url}>
        Download
      </Button>
    ),
  },
];

export default function FileList({
  onSelect,
}: {
  onSelect?: (images: QueryType["items"]) => void;
}) {
  const {
    args: { order, page, query, skip, sortBy, take },
    onChange,
    pagination,
    setQuery,
  } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "createdAt",
  });

  const { data, refetch, loading } = useTypedQuery(FILE_LIST, {
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
    },
    fetchPolicy: "network-only",
  });

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Table
        onRow={(r) => ({
          onClick: () => onSelect && onSelect([r]),
        })}
        onChange={onChange}
        title={() => (
          <div style={{ flexDirection: "row", display: "flex" }}>
            <Title style={{ flex: 1 }}>{data?.FileList.total || 0} Files</Title>

            <Space>
              <Input
                suffix={<SearchOutlined />}
                placeholder="Search"
                onChange={(v) => setQuery(v.target.value)}
              />
              <Button
                onClick={() => setShowModal(true)}
                icon={<UploadOutlined />}
                type="primary"
              >
                Upload
              </Button>
            </Space>
          </div>
        )}
        pagination={{
          ...pagination,
          total: data?.FileList.total,
        }}
        dataSource={data?.FileList.items}
        columns={COLUMNS}
      />
      <Modal
        footer={null}
        visible={showModal}
        onCancel={() => setShowModal(false)}
      >
        <MediaUpload
          onSuccess={() => {
            refetch();
            setShowModal(false);
          }}
        />
      </Modal>
    </>
  );
}
