import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  split,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import * as Sentry from "@sentry/react";
import "antd/dist/antd.css";
import { createPersistedQueryLink } from "apollo-link-persisted-queries";
import { createUploadLink as UploadLink } from "apollo-upload-client";
import { extractFiles } from "extract-files";
import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import SnackbarProvider from "react-simple-snackbar";
import { SubscriptionClient } from "subscriptions-transport-ws";
import NetworkChecker from "./components/NetworkChecker";
import { PrivateRoute } from "./components/PrivateRoute";
import CitySwitch from "./components/quickswitch/CitySwitch";
import { UserType } from "./graphql/generated";
import PageView from "./hooks/PageView";
import { APIConfig } from "./hooks/useAPIConfig";
import routes from "./routes";
import { reducer } from "./state/reducer";
import { API_ENDPOINTS, state, WS_ENDPOINTS } from "./state/state";
import { StateProvider, useStateValue } from "./state/StateProvider";
import "./styles/custom.scss";
import ScrollToTop from "./utils/scroll-top";
import Login from "./views/Login";
import withTracker from "./withTracker";

const FallbackComponent = () => (
  <h3>
    Ouch. We've reported this crash to engineering.{" "}
    <a href="#" onClick={() => window.location.reload()}>
      Reload?
    </a>
  </h3>
);

const WS_URL =
  process.env.NODE_ENV === "development"
    ? WS_ENDPOINTS.local
    : window.location.hostname !== "admin.ecoeats.uk"
    ? WS_ENDPOINTS.staging
    : WS_ENDPOINTS.production;
const headers = {
  "x-ecoeats-prefer-user-type": UserType.restaurant,
};
export const wsLink = new WebSocketLink({
  uri: WS_URL,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
      headers,
    }),
  },
});

export const App = () => {
  const [, dispatch] = useStateValue();

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        // eslint-disable-next-line no-console
        try {
          if (JSON.parse(message).statusCode === 401)
            dispatch({ type: "logout" });
        } catch (e) {}
      });
    }

    if (networkError) {
      // eslint-disable-next-line no-console
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const { local, production, staging } = API_ENDPOINTS;

  const API_URL =
    process.env.NODE_ENV === "development"
      ? local
      : window.location.hostname !== "admin.ecoeats.uk"
      ? staging
      : production;

  /**
   * This little interval is to make sure that when we get fresh auth credentials set
   * via the HTTP API (polling on ME query) the websocket client will get refreshed with
   * those too. Since polling is always happening and the server will pre-emptively
   * refresh authtokens, we should catch most situations before we get any unauthorised
   * situations. This also means that fresh roles added to users will be reflected at
   * the usual speed.
   */
  const getSubscriptionClient = () =>
    (wsLink as any).subscriptionClient as SubscriptionClient | undefined;

  const graphQLClient = new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      createPersistedQueryLink() as any,

      split(
        (operation) =>
          extractFiles(operation).files.size > 0 ||
          process.env.NODE_ENV === "development",

        new UploadLink({
          uri: API_URL,
          credentials: "include",
          headers,
        }),

        split(
          (operation) => {
            const isHTTPOnly = [
              "mutation_login",
              "me",
              "login",
              "resetPasswordRequest",
              "logout",
            ].includes(operation.operationName);

            if (
              operation.operationName === "login" ||
              operation.operationName === "logout"
            )
              setTimeout(() => getSubscriptionClient()?.close(), 500);

            return isHTTPOnly;
          },
          new BatchHttpLink({
            uri: API_URL,
            credentials: "include",
            headers,
            batchMax: 3,
          }),
          wsLink
        )
      ),
    ]),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache",
      },
    },
    name: "admin",
    version: process.env.REACT_APP_GIT_SHA || "dev",
  });

  return (
    <SnackbarProvider>
      <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
        <ApolloProvider client={graphQLClient}>
          <>
            <NetworkChecker />
            <Router basename={process.env.REACT_APP_BASENAME || ""}>
              <>
                <APIConfig />
                <ScrollToTop />
                <div>
                  <Route
                    path="/login"
                    component={withTracker((props) => {
                      return <Login {...props} />;
                    })}
                  />
                  {routes.map((route) => {
                    return (
                      <PrivateRoute
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        component={withTracker((props) => {
                          return (
                            <route.layout {...props}>
                              <route.component {...props} />
                            </route.layout>
                          );
                        })}
                      />
                    );
                  })}
                  <PageView />
                </div>
                <CitySwitch />
              </>
            </Router>{" "}
          </>
        </ApolloProvider>
      </Sentry.ErrorBoundary>
    </SnackbarProvider>
  );
};

const checkCache = () => {
  try {
    return JSON.parse(localStorage.getItem("state")!);
  } catch (e) {}

  return {};
};

export default Sentry.withProfiler(() => (
  <StateProvider
    initialState={Object.assign(state, checkCache())}
    reducer={reducer}
  >
    <App />
  </StateProvider>
));
