import { PageHeader, Tabs } from "antd";
import React from "react";
import { Container } from "react-bootstrap";
import MarketingCampaigns from "../components/marketing/Campaigns";
import MarketingPromotions from "../components/marketing/Promotions";
import { RiderDiscounts } from "../components/rider/rider-discounts/RiderDiscounts";
import useTabSync from "../hooks/useTabSync";
import { Discounts } from "./Discounts";

export default function Marketing() {
  const { key, onSelect } = useTabSync("campaigns");

  return (
    <Container
      fluid
      className="main-content-container px-4"
      style={{ backgroundColor: "white" }}
    >
      <PageHeader
        title="Marketing"
        subTitle="Manage campaigns and promotions"
      />
      <Tabs activeKey={key} onChange={onSelect}>
        <Tabs.TabPane key="campaigns" tab="Campaigns">
          <MarketingCampaigns />
        </Tabs.TabPane>
        <Tabs.TabPane key="promotions" tab="Promotions">
          <MarketingPromotions />
        </Tabs.TabPane>
        <Tabs.TabPane key="discounts" tab="Discounts">
          <Discounts />
        </Tabs.TabPane>
        <Tabs.TabPane key="referrals" tab="Referrals">
          <RiderDiscounts />
        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
}
