import { UserOutlined } from "@ant-design/icons";
import { Badge, Card, Input, Table, Tag, Tooltip } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import React, { forwardRef, useCallback, useRef, useState } from "react";
import { useImperativeHandle } from "react";
import { Key } from "react";
import { Map, Marker, Polygon, Popup, TileLayer } from "react-leaflet";
import { Link, useHistory } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { style } from "typestyle";
import {
  MapType,
  Query,
  RiderActivationStatus,
  Zone,
} from "../graphql/generated";
import { useTypedQuery } from "../graphql/hooks";
import { ALL_RIDERS, ZONE } from "../graphql/queries";
import usePagination, { Columns } from "../hooks/usePagination";
import { getImageUrl } from "../utils/image-url";

export const getCentroid = (arr) =>
  arr.reduce(
    function (x, y) {
      return [x[0] + y[0] / arr.length, x[1] + y[1] / arr.length];
    },
    [0, 0]
  );

interface Props {
  zone?: MapType<Query, typeof ZONE>["zone"];
  persistContextId?: string;
}

export type RidersTableRef = {
  refreshMap: () => void;
} | null;

export const RidersTable = forwardRef<RidersTableRef, Props>(
  ({ zone, persistContextId: propsPersistContextId }: Props, ref) => {
    const history = useHistory();

    const persistContextId = propsPersistContextId
      ? `${propsPersistContextId}-riders-table`
      : undefined;

    const statuses = RiderActivationStatus;
    const [status, setStatus] = useState<RiderActivationStatus | undefined>(
      undefined
    );
    const [online, setOnline] = useState(false);

    const {
      pagination,
      args: { order, page, query, sortBy, take },
      onChange,
      setQuery,
      applyDefaultSortOrder,
    } = usePagination({
      defaultOrder: "DESC",
      defaultSortBy: "created",
      persistContextId: persistContextId,
      additionalTableConfigs: [
        {
          propertyName: "status",
          value: status,
          setFunc: setStatus,
        },
        {
          propertyName: "online",
          value: online,
          setFunc: setOnline,
        },
      ],
      additionalOnChangeActions: (p, f, s) => {
        setStatus(
          f.activationStatus
            ? RiderActivationStatus[f.activationStatus.toString()]
            : undefined
        );
        setOnline(!!f["online"]);
      },
    });

    const { data, loading } = useTypedQuery(ALL_RIDERS, {
      variables: {
        skip: (page - 1) * take,
        take,
        sortBy,
        order,
        zoneId: zone?.id,
        online,
        query,
        activationStatus: status,
      },
      fetchPolicy: "network-only",
      pollInterval: 10000,
    });

    const mapRef = useRef<Map | null>(null);
    const polygonRef = useRef<Polygon | null>(null);

    const fitPolygon = useCallback(() => {
      if (polygonRef.current) {
        mapRef.current?.leafletElement.fitBounds(
          polygonRef.current?.leafletElement.getBounds()
        );
      }
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        refreshMap: () => {
          mapRef.current?.leafletElement.invalidateSize();
        },
      }),
      []
    );

    const [searchVal, setSearchVal] = useState(query);

    return (
      <>
        {zone ? (
          <div
            style={{
              height: "300px",
            }}
          >
            {data ? (
              <Map
                ref={mapRef}
                center={getCentroid(zone.polygon!.coordinates[0]) || [0, 0]}
                zoom={14}
                style={{
                  height: "100%",
                }}
              >
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://maps.ecoeats.uk/tile/{z}/{x}/{y}.png"
                />
                {zone.polygon && (
                  <Polygon
                    ref={polygonRef}
                    positions={zone.polygon.coordinates[0]}
                  />
                )}
                {data.RiderList.items.map((r) =>
                  r?.position?.coordinates ? (
                    <Marker position={r?.position.coordinates}>
                      <Popup>
                        <Link to={`/rider/${r.id}`}>
                          <h6>
                            {r.name} <Tag>{r.vehicle}</Tag>
                          </h6>
                        </Link>
                      </Popup>
                    </Marker>
                  ) : null
                )}
              </Map>
            ) : null}
          </div>
        ) : null}
        <Card
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
              className={styles.toolbar}
            >
              <Title level={4} style={{ flex: 1, marginRight: 32 }}>
                {data?.RiderList.total} Riders
              </Title>
              <Input
                value={searchVal}
                onChange={(e) => {
                  const query = e.currentTarget.value;
                  setQuery(query);
                  setSearchVal(query);
                }}
                placeholder="Search..."
              />
            </div>
          }
          style={{
            overflowX: "auto",
          }}
        >
          <Table
            dataSource={data?.RiderList.items}
            loading={loading}
            pagination={{
              ...pagination,
              total: data?.RiderList.total,
            }}
            onChange={onChange}
            columns={applyDefaultSortOrder([
              {
                key: "profileImageKey",
                sorter: true,
                width: 16,
                render: (_, r) => (
                  <Avatar
                    size={24}
                    icon={
                      r?.profileImageKey ? undefined : (
                        <UserOutlined size={18} />
                      )
                    }
                    src={
                      r?.profileImageKey
                        ? getImageUrl(r.profileImageKey, 256, 256)
                        : undefined
                    }
                  />
                ),
              },
              {
                key: "name",
                title: "Name",
                sorter: true,
                render: (r) => <Link to={`/rider/${r.id}`}>{r.name}</Link>,
              },
              {
                key: "email",
                title: "Email",
                sorter: true,
                dataIndex: "email",
              },
              {
                dataIndex: "vehicle",
                key: "vehicle",
                title: "Vehicle",
                sorter: true,
              },
              { dataIndex: "zone", key: "zone", render: (z) => z.name },
              { dataIndex: "note", key: "note", title: "Note", sorter: true },

              {
                key: "activationStatus",
                title: "Status",
                filters: Object.keys(statuses).map((s) => ({
                  text: s,
                  value: s,
                })),
                sorter: true,
                filterMultiple: false,
                filteredValue: status ? [status as Key] : undefined,
                render: (rider) => (
                  <Tooltip
                    title={`Joined ${new Date(rider.created).toLocaleString()}`}
                  >
                    <Badge
                      status={
                        rider.activationStatus === "APPROVED"
                          ? "success"
                          : rider.activationStatus === "REJECTED"
                          ? "error"
                          : rider.activationStatus === "APPLIED"
                          ? rider.application
                            ? "processing"
                            : "warning"
                          : "default"
                      }
                      text={
                        rider.activationStatus === "APPLIED"
                          ? !rider.application
                            ? "Applied"
                            : "RTW Received"
                          : rider.activationStatus.split("_").join(" ")
                      }
                    />
                  </Tooltip>
                ),
              },
              {
                key: "online",
                title: "Online",
                sorter: true,
                filteredValue: online ? [online] : undefined,
                filters: [{ text: "Online Only", value: true }],
                render: (rider) => (
                  <Tooltip
                    title={`Last Online ${moment(rider.lastOnline).fromNow()}`}
                  >
                    <Badge
                      status={rider.online ? "success" : "default"}
                      text={rider.online ? "Online" : "Offline"}
                    />
                  </Tooltip>
                ),
              },
              {
                key: "throttledUntil",
                title: "Throttled",
                sorter: true,
                render: (rider) => {
                  const diff = moment(rider.throttledUntil).diff(
                    moment(),
                    "minutes"
                  );
                  return (
                    <Tooltip
                      title={`Throttled ${moment(
                        rider.throttledUntil
                      ).fromNow()}`}
                    >
                      <Badge
                        status={
                          diff > 0
                            ? "error"
                            : diff >= -3
                            ? "warning"
                            : "success"
                        }
                        text={
                          diff > 0
                            ? "Throttled"
                            : diff >= -3
                            ? "Recently Throttled"
                            : "Not Throttled"
                        }
                      />
                    </Tooltip>
                  );
                },
              },
            ] as Columns<NonNullable<typeof data>["RiderList"]["items"][0]>)}
          />
        </Card>
      </>
    );
  }
);

const styles = {
  toolbar: style({
    $nest: {
      "> *": {
        marginRight: "4px",
      },
    },
  }),
};
