import { QueryResult } from "@apollo/client";
import { Alert, Col, PageHeader, Rate, Skeleton, Tabs } from "antd";
import React, { createContext, useEffect, useState } from "react";
import {
  generatePath,
  Link,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Row } from "shards-react";
import OpeningTimes from "../components/forms/OpeningTimeForm";
import { RatingsModal } from "../components/forms/RatingsModal";
import IncidentTable from "../components/incident/IncidentTable";
import PartnerAnalytics from "../components/PartnerAnalytics";
import { RestaurantBranding } from "../components/RestaurantBranding";
import { RestaurantDelivery } from "../components/RestaurantDelivery";
import { RestaurantHygiene } from "../components/RestaurantHygiene";
import { RestaurantItemSales } from "../components/RestaurantItemSales";
import RestaurantMenu from "../components/RestaurantMenu";
import { RestaurantOverview } from "../components/RestaurantOverview";
import { RestaurantOwner } from "../components/RestaurantOwner";
import { RestaurantStatus } from "../components/RestaurantStatus";
import { RestaurantTablet } from "../components/RestaurantTablet";
import PartnerReusables from "../components/reusables/PartnerReusables";
import { MapType, Query } from "../graphql/generated";
import { useTypedQuery } from "../graphql/hooks";
import { RESTAURANT } from "../graphql/queries";

const Tab = Tabs.TabPane;

export const RestaurantContext = createContext<
  QueryResult<MapType<Query, typeof RESTAURANT>>
>(null as any);

export const Restaurant = () => {
  const { id, tab } = useParams() as any;
  const match = useRouteMatch();
  const history = useHistory();
  const query = useTypedQuery(RESTAURANT, {
    variables: {
      id,
    },
    pollInterval: 10000,
  });
  const restaurant = query.data?.Restaurant;
  const [key, setKey] = useState("");
  const [showRatingModal, setShowRatingModal] = useState(false);

  useEffect(() => {
    setKey(tab);
  }, [tab]);

  const onSelect = (_tab) => {
    const path = generatePath(match.path, { id, tab: _tab });
    history.replace(path);
  };

  return (
    <RestaurantContext.Provider value={query}>
      <PageHeader
        title={
          restaurant ? (
            `${restaurant?.name}`
          ) : (
            <Skeleton paragraph={false} title />
          )
        }
        breadcrumb={{
          itemRender: (route, params, routes) => (
            <Link
              key={`$breadcrumb_${routes.indexOf(route)}_${
                route.breadcrumbName
              }`}
              to={route.path}
            >
              {route.breadcrumbName}
            </Link>
          ),
          routes: [
            {
              breadcrumbName: "Cities",
              path: "/cities",
            },
            {
              breadcrumbName: restaurant?.zone.city.name || "",
              path: "#",
            },
            {
              breadcrumbName: restaurant?.zone.name || "",
              path: `/zone/${restaurant?.zone.id}`,
            },
            {
              breadcrumbName: restaurant?.name || "",
              path: "#",
            },
          ],
        }}
        extra={[
          restaurant && (
            <RestaurantStatus key="restaurant_status" restaurant={restaurant} />
          ),

          <div
            key="rating-div"
            style={{
              cursor: "pointer",
              display: "inline-block",
            }}
            onClick={() => setShowRatingModal(true)}
          >
            <Rate disabled allowHalf value={restaurant?.ratingStars || 0} />
            <span style={{ marginLeft: 8 }}>({restaurant?.ratingCount})</span>
          </div>,

          <a
            key="view-website-link"
            target="_blank"
            rel="noopener noreferrer"
            href={restaurant?.url}
          >
            View on Website
          </a>,
        ]}
      />

      <Row
        style={{
          padding: "0 24px",
        }}
      >
        <Col span={24}>
          {restaurant && (
            <Tabs
              activeKey={key}
              onChange={onSelect}
              style={{ borderBottom: 0 }}
            >
              <Tab key="overview" tab="Overview">
                {key === "overview" || !key ? (
                  <RestaurantOverview restaurant={restaurant} />
                ) : null}
              </Tab>
              <Tab key="menu" tab="Menu">
                {key === "menu" && <RestaurantMenu />}
              </Tab>
              <Tab key="branding" tab="Branding">
                {key === "branding" && (
                  <RestaurantBranding restaurant={restaurant} />
                )}
              </Tab>
              <Tab key="hours" tab="Hours">
                <OpeningTimes />
              </Tab>
              <Tab key="delivery" tab="Delivery">
                <RestaurantDelivery
                  restaurant={restaurant}
                  refetch={() => query.refetch()}
                />
              </Tab>
              <Tab key="tablet" tab="Tablet">
                {key === "tablet" && (
                  <RestaurantTablet restaurant={restaurant} />
                )}
              </Tab>
              <Tab key="owner" tab="Owner">
                <RestaurantOwner restaurant={restaurant as any} />
              </Tab>
              <Tab key="hygiene" tab="Hygiene">
                <RestaurantHygiene restaurant={restaurant as any} />
              </Tab>
              <Tab key="sales" tab="Sales">
                {key === "sales" && (
                  <RestaurantItemSales restaurant={restaurant} />
                )}
              </Tab>
              <Tab key="reusables" tab="Reusables">
                {restaurant.isReusablesEnabled ? (
                  <Alert message="Reusables is enabled." type="info" />
                ) : (
                  <Alert message="Reusables is disabled." type="error" />
                )}
                <PartnerReusables partnerId={id} />
              </Tab>
              <Tab key="analytics" tab="Analytics">
                {key === "analytics" && (
                  <>
                    <PartnerAnalytics />
                  </>
                )}
              </Tab>
              <Tabs.TabPane key="incidents" tab="Incidents">
                <IncidentTable partnerId={id} />
              </Tabs.TabPane>
            </Tabs>
          )}
        </Col>
      </Row>

      {restaurant && (
        <RatingsModal
          restaurant={restaurant}
          show={showRatingModal}
          onHide={() => setShowRatingModal(false)}
        />
      )}
    </RestaurantContext.Provider>
  );
};
