import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Descriptions, message, PageHeader, Switch, Upload } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import ZoneAutoComplete from "../components/ZoneAutocomplete";
import { $, UpdatePromotionInput } from "../graphql/generated";
import { useTypedMutation, useTypedQuery } from "../graphql/hooks";
import { UPDATE_PROMOTION } from "../graphql/mutations";
import { PROMOTION } from "../graphql/queries";
import { getImageUrl } from "../utils/image-url";
import { useUpdateItemKey } from "../utils/update-item-key";

export default function Promotion() {
  const { id } = useParams<{ id: string }>();

  const { data } = useTypedQuery(PROMOTION, {
    variables: {
      id,
    },
  });

  const promotion = data?.Promotion;

  const [updatePromotion, { loading: updating }] = useTypedMutation(
    UPDATE_PROMOTION,
    {
      onCompleted: (d) => {
        message.success("Updated!");
      },
      onError: (e) => {
        message.error(e.message || "Failed to update.");
      },
    }
  );
  const history = useHistory();
  const updatePromotionKey = useUpdateItemKey<UpdatePromotionInput>(
    updatePromotion,
    "promotion",
    id
  );
  const [updatePromotionImage, { loading: uploading }] = useTypedMutation({
    uploadPromotionImage: [
      {
        id: $`id`,
        image: $`image`,
      },
      {
        id: true,
        imageKey: true,
      },
    ],
  });

  const uploadButton = (
    <div>
      {updating ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <PageHeader
        title={
          <Title
            level={4}
            editable={{ onChange: (t) => updatePromotionKey("name", t) }}
          >
            {promotion?.name}
          </Title>
        }
        subTitle="Customer App Promotion"
        onBack={() => history.goBack()}
      >
        <Descriptions>
          <Descriptions.Item label="Title">
            <Text
              editable={{ onChange: (t) => updatePromotionKey("title", t) }}
            >
              {promotion?.title}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item label="Body">
            <Text editable={{ onChange: (t) => updatePromotionKey("body", t) }}>
              {promotion?.body}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item label="Start">
            {promotion?.start}
          </Descriptions.Item>
          <Descriptions.Item label="End">{promotion?.end}</Descriptions.Item>
          <Descriptions.Item label="Enabled?">
            <Switch
              checked={!!promotion?.enabled}
              loading={updating}
              onChange={(v) => updatePromotionKey("enabled", v)}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Zones">
            {promotion && (
              <ZoneAutoComplete
                initialValue={promotion.zones.map((z) => z.id)}
                onChange={(zids) => updatePromotionKey("zoneIds", zids)}
              />
            )}
          </Descriptions.Item>
        </Descriptions>
        <div>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            style={{
              width: 700,
              height: 350,
            }}
            beforeUpload={(file) => {
              updatePromotionImage({
                variables: {
                  id,
                  image: file,
                },
              });
              return false;
            }}
          >
            {promotion?.imageKey ? (
              <img
                src={getImageUrl(promotion.imageKey, 700)}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
      </PageHeader>
    </>
  );
}
