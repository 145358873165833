import { WarningFilled } from "@ant-design/icons";
import { Tag } from "antd";
import React from "react";
import TagWithIcon from "./TagWithIcon";

interface Props {
  children: string;
}

const WarningTag = (props: Props) => {
  const { children } = props;
  return (
    <TagWithIcon color="warning" icon={<WarningFilled />}>
      {children}
    </TagWithIcon>
  );
};

export default WarningTag;
