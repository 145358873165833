import {
  ClockCircleFilled,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Badge, Button, Drawer, Input, Space, Table, Tag } from "antd";
import Title from "antd/lib/typography/Title";
import { isFuture } from "date-fns";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { $ } from "../../graphql/generated";
import { query, useTypedQuery } from "../../graphql/hooks";
import usePagination from "../../hooks/usePagination";
import DynamicForm from "../forms/DynamicForm";

const LEASE_TABLE = query({
  LeaseList: [
    {
      skip: $`skip`,
      take: $`take`,
      sortBy: $`sortBy`,
      order: $`order`,
      riderId: $`riderId`,
      query: $`query`,
      vehicleId: $`vehicleId`,
    },
    {
      total: true,
      items: {
        id: true,
        start: true,
        end: true,
        firstDaysFree: true,
        pricePerDay: true,
        riderId: true,
        depositGraceWeeks: true,
        initialDeposit: true,
        nextDepositDate: true,
        outstandingBalance: true,
        outstandingDeposit: true,
        totalPaid: true,
        numberOfDepositWeeks: true,
        totalDeposit: true,
        vehicle: {
          id: true,
          registration: true,
          dvlaInfo: true,
          note: true,
        },
        rider: {
          id: true,
          name: true,
          email: true,
        },
      },
    },
  ],
});

export default function LeaseTable({
  riderId,
  vehicleId,
}: {
  riderId?: string;
  vehicleId?: string;
}) {
  const {
    pagination,
    args: { order, page, query, sortBy, take },
    onChange,
    setQuery,
  } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "created",
  });

  const { data, refetch } = useTypedQuery(LEASE_TABLE, {
    fetchPolicy: "network-only",
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
      vehicleId,
      riderId,
    },
    pollInterval: 10000,
  });

  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<string>("");

  const onComplete = useCallback(() => {
    refetch();
    setSelected("");
    setShow(false);
  }, []);

  return (
    <>
      <Table
        title={() => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Title level={4} style={{ flex: 1 }}>
              {data?.LeaseList.total} Leases
            </Title>

            <Space>
              <Input
                placeholder="Search..."
                suffix={<SearchOutlined />}
                onChange={(t) => setQuery(t.target.value)}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setShow(true)}
              >
                Create Lease
              </Button>
            </Space>
          </div>
        )}
        onChange={(p, f, s) => {
          onChange(p, f, s);
        }}
        dataSource={data?.LeaseList.items}
        pagination={{
          total: data?.LeaseList.total,
          ...pagination,
        }}
        columns={[
          {
            title: "ID",
            sorter: true,
            render: (_, r) => (
              <Link
                to={`/lease/${r.id}`}
                style={{
                  fontFamily: "monospace",
                }}
              >
                {r.id.slice(0, 8)}
              </Link>
            ),
          },
          {
            title: "Vehicle",
            render: (_, r) => r.vehicle.registration,
          },
          {
            title: "Start",
            dataIndex: "start",
            sorter: true,
            render: (r, l) => (
              <Badge
                status={
                  isFuture(new Date(r))
                    ? "default"
                    : !l.end || isFuture(new Date(l.end))
                    ? "processing"
                    : "success"
                }
                text={new Date(r).toLocaleString()}
              />
            ),
          },
          {
            title: "End",
            dataIndex: "end",
            sorter: true,
            render: (r) =>
              r ? (
                new Date(r).toLocaleString()
              ) : (
                <Tag icon={<ClockCircleFilled />}>Open Ended</Tag>
              ),
          },

          {
            title: "Rider",
            render: (_, r) => (
              <Link to={`/rider/${r.rider.id}`}>{r.rider.name}</Link>
            ),
          },
        ]}
      />

      <Drawer
        width={window.innerWidth <= 768 ? "100%" : "50%"}
        visible={!!(show || selected)}
        onClose={useCallback(() => {
          setShow(false);
          setSelected("");
        }, [])}
      >
        <DynamicForm id="Lease" onComplete={onComplete} noCard />
      </Drawer>
    </>
  );
}
