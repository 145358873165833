import { message } from "antd";
import { MapType, Mutation } from "../../../graphql/generated";
import { useTypedMutation } from "../../../graphql/hooks";
import { SUBMIT_DYNAMIC_FORM } from "./queries";

type ReturnType = MapType<
  Mutation,
  typeof SUBMIT_DYNAMIC_FORM
>["submitDynamicForm"];

export default function useSubmitDynamicForm(
  setDirtyState?: (v: boolean) => void,
  onComplete?: (ret: ReturnType) => any
) {
  return useTypedMutation(SUBMIT_DYNAMIC_FORM, {
    onCompleted: (m) => {
      message.success(m.submitDynamicForm.successMessage || "Success!");
      if (typeof onComplete === "function") onComplete(m.submitDynamicForm);
      if (setDirtyState) setDirtyState(false);
    },
    onError: (e) => message.error(e.message || "Failed"),
  });
}
