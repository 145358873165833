import { SearchOutlined } from "@ant-design/icons";
import { Badge, Input, Space, Table, Tag } from "antd";
import Modal from "antd/lib/modal/Modal";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { $, ReusablesPickupStatus } from "../../graphql/generated";
import { query, useTypedQuery } from "../../graphql/hooks";
import usePagination from "../../hooks/usePagination";
import DynamicForm from "../forms/DynamicForm";

const SITE_TABLE = query({
  ReusablesPickupAggregateList: [
    {
      skip: $`skip`,
      take: $`take`,
      sortBy: $`sortBy`,
      order: $`order`,
      query: $`query`,
    },
    {
      total: true,
      items: {
        id: true,
        createdAt: true,
        washingLocation: {
          id: true,
          name: true,
          status: true,
        },
        pickups: {
          id: true,
          type: true,
          status: true,
          customerCount: true,
          riderCount: true,
          customer: {
            id: true,
            name: true,
            email: true,
          },
          address: {
            id: true,
            address_line_1: true,
            postcode: true,
            coordinates: {
              type: true,
              coordinates: true,
            },
          },
          aggregate: {
            id: true,
            createdAt: true,
          },
        },
      },
    },
  ],
});

export default function ReusableAggregatePickupTable() {
  const {
    pagination,
    args: { order, page, query, sortBy, take },
    onChange,
    setQuery,
  } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "createdAt",
  });

  const { data, refetch } = useTypedQuery(SITE_TABLE, {
    fetchPolicy: "network-only",
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
    },
    pollInterval: 5000,
  });

  const [show, setShow] = useState(false);
  const [showTypes, setShowTypes] = useState(false);

  return (
    <>
      <Table
        title={() => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Title level={4} style={{ flex: 1 }}>
              {data?.ReusablesPickupAggregateList.total} Aggregates
            </Title>

            <Space>
              <Input
                placeholder="Search..."
                suffix={<SearchOutlined />}
                onChange={(t) => setQuery(t.target.value)}
              />
            </Space>
          </div>
        )}
        onChange={(p, f, s) => {
          onChange(p, f, s);
        }}
        dataSource={data?.ReusablesPickupAggregateList.items}
        pagination={{
          total: data?.ReusablesPickupAggregateList.total,
          ...pagination,
        }}
        columns={[
          {
            title: "ID",
            sorter: true,
            dataIndex: "id",
            render: (_, r) => (
              <Link
                to={`/reusables/pickup-aggregate/${r.id}`}
                style={{
                  fontFamily: "monospace",
                }}
              >
                {r.id}
              </Link>
            ),
          },
          {
            title: "Created",
            dataIndex: "createdAt",
            sorter: true,
            render: (r) => new Date(r).toLocaleString(),
          },
          {
            title: "Washing Location",
            render: (_, r) => (
              <Badge
                status={
                  r.washingLocation.status === "READY" ? "success" : "warning"
                }
                text={
                  <Link
                    to={`/reusables/washing-location/${r.washingLocation.id}`}
                  >
                    {r.washingLocation.name}
                  </Link>
                }
              />
            ),
          },
          {
            title: "Pickups",
            key: "pickips",

            render: (_, r) => {
              const out: Record<ReusablesPickupStatus, number> = {
                COMPLETE: 0,
                IN_PROGRESS: 0,
                RIDER_AT_CUSTOMER: 0,
                SCHEDULED: 0,
              };
              for (const pickup of r.pickups) {
                out[pickup.status] = out[pickup.status] + 1;
              }

              return (
                <Space direction="vertical">
                  {Object.entries(out).map(([status, count]) =>
                    count > 0 ? (
                      <Tag
                        color={
                          status === ReusablesPickupStatus.COMPLETE
                            ? "success"
                            : status === ReusablesPickupStatus.IN_PROGRESS
                            ? "processing"
                            : "default"
                        }
                      >
                        {count} {status}
                      </Tag>
                    ) : null
                  )}
                </Space>
              );
            },
          },
          {
            title: "Containers",
            key: "containers",

            render: (_, r) =>
              r.pickups.reduce((a, c) => c.customerCount + a, 0),
          },
        ]}
      />
      <Modal
        footer={null}
        visible={show}
        destroyOnClose
        onCancel={() => setShow(false)}
      >
        <DynamicForm
          onComplete={() => {
            refetch();
            setShow(false);
          }}
          noCard
          id="ReusableContainer"
        />
      </Modal>
      <Modal
        footer={null}
        visible={showTypes}
        destroyOnClose
        onCancel={() => setShowTypes(false)}
      >
        <DynamicForm
          onComplete={() => {
            refetch();
            setShowTypes(false);
          }}
          noCard
          id="ReusableContainerType"
        />
      </Modal>
    </>
  );
}
