import { Form, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useRef, useCallback, useEffect } from "react";
import WeightFormItem from "./WeightFormItem";

interface Props {
  visible?: boolean;
  onCancel?: () => void;
  onUpdateItemWeights: (weight: number) => void;
}

const UpdateItemWeightsModal = (props: Props) => {
  const { visible, onCancel, onUpdateItemWeights } = props;

  const weightFormItemRef = useRef();

  // call set weight on the WeightFormItem
  const resetWeight = useCallback(() => {
    if (weightFormItemRef?.current) {
      weightFormItemRef.current.setWeight(0);
    }
  }, [weightFormItemRef]);

  const [form] = useForm();

  // whenever the form becomes invisible reset the weight.
  useEffect(() => {
    if (!visible) {
      resetWeight();
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      okText="Update All Items"
      onCancel={() => {
        if (onCancel) onCancel();
        resetWeight();
      }}
      onOk={() => {
        onUpdateItemWeights(form.getFieldValue("weight"));
      }}
    >
      <Form form={form}>
        <WeightFormItem ref={weightFormItemRef} form={form} />
      </Form>
    </Modal>
  );
};

export default UpdateItemWeightsModal;
