import { Button, Form, Input, InputNumber, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useContext, useEffect, useState } from "react";
import { $ } from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";
import { OrderContext } from "../../views/Order";
import CurrencyFormInput from "../common/CurrencyFormInput";

const notPositiveCurrencyValidator = (_, value) => {
  if (typeof value === 'number') {
    if (value <= 0) {
      return Promise.resolve();
    } else {
      return Promise.reject('Must not be a positive value.');
    }
  } else {
    return Promise.reject('Please enter a number.');
  }
}

// requires an adjustment if the adjustment value is non-zero
const requireJustificationValidator = (valueField: string) => ({ getFieldValue }) => ({
  validator(_, value) {
    const adjustmentValue = getFieldValue(valueField);
    if (adjustmentValue && adjustmentValue !== 0) {
      if (!value || value === '') {
        return Promise.reject(`Require a justification as ${valueField} is not zero.`);
      }  else {
        return Promise.resolve();
      }
    } else {
      // don't need a justification if there is no adjustment
      return Promise.resolve();
    }
  },
});

const fields = [
  "ourAdjustment",
  "ourAdjustmentJustification",
  "partnerAdjustment",
  "partnerAdjustmentJustification",
  "riderEarningsAdjustment",
  "riderEarningsAdjustmentJustification",
  "riderTipAdjustment",
  "riderTipAdjustmentJustification",
];

export default ({ onCompleted }) => {
  const { data, refetch } = useContext(OrderContext);

  const [adjustOrder] = useTypedMutation(
    {
      adjustOrder: [
        {
          data: $`data`,
          id: $`id`,
        },
        {
          id: true,
        },
      ],
    },
    {
      onCompleted: () => {
        message.success("Adjusted order values!");
        refetch();
        onCompleted();
      },
      onError: () => {
        message.error("Failed to Adjust order");
      }
    }
  );

  const initialValues = data?.order != null ?
    fields.reduce((prev, field) => ({
      ...prev,
      [field]: data?.order ? data?.order[field] : undefined,
    }), {})
    : null;

  // indicates whether the user has changed the values from the initial ones
  const [dirtyState, setDirtyState] = useState(false);

  const [form] = useForm();

  // update initial values
  useEffect(() => {
    if (!dirtyState && initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues]);

  return (
    <Form
      form={form}
      onFinish={(result) => {
        console.log("submitting adjustment", result);
        adjustOrder({
          variables: {
            data: result,
            id: data?.order?.id,
          },
        })
      }}
      onChange={() => setDirtyState(true)}
      layout='vertical'
    >
      <Form.Item
        name="ourAdjustment"
        label="Our Adjustment"
        initialValue={0}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please enter our adjustment, or set it to zero.',
          },
          {
            validator: notPositiveCurrencyValidator,
          },
        ]}
      >
        <CurrencyFormInput currencySymbol='£' allowNegativeValues={true}/>
      </Form.Item>
      <Form.Item
        name="ourAdjustmentJustification"
        label="Our Adjustment Justification"
        hasFeedback
        rules={[
          requireJustificationValidator('ourAdjustment'),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="partnerAdjustment"
        label="Partner Adjustment"
        initialValue={0}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please enter the partner's adjustment, or set it to zero.",
          },
          {
            validator: notPositiveCurrencyValidator,
          },
        ]}
      >
        <CurrencyFormInput currencySymbol='£' allowNegativeValues={true}/>
      </Form.Item>
      <Form.Item
        name="partnerAdjustmentJustification"
        label="Partner Adjustment Justification"
        hasFeedback
        rules={[
          requireJustificationValidator('partnerAdjustment'),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="riderEarningsAdjustment"
        label="Rider Earnings Adjustment"
        initialValue={0}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please enter the rider earning's adjustment, or set it to zero.",
          },
        ]}
      >
        <CurrencyFormInput currencySymbol='£' allowNegativeValues={true}/>
      </Form.Item>
      <Form.Item
        name="riderEarningsAdjustmentJustification"
        label="Rider Earnings Adjustment Justification"
        hasFeedback
        rules={[
          requireJustificationValidator('riderEarningsAdjustment'),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="riderTipAdjustment"
        label="Rider Tip Adjustment"
        initialValue={0}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please enter the rider's tip adjustment, or set it to zero.",
          },
          {
            validator: notPositiveCurrencyValidator,
          },
        ]}
      >
        <CurrencyFormInput currencySymbol='£' allowNegativeValues={true}/>
      </Form.Item>
      <Form.Item
        name="riderTipAdjustmentJustification"
        label="Rider Tip Adjustment Justification"
        hasFeedback
        rules={[
          requireJustificationValidator('riderTipAdjustment'),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit'>
          Adjust Order
        </Button>
      </Form.Item>
    </Form>
  );
};

