import { Descriptions, Divider, Form, message, Select, Slider } from "antd";
import { useForm } from "antd/lib/form/Form";
import Paragraph from "antd/lib/typography/Paragraph";
import Text from "antd/lib/typography/Text";
import { LatLngExpression, LatLngTuple } from "leaflet";
import React, {
  createRef,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RefObject } from "react";
import { MutableRefObject } from "react";
import { useImperativeHandle } from "react";
import { Circle, Map, Polygon, TileLayer } from "react-leaflet";
import {
  AllTypesProps,
  ZoneBusyStatus,
  ZoneOnboardingStatus,
  ZoneUpdateInput,
} from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";
import { UPDATE_ZONE_T } from "../../graphql/mutations";
import { useDebouncedCallback } from "../../hooks/useDebounce";
import { useFormData } from "../../hooks/useFormData";
import { getMapInputFields } from "../../utils/input-control-gql";
import { getCenterPolygon } from "../../utils/sphere";
import { useUpdateItemKey } from "../../utils/update-item-key";
import { ZoneContext } from "../../views/Zone";
import ZoneOpeningTimeForm from "../forms/ZoneOpeningTimeForm";
import ZonePolygonLibrary from "../forms/ZonePolygonLibrary";
import { ZoneBusyStatusBadge, ZoneStateBadge } from "../ZoneBadges";

const MAP_ZOOM = 10;

const { conditions, inputs } = getMapInputFields<
  typeof AllTypesProps.ZoneUpdateInput,
  ZoneUpdateInput
>(AllTypesProps.ZoneUpdateInput, {}, ["polygon"]);

const STATE_OPTIONS = Object.keys(ZoneOnboardingStatus).map((k) => ({
  label: k,
  value: k,
}));

const DEMAND_OPTIONS = Object.keys(ZoneBusyStatus).map((k) => ({
  label: k,
  value: k,
}));

interface Props {
  visible?: boolean;
}

export type ZoneDeliveryParametersRef = {
  refreshMap: () => void;
} | null;

const ZoneDeliveryParameters = ({ visible = true }: Props, ref) => {
  const zone = useContext(ZoneContext)!;

  const [formData, onChange, sfd] = useFormData({
    ...inputs.reduce((a, c) => ({ ...a, [c[0]]: zone?.[c[0]] }), {}),
  });

  const INITIAL_POSITION = useMemo(
    () => getCenterPolygon(zone.polygon.coordinates[0]),
    [zone.polygon]
  );

  const mapRef = useRef<Map | null>(null);
  const polygonRef = useRef<Circle | null>(null);

  const fitPolygon = useCallback(() => {
    if (polygonRef.current) {
      mapRef.current?.leafletElement.fitBounds(
        polygonRef.current?.leafletElement.getBounds()
      );
    }
  }, []);

  useEffect(() => fitPolygon());

  useImperativeHandle(
    ref,
    () => ({
      refreshMap: () => {
        mapRef.current?.leafletElement.invalidateSize();
        mapRef.current?.leafletElement.setZoom(MAP_ZOOM);
        mapRef.current?.leafletElement.panTo(
          INITIAL_POSITION as LatLngExpression
        );
      },
    }),
    []
  );

  const [updateZoneMutation, { loading: updatingZone }] = useTypedMutation(
    UPDATE_ZONE_T,
    {
      variables: {
        id: zone?.id,
        zone: {
          ...formData,
        },
      },
      onCompleted: () => {
        message.success("Updated Zone!");
      },
      onError: () => {
        message.error("Failed to update Zone");
      },
    }
  );

  const updateItemKey = useUpdateItemKey<ZoneUpdateInput>(
    updateZoneMutation,
    "zone",
    zone?.id
  );

  const sliderOnChange = useDebouncedCallback(
    useCallback(
      (v) =>
        updateItemKey("currentDeliveryDistanceMetres", Number.parseInt(v, 10)),
      []
    ),
    1000
  );

  const [form] = useForm();

  useEffect(() => {
    if (typeof zone.currentDeliveryDistanceMetres === "number")
      form.setFieldsValue({
        currentDeliveryDistanceMetres: zone.currentDeliveryDistanceMetres,
      });
  }, [zone.currentDeliveryDistanceMetres]);

  return (
    <div style={{ display: !visible ? "none" : undefined }}>
      <Divider>Zone State</Divider>
      <Descriptions bordered>
        <Descriptions.Item label="Name">
          <Text editable={{ onChange: (v) => updateItemKey("name", v) }}>
            {zone.name}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="State">
          <ZoneStateBadge
            showText={false}
            onboardingStatus={zone.onboardingStatus!}
          />
          <Select
            loading={updatingZone}
            value={zone.onboardingStatus}
            options={STATE_OPTIONS}
            onChange={(v) => updateItemKey("onboardingStatus", v)}
            style={{ width: "200px" }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Demand">
          <ZoneBusyStatusBadge showText={false} busyStatus={zone.busyStatus!} />
          <Select
            loading={updatingZone}
            value={zone.busyStatus}
            options={DEMAND_OPTIONS}
            onChange={(v) => updateItemKey("busyStatus", v)}
            style={{ maxWidth: "250px", minWidth: "80%" }}
          />
        </Descriptions.Item>
      </Descriptions>
      <Divider>Prices</Divider>
      <Descriptions bordered>
        <Descriptions.Item label="Delivery Fee Per KM">
          <Text
            editable={{
              tooltip: "How much to charge customers per kilometre",
              onChange: (v) =>
                updateItemKey("feePerKM", Number.parseInt(v, 10)),
            }}
          >
            {zone.feePerKM}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Base Delivery Fee">
          <Text
            editable={{
              tooltip:
                "Minimum delivery fee charged. Per KM is on top of this.",
              onChange: (v) =>
                updateItemKey("baseDeliveryFee", Number.parseInt(v, 10)),
            }}
          >
            {zone.baseDeliveryFee}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Base Rider Earnings">
          <Text
            editable={{
              tooltip: "Minimum Rider Earnings per Delivery",
              onChange: (v) =>
                updateItemKey("baseRiderEarnings", Number.parseInt(v, 10)),
            }}
          >
            {zone.baseRiderEarnings}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Rider Earnings per KM">
          <Text
            editable={{
              tooltip: "Adds on to base rider earnings per KM of delivery",
              onChange: (v) =>
                updateItemKey("riderEarningsPerKM", Number.parseInt(v, 10)),
            }}
          >
            {zone.riderEarningsPerKM}
          </Text>
        </Descriptions.Item>
      </Descriptions>

      <Divider>Opening Times</Divider>
      <ZoneOpeningTimeForm />

      <Divider>Distances</Divider>
      <Paragraph>
        These distances are used by the AutoScaler to manage the range we
        deliver for at a given moment. There is a sharp dropoff from max to
        standard as we become busier, and a slower curve towards minimum as we
        exceed the busy threshold. Each one is in metres and is relative to the
        restaurant a customer is ordering from, so the below circle drawn will
        probably appear incorrect.
      </Paragraph>

      <Descriptions bordered>
        <Descriptions.Item label="Min">
          <Text
            editable={{
              onChange: (v) =>
                updateItemKey(
                  "minDeliveryDistanceMetres",
                  Number.parseInt(v, 10)
                ),
            }}
          >
            {zone.minDeliveryDistanceMetres}m
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Standard (max Bike Distance)">
          <Text
            editable={{
              onChange: (v) =>
                updateItemKey(
                  "standardDeliveryDistanceMetres",
                  Number.parseInt(v, 10)
                ),
            }}
          >
            {zone.standardDeliveryDistanceMetres}m
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Max">
          <Text
            editable={{
              onChange: (v) =>
                updateItemKey(
                  "hardLimitDeliveryDistanceMetres",
                  Number.parseInt(v, 10)
                ),
            }}
          >
            {zone.hardLimitDeliveryDistanceMetres}m
          </Text>
        </Descriptions.Item>
      </Descriptions>

      <Form
        form={form}
        style={{
          marginTop: 48,
          marginBottom: 16,
        }}
        initialValues={{
          currentDeliveryDistanceMetres: zone.currentDeliveryDistanceMetres,
        }}
        onValuesChange={(changedValues) => {
          if (changedValues.currentDeliveryDistanceMetres) {
            sliderOnChange(changedValues.currentDeliveryDistanceMetres);
          }
        }}
      >
        <Form.Item
          label="Current Distance"
          name="currentDeliveryDistanceMetres"
        >
          <Slider
            id={`zone-delivery-parameters-slider-${zone.id}`}
            tooltipVisible
            marks={{
              [zone.standardDeliveryDistanceMetres]: {
                style: {
                  color: "green",
                },
                label: <strong>Standard</strong>,
              },
            }}
            min={zone.minDeliveryDistanceMetres}
            max={zone.hardLimitDeliveryDistanceMetres}
            tipFormatter={(v) => `${v}m`}
            getTooltipPopupContainer={(node) => {
              return node.parentNode as HTMLElement;
            }}
          />
        </Form.Item>
      </Form>

      <Divider>Zone Boundaries</Divider>

      <Map
        ref={mapRef}
        center={INITIAL_POSITION as LatLngTuple}
        zoom={MAP_ZOOM}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://maps.ecoeats.uk/tile/{z}/{x}/{y}.png"
        />
        <Polygon positions={zone.polygon?.coordinates?.[0]} color="green" />
        {zone.polygon ? (
          <Circle
            ref={polygonRef}
            center={
              getCenterPolygon(zone.polygon.coordinates[0]) as [number, number]
            }
            radius={zone.currentDeliveryDistanceMetres}
          />
        ) : null}
      </Map>

      <Divider>Polygon Library</Divider>

      <ZonePolygonLibrary
        activePolygonId={zone.activePolygonId!}
        zoneId={zone.id}
      />
    </div>
  );
};

export default forwardRef<ZoneDeliveryParametersRef, Props>(
  ZoneDeliveryParameters
);
