import { useMutation } from "@apollo/client";
import { Button, Form, Input } from "antd";
import { getOperationName } from "apollo-link";
import { LatLngTuple } from "leaflet";
import React, { useState } from "react";
import { Map, Polygon, TileLayer } from "react-leaflet";
import { CREATE_CITY } from "../../graphql/mutations";
import { ALL_CITIES } from "../../graphql/queries";

export const CreateCityForm = () => {
  const INITIAL_POSITION = [56.333874, -2.8059686];
  const [positions, setPositions] = useState<any[]>([]);
  const [formData, setFormData] = useState({
    name: "",
  });

  const [createCityMutation] = useMutation(CREATE_CITY, {
    refetchQueries: () => [getOperationName(ALL_CITIES)!],
  });

  const addPosition = (e) => {
    const newPos = [e.latlng.lat, e.latlng.lng];
    setPositions(positions.concat([newPos]));
  };

  const submitForm = (e) => {
    createCityMutation({
      variables: {
        city: {
          ...formData,
          zones: [
            {
              name: formData.name,
              polygon: {
                type: "Polygon",
                coordinates: [positions],
              },
            },
          ],
        },
      },
    });
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <Form onFinish={submitForm}>
      <Form.Item label="Name">
        <Input name="name" placeholder="ecoeats Kebabs" onChange={onChange} />
      </Form.Item>

      <Button danger onClick={() => setPositions([])}>
        Reset Polygon
      </Button>
      <Map
        center={INITIAL_POSITION as LatLngTuple}
        zoom={10}
        onClick={(e) => addPosition(e)}
        draw
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://maps.ecoeats.uk/tile/{z}/{x}/{y}.png"
        />
        <Polygon positions={positions} color="blue" />
      </Map>

      <Button htmlType="submit">Create New City</Button>
    </Form>
  );
};
