import {
  CarFilled,
  LockFilled,
  RobotFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { Badge, Button, DatePicker, Space, Table, Tag } from "antd";
import Text from "antd/lib/typography/Text";
import { subDays } from "date-fns/esm";
import moment from "moment";
import React, { Key, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody } from "shards-react";
import { $, BookingSlotType } from "../graphql/generated";
import { query, useTypedQuery } from "../graphql/hooks";
import usePagination from "../hooks/usePagination";
import { price } from "../utils/price";
import FutureDateBadge from "./FutureDateBadge";

const BOOKING_SLOT_LIST = query({
  BookingSlotList: [
    {
      skip: $`skip`,
      take: $`take`,
      sortBy: $`sortBy`,
      order: $`order`,
      riderId: $`riderId`,
      zoneId: $`zoneId`,
      start: $`start`,
    },
    {
      total: true,
      items: {
        id: true,
        zoneId: true,
        bookings: [
          { includeCancelled: false },
          {
            id: true,
            createdAt: true,
            lateCancellation: true,
            riderId: true,
            status: true,
          },
        ],
        endTime: true,
        startTime: true,
        autoIncreaseRiderPercentage: true,
        guaranteedHourlyPay: true,
        percentage: true,
        maxRiders: true,
        slotType: true,
        carOnly: true,
      },
    },
  ],
});

export default function BookingSlotTable({
  zoneId,
  riderId,
  // ! Important: ensure that a different contextId is used on each different use of BookingSlotTable to prevent issues with persistence of filters
  persistContextId: propsContextId,
}: {
  zoneId?: string;
  riderId?: string;
  persistContextId?: string;
}) {
  const contextId = propsContextId
    ? `booking-slot-table-${propsContextId}`
    : undefined;

  const history = useHistory();

  const [start, setStart] = useState<string>(
    subDays(new Date(), 1).toISOString()
  );

  const {
    pagination,
    args: { order, page, sortBy, take },
    onChange,
  } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "startTime",
    persistContextId: `booking-slot-table-${contextId}`,
    additionalTableConfigs: [
      {
        propertyName: "start",
        value: start,
        setFunc: setStart,
      },
    ],
    additionalOnChangeActions: (p, f, s) => {
      if (f["startTime"] && f["startTime"][0]) {
        setStart(new Date(f["startTime"][0] as Key).toISOString());
        console.log(
          "updated startTime filter",
          f["startTime"] && f["startTime"][0]
        );
        // persistStart(new Date(f["startTime"][0] as Key).toISOString());
      } else {
        setStart(subDays(new Date(), 1).toISOString());
        // persistStart(undefined);
      }
    },
  });

  const { data, loading } = useTypedQuery(BOOKING_SLOT_LIST, {
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      zoneId,
      start: new Date(start),
      riderId,
    },
    pollInterval: 10000,
  });

  console.log("filtered diff days", moment(start).diff(new Date(), "days"));

  return (
    <>
      <Card small className="mb-4">
        <CardBody className="p-0 pb-3">
          <Table
            dataSource={data?.BookingSlotList.items}
            loading={loading}
            style={{
              cursor: "pointer",
            }}
            pagination={{
              total: data?.BookingSlotList.total,
              ...pagination,
            }}
            onChange={onChange}
            columns={[
              {
                title: "Start",
                dataIndex: "startTime",
                render: (d, row) => (
                  <Link to={`/booking-slot/${row.id}`}>
                    {new Date(d).toLocaleString()}
                  </Link>
                ),
                filteredValue: [start],
                filterDropdown: ({
                  // first index contains start
                  selectedKeys: selectedStarts,
                  // indirectly sets start, if when setting a filter include it as the first
                  // index in the array, otherwise if clearing the filter set to a zero-length array
                  setSelectedKeys: setSelectedStarts,
                  confirm,
                  clearFilters: resetSelectedStarts,
                }) => (
                  <div style={{ padding: 8 }}>
                    <DatePicker
                      value={moment(selectedStarts[0])}
                      onChange={(e) => {
                        const selectedKeys = e
                          ? [e.startOf("date").toISOString()]
                          : [];
                        console.log("updated selected keys", selectedKeys);
                        setSelectedStarts(selectedKeys);
                      }}
                      style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Space>
                      <Button
                        type="primary"
                        onClick={() => {
                          confirm();
                        }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                      >
                        Search
                      </Button>
                      <Button onClick={resetSelectedStarts}>Reset</Button>
                    </Space>
                  </div>
                ),
              },
              {
                title: "End",
                dataIndex: "endTime",
                render: (d) => new Date(d).toLocaleString(),
              },
              {
                title: "Min. Hourly",
                dataIndex: "guaranteedHourlyPay",
                render: (d, r) =>
                  r.slotType === BookingSlotType.GOLDEN ? (
                    price(d)
                  ) : (
                    <Text type="secondary">N/A</Text>
                  ),
              },
              {
                title: "Percentage",
                render: (b) => (
                  <Text>
                    {b.percentage}%{" "}
                    {b.autoIncreaseRiderPercentage ? (
                      <Tag icon={<RobotFilled />}>Auto</Tag>
                    ) : (
                      <Tag icon={<LockFilled />}>Locked</Tag>
                    )}
                  </Text>
                ),
              },
              {
                title: "Restrictions",
                render: (c) => (c.carOnly ? <CarFilled /> : null),
              },
              {
                title: "Riders",
                render: (r) => (
                  <Badge
                    text={`${r.bookings.length}/${r.maxRiders}`}
                    status={
                      r.bookings.length === r.maxRiders ? "success" : "warning"
                    }
                  />
                ),
              },
              {
                render: (r) => (
                  <FutureDateBadge
                    start={r.startTime}
                    end={r.endTime}
                    verbose
                  />
                ),
              },
            ]}
          />
        </CardBody>
      </Card>
    </>
  );
}
