import { notification } from "antd";
import React, { useEffect, useRef } from "react";
import { useNetworkState } from "react-use";

export default function NetworkChecker() {
  const online = useNetworkState();
  const lastStatus = useRef(true);
  useEffect(() => {
    if (!online.online)
      notification.error({
        message: "You are offline",
      });
    else if (!lastStatus.current)
      notification.success({
        message: "Connection Restored",
      });
    lastStatus.current = !!online.online;
  }, [online.online]);
  return <></>;
}
