import React from "react";
import { Col, Row } from "shards-react";
import { Financials } from "./financials/Financials";
import RestaurantInvoiceTable from "./financials/RestaurantInvoiceTable";

export const RestaurantItemSales = ({ restaurant }) => {
  return (
    <>
      <div>
        <Row
          style={{
            marginBottom: 8,
          }}
        >
          <Col>
            <Financials restaurantId={restaurant.id} />
          </Col>
        </Row>

        <Row
          style={{
            marginBottom: 8,
          }}
        >
          <Col>
            <RestaurantInvoiceTable restaurantId={restaurant?.id} />
          </Col>
        </Row>
      </div>
    </>
  );
};
