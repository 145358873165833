import { AlertOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Badge, Button, notification, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { $, MapType, OrderAlertType, Query } from "../../graphql/generated";
import {
  query,
  subscription,
  useTypedMutation,
  useTypedQuery,
  useTypedSubscription,
} from "../../graphql/hooks";
import usePagination from "../../hooks/usePagination";
const ORDER_ALERTS = query({
  OrderAlertList: [
    {
      order: $`order`,
      sortBy: $`sortBy`,
      skip: $`skip`,
      take: $`take`,
      includeResolved: $`includeResolved`,
      zoneIds: $`zoneIds`,
      orderId: $`orderId`,
      riderId: $`riderId`,
    },
    {
      hasMore: true,
      total: true,
      items: {
        id: true,
        created: true,
        order: {
          id: true,
          number: true,
          status: true,
          isRiderAtRestaurant: true,
          restaurant: {
            id: true,
            name: true,
          },
        },
        resolved: true,
        resolvedBy: {
          id: true,
          name: true,
        },
        rider: {
          id: true,
          name: true,
        },
        type: true,
        zoneId: true,
      },
    },
  ],
});

const ALERT_SUBSCRIPTION = subscription({
  OrderAlerts: [
    {
      ...ORDER_ALERTS.OrderAlertList[0],
    },
    {
      ...ORDER_ALERTS.OrderAlertList[1].items,
    },
  ],
});

type OrderAlertQueryItem = MapType<
  Query,
  typeof ORDER_ALERTS
>["OrderAlertList"]["items"][0];

const getPlaintextLocale = (alert: OrderAlertQueryItem) =>
  alert.type
    .split("_")
    .map((txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    .join(" ");

const getLocale = (alert: OrderAlertQueryItem): string | React.ReactNode => {
  if (alert.type === OrderAlertType.PARTNER_NOT_RESPONDING) {
    return (
      <span>
        <Link to={`/restaurant/${alert.order.restaurant.id}/tablet`}>
          {alert.order.restaurant.name}
        </Link>{" "}
        is not responding
      </span>
    );
  }

  if (alert.type === OrderAlertType.RIDER_LATE_TO_RESTAURANT && alert.rider) {
    return (
      <span>
        <Link to={`/rider/${alert.rider.id}`}>{alert.rider.name}</Link>{" "}
        {alert.order.isRiderAtRestaurant ||
        (alert.order.status !== "AWAITING_RIDER" &&
          alert.order.status !== "PREP")
          ? "was late to"
          : "is late to"}{" "}
        {alert.order.restaurant.name}
      </span>
    );
  }

  if (alert.type === OrderAlertType.RIDER_LATE_TO_CUSTOMER && alert.rider) {
    return (
      <span>
        <Link to={`/rider/${alert.rider.id}`}>{alert.rider.name}</Link> late to
        customer
      </span>
    );
  }

  if (alert.type === OrderAlertType.RIDER_COMPLETED_TOO_FAST && alert.rider) {
    return (
      <span>
        <Link to={`/rider/${alert.rider.id}`}>{alert.rider.name}</Link>{" "}
        completed order too fast
      </span>
    );
  }

  return getPlaintextLocale(alert);
};

const isResolvable = (alert: OrderAlertQueryItem): boolean => !alert.resolved;

const badgeColor = (a: OrderAlertQueryItem) =>
  a.type === OrderAlertType.PARTNER_NOT_RESPONDING ||
  a.type === OrderAlertType.RIDER_COMPLETED_TOO_FAST
    ? "error"
    : a.type === OrderAlertType.RIDER_LATE_TO_RESTAURANT ||
      a.type === OrderAlertType.RIDER_LATE_TO_CUSTOMER
    ? "warning"
    : "default";

type SortableZoneAlertsTableCol = "created";
type Ordering = "ASC" | "DESC";

const DEFAULT_SORT_BY: SortableZoneAlertsTableCol = "created";
const DEFAULT_ORDERING: Ordering = "DESC";
const DEFAULT_INCLUDE_RESOLVED = false;

export default function ZoneAlerts({
  setAlerts,
  zoneIds,
  orderId,
  riderId,
  visible = true,
  persistContextId: propsPersistContextId,
}: {
  setAlerts: (n: number) => void;
  zoneIds?: string[];
  orderId?: string;
  riderId?: string;
  visible?: boolean;
  // if provided then table config will be persisted
  persistContextId?: string;
}) {
  const persistContextId = propsPersistContextId
    ? `zone-alerts-table-${propsPersistContextId}`
    : undefined;

  const [includeResolved, setIncludeResolved] = useState(
    DEFAULT_INCLUDE_RESOLVED
  );

  const {
    pagination,
    args: { order, page, sortBy, take },
    onChange,
  } = usePagination({
    defaultOrder: DEFAULT_ORDERING,
    defaultSortBy: DEFAULT_SORT_BY,
    additionalTableConfigs: [
      {
        propertyName: "includeResolved",
        value: includeResolved,
        setFunc: setIncludeResolved,
      },
    ],
    additionalOnChangeActions: (p, f, s) => {
      const includeResolved = !!f["buttons"];
      setIncludeResolved(includeResolved);
    },
    persistContextId,
  });

  const { data, updateQuery, loading: loadingOrderAlerts } = useTypedQuery(
    ORDER_ALERTS,
    {
      variables: {
        order,
        sortBy,
        skip: (page - 1) * take,
        take,
        includeResolved,
        zoneIds,
        orderId,
        riderId,
      },
      fetchPolicy: "network-only",
      pollInterval: 30000,
    }
  );

  const [resolve, { loading }] = useTypedMutation({
    resolveOrderAlert: [
      {
        id: $`id`,
      },
      {
        ...ORDER_ALERTS.OrderAlertList[1].items,
      },
    ],
  });

  useTypedSubscription(ALERT_SUBSCRIPTION, {
    variables: {
      order,
      sortBy,
      skip: (page - 1) * take,
      take,
      includeResolved,
      zoneIds,
      orderId,
      riderId,
    },
    onSubscriptionData: (d) => {
      const newAlert = d.subscriptionData.data?.OrderAlerts;
      if (newAlert && !newAlert.resolved) {
        updateQuery((p) => ({
          OrderAlertList: {
            ...p.OrderAlertList,
            items: [newAlert, ...p.OrderAlertList.items],
          },
        }));
        notification.warning({
          message: (
            <div>
              <h3>New Alert</h3>
              <Badge
                status={badgeColor(newAlert)}
                text={getPlaintextLocale(newAlert)}
              />
            </div>
          ),
          icon: <AlertOutlined />,
        });
        if (audioRef.current) {
          audioRef.current.volume = 0.4;
          try {
            audioRef.current.play();
          } catch {}
        }
      }
    },
  });

  const audioRef = useRef<HTMLAudioElement | null>(null);

  /**
   * Check for new alerts
   */
  useEffect(() => {
    if (data) setAlerts(data.OrderAlertList.total);
  }, [data?.OrderAlertList.total]);

  const COLUMNS = useMemo<ColumnsType<any>>(
    () => [
      {
        title: "Order",
        dataIndex: "order",
        render: (o) => <Link to={`/order/${o.id}`}>{o.number}</Link>,
      },
      {
        title: "Type",
        render: (a) => <Badge status={badgeColor(a)} text={getLocale(a)} />,
      },
      {
        title: "Created",
        dataIndex: "created",
        render: (a) => new Date(a).toLocaleString(),
      },
      {
        key: "buttons",
        filters: [{ text: "Include Resolved", value: true }],
        width: 350,
        filteredValue: [includeResolved],
        render: (a: OrderAlertQueryItem) => (
          <Space style={{ float: "right" }}>
            <Button
              disabled={!isResolvable(a)}
              onClick={() => resolve({ variables: { id: a.id } })}
              type={a.resolved ? "text" : "primary"}
              icon={<CheckCircleOutlined />}
            >
              {a.resolved
                ? `${a.resolvedBy?.name || "System"} resolved ${moment(
                    a.resolved
                  ).fromNow()}`
                : "Resolve"}
            </Button>
          </Space>
        ),
      },
    ],
    [includeResolved, resolve]
  );

  return (
    <>
      <audio
        ref={(ref) => (audioRef.current = ref)}
        src="/pixie_dust.mp3"
        style={{
          display: "none",
        }}
      />
      <Table
        style={{ display: !visible ? "none" : undefined }}
        scroll={{ x: 400 }}
        dataSource={data?.OrderAlertList.items}
        columns={COLUMNS}
        rowKey={(r) => r.id}
        onChange={onChange}
        pagination={{
          ...pagination,
          total: data?.OrderAlertList.total,
        }}
        loading={loadingOrderAlerts}
      />
    </>
  );
}
