import { QueryResult } from "@apollo/client";
import * as Sentry from "@sentry/react";
import {
  Affix,
  Badge,
  message,
  PageHeader,
  Skeleton,
  Space,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React, { createContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Col, Row } from "shards-react";
import PlatformAssignment from "../components/platform/Assignment";
import Search from "../components/quickswitch/search/Search";
import ZoneReusables from "../components/reusables/ZoneReusables";
import ZoneAlerts from "../components/zone/ZoneAlerts";
import ZoneAutoScaler from "../components/zone/ZoneAutoScaler";
import ZoneBookings from "../components/zone/ZoneBookings";
import ZoneBoosts from "../components/zone/ZoneBoosts";
import ZoneDeliveryParameters from "../components/zone/ZoneDeliveryParameters";
import ZoneEvents from "../components/zone/ZoneEvents";
import ZoneOffers from "../components/zone/ZoneOffers";
import ZoneOverview from "../components/zone/ZoneOverview";
import ZonePartners from "../components/zone/ZonePartners";
import ZoneRiders from "../components/zone/ZoneRiders";
import ZoneSimulator from "../components/zone/ZoneSimulator";
import ZoneStatus from "../components/zone/ZoneStatus";
import {
  MapType,
  Query,
  ZoneBusyStatus,
  ZoneOnboardingStatus,
} from "../graphql/generated/graphql-zeus";
import { useTypedQuery } from "../graphql/hooks";
import { ZONE } from "../graphql/queries";

const { TabPane: Tab } = Tabs;

export const ZoneContext = createContext<
  MapType<Query, typeof ZONE>["zone"] | undefined
>({} as any);

export const ZoneView = () => {
  const { id, tab } = useParams() as any;
  const history = useHistory();
  const match = useRouteMatch();
  const [key, setKey] = useState("overview");

  const onSelect = (_tab) => {
    const path = generatePath(match.path, { id, tab: _tab });
    history.replace(path);
  };

  useEffect(() => {
    setKey(tab);
  }, [tab]);

  const zoneQuery = useTypedQuery(ZONE, {
    skip: !id,
    variables: {
      id,
    },
    onError: (e) => {
      message.error("Failed to load this zone");
      Sentry.showReportDialog({
        errorGeneric: e.message,
      });
    },
    pollInterval: 10000,
  });

  const { data: zData, refetch } = zoneQuery;

  const zone = zData?.zone;

  const [nAlerts, setNAlerts] = useState(0);

  return (
    <ZoneContext.Provider value={zoneQuery?.data?.zone}>
      <Container
        fluid
        className="main-content-container px-4"
        style={{ backgroundColor: "white" }}
      >
        <Affix offsetTop={0}>
          <PageHeader
            style={{
              backgroundColor: "white",
            }}
            title={
              <Skeleton
                key="zone-name"
                title={false}
                paragraph={{ rows: 1, width: "100px" }}
                loading={!zone}
              >
                {zone ? (
                  <Title
                    style={{
                      marginBottom: 0,
                    }}
                    level={4}
                  >
                    {zone.name}
                  </Title>
                ) : null}
              </Skeleton>
            }
            subTitle={
              <Skeleton
                key="zone-city-name"
                paragraph={{ rows: 1, width: "80px" }}
                title={false}
                loading={!zone}
              >
                {zone ? <Text>{zone.city.name}</Text> : null}
              </Skeleton>
            }
            onBack={() => history.goBack()}
            extra={[
              <Skeleton
                key="zone-extra"
                loading={!zone}
                paragraph={{ rows: 1, width: "200px" }}
                title={false}
              >
                {zone ? <ZoneStatus zone={zone} /> : null}
              </Skeleton>,
            ]}
          />
        </Affix>

        <Row>
          <Col>
            {zone ? (
              <Tabs
                activeKey={key}
                onChange={onSelect}
                id="zone-nav"
                style={{ borderBottom: 0 }}
              >
                <Tab key="overview" tab="Overview">
                  <ZoneOverview zone={zone} />
                </Tab>
                <Tab
                  key="alerts"
                  tab={
                    <Badge count={nAlerts} offset={[4, -5]} size="small">
                      Alerts
                    </Badge>
                  }
                  forceRender
                >
                  <ZoneAlerts setAlerts={setNAlerts} zoneIds={[id]} />
                </Tab>
                <Tab key="delivery" tab="Delivery">
                  {key === "delivery" && <ZoneDeliveryParameters />}
                </Tab>
                <Tab key="boosts" tab="Boosts">
                  {key === "boosts" && (
                    <ZoneBoosts zone={zone} refetch={refetch} />
                  )}
                </Tab>
                <Tab key="autoScaler" tab="AutoScaler">
                  {key === "autoScaler" && zone ? (
                    <ZoneAutoScaler zone={zone} refetch={refetch} />
                  ) : null}
                </Tab>
                <Tab key="partners" tab="Partners">
                  {key === "partners" && <ZonePartners zone={zone} />}
                </Tab>
                <Tab key="bookings" tab="Bookings">
                  {key === "bookings" && (
                    <ZoneBookings persistContextId="zone" />
                  )}
                </Tab>
                <Tab key="riders" tab="Riders">
                  {key === "riders" && <ZoneRiders zone={zone as any} />}
                </Tab>
                <Tab key="offers" tab="Offers">
                  {key === "offers" && <ZoneOffers zoneId={zone.id} />}
                </Tab>
                <Tab key="events" tab="Events">
                  {key === "events" && <ZoneEvents />}
                </Tab>
                <Tab key="assignment" tab="Assignment">
                  {key === "assignment" && <PlatformAssignment zoneId={id} />}
                </Tab>
                <Tab key="reusables" tab="🌳 Reusables">
                  {key === "reusables" && <ZoneReusables zoneId={id} />}
                </Tab>
                {window.location.hostname !== "admin.ecoeats.uk" ? (
                  <Tab key="simulator" tab="Simulator">
                    {key === "simulator" && <ZoneSimulator />}
                  </Tab>
                ) : null}
              </Tabs>
            ) : null}
          </Col>
        </Row>
      </Container>

      <Search zoneId={id} />
    </ZoneContext.Provider>
  );
};
