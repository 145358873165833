import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";
import React from "react";
const { Text } = Typography;

interface Props {
  visible: boolean;
  onCancel: () => void;
  nSelectedCategories: number;
  nSelectedItems: number;
  addModifierGroupsToSelectedItems: () => void;
  selectAllItemsInCategories: () => void;
  deselectAllItemsInCategories: () => void;
  approveSelectedItems: () => void;
  updateWeightOfSelectedItems: () => void;
}

const height = 65;

const actionButtonStyle: React.CSSProperties = { float: "right" };

function MenuEditHeader(props: Props) {
  const {
    nSelectedCategories,
    nSelectedItems,
    visible,
    onCancel,
    addModifierGroupsToSelectedItems,
    selectAllItemsInCategories,
    deselectAllItemsInCategories,
    approveSelectedItems,
    updateWeightOfSelectedItems,
  } = props;
  return (
    <div
      style={{
        // nice animated intro and exit
        position: "fixed",
        top: visible ? 0 : -height,
        right: 0,
        left: "auto",
        width: "100%",
        height: height,
        // in order to get infront of left menu bar with zIndex = 1070
        zIndex: 1080,
        background: "linear-gradient(#FFFFFF, #F4F4F4)",
        transition: "top .2s linear 0s",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <span
          style={{
            width: "30%",
          }}
        >
          {/* number of items selected */}
          <Text style={{ marginLeft: 30 }}>
            {`
                ${
                  nSelectedCategories > 0
                    ? `${nSelectedCategories} Categor${
                        nSelectedCategories > 1 ? "ies" : "y"
                      }
                  ${nSelectedItems > 0 ? ", " : ""}
                  `
                    : ""
                }
                ${
                  nSelectedItems > 0
                    ? `${nSelectedItems} Item${nSelectedItems > 1 ? "s" : ""}`
                    : ""
                } Selected`}
          </Text>
        </span>
        <span
          style={{
            flexGrow: 2,
            paddingRight: 30,
          }}
        >
          <Space style={{ float: "right" }} align="end">
            <Button
              style={actionButtonStyle}
              disabled={nSelectedCategories === 0}
              onClick={() => selectAllItemsInCategories()}
            >
              Select All in Categories
            </Button>
            <Button
              style={actionButtonStyle}
              disabled={nSelectedCategories === 0}
              onClick={() => deselectAllItemsInCategories()}
            >
              Deselect All in Categories
            </Button>
            <Button
              style={actionButtonStyle}
              disabled={nSelectedItems === 0}
              onClick={() => addModifierGroupsToSelectedItems()}
            >
              Add Modifier Groups
            </Button>
            <Button
              style={actionButtonStyle}
              disabled={nSelectedItems === 0}
              onClick={() => updateWeightOfSelectedItems()}
            >
              Update Weights
            </Button>
            <Button
              style={actionButtonStyle}
              disabled={nSelectedItems === 0}
              onClick={() => approveSelectedItems()}
            >
              Approve
            </Button>
          </Space>
        </span>
      </div>

      {/* Cancel Button */}
      <Button
        danger
        icon={<CloseCircleOutlined />}
        style={{
          // position below, but slightly overlapping with menu bar
          position: "absolute",
          padding: 0,
          top: "100%",
          left: "50%",
          height: 25,
          marginTop: -10,
          // styling
          borderRadius: 10,
          boxShadow: "0 0 10px 0 rgb(0 0 0 / 10%)",
          // center icon and text
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // managing size of button
          paddingLeft: 5,
          paddingRight: 7,
          // prevents it poking out
          visibility: visible ? "visible" : "hidden",
        }}
        onClick={() => onCancel()}
      >
        Cancel
      </Button>
    </div>
  );
}

export default MenuEditHeader;
