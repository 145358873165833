import React from "react";
import { OrdersTable } from "../OrdersTable";
import UpcomingBaskets from "../UpcomingBaskets";

const ZoneOverView = ({
  zone,
  persistContextId: propsPersistContextId,
  visible = true,
}: {
  zone: { id: string };
  visible?: boolean;
  persistContextId?: string;
}) => {
  const persistContextId = propsPersistContextId
    ? `${propsPersistContextId}-zone-overview`
    : undefined;

  return (
    <div
      style={{
        width: "100%",
        overflow: "auto",
        display: !visible ? "none" : undefined,
      }}
    >
      <UpcomingBaskets zoneIds={[zone.id!]} />
      <OrdersTable zoneId={zone.id} persistContextId={persistContextId} />
    </div>
  );
};

export default ZoneOverView;
