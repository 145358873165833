import {
  Button,
  Col,
  Form,
  message,
  PageHeader,
  Row,
  Select,
  Statistic,
  Table,
} from "antd";
import Title from "antd/lib/typography/Title";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { $, Permissions } from "../graphql/generated";
import { useTypedMutation, useTypedQuery } from "../graphql/hooks";
import { ADMIN } from "../graphql/queries";

const Admin = () => {
  const { id } = useParams();

  const history = useHistory();
  const [roles, setRoles] = useState<any[] | null>(null);
  const { data, refetch } = useTypedQuery(ADMIN, {
    variables: {
      id,
    },
    onCompleted: (d) => setRoles(d.RestaurantAdmin.roles),
  });

  const [updateAdmin] = useTypedMutation(
    {
      updateRestaurantAdmin: [
        {
          admin: $`admin`,
          id: $`id`,
        },
        {
          id: true,
          name: true,
        },
      ],
    },
    {
      onCompleted: useCallback(
        (d) => {
          refetch();
          message.success("Updated " + d.updateRestaurantAdmin.name);
        },
        [refetch]
      ),
      variables: {
        id,
        admin: {
          roles,
        },
      },
    }
  );

  useEffect(() => {
    if (
      data &&
      roles &&
      roles.slice().sort().join() !==
        data?.RestaurantAdmin.roles.slice().sort().join()
    ) {
      updateAdmin();
    }
  }, [roles, updateAdmin, data]);

  return (
    <>
      <PageHeader
        title={data?.RestaurantAdmin.name}
        subTitle="Admin"
        onBack={() => history.push("/admins")}
        extra={[
          data?.RestaurantAdmin.administeredRestaurant ? (
            <Button
              href={`/restaurant/${data?.RestaurantAdmin.administeredRestaurant.id}`}
            >
              View {data?.RestaurantAdmin.administeredRestaurant.name}
            </Button>
          ) : null,
        ]}
      >
        <Statistic
          title="Account Type"
          value={
            data?.RestaurantAdmin.administeredRestaurant
              ? "Tablet"
              : data?.RestaurantAdmin.roles.find(
                  (r) =>
                    r === "CREATE_SUPER_ADMINS" || r === "CREATE_RESTAURANT"
                )
              ? "ecoeats Staff"
              : "Owner"
          }
        />
      </PageHeader>
      <div
        style={{
          padding: "0 24px",
        }}
      >
        {/* Page Header */}

        <Row>
          <Col flex={1}>
            {roles ? (
              <Form.Item label="Roles">
                <Select
                  dropdownMatchSelectWidth
                  value={roles}
                  mode="multiple"
                  onChange={(r) => setRoles(r)}
                >
                  {Object.keys(Permissions)
                    .sort((a, b) => a.localeCompare(b))
                    .map((r) => (
                      <Select.Option value={r} key={r}>
                        {r}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            ) : null}
          </Col>
        </Row>

        <Row gutter={32}>
          <Col flex={1}>
            <Table
              title={(r) => <Title level={3}>Restaurants</Title>}
              columns={[
                {
                  title: "Name",
                  dataIndex: "name",
                },
                {
                  title: "Zone",
                  dataIndex: "zone",
                  render: (z) => z.name,
                },
              ]}
              style={{
                cursor: "pointer",
              }}
              onRow={(r) => ({
                onClick: () => history.push(`/restaurant/${r.id}`),
              })}
              dataSource={data?.RestaurantAdmin.restaurants || []}
            ></Table>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Admin;
