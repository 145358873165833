import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Popover, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import Title from "antd/lib/typography/Title";
import React, { useCallback, useMemo, useState } from "react";
import { Blurhash } from "react-blurhash";
import { Link } from "react-router-dom";
import { MapType, Query } from "../../graphql/generated";
import { useTypedQuery } from "../../graphql/hooks";
import { IMAGE_ASSET_LIST } from "../../graphql/queries";
import { useDebounce } from "../../hooks/useDebounce";
import { getCDNUrl, getImageUrl } from "../../utils/image-url";
import { CopyText } from "./CopyText";
import MediaUpload from "./MediaUpload";
function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Bytes";
  var i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
}
type QueryType = MapType<Query, typeof IMAGE_ASSET_LIST>["ImageAssetList"];

export type ImageAssetType = QueryType["items"][0];

const COLUMNS: ColumnsType<QueryType["items"][0]> = [
  {
    title: "Image",
    render: (_, c) => (
      <Popover
        placement="right"
        content={
          <img
            src={getCDNUrl(c.key)}
            style={{ maxHeight: 512, width: "auto" }}
          />
        }
      >
        <a
          href={getImageUrl(c.key)}
          target="_blank"
          rel="noreferer noopener noreferrer"
        >
          <div
            style={{ width: 128, height: 128, backgroundColor: "lightgrey" }}
          >
            <img alt={c.altText} width="128" src={c.url} />
          </div>
        </a>
      </Popover>
    ),
  },
  {
    title: "Title",
    dataIndex: "title",
    sorter: true,
  },
  {
    title: "Resolution",
    key: "width",
    sorter: true,
    render: (_, c) => `${c.width}x${c.height}`,
  },
  {
    title: "Blur",
    dataIndex: "blurhash",
    render: (b) =>
      b && (
        <Blurhash
          hash={b}
          width={128}
          height={128}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      ),
  },
  {
    title: "Size",
    dataIndex: "fileSize",
    sorter: true,
    render: bytesToSize,
  },
  {
    title: "Created",
    dataIndex: "created",
    sorter: true,
    render: (c) => new Date(c).toLocaleString(),
  },
  {
    title: "ID",
    dataIndex: "id",
    render: (c) => <CopyText text={c} />,
  },
  {
    title: "Owner",
    dataIndex: "uploadedBy",
    render: (u) => u && <Link to={`/admin/${u.id}`}>{u.name}</Link>,
  },
];

export default function MediaList({
  onSelect,
}: {
  onSelect?: (images: QueryType["items"]) => void;
}) {
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [sortBy, setSortBy] = useState("created");
  const [order, setOrder] = useState("DESC");
  const [_query, setQuery] = useState("");

  const query = useDebounce(_query, 500);

  const { data, refetch, loading } = useTypedQuery(IMAGE_ASSET_LIST, {
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
      width: 256,
      height: 256,
    },
    fetchPolicy: "network-only",
  });

  const [showModal, setShowModal] = useState(false);

  const onChange = useCallback((p, f, s) => {
    if (s instanceof Array) {
    } else {
      setSortBy(
        s.order
          ? (s.field as string) || (s.columnKey as string) || "placedAt"
          : "placedAt"
      );
      setOrder(!s.order ? "DESC" : s.order === "ascend" ? "ASC" : "DESC");
    }
  }, []);

  return (
    <>
      <Table
        onRow={(r) => ({
          onClick: () => onSelect && onSelect([r]),
        })}
        onChange={onChange}
        title={() => (
          <div style={{ flexDirection: "row", display: "flex" }}>
            <Title style={{ flex: 1 }}>
              {data?.ImageAssetList.total || 0} Images
            </Title>

            <Space>
              <Input
                suffix={<SearchOutlined />}
                placeholder="Search"
                onChange={(v) => setQuery(v.target.value)}
              />
              <Button
                onClick={() => setShowModal(true)}
                icon={<UploadOutlined />}
                type="primary"
              >
                Upload
              </Button>
            </Space>
          </div>
        )}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["25", "50", "100"],
          total: data?.ImageAssetList.total,
          current: page,
          pageSize: take,
          onChange: (p, ps) => {
            if (ps) setTake(ps);
            setPage(p);
          },
        }}
        dataSource={data?.ImageAssetList.items}
        columns={COLUMNS}
      />
      <Modal
        footer={null}
        visible={showModal}
        onCancel={() => setShowModal(false)}
      >
        <MediaUpload
          onSuccess={() => {
            refetch();
            setShowModal(false);
          }}
        />
      </Modal>
    </>
  );
}

export function useMediaList() {
  const [items, setItems] = useState<QueryType["items"]>([]);

  const onSelect = useCallback((a) => setItems(a), []);

  const callback = useCallback(() => {
    Modal.confirm({
      content: <MediaList onSelect={onSelect} />,
    });
  }, [onSelect]);

  const ret = useMemo(() => [callback, items] as const, [callback, items]);

  return ret;
}
