import React from "react";
import { Redirect } from "react-router-dom";
import SingleCampaign from "./components/charity/SingleCampaign";
import SingleCharity from "./components/charity/SingleCharity";
import LeaseView from "./components/lease/LeaseView";
import ContainerTypeView from "./components/reusables/ContainerTypeView";
import ContainerView from "./components/reusables/ContainerView";
import PickupAggregate from "./components/reusables/PickupAggregate";
import ShipmentView from "./components/reusables/ShipmentView";
import WashingLocationView from "./components/reusables/WashingLocation";
import { ReusablesView } from "./components/reusables/ZoneReusables";
import VehicleView from "./components/vehicle/VehicleView";
// Layout Types
import { DefaultLayout } from "./layouts";
import { Accounting } from "./views/Accounting";
import { AddCategory } from "./views/AddCategory";
import { AddModifierGroup } from "./views/AddModifierGroup";
import Admin from "./views/Admin";
import Admins from "./views/Admins";
import BookingSlotView from "./views/BookingSlotView";
import Charity from "./views/Charity";
import { Cities } from "./views/Cities";
import { City } from "./views/City";
import Configurations from "./views/Configurations";
import Customer from "./views/Customer";
import Customers from "./views/Customers";
import Discount from "./views/Discount";
import { Discounts } from "./views/Discounts";
import Media from "./views/Media";
import MenuRequests from "./views/MenuRequests";
import MerchantSite from "./views/MerchantSite";
import Operations from "./views/Operations";
import { Order } from "./views/Order";
import Orders from "./views/Orders";
// Route Views
import Overview from "./views/Overview";
import Partners from "./views/Partners";
import Platform from "./views/Platform";
import Promotion from "./views/Promotion";
import Promotions from "./views/Promotions";
import { Restaurant } from "./views/Restaurant";
import { Rider } from "./views/rider/Rider";
import Riders from "./views/rider/Riders";
import Tablets from "./views/Tablets";
import { ZoneView } from "./views/Zone";
// Layout Types
// Route Views
// Layout Types
// Route Views

const ROUTES = [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/dashboard" />,
  },
  {
    path: "/dashboard/:tab?",
    layout: DefaultLayout,
    component: Overview,
  },

  {
    path: "/restaurant/:id/:tab?/:subtab?",
    layout: DefaultLayout,
    component: Restaurant,
    exact: false,
  },

  {
    path: "/restaurant/:id/add-category",
    layout: DefaultLayout,
    component: AddCategory,
    exact: true,
  },
  {
    path: "/restaurant/:id/add-modifier-group",
    layout: DefaultLayout,
    component: AddModifierGroup,
    exact: true,
  },
  {
    path: "/order/:id",
    layout: DefaultLayout,
    component: Order,
    exact: true,
  },
  {
    path: "/partners/:tab?",
    layout: DefaultLayout,
    component: Partners,
  },
  {
    path: "/cities",
    layout: DefaultLayout,
    component: Cities,
  },
  {
    path: "/ops",
    layout: DefaultLayout,
    component: Operations,
  },
  {
    path: "/city/:id",
    layout: DefaultLayout,
    component: City,
  },
  {
    path: "/zone/:id/:tab?/:subtab?",
    layout: DefaultLayout,
    component: ZoneView,
  },
  {
    path: "/booking-slot/:id",
    layout: DefaultLayout,
    component: BookingSlotView,
  },
  {
    path: "/riders",
    layout: DefaultLayout,
    component: Riders,
  },
  {
    path: "/rider/:id/:tab?",
    layout: DefaultLayout,
    component: Rider,
  },

  {
    path: "/discounts",
    layout: DefaultLayout,
    component: Discounts,
  },
  {
    path: "/discount/:id",
    layout: DefaultLayout,
    component: Discount,
  },
  {
    path: "/orders",
    layout: DefaultLayout,
    component: Orders,
  },
  {
    path: "/accounting/:tab?",
    layout: DefaultLayout,
    component: Accounting,
  },
  {
    path: "/customers",
    layout: DefaultLayout,
    component: Customers,
  },
  {
    path: "/customer/:id/:tab?",
    layout: DefaultLayout,
    component: Customer,
  },
  {
    path: "/admins",
    layout: DefaultLayout,
    component: Admins,
  },
  {
    path: "/admin/:id",
    layout: DefaultLayout,
    component: Admin,
  },
  {
    path: "/menu-requests",
    layout: DefaultLayout,
    component: MenuRequests,
  },
  {
    path: "/tablets",
    layout: DefaultLayout,
    component: Tablets,
  },
  {
    path: "/marketing/:tab?",
    layout: DefaultLayout,
    component: Promotions,
  },
  {
    path: "/promotion/:id",
    layout: DefaultLayout,
    component: Promotion,
  },
  {
    path: "/platform/:tab?",
    layout: DefaultLayout,
    component: Platform,
  },
  {
    path: "/donations/:tab?",
    layout: DefaultLayout,
    component: Charity,
  },
  {
    path: "/charity/:id",
    layout: DefaultLayout,
    component: SingleCharity,
  },
  {
    path: "/campaign/:id",
    layout: DefaultLayout,
    component: SingleCampaign,
  },
  {
    path: "/media/:tab?",
    layout: DefaultLayout,
    component: Media,
  },
  {
    path: "/configuration/:id",
    layout: DefaultLayout,
    component: Configurations,
  },
  {
    path: "/lease/:id",
    layout: DefaultLayout,
    component: LeaseView,
  },
  {
    path: "/vehicle/:id",
    layout: DefaultLayout,
    component: VehicleView,
  },
  {
    path: "/merchant-site/:id/:tab?",
    layout: DefaultLayout,
    component: MerchantSite,
  },
  {
    path: "/reusables-overview/:subtab?",
    layout: DefaultLayout,
    component: ReusablesView,
  },
  {
    path: "/reusables/pickup-aggregate/:id",
    layout: DefaultLayout,
    component: PickupAggregate,
  },
  {
    path: "/reusables/washing-location/:id",
    layout: DefaultLayout,
    component: WashingLocationView,
  },
  {
    path: "/reusables/shipment/:id",
    layout: DefaultLayout,
    component: ShipmentView,
  },
  {
    path: "/reusables/container/:id",
    layout: DefaultLayout,
    component: ContainerView,
  },
  {
    path: "/reusables/container-type/:id",
    layout: DefaultLayout,
    component: ContainerTypeView,
  },
];

export default ROUTES;
