import React from "react";
import { Nav } from "shards-react";
import { DarkModeToggle } from "./DarkModeToggle";
import { UserActions } from "./UserActions";

export default () => (
  <Nav navbar className="border-left flex-row">
    <DarkModeToggle />
    <UserActions />
  </Nav>
);
