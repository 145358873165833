import { $ } from "../../../graphql/generated";
import { mutation, query } from "../../../graphql/hooks";

export const DYNAMIC_FORM = query({
  DynamicForm: [
    {
      id: $`id`,
      entityId: $`entityId`,
      meta: $`meta`,
    },
    {
      id: true,
      title: true,
      type: true,
      description: true,
      schema: true,
      metaSchema: true,
      initialValues: true,
      canSubmit: true,
      pollInterval: true,
      submitText: true,
      meta: {
        pagination: {
          skip: true,
          take: true,
          total: true,
          order: true,
          sortBy: true,
        },
      },
      __typename: true,
    },
  ],
});

export const SUBMIT_DYNAMIC_FORM = mutation({
  submitDynamicForm: [
    {
      id: $`id`,
      entityId: $`entityId`,
      data: $`data`,
    },
    {
      __typename: true,
      success: true,
      successMessage: true,
      form: {
        ...DYNAMIC_FORM.DynamicForm[1],
      },
    },
  ],
});
