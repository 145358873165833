// eslint-disable-next-line no-unused-vars
import React, { useCallback, useState } from "react";
import { mergeDeep } from "../utils";

type ObjectLiteral = { [key: string]: any };

export function useFormData<T extends ObjectLiteral>(
  initialFormData: T
): [
  T,
  (e: React.FormEvent<HTMLInputElement>) => void,
  (data: T | ((s: T) => void)) => void
] {
  const [formData, setFormData] = useState(initialFormData);

  const onChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (!e) return;
      const target = e.currentTarget;

      // eslint-disable-next-line prefer-destructuring
      let value: number | boolean | string = target.value;
      if (target.type === "number") {
        value = Number.parseFloat(value);
      } else if (target.type === "checkbox") {
        value = !formData[target.name];
      }

      const path = target.name.split(".");

      const stateDelta = {
        [path[0]]: path.length === 1 ? value : ({} as any),
      };
      let currentObj = stateDelta[path[0]];

      for (let i = 1; i < path.length; i += 1) {
        currentObj[path[i]] = i + 1 >= path.length ? value : {};
        currentObj = currentObj[path[i]];
      }

      setFormData(mergeDeep({}, formData, stateDelta));
    },
    [formData]
  );

  return [formData, onChange, setFormData];
}
