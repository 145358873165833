import { InboxOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Form,
  Input,
  message,
  Progress,
  Space,
  Upload,
} from "antd";
import React, { useCallback, useRef, useState } from "react";
import { $, UploadMediaPart } from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";

/**
 * Upload new media to the server as an asset
 */
export default function VideoUpload({
  onSuccess,
}: {
  onSuccess?: (id: string) => void;
}) {
  const videoObj = useRef<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [confirmUpload] = useTypedMutation(
    {
      UploadMediaComplete: [
        {
          key: $`key`,
          uploadId: $`uploadId`,
          parts: $`parts`,
        },
        {
          id: true,
        },
      ],
    },
    {
      onCompleted: (d) => {
        message.success("Uploaded media!");
        setUploading(false);
        onSuccess && onSuccess(d.UploadMediaComplete.id);
      },
    }
  );

  const [upload, { loading, error }] = useTypedMutation(
    {
      UploadVideoAsset: [
        {
          mimeType: $`mimeType`,
          size: $`size`,
          altText: $`altText`,
          title: $`title`,
        },
        {
          id: true,
          key: true,
          uploadId: true,
          urls: {
            url: true,
            endBytes: true,
            startBytes: true,
            method: true,
          },
        },
      ],
    },
    {
      onCompleted: async (d) => {
        if (!videoObj.current) {
          message.error("Failed to upload.");
          return;
        }
        setUploading(true);
        message.info({
          message: "Upload Started!",
        });

        const parts: UploadMediaPart[] = [];

        // Do the part upload
        for (let i = 0; i < d.UploadVideoAsset.urls.length; i++) {
          const url = d.UploadVideoAsset.urls[i];
          const resp = await fetch(url.url, {
            method: url.method,
            body: videoObj.current.slice(url.startBytes, url.endBytes),
          });
          parts.push({
            etag: resp.headers.get("etag")!,
            partNumber: i + 1,
          });
          setProgress(((i + 1) / d.UploadVideoAsset.urls.length) * 100);
        }

        confirmUpload({
          variables: {
            key: d.UploadVideoAsset.key,
            uploadId: d.UploadVideoAsset.uploadId,
            parts,
          },
        });
      },
    }
  );

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess && onSuccess("ok", null as any);
    }, 0);
  };

  const onFinish = useCallback((v) => {
    videoObj.current = v.video[0].originFileObj;
    if (!videoObj.current) return;
    console.log(videoObj.current);
    upload({
      variables: {
        mimeType: videoObj.current.type,
        size: videoObj.current.size,
        title: v.title || videoObj.current.name,
        altText: v.altText,
      },
    });
  }, []);

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Form.Item name="title" label="Title">
        <Input />
      </Form.Item>

      <Form.Item name="altText" label="Alt Text">
        <Input />
      </Form.Item>

      <Form.Item
        name="video"
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload.Dragger name="video" customRequest={customRequest as any}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>
      </Form.Item>

      <Space align="end">
        <Button loading={loading} type="primary" htmlType="submit">
          Upload
        </Button>
      </Space>

      {uploading && <Progress percent={progress} />}

      {error && <Alert type="error" message={error.message} />}
    </Form>
  );
}
