import { $ } from "../../../graphql/generated";
import { mutation, query } from "../../../graphql/hooks";

export const ORDER_MESSAGES_LIST = query({
  ChatMessageList: [
    {
      orderId: $`orderId`,
      skip: $`skip`,
      take: $`take`,
      isReading: $`isReading`,
    },
    {
      hasMore: true,
      total: true,
      unreadCount: true,
      items: {
        id: true,
        text: true,
        read: true,
        sent: true,
        viewableBy: true,
        sender: {
          id: true,
          name: true,
          email: true,
          type: true,
          avatarUrl: true,
        },
      },
    },
  ],
});

export const SEND_ORDER_MESSAGE = mutation({
  sendChatMessage: [
    {
      message: {
        orderId: $`orderId`,
        text: $`text`,
        viewableBy: $`viewableBy`,
      },
    },
    {
      ...ORDER_MESSAGES_LIST.ChatMessageList[1].items,
    },
  ],
});
