import { Descriptions } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import { Link } from "react-router-dom";
import UpdateOrderAddressButton from "../UpdateOrderAddressButton";

export default function OrderCustomerInfo({
  bordered,
  column,
  customerId,
  email,
  orderId,
  name,
  address,
  basket,
}: {
  bordered: boolean;
  column: number;
  orderId: string;
  basket: {
    instructions?: string;
  };
  customerId: string;
  name: string;
  email: string;
  address?: {
    coordinates: any;
    flat_number?: string;
    postcode: string;
    address_line_1: string;
    phone_number?: string;
  };
}) {
  return (
    <Descriptions bordered={bordered} column={column}>
      <Descriptions.Item label="Customer">
        <Link to={`/customer/${customerId}`}>{name}</Link>
      </Descriptions.Item>
      <Descriptions.Item label="Email">{email}</Descriptions.Item>

      {address ? (
        <>
          <Descriptions.Item label="Address">
            {address?.flat_number ? address?.flat_number + ", " : ""}
            {address?.address_line_1}, {address?.postcode}
            <UpdateOrderAddressButton
              coordinates={address?.coordinates.coordinates}
              customerId={customerId}
              orderId={orderId}
              initialValues={address}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Phone">
            {address?.phone_number}
          </Descriptions.Item>
          <Descriptions.Item label="Instructions">
            {basket.instructions || (
              <Text type="secondary">No Instructions</Text>
            )}
          </Descriptions.Item>
        </>
      ) : null}
    </Descriptions>
  );
}
