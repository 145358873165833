import {
  CameraOutlined,
  ReloadOutlined,
  SoundOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Descriptions,
  Divider,
  message,
  PageHeader,
  Popconfirm,
  Progress,
  Space,
} from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Marker } from "react-leaflet";
import { Link } from "react-router-dom";
import {
  $,
  PartnerTabletActionType,
  PartnerTabletSyncArgs,
} from "../../graphql/generated/graphql-zeus";
import { useTypedMutation, useTypedQuery } from "../../graphql/hooks";
import { PARTNER_TABLET } from "../../graphql/queries";
import { getCDNUrl } from "../../utils/image-url";
import Map from "../../utils/Map";
import { LRestoIcon } from "../../views/Order";
import WebRTCViewer from "./WebRTCViewer";

export function PartnerTablet({ id }: { id: string }) {
  const { data } = useTypedQuery(PARTNER_TABLET, {
    pollInterval: 3000,
    fetchPolicy: "network-only",
    variables: {
      id,
    },
  });
  const [commitActionMutation, { loading: committing }] = useTypedMutation(
    {
      PartnerTabletAction: [
        {
          id: $`id`,
          action: $`action`,
        },
        {
          id: true,
        },
      ],
    },
    {
      onCompleted: () => message.success("Sent to tablet!"),
    }
  );

  const commitAction = (action: PartnerTabletActionType) =>
    commitActionMutation({
      variables: {
        action,
        id,
      },
    });

  const selected = data?.PartnerTablet;

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const t = setTimeout(() => setLoaded(true), 300);

    return () => clearTimeout(t);
  }, []);

  const [tabletSync] = useTypedMutation({
    PartnerTabletSync: [
      {
        partnerId: $`partnerId`,
        tablet: $`tablet`,
      },
      {
        id: true,
      },
    ],
  });

  if (!selected) return <></>;

  return (
    <>
      {loaded ? (
        <Map
          mapType="raster"
          center={selected.location?.coordinates}
          zoom={14}
          style={{
            height: "300px",
          }}
        >
          <Marker
            position={selected.location?.coordinates}
            icon={LRestoIcon()}
          ></Marker>
        </Map>
      ) : (
        <div
          style={{
            width: "100%",
            height: "300px",
          }}
        />
      )}

      <PageHeader
        title={selected.serial}
        subTitle={`${selected.manufacturer} ${selected.model}`}
        extra={[
          <Badge
            status={
              moment(selected.lastConnection).diff(moment(), "minutes") < -1
                ? "error"
                : "success"
            }
            text={moment(selected.lastConnection).fromNow()}
          />,
        ]}
      >
        <Space>
          <Button
            loading={committing}
            onClick={() =>
              commitAction(PartnerTabletActionType.TAKE_SCREENSHOT)
            }
            icon={<CameraOutlined />}
          >
            Screenshot
          </Button>
          <Button
            icon={<SoundOutlined />}
            loading={committing}
            onClick={() => commitAction(PartnerTabletActionType.PLAY_SOUND)}
          >
            Play Sound
          </Button>
          <Popconfirm
            title="The app will exit, but may or may not start again straight away. Partners can tap the app to open it again."
            okButtonProps={{
              danger: true,
            }}
            onConfirm={() => commitAction(PartnerTabletActionType.RESTART_APP)}
          >
            <Button icon={<ReloadOutlined />} loading={committing}>
              Restart App{" "}
            </Button>
          </Popconfirm>
          <Popconfirm
            title="This will only work on devices we own."
            okButtonProps={{
              danger: true,
            }}
            onConfirm={() =>
              commitAction(PartnerTabletActionType.RESTART_DEVICE)
            }
          >
            <Button icon={<ReloadOutlined />} loading={committing}>
              Restart Device{" "}
            </Button>
          </Popconfirm>
          <Button
            icon={<VideoCameraOutlined />}
            onClick={() => {
              const args: PartnerTabletSyncArgs = {
                manufacturer: selected.manufacturer,
                model: selected.model,
                serial: selected.serial,
                webRTCOfferReceiveDescription: null,
                webRTCOfferDescription: null,
                webRTCOfferCandidates: null,
                webRTCOfferReceiveCandidates: null,
              };

              tabletSync({
                variables: {
                  tablet: args,
                  partnerId: selected.partnerId,
                },
              }).then((r) => {
                commitAction(PartnerTabletActionType.STREAM_VIDEO);
              });
            }}
          >
            Share Screen
          </Button>
        </Space>
        <Descriptions
          style={{ marginTop: 8 }}
          bordered
          column={{
            lg: 2,
            md: 1,
          }}
        >
          <Descriptions.Item label="Partner">
            <Link to={`/restaurant/${selected.partner.id}`}>
              {selected.partner.name}
            </Link>
          </Descriptions.Item>
          <Descriptions.Item label="Release">
            <Text style={{ fontFamily: "monospace" }}>{selected.release}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="RAM">
            <Progress
              percent={Math.floor(
                ((selected.usedRam || 0) / (selected.ram || 0)) * 100
              )}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Battery">
            {selected.battery ? (
              <Progress
                percent={Math.floor(selected.battery * 100)}
                strokeColor={
                  selected.battery > 0.8
                    ? "green"
                    : selected.battery > 0.5
                    ? "orange"
                    : "red"
                }
              />
            ) : (
              ""
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Volume">
            <Progress percent={Math.floor((selected.volume || 0) * 100)} />
          </Descriptions.Item>
          <Descriptions.Item label="WiFi">
            {selected.wifiNetwork ?? "Unknown"} (
            {selected.routerMake ?? selected.bssid}){" "}
            <Progress
              type="circle"
              percent={100 * ((selected.wifiStrength || 0) / 4)}
              size="small"
              width={32}
            />
          </Descriptions.Item>
          <Descriptions.Item label="IP">
            {selected.ip ?? "Unknown"} ({selected.hostname})
          </Descriptions.Item>
          <Descriptions.Item label="ISP">{selected.isp}</Descriptions.Item>
        </Descriptions>
        <WebRTCViewer
          tablet={selected}
          offer={selected.webRTCOfferDescription}
        />

        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            overflow: "scroll",
          }}
        >
          {selected.screenshots.map((s) =>
            !loaded ? null : (
              <Card
                key={s.id}
                cover={<img src={getCDNUrl(s.key)} width={128} />}
                style={{ marginRight: 8 }}
              >
                <Card.Meta title={s.created} />
              </Card>
            )
          )}
        </div>
        <pre>{JSON.stringify(selected, null, 2)}</pre>
      </PageHeader>
    </>
  );
}
