import { LockFilled } from "@ant-design/icons";
import { Alert } from "antd";
import React from "react";
import { Permissions } from "../../graphql/generated";
import useMe from "../../hooks/useMe";

/**
 * Guard a component against some permissions
 */
export default function PermissionsGuard({
  children,
  permissions,
}: {
  children?: React.ReactNode;
  permissions: (keyof typeof Permissions)[];
}) {
  const { me } = useMe();

  return (
    <>
      {permissions.some((p) => me?.roles.includes(p as any)) ? (
        children
      ) : (
        <Alert
          icon={<LockFilled />}
          message={`You need ${permissions.join(", ")} to view this.`}
          type="warning"
        />
      )}
    </>
  );
}
