import { Tabs } from "antd";
import React from "react";
import { MapType, Query, Zone } from "../../graphql/generated";
import { ZONE } from "../../graphql/queries";
import useTabSync from "../../hooks/useTabSync";
import DynamicForm from "../forms/DynamicForm";

export default function ZoneAutoScaler({
  zone,
  refetch,
  visible = true,
}: {
  zone: MapType<Query, typeof ZONE>["zone"];
  refetch: Function;
  visible?: boolean;
}) {
  const { key, onSelect } = useTabSync("autoscaler", {}, "subtab");

  return (
    <div style={{ display: !visible ? "none" : undefined }}>
      <Tabs activeKey={key} onChange={onSelect}>
        <Tabs.TabPane tab="AutoScaler" key="autoscaler">
          <DynamicForm id="AutoScalerConfig" entityId={zone.id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Busy Monitor" key="busy-monitor">
          {key === "busy-monitor" && (
            <DynamicForm id="BusyMonitorSettings" entityId={zone.id} />
          )}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
