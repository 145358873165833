import React from "react";
import SplitPane from "react-split-pane";

export type PanelArrangement = "wide-columns" | "long-rows";

interface Props {
  children: JSX.Element[];
  preferArrange?: PanelArrangement;
}

const maxPanels = 4;

const SplitPanels = (props: Props) => {
  const { children, preferArrange = "wide-columns" } = props;
  const nPanes = children.length;

  if (nPanes > maxPanels) {
    console.warn(
      `Too many panels provided: ${nPanes}, only displaying max ${maxPanels}.`
    );
  }

  if (nPanes === 1) {
    return (
      <SplitPane split="horizontal" maxSize="100%" defaultSize="100%">
        {children[0]}
      </SplitPane>
    );
  } else if (nPanes === 2) {
    return (
      <SplitPane
        split={preferArrange === "wide-columns" ? "horizontal" : "vertical"}
        maxSize="50%"
        defaultSize="50%"
        pane2Style={{
          flexGrow: 0,
          ...(preferArrange === "wide-columns"
            ? { maxHeight: "50%", minHeight: "50%" }
            : { maxWidth: "50%", minWidth: "50%" }),
        }}
        allowResize={false}
      >
        {children}
      </SplitPane>
    );
  } else if (nPanes === 3) {
    return (
      <SplitPane
        split={preferArrange === "wide-columns" ? "horizontal" : "vertical"}
        maxSize="50%"
        defaultSize="50%"
        pane2Style={{
          flexGrow: 0,
          ...(preferArrange === "wide-columns"
            ? { maxHeight: "50%" }
            : { maxWidth: "50%", minWidth: "50%" }),
        }}
        allowResize={false}
      >
        <SplitPane
          split={preferArrange === "wide-columns" ? "vertical" : "horizontal"}
          maxSize="50%"
          defaultSize="50%"
          pane2Style={{
            flexGrow: 0,
            ...(preferArrange === "wide-columns"
              ? { maxWidth: "50%", minWidth: "50%" }
              : { maxHeight: "50%", minHeight: "50%" }),
          }}
          allowResize={false}
        >
          {children[0]}
          {children[1]}
        </SplitPane>
        {children[2]}
      </SplitPane>
    );
  } else if (nPanes >= 4) {
    return (
      <SplitPane
        split="horizontal"
        maxSize="50%"
        defaultSize="50%"
        pane2Style={{ minHeight: "50%", maxHeight: "50%" }}
        allowResize={false}
      >
        <SplitPane
          split="vertical"
          maxSize="50%"
          defaultSize="50%"
          pane2Style={{ maxWidth: "50%", flexGrow: 0, minWidth: "50%" }}
          allowResize={false}
        >
          {children[0]}
          {children[1]}
        </SplitPane>
        <SplitPane
          split="vertical"
          maxSize="50%"
          defaultSize="50%"
          pane2Style={{ maxWidth: "50%", flexGrow: 0, minWidth: "50%" }}
          allowResize={false}
        >
          {children[2]}
          {children[3]}
        </SplitPane>
      </SplitPane>
    );
  } else {
    return null;
  }
};

export default SplitPanels;
