import { PushpinOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  InputNumber,
  message,
  Row,
  Select,
  Switch,
} from "antd";
import Text from "antd/lib/typography/Text";
import L from "leaflet";
import React, { useCallback } from "react";
import { Alert } from "react-bootstrap";
import ReactDOMServer from "react-dom/server";
import { Marker } from "react-leaflet";
import {
  $,
  AddressUpdateInput,
  CoreConfigurations,
  FulfillmentMethod,
  GraphQLGeoJSONPoint,
  Restaurant,
  RestaurantBusyStatus,
  RestaurantOnboardingStatus,
} from "../graphql/generated";
import { useTypedMutation } from "../graphql/hooks";
import { UPDATE_PARTNER } from "../graphql/mutations";
import { RESTAURANT } from "../graphql/queries";
import { getImageUrl } from "../utils/image-url";
import Map from "../utils/Map";
import { useUpdateItemKey } from "../utils/update-item-key";
import CoreAppDisplay from "./CoreAppDisplay";
import PartnerCommission from "./financials/commissions/PartnerCommission";
import UpdateAddressButton from "./forms/UpdateAddressButton";

const FulfillmentOptions = Object.keys(FulfillmentMethod).map((k) => ({
  value: k,
  label: k,
}));
const OnboardingOptions = Object.keys(RestaurantOnboardingStatus).map((k) => ({
  label: k,
  value: k,
}));
const BusyOptions = Object.keys(RestaurantBusyStatus).map((k) => ({
  label: k,
  value: k,
}));

export const RestoIcon = (
  imageKey?: string,
  configuration?: CoreConfigurations
) =>
  L?.divIcon?.({
    className: "custom-icon",
    iconAnchor: [16, 32],
    html: ReactDOMServer.renderToString(
      <div
        style={{
          position: "relative",
          width: 52,
          height: 52,
          borderRadius: 25,
          border: "2px solid white",
          filter: "revert",
        }}
      >
        {imageKey ? (
          <>
            <img
              alt="resto-icon"
              src={getImageUrl(imageKey, 256, 256)}
              style={{
                width: 48,
                height: 48,
                borderRadius: 24,
                objectFit: "cover",
              }}
            />
            <span
              style={{
                position: "absolute",
                top: -2,
                right: -2,
              }}
            >
              <CoreAppDisplay
                configuration={configuration || CoreConfigurations.ECOEATS}
                srcHeight={18}
                srcWidth={18}
                imgWidth={18}
              />
            </span>
          </>
        ) : (
          <CoreAppDisplay
            configuration={configuration || CoreConfigurations.ECOEATS}
            srcHeight={48}
            srcWidth={48}
            imgWidth={48}
          />
        )}
      </div>
    ),
  });
const inputs: (keyof Restaurant)[] = [
  "basePrepTime",
  "baseDeliveryTime",
  "incrementPrepTime",
  "commission",
  "commissionCollection",
  "fulfillmentMethods",
  "busyStatus",
  "onboardingStatus",
  "acceptsScheduledOrders",
  "isReusablesEnabled",
];
export const RestaurantDelivery = ({
  restaurant,
  refetch,
}: {
  restaurant: {
    id: string;
    name: string;
    headerImageKey?: string;
    configuration?: CoreConfigurations;
    acceptsScheduledOrders: boolean;
    isReusablesEnabled: boolean;
    address: {
      id: string;
      flat_number?: string;
      address_line_1: string;
      postcode: string;
      coordinates: GraphQLGeoJSONPoint;
      instructions?: string;
    };
  };
  refetch: ( ) => void;
}) => {

  const [updateRestaurant, { loading, error }] = useTypedMutation(
    UPDATE_PARTNER,
    {
      onCompleted: () => {
        message.success("Updated Partner! Cache may take ~15s to refresh.");
        refetch();
      },
    }
  );

  const submitForm = useCallback(
    (e) => {
      const input = {
        variables: {
          id: restaurant.id,
          restaurant: {
            ...e,
          },
        },
      };
      updateRestaurant(input);
    },
    [restaurant.id, updateRestaurant]
  );

  const [updateAddress] = useTypedMutation(
    {
      updateAddress: [
        {
          address: $`address`,
          id: $`id`,
        },
        {
          ...RESTAURANT.Restaurant[1].address,
        },
      ],
    },
    {
      onCompleted: () => message.success("Saved!"),
    }
  );

  const updateItemKey = useUpdateItemKey<AddressUpdateInput>(
    updateAddress,
    "address",
    restaurant.address.id
  );

  const formattedAddress = `${(restaurant.address?.flat_number || "") + " "}${
    restaurant.address?.address_line_1
  }, ${restaurant.address?.postcode}`;

  return (
    <>
      {restaurant.address && (
        <>
          <Map
            center={restaurant.address.coordinates.coordinates}
            zoom={14}
            style={{
              height: "400px",
            }}
          >
            <Marker
              position={restaurant.address.coordinates?.coordinates}
              icon={RestoIcon(
                restaurant.headerImageKey,
                restaurant.configuration
              )}
            ></Marker>
          </Map>
          <Descriptions bordered style={{ margin: "16px 0" }}>
            <Descriptions.Item label="Address">
              {formattedAddress}{" "}
              <Button
                type="primary"
                target="_blank"
                icon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <PushpinOutlined />
                  </div>
                }
                href={`http://maps.google.com/maps/dir/?daddr=${formattedAddress}&dirflg=d&dir_action=navigate`}
              />
              <UpdateAddressButton
                initialAddress={restaurant?.address}
                onComplete={() => {
                  refetch();
                }}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Rider Instructions">
              <Text
                editable={{ onChange: (v) => updateItemKey("instructions", v) }}
              >
                {restaurant.address.instructions}
              </Text>
            </Descriptions.Item>
          </Descriptions>
        </>
      )}

      <Row gutter={16}>
        <Col md={24} lg={12}>
          <Card>
            {error
              ? error.graphQLErrors.map((e) => (
                  <Alert variant="danger">{JSON.stringify(e)}</Alert>
                ))
              : null}

            {error?.networkError ? (
              <Alert variant="danger">
                {JSON.stringify(error?.networkError?.message)}
              </Alert>
            ) : null}

            <Form
              initialValues={restaurant}
              layout="vertical"
              onFinish={submitForm}
            >
              <Form.Item
                label="Fulfillment"
                help="Which methods does this partner support?"
                name="fulfillmentMethods"
              >
                <Select options={FulfillmentOptions} mode="multiple" />
              </Form.Item>

              <Form.Item
                // initialValue={restaurant.acceptsScheduledOrders}
                name="acceptsScheduledOrders"
                label="Scheduled Orders"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                // initialValue={restaurant.isReusablesEnabled}
                name="isReusablesEnabled"
                label="Supports Reusables?"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                name="basePrepTime"
                label="Base Prep Time"
                help="How long this partner takes to prepare a dish, on average."
              >
                <InputNumber />
              </Form.Item>

              <Form.Item
                name="incrementPrepTime"
                label="Increment Prep Time"
                help="By how much does the prep time change when this partner is busy."
              >
                <InputNumber />
              </Form.Item>

              <Form.Item
                name="commission"
                label={
                  <Text>
                    Commission (Delivery){" "}
                    <Text type="secondary">
                      Deprecated, use Commission Plans
                    </Text>
                  </Text>
                }
              >
                <InputNumber />
              </Form.Item>

              <Form.Item
                name="commissionCollection"
                label={
                  <Text>
                    Commission (Non-Delivery){" "}
                    <Text type="secondary">
                      Deprecated, use Commission Plans
                    </Text>
                  </Text>
                }
              >
                <InputNumber />
              </Form.Item>

              <Form.Item label="Busy?" name="busyStatus">
                <Select options={BusyOptions} />
              </Form.Item>

              <Form.Item name="onboardingStatus" label="Onboarding Status">
                <Select options={OnboardingOptions} />
              </Form.Item>

              <Button
                htmlType="submit"
                type="primary"
                icon={<SaveOutlined />}
                loading={loading}
              >
                Save {restaurant.name}
              </Button>
            </Form>
          </Card>
        </Col>

        <Col md={24} lg={12}>
          <Card>
            <PartnerCommission partnerId={restaurant.id} />
          </Card>
        </Col>
      </Row>
    </>
  );
};
