import { SearchOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Divider,
  PageHeader,
  Result,
  Space,
  Steps,
  Table,
  Tag,
} from "antd";
import { formatDistance } from "date-fns";
import React, { useMemo, useRef } from "react";
import { Map } from "react-leaflet";
import { Link } from "react-router-dom";
import { MapType, Query } from "../../graphql/generated";
import { useTypedQuery } from "../../graphql/hooks";
import { RIDER_FLIGHT_PLAN, RIDER_LIVE_UI } from "../../graphql/queries";
import { formatAddress } from "../forms/AddAddressForm";
import { OrderBadge } from "../OrderBadge";
import PickupAtDisplay from "../PickupAtDisplay";
import FlightPlanMap from "./FlightPlanMap";

const { Step } = Steps;

type FlightPlanResult = NonNullable<
  MapType<Query, typeof RIDER_FLIGHT_PLAN>["Rider"]["activeFlightPlan"]
>;

const PageQuery = { ...RIDER_FLIGHT_PLAN, ...RIDER_LIVE_UI };

export default function RiderFlightPlan({ riderId }: { riderId: string }) {
  const { data } = useTypedQuery(PageQuery, {
    variables: {
      id: riderId,
      riderId,
    },
    pollInterval: 5000,
    fetchPolicy: "network-only",
  });

  const ui = data?.RiderLiveUI;
  const plan = data?.Rider.activeFlightPlan;

  const steps = useMemo(() => {
    if (!plan) return [];

    const pickups: any[] = [];
    const destinations: any[] = [];

    for (const l of plan.legs) {
      pickups.push(
        <Step
          key={l.id + "pickup"}
          title={<Link to={`/order/${l.order.id}`}>{l.order.number}</Link>}
          status={
            ui?.job?.currentDestination.coordinates.join(",") ===
            l.order.address?.coordinates?.coordinates.join(",")
              ? "process"
              : "wait"
          }
          subTitle={l.order.restaurant.name}
          description={
            <Space direction="vertical">
              <PickupAtDisplay
                status={l.order.status}
                initialPickupAt={l.order.initialPickupAt}
                riderPickedUpTime={l.order.riderPickedUpTime}
              />
              <OrderBadge status={l.order.status} />
              <Badge
                status={
                  l.order.isBeingOfferedToRider ? "processing" : "success"
                }
                text={
                  (l.order.isBeingOfferedToRider ? "Offering" : "Assigned") +
                  " " +
                  formatDistance(
                    new Date(l.order.lastOfferedToRider),
                    new Date(),
                    {
                      addSuffix: true,
                    }
                  )
                }
              />
            </Space>
          }
        />
      );
    }

    return pickups.concat(destinations);
  }, [plan]);

  const numberOfOrders = plan?.legs.length || 0;

  const activeOffer = plan?.activeOffer;

  const mapRef = useRef<Map | null>(null);

  return (
    <>
      {plan ? (
        <>
          <FlightPlanMap
            forwardRef={(ref) => (mapRef.current = ref)}
            plan={plan}
            riderPosition={data?.Rider.position?.coordinates}
            ui={ui}
            vehicle={data?.Rider.vehicle}
          />
          <PageHeader title={`${numberOfOrders} Orders`} />
          <Steps
            size="default"
            current={1}
            status="process"
            style={{
              maxWidth: "768px",
              margin: "0 auto",
            }}
          >
            {steps}
          </Steps>
          <Divider>Flight Plan Legs</Divider>
          <Table
            dataSource={plan.legs}
            columns={[
              {
                title: "Leg Order",
                sorter: (a, b) => a.legOrder - b.legOrder,
                dataIndex: "legOrder",
              },
              {
                title: "a",
                render: (_, r) => (r.address ? formatAddress(r.address) : ""),
              },
              {
                title: "Order",
                render: (_, r) => (
                  <Tag color="green">
                    <Link to={`/order/${r.order.id}`}>{r.order.number}</Link>{" "}
                    <OrderBadge status={r.order.status} />
                  </Tag>
                ),
              },
              {
                render: (_, r) => (
                  <Space>
                    {r.address ? (
                      <Button
                        type="text"
                        icon={<SearchOutlined />}
                        onClick={() =>
                          mapRef.current?.leafletElement.flyTo(
                            {
                              lat: r.address!.coordinates?.coordinates[0],
                              lng: r.address!.coordinates?.coordinates[1],
                            },
                            15
                          )
                        }
                      ></Button>
                    ) : null}
                  </Space>
                ),
              },
            ]}
          />
        </>
      ) : (
        <Result status="404" title="This rider has no flight plan." />
      )}
      <Divider>Rider's Live UI</Divider>
      <pre>{JSON.stringify(ui, null, 2)}</pre>
    </>
  );
}
