import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { getImageUrl } from "../../utils/image-url";

interface Props {
  img?: File | null;
  initialKey?: string;
  onChange?: (newImg: File | null) => void;
  onDelete: () => void;
}

function UploadImage(props: Props) {
  const { img, onChange, onDelete, initialKey } = props;

  const [hasClickedDelete, setHasClickedDelete] = useState(false);

  const [imgSrc, setImgSrc] = useState(
    initialKey ? getImageUrl(initialKey, 512) : null
  );

  useEffect(() => {
    if (img !== null && img !== undefined) {
      console.log("attempting to read file.", img);
      const reader = new FileReader();
      reader.onload = (e) => setImgSrc(e.target?.result as any);
      reader.readAsDataURL(img);
    } else if (hasClickedDelete) {
      setImgSrc(null);
    }
  }, [img, hasClickedDelete]);

  const uploadProps = {
    accept: "image/*",
    showUploadList: false,
    beforeUpload: (file) => {
      if (onChange) onChange(file);
      // prevents from automatically uploading
      return false;
    },
  };

  return (
    <>
      <Upload {...uploadProps}>
        {imgSrc ? (
          <div>
            <img
              style={{
                maxWidth: "400px",
                maxHeight: "400px",
                marginBottom: 10,
              }}
              src={imgSrc}
            />
          </div>
        ) : null}
        <Button type="primary" icon={<UploadOutlined />}>
          Upload Image
        </Button>
      </Upload>
      <Button
        danger
        icon={<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <DeleteOutlined />
        </div>}
        style={{ marginLeft: 10, borderRadius: "20%" }}
        onClick={() => {
          setHasClickedDelete(true);
          onDelete();
          if (onChange) onChange(null);
        }}
      />
    </>
  );
}

export default UploadImage;
