import { Table } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import { $ } from "../../graphql/generated";
import { useTypedQuery } from "../../graphql/hooks";

export default function SiteDomains({ id }: { id: string }) {
  const { data } = useTypedQuery(
    {
      MerchantSite: [
        {
          id: $`id`,
        },
        {
          id: true,
          name: true,
          status: true,
          domains: {
            id: true,
            createdAt: true,
            domain: true,
          },
        },
      ],
    },
    {
      variables: {
        id,
      },
    }
  );

  return (
    <>
      <Table
        title={() => (
          <Title level={5}>{data?.MerchantSite.domains.length} Domains</Title>
        )}
        dataSource={data?.MerchantSite.domains}
        columns={[
          {
            title: "Domain",
            render: (_, r) => (
              <a
                href={`https://${r.domain}/`}
                target="_blank"
                style={{
                  fontFamily: "monospace",
                }}
              >
                {r.domain}
              </a>
            ),
          },
          {
            title: "Created",
            dataIndex: "createdAt",
            render: (r) => new Date(r).toLocaleString(),
          },
        ]}
      />
    </>
  );
}
