import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, List, message, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import Modal from "antd/lib/modal/Modal";
import Text from "antd/lib/typography/Text";
import React, { useCallback, useEffect, useState } from "react";
import { Map, Marker, TileLayer } from "react-leaflet";
import { $ } from "../graphql/generated/graphql-zeus";
import { useTypedMutation } from "../graphql/hooks";
import {
  latLngToPostcode,
  postcodeIcon,
  postcodeToInfo,
  validatePostcode,
} from "../utils/postcode";

export default function UpdateOrderAddressButton({
  coordinates,
  customerId,
  orderId,
  initialValues,
}: {
  orderId: string;
  coordinates: [number, number];
  customerId: string;
  initialValues: {
    flat_number?: string;
    address_line_1?: string;
    postcode?: string;
    phone_number?: string;
  };
}) {
  const [showModal, setShowModal] = useState(false);

  const [marker, setMarker] = useState<[number, number] | null>(coordinates);

  const [customMarkerPostcodes, setCustomMarkerPostcodes] = useState<
    string[] | undefined
  >(undefined);

  useEffect(() => {
    if (!marker) {
      setCustomMarkerPostcodes(undefined);
    } else {
      latLngToPostcode(marker).then((val) => {
        console.log(
          "nearby postcodes",
          val.result.map((result) => result.postcode)
        );
        setCustomMarkerPostcodes(val.result.map((result) => result.postcode));
      });
    }
  }, [marker]);

  const [postcodeMarker, setPostcodeMarker] = useState<[number, number] | null>(
    null
  );

  const [addAddress] = useTypedMutation(
    {
      addAddress: [
        {
          address: $`address`,
          customerId: $`customerId`,
        },
        { id: true },
      ],
    },
    {
      onCompleted: () => {
        message.success("Set new address!");
        setShowModal(false);
      },
    }
  );

  const [setAddress, { loading }] = useTypedMutation({
    updateOrder: [
      {
        data: {
          addressId: $`addressId`,
        },
        id: $`id`,
      },
      {
        id: true,
        addressId: true,
        address: {
          id: true,
          address_line_1: true,
          country: true,
          flat_number: true,
          instructions: true,
          nickname: true,
          phone_number: true,
          postcode: true,
          town: true,
          coordinates: {
            type: true,
            coordinates: true,
          },
        },
      },
    ],
  });

  const finish = async (v) => {
    const result = await addAddress({
      variables: {
        address: {
          ...v,
          coordinates: {
            type: "Point",
            coordinates: marker,
          },
        },
        customerId,
      },
    });

    const addressId = result.data?.addAddress.id;

    setAddress({
      variables: {
        id: orderId,
        addressId,
      },
    });
  };

  const [form] = useForm();

  const onPostcodeUpdate = useCallback(
    async (postcode: string) => {
      const postcodeResult = (await postcodeToInfo(postcode))?.result;
      if (postcodeResult) {
        const latitude = postcodeResult.latitude;
        const longitude = postcodeResult.longitude;
        setPostcodeMarker([latitude, longitude]);
      }
    },
    [setPostcodeMarker]
  );

  useEffect(() => {
    if (initialValues?.postcode) {
      onPostcodeUpdate(initialValues.postcode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Button style={{ marginLeft: 8 }} onClick={() => setShowModal(true)}>
        Change Address
      </Button>
      <Modal
        visible={showModal}
        title="Change Order Address"
        onCancel={() => setShowModal(false)}
        footer={
          <Button onClick={() => form.submit()} icon={<CheckCircleOutlined />}>
            Change Address
          </Button>
        }
      >
        <Form
          form={form}
          onFinish={finish}
          onValuesChange={async (values) => {
            onPostcodeUpdate(values.postcode);
          }}
          initialValues={initialValues}
        >
          <Form.Item label="Flat Number" name="flat_number">
            <Input />
          </Form.Item>
          <Form.Item
            label="Line 1"
            rules={[{ required: true }]}
            name="address_line_1"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Postcode"
            rules={[
              { required: true },
              {
                validator: async (_, value) => {
                  if (await (await validatePostcode(value)).result) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Please enter a valid postcode");
                  }
                },
              },
            ]}
            name="postcode"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            rules={[{ required: true, pattern: /^\+[1-9]{1}[0-9]{3,14}$/g }]}
            name="phone_number"
          >
            <Input />
          </Form.Item>

          {orderId ? (
            <>
              <Map
                id="modal-map"
                center={coordinates || [56.333874, -2.8059686]}
                zoom={12}
                style={{
                  height: "300px",
                }}
                onClick={(e) => setMarker([e.latlng.lat, e.latlng.lng])}
              >
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://maps.mcgown.dev/tile/{z}/{x}/{y}.png"
                />
                {marker ? <Marker position={marker} /> : null}
                {postcodeMarker ? (
                  <Marker
                    icon={postcodeIcon}
                    tooltip={`Location of postcode ${form.getFieldValue(
                      "postcode"
                    )}`}
                    position={postcodeMarker}
                  />
                ) : null}
              </Map>
              <Text>Nearby Postcodes:</Text>
              <List
                dataSource={customMarkerPostcodes}
                renderItem={(postcode) => (
                  <Space>
                    <Button
                      onClick={() => {
                        form.setFieldsValue({
                          postcode,
                        });
                        onPostcodeUpdate(postcode);
                      }}
                    >
                      Update
                    </Button>
                    <List.Item>{postcode}</List.Item>
                  </Space>
                )}
              />
            </>
          ) : null}
        </Form>
      </Modal>
    </>
  );
}
