export function getCenterPolygon(arr: number[][]) {
  const x = arr.map((xy) => xy[0]);
  const y = arr.map((xy) => xy[1]);
  const cx = (Math.min(...x) + Math.max(...x)) / 2;
  const cy = (Math.min(...y) + Math.max(...y)) / 2;
  return [cx, cy];
}

export function formatDistanceDuration(dd?: {
  duration: { text: string; value: number };
  distance: { text: string; value: number };
}): string {
  return dd && dd.distance && dd.duration
    ? `${dd.distance.text || dd.distance.value.toFixed(1) + "m"} (${
        dd.duration.text || (dd.duration.value / 60).toFixed(1) + "m"
      })`
    : "No Distance";
}
