import React, { useContext } from "react";
import { $, Ordering } from "../../graphql/generated";
import { useTypedQuery } from "../../graphql/hooks";
import { RiderContext } from "../../views/rider/Rider";
import BookingSlotTable from "../BookingSlotTable";

export default function RiderBookings() {
  const context = useContext(RiderContext);

  const rider = context.data?.Rider;

  const { data } = useTypedQuery(
    {
      BookingMetricsRank: [
        {
          riderId: $`riderId2`,
        },
        true,
      ],
      BookingSlotsAvailableForRider: [
        {
          order: $`order`,
          sortBy: $`sortBy`,
          zoneId: $`zoneId`,
          riderId: $`riderId`,
        },
        {
          items: {
            id: true,
            endTime: true,
            startTime: true,
            guaranteedHourlyPay: true,
            maxRiders: true,
            slotType: true,
            percentage: true,
            hasBooked: true,
            zoneId: true,
            carOnly: true,
          },
        },
      ],
    },
    {
      skip: !rider,
      variables: {
        order: Ordering.ASC,
        sortBy: "startTime",
        zoneId: rider?.zone.id!,
        riderId: rider?.id,
        riderId2: rider?.id,
      },
    }
  );

  return (
    <div>
      <BookingSlotTable riderId={rider?.id} persistContextId="rider-bookings" />
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
}
