import { style } from "typestyle";

export const searchStyles = {
  Result: style({
    border: "1px solid #eee",
    cursor: "pointer",
    display: "flex",
    padding: "8px",

    $nest: {
      "&:hover": {
        background: "#eee",
      },
    },
  }),
};
