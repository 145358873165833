import { CheckCircleOutlined, ClearOutlined } from "@ant-design/icons";
import { Button, Form, Popconfirm } from "antd";
import { LatLngTuple } from "leaflet";
import React, { useCallback, useState } from "react";
import { Map, Polygon, TileLayer } from "react-leaflet";
import { $ } from "../../graphql/generated";
import { mutation, useTypedMutation } from "../../graphql/hooks";

const CREATE_POLYGON = mutation({
  createZonePolygon: [
    {
      polygon: $`polygon`,
    },
    {
      id: true,
      created: true,
      maxCycleTime: true,
      maxDriveTime: true,
      polygon: {
        type: true,
        coordinates: true,
      },
      updated: true,
    },
  ],
});

export default function CreatePolygonForm({
  zoneId,
  onCompleted,
}: {
  zoneId: string;
  onCompleted: () => void;
}) {
  const [positions, setPositions] = useState<[number, number][]>([]);
  const INITIAL_POSITION = [56.333874, -2.8059686];

  const addPosition = useCallback(
    (e) => {
      const newPos: [number, number] = [
        e.latlng.lat as number,
        e.latlng.lng as number,
      ];
      setPositions(positions.concat([newPos]));
    },
    [positions]
  );

  const [createPolygon, { loading }] = useTypedMutation(CREATE_POLYGON, {
    onCompleted,
    variables: {
      polygon: {
        polygon: {
          type: "Polygon",
          coordinates: [positions],
        },
        zoneId,
      },
    },
  });

  const onClick = useCallback(() => {
    createPolygon();
  }, []);

  return (
    <Form>
      <Popconfirm
        title="Are you sure you want to delete the entire zone polygon?"
        onConfirm={() => setPositions([])}
      >
        <Button danger style={{ marginBottom: 8 }} icon={<ClearOutlined />}>
          Reset Polygon
        </Button>
      </Popconfirm>
      <Map
        center={INITIAL_POSITION as LatLngTuple}
        zoom={10}
        onClick={addPosition}
        draw
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://maps.ecoeats.uk/tile/{z}/{x}/{y}.png"
        />
        <Polygon positions={positions} color="blue" />
      </Map>

      <div style={{ marginTop: 16 }}>
        <Button
          type="primary"
          onClick={onClick}
          loading={loading}
          icon={<CheckCircleOutlined />}
        >
          Add New Polygon
        </Button>
      </div>
    </Form>
  );
}
