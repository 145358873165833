import { SaveOutlined } from "@ant-design/icons";
import { Button, Card, Descriptions, Form, Input, message, Switch } from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import { useForm } from "antd/lib/form/Form";
import Text from "antd/lib/typography/Text";
import React, { useState } from "react";
import { $ } from "../../graphql/generated/graphql-zeus";
import { useTypedMutation, useTypedQuery } from "../../graphql/hooks";

export default function PaymentSettings({ id }) {
  const { data: paymentData, updateQuery } = useTypedQuery(
    {
      PartnerPaymentDetails: [
        {
          partnerId: $`partnerId`,
        },
        {
          id: true,
          accountNo: true,
          sortCode: true,
          individualName: true,
          isCompany: true,
        },
      ],
    },
    {
      skip: !id,
      variables: {
        partnerId: id,
      },
    }
  );

  const [updateDetails, { loading: updating }] = useTypedMutation(
    {
      updatePartnerPaymentDetails: [
        {
          details: $`details`,
          partnerId: $`partnerId`,
        },
        {
          id: true,
          accountNo: true,
          sortCode: true,
          isCompany: true,
          individualName: true,
        },
      ],
    },
    {
      onCompleted: (d) => {
        message.success("Updated your details!");
        setEdit(false);
        updateQuery((p) => ({
          PartnerPaymentDetails: {
            ...p,
            ...d.updatePartnerPaymentDetails,
          },
        }));
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const [edit, setEdit] = useState(false);

  const [form] = useForm();

  return (
    <Card title="Bank Account">
      {edit ? (
        <Form
          form={form}
          layout="vertical"
          onFinish={(v) =>
            updateDetails({
              variables: {
                details: {
                  ...v,
                },
                partnerId: id,
              },
            })
          }
        >
          <Form.Item
            initialValue={!!paymentData?.PartnerPaymentDetails.isCompany}
            name="isCompany"
            label="Is this a Business account?"
          >
            <Switch
              defaultChecked={paymentData?.PartnerPaymentDetails.isCompany}
            />
          </Form.Item>
          <Form.Item
            label="Individual Name"
            name="individualName"
            extra={[<Text>Required if not using a business account</Text>]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Account No"
            name="accountNo"
            rules={[
              {
                required: true,
                pattern: /^(\d){8}$/g,
                message: "Please enter a valid account number.",
              },
            ]}
          >
            <Input placeholder="40111295" />
          </Form.Item>
          <Form.Item
            label="Sort Code"
            name="sortCode"
            rules={[
              {
                required: true,
                pattern: /^(\d){6}$/g,
                message: "Please enter a valid sort code (without hyphens)",
              },
            ]}
          >
            <Input placeholder="826934" />
          </Form.Item>
          <Button
            htmlType="submit"
            loading={updating}
            type="primary"
            icon={<SaveOutlined />}
          >
            Update Bank Details
          </Button>
        </Form>
      ) : (
        <Descriptions
          title={
            <a href="#" onClick={() => setEdit(true)}>
              Edit
            </a>
          }
          bordered
        >
          {paymentData?.PartnerPaymentDetails.individualName ? (
            <DescriptionsItem label="Name">
              {paymentData.PartnerPaymentDetails.individualName}
            </DescriptionsItem>
          ) : null}
          <DescriptionsItem label="Account No">
            {paymentData?.PartnerPaymentDetails.accountNo}
          </DescriptionsItem>
          <DescriptionsItem label="Sort Code">
            {paymentData?.PartnerPaymentDetails.sortCode}
          </DescriptionsItem>
        </Descriptions>
      )}
    </Card>
  );
}
