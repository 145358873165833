import { ColumnProps } from "antd/lib/table";
import { camel2title } from ".";

export function convertToCSV(arr, camel = true) {
  const array = [
    Object.keys(arr[0]).map((k) => (camel ? camel2title(k) : k)),
  ].concat(arr);

  return array
    .map((it) => {
      return Object.values(it).toString();
    })
    .join("\n");
}

export function download(filename, text) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

// This function can be used with antd's Table component and will perform the
// necessary sorting and filtering logic on the records, given the value of columns.
// It is useful when you want to create a CSV with data that mirrors data in an antd
// table
export function filterAndSortTableData<R>(
  records: R[],
  columns: ColumnProps<R>[]
) {
  return records
    .filter((record) => {
      for (let col of columns) {
        const { filteredValue, onFilter } = col;
        if (onFilter && filteredValue && filteredValue.length > 0) {
          if (filteredValue.filter((val) => onFilter(val, record)).length === 0)
            return false;
        }
      }
      return true;
    })
    .sort((a, b) => {
      const sort = (col: ColumnProps<R>) => {
        const { sorter, sortOrder } = col;
        if (sorter && sorter instanceof Function && !isBool(sortOrder)) {
          return sorter(a, b, sortOrder);
        }
        return 0;
      };

      const sortColumn = columns.find((c) => !!c.sortOrder);
      if (sortColumn) return sort(sortColumn);

      const defaultSortColumn = columns.find((c) => !!c.defaultSortOrder);
      if (defaultSortColumn) return sort(defaultSortColumn);

      return 0;
    })
    .map((record, idx) => {
      const finalRecord: any = {};

      for (const col of columns) {
        const propName = col.dataIndex || col.key;
        if (propName) {
          const text = (record as any)[propName as any];

          if (col.render) {
            finalRecord[propName as any] = col.render(text, record, idx);
          } else {
            finalRecord[propName as any] = text;
          }
        }
      }

      return finalRecord;
    });
}

function isBool(x: any): x is boolean {
  return typeof x === "boolean";
}
