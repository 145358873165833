import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  PageHeader,
  Radio,
  Tooltip,
  Switch,
  Modal,
  ModalFuncProps,
} from "antd";
import React, {
  ReactText,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Text from "antd/lib/typography/Text";
import { useTypedMutation } from "../../graphql/hooks";
import { $ } from "../../graphql/generated";
import { QuestionCircleOutlined } from "@ant-design/icons";
import ModifierOptionsTable from "./ModifierOptionsTable";
import { MenuContextType } from "../RestaurantMenu";

export type OptionsStrategy = "ONCE_ONLY" | "LIMITED_MULTIPLE" | "UNLIMITED";

const QuestionMarkToolTip = (props: { title: string }) => {
  const { title } = props;
  return (
    <Tooltip title={title}>
      <QuestionCircleOutlined />
    </Tooltip>
  );
};

interface ModifierOption {
  id: string;
  name: string;
  description?: string;
  unitPrice: number;
  published: boolean;
  available: boolean;
  sortOrder: number;
}

type FormItems = {
  name: string;
  instruction: string;
  priceStrategy: "NO_EXTRA_PRICE" | "ITEM_PRICE_SUM";
  options: ModifierOption[];
  optionsStrategy?: "UNLIMITED" | "ONCE_ONLY" | "LIMITED_MULTIPLE";
  maxPerOption?: React.ReactText;
  minOptions: React.ReactText;
  maxOptions: React.ReactText;
};

export type InitialAddModifierGroup = Partial<{
  id: string;
  options: ModifierOption[];
  sortOrder: number;
  maxPerOption?: number;
  minOptions: number;
  maxOptions: number;
  name: string;
  instruction: string;
  priceStrategy: "NO_EXTRA_PRICE" | "ITEM_PRICE_SUM";
  cascades: boolean;
}>;

interface Props {
  partnerId: string;
  // if not provided will create a new modifier group
  initialModifierGroup?: InitialAddModifierGroup;
  menu: {
    id: string;
    categories: {
      id: string;
      name: string;
      items: ModifierOption[];
    }[];
    modifierGroups: {
      id: string;
      sortOrder: number;
    }[];
  };
  onCompleted: (
    newMg: MenuContextType["menu"]["modifierGroups"][0] | null
  ) => void;
  onCreateNewOption: (mgInProgress: InitialAddModifierGroup) => void;
}

const parseReactTextToNumber = (value: ReactText) => {
  if (typeof value === "string") {
    return parseInt(value);
  } else {
    return value;
  }
};

const AddModifierGroup = (props: Props) => {
  const {
    initialModifierGroup,
    menu,
    onCompleted,
    onCreateNewOption,
    partnerId,
  } = props;

  const allModifierGroups = menu.modifierGroups;

  const [options, setOptions] = useState<ModifierOption[]>(
    initialModifierGroup?.options != null ? initialModifierGroup.options : []
  );

  const menuItems = useMemo(() => {
    const categoryItems = menu.categories.map((category) => {
      return category.items.map((item) => {
        return {
          ...item,
          categoryId: category.id,
          categoryName: category.name,
        };
      });
    });
    // remove duplicates
    return ([] as (ModifierOption & {
      categoryName: string;
      categoryId: string;
    })[]).concat(...categoryItems);
  }, [menu.categories]);

  // options sorted according to sortOrder.
  const sortedOptions = useMemo(() => {
    return options?.slice()?.sort((a, b) => a.sortOrder - b.sortOrder);
  }, [options]);

  const [form] = Form.useForm<FormItems>();

  // used to determine the id of the modifier group which was created
  const [
    modifierGroupIdsBeforeCreation,
    setModifierGroupIdsBeforeCreation,
  ] = useState<string[]>(allModifierGroups.map((mg) => mg.id));

  const [addModifierGroupMutation, { loading }] = useTypedMutation(
    {
      addModifierGroup: [
        {
          id: $`id`,
          modifierGroup: $`modifierGroup`,
        },
        {
          id: true,
          modifierGroups: {
            id: true,
            name: true,
            instruction: true,
            maxOptions: true,
            maxPerOption: true,
            minOptions: true,
            cascades: true,
            sortOrder: true,
            priceStrategy: true,

            options: {
              id: true,
              name: true,
              available: true,
              published: true,
              description: true,
              unitPrice: true,
              categoryId: true,
              headerImage: true,
              sortOrder: true,
            },
          },
        },
      ],
    },
    {
      onCompleted: (data) => {
        message.success("Saved Changes!");
        const newMg = data.addModifierGroup.modifierGroups.find(
          (mg) => !modifierGroupIdsBeforeCreation.includes(mg.id)
        );
        onCompleted(newMg ? newMg : null);
      },
      onError: () => {
        message.error(
          `Couldn't ${
            initialModifierGroup ? "update" : "create"
          } modifier group.`
        );
      },
    }
  );

  const highestSortOrder = useMemo(() => {
    if (allModifierGroups.length > 0) {
      return Math.max(...allModifierGroups.map((mg) => mg.sortOrder));
    } else {
      return undefined;
    }
  }, [allModifierGroups]);

  const onSubmit = useCallback(
    (updatedModifierGroup) => {
      const input = {
        variables: {
          id: menu.id,
          modifierGroup: updatedModifierGroup,
        },
      };
      setModifierGroupIdsBeforeCreation(allModifierGroups.map((mg) => mg.id));
      addModifierGroupMutation(input);
    },
    [addModifierGroupMutation, allModifierGroups, menu.id]
  );

  const getDefaultOptionsStrategy = useCallback((maxPerOption?: number) => {
    return maxPerOption
      ? // once only is just a shortcut for LIMITED_MULTIPLE with the multiple set to 1
        maxPerOption === 1
        ? "ONCE_ONLY"
        : "LIMITED_MULTIPLE"
      : // if maxPerOption is undefined that means that there is no limit
        "UNLIMITED";
  }, []);

  const [optionsStrategy, setOptionsStrategy] = useState<OptionsStrategy>(
    initialModifierGroup?.maxPerOption != null
      ? getDefaultOptionsStrategy(initialModifierGroup.maxPerOption)
      : // otherwise default to 'UNLIMITED'
        "UNLIMITED"
  );

  const [limitedMultiple, setLimitedMultiple] = useState(
    // if maxPerOption defined (possibly by null) in context use that value
    initialModifierGroup?.maxPerOption ? initialModifierGroup?.maxPerOption : 1
  );

  const [minOptions, setMinOptions] = useState<number | undefined>(
    initialModifierGroup?.minOptions != null
      ? initialModifierGroup.minOptions
      : 0
  );

  const maxPossibleOptions = useMemo(() => {
    if (optionsStrategy === "UNLIMITED") {
      // there is no limit
      return null;
    } else if (optionsStrategy === "ONCE_ONLY") {
      // can only choose each at most once
      return options.length;
    } else if (optionsStrategy === "LIMITED_MULTIPLE") {
      // at most multiple limit * no of options
      if (limitedMultiple) {
        return options.length * limitedMultiple;
      } else {
        return null;
      }
    }
  }, [optionsStrategy, limitedMultiple, options.length]);

  // indicates whether the max options still take their default value
  // or whether they have been changed.
  // allows to change the default maxOptions as the limit changes, unless the user has updated the value.
  const [defaultMaxOptions, setDefaultMaxOptions] = useState(
    // if updating a modifier group set to true only if maxOptions is not defined
    // otherwise default to true
    !initialModifierGroup?.maxOptions
  );

  // sync maxOptions with maxPossibleOptions until the user changes the value (defaultMaxOptions is false).
  useEffect(() => {
    if (defaultMaxOptions && maxPossibleOptions !== null) {
      // if the current value of maxOptions has not yet been set then update the default
      form.setFieldsValue({
        maxOptions: maxPossibleOptions,
      });
    }
  }, [defaultMaxOptions, form, maxPossibleOptions]);

  const formDataToMg = useCallback(
    (data) => {
      var maxPerOption;

      if (data.optionsStrategy === "UNLIMITED") {
        maxPerOption = null;
      } else if (data.optionsStrategy === "LIMITED_MULTIPLE") {
        maxPerOption = data.maxPerOption;
      } else if (data.optionsStrategy === "ONCE_ONLY") {
        maxPerOption = 1;
      }

      var modifierGroup = {
        name: data.name,
        instruction: data.instruction,
        options: sortedOptions,
        // if there is no maxOptions then count must be 0
        maxOptions: data.maxOptions ? data.maxOptions : 0,
        maxPerOption,
        minOptions: minOptions,
        priceStrategy: data.priceStrategy,
        cascades: data.cascades,
      };

      return modifierGroup;
    },
    [minOptions, sortedOptions]
  );

  const onFinish = useCallback(
    (data) => {
      if (options.length > 0) {
        const mgData = formDataToMg(data);

        var updatedModifierGroup = {
          ...mgData,
          optionIds: mgData.options.map((option) => option.id),
          options: undefined,
        };
        if (initialModifierGroup?.sortOrder != null) {
          updatedModifierGroup["id"] = initialModifierGroup?.id;
          updatedModifierGroup["sortOrder"] = initialModifierGroup?.sortOrder;
        } else {
          updatedModifierGroup["sortOrder"] =
            highestSortOrder != null ? highestSortOrder + 1 : 0;
        }
        onSubmit(updatedModifierGroup);
      } else {
        message.error(
          "Please select at least one option for the modifier group."
        );
      }
    },
    [
      options.length,
      formDataToMg,
      initialModifierGroup?.sortOrder,
      initialModifierGroup?.id,
      onSubmit,
      highestSortOrder,
    ]
  );

  const confirmBackConfig: ModalFuncProps = {
    title: "Unsaved changes!",
    content:
      "Are you sure you want to leave the page? You will lose all progress in creating the modifier group!",
    okText: "Leave Page",
    okType: "danger",
    onOk: () => onCompleted(null),
  };

  return (
    <>
      <PageHeader
        title={
          <Text>
            {initialModifierGroup
              ? `Update '${initialModifierGroup.name}'`
              : "Create new Modifier Group"}
          </Text>
        }
        onBack={() => {
          Modal.confirm(confirmBackConfig);
        }}
      ></PageHeader>
      <Form
        style={{ marginTop: 20 }}
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={onFinish}
        onValuesChange={(changedValues: Partial<FormItems>) => {
          // on change max per option update limitedMultiple
          if (changedValues.maxPerOption != null) {
            setLimitedMultiple(
              parseReactTextToNumber(changedValues.maxPerOption)
            );
          }
          // when the options are updated update options
          if (changedValues.options != null) {
            setOptions(changedValues.options);
          }
          // sync minOptions variable with the form
          if (changedValues.minOptions != null) {
            setMinOptions(parseReactTextToNumber(changedValues.minOptions));
          }

          // sync options strategy with the form
          if (changedValues.optionsStrategy != null) {
            setOptionsStrategy(changedValues.optionsStrategy);
          }

          if (changedValues.maxOptions != null) {
            setDefaultMaxOptions(false);
          }
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true }]}
          initialValue={initialModifierGroup?.name}
        >
          <Input
            className="new-modifier-group-name-input"
            placeholder="the name of the modifier group"
            style={{ maxWidth: 400 }}
          />
        </Form.Item>
        <Form.Item
          name="instruction"
          label="Instruction"
          initialValue={initialModifierGroup?.instruction}
        >
          <Input
            className="new-modifier-group-instruction-input"
            placeholder="instructions including allergen information"
          />
        </Form.Item>
        <Form.Item
          name="priceStrategy"
          label="Price Strategy"
          rules={[{ required: true, message: "Please pick a strategy!" }]}
          initialValue={initialModifierGroup?.priceStrategy}
        >
          <Radio.Group className="add-modifier-group-price-strategy-radio-group">
            <Tooltip title="The customer is not charged for the options, regardless of their unit price.">
              <Radio.Button value="NO_EXTRA_PRICE">No extra price</Radio.Button>
            </Tooltip>
            <Tooltip title="The customer is charged the standard unit price for each option.">
              <Radio.Button value="ITEM_PRICE_SUM">
                Add to item price
              </Radio.Button>
            </Tooltip>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="options"
          label="Options"
          initialValue={sortedOptions}
          rules={[
            {
              validator: async (_, options) => {
                if (!options || options.length === 0) {
                  return Promise.reject("Please select at least one option.");
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <ModifierOptionsTable
            partnerId={partnerId}
            menuItems={menuItems}
            categories={menu.categories}
            createModifier={() => {
              onCreateNewOption(formDataToMg(form.getFieldsValue()));
            }}
          />
        </Form.Item>

        {options.length > 0 ? (
          <>
            <Form.Item
              name="optionsStrategy"
              label="Options Strategy"
              initialValue={
                initialModifierGroup
                  ? getDefaultOptionsStrategy(initialModifierGroup.maxPerOption)
                  : // otherwise default to 'UNLIMITED'
                    "UNLIMITED"
              }
            >
              <Radio.Group className="add-modifier-group-options-strategy-radio-group">
                <Tooltip title="The customer can choose any option an unlimited number of times.">
                  <Radio.Button value="UNLIMITED">Unlimited </Radio.Button>
                </Tooltip>
                <Tooltip title="The customer can choose each option at most once.">
                  <Radio.Button value="ONCE_ONLY">Once Only</Radio.Button>
                </Tooltip>
                <Tooltip title="The customer can choose each option up to a set limit number of times.">
                  <Radio.Button value="LIMITED_MULTIPLE">
                    Custom Limit
                  </Radio.Button>
                </Tooltip>
              </Radio.Group>
            </Form.Item>
            {optionsStrategy === "LIMITED_MULTIPLE" ? (
              <Form.Item
                name="maxPerOption"
                initialValue={limitedMultiple}
                label="Maximum per Option"
                rules={[
                  {
                    required: true,
                    message:
                      "Please give the limit of the number of each option.",
                  },
                  {
                    validator: async (_, value) => {
                      if (value < 1) {
                        return Promise.reject(
                          `Max Per Optiozzzn must be at least one.`
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <InputNumber
                  className="create-modifier-group-max-per-option-inputnumber"
                  value={limitedMultiple}
                  min={1}
                />
              </Form.Item>
            ) : null}
            <Form.Item label="Minimum Options">
              <Form.Item
                noStyle
                initialValue={
                  initialModifierGroup ? initialModifierGroup.minOptions : 0
                }
                rules={[
                  {
                    required: true,
                    message: "Please specify the minimum number of options.",
                  },
                  {
                    validator: async (_, value) => {
                      if (value < 0) {
                        return Promise.reject(`Minimum Options must positive.`);
                      } else if (
                        maxPossibleOptions &&
                        value > maxPossibleOptions
                      ) {
                        return Promise.reject(
                          `Minimum Options must be no more than ${maxPossibleOptions}.`
                        );
                      }
                    },
                  },
                ]}
                name="minOptions"
              >
                {maxPossibleOptions ? (
                  <InputNumber
                    className="create-modifier-group-min-options"
                    min={0}
                    max={maxPossibleOptions}
                  />
                ) : (
                  <InputNumber
                    className="create-modifier-group-min-options"
                    min={0}
                  />
                )}
              </Form.Item>
              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  marginLeft: 10,
                }}
              >
                <QuestionMarkToolTip title="The minimum number of options a user must choose. e.g. must choose at least one pizza topping option." />
              </div>
            </Form.Item>
            <Form.Item label="Maximum Options">
              <Form.Item
                noStyle
                name="maxOptions"
                initialValue={
                  initialModifierGroup
                    ? initialModifierGroup.maxOptions
                    : maxPossibleOptions
                }
                rules={[
                  {
                    required: true,
                    message: "Please specify the maximum number of options.",
                  },
                  {
                    validator: async (_, value) => {
                      const min =
                        minOptions && minOptions >= 1 ? minOptions : 1;
                      if (value < min) {
                        return Promise.reject(
                          `Maximum Options must be at least ${min}.`
                        );
                      } else if (
                        maxPossibleOptions &&
                        value > maxPossibleOptions
                      ) {
                        return Promise.reject(
                          `Maximum Options must be no more than ${maxPossibleOptions}.`
                        );
                      }
                    },
                  },
                ]}
              >
                {maxPossibleOptions ? (
                  <InputNumber
                    className="create-modifier-group-max-options"
                    min={minOptions && minOptions >= 1 ? minOptions : 1}
                    max={maxPossibleOptions}
                  />
                ) : (
                  <InputNumber
                    className="create-modifier-group-max-options"
                    min={minOptions && minOptions >= 1 ? minOptions : 1}
                  />
                )}
              </Form.Item>

              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  marginLeft: 10,
                }}
              >
                <QuestionMarkToolTip title="The maximum number of options a user can choose including duplicates of the same options. e.g. can choose at most two drinks, including the same drink twice (if allowed by options strategy)." />
              </div>
            </Form.Item>
          </>
        ) : null}
        <Form.Item
          label="Cascades?"
          name="cascades"
          valuePropName="checked"
          initialValue={
            initialModifierGroup?.cascades != null
              ? initialModifierGroup.cascades
              : false
          }
        >
          <Switch />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 4 }}>
          <Button
            className="create-modifier-group-btn"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddModifierGroup;
