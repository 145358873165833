import React, { useState } from "react";
import { style } from "typestyle";
import { getImageUrl } from "../../../utils/image-url";
import { searchStyles } from "./styles";

const ItemResult = ({
  name,
  restaurantName,
  ratingStars,
  numRatings,
  image,
  onClick,
  index,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className={searchStyles.Result} onClick={onClick} tabIndex={index}>
      <img
        className={styles.SquareImage}
        src={image ? getImageUrl(image, 256, 256) : "/background.png"}
        alt={name}
        onLoad={() => setImageLoaded(true)}
        style={{
          opacity: imageLoaded ? 1 : 0,
          transition: "opacity ease-in-out 0.3s",
        }}
      />

      <div>
        <b>{name}</b>
        <div>
          <span style={{ margin: "0 6px", color: "#bbb" }}>•</span>
          <p style={{ display: "inline", marginLeft: "10px", color: "#bbb" }}>
            {restaurantName}
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  SquareImage: style({
    height: "70px",
    width: "70px",
    margin: 0,
    padding: 0,
    marginRight: "10px",
    borderRadius: 4,
  }),
};
export default ItemResult;
