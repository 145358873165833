import { HeatMapOutlined } from "@ant-design/icons";
import { Badge, Button, Descriptions, PageHeader, Table, Tooltip } from "antd";
import Title from "antd/lib/typography/Title";
import L from "leaflet";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { Marker, Popup } from "react-leaflet";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { style } from "typestyle";
import { $, ReusableShipmentStatus } from "../../graphql/generated";
import {
  mutation,
  query,
  useTypedMutation,
  useTypedQuery,
} from "../../graphql/hooks";
import { ADDRESS_FRAGMENT, RESTAURANT } from "../../graphql/queries";
import Map from "../../utils/Map";
import { formatAddress } from "../forms/AddAddressForm";
import { OrdersTable } from "../OrdersTable";
import { SHIPMENT_COLUMNS } from "./ReusableShipmentTable";
import ScannedContainerTable from "./ScannedContainerTable";

const PICKUP_AGG = query({
  WashingLocation: [
    {
      id: $`id`,
    },
    {
      id: true,
      name: true,
      notes: true,
      status: true,
      partner: {
        ...RESTAURANT["Restaurant"]["1"],
      },
      address: {
        ...ADDRESS_FRAGMENT,
      },
      availableShipments: {
        id: true,
        createdAt: true,
        status: true,
        scheduledFor: true,
        activeOrder: {
          id: true,
        },
        canScheduleOrder: true,
        containers: {
          containersScannedSoFar: true,
          containerType: {
            id: true,
            image: {
              id: true,
              key: true,
            },
            name: true,
          },
          quantity: true,
        },
        partner: {
          id: true,
          name: true,
          headerImageKey: true,
          address: {
            id: true,
            flat_number: true,
            instructions: true,
            coordinates: {
              type: true,
              coordinates: true,
            },
            address_line_1: true,
            postcode: true,
          },
        },
      },
    },
  ],
});

const styles = {
  icon: style({
    display: "flex",
    borderRadius: 30,
    backgroundColor: "white",
    height: 32,
    width: 32,
    justifyContent: "center",
    alignItems: "center",
  }),
};
const LWashingIcon = L.divIcon?.({
  className: "custom-icon",
  iconAnchor: [12, 12],
  html: ReactDOMServer.renderToString(
    <div
      className={styles.icon}
      style={{
        border: `4px solid green`,
      }}
    >
      <HeatMapOutlined />
    </div>
  ),
});

const ASSIGN_SHIPMENT = mutation({
  AssignReusableShipment: [
    {
      id: $`id`,
      washingLocationId: $`washingLocationId`,
    },
    {
      ...PICKUP_AGG["WashingLocation"]["1"]["availableShipments"],
    },
  ],
});

export default function WashingLocationView() {
  const { id } = useParams();

  const { data } = useTypedQuery(PICKUP_AGG, {
    pollInterval: 10000,
    variables: {
      id,
    },
  });

  const [claimShipment, { loading: claiming }] = useTypedMutation(
    ASSIGN_SHIPMENT
  );

  const location = data?.WashingLocation;

  const locationCoords = {
    lat: location?.address.coordinates?.coordinates[0],
    lng: location?.address.coordinates?.coordinates[1],
  };
  return (
    <PageHeader
      title={
        <Title
          level={5}
          style={{
            fontFamily: "monospace",
            marginBottom: 0,
          }}
        >
          {location?.name}
        </Title>
      }
      subTitle="Washing Location"
    >
      {location ? (
        <Map
          style={{
            height: 300,
          }}
          zoom={10}
          center={locationCoords}
        >
          <Marker position={locationCoords} icon={LWashingIcon}>
            <Popup>Washing Location</Popup>
          </Marker>
        </Map>
      ) : (
        <div style={{ width: "100%", height: 300 }} />
      )}

      {location && (
        <Descriptions bordered style={{ marginTop: 16, marginBottom: 16 }}>
          <Descriptions.Item label="Status">
            <Badge
              status={location.status === "READY" ? "success" : "warning"}
              text={location.status}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Partner Link">
            <Tooltip title="Washing Locations use a hidden restaurant internally to manage orders">
              <Link to={`/restaurant/${location.partner.id}`}>
                {location.partner.name}
              </Link>
            </Tooltip>
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {formatAddress(location.address)}
          </Descriptions.Item>
        </Descriptions>
      )}

      {data && (
        <OrdersTable restaurant={data?.WashingLocation.partner as any} />
      )}

      <div>
        <Title level={5}>Available Shipments</Title>
        <Table
          dataSource={data?.WashingLocation.availableShipments}
          columns={[
            ...SHIPMENT_COLUMNS,
            {
              title: "Actions",
              render: (_, r) => (
                <>
                  {r.status === ReusableShipmentStatus.PENDING && (
                    <Button
                      onClick={() =>
                        claimShipment({
                          variables: {
                            id: r.id,
                            washingLocationId: id,
                          },
                        })
                      }
                    >
                      Assign
                    </Button>
                  )}
                </>
              ),
            },
          ]}
        />
      </div>

      <ScannedContainerTable washingLocationId={id} />

      <pre>{JSON.stringify(data, null, 2)}</pre>
    </PageHeader>
  );
}
