import {
  Alert,
  Badge,
  Button,
  Card,
  DatePicker,
  Modal,
  Space,
  Switch,
  Table,
} from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { $, Ordering } from "../../graphql/generated";
import {
  useTypedLazyQuery,
  useTypedMutation,
  useTypedQuery,
} from "../../graphql/hooks";
import { price } from "../../utils/price";
import PayPartnerInvoiceButton from "./PayPartnerInvoiceButton";

export default function RestaurantInvoiceTable({
  restaurantId,
}: {
  restaurantId?: string;
}) {
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf("week"));
  const [endDate, setEndDate] = useState(moment().endOf("week"));

  const [page, setSkip] = useState(1);
  const [take, setTake] = useState(5);
  const [paid, setPaid] = useState(false);
  const [sortBy, setSortBy] = useState("start");
  const [order, setOrder] = useState(Ordering.ASC);

  const { data, refetch, loading: loadingData } = useTypedQuery(
    {
      allPartnerInvoices: [
        {
          order: $`order`,
          sortBy: $`sortBy`,
          partnerId: $`partnerId`,
          paid: $`paid`,
          skip: $`skip`,
          take: $`take`,
        },
        {
          hasMore: true,
          total: true,
          items: {
            id: true,
            adjustments: true,
            commission: true,
            end: true,
            start: true,
            sales: true,
            numberOfOrders: true,
            paid: true,
            ref: true,
            revolutTransactionId: true,
            revolutTransactionLink: true,
            value: true,
            partner: {
              id: true,
              name: true,
            },
          },
        },
      ],
    },
    {
      variables: {
        order,
        sortBy,
        partnerId: restaurantId,
        skip: (page - 1) * take,
        take,
        paid,
      },
    }
  );

  const [createInvoice, { loading }] = useTypedMutation(
    {
      PartnerInvoiceForPeriod: [
        {
          end: $`end`,
          start: $`start`,
          partnerId: $`partnerId`,
        },
        {
          id: true,
        },
      ],
    },
    {
      variables: {
        end: endDate.toDate(),
        start: startDate.toDate(),
        partnerId: restaurantId,
      },
      onCompleted: () => {
        refetch();
        setShowModal(false);
      },
    }
  );

  const [downloadInvoice, { loading: downloading }] = useTypedLazyQuery(
    {
      downloadPartnerInvoice: [
        {
          id: $`id`,
        },
        true,
      ],
    },
    {
      fetchPolicy: "no-cache",
      onCompleted: (d) => {
        window.open(d.downloadPartnerInvoice, "_blank");
      },
    }
  );

  return (
    <>
      <Card
        bodyStyle={{
          padding: 0,
          overflowX: "auto",
        }}
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Title level={4} style={{ flex: 1 }}>
              {data?.allPartnerInvoices.total} Partner Invoices
            </Title>

            <Text>Paid?&nbsp;</Text>
            <Switch checked={paid} onChange={(v) => setPaid(v)} />

            {restaurantId && (
              <Button loading={loading} onClick={setShowModal.bind(null, true)}>
                Generate Invoice
              </Button>
            )}
          </div>
        }
      >
        <Table
          dataSource={data?.allPartnerInvoices.items || []}
          loading={loadingData}
          onChange={(p, f, s) => {
            if (s instanceof Array) {
            } else if (s.field) {
              setSortBy(s.field as string);
              setOrder(s.order === "ascend" ? Ordering.ASC : Ordering.DESC);
            }
          }}
          pagination={{
            total: data?.allPartnerInvoices.total,
            current: page,
            pageSize: take,
            pageSizeOptions: ["5", "10", "25"],
            onChange: (p, ps) => {
              if (ps) setTake(ps!);
              setSkip(p);
            },
          }}
          columns={[
            {
              title: "Partner",
              dataIndex: "partner",
              render: (r) => (
                <Link to={`/restaurant/${r.id}`}>
                  <a>{r.name}</a>
                </Link>
              ),
            },
            {
              title: "Ref",
              dataIndex: "ref",
            },
            {
              title: "Start",
              key: "start",
              dataIndex: "start",
              render: (r) => new Date(r).toLocaleDateString(),
              sorter: true,
            },
            {
              title: "End",
              key: "end",
              dataIndex: "end",
              render: (r) => new Date(r).toLocaleDateString(),
            },
            {
              title: "Gross Sales",
              key: "sales",
              dataIndex: "sales",
              render: (r) => price(r),
            },
            {
              title: "Commission",
              key: "commission",
              dataIndex: "commission",
              render: (r) => price(r),
            },
            {
              title: "Adjustments",
              key: "adjustments",
              dataIndex: "adjustments",
              render: (r) => price(r),
            },
            {
              title: "Net Sales",
              key: "value",
              dataIndex: "value",
              render: (r) => price(r),
            },
            {
              title: "Paid",
              key: "paid",
              render: (r) => (
                <Space>
                  {r.paid ? (
                    <Badge
                      status={r.revolutTransactionLink ? "success" : "warning"}
                      text={
                        r.revolutTransactionLink ? (
                          <a href={r.revolutTransactionLink}>
                            View Transaction
                          </a>
                        ) : (
                          "Paid Manually"
                        )
                      }
                    />
                  ) : (
                    <PayPartnerInvoiceButton id={r.id} />
                  )}
                  <Button
                    type="link"
                    loading={downloading}
                    onClick={downloadInvoice.bind(null, {
                      variables: {
                        id: r.id,
                      },
                    })}
                  >
                    View PDF
                  </Button>
                </Space>
              ),
            },
          ]}
        ></Table>
      </Card>
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={() => createInvoice()}
        confirmLoading={loading}
        okButtonProps={{
          title: "Generate",
        }}
        title="Generate Partner Invoice"
      >
        <Alert
          message="This will forcibly generate an invoice for the given dates"
          type="warning"
        ></Alert>

        <DatePicker.RangePicker
          value={[moment(startDate), moment(endDate)]}
          onChange={([sd, ed]: any) => {
            setEndDate(ed);
            setStartDate(sd);
          }}
        />
      </Modal>
    </>
  );
}
