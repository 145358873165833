import { Badge, Space, Tag, Tooltip } from "antd";
import React from "react";
import { ZoneBusyStatus, ZoneOnboardingStatus } from "../../graphql/generated";

interface Props {
  zone: {
    onboardingStatus: ZoneOnboardingStatus;
    busyWeight: number;
    busyStatus: ZoneBusyStatus;
    enableAutoScaler: boolean;
    liveView: {
      activeRiders: number;
      unassignedRiders: number;
      ridersFreeSoon?: number;
      riderAcceptRate?: number;
    };
  };
  showAutoScalerStatus?: boolean;
  showLiveView?: boolean;
}

const ZoneStatus = (props: Props) => {
  const { zone, showLiveView = true, showAutoScalerStatus = true } = props;
  return (
    <Space>
      <Badge
        status={
          zone.onboardingStatus === ZoneOnboardingStatus.TEMPORARILY_DISABLED
            ? "error"
            : zone.onboardingStatus === ZoneOnboardingStatus.ONBOARDING
            ? "warning"
            : zone.onboardingStatus === ZoneOnboardingStatus.LIVE
            ? "success"
            : "default"
        }
        style={{
          marginRight: 8,
        }}
        text={zone.onboardingStatus}
      ></Badge>
      <Tooltip title={zone.busyWeight.toFixed(2)}>
        <Badge
          status={
            zone.busyStatus === ZoneBusyStatus.BUSY
              ? "error"
              : zone.busyStatus === ZoneBusyStatus.MODERATE
              ? "warning"
              : "default"
          }
          style={{
            marginRight: 8,
          }}
          text={zone.busyStatus}
        />
      </Tooltip>
      {showAutoScalerStatus ? (
        <Badge
          status={zone.enableAutoScaler ? "success" : "error"}
          text={`Autoscaler ${zone.enableAutoScaler ? "Enabled" : "Disabled"}`}
        ></Badge>
      ) : null}

      {showLiveView ? (
        <>
          <Tag color="blue">{zone.liveView.activeRiders} Riders</Tag>
          <Tag color="red">
            {zone.liveView.unassignedRiders} Riders Unassigned
          </Tag>
          {zone.liveView.ridersFreeSoon != null ? (
            <Tag color="volcano">
              {zone.liveView.ridersFreeSoon} Riders Free Soon
            </Tag>
          ) : null}
          {zone.liveView.riderAcceptRate != null ? (
            <Tag>
              {(zone.liveView.riderAcceptRate * 100).toFixed(0)}% Rider Accept
              Rate
            </Tag>
          ) : null}
        </>
      ) : null}
    </Space>
  );
};

export default ZoneStatus;
