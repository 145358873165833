import { SearchOutlined } from "@ant-design/icons";
import { Input, Space, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { $ } from "../../graphql/generated";
import { query, useTypedQuery } from "../../graphql/hooks";
import usePagination from "../../hooks/usePagination";
import { price } from "../../utils/price";
import DynamicForm from "../forms/DynamicForm";

const SITE_TABLE = query({
  CustomerWalletTopupList: [
    {
      skip: $`skip`,
      take: $`take`,
      sortBy: $`sortBy`,
      order: $`order`,
      query: $`query`,
      customerId: $`customerId`,
    },
    {
      total: true,
      items: {
        id: true,
        note: true,
        createdAt: true,
        amount: true,
        paymentIntentId: true,
        customer: {
          id: true,
          email: true,
        },
      },
    },
  ],
});

export default function CustomerWalletTopupTable({
  customerId,
}: {
  customerId?: string;
}) {
  const {
    pagination,
    args: { order, page, query, sortBy, take },
    onChange,
    setQuery,
  } = usePagination({
    defaultOrder: "DESC",
    defaultSortBy: "createdAt",
  });

  const { data, refetch } = useTypedQuery(SITE_TABLE, {
    fetchPolicy: "network-only",
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
      customerId,
    },
    pollInterval: 10000,
  });

  const [show, setShow] = useState(false);

  return (
    <>
      <Table
        title={() => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Title level={4} style={{ flex: 1 }}>
              {data?.CustomerWalletTopupList.total} Topups
            </Title>

            <Space>
              <Input
                placeholder="Search..."
                suffix={<SearchOutlined />}
                onChange={(t) => setQuery(t.target.value)}
              />
            </Space>
          </div>
        )}
        onChange={(p, f, s) => {
          onChange(p, f, s);
        }}
        dataSource={data?.CustomerWalletTopupList.items}
        pagination={{
          total: data?.CustomerWalletTopupList.total,
          ...pagination,
        }}
        rowKey={"id"}
        columns={[
          {
            title: "Customer",
            sorter: true,
            dataIndex: "customer",
            render: (_, r) => (
              <Link to={`/customer/${r.customer.id}`}>{r.customer.email}</Link>
            ),
          },
          {
            title: "Created",
            key: "created",
            sorter: true,
            render: (_, r) => new Date(r.createdAt).toLocaleString(),
          },
          {
            title: "Amount",
            key: "amount",
            render: (_, r) => price(r.amount),
          },
          {
            title: "Note",
            dataIndex: "note",
          },
          {
            render: (_, r) => (
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={`https://dashboard.stripe.com/payments/${r.paymentIntentId}`}
                style={{ fontFamily: "monospace" }}
              >
                {r.paymentIntentId}
              </a>
            ),
          },
        ]}
      />
      <Modal
        footer={null}
        visible={show}
        destroyOnClose
        onCancel={() => setShow(false)}
      >
        <DynamicForm
          onComplete={() => {
            refetch();
            setShow(false);
          }}
          noCard
          id="ReusableShipmentCreate"
        />
      </Modal>
    </>
  );
}
