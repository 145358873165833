import {
  CheckCircleOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
  PoundCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Divider,
  Drawer,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Skeleton,
  Space,
  Statistic,
  Table,
  Tag,
  Tooltip,
} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import Title from "antd/lib/typography/Title";
import React, { useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { $ } from "../../graphql/generated";
import { query, useTypedMutation, useTypedQuery } from "../../graphql/hooks";
import { getImageUrl } from "../../utils/image-url";
import { price } from "../../utils/price";
import DynamicForm from "../forms/DynamicForm";

const LEASE = query({
  Lease: [
    {
      id: $`id`,
    },
    {
      id: true,
      start: true,
      end: true,
      firstDaysFree: true,
      nextDepositDate: true,
      outstandingBalance: true,
      outstandingDeposit: true,
      pricePerDay: true,
      totalPaid: true,
      totalDeposit: true,
      initialDeposit: true,
      depositGraceWeeks: true,
      numberOfDepositWeeks: true,
      nextDepositAmount: true,
      rider: {
        id: true,
        name: true,
        email: true,
        profileImageKey: true,
        stripeSetupIntentId: true,
      },
      vehicle: {
        id: true,
        registration: true,
        status: true,
        note: true,
        dvlaInfo: true,
      },
      payments: {
        id: true,
        created: true,
        dueAt: true,
        paidAt: true,
        status: true,
        type: true,
        value: true,
        stripePaymentIntentId: true,
      },
    },
  ],
});

export default function LeaseView() {
  const { id } = useParams<{ id: string }>();

  const { data, refetch } = useTypedQuery(LEASE, {
    variables: {
      id,
    },
    pollInterval: 5000,
  });

  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const onComplete = useCallback(() => {
    refetch();
    setShow(false);
  }, []);

  const [payMutation, { loading }] = useTypedMutation(
    {
      PayLeasePayment: [
        {
          id: $`id`,
          manual: $`manual`,
        },
        {
          id: true,
          paidAt: true,
        },
      ],
    },
    {
      onCompleted: () => message.success("Paid!"),
      onError: (e) =>
        Modal.error({
          title: "Failed to pay lease",
          content: e.message,
        }),
    }
  );

  const pay = (id: string, manual?: boolean) =>
    payMutation({
      variables: {
        id,
        manual,
      },
    });

  if (!data) return <Skeleton active />;

  const lease = data.Lease;

  return (
    <>
      <PageHeader
        title={lease.id.slice(0, 8)}
        subTitle="Lease"
        extra={[
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => setShowEdit(true)}
          >
            Edit
          </Button>,
        ]}
      >
        <Descriptions bordered>
          <Descriptions.Item label="Vehicle">
            {lease.vehicle.registration}
          </Descriptions.Item>
          <Descriptions.Item label="Rider">
            <Avatar
              src={
                lease.rider.profileImageKey
                  ? getImageUrl(lease.rider.profileImageKey, 256, 256)
                  : undefined
              }
            />{" "}
            <Link to={`/rider/${lease.rider.id}/leases`}>
              {lease.rider.name || lease.rider.email}
            </Link>
            <Tooltip
              title={
                lease.rider.stripeSetupIntentId
                  ? "This rider has set up direct debit."
                  : "No direct debit set up"
              }
            >
              <Tag color={lease.rider.stripeSetupIntentId ? "green" : "red"}>
                {lease.rider.stripeSetupIntentId ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseOutlined />
                )}
              </Tag>
            </Tooltip>
          </Descriptions.Item>
          <Descriptions.Item label="Period">
            {new Date(lease.start).toLocaleString()} -{" "}
            {lease.end ? new Date(lease.end).toLocaleString() : "Open Ended"}
          </Descriptions.Item>
          <Descriptions.Item label="First Days Free">
            {lease.firstDaysFree}
          </Descriptions.Item>
          <Descriptions.Item label="Deposit Weeks">
            {lease.numberOfDepositWeeks} + {lease.depositGraceWeeks} free
          </Descriptions.Item>
          <Descriptions.Item label="Next Deposit">
            {price(lease.nextDepositAmount)} on{" "}
            {new Date(lease.nextDepositDate).toLocaleString()}
          </Descriptions.Item>
        </Descriptions>

        <Space
          size="large"
          style={{
            marginTop: 8,
          }}
        >
          <Statistic title="Deposit" value={price(lease.totalDeposit)} />
          <Statistic
            title="Initial Deposit"
            value={price(lease.initialDeposit)}
          />
          <Statistic
            title="Total Outstanding Deposit"
            value={price(lease.outstandingDeposit)}
          />

          <Statistic
            title="Outstanding Balance"
            value={price(lease.outstandingBalance)}
          />
          <Statistic title="Total Paid" value={price(lease.totalPaid)} />
          <Statistic
            title="Price"
            value={price(lease.pricePerDay)}
            suffix="/day"
          />
        </Space>
        <Divider />

        <Table
          dataSource={lease.payments}
          title={() => (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Title level={4} style={{ flex: 1 }}>
                {lease.payments.length} Payments
              </Title>

              <Space>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setShow(true)}
                >
                  Create Payment
                </Button>
              </Space>
            </div>
          )}
          columns={[
            {
              title: "Created",
              dataIndex: "created",
            },
            {
              title: "Type",
              dataIndex: "type",
            },
            {
              title: "Due",
              dataIndex: "dueAt",
              render: (r) => new Date(r).toLocaleString(),
            },
            {
              title: "Paid",
              dataIndex: "paidAt",
              render: (r, p) =>
                r ? (
                  <Tag
                    icon={r ? <CheckOutlined /> : <ClockCircleOutlined />}
                    color={r ? "green" : "default"}
                  >
                    <a
                      target="_blank"
                      href={`https://dashboard.stripe.com/test/payments/${p.stripePaymentIntentId}`}
                      rel="noreferrer"
                    >
                      {" "}
                      {new Date(r).toLocaleString()}
                    </a>
                  </Tag>
                ) : (
                  <Popconfirm
                    title="Are you sure you want to direct debit this payment?"
                    onConfirm={() => pay(p.id)}
                    okButtonProps={{
                      icon: <PoundCircleFilled />,
                    }}
                    okText="Make Payment"
                    cancelText="Already paid"
                    onCancel={() => pay(p.id, true)}
                  >
                    <Button type="primary" icon={<PoundCircleFilled />}>
                      Pay Now
                    </Button>
                  </Popconfirm>
                ),
            },
            {
              title: "Amount",
              dataIndex: "value",
              render: price,
            },
          ]}
        />

        <Divider />
        <pre>{JSON.stringify(lease, null, 2)}</pre>
      </PageHeader>
      <Drawer
        width={window.innerWidth <= 768 ? "100%" : "50%"}
        visible={show}
        onClose={onComplete}
      >
        <DynamicForm
          id="LeasePayment"
          entityId={id}
          onComplete={onComplete}
          noCard
        />
      </Drawer>
      <Modal
        width={window.innerWidth <= 768 ? "100%" : "50%"}
        visible={showEdit}
        onCancel={() => setShowEdit(false)}
        footer={null}
      >
        <DynamicForm id="Lease" onComplete={onComplete} noCard entityId={id} />
      </Modal>
    </>
  );
}
