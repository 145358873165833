import { icon } from "leaflet";

export interface Codes {
  admin_district: string;
  admin_county: string;
  admin_ward: string;
  parish: string;
  parliamentary_constituency: string;
  ccg: string;
  ccg_id: string;
  ced: string;
  nuts: string;
}

export interface LatLonResult extends PostcodeResult {
  distance: number;
}

export interface PostcodeResult {
  postcode: string;
  quality: number;
  eastings: number;
  northings: number;
  country: string;
  nhs_ha: string;
  longitude: number;
  latitude: number;
  european_electoral_region: string;
  primary_care_trust: string;
  region?: any;
  lsoa: string;
  msoa: string;
  incode: string;
  outcode: string;
  parliamentary_constituency: string;
  admin_district: string;
  parish?: any;
  admin_county?: any;
  admin_ward: string;
  ced?: any;
  ccg: string;
  nuts: string;
  codes: Codes;
}

export interface PostcodeResponse {
  status: number;
  result: PostcodeResult;
}

export interface QueryPostcodeResponse {
  status: number;
  result: PostcodeResult[];
}

export interface LatLonResponse {
  status: number;
  result: LatLonResult[];
}

export interface ValidateResponse {
  status: number;
  result: boolean;
}

const API_URL = "https://postcodes.ecoeats.uk";

export const postcodeToInfo = async (
  postcode: string
): Promise<PostcodeResponse> => {
  const res = await fetch(`${API_URL}/postcodes/${postcode}`).then((r) =>
    r.json()
  );

  return res;
};

export const latLngToPostcode = async (
  latlng: [number, number],
  radius = 1000
): Promise<LatLonResponse> => {
  const res = await fetch(
    `${API_URL}/postcodes?lon=${latlng[1]}&lat=${latlng[0]}&limit=1&radius=${radius}`
  ).then((r) => r.json());

  return res;
};

export const queryPostcode = async (
  query: string
): Promise<QueryPostcodeResponse> => {
  const res = await fetch(`${API_URL}/postcodes?q=${query}`).then((r) =>
    r.json()
  );

  return res;
};

export const validatePostcode = async (
  postcode: string
): Promise<ValidateResponse> => {
  const res = await fetch(
    `${API_URL}/postcodes/${postcode}/validate`
  ).then((r) => r.json());

  return res;
};

export const postcodeIcon = icon({
  iconUrl: `/static/images/custom-map-markers/aim-outline.png`,
  iconSize: [40, 40],
  iconAnchor: [20, 20],
  popupAnchor: [20, 0],
});