import {
  DownOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Button, message, Table } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useTypedMutation } from "../../graphql/hooks";
import { REORDER_MODIFIER_GROUPS } from "../../graphql/mutations";
import { SearchModifierGroups } from "../forms/SearchModifierGroups";
import AvailabilitySelect from "../partner/AvailabilitySelect";
import { MenuContextType } from "../RestaurantMenu";

interface ModifierOption {
  id: string;
  name: string;
  description: string;
}

interface ModifierGroup {
  id: string;
  name: string;
  sortOrder: number;
  cascades: boolean;
  options: ModifierOption[];
  instruction: string;
}

interface ItemModifierGroup {
  id: string;
}

interface Item {
  id: string;
  name: string;
  unitPrice: number;
  available: boolean;
  sortOrder: number;
  description: string;
  headerImage: string;
  published: boolean;
  categoryId: string;
  modifiers: ItemModifierGroup[];
}

interface Category {
  id: string;
  name: string;
  items: Item[];
}

interface Restaurant {
  id: string;
  name: string;
}

interface Props {
  restaurant: Restaurant | undefined;

  menu: MenuContextType["menu"];
  // selected modfiier groups
  selected?: MenuContextType["menu"]["modifierGroups"];
  onChange?: (
    modifierGroups: MenuContextType["menu"]["modifierGroups"] | undefined
  ) => void;
  refreshMenu: () => void;
  addNewModifierGroup: () => void;
}

const ItemModifierGroupsTable = (props: Props) => {
  const {
    restaurant,
    menu,
    selected,
    onChange,
    refreshMenu,
    addNewModifierGroup,
  } = props;

  const sortedModifers = useMemo(() => {
    if (menu?.modifierGroups) {
      return menu.modifierGroups.slice().sort((aMg, bMg) => {
        const sortOrderDiff = aMg.sortOrder - bMg.sortOrder;
        if (sortOrderDiff !== 0) {
          return sortOrderDiff;
        } else {
          return String(aMg.name).localeCompare(bMg.name!);
        }
      });
    } else {
      return undefined;
    }
  }, [menu?.modifierGroups]);

  const sortedSelectedModifiers = useMemo(() => {
    const selectedModifierIds = selected?.map((mg) => mg.id);
    return (
      sortedModifers?.filter((mg) => selectedModifierIds?.includes(mg.id)) || []
    );
  }, [sortedModifers, selected]);

  console.log(sortedSelectedModifiers, selected);

  const selectedModiferData = useMemo(() => {
    return sortedSelectedModifiers.map((selectedModifier) => {
      return {
        ...selectedModifier,
        key: selectedModifier.id,
      };
    });
  }, [sortedSelectedModifiers]);

  const expandedRowRender = (
    modifierGroup: typeof menu["modifierGroups"][0]
  ) => {
    const modifierGroupOptions = modifierGroup.options.map((option) => {
      const optionItem = option;
      return {
        ...option,
        unitPriceStr: `£${(optionItem.unitPrice / 100).toFixed(2)}`,
        published: optionItem.published,
        available: optionItem.available,
      };
    });
    const columns = [
      {
        title: "Option Name",
        dataIndex: "name",
      },
      {
        title: "Option Description",
        dataIndex: "description",
      },
      {
        title: "Option Unit Price",
        dataIndex: "unitPriceStr",
      },
      {
        title: "Option Available",
        render: (option) => {
          return (
            <AvailabilitySelect
              id={option.id}
              published={option.published}
              available={option.available}
              partnerId={restaurant?.id!}
              onChange={() => refreshMenu()}
            />
          );
        },
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={modifierGroupOptions}
        pagination={false}
      />
    );
  };

  const [reorderModifierGroups] = useTypedMutation(REORDER_MODIFIER_GROUPS, {
    onError: () => message.error("Could not reorder modifier groups."),
    onCompleted: () => {
      message.success("Modifiers Reordered!");
      refreshMenu();
      if (onChange) onChange(selected);
    },
  });

  const swapModifiers = useCallback(
    async (modifier1, modifier2) => {
      const modifierGroup1 = menu?.modifierGroups.find(
        (mg) => mg.id === modifier1.id
      );
      const modifierGroup2 = menu?.modifierGroups.find(
        (mg) => mg.id === modifier2.id
      );

      if (!modifierGroup1 || !modifierGroup2) return;

      const newSortedModifierGroupIds = sortedModifers?.map((modifier) => {
        if (modifier.id === modifierGroup1.id) {
          return modifierGroup2.id;
        } else if (modifier.id === modifierGroup2.id) {
          return modifierGroup1.id;
        } else {
          return modifier.id;
        }
      });

      const input = {
        variables: {
          partnerId: restaurant?.id,
          modifierGroupIds: newSortedModifierGroupIds,
        },
      };

      reorderModifierGroups(input);
    },
    [
      menu?.modifierGroups,
      reorderModifierGroups,
      restaurant?.id,
      sortedModifers,
    ]
  );

  const moveModifierUp = useCallback(
    async (itemModifier) => {
      const modifierIndex = sortedSelectedModifiers.findIndex(
        (mi) => mi.id === itemModifier.id
      );
      if (modifierIndex > 0) {
        // if first index then can't move up
        await swapModifiers(
          sortedSelectedModifiers[modifierIndex],
          sortedSelectedModifiers[modifierIndex - 1]
        );
      }
    },
    [sortedSelectedModifiers, swapModifiers]
  );

  const moveModifierDown = useCallback(
    async (itemModifier) => {
      const modifierIndex = sortedSelectedModifiers.findIndex(
        (mi) => mi.id === itemModifier.id
      );
      if (
        modifierIndex !== -1 &&
        modifierIndex !== sortedSelectedModifiers.length - 1
      ) {
        // if last index then can't move down
        await swapModifiers(
          sortedSelectedModifiers[modifierIndex],
          sortedSelectedModifiers[modifierIndex + 1]
        );
      }
    },
    [sortedSelectedModifiers, swapModifiers]
  );

  const removeModifierFromItem = useCallback(
    (modifierToRemove) => {
      if (onChange)
        onChange(selected?.filter((mg) => mg.id !== modifierToRemove.id));
    },
    [onChange, selected]
  );

  const [showAddModifierModal, setShowAddModifierModal] = useState(false);

  const getModifierGroupsFromIds = useCallback(
    (mgIds: string[]) => {
      return sortedModifers?.filter((mg) => mgIds.includes(mg.id));
    },
    [sortedModifers]
  );

  return (
    <>
      <>
        <div style={{ position: "relative", float: "right", bottom: 10 }}>
          <Button onClick={() => setShowAddModifierModal(true)}>
            <PlusCircleOutlined />
            Add Existing Modifier Group
          </Button>
          <Button onClick={() => addNewModifierGroup()}>
            <PlusCircleOutlined />
            Create New Modifier Group
          </Button>
        </div>

        <SearchModifierGroups
          show={showAddModifierModal}
          onHide={() => setShowAddModifierModal(false)}
          onSave={(selected) => {
            const updatedMgs = getModifierGroupsFromIds(selected);
            if (updatedMgs) {
              if (onChange) onChange(updatedMgs);
            } else {
              message.error("Could not update item modifiers.");
            }
            setShowAddModifierModal(false);
          }}
          initialSelectedModifierGroupIds={
            selected ? selected.map((mg) => mg.id) : []
          }
          restaurant={restaurant}
        />
      </>
      <Table
        dataSource={selectedModiferData}
        pagination={false}
        expandable={{ expandedRowRender }}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
          },
          {
            title: "Instruction",
            dataIndex: "instruction",
          },
          {
            width: 120,
            render: (modifier) => {
              return (
                <table>
                  <tr>
                    <td style={{ width: 20 }}>
                      <Button
                        icon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <UpOutlined />
                          </div>
                        }
                        style={{ borderRadius: "20%" }}
                        onClick={async () => await moveModifierUp(modifier)}
                      />
                    </td>
                    <td style={{ marginLeft: 10, width: 20 }}>
                      <Button
                        icon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            <DownOutlined />{" "}
                          </div>
                        }
                        style={{ borderRadius: "20%" }}
                        onClick={async () => await moveModifierDown(modifier)}
                      />
                    </td>
                  </tr>
                </table>
              );
            },
          },

          {
            width: 60,
            render: (modifier) => {
              return (
                <Button
                  danger
                  icon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MinusCircleOutlined />{" "}
                    </div>
                  }
                  style={{ borderRadius: "20%" }}
                  onClick={() => removeModifierFromItem(modifier)}
                />
              );
            },
          },
        ]}
      />
    </>
  );
};

export default ItemModifierGroupsTable;
