import React from "react";
import { Col, Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { OrdersTable } from "../components/OrdersTable";

const Orders = () => {
  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Orders"
          subtitle="Dashboard"
          className="text-sm-left"
        />
      </Row>

      <Col>
        <Col>
          <OrdersTable restaurant={null} />
        </Col>
      </Col>
    </Container>
  );
};

export default Orders;
