import React, { useRef, useCallback } from "react";
import { Table, Button, message } from "antd";
import Text from "antd/lib/typography/Text";
import { DndProvider } from "react-dnd";
import { DraggableTableRow, RNDContext } from "../partner/DraggableTableRow";
import { useTypedMutation } from "../../graphql/hooks";
import { REORDER_MODIFIER_GROUPS } from "../../graphql/mutations";
import update from "immutability-helper";
import Title from "antd/lib/typography/Title";

interface Props {
  loading: boolean;
  updateMgSortOrder: (
    mg: {
      id: string;
      name: string;
      maxOptions: number;
      minOptions: number;
      options: {
        id: string;
      }[];
      priceStrategy: "NO_EXTRA_PRICE" | "ITEM_PRICE_SUM";
      cascades: boolean;
    },
    sortOrder: number
  ) => void;
  modifierGroups?: {
    id: string;
    name: string;
    maxOptions: number;
    minOptions: number;
    options: {
      id: string;
    }[];
    priceStrategy: "NO_EXTRA_PRICE" | "ITEM_PRICE_SUM";
    cascades: boolean;
    instruction: string | undefined;
    sortOrder: number;
  }[];
  onMgClick: (mg: {
    id: string;
    name: string;
    maxOptions: number;
    minOptions: number;
    options: {
      id: string;
    }[];
    priceStrategy: "NO_EXTRA_PRICE" | "ITEM_PRICE_SUM";
    cascades: boolean;
    instruction: string | undefined;
    sortOrder: number;
  }) => void;
  refetchMenu: () => void;
  partnerId: string;
}

const components = {
  body: {
    row: DraggableTableRow,
  },
};

const ModifierGroupsTable = (props: Props) => {
  const {
    loading,
    modifierGroups,
    updateMgSortOrder,
    onMgClick,
    refetchMenu,
    partnerId,
  } = props;

  const manager = useRef(RNDContext);

  const sortedModifierGroups = modifierGroups
    ? [...modifierGroups!].sort((a, b) => a.sortOrder - b.sortOrder)
    : modifierGroups;

  const [reorderModifierGroups, { loading: loadingReorder }] = useTypedMutation(
    REORDER_MODIFIER_GROUPS,
    {
      onError: () => message.error("Could not reorder modifier groups."),
      onCompleted: () => {
        message.success("Modifiers Reordered!");
        refetchMenu();
      },
    }
  );

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      if (!sortedModifierGroups) return;

      const dragRow = sortedModifierGroups![dragIndex];
      const reorderedModifierGroups = update(sortedModifierGroups, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      reorderModifierGroups({
        variables: {
          partnerId,
          modifierGroupIds: reorderedModifierGroups.map((mg) => mg.id),
        },
      });
    },
    [sortedModifierGroups, reorderModifierGroups, partnerId]
  );

  const onRow = useCallback(
    (record, index) => ({
      index,
      moveRow,
    }),
    [moveRow]
  );

  return (
    <DndProvider manager={manager.current.dragDropManager as DragDropManager}>
      <Table
        title={() => <Title level={4}>Modifier Groups</Title>}
        pagination={false}
        rowKey={(mg) => `${mg.id}_row_rm`}
        loading={loading || loadingReorder}
        dataSource={sortedModifierGroups}
        components={components}
        onRow={onRow}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
          },
          {
            title: "Instruction",
            dataIndex: "instruction",
          },
          {
            title: "Order",
            dataIndex: "sortOrder",
            width: 150,
            render: (sortOrder, mg) => (
              <Text
                editable={{
                  onChange: (strVal) => {
                    const val = parseFloat(strVal);
                    if (!isNaN(val)) {
                      updateMgSortOrder(mg, val);
                    } else {
                      message.error(`${strVal} is not a valid sort order.`);
                    }
                  },
                }}
              >
                {sortOrder.toString()}
              </Text>
            ),
          },
          // {
          //   title: "Approved",
          //   dataIndex: "approved",
          //   width: 100,
          //   render: (approved, mg) => (
          //     <Switch
          //       onChange={(v) => updateMg(mg, { approved: v })}
          //       checked={approved !== false}
          //     />
          //   ),
          // },
          {
            title: "View/Edit",
            width: 30,
            render: (_, mg) => (
              <Button type="text" onClick={() => onMgClick(mg)}>
                Edit
              </Button>
            ),
          },
        ]}
      />
    </DndProvider>
  );
};

export default ModifierGroupsTable;
