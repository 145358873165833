import React from "react";
import { Button } from "react-bootstrap";
import { ThreeDots } from "./ThreeDots";

export const LoadingButton = ({
  loading,
  children,
  disabled = false,
  ...props
}) => (
  <Button variant="primary" {...props} disabled={loading || disabled}>
    {
      // @ts-ignore
      !loading || <ThreeDots width="30" />
    }{" "}
    {children}
  </Button>
);
