import { InboxOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, message, Space, Upload } from "antd";
import React, { useCallback } from "react";
import { $ } from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";

/**
 * Upload new media to the server as an asset
 */
export default function MediaUpload({
  onSuccess,
}: {
  onSuccess?: (id: string) => void;
}) {
  const [upload, { loading, error }] = useTypedMutation(
    {
      uploadImageAsset: [
        {
          image: $`image`,
          altText: $`altText`,
          title: $`title`,
        },
        {
          id: true,
          key: true,
        },
      ],
    },
    {
      onCompleted: (d) => {
        message.success({
          message: "Upload Complete!",
        });
        onSuccess && onSuccess(d.uploadImageAsset.id);
      },
    }
  );

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess && onSuccess("ok", null as any);
    }, 0);
  };

  const onFinish = useCallback((v) => {
    upload({
      variables: {
        ...v,
        image: v.image[0].originFileObj,
      },
    });
  }, []);

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Form.Item name="title" label="Title">
        <Input />
      </Form.Item>

      <Form.Item name="altText" label="Alt Text">
        <Input />
      </Form.Item>

      <Form.Item
        name="image"
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload.Dragger name="image" customRequest={customRequest as any}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>
      </Form.Item>

      <Space align="end">
        <Button loading={loading} type="primary" htmlType="submit">
          Upload
        </Button>
      </Space>

      {error && <Alert type="error" message={error.message} />}
    </Form>
  );
}
