export function rangeInFuture([start, end]: [
  Date | string | number,
  Date | string | number
]) {
  const now = new Date().getTime();
  const isFuture = new Date(start).getTime() > now;
  const isNow =
    new Date(start).getTime() < now && new Date(end).getTime() > now;

  return { isFuture, isNow };
}
