import { LoadingOutlined } from "@ant-design/icons";
import Spin, { SpinProps } from "antd/lib/spin";
import React from "react";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function NiceSpin({
  children,
  ...props
}: SpinProps & { children: any }) {
  return (
    <Spin indicator={antIcon} {...props}>
      {children}
    </Spin>
  );
}
