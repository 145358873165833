import { Tabs } from "antd";
import React from "react";
import useTabSync from "../../hooks/useTabSync";
import ReusableContainerTable from "./ReusableContainerTable";
import ReusableShipmentTable from "./ReusableShipmentTable";

export default function PartnerReusables({ partnerId }: { partnerId: string }) {
  const { key, onSelect } = useTabSync("dropboxes", {}, "subtab");

  return (
    <>
      <Tabs activeKey={key} onChange={onSelect}>
        <Tabs.TabPane tab="Shipments" key="shipments">
          <ReusableShipmentTable partnerId={partnerId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Containers" key="containers">
          <ReusableContainerTable />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}
