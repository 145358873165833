import { Alert, Button, Card, Form, Input, Modal } from "antd";
import React from "react";
import { $ } from "../graphql/generated";
import { useTypedMutation, useTypedQuery } from "../graphql/hooks";
import { onSuccess } from "../utils/on-error";
import PartnerTabletTable from "./partner/PartnerTabletTable";

export function RestaurantTablet({
  restaurant,
}: {
  restaurant: { id: string };
}) {
  const [updateRestaurant, { loading, error }] = useTypedMutation(
    {
      updateTabletAccountPassword: [
        {
          password: $`password`,
          restaurantId: $`restaurantId`,
        },
        true,
      ],
    },
    { onCompleted: onSuccess }
  );

  const { data } = useTypedQuery({
    tabletForRestaurant: [
      {
        restaurantId: restaurant.id,
      },
      {
        id: true,
        email: true,
      },
    ],
  });

  const submitForm = async ({ password }) => {
    const input = {
      variables: {
        restaurantId: restaurant.id,
        password,
      },
    };

    if (!data) {
      const { destroy } = Modal.confirm({
        title: "Create Tablet Account",
        content: "This partner has no tablet account, create one?",
        onOk: () => {
          destroy();
          updateRestaurant(input);
        },
      });
    } else {
      updateRestaurant(input);
    }
  };

  return (
    <>
      <Card title="Tablet Password">
        {error
          ? error.graphQLErrors.map((e) => (
              <Alert message={JSON.stringify(e)} type="error" />
            ))
          : null}

        <Form onFinish={submitForm}>
          {data?.tabletForRestaurant ? (
            <h6>{data?.tabletForRestaurant.email}</h6>
          ) : (
            <Alert
              message="No Tablet Account Found! Set a password to create one"
              style={{ marginTop: 8, marginBottom: 16 }}
              type="warning"
            />
          )}
          <Form.Item name="password" label="Password">
            <Input />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={loading}>
            Set Tablet Password
          </Button>
        </Form>
      </Card>
      <PartnerTabletTable partnerId={restaurant.id} />
    </>
  );
}
