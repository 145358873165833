import { useCallback, useEffect, useState } from "react";
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

export default function useTabSync(
  initialKey: string,
  extraParams: Record<string, string> = {},
  paramName = "tab"
) {
  const params = useParams() as any;

  const tab = params[paramName];

  const history = useHistory();
  const match = useRouteMatch();
  const [key, setKey] = useState(initialKey);

  useEffect(() => {
    setKey(tab);
  }, [tab]);

  const onSelect = useCallback(
    (_tab) => {
      const path = generatePath(match.path, {
        ...extraParams,
        ...params,
        [paramName]: _tab,
      });
      history.replace(path);
    },
    [extraParams, history, match.path, paramName, params]
  );

  return { key, onSelect };
}
