import { Badge, message, Select } from "antd";
import React, { useCallback, useState } from "react";
import { $ } from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";
import { hiddenUnavailable } from "../../utils/hidden-unavailable";

export type AvailabilitySelectOnChange = (out: {
  available: boolean;
  published: boolean;
}) => void;

export default function AvailabilitySelect({
  id,
  published,
  available,
  partnerId,
  onChange,
}: {
  id: string;
  published: boolean;
  available: boolean;
  partnerId: string;
  onChange?: AvailabilitySelectOnChange;
}) {
  const [setAvailable, { loading: setting }] = useTypedMutation(
    {
      setItemAvailability: [
        {
          id: $`id`,
          available: $`available`,
          published: $`published`,
          restaurantId: $`restaurantId`,
        },
        {
          id: true,
          available: true,
          published: true,
        },
      ],
    },
    {
      onCompleted: (d) => {
        if (onChange)
          onChange({
            available: d.setItemAvailability.available,
            published: d.setItemAvailability.published,
          });
        setValue(
          hiddenUnavailable(
            d.setItemAvailability.published,
            d.setItemAvailability.available
          )
        );
        message.success("Saved");
      },
    }
  );

  const [value, setValue] = useState<"AVAILABLE" | "UNAVAILABLE" | "HIDDEN">(
    hiddenUnavailable(id && published, id && available)
  );

  const onSelectValueChange = useCallback(
    (v: "AVAILABLE" | "UNAVAILABLE" | "HIDDEN") =>
      setAvailable({
        variables: {
          id,
          available: v !== "UNAVAILABLE",
          published: v !== "HIDDEN",
          restaurantId: partnerId,
        },
      }),
    []
  );

  return (
    <Select
      loading={setting}
      value={value}
      style={{
        minWidth: 200,
      }}
      onChange={onSelectValueChange}
    >
      <Select.Option value="AVAILABLE">
        <Badge text="Available" status="success" />
      </Select.Option>
      <Select.Option value="UNAVAILABLE">
        <Badge text="Unavailable (today)" status="warning" />
      </Select.Option>
      <Select.Option value="HIDDEN">
        <Badge text="Hidden" status="error" />
      </Select.Option>
    </Select>
  );
}
