import { Table } from "antd";
import React, { useState } from "react";
import { query, useTypedQuery } from "../../graphql/hooks";
import { OFFER_LIST } from "../../graphql/queries";
import { OrderOfferTableColumns } from "../order/OrderOfferTable";

export default function FlightOfferTable({
  riderId,
  orderId,
}: {
  riderId?: string;
  orderId?: string;
}) {
  const [page, setSkip] = useState(1);
  const [take, setTake] = useState(10);
  const [sortBy, setSortBy] = useState("offered");
  const [order, setOrder] = useState("DESC");
  const [_query, setQuery] = useState("");

  const { data, loading } = useTypedQuery(OFFER_LIST, {
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
      includeCompleted: true,
      riderId,
      orderId,
    },
    pollInterval: 5000,
    fetchPolicy: "cache-and-network",
  });
  const offers = data?.FlightPlanOfferList.items || [];

  return (
    <>
      <Table
        loading={loading}
        dataSource={offers}
        columns={OrderOfferTableColumns}
        expandable={{
          rowExpandable: (r) => !!r.context,
          expandedRowRender: (r) => (
            <pre>{JSON.stringify(r.context, null, 2)}</pre>
          ),
        }}
        pagination={{
          total: data?.FlightPlanOfferList.total,
          current: page,
          pageSize: take,
          onChange: (p, ps) => {
            setTake(ps);
            setSkip(p);
          },
          pageSizeOptions: ["10", "25", "50"],
          showSizeChanger: true,
        }}
      />
    </>
  );
}
