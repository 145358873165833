import { Alert, PageHeader, Tabs } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import DynamicForm from "../components/forms/DynamicForm";
import Scheduler from "../components/platform/Scheduler";
export default function Platform() {
  const { tab } = useParams() as any;

  const history = useHistory();
  const match = useRouteMatch();
  const [key, setKey] = useState("scheduler");

  useEffect(() => {
    setKey(tab);
  }, [tab]);

  const onSelect = useCallback((_tab) => {
    const path = generatePath(match.path, { tab: _tab });
    history.replace(path);
  }, []);

  return (
    <Container fluid>
      <PageHeader title="Platform" subTitle="API and Internal Settings" />

      <Alert type="error" message="Don't touch things you don't understand." />

      <Tabs activeKey={key} onChange={onSelect} id="platform-nav">
        <Tabs.TabPane key="scheduler" tab="Scheduler">
          <Scheduler />
        </Tabs.TabPane>
        <Tabs.TabPane key="export" tab="Export">
          <DynamicForm id="ExportService" />
        </Tabs.TabPane>
        <Tabs.TabPane key="forms" tab="Forms">
          <DynamicForm id="DynamicFormList" />
        </Tabs.TabPane>
        <Tabs.TabPane key="homepage" tab="Homepage">
          <DynamicForm id="HomePageSettings" />
        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
}
