import { HeatMapOutlined } from "@ant-design/icons";
import { Badge, Descriptions, PageHeader, Tag } from "antd";
import Title from "antd/lib/typography/Title";
import L from "leaflet";
import poly from "polyline-encoded";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { Marker, Polyline, Popup } from "react-leaflet";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { style } from "typestyle";
import { $ } from "../../graphql/generated";
import { query, useTypedQuery } from "../../graphql/hooks";
import Map from "../../utils/Map";
import { LRiderIcon } from "../../views/Order";
import { OrderRiderMiniDisplay } from "../order/OrderDescriptions";
import { OrderBadge } from "../OrderBadge";
import ReusablePickupTable from "./ReusablePickupTable";

const PICKUP_AGG = query({
  ReusablesPickupAggregate: [
    {
      id: $`id`,
    },
    {
      id: true,
      createdAt: true,
      orders: {
        id: true,
        number: true,
        status: true,
        directions: true,
        isBeingOfferedToRider: true,
        rider: {
          id: true,
          name: true,
          phoneNumber: true,
          vehicle: true,
          position: {
            type: true,
            coordinates: true,
          },
        },
      },
      pickups: {
        id: true,
        customerCount: true,
        riderCount: true,
        refundedDeposit: true,
        customer: {
          id: true,
          name: true,
          email: true,
        },
        address: {
          id: true,
          flat_number: true,
          address_line_1: true,
          postcode: true,
          coordinates: {
            type: true,
            coordinates: true,
          },
        },
      },
      washingLocation: {
        id: true,
        name: true,
        status: true,
        address: {
          id: true,
          flat_number: true,
          address_line_1: true,
          postcode: true,
          coordinates: {
            type: true,
            coordinates: true,
          },
        },
      },
    },
  ],
});

const styles = {
  icon: style({
    display: "flex",
    borderRadius: 30,
    backgroundColor: "white",
    height: 32,
    width: 32,
    justifyContent: "center",
    alignItems: "center",
  }),
};
const LWashingIcon = L.divIcon?.({
  className: "custom-icon",
  iconAnchor: [12, 12],
  html: ReactDOMServer.renderToString(
    <div
      className={styles.icon}
      style={{
        border: `4px solid green`,
      }}
    >
      <HeatMapOutlined />
    </div>
  ),
});

export default function PickupAggregate() {
  const { id } = useParams();

  const { data } = useTypedQuery(PICKUP_AGG, {
    pollInterval: 10000,
    variables: {
      id,
    },
  });

  const pickups = data?.ReusablesPickupAggregate.pickups;
  const location = data?.ReusablesPickupAggregate.washingLocation;
  const orders = data?.ReusablesPickupAggregate.orders;
  const activeOrder = orders?.find(
    (o) => o.status !== "CANCELLED" && o.status !== "COMPLETE"
  );
  const locationCoords = {
    lat: location?.address.coordinates?.coordinates[0],
    lng: location?.address.coordinates?.coordinates[1],
  };

  const line = activeOrder?.directions
    ? poly.decode(activeOrder.directions)
    : null;

  return (
    <PageHeader
      title={
        <Title
          level={5}
          style={{
            fontFamily: "monospace",
            marginBottom: 0,
          }}
        >
          {data?.ReusablesPickupAggregate.id.slice(0, 8)}
        </Title>
      }
      subTitle="Pickup Aggregate"
    >
      {location ? (
        <Map
          style={{
            height: 300,
          }}
          zoom={10}
          center={locationCoords}
        >
          {line && <Polyline positions={line} />}
          <Marker position={locationCoords} icon={LWashingIcon}>
            <Popup>Washing Location</Popup>
          </Marker>
          {activeOrder?.rider && (
            <Marker
              icon={LRiderIcon(activeOrder.rider.vehicle)}
              position={{
                lat: activeOrder.rider.position?.coordinates[0],
                lng: activeOrder.rider.position?.coordinates[1],
              }}
            ></Marker>
          )}
          {pickups?.map((p) => (
            <Marker
              position={{
                lat: p.address.coordinates?.coordinates[0],
                lng: p.address.coordinates?.coordinates[1],
              }}
            ></Marker>
          ))}
        </Map>
      ) : (
        <div style={{ width: "100%", height: 300 }} />
      )}

      {location && (
        <Descriptions bordered style={{ marginTop: 16, marginBottom: 16 }}>
          <Descriptions.Item label="Washing Location">
            <Badge
              status={location.status === "READY" ? "success" : "warning"}
              text={
                <Link to={`/reusables/washing-location/${location.id}`}>
                  {location.name}
                </Link>
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="Order">
            {data?.ReusablesPickupAggregate.orders.map((o) => (
              <Tag color="green">
                <Link to={`/order/${o.id}`}>{o.number}</Link>{" "}
                <OrderBadge status={o.status} />
              </Tag>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label="Rider">
            {activeOrder && <OrderRiderMiniDisplay {...activeOrder} />}
          </Descriptions.Item>
        </Descriptions>
      )}

      <ReusablePickupTable aggregateId={id} />

      <pre>{JSON.stringify(data, null, 2)}</pre>
    </PageHeader>
  );
}
