import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, PageHeader, Skeleton } from "antd";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTypedQuery } from "../../graphql/hooks";
import { CHARITY_CAMPAIGN } from "../../graphql/queries";
import DynamicForm from "../forms/DynamicForm";

export default function SingleCampaign() {
  const { id } = useParams() as any;

  const { data } = useTypedQuery(CHARITY_CAMPAIGN, {
    variables: { id },
  });

  const campaign = data?.CharityCampaign;

  if (!campaign) return <Skeleton active></Skeleton>;

  return (
    <>
      <PageHeader
        title={campaign?.title}
        avatar={{
          size: 128,
          src: campaign.campaignLogo?.url,
        }}
        backIcon
        breadcrumb={{
          itemRender: (r) => <Link to={r.path}>{r.breadcrumbName}</Link>,
          routes: [
            {
              breadcrumbName: "Campaigns",
              path: "/donations/campaigns",
            },
            {
              breadcrumbName: campaign.title,
              path: "#",
            },
          ],
        }}
        subTitle={campaign.description}
        extra={[
          <Button
            type="primary"
            target="blank"
            href={`/charity/${campaign.charity.id}`}
            icon={<ArrowRightOutlined />}
          >
            View {campaign.charity.name}
          </Button>,
        ]}
      >
        <DynamicForm id="CharityCampaign" entityId={id} />
      </PageHeader>
    </>
  );
}
