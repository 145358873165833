import { Badge } from "antd";
import React from "react";
import { ZoneBusyStatus, ZoneOnboardingStatus } from "../graphql/generated";

export function ZoneStateBadge({
  onboardingStatus,
  showText = true,
}: {
  showText: boolean;
  onboardingStatus: ZoneOnboardingStatus;
}) {
  return (
    <Badge
      text={showText ? onboardingStatus : undefined}
      status={
        onboardingStatus === ZoneOnboardingStatus.TEMPORARILY_DISABLED
          ? "error"
          : onboardingStatus === ZoneOnboardingStatus.ONBOARDING ||
            onboardingStatus === ZoneOnboardingStatus.COMING_SOON
          ? "warning"
          : "success"
      }
    />
  );
}

export function ZoneBusyStatusBadge({
  busyStatus,
  showText = true,
}: {
  showText: boolean;

  busyStatus: ZoneBusyStatus;
}) {
  return (
    <Badge
      text={showText ? busyStatus : undefined}
      status={
        busyStatus === ZoneBusyStatus.BUSY
          ? "error"
          : busyStatus === ZoneBusyStatus.MODERATE
          ? "warning"
          : "success"
      }
    />
  );
}
