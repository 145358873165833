import { Button, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useCallback, useState } from "react";
import { $ } from "../graphql/generated";
import { useTypedQuery } from "../graphql/hooks";
import { getImageUrl } from "../utils/image-url";
import MediaList, { ImageAssetType } from "./media/MediaList";

export default function SelectImageAsset({
  onChange,
}: {
  onChange: (items: ImageAssetType[]) => void;
}) {
  const [show, setShow] = useState(false);
  const openDialog = useCallback(() => setShow(true), []);
  return (
    <>
      <Button onClick={openDialog}>Choose Image</Button>
      <Modal visible={show} onCancel={() => setShow(false)}>
        <MediaList
          onSelect={(s) => {
            setShow(false);
            onChange(s);
          }}
        />
      </Modal>
    </>
  );
}

export function DisplayImageAsset({ id }: { id: string }) {
  const { data } = useTypedQuery(
    {
      ImageAsset: [
        {
          id: $`id`,
        },
        {
          id: true,
          key: true,
          altText: true,
          title: true,
        },
      ],
    },
    {
      variables: {
        id,
      },
    }
  );

  return data ? (
    <img
      src={getImageUrl(data?.ImageAsset.key)}
      alt={data.ImageAsset.altText}
      style={{
        maxWidth: "100%",
        maxHeight: 300,
      }}
    />
  ) : (
    <Spin />
  );
}
