import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, PageHeader, Space, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Container, Row } from "shards-react";
import { CreateCityForm } from "../components/forms/CreateCityForm";
import { CreateZoneForm } from "../components/forms/CreateZoneForm";
import { ZoneBusyStatusBadge, ZoneStateBadge } from "../components/ZoneBadges";
import { $ } from "../graphql/generated";
import { useTypedQuery } from "../graphql/hooks";

export const Cities = () => {
  const { data, loading } = useTypedQuery(
    {
      allCities: [
        {
          skip: $`skip`,
          take: $`take`,
        },
        {
          id: true,
          name: true,
          zones: {
            id: true,
            name: true,
            slug: true,
            busyStatus: true,
            onboardingStatus: true,
          },
        },
      ],
    },
    {
      variables: {
        skip: 0,
        take: 25,
      },
    }
  );

  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  const [selected, setSelected] = useState("");
  const allCities = data?.allCities ?? [];

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <PageHeader
        title="Cities"
        extra={[
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => setShowModal(true)}
          >
            New City
          </Button>,
        ]}
      />

      <Row>
        <Col lg="12">
          <Table
            loading={loading}
            dataSource={allCities}
            columns={[
              {
                title: "Name",
                dataIndex: "name",
              },
              {
                title: "Zones",
                dataIndex: "zones",
                render: (r: typeof allCities[0]["zones"]) => (
                  <Space direction="vertical">
                    {r.map((z) => (
                      <Space>
                        <Link to={`/zone/${z.id}`}>
                          <a>{z.name}</a>
                        </Link>
                        <ZoneStateBadge onboardingStatus={z.onboardingStatus} />
                        <ZoneBusyStatusBadge busyStatus={z.busyStatus} />
                      </Space>
                    ))}
                  </Space>
                ),
              },
              {
                render: (_, r) => (
                  <Button
                    type="text"
                    icon={<PlusCircleOutlined />}
                    onClick={() => setSelected(r.id)}
                  >
                    Add Zone
                  </Button>
                ),
              },
            ]}
          />
        </Col>
      </Row>
      <Modal
        visible={showModal}
        title="Create a City"
        onCancel={() => setShowModal(false)}
        footer={false}
      >
        <CreateCityForm />
      </Modal>

      <Modal
        visible={!!selected}
        title="Create a Zone"
        onCancel={() => setSelected("")}
        footer={false}
      >
        <CreateZoneForm cityId={selected} />
      </Modal>
    </Container>
  );
};
