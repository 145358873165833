import { Button, Card, Input, message, Popconfirm, Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { $ } from "../graphql/generated";
import { useTypedMutation, useTypedQuery } from "../graphql/hooks";
import { CUSTOMER_LIST } from "../graphql/queries";
import usePagination from "../hooks/usePagination";

export default function CustomersTable() {
  const {
    pagination,
    args: { order, page, query, sortBy, take },
    onChange,
    setQuery,
  } = usePagination({
    defaultOrder: "ASC",
    defaultSortBy: "email",
  });

  const { data } = useTypedQuery(CUSTOMER_LIST, {
    variables: {
      skip: (page - 1) * take,
      take,
      sortBy,
      order,
      query,
    },
    fetchPolicy: "network-only",
  });

  const [deleteCustomer, { loading: deleting }] = useTypedMutation(
    {
      deleteCustomer: [
        {
          id: $`id`,
        },
        true,
      ],
    },
    {
      onCompleted: () => {
        message.success("Deleted the user.");
      },
    }
  );

  return (
    <Card
      className="mb-4"
      title={
        <>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Input
              placeholder="Search Customers"
              onChange={(e) => setQuery(e.target.value)}
              style={{ marginBottom: 8 }}
              allowClear
            />
          </div>
        </>
      }
    >
      <Table
        dataSource={data?.CustomerList.items || []}
        pagination={{
          total: data?.CustomerList.total,
          ...pagination,
        }}
        onChange={onChange}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            sorter: true,
          },
          {
            title: "Email",
            dataIndex: "email",
            sorter: true,
            render: (_, r) => <Link to={`/customer/${r.id}`}>{r.email}</Link>,
          },
          {
            render: (u) => (
              <Popconfirm
                title="This is VERY PERMANENT!!!"
                okButtonProps={{
                  loading: deleting,
                  danger: true,
                }}
                okText="I WANT TO DELETE THIS USER"
                onConfirm={() =>
                  deleteCustomer({
                    variables: {
                      id: u.id,
                    },
                  })
                }
              >
                {" "}
                <Button danger>Delete</Button>
              </Popconfirm>
            ),
          },
        ]}
      ></Table>
    </Card>
  );
}
