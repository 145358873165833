import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, List, message, Modal, Space, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import Text from "antd/lib/typography/Text";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Marker } from "react-leaflet";
import { $, Address } from "../../graphql/generated";
import { useTypedMutation, useTypedQuery } from "../../graphql/hooks";
import Map from "../../utils/Map";
import {
  latLngToPostcode,
  postcodeIcon,
  postcodeToInfo,
  validatePostcode,
} from "../../utils/postcode";
import { ZoneContext } from "../../views/Zone";

export function formatAddress(
  address: Pick<Address, "flat_number" | "address_line_1" | "postcode">
) {
  return `${(address.flat_number || "") + " "}${address.address_line_1}, ${
    address.postcode
  }`;
}

export function AddressDisplay({ id }: { id: string }) {
  const { data } = useTypedQuery(
    {
      Address: [
        {
          id: $`id`,
        },
        {
          id: true,
          address_line_1: true,
          flat_number: true,
          coordinates: {
            type: true,
            coordinates: true,
          },
          country: true,
          instructions: true,
          nickname: true,
          phone_number: true,
          postcode: true,
          town: true,
        },
      ],
    },
    {
      variables: {
        id,
      },
    }
  );

  const formattedAddress = data ? (
    `${(data?.Address.flat_number || "") + " "}${
      data?.Address.address_line_1
    }, ${data?.Address.postcode}`
  ) : (
    <Spin />
  );

  return (
    <>
      <Text>{formattedAddress}</Text>
      <Map
        center={
          data?.Address.coordinates?.coordinates || [56.333874, -2.8059686]
        }
        zoom={12}
        style={{
          height: "300px",
        }}
      >
        {data && <Marker position={data.Address.coordinates?.coordinates!} />}
      </Map>
    </>
  );
}

export default function AddAddressButton({
  coordinates,
  initialAddrId: propInitialAddrId,
  onComplete,
}: {
  coordinates?: [number, number];
  initialAddrId?: string;
  onComplete: (id: string) => void;
}) {
  const [initialAddrId, setInitialAddrId] = useState(propInitialAddrId);

  const zone = useContext(ZoneContext);

  // update initialAddrId when props update
  useEffect(() => {
    if (propInitialAddrId) {
      setInitialAddrId(propInitialAddrId);
    }
  }, [propInitialAddrId]);

  // estimate  of the zone polygon centre based on the coordinates
  // can be skewed by an imbalance in the density of vertices
  const zonePolygonCentreEstimate = useMemo<[number, number]>(() => {
    const polygonCoordinatesArr = zone?.polygon?.coordinates;
    if (!polygonCoordinatesArr || polygonCoordinatesArr.length !== 1)
      return [56.333874, -2.8059686];
    const polygonCoordinates = polygonCoordinatesArr[0];
    var totalX = 0;
    var totalY = 0;
    polygonCoordinates.forEach((polygonCoordinate) => {
      totalX += polygonCoordinate[0];
      totalY += polygonCoordinate[1];
    });
    const nPolygons = polygonCoordinates.length;
    return [totalX / nPolygons, totalY / nPolygons];
  }, [zone?.polygon?.coordinates]);

  const [centreCoordinates, setCentreCoordinates] = useState<[number, number]>(
    coordinates ? coordinates : zonePolygonCentreEstimate
  );

  const { data: initialAddrData } = useTypedQuery(
    {
      Address: [
        {
          id: $`id`,
        },
        {
          id: true,
          address_line_1: true,
          flat_number: true,
          coordinates: {
            type: true,
            coordinates: true,
          },
          phone_number: true,
          postcode: true,
        },
      ],
    },
    {
      skip: !initialAddrId,
      variables: {
        id: initialAddrId,
      },
    }
  );

  const initialAddr = initialAddrData?.Address;

  const [showModal, setShowModal] = useState(false);

  const [marker, setMarker] = useState<[number, number] | undefined>(
    coordinates
  );

  const [customMarkerPostcodes, setCustomMarkerPostcodes] = useState<
    string[] | undefined
  >(undefined);

  useEffect(() => {
    if (!marker) {
      setCustomMarkerPostcodes(undefined);
    } else {
      latLngToPostcode(marker).then((val) => {
        setCustomMarkerPostcodes(val.result.map((result) => result.postcode));
      });
    }
  }, [marker]);

  const [postcodeMarker, setPostcodeMarker] = useState<[number, number] | null>(
    null
  );

  const [addAddress] = useTypedMutation(
    {
      createAddress: [
        {
          address: $`address`,
        },
        { id: true },
      ],
    },
    {
      onCompleted: (d) => {
        message.success("Created new address!");
        setInitialAddrId(d.createAddress.id);
        setShowModal(false);
        onComplete(d.createAddress.id);
      },
    }
  );

  const finish = async (v) => {
    await addAddress({
      variables: {
        address: {
          ...v,
          coordinates: {
            type: "Point",
            coordinates: marker,
          },
        },
      },
    });
  };

  const [form] = useForm();

  // update the location of the postcode marker
  const onPostcodeUpdate = useCallback(
    async (postcode: string) => {
      const postcodeResult = (await postcodeToInfo(postcode))?.result;
      if (postcodeResult) {
        const latitude = postcodeResult.latitude;
        const longitude = postcodeResult.longitude;
        setPostcodeMarker([latitude, longitude]);
      }
    },
    [setPostcodeMarker]
  );

  // update form to reflect initialAddr when initialAddr is updated and the modal is not visible
  useEffect(() => {
    if (initialAddr && !showModal) {
      form.setFieldsValue(initialAddr);
      onPostcodeUpdate(initialAddr.postcode);
    }
  }, [initialAddr, showModal, form, onPostcodeUpdate]);

  // update map to reflect initialAddr when initialAddr's coordinates are updated and the modal is not visible
  useEffect(() => {
    const newInitialAddrCoordinates = initialAddr?.coordinates?.coordinates;
    if (newInitialAddrCoordinates && !showModal) {
      setCentreCoordinates(newInitialAddrCoordinates);
    }
  }, [initialAddr?.coordinates?.coordinates, showModal]);

  return (
    <>
      <Button style={{ marginLeft: 8 }} onClick={() => setShowModal(true)}>
        Change Address
      </Button>
      <Modal
        visible={showModal}
        title="New Address"
        onCancel={() => setShowModal(false)}
        footer={
          <Button onClick={() => form.submit()} icon={<CheckCircleOutlined />}>
            Save
          </Button>
        }
      >
        <Form
          form={form}
          onFinish={finish}
          onValuesChange={async (values) => {
            onPostcodeUpdate(values.postcode);
          }}
        >
          <Form.Item label="Flat Number" name="flat_number">
            <Input />
          </Form.Item>
          <Form.Item
            label="Line 1"
            rules={[{ required: true }]}
            name="address_line_1"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Postcode"
            rules={[
              { required: true },
              {
                validator: async (_, value) => {
                  if (await (await validatePostcode(value)).result) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Please enter a valid postcode");
                  }
                },
              },
            ]}
            name="postcode"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            rules={[{ required: true, pattern: /^\+[1-9]{1}[0-9]{3,14}$/g }]}
            name="phone_number"
          >
            <Input />
          </Form.Item>

          <Map
            center={centreCoordinates}
            zoom={12}
            style={{
              height: "300px",
            }}
            onclick={(e) => setMarker([e.latlng.lat, e.latlng.lng])}
          >
            {marker ? <Marker position={marker} /> : null}
            {postcodeMarker ? (
              <Marker
                icon={postcodeIcon}
                tooltip={`Location of postcode ${form.getFieldValue(
                  "postcode"
                )}`}
                position={postcodeMarker}
              />
            ) : null}
          </Map>
          <Text>Nearby Postcodes:</Text>
          <List
            dataSource={customMarkerPostcodes}
            renderItem={(postcode) => (
              <Space>
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      postcode,
                    });
                    onPostcodeUpdate(postcode);
                  }}
                >
                  Update
                </Button>
                <List.Item>{postcode}</List.Item>
              </Space>
            )}
          />
        </Form>
      </Modal>
    </>
  );
}
