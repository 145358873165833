import { Button, Card, DatePicker, message, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import Title from "antd/lib/typography/Title";
import { endOfDay, endOfWeek, startOfDay, startOfWeek } from "date-fns";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useTypedQuery } from "../../graphql/hooks";
import { CHARITY_FINANCIAL_OVERVIEW } from "../../graphql/queries";
import {
  convertToCSV,
  download,
  filterAndSortTableData,
} from "../../utils/export-table";
import { price } from "../../utils/price";

const notToPrice = [
  "restaurantId",
  "numberOfOrders",
  "commissionPercent",
  "taxPercent",
];

export default function FinancialOverview() {
  const [startDate, setStartDate] = useState(startOfWeek(new Date()));
  const [endDate, setEndDate] = useState(endOfWeek(new Date()));

  const { data, loading } = useTypedQuery(CHARITY_FINANCIAL_OVERVIEW, {
    fetchPolicy: "network-only",
    variables: {
      startDate,
      endDate,
    },
    onError: (e) => message.error(e.message),
  });

  const dataSource = data?.FinancialOverview.charities || [];

  const keys = Object.keys(dataSource);
  const COLUMNS: ColumnsType<NonNullable<typeof dataSource>[0]> = [
    {
      title: "Charity",
      render: (_, r) => (
        <Link to={`/charity/${r.charity.id}`}>{r.charity.name}</Link>
      ),
    },
    {
      title: "Donation Total",
      render: (_, r) => price(r.donationTotal),
    },
  ];

  const asCSV = useCallback(() => {
    const sorted = filterAndSortTableData(dataSource, COLUMNS as any);
    const csv = convertToCSV(sorted);
    download(
      `ecoeats-charity-${startDate.toDateString()}-${endDate.toDateString()}.csv`,
      csv
    );
  }, [dataSource, COLUMNS]);

  return (
    <>
      <Card
        style={{ overflowX: "auto", padding: 0 }}
        bodyStyle={{
          padding: 0,
        }}
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Title level={4} style={{ flex: 1 }}>
              Charity Financials
            </Title>

            <DatePicker.RangePicker
              value={[moment(startDate), moment(endDate)]}
              onChange={(v) => {
                if (v && v[0] && v[1]) {
                  setStartDate(startOfDay(v[0]?.toDate()));
                  setEndDate(endOfDay(v[1]?.toDate()));
                }
              }}
              style={{
                marginRight: 8,
              }}
            />

            <Button onClick={asCSV}>Financials Only</Button>
          </div>
        }
      >
        <Table
          dataSource={dataSource}
          showSorterTooltip
          loading={loading}
          columns={COLUMNS}
          pagination={{
            pageSize: 25,
          }}
          rowKey="id"
          expandable={{
            expandedRowRender: (record) => (
              <Table
                dataSource={record.campaigns}
                columns={[
                  {
                    title: "Campaign",
                    render: (_, r) => r.campaign.title,
                  },
                  {
                    title: "Donation Total",
                    render: (_, r) => price(r.donationTotal),
                  },
                ]}
              />
            ),
            defaultExpandAllRows: true,
          }}
        />
      </Card>
    </>
  );
}
