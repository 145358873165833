import { formatDistance, isToday } from "date-fns";

export const formatIfPast = (d: string | Date) => {
  const stamp = new Date(d);
  if (isToday(stamp)) {
    return formatDistance(stamp, new Date(), {
      addSuffix: true,
    });
  }

  return stamp.toLocaleString();
};

export function formatEnum(k: string) {
  return k
    .split("_")
    .map((s, i) =>
      i === 0
        ? `${s[0].toUpperCase()}${s.slice(1).toLowerCase()}`
        : s.toLowerCase()
    )
    .join(" ");
}
