import { Button, message, Switch, Table } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { ColumnsType } from "antd/lib/table";
import update from "immutability-helper";
import React, { useCallback, useMemo, useRef } from "react";
import { DndProvider } from "react-dnd";
import { Item, ItemUpdateInput } from "../../graphql/generated";
import { useTypedMutation } from "../../graphql/hooks";
import { UPDATE_ITEM } from "../../graphql/mutations";
import { getImageUrl } from "../../utils/image-url";
import { onError } from "../../utils/on-error";
import { useUpdateItemKey } from "../../utils/update-item-key";
import { useSelectedMenu } from "../RestaurantMenu";
import AvailabilitySelect, {
  AvailabilitySelectOnChange,
} from "./AvailabilitySelect";
import { DraggableTableRow, RNDContext } from "./DraggableTableRow";

interface Props {
  menuItems: Item[];
  setMenuItems(items: Item[]): void;
  partnerId: string;
  selectedItemIds: string[];
  addSelectedItemId: (itemId: string) => void;
  removeSelectedItemId: (itemId: string) => void;
}
const components = {
  body: {
    row: DraggableTableRow,
  },
};

const ViewEditButton = ({ item }) => {
  const { onClick } = useSelectedMenu();

  const _onClick = useCallback(() => {
    onClick(item);
  }, [onClick, item]);

  return (
    <Button type="text" onClick={_onClick}>
      Edit
    </Button>
  );
};

const DragSortingMenuTable = (props: Props) => {
  const {
    partnerId,
    menuItems,
    setMenuItems,
    selectedItemIds,
    addSelectedItemId,
    removeSelectedItemId,
  } = props;
  const {
    query: { updateQuery },
  } = useSelectedMenu();

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = menuItems[dragIndex];
      const resortedItems = update(menuItems, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      setMenuItems(resortedItems);
      // setLocalMenuItems(resortedItems);
    },
    [menuItems]
  );

  const manager = useRef(RNDContext);

  const onChange = useCallback(
    ({ id, categoryId, available, published }) => {
      updateQuery((p) => ({
        Menu: {
          ...p.Menu,
          categories: p.Menu.categories.map((c) =>
            c.id === categoryId
              ? {
                ...c,
                items: c.items.map((i) =>
                  i.id === id
                    ? {
                      ...i,
                      available,
                      published,
                    }
                    : i
                ),
              }
              : c
          ),
        },
      }));
    },
    [updateQuery]
  );

  const dataSource: (typeof menuItems[0] & {
    onChange: AvailabilitySelectOnChange;
    partnerId: string;
  })[] = useMemo(() => menuItems.map((m) => ({ ...m, onChange, partnerId })), [
    partnerId,
    onChange,
    menuItems,
  ]);

  const onRow = useCallback(
    (record, index) => ({
      index,
      moveRow,
    }),
    [moveRow]
  );

  const [updateItem, { client, loading }] = useTypedMutation(UPDATE_ITEM, {
    onCompleted: () => message.success("Updated!"),
    onError: onError,
  });
  const updateItemKey = useUpdateItemKey<ItemUpdateInput>(updateItem, "item");

  const columns: ColumnsType = useMemo(
    () => [
      {
        render: (item) => (
          <Checkbox
            checked={selectedItemIds.includes(item.id)}
            onChange={(e) => {
              if (e.target.checked) {
                addSelectedItemId(item.id);
              } else {
                removeSelectedItemId(item.id);
              }
            }}
          />
        ),
      },
      {
        title: "",
        key: "image",
        render: (item) =>
          item.headerImageKey ? (
            <img
              style={{
                width: 96,
                height: 96,
              }}
              src={getImageUrl(item.headerImageKey, 256, 256)}
            />
          ) : null,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Price",
        dataIndex: "unitPrice",
        key: "price",
        render: (price) => `£${(price / 100).toFixed(2)}`,
      },
      {
        title: "Availability",
        key: "availability",
        render: ({ id, partnerId, available, published, onChange }) => (
          <AvailabilitySelect
            key={id}
            onChange={onChange}
            available={available}
            id={id}
            published={published}
            partnerId={partnerId}
          />
        ),
      },
      {
        title: "Approved",
        key: "approved",
        render: ({ id, approved }) => (
          <Switch
            onChange={(v) => updateItemKey("approved", v, id)}
            checked={approved}
          ></Switch>
        ),
      },
      {
        title: "View/Edit",
        key: "itemLink",
        render: (item) => <ViewEditButton item={item} />,
      },
    ],
    [selectedItemIds]
  );

  return (
    <DndProvider manager={manager.current.dragDropManager}>
      <Table
        // otherwise dragging is impossible
        pagination={false}
        columns={columns}
        dataSource={dataSource}
        rowKey={(item: any) => `${item.id}_row`}
        components={components}
        onRow={onRow as any}
      />
    </DndProvider>
  );
};

export default DragSortingMenuTable;
