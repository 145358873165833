import Checkbox from "antd/lib/checkbox/Checkbox";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Marker } from "react-leaflet";
import { Address } from "../../../graphql/generated";
import Map from "../../../utils/Map";
import styles from "./AddressView.module.css";

const AddressView = ({
  address,
  collapse,
  selected,
  selectable,
  deleteAddress,
  setSelected,
}: {
  address: Address;
  collapse: boolean;
  selected: string;
  selectable: boolean;
  deleteAddress?: (id: string) => void;
  setSelected?: (id: string) => void;
}) => {
  const doDelete = React.useCallback(() => deleteAddress?.(address.id), [
    address.id,
    deleteAddress,
  ]);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const deleteClick = () => setShowDeleteConfirm(true);

  const onClick = React.useCallback(() => {
    if (!setSelected || !selectable) return;
    if (collapse && selected) {
      setSelected("");
    } else {
      setSelected(address.id);
    }
  }, [collapse, selected, address.id, setSelected]);
  return (
    <>
      <div className={styles.container} key={address.id} onClick={onClick}>
        {!showDeleteConfirm && (!selectable || selected !== address?.id) ? (
          <Map
            center={address.coordinates?.coordinates}
            style={{ width: "100px", height: "100px" }}
            zoom={14}
            zoomControl={false}
          >
            <Marker position={address.coordinates?.coordinates} />
          </Map>
        ) : null}
        <span style={{ flex: "1 1 0%" }}>
          <p style={{ fontSize: "0.8em", padding: "0 5px", margin: "auto" }}>
            {address.flat_number ? (
              <>
                {address.flat_number}
                <br />
              </>
            ) : null}
            {address.address_line_1}
            <br />
            {address.postcode} <br />
            {address.phone_number} <br />
            {address.instructions}
          </p>
        </span>
        {!selectable ? (
          showDeleteConfirm ? (
            <div>
              <span
                style={{
                  display: "block",
                  fontSize: "0.8em",
                  textAlign: "center",
                  paddingTop: "4px",
                }}
              >
                Delete this address?
              </span>
              <span style={{ display: "flex" }}>
                <Button
                  style={{ color: "red" }}
                  variant="link"
                  onClick={doDelete}
                >
                  Delete
                </Button>
                <Button
                  variant="link"
                  onClick={() => setShowDeleteConfirm(false)}
                >
                  Back
                </Button>
              </span>
            </div>
          ) : (
            <div>
              <Button
                variant="link"
                onClick={deleteClick}
                style={{
                  color: "red",
                }}
              >
                Delete
              </Button>
            </div>
          )
        ) : (
          <span style={{ alignSelf: "center", marginRight: "20px" }}>
            <Checkbox checked={selected === address.id} />
          </span>
        )}
      </div>
    </>
  );
};

export default AddressView;
