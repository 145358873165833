import { Col, PageHeader, Row } from "antd";
import React from "react";
import PartnerTabletTable from "../components/partner/PartnerTabletTable";

export default function Tablets() {
  return (
    <PageHeader
      title="Partner Tablets"
      subTitle="Monitor live connections from our tablets"
    >
      <Row>
        <Col span={24}>
          <PartnerTabletTable />
        </Col>
      </Row>
    </PageHeader>
  );
}
