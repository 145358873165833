import { Button, ButtonProps } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import DynamicForm, { DynamicFormProps } from "../DynamicForm";

export default function DynamicFormButton({
  form,
  button = {},
  children,
}: {
  children?: React.ReactNode;
  form: DynamicFormProps;
  button?: ButtonProps;
}) {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        {...button}
        onClick={(e) => {
          setShow(true);
          if (button.onClick) button.onClick(e);
        }}
      >
        {children}
      </Button>

      <Modal visible={show} footer={null} onCancel={() => setShow(false)}>
        {show && (
          <DynamicForm
            {...form}
            onComplete={(...args) => {
              setShow(false);
              if (form.onComplete) {
                form.onComplete(...args);
              }
            }}
          />
        )}
      </Modal>
    </>
  );
}
