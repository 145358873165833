import { Badge, PageHeader, Table } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { $ } from "../../graphql/generated/graphql-zeus";
import { useTypedQuery } from "../../graphql/hooks";
import { onError } from "../../utils/on-error";
import { ZoneContext } from "../../views/Zone";
export default function ZoneEvents() {
  const zone = useContext(ZoneContext);

  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [sortBy, setSortBy] = useState("timestamp");
  const [order, setOrder] = useState("DESC");

  const [fields, setFields] = useState<string[]>([]);

  const [updated, setUpdated] = useState(moment());

  const { data, loading } = useTypedQuery(
    {
      ZoneEventList: [
        {
          order: $`order`,
          sortBy: $`sortBy`,
          skip: $`skip`,
          take: $`take`,
          zoneId: $`zoneId`,
          fields: $`fields`,
        },
        {
          total: true,
          items: {
            id: true,
            operation: true,
            timestamp: true,
            from: true,
            to: true,
            zoneId: true,
          },
        },
      ],
    },
    {
      skip: !zone?.id,
      variables: {
        order,
        sortBy,
        skip: (page - 1) * take,
        take,
        zoneId: zone?.id,
        fields,
      },
      pollInterval: 10000,
      onError: onError,
    }
  );

  useEffect(() => {
    setUpdated(moment());
  }, [data]);

  return (
    <div>
      <PageHeader
        title="Events"
        subTitle="Record of any changes to the Zone"
        extra={[
          <Badge status="processing" text={`Updated ${updated.fromNow()}`} />,
        ]}
      />
      <Table
        dataSource={data?.ZoneEventList.items}
        pagination={{
          current: page,
          total: data?.ZoneEventList.total,
          pageSize: take,
          onChange: (p, ps) => {
            if (ps) setTake(ps);
            setPage(p);
          },
        }}
        onChange={(p, f) => {
          if (f.from) {
            setFields(f.from.map((t) => t.toString()));
          } else {
            setFields([]);
          }
        }}
        columns={[
          {
            title: "Time",
            dataIndex: "timestamp",
            render: (r) => new Date(r).toLocaleString(),
          },
          {
            title: "From",
            dataIndex: "from",
            render: (r) => JSON.stringify(r),
            filters: Object.keys(zone.data?.zone || {}).map((k) => ({
              text: k,
              value: k,
            })),
          },
          {
            title: "To",
            dataIndex: "to",
            render: (r) => JSON.stringify(r),
          },
        ]}
      />
    </div>
  );
}
