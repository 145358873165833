import { HeatMapOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Descriptions,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Space,
  Table,
  Tag,
} from "antd";
import Title from "antd/lib/typography/Title";
import L from "leaflet";
import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Marker, Popup } from "react-leaflet";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { style } from "typestyle";
import { $, ReusableShipmentStatus } from "../../graphql/generated";
import {
  mutation,
  query,
  useTypedMutation,
  useTypedQuery,
} from "../../graphql/hooks";
import { getImageUrl } from "../../utils/image-url";
import Map from "../../utils/Map";
import { LRiderIcon } from "../../views/Order";
import DynamicForm from "../forms/DynamicForm";
import { OrderRiderMiniDisplay } from "../order/OrderDescriptions";
import { OrderBadge } from "../OrderBadge";
import { RestoIcon } from "../RestaurantDelivery";
import ScannedContainerTable from "./ScannedContainerTable";

const SHIPMENT = query({
  ReusableShipment: [
    {
      id: $`id`,
    },
    {
      id: true,
      createdAt: true,
      status: true,
      scheduledFor: true,
      orders: {
        id: true,
        number: true,
        status: true,
      },
      canScheduleOrder: true,
      activeOrder: {
        id: true,
        number: true,
        status: true,
        isBeingOfferedToRider: true,
        rider: {
          id: true,
          name: true,
          phoneNumber: true,
          vehicle: true,
          position: {
            type: true,
            coordinates: true,
          },
        },
      },
      partner: {
        id: true,
        name: true,
        headerImageKey: true,
        address: {
          id: true,
          flat_number: true,
          address_line_1: true,
          postcode: true,
          coordinates: {
            type: true,
            coordinates: true,
          },
        },
      },
      containers: {
        containerType: {
          id: true,
          depth: true,
          length: true,
          width: true,
          image: {
            id: true,
            key: true,
          },
          name: true,
        },
        containersScannedSoFar: true,
        quantity: true,
      },
      washingLocation: {
        id: true,
        name: true,
        status: true,
        address: {
          id: true,
          flat_number: true,
          address_line_1: true,
          postcode: true,
          coordinates: {
            type: true,
            coordinates: true,
          },
        },
      },
    },
  ],
});

const styles = {
  icon: style({
    display: "flex",
    borderRadius: 30,
    backgroundColor: "white",
    height: 32,
    width: 32,
    justifyContent: "center",
    alignItems: "center",
  }),
};
const LWashingIcon = L.divIcon?.({
  className: "custom-icon",
  iconAnchor: [12, 12],
  html: ReactDOMServer.renderToString(
    <div
      className={styles.icon}
      style={{
        border: `4px solid green`,
      }}
    >
      <HeatMapOutlined />
    </div>
  ),
});

const EXECUTE_SHIPMENT = mutation({
  CompleteReusableShipment: [
    {
      id: $`id`,
      washingLocationId: $`washingLocationId`,
    },
    {
      ...SHIPMENT.ReusableShipment[1],
    },
  ],
});

export default function ShipmentView() {
  const { id } = useParams();

  const { data, refetch } = useTypedQuery(SHIPMENT, {
    pollInterval: 10000,
    variables: {
      id,
    },
  });

  const washingLocationId = data?.ReusableShipment.washingLocation?.id;

  const [completeShipment, { loading: completing }] = useTypedMutation(
    EXECUTE_SHIPMENT,
    {
      variables: {
        id,
        washingLocationId,
      },
      onError: (e) => message.error(e.message),
    }
  );

  const location = data?.ReusableShipment.washingLocation;
  const orders = data?.ReusableShipment.orders;
  const activeOrder = data?.ReusableShipment.activeOrder;
  const locationCoords = {
    lat:
      data?.ReusableShipment.washingLocation?.address.coordinates
        ?.coordinates[0],
    lng:
      data?.ReusableShipment.washingLocation?.address.coordinates
        ?.coordinates[1],
  };

  const r = data?.ReusableShipment;

  const [show, setShow] = useState(false);

  return (
    <>
      <PageHeader
        title={
          <Title
            level={5}
            style={{
              fontFamily: "monospace",
              marginBottom: 0,
            }}
          >
            {data?.ReusableShipment.id.slice(0, 8)}
          </Title>
        }
        subTitle="Reusables Shipment"
        extra={[
          <Badge
            status={
              data?.ReusableShipment.status !== ReusableShipmentStatus.COMPLETE
                ? "processing"
                : "success"
            }
            color={
              data?.ReusableShipment.status === ReusableShipmentStatus.EN_ROUTE
                ? "orange"
                : data?.ReusableShipment.status ===
                  ReusableShipmentStatus.PREPARING
                ? "cyan"
                : undefined
            }
            text={data?.ReusableShipment.status}
          />,
          <Button onClick={() => setShow(true)}>Edit</Button>,
        ]}
      >
        {data ? (
          <Map
            style={{
              height: 300,
            }}
            zoom={10}
            center={
              locationCoords?.lat
                ? locationCoords
                : {
                    lat: 0,
                    lng: 0,
                  }
            }
          >
            {locationCoords.lat && (
              <Marker position={locationCoords} icon={LWashingIcon}>
                <Popup>Washing Location</Popup>
              </Marker>
            )}
            {activeOrder?.rider?.position?.coordinates[0] && (
              <Marker
                icon={LRiderIcon(activeOrder.rider.vehicle)}
                position={{
                  lat: activeOrder.rider.position?.coordinates[0],
                  lng: activeOrder.rider.position?.coordinates[1],
                }}
              ></Marker>
            )}
            <Marker
              position={
                data.ReusableShipment.partner.address.coordinates?.coordinates
              }
              icon={RestoIcon(
                data.ReusableShipment.partner.headerImageKey || ""
              )}
            ></Marker>
          </Map>
        ) : (
          <div style={{ width: "100%", height: 300 }} />
        )}

        <Descriptions bordered style={{ marginTop: 16, marginBottom: 16 }}>
          <Descriptions.Item label="Status">
            {r && (
              <Badge
                status={
                  r.status === ReusableShipmentStatus.PENDING
                    ? "processing"
                    : "default"
                }
                color={
                  r.status === ReusableShipmentStatus.EN_ROUTE
                    ? "orange"
                    : r.status === ReusableShipmentStatus.COMPLETE
                    ? "green"
                    : ReusableShipmentStatus.PREPARING
                    ? "blue"
                    : undefined
                }
                text={r.status}
              />
            )}{" "}
          </Descriptions.Item>
          <Descriptions.Item label="Washing Location">
            <Badge
              status={
                !location
                  ? "warning"
                  : location.status === "READY"
                  ? "success"
                  : "warning"
              }
              text={
                location ? (
                  <Link to={`/reusables/washing-location/${location.id}`}>
                    {location.name}
                  </Link>
                ) : (
                  "Unassigned"
                )
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="Deliver To">
            <Badge
              status={"success"}
              text={
                <Link to={`/restaurant/${data?.ReusableShipment.partner.id}`}>
                  {data?.ReusableShipment.partner.name}
                </Link>
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="Scheduled For">
            {new Date(data?.ReusableShipment.scheduledFor).toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item label="Orders">
            {data?.ReusableShipment.orders.map((o) => (
              <Tag color="green">
                <Link to={`/order/${o.id}`}>{o.number}</Link>{" "}
                <OrderBadge status={o.status} />
              </Tag>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label="Rider">
            {activeOrder && <OrderRiderMiniDisplay {...activeOrder} />}
          </Descriptions.Item>
          <Descriptions.Item label="Actions">
            {data?.ReusableShipment.canScheduleOrder && (
              <Space>
                <Button
                  type="primary"
                  loading={completing}
                  onClick={() => completeShipment()}
                >
                  Schedule For Pickup
                </Button>
                <Popconfirm
                  onConfirm={() =>
                    completeShipment({
                      variables: {
                        id,
                        washingLocationId,
                        override: true,
                      },
                    })
                  }
                  title="Are you sure you want to reschedule this order without all the containers scanned?"
                >
                  <Button type="primary" danger loading={completing}>
                    Schedule without Checks
                  </Button>
                </Popconfirm>
              </Space>
            )}
          </Descriptions.Item>
        </Descriptions>

        <Table
          dataSource={data?.ReusableShipment.containers}
          title={() =>
            `${data?.ReusableShipment.containers.reduce(
              (a, c) => a + c.quantity,
              0
            )} Containers`
          }
          columns={[
            {
              render: (_, r) =>
                r.containerType.image ? (
                  <img
                    src={getImageUrl(r.containerType.image.key, 256, 256)}
                    alt="container"
                    style={{
                      width: 64,
                    }}
                  />
                ) : null,
            },
            {
              title: "Name",
              dataIndex: "name",
              sorter: true,
              render: (_, r) => r.containerType.name,
            },
            {
              title: "Size",
              key: "size",
              sorter: true,
              render: (_, r) =>
                `${r.containerType.length}x${r.containerType.width}x${r.containerType.depth}`,
            },
            {
              title: "Quantity",
              dataIndex: "quantity",
              render: (_, r) =>
                `${r.containersScannedSoFar}/${r.quantity} Scanned`,
            },
          ]}
        />

        <ScannedContainerTable shipmentId={id} />

        <pre>{JSON.stringify(data, null, 2)}</pre>
      </PageHeader>

      <Modal
        footer={null}
        visible={show}
        destroyOnClose
        onCancel={() => setShow(false)}
      >
        <DynamicForm
          entityId={id}
          onComplete={() => {
            refetch();
            setShow(false);
          }}
          noCard
          id="ReusableShipmentCreate"
        />
      </Modal>
    </>
  );
}
