import { ColumnsType } from "antd/lib/table";
import React from "react";
import { Link } from "react-router-dom";
import { MapType, Query } from "../../graphql/generated";
import { CHARITY_LIST } from "../../graphql/queries";
import DynamicForm from "../forms/DynamicForm";

type QueryType = MapType<Query, typeof CHARITY_LIST>["CharityList"];

export type CharityType = QueryType["items"][0];

const COLUMNS: ColumnsType<QueryType["items"][0]> = [
  {
    title: "Image",
    render: (_, c) => (
      <div style={{ width: 128, height: 128, backgroundColor: "lightgrey" }}>
        <img width="128" src={c.logo.url} />
      </div>
    ),
  },
  {
    title: "Charity",
    render: (_, c) => <Link to={`/charity/${c.id}`}>{c.name}</Link>,
  },
];

export default function Charities({
  onSelect,
}: {
  onSelect?: (charity: CharityType) => void;
}) {
  return (
    <>
      <DynamicForm id="Charity.List" />
    </>
  );
}
