import { Button, Form, Input, message, Modal } from "antd";
import React, { useState } from "react";
import { $ } from "../graphql/generated";
import { useTypedMutation } from "../graphql/hooks";

export default function RiderSendMessage({ id, name }) {
  const [showModal, setShowModal] = useState(false);

  const [sendMessage, { loading }] = useTypedMutation(
    {
      sendMessageToRider: [
        {
          id: $`id`,
          message: $`message`,
        },
        true,
      ],
    },
    {
      onCompleted: () => {
        message.success("Sent!");
        setShowModal(false);
      },
    }
  );

  return (
    <>
      <Button onClick={() => setShowModal(true)} loading={loading}>
        Send Rider a Message
      </Button>
      <Modal
        footer={false}
        title="Send Rider a Message"
        visible={showModal}
        onCancel={() => setShowModal(false)}
      >
        <Form
          layout="vertical"
          onFinish={(v) =>
            v.message && sendMessage({ variables: { id, message: v.message } })
          }
        >
          <Form.Item label="Message" name="message">
            <Input placeholder="We've updated your order." />
          </Form.Item>
          <Button
            type="primary"
            style={{
              width: "100%",
            }}
            htmlType="submit"
            loading={loading}
          >
            Send Notification to {name}
          </Button>
        </Form>
      </Modal>
    </>
  );
}
