import { Badge, Card, Col, Row, Skeleton } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useContext } from "react";
import { OrderContext } from "../../views/Order";

export default function OrderB2BInfo() {
  const { data } = useContext(OrderContext);

  const order = data?.order;

  if (!order) return <Skeleton />;

  if (!order.b2bDeliveryJob) return <></>;

  return (
    <Card
      title={<Title level={5}>B2B Job</Title>}
      style={{ marginBottom: "16px" }}
    >
      <div>
        <strong>Package Size </strong>
        <Badge text={order.b2bDeliveryJob.size} status="success" />
      </div>

      <Row>
        <Col>
          <strong>Origin</strong>
          <h6>{order?.b2bDeliveryJob.origin.address_line_1}</h6>
          <h6>{order?.b2bDeliveryJob.origin.postcode}</h6>
          <h6>{order?.b2bDeliveryJob.origin.phone_number}</h6>
        </Col>
        <Col>
          <strong>Destination</strong>
          <h6>{order?.b2bDeliveryJob.destination.address_line_1}</h6>
          <h6>{order?.b2bDeliveryJob.destination.postcode}</h6>
          <h6>{order?.b2bDeliveryJob.destination.phone_number}</h6>
        </Col>
      </Row>
    </Card>
  );
}
